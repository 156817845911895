import { FC, useMemo } from 'react';
import { GraphSection as GraphSectionType } from 'types/interfaces/Drilldown/types';
import { JitSidePanelItem } from 'components/JitSidePanel/JitSidePanelItem/JitSidePanelItem';
import { ContextGraph } from 'components/PriorityFactorsGraph/ContextGraph';
import { GraphEntity } from 'components/PriorityFactorsGraph/types';
import { CustomNodeType } from 'components/PriorityFactorsGraph/types';
import { CustomEdgeType } from 'components/PriorityFactorsGraph/types';
import { buildEdge, buildNode } from 'components/PriorityFactorsGraph/utils/visualGraphUtils';
import { IPriorityFields } from 'types/interfaces/Graph/IPriorityFactor';

interface GraphSectionProps {
  section: GraphSectionType;
}

const GraphSection: FC<GraphSectionProps> = ({ section }) => {
  const entity = {} as unknown as GraphEntity;
  const priorityFields = {} as IPriorityFields;


  const nodes: CustomNodeType[] = useMemo(() => {
    if (!section.graph) return [];
    const currentNodes = section.graph.nodes.map((node) => buildNode(node, entity, priorityFields!));
    return [...currentNodes];
  }, [section.graph, entity, priorityFields]);

  const edges: CustomEdgeType[] = useMemo(() => {
    if (!section.graph) return [];
    const currentEdges = section.graph.edges.map((edge) => buildEdge(edge));
    return [...currentEdges];
  }, [section.graph]);
  
  console.log('nodes', nodes);
  console.log('edges', edges);  
  return (
    <JitSidePanelItem
      title={section.title}
      variant='light'
      wrapperStyleOverrides={{ borderRadius: '8px' }}
    >
      <div style={{ height: '350px' }}>
        <ContextGraph edges={edges} isLoading={false} nodes={nodes} />
      </div>
    </JitSidePanelItem>
  );
};

export default GraphSection; 