import { AssetType, AssetStatus } from 'types/enums';
import { ManualFactors } from 'types/interfaces/Findings';
import { IPriorityContext } from 'types/interfaces/Priority';
import { Tag } from 'types/interfaces/Tag';

export interface IAsset {
  asset_id: string;
  tenant_id: string;
  asset_type: AssetType;
  vendor: string;
  owner: string;
  asset_name: string;
  is_active: boolean;
  is_covered: boolean;
  is_archived?: boolean;
  created_at: string;
  modified_at: string;
  aws_account_id?: string;
  account_id?: string;
  status?: AssetStatus;
  score?: number;
  status_details?: string;
  aws_regions_to_scan?: string[];
  tags: Tag[];
  plan_items?: string[];
  priority_score: number;
  priority_context?: IPriorityContext;
  original_priority_context?: IPriorityContext;
  priority_factors: string[];
  manual_factors: ManualFactors;
  external_id?: string;
}

export interface IUpdateAssetRequest {
  asset_id: string;
  new_name?: string;
  is_covered?: boolean;
  is_archived?: boolean;
  environment?: string;
  is_branch_protected_by_jit?: boolean;
  status?: AssetStatus;
  status_details?: string;
  tags?: Tag[];
  score?: number;
}

export interface IAssetPriorityBuckets {
  critical: number;
  high: number;
  medium: number;
  low: number;
}

export interface IAggregatedAssetStatisticsSnakeCase {
  aggregation_key: string | Tag;
  description: string;
  count: number;
  average_priority_score: number;
  buckets: IAssetPriorityBuckets;
}

// Define the camel case interface extending the snake case interface omitting the aggregation_key and average_priority_score fields
export interface IAggregatedAssetStatistics
  extends Omit<IAggregatedAssetStatisticsSnakeCase, 'aggregation_key' | 'average_priority_score'> {
  aggregationKey: string;
  averagePriorityScore: number;
}

export interface IInitialScanStats {
  planItemSlug: string;
  completedAssetCount: number;
  inProgressAssetCount: number;
  planItemDisplayName: string;
}

export enum AssetSortBy {
  PriorityScore = 'priority_score',
  AssetName = 'asset_name',
}
