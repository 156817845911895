import { t } from 'i18next';
import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { Id } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import { useSubscribeToTicketWebsocket } from './hooks/useSubscribeToTicketWebsocket';
import { TicketCreationWebsocketContext } from './TicketCreationWebsocketContext';

import { constants } from 'globalConstants';
import { TicketFinding } from 'types/interfaces';
import { useSnackBar } from 'utils/hooks/useSnackBar';

export enum TicketCreationStatus {
  IN_PROGRESS = 'inProgress',
  SUCCESS = 'success',
  ERROR = 'error',
}

interface ITicketCreation {
  entities: Record<string, TicketCreationStatus>,
  notificationId: Id,
  onSuccess?: (ticket: TicketFinding) => void,
  onError?: (reason: string) => void
}

export const TicketCreationWebsocketProvider: FC<PropsWithChildren> = ({ children }) => {
  const [ticketCreation, setTicketCreation] = useState<Record<string, ITicketCreation>>({});
  const { showSnackBar, updateSnackBar } = useSnackBar();
  const { time: { MINUTE } } = constants;
  const displayInProgressNotification = useCallback((entityIds: string[]): Id => {
    const isPlural = entityIds.length > 1;
    return showSnackBar({
      title: t(`pages.findings.notifications.creatingTicketInProgress.title.${isPlural ? 'plural' : 'singular'}`),
      description: t(`pages.findings.notifications.creatingTicketInProgress.description.${isPlural ? 'plural' : 'singular'}`, {
        count: entityIds.length,
      }),
      type: 'loading',
      options: { autoClose: MINUTE },
    });
  }, [MINUTE, showSnackBar]);

  const displayErrorNotification = useCallback((reason: string, entityIds: string[], key?: string) => {
    if (key) {
      const groupState = ticketCreation[key];
      const isPlural = Object.keys(groupState.entities).length > 1;
      if (groupState) {
        updateSnackBar({
          notificationId: groupState.notificationId,
          title: t(`pages.findings.notifications.creatingTicketFailed.title.${isPlural ? 'plural' : 'singular'}`),
          description: t('pages.findings.notifications.creatingTicketFailed.description', {
            reason,
          }),
          type: 'error',
        });
      }
    } else {
      showSnackBar({
        title: t(`pages.findings.notifications.creatingTicketFailed.title.${entityIds?.length > 1 ? 'plural' : 'singular'}`),
        description: t('pages.findings.notifications.creatingTicketFailed.description', {
          reason,
        }),
        type: 'error',
      });
    }
  }, [showSnackBar, updateSnackBar, ticketCreation]);

  const displaySuccessNotification = useCallback((key: string, ticketUrl: string) => {
    const groupState = ticketCreation[key];
    const isPlural = Object.keys(groupState.entities).length > 1;
    if (groupState) {
      updateSnackBar({
        notificationId: groupState.notificationId,
        title: t(`pages.findings.notifications.creatingTicketSuccess.title.${isPlural ? 'plural' : 'singular'}`),
        description: t(`pages.findings.notifications.creatingTicketSuccess.description.${isPlural ? 'plural' : 'singular'}`),
        action: isPlural ? undefined : {
          label: t('pages.findings.notifications.creatingTicketSuccess.link'),
          handleClick: () => window.open(ticketUrl, '_blank'),
        },
        type: 'success',
      });
    }
  }, [updateSnackBar, ticketCreation]);

  const registerTicketCreationSuccess = useCallback((entityIds: string[], onSuccess?: (ticket: TicketFinding) => void, onError?: (reason: string) => void) => {
    const notificationId = displayInProgressNotification(entityIds);
    const groupId = uuidv4();
    setTicketCreation((prev) => ({ ...prev,
      [groupId]: { entities: entityIds.reduce((acc, id) => ({ ...acc,
        [id]: 'inProgress' }), {}),
      notificationId,
      onSuccess,
      onError } }));
  }, [displayInProgressNotification]);

  const unregisterTicketCreationWithSuccess = useCallback((entityIds: string[], ticket: TicketFinding) => {
    const entityId = entityIds[0];
    const groupId = Object.keys(ticketCreation).find((key) => ticketCreation[key].entities[entityId] === TicketCreationStatus.IN_PROGRESS);
    if (!groupId) {
      return;
    }
    const groupState = ticketCreation[groupId];
    if (!groupState) {
      return;
    }

    groupState.entities[entityId] = TicketCreationStatus.SUCCESS;
    if (Object.values(groupState.entities).every((status) => status === TicketCreationStatus.SUCCESS)) {
      setTicketCreation((prev) => {
        const { [groupId]: unused, ...rest } = prev;
        return rest;
      });
      if (groupState.onSuccess) {
        groupState.onSuccess(ticket);
      }
      displaySuccessNotification(groupId, ticket.ticketUrl);
    } else {
      setTicketCreation((prev) => {
        const { [groupId]: value, ...rest } = prev;
        return { ...rest,
          [groupId]: groupState };
      });
    }
  }, [displaySuccessNotification, ticketCreation]);

  const unregisterTicketCreationWithFailure = useCallback((entityIds: string[], reason: string) => {
    const entityId = entityIds[0];
    const groupId = Object.keys(ticketCreation).find((key) => ticketCreation[key].entities[entityId] === 'inProgress');
    if (!groupId) {
      return;
    }
    const groupState = ticketCreation[groupId];
    if (!groupState) {
      return;
    }

    displayErrorNotification(reason, entityIds, groupId);
    if (groupState.onError) {
      groupState.onError(reason);
    }
    groupState.entities[entityId] = TicketCreationStatus.ERROR;
    setTicketCreation((prev) => {
      const { [groupId]: unused, ...rest } = prev;
      return rest;
    });
  }, [displayErrorNotification, ticketCreation]);

  const registerTicketCreationError = useCallback((entityIds: string[], reason: string) => {
    displayErrorNotification(reason, entityIds);
  }, [displayErrorNotification]);

  useSubscribeToTicketWebsocket({
    expectedNotifications: Object.values(ticketCreation).flatMap(({ entities }) => Object.keys(entities)),
    onSuccess: unregisterTicketCreationWithSuccess,
    onError: unregisterTicketCreationWithFailure,
  });

  const contextValue = useMemo(() => ({
    registerTicketCreationSuccess,
    registerTicketCreationError,
  }), [registerTicketCreationSuccess, registerTicketCreationError]);

  return (
    <TicketCreationWebsocketContext.Provider value={contextValue}>
      {children}
    </TicketCreationWebsocketContext.Provider>
  );
};
