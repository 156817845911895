import { SelectedProjects } from '../SelectedProjects/SelectedProjects';

import styles from './RadioProjects.module.scss';

import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { JitRadioButton } from 'components/JitRadioButton/JitRadioButton';
import { JitText } from 'components/JitText/JitText';
import { ActionButtonVariant } from 'types/interfaces/ActionButton/ActionButton';
import { Repository, SubOrganization } from 'types/interfaces/SCM/SCMMember';

export interface RadioProjectsProps {
  isAllSelected: boolean;
  label: string;
  description: string;
  index: number;
  setIsAllSelected: (isAllSelected: boolean) => void;
  setShowAssetsDialog: (showAssetsDialog: boolean) => void;
  buttonText: string;
  areSCMResourcesSelected: boolean;
  selectedSCMResources: Repository[];
  selectedSubOrganizations: SubOrganization[];
  isCompleted: boolean;
}

export const RadioProjects = ({
  index,
  isAllSelected,
  label,
  description,
  setIsAllSelected,
  setShowAssetsDialog,
  buttonText,
  areSCMResourcesSelected,
  selectedSCMResources,
  selectedSubOrganizations,
  isCompleted,
}: RadioProjectsProps) => {
  const handleClick = () => {
    if (!isCompleted) {
      setIsAllSelected(false);
    }
  };

  const handleDialogOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!isCompleted) {
      e.stopPropagation();
      setShowAssetsDialog(true);
    }
  };

  return (
    <div
      className={styles.buttonContainer}
      onClick={handleClick}
      role='button'
      tabIndex={index}
    >
      <JitRadioButton checked={!isAllSelected} disabled={isCompleted} />

      <div className={styles.textWrapper}>
        <JitText bold size='l' text={label} />

        <JitText
          muted
          size='l'
          text={description}
        />

        <div>
          {!isCompleted && !isAllSelected && !areSCMResourcesSelected && (
            <div className={styles.buttonWrapper}>
              <JitActionButton
                actionButton={{
                  variant: ActionButtonVariant.OUTLINED,
                  label: buttonText,
                  handleClick: handleDialogOpen,
                  disabled: isCompleted,
                }}
              />
            </div>
          )}

          <SelectedProjects
            areSCMResourcesSelected={areSCMResourcesSelected}
            isCompleted={isCompleted}
            selectedSCMResources={selectedSCMResources}
            selectedSubOrganizations={selectedSubOrganizations}
            setShowAssetsDialog={setShowAssetsDialog}
          />
        </div>
      </div>
    </div>
  );
};
