import { FC } from 'react';

import { GithubActions, JitIcon } from 'assets';
import colors from 'themes/colors.module.scss';
import { Runner, PipelineTypes } from 'types/enums';
import { PipelineScope } from 'types/enums/PipelineScope';
import { ISvg } from 'types/interfaces';

export const STATUSES = {
  COMPLETED: 'completed',
  FAILED: 'failed',
  ERROR: 'error',
  SUCCESS: 'success',
  RUNNING: 'running',
  QUEUED: 'queued',
  PENDING: 'pending',
};

export const EVENTS_TYPES = {
  FULL_SCAN: 'full_scan',
  PULL_REQUEST_CREATED: 'pull_request_created',
  PULL_REQUEST_UPDATED: 'pull_request_updated',
  MERGE_DEFAULT_BRANCH: 'merge_default_branch',
  SCHEDULED: 'trigger_scheduled_task',
  ITEM_ACTIVATED: 'item_activated',
  OPEN_FIX_PR: 'open_fix_pull_request',
  REGISTER_SCHEDULED_TASKS: 'register_scheduled_tasks',
  UNREGISTER_SCHEDULED_TASKS: 'unregister_scheduled_tasks',
  DEPLOYMENT: 'deployment',
  NON_PROD_DEPLOYMENT: 'non_production_deployment',
  PROD_DEPLOYMENT: 'production_deployment',
  RESOURCE_ADDED: 'resource_added',
  JIT_BRANCH_CHANGED: 'jit_branch_changed',
  SYNCHRONOUS: 'sync',
  MANUAL_BRANCH_SCAN: 'manual_branch_scan',
};

export const WORKFLOW_STATUS_TO_COLOR = {
  [STATUSES.COMPLETED]: colors.pipelineSuccessStatusText,
  [STATUSES.FAILED]: colors.pipelineFailedStatusText,
  [STATUSES.ERROR]: colors.pipelineFailedStatusText,
  [STATUSES.RUNNING]: colors.pipelineRunningStatusText,
  [STATUSES.PENDING]: colors.pipelineQueuedStatusText,
};

export const PIPELINE_ROW_APPROXIMATE_HEIGHT = 150;

const allScopeData = {
  text: 'pages.pipelines.pipelinesTable.scopes.all',
  tooltip: 'pages.pipelines.pipelinesTable.scopesTooltip.all',
};
const changeScopeData = {
  text: 'pages.pipelines.pipelinesTable.scopes.change',
  tooltip: 'pages.pipelines.pipelinesTable.scopesTooltip.change',
};
export const SCOPES_DATA = {
  [PipelineScope.ALL]: allScopeData,
  [PipelineScope.CHANGE]: changeScopeData,
};

export const JOB_RUNNER_ICONS = new Map<string, FC<ISvg>>([
  [Runner.CI, GithubActions],
  [Runner.JIT, JitIcon],
  [Runner.SYNC, GithubActions],
]);

export const EVENT_TO_PIPELINE_TYPE = {
  [EVENTS_TYPES.FULL_SCAN]: PipelineTypes.SCHEDULER,
  [EVENTS_TYPES.PULL_REQUEST_CREATED]: PipelineTypes.PR,
  [EVENTS_TYPES.PULL_REQUEST_UPDATED]: PipelineTypes.PR,
  [EVENTS_TYPES.OPEN_FIX_PR]: PipelineTypes.PR,
  [EVENTS_TYPES.MERGE_DEFAULT_BRANCH]: PipelineTypes.PR,
  [EVENTS_TYPES.REGISTER_SCHEDULED_TASKS]: PipelineTypes.SCHEDULER,
  [EVENTS_TYPES.UNREGISTER_SCHEDULED_TASKS]: PipelineTypes.SCHEDULER,
  [EVENTS_TYPES.SCHEDULED]: PipelineTypes.SCHEDULER,
  [EVENTS_TYPES.DEPLOYMENT]: PipelineTypes.DEPLOYMENT,
  [EVENTS_TYPES.NON_PROD_DEPLOYMENT]: PipelineTypes.DEPLOYMENT,
  [EVENTS_TYPES.PROD_DEPLOYMENT]: PipelineTypes.DEPLOYMENT,
  [EVENTS_TYPES.ITEM_ACTIVATED]: PipelineTypes.SCHEDULER,
  [EVENTS_TYPES.RESOURCE_ADDED]: PipelineTypes.SCHEDULER,
  [EVENTS_TYPES.JIT_BRANCH_CHANGED]: PipelineTypes.SCHEDULER,
  [EVENTS_TYPES.SYNCHRONOUS]: PipelineTypes.DEPLOYMENT,
};

