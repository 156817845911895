import { FC } from 'react';

import styles from './EmptyState.module.scss';

import { JitEmpty } from 'components/JitEmpty/JitEmpty';
import { i18n } from 'locale/i18n';

export const EmptyState: FC = () => (
  <div className={styles.emptyStateContainer}>
    <JitEmpty
      data-testid='assets-empty-state'
      description={i18n.t('dialogs.assetsManagement.emptyState.description')}
      title={i18n.t('dialogs.assetsManagement.emptyState.title')}
    />
  </div>
);
