import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { QuickstartGuideData, CheckStatus } from 'pages/QuickStartGuide/types';
import { logError } from 'services/logger/logger';
import { useTenantService } from 'services/TenantService/useTenantService';
import { Queries } from 'types/enums/Queries';
import { UpdateCheckStatus } from 'types/interfaces/TenantService/ICheck';

export const useUpdateCheckStatus = () => {
  const queryClient = useQueryClient();
  const { updateCheckStatus: updateCheckStatusApi } = useTenantService();

  const updateCheckStatusLocally = useCallback((
    checkSlug: string,
    status: CheckStatus = CheckStatus.COMPLETED,
  ) => {
    queryClient.setQueryData<QuickstartGuideData | undefined>(
      [Queries.QuickstartChecks],
      (oldData) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          checks: oldData.checks.map((check) => (
            check.checkSlug === checkSlug
              ? { ...check,
                status }
              : check
          )),
        };
      },
    );
  }, [queryClient]);

  const { mutate: updateCheckStatus, isLoading } = useMutation(
    async ({ checkSlug, status }: UpdateCheckStatus) => {
      const response = await updateCheckStatusApi({
        checkSlug,
        status,
      });

      if (!response) throw new Error('Failed to update check status');
    },
    {
      onSuccess: (_, variables) => {
        updateCheckStatusLocally(variables.checkSlug, variables.status);
      },
      onError: (error: unknown) => {
        logError(`Failed to update check status: ${error}`);
      },
    },
  );

  return {
    updateCheckStatusLocally,
    updateCheckStatus,
    isLoading,
  };
};
