import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { JitAutoCompleteForm } from 'components/JitAutoCompleteForm/components/JitAutocompleteForm';
import { useMSTeamsTeams, useMSTeamsTeamChannels } from 'services/MicrosoftTeamsService/hooks';
import { MSTeamsItemBase } from 'services/MicrosoftTeamsService/types';

export type MSTeamsChannel = `${string}::${string}`;

interface Props {
  value?: MSTeamsChannel;
  onChange: (value?: MSTeamsChannel) => void;
}

export const MSTeamsChannelSelect: FC<Props> = ({
  value, onChange,
}) => {
  const [inputValue, setInputValue] = useState(value ?? '');
  if (typeof value === 'string' && value !== inputValue) {
    setInputValue(value);
  }

  const [selectedTeam, selectedChannel] = useMemo(
    () => inputValue.split('::'),
    [inputValue],
  );

  const { data: teams, isLoading: isLoadingTeams } = useMSTeamsTeams();
  const { data: channels, isLoading: isLoadingChannels } = useMSTeamsTeamChannels(selectedTeam);

  const selectedTeamOption = useMemo(() => teams?.value.find((team) => team.id === selectedTeam), [teams, selectedTeam]);
  const selectedChannelOption = useMemo(() => channels?.value.find((channel) => channel.id === selectedChannel), [channels, selectedChannel]);

  // Common autocomplete props
  const commonAutocompleteProps = useMemo(() => ({
    getOptionKey: (option: MSTeamsItemBase) => option.id,
    getOptionLabel: (option: MSTeamsItemBase) => option.displayName,
    isSingleValue: true,
  }), []);

  const { t } = useTranslation();

  return (
    <>
      <JitAutoCompleteForm
        {...commonAutocompleteProps}
        disabled={isLoadingTeams}
        isLoading={isLoadingTeams}
        options={teams?.value ?? []}
        placeHolder={t('notifications.msTeams.teamSelect.placeholder')}
        selectedValue={selectedTeamOption}
        setSelectedValue={(team) => {
          setInputValue(team.id);
          onChange(undefined);
        }}
      />

      <JitAutoCompleteForm
        {...commonAutocompleteProps}
        disabled={isLoadingChannels || !selectedTeam}
        isLoading={isLoadingChannels}
        options={channels?.value ?? []}
        placeHolder={t('notifications.msTeams.channelSelect.placeholder')}
        selectedValue={selectedChannelOption}
        setSelectedValue={(channel) => {
          const newInputValue: MSTeamsChannel = `${selectedTeam}::${channel.id}`;
          setInputValue(newInputValue);
          onChange(newInputValue);
        }}
      />
    </>
  );
};
