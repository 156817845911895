import { SelectedRepositories } from '../SelectedRepositories/SelectedRepositories';

import styles from './RadioRepositories.module.scss';

import { CircularLoadingSpinner } from 'components/CircularLoadingSpinner/CircularLoadingSpinner';
import { JitRadioButton } from 'components/JitRadioButton/JitRadioButton';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { Repository } from 'types/interfaces/SCM/SCMMember';

export interface RadioRepositoriesProps {
  isAllSelected: boolean;
  label: string;
  description: string;
  note: string;
  index: number;
  setIsAllSelected: (isAllSelected: boolean) => void;
  setShowAssetsDialog: (showAssetsDialog: boolean) => void;
  buttonText: string;
  selectedSCMResources: Repository[];
  isSelectedSCMResourcesLoading: boolean;
  isCompleted: boolean;
}

export const RadioRepositories = ({
  index,
  isAllSelected,
  label,
  description,
  note,
  setIsAllSelected,
  setShowAssetsDialog,
  buttonText,
  selectedSCMResources,
  isSelectedSCMResourcesLoading,
  isCompleted,
}: RadioRepositoriesProps) => {
  const handleClick = () => {
    if (!isCompleted) {
      setIsAllSelected(false);
    }
  };
  const handleDialogOpen = () => {
    if (!isCompleted) {
      setShowAssetsDialog(true);
    }
  };

  return (
    <div
      className={styles.buttonContainer}
      onClick={handleClick}
      role='button'
      tabIndex={index}
    >
      <JitRadioButton checked={!isAllSelected} disabled={isCompleted} />

      <div className={styles.buttonWrapper}>
        <div className={styles.radioWapper}>
          <JitText bold size='l' text={label} />

          <JitText size='l' text={note} />
        </div>

        <div style={{ display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '8px' }}
        >
          <JitText
            muted
            size='l'
            text={description}
          />

          {isSelectedSCMResourcesLoading ? <CircularLoadingSpinner size='small' variant='simple' /> : (
            <JitText
              color={colors.blue02}
              onClick={handleDialogOpen}
              size='l'
              style={{ opacity: isCompleted ? 0.5 : 1,
                cursor: isCompleted ? 'not-allowed' : 'pointer' }}
              text={buttonText}
            />
          )}
        </div>

        <SelectedRepositories
          isAllSelected={isAllSelected}
          selectedSCMResources={selectedSCMResources}
        />
      </div>
    </div>
  );
};
