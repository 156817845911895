/* eslint-disable max-nested-callbacks */
import { useCallback, useEffect } from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';

import { useWebsocketSubscribe } from 'context/WebSocketContext/hooks';
import { useWorkflowsService } from 'services/WorkflowsService/useWorkflowsService';
import { WebSocketNotificationTopics } from 'types/enums';
import { Queries } from 'types/enums/Queries';
import { IWebsocketNotification } from 'types/interfaces/WebSocket';
import { IWorkflow } from 'types/interfaces/Workflows/IWorkflow';
import { IWorkflowRun } from 'types/interfaces/Workflows/IWorkflowRun';
import { assertWebsocketNotificationEntity } from 'utils/functions/assertions/websocketNotificationEntity';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';

interface WorkflowRunsData {
  pages: Array<{
    runs: {
      data: IWorkflowRun[];
      metadata?: {
        after?: string;
      };
    };
    workflow?: IWorkflow;
  }>;
}

export const useWorkflowHistory = (workflowId: string) => {
  const { getWorkflowRuns } = useWorkflowsService();
  const { websocketSubscribe } = useWebsocketSubscribe();
  const queryClient = useQueryClient();

  const isRunAlreadyExists = useCallback((pages: WorkflowRunsData['pages'], runId: string) => {
    const hasMatchingRun = (page: WorkflowRunsData['pages'][0]) => {
      const runs = page.runs.data;
      return runs.some((run) => run.id === runId && run.workflowId === workflowId);
    };

    return pages.some(hasMatchingRun);
  }, [workflowId]);

  const {
    data: historyData,
    fetchNextPage: fetchNextHistoryPage,
    isFetchingNextPage: isFetchingNextHistoryPage,
    isLoading: isHistoryLoading,
  } = useInfiniteQuery(
    [Queries.WorkflowHistory, workflowId],
    ({ pageParam }) => getWorkflowRuns({ workflowId,
      after: pageParam }),
    {
      getNextPageParam: (lastPage) => lastPage?.runs?.metadata?.after || undefined,
    },
  );

  const processCreatedRuns = useCallback((
    createdRuns: IWorkflowRun[] | undefined,
    existingPages: WorkflowRunsData['pages'],
  ): IWorkflowRun[] => createdRuns
    ?.filter((createdRun) => (camelizeSnakeCaseKeys(createdRun) as IWorkflowRun).workflowId === workflowId
        && !isRunAlreadyExists(existingPages, createdRun.id))
    .map((run) => camelizeSnakeCaseKeys(run) as IWorkflowRun) || [], [workflowId, isRunAlreadyExists]);

  const handleWorkflowRunStatusChange = useCallback((notification: IWebsocketNotification<IWorkflowRun>) => {
    queryClient.setQueryData(
      [Queries.WorkflowHistory, workflowId],
      (oldData: WorkflowRunsData | undefined): WorkflowRunsData => {
        if (!oldData?.pages) {
          return {
            pages: [{
              runs: {
                data: [],
                metadata: { after: undefined },
              },
            }],
          };
        }

        assertWebsocketNotificationEntity(notification);

        return {
          ...oldData,
          pages: oldData.pages.map((page, pageIndex) => ({
            ...page,
            runs: {
              ...page.runs,
              data: [
                ...(pageIndex === 0
                  ? processCreatedRuns(notification.message.created, oldData.pages)
                  : []),
                ...(page.runs.data.map((run: IWorkflowRun) => {
                  const updatedRun = notification.message.updated?.find(
                    (updated) => updated.id === run.id,
                  );
                  return updatedRun
                    ? (camelizeSnakeCaseKeys(updatedRun) as IWorkflowRun)
                    : run;
                }) || []),
              ],
            },
          })),
        };
      },
    );
  }, [queryClient, workflowId, processCreatedRuns]);

  // Subscribe to websocket notifications
  useEffect(() => {
    websocketSubscribe(
      WebSocketNotificationTopics.WorkflowRunStatusChange,
      handleWorkflowRunStatusChange,
    );
  }, [handleWorkflowRunStatusChange, websocketSubscribe]);

  const allRuns = historyData?.pages.flatMap((page) => page?.runs?.data || []) || [];
  const workflow = historyData?.pages[0]?.workflow;

  return {
    runs: allRuns,
    workflow,
    fetchNextPage: fetchNextHistoryPage,
    isFetchingNextPage: isFetchingNextHistoryPage,
    isLoading: isHistoryLoading,
  };
};
