import { useQuery } from 'react-query';
import { useAegisService } from 'services/AegisService/useAegisService';
import { useJittoContext } from 'context/JittoContext';
import { DrilldownResponse } from 'types/interfaces/Drilldown/types';
import { ChatResponse } from 'types/interfaces/Aegis/types';
import { config } from 'config';
import { mockAwsS3BucketsDrilldown } from 'context/JittoContext/mockData';
import { useMockModeState } from 'utils/hooks/useMockModeState';

// Query key factory for drilldown data
const drilldownKeys = {
  all: ['drilldown'] as const,
  item: (itemId: string | number | null) => 
    [...drilldownKeys.all, itemId] as const,
};

// Helper to find the report item by ID
const findReportItemById = (id: string | number | null, reportData: any) => {
  if (!id || !reportData) return null;
  
  // Basic implementation - search recursively through the report data
  const search = (data: any): any => {
    if (!data) return null;
    
    if (data.id === id) {
      return data;
    }
    
    if (Array.isArray(data)) {
      for (const item of data) {
        const result = search(item);
        if (result) return result;
      }
    } else if (typeof data === 'object') {
      for (const key in data) {
        const result = search(data[key]);
        if (result) return result;
      }
    }
    
    return null;
  };
  
  return search(reportData);
};

interface UseItemDrilldownOptions {
  enabled?: boolean;
}

export const useItemDrilldown = (options?: UseItemDrilldownOptions) => {
  const { currentDrilldownItemId, currentConversation } = useJittoContext();
  const { chat } = useAegisService();
  const { getMockModeState } = useMockModeState();
  // Check if we should use mock mode
  const useMockMode = getMockModeState();
  
  console.log(`Jitto is running in ${useMockMode ? 'MOCK' : 'REAL API'} mode`);
  
  // Find the report item for the query
  const reportItem = currentConversation?.report?.data 
    ? findReportItemById(currentDrilldownItemId, currentConversation.report.data)
    : null;

  // Define the query
  const result = useQuery<DrilldownResponse, Error>(
    // Query key
    drilldownKeys.item(currentDrilldownItemId),
    // Query function
    async () => {
      if (!currentConversation || !currentDrilldownItemId || !reportItem) {
        throw new Error('Missing data required for drilldown');
      }
      
      // Use mock data if in mock mode
      if (useMockMode) {
        console.log('Using mock drilldown data');
        await new Promise((resolve) => setTimeout(resolve, 2000));
        return mockAwsS3BucketsDrilldown;
      }
      
      // Call the chat API with drill-down context
      const response = await chat({
        message: `Generate drill-down information for item ${currentDrilldownItemId}`,
        metadata: {
          conversationId: currentConversation.id,
          drilldownItem: reportItem
        }
      });
      
      if (!response || !response.data) {
        throw new Error('Empty response from API');
      }
      
      // Ensure the response matches the DrilldownResponse type
      const data = response.data as unknown as DrilldownResponse;
      
      if (!data.sections) {
        throw new Error('Invalid response format: missing sections');
      }
      
      return data;
    },
    {
      // Only enable query if we have all required data
      enabled: Boolean(
        currentDrilldownItemId && 
        currentConversation && 
        reportItem && 
        (options?.enabled !== false)
      ),
      // Disable caching
      refetchOnWindowFocus: true,
      staleTime: 0,
      cacheTime: 0,
      retry: 1, // Only retry once
    }
  );

  return {
    drilldownData: result.data || { sections: [] },
    isLoading: result.isLoading,
    error: result.error?.message || null,
    isError: result.isError,
    refetch: result.refetch,
  };
}; 