import { FC, useCallback, useState } from 'react';
import { Legend, Tooltip, BarChart, Bar, XAxis, YAxis, ResponsiveContainer, CartesianGrid, Rectangle, RectangleProps } from 'recharts';
import { BarRectangleItem } from 'recharts/types/cartesian/Bar';

import { GraphLegend } from '../components/GraphLegend/GraphLegend';
import { GraphTooltip } from '../components/GraphTooltip/GraphTooltip';
import { TooltipFormatter } from '../components/TooltipFormatter/TooltipFormatter';
import { commonAxisProps } from '../constants';
import { useGraphTooltip } from '../hooks/useGraphTooltip';

import colors from 'themes/colors.module.scss';

export type BarData = {
  name: string;
  [key: string]: number | string;
};

export type BarConfig = {
  key: keyof BarData;
  label: string;
  color: string;
};

type BarChartComponentProps = {
  data: BarData[];
  xAxisKey?: keyof BarData;
  bars: BarConfig[];
  height?: number;
  children?: React.ReactNode;
};

export const JitBarChart: FC<BarChartComponentProps> = ({
  data,
  xAxisKey = 'name',
  bars,
  height = 220,
  children,
}) => {
  const [currentValue, setCurrentValue] = useState(0);
  const [hoveredName, setHoveredName] = useState('');
  const { position, activeKey, handleMouseMove, handleMouseEnter, handleMouseLeave } = useGraphTooltip();

  const onEnter = (key: string, { name, value }: BarRectangleItem) => {
    if (!name || !value) return;

    handleMouseEnter(key);
    setCurrentValue(value as number);
    setHoveredName(name);
  };

  const onLeave = () => {
    setCurrentValue(0);
    setHoveredName('');
    handleMouseLeave();
  };

  const CustomBar: FC<RectangleProps & { barKey: string }> = useCallback(({ x, y, width, height: barHeight, fill, name, barKey }) => (
    <Rectangle
      fill={fill}
      height={barHeight}
      onMouseMove={(e) => handleMouseMove(e)}
      opacity={!hoveredName || (hoveredName === name && activeKey === barKey) ? 1 : 0.5}
      radius={2}
      width={width}
      x={x}
      y={y}
    />
  ), [activeKey, handleMouseMove, hoveredName]);

  const maxYValue = Math.max(
    ...data.map((entry) => Math.max(...bars.map((bar) => Number(entry[bar.key])))),
  );

  const yAxisWidth = 10 + String(maxYValue).length * 7.5;

  return (
    <ResponsiveContainer height={height} width='100%'>
      <BarChart
        barGap={2}
        data={data}
        margin={{ top: 20 }}
        onMouseLeave={onLeave}
      >
        <CartesianGrid stroke={colors.cardsDivider} vertical={false} />

        <XAxis
          dataKey={xAxisKey}
          {...commonAxisProps}
        />

        <YAxis
          width={yAxisWidth}
          {...commonAxisProps}
        />

        {children}

        {bars.map(({ key, label, color }) => (
          <Bar
            key={key}
            barSize={18}
            dataKey={key}
            fill={color}
            name={label}
            onMouseEnter={(e) => onEnter(label, e)}
            onMouseLeave={onLeave}
            shape={<CustomBar barKey={label} />}
          />
        ))}

        <Tooltip
          content={(
            <GraphTooltip hide={!activeKey}>
              <TooltipFormatter label={activeKey} value={currentValue} />
            </GraphTooltip>
          )}
          cursor={false}
          position={position}
          wrapperStyle={{ outline: 'none' }}
        />

        <Legend content={<GraphLegend />} layout='vertical' wrapperStyle={{ paddingTop: '15px' }} />
      </BarChart>
    </ResponsiveContainer>
  );
};

