import React, { useState, useEffect } from 'react';

export const useCalculateMaxVisibleElementsCount = (elements: React.ReactElement[], containerWidth: number): number => {
  const [visibleCount, setVisibleCount] = useState(0);

  useEffect(() => {
    if (!elements.length || !containerWidth) {
      setVisibleCount(0);
      return undefined;
    }

    // Create container
    const container = document.createElement('div');
    container.style.cssText = 'position: absolute; visibility: hidden; display: flex; flex-wrap: nowrap;';
    document.body.appendChild(container);

    // Create elements
    const elementDivs = elements.map(() => {
      const div = document.createElement('div');
      container.appendChild(div);
      return div;
    });

    // Let the browser calculate sizes
    requestAnimationFrame(() => {
      let totalWidth = 0;
      let count = 0;

      elementDivs.some((div) => {
        const { width } = div.getBoundingClientRect();
        if (totalWidth + width <= containerWidth) {
          totalWidth += width;
          count += 1;
          return false;
        }
        return true;
      });

      setVisibleCount(Math.max(1, count));

      // Cleanup
      if (container.parentNode) {
        document.body.removeChild(container);
      }
    });

    return () => {
      if (container.parentNode) {
        document.body.removeChild(container);
      }
    };
  }, [elements, containerWidth]);

  return visibleCount;
};
