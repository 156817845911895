import { OpenTicket } from '../VendorConfig';

import { generateTitle } from './title';

import { useTicketCreationWebsocketContext } from 'context/TicketCreationWebsocketContext/TicketCreationWebsocketContext';
import { IFinding, TicketFinding } from 'types/interfaces';
import { IntegrationProvider } from 'types/interfaces/Integrations/IIntegration';
import { TicketError } from 'types/interfaces/TicketManager/Ticket';

interface Props {
  onSuccess?: (ticket: TicketFinding) => void;
  onError?: (error: string) => void;
}

export const useCreateTicketAsync = ({ onSuccess, onError }: Props) => {
  const { registerTicketCreationSuccess, registerTicketCreationError } = useTicketCreationWebsocketContext();

  const createTicket = async (findings: IFinding[], integrationId: string, openTicketFunction: OpenTicket, provider: IntegrationProvider, title: string | null = null) => {
    const ticketTitle = title || generateTitle(findings);
    const response = await openTicketFunction(integrationId, ticketTitle, findings, provider);
    if (response?.status === 400) {
      registerTicketCreationError(findings.map((finding) => finding.id), (response?.data as TicketError).message);
      if (onError) {
        onError((response?.data as TicketError).message);
      }
    } else {
      registerTicketCreationSuccess(findings.map((finding) => finding.id), onSuccess, onError);
    }
  };

  return { createStory: createTicket };
};

