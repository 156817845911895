import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';

import { JitCheckbox } from 'components/JitCheckbox/JitCheckbox';
import { JitText } from 'components/JitText/JitText';
import { i18n } from 'locale/i18n';
import { IChangeInputEvent } from 'types/interfaces';

interface Props {
  isAllAssetsSelectedTabCovered: boolean;
  handleSearchAsset: (e: IChangeInputEvent) => void;
  handleToggleSelectAllAssets: (e: IChangeInputEvent, checked: boolean) => void;
  wholeSelectionCheckboxText: string;
  searchValue?: string;
  onClearSearch?: () => void;
  isSearching?: boolean;
}

export const TopBar: FC<Props> = ({
  handleSearchAsset,
  handleToggleSelectAllAssets,
  isAllAssetsSelectedTabCovered,
  wholeSelectionCheckboxText,
  searchValue = '',
  onClearSearch,
  isSearching = false,
}) => {
  const { isControlledRepoActivation } = useFlags();
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginRight: '1rem',
        marginLeft: '1rem',
        paddingTop: '1rem',
        paddingBottom: '1rem',
      }}
    >
      <TextField
        autoFocus
        fullWidth
        id='search'
        InputProps={{
          inputProps: {
            style: {
              display: 'flex',
              alignItems: 'center',
              paddingTop: '0.5rem',
              paddingBottom: '0.5rem',
            },
          },
          notched: false,
          style: {
            display: 'flex',
            alignItems: 'center',
            fontSize: '12px',
            borderRadius: '0.5rem',
            width: '80%',
          },
          startAdornment: <SearchIcon
            sx={{
              marginRight: '0.5rem',
              fontSize: '1.25rem',
              color: 'action.active',
            }}
          />,
          endAdornment: (
            <>
              {isSearching && (
                <CircularProgress
                  size={20}
                  style={{ marginRight: '0.5rem' }}
                />
              )}

              {searchValue && onClearSearch && (
                <IconButton
                  aria-label='clear search'
                  onClick={onClearSearch}
                  size='small'
                  style={{ marginRight: '0.5rem' }}
                >
                  <ClearIcon fontSize='small' />
                </IconButton>
              )}
            </>
          ),
        }}
        label=' '
        onChange={handleSearchAsset}
        placeholder={i18n.t('dialogs.assetsManagement.search.placeholder')}
        type='text'
        value={searchValue}
        variant='outlined'
      />

      <div style={{
        display: 'flex',
        alignItems: 'center',
      }}
      >
        <JitCheckbox
          checked={isAllAssetsSelectedTabCovered}
          data-testid='all-repos-checkbox'
          disabled={isControlledRepoActivation}
          onChange={handleToggleSelectAllAssets}
          style={{
            marginRight: '0.5rem',
          }}
        />

        <JitText noWrap text={wholeSelectionCheckboxText} />
      </div>
    </div>
  );
};
