import { FC } from 'react';

export const SeraIcon: FC = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="17.0793" y="2.70728" width="26.1427" height="54.2853" rx="13.0713" stroke="url(#paint0_linear_299_88936)" strokeWidth="2"/>
    <rect x="3.00781" y="42.921" width="26.1427" height="54.2853" rx="13.0713" transform="rotate(-90 3.00781 42.921)" stroke="url(#paint1_linear_299_88936)" strokeWidth="2"/>
    <rect x="1.71573" y="19.8999" width="26.1427" height="54.2853" rx="13.0713" transform="rotate(-45 1.71573 19.8999)" stroke="url(#paint2_linear_299_88936)" strokeWidth="2"/>
    <rect x="-1.41421" width="26.1427" height="54.2853" rx="13.0713" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 57.5858 18.8999)" stroke="url(#paint3_linear_299_88936)" strokeWidth="2"/>
    <defs>
      <linearGradient id="paint0_linear_299_88936" x1="27.4974" y1="1.65853" x2="31.6432" y2="65.8356" gradientUnits="userSpaceOnUse">
        <stop stopColor="#CF4F9D"/>
        <stop offset="1" stopColor="#CF4F9D" stopOpacity="0"/>
      </linearGradient>
      <linearGradient id="paint1_linear_299_88936" x1="16.0791" y1="43.921" x2="16.0791" y2="109.21" gradientUnits="userSpaceOnUse">
        <stop stopColor="#CF4F9D"/>
        <stop offset="0.498029" stopColor="#CF4F9D" stopOpacity="0.67"/>
        <stop offset="1" stopColor="#CF4F9D"/>
      </linearGradient>
      <linearGradient id="paint2_linear_299_88936" x1="11.3241" y1="98.2303" x2="20.9395" y2="23.8867" gradientUnits="userSpaceOnUse">
        <stop stopColor="#CF4F9D" stopOpacity="0"/>
        <stop offset="1" stopColor="#CF4F9D"/>
      </linearGradient>
      <linearGradient id="paint3_linear_299_88936" x1="13.2505" y1="58.2787" x2="17.7064" y2="0.586304" gradientUnits="userSpaceOnUse">
        <stop offset="0.0208333" stopColor="#CF4F9D" stopOpacity="0.2"/>
        <stop offset="1" stopColor="#CF4F9D"/>
      </linearGradient>
    </defs>
  </svg>
); 