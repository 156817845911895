import SecurityIcon from '@mui/icons-material/Security';
import { FC, ReactNode } from 'react';

import styles from './ProgressBarPanel.module.scss';

import { CircleCheck } from 'assets';
import { StyledLinearProgress } from 'components/AppLoadingBar/AppLoadingBar.styles';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

type Props = {
  title: string;
  total: number;
  completed: number;
  action?: ReactNode;
};

const titleStyle = {
  flex: 1,
  padding: '0 10px',
};

export const ProgressBarPanel: FC<Props> = ({
  title,
  total,
  completed,
  action,
}) => {
  const percentage = total === 0 ? 0 : (completed / total) * 100;
  const statusText = action
    ? 'pages.quickStart.checks.securityPlansProcess.notActivated'
    : percentage === 100
      ? 'pages.quickStart.checks.securityPlansProcess.completed.status'
      : 'pages.quickStart.checks.securityPlansProcess.scanning.status';

  return (
    <div className={styles.wrapper} data-testid='progress-bar-panel'>
      {action ? (
        <SecurityIcon className={styles.icon} fontSize='small' />
      ) : (
        <JitIcon color='transparent' data-testid='progress-bar-icon' icon={CircleCheck} />
      )}

      <JitText
        bold
        data-testid='progress-bar-panel-title'
        size='s'
        style={titleStyle}
        text={title}
      />

      <div className={styles.barWrapper} data-testid='progress-bar-wrapper'>
        {action || (
          <>
            <div className={styles.progressBar} data-testid='progress-bar'>
              <StyledLinearProgress
                data-testid='progress-bar-percentage'
                isProgressLine
                value={percentage}
                variant='determinate'
              />
            </div>

            <div className={styles.barLegend} data-testid='progress-bar-legend'>
              <JitText color={colors.gray} data-testid='progress-bar-status-text' size='xs' text={statusText} />

              {(total !== 0) && (
                <JitText
                  color={colors.gray}
                  data-testid='progress-bar-resources-text'
                  params={{
                    completed,
                    total,
                  }}
                  size='xs'
                  text='pages.quickStart.checks.securityPlansProcess.resourcesCount'
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
