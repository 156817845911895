import React, { ReactNode } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { Label, LabelProps, Button, ButtonProps } from '..';
import { useJittoContext } from 'context/JittoContext/JittoContext';
export interface ComplexCardProps {
  /**
   * Array of labels to display at the top of the card
   */
  topLabels?: Array<LabelProps>;

  /**
   * Array of labels to display at the bottom of the card after the description
   */
  bottomLabels?: Array<LabelProps>;
  
  /**
   * Title of the card
   */
  title: string;
  
  /**
   * Description text or React node
   */
  description: string;
  
  /**
   * Array of buttons to display at the bottom of the card
   */
  buttons: Array<ButtonProps>;

  /**
   * ID of the item
   */
  id: string;
}

/**
 * ComplexCard component that displays top labels, title, description, bottom labels, and buttons
 * Used for displaying actionable information cards
 */
export const ComplexCard: React.FC<ComplexCardProps> = ({
  topLabels = [],
  bottomLabels = [],
  title,
  description,
  buttons,
  id
}) => {
const {setCurrentDrilldownItemId} = useJittoContext();
  return (
    <Box
      onClick={() => setCurrentDrilldownItemId(id)}
      sx={{
        bgcolor: '#212839',
        color: 'white',
        borderRadius: '8px',
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'relative',
        width: '100%',
        cursor: 'pointer',
      }}
    >
      {/* Top Labels */}
      {topLabels.length > 0 && (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
          {topLabels.map((label, index) => (
            <Label
              key={`top-${label.text}-${index}`}
              text={label.text}
              type={label.type}
              color={label.color}
            />
          ))}
        </Box>
      )}
      
      {/* Title */}
      <Typography 
        variant="subtitle1" 
        sx={{ 
          color: 'white',
          fontWeight: 500,
          fontSize: '14px',
          mb: 0.5
        }}
      >
        {title}
      </Typography>
      
      {/* Description */}
      <Box>
          <Typography variant="body2" sx={{                 
            fontWeight: 500,
            fontSize: '14px',
            color: '#AEB9E1'
          }}>
            {description}
          </Typography>
      </Box>
      
      {/* Bottom Labels */}
      {bottomLabels.length > 0 && (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2, mb: 2 }}>
          {bottomLabels.map((label, index) => (
            <Label
              key={`bottom-${label.text}-${index}`}
              text={label.text}
              type={label.type}
              color={label.color}
            />
          ))}
        </Box>
      )}
      
      {/* Buttons */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'flex-end', 
        mt: 'auto',
        gap: 2 
      }}>
        {buttons.map((button, index) => (
          <Button
            key={`${button.text}-${index}`}
            text={button.text}
            variant={button.variant}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ComplexCard; 