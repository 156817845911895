import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import styled from '@mui/material/styles/styled';
import { JitTag } from 'components/JitTag/JitTag';

// Styled components
const StepList = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

const StepItem = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '16px',
  padding: '16px',
  backgroundColor: '#1A1E2D',
  borderRadius: '8px',
});

const StepIcon = styled(Box)({
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '12px',
  color: '#fff',
});

const StepContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  gap: '4px',
});

const StepTitleContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '8px',
});

const StepTitle = styled(Typography)({
  fontSize: '16px',
  fontWeight: 500,
  color: '#fff',
});

const StepDescription = styled(Typography)({
  fontSize: '14px',
  color: 'rgba(255, 255, 255, 0.7)',
  marginTop: '4px',
  lineHeight: 1.5,
});

const StepStatus = styled(Box)(({ status }: { status: string }) => {
  const statusColors: Record<string, string> = {
    completed: '#6EBD9C',
    failed: '#EC5B5B',
    in_progress: '#C4C4C4',
    pending: '#A9A9A9',
    waiting_for_approval: '#FFB74D',
  };
  
  return {
    fontSize: '12px',
    color: statusColors[status],
  };
});

const TagsSectionsContainer = styled(Box)({
  display: 'flex',
  marginTop: '16px',
  gap: '16px',
});

const TagsColumn = styled(Box)({
  flex: 1,
});

const TagsSectionTitle = styled(Typography)({
  fontSize: '14px',
  color: 'rgba(255, 255, 255, 0.5)',
  marginBottom: '8px',
});

const TagsContainer = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '8px',
});

// Helper function to generate deterministic color based on string
const getColorByString = (str: string) => {
  const tagColors = [
    '#6EBD9C', // Green
    '#FFB74D', // Orange
    '#64B5F6', // Blue
    '#EC5B5B', // Red
    '#9575CD', // Purple
    '#4DB6AC', // Teal
    '#F06292', // Pink
    '#FF8A65', // Coral
    '#7986CB', // Indigo
    '#4FC3F7', // Light Blue
  ];

  // Simple checksum function - sum character codes
  let checksum = 0;
  for (let i = 0; i < str.length; i++) {
    checksum += str.charCodeAt(i);
  }
  
  // Use modulo to get consistent index for the colors array
  const colorIndex = checksum % tagColors.length;
  return tagColors[colorIndex];
};

export type TaskStep = {
  id: string;
  title: string;
  status: 'completed' | 'failed' | 'in_progress' | 'pending' | 'waiting_for_approval';
  description?: string;
  tools?: string[];
  sources?: string[];
};

interface JittoTaskStepsProps {
  steps: TaskStep[];
}

export const JittoTaskSteps: FC<JittoTaskStepsProps> = ({ steps }) => {
  return (
    <StepList>
      {steps.map((step, index) => (
        <StepItem key={step.id}>
          <StepIcon>{index + 1}</StepIcon>
          <StepContent>
            <StepTitleContainer>
              <StepTitle>{step.title}</StepTitle>
              <StepStatus status={step.status}>
                {step.status === 'in_progress' 
                  ? 'In progress' 
                  : step.status === 'waiting_for_approval'
                    ? 'Waiting for user approval'
                    : step.status.charAt(0).toUpperCase() + step.status.slice(1)}
              </StepStatus>
            </StepTitleContainer>
            
            {step.description && (
              <StepDescription>{step.description}</StepDescription>
            )}
            
            {/* Render tools and sources tags side by side */}
            {((step.tools && step.tools.length > 0) || (step.sources && step.sources.length > 0)) && (
              <TagsSectionsContainer>
                {step.tools && step.tools.length > 0 && (
                  <TagsColumn>
                    <TagsSectionTitle>Tools</TagsSectionTitle>
                    <TagsContainer>
                      {step.tools.map((tool, toolIndex) => (
                        <JitTag 
                          key={`${step.id}-tool-${toolIndex}`}
                          text={tool}
                          color={getColorByString(tool)}
                          textSize="xs"
                          padding="4px 8px"
                          withBorder
                        />
                      ))}
                    </TagsContainer>
                  </TagsColumn>
                )}
                
                {step.sources && step.sources.length > 0 && (
                  <TagsColumn>
                    <TagsSectionTitle>Sources</TagsSectionTitle>
                    <TagsContainer>
                      {step.sources.map((source, sourceIndex) => (
                        <JitTag 
                          key={`${step.id}-source-${sourceIndex}`}
                          text={source}
                          color={getColorByString(source)}
                          textSize="xs"
                          padding="4px 8px"
                          withBorder
                        />
                      ))}
                    </TagsContainer>
                  </TagsColumn>
                )}
              </TagsSectionsContainer>
            )}
          </StepContent>
        </StepItem>
      ))}
    </StepList>
  );
}; 