/* eslint-disable no-nested-ternary */

import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { FC, useMemo, useState } from 'react';

import { constants } from '../../globalConstants';

import { AWSIntegrationCard } from './components/integrationCards/AWSIntegrationCard/AWSIntegrationCard';
import { GithubIntegrationCard } from './components/integrationCards/GithubIntegrationCard/GithubIntegrationCard';
import { SlackIntegrationCard } from './components/integrationCards/SlackIntegrationCard/SlackIntegrationCard';
import { IntegrationsSidebar } from './components/IntegrationsSidebar/IntegrationsSidebar';
import { NoIntegrationsFound } from './components/NoIntegrationsFound/NoIntegrationsFound';
import styles from './IntegrationsPage.module.scss';
import { CustomIntegrationType, CUSTOM_INTEGRATION_ICONS } from './utils/customIntegrations';
import {
  createFilterableCardsFromDynamicIntegrations,
  createFilterableCardsFromTemplates,
  createFilterableCardsForCustomCards,
  filterCard,
} from './utils/filterIntegrations';
import { getCategoryCounts } from './utils/getCategoryCounts';
import { getCustomCardForCategory } from './utils/getCustomCardForCategory';
import { createRenderIntegrationCardFunction } from './utils/renderIntegrationCard';
import { combineAndSortCards } from './utils/sortIntegrationCards';

import { AzureIcon, DrataIcon, GcpIcon, JiraIcon, LinearIcon, ShortcutIcon } from 'assets';
import { IntegrationsSearch } from 'components/IntegrationsSearch/IntegrationsSearch';
import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { PageTitles } from 'components/PageTitles/PageTitles';
import { useFilesContext } from 'context/FilesContext';
import { useIntegrationsContext } from 'context/IntegrationsContext';
import { useParagonAuth } from 'context/IntegrationsContext/hooks/useParagonAuth';
import { CustomIntegrationCardDefaultProps, IntegrationVendorType } from 'context/IntegrationsContext/templates/interfaces';
import { useIntegrationsTemplate } from 'context/IntegrationsContext/templates/useIntegrationTemplates';
import { useTenantContext } from 'context/TenantContext/TenantContext';
import { Vendor } from 'types/enums';
import { FileEntityType } from 'types/enums/FileEntityType';
import { useDebounce } from 'utils/hooks/useDebounce';

export const IntegrationsPage: FC = () => {
  const { isLoadingInstallation } = useTenantContext();
  const { integrations, isLoading } = useIntegrationsContext();
  const { filesMetadata } = useFilesContext();
  const { templates: integrationTemplates } = useIntegrationsTemplate(integrations);
  const { securityPlanShowPlanSoc2ByDrata } = useFlags();
  const [selectedCategory, setSelectedCategory] = useState<'all' | IntegrationVendorType>('all');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { debounceValue: debouncedSearchTerm } = useDebounce(searchTerm, 300);

  const handleCategorySelect = (category: string) => {
    setSelectedCategory(category as 'all' | IntegrationVendorType);
  };

  const handleSearchChange = (term: string) => {
    setSearchTerm(term);
  };

  const { isLoading: isLoadingParagon } = useParagonAuth();

  // Get integration file link for asCode integrations
  const integrationFileLink = useMemo(() => filesMetadata?.find((file) => file.type
  === FileEntityType.INTEGRATION_FILE
  && file.vendorResponse?.htmlUrl)?.vendorResponse?.htmlUrl, [filesMetadata]);

  // Define dynamic integrations based on integration file link
  const dynamicIntegrations = useMemo(() => {
    if (!integrationFileLink) {
      return [];
    }
    const integrationsList = [{
      vendor: Vendor.GCP,
      variant: IntegrationVendorType.cloud,
      icon: GcpIcon,
    },
    {
      vendor: Vendor.AZURE,
      variant: IntegrationVendorType.cloud,
      icon: AzureIcon,
    },
    {
      vendor: Vendor.Jira,
      variant: IntegrationVendorType.tms,
      icon: JiraIcon,
    },
    {
      vendor: Vendor.Shortcut,
      variant: IntegrationVendorType.tms,
      icon: ShortcutIcon,
    },
    {
      vendor: Vendor.LINEAR,
      variant: IntegrationVendorType.tms,
      icon: LinearIcon,
    }];

    if (securityPlanShowPlanSoc2ByDrata) {
      integrationsList.push({
        vendor: Vendor.Drata,
        variant: IntegrationVendorType.compliance,
        icon: DrataIcon,
      });
    }

    return integrationsList;
  }, [integrationFileLink, securityPlanShowPlanSoc2ByDrata]);

  // Define the custom integrations mapping
  const customIntegrationsMapping = useMemo<Record<string, FC<CustomIntegrationCardDefaultProps>>>(() => ({
    aws: AWSIntegrationCard,
    github: GithubIntegrationCard,
    slack: SlackIntegrationCard,
  }), []);

  // Create filterable cards
  const allFilterableCards = useMemo(() => {
    const templateCards = createFilterableCardsFromTemplates(integrationTemplates);
    const dynamicCards = createFilterableCardsFromDynamicIntegrations(dynamicIntegrations, integrationTemplates);
    const customCards = createFilterableCardsForCustomCards(
      Object.keys(customIntegrationsMapping) as CustomIntegrationType[],
      CUSTOM_INTEGRATION_ICONS,
    );

    return [...templateCards, ...dynamicCards, ...customCards];
  }, [integrationTemplates, dynamicIntegrations, customIntegrationsMapping]);

  // Filter cards based on search term and category
  const filteredCards = useMemo(
    () => allFilterableCards.filter(
      (card) => filterCard(card, debouncedSearchTerm) && (
        selectedCategory === 'all' || card.vendorType === selectedCategory
      ),
    ),
    [allFilterableCards, debouncedSearchTerm, selectedCategory],
  );

  // Split filtered cards by types for rendering
  const {
    customFilteredCards,
    dynamicFilteredCards,
    regularFilteredCards,
    comingSoonFilteredCards,
  } = useMemo(() => {
    const customCards = filteredCards.filter((card) => card.type === 'custom')
      .sort((a, b) => a.label.localeCompare(b.label));

    const dynamicCards = filteredCards.filter((card) => card.type === 'dynamic'
      // Don't show dynamic cards that would also show as custom cards
      && !Object.keys(customIntegrationsMapping).includes(card.vendor))
      .sort((a, b) => a.label.localeCompare(b.label));

    const templateCards = filteredCards.filter((card) => card.type === 'template'
      && card.template?.integrationType !== 'comingSoon'
      // Don't show as regular cards if they are shown as dynamic or custom
      && !dynamicCards.some((d) => d.vendor === card.vendor)
      && !customCards.some((c) => c.vendor === card.vendor))
      .sort((a, b) => a.label.localeCompare(b.label));

    const comingSoonCards = filteredCards.filter((card) => card.type === 'template'
      && card.template?.integrationType === 'comingSoon'
      // Don't show coming soon cards if they are shown as custom or dynamic
      && !Object.keys(customIntegrationsMapping).includes(card.vendor)
      && !dynamicIntegrations.some((di) => di.vendor === card.vendor))
      .sort((a, b) => a.label.localeCompare(b.label));

    return {
      customFilteredCards: customCards,
      dynamicFilteredCards: dynamicCards,
      regularFilteredCards: templateCards,
      comingSoonFilteredCards: comingSoonCards,
    };
  }, [filteredCards, customIntegrationsMapping, dynamicIntegrations]);

  // Display empty state when no cards match the search
  const showEmptyState = debouncedSearchTerm && filteredCards.length === 0;

  // Calculate filtered counts for each category
  const filteredCounts = useMemo(() => {
    // If there's no search term, use the original count calculation
    if (!debouncedSearchTerm) {
      return getCategoryCounts(integrationTemplates, dynamicIntegrations);
    }

    // If there's a search term, calculate counts based on the search term
    return getCategoryCounts(integrationTemplates, dynamicIntegrations, debouncedSearchTerm);
  }, [debouncedSearchTerm, integrationTemplates, dynamicIntegrations]);

  // Create the renderIntegrationCard function
  const renderIntegrationCard = useMemo(() => createRenderIntegrationCardFunction({
    integrations,
    isLoading,
    isLoadingParagon,
    integrationFileLink,
    customIntegrationsMapping,
    constants,
  }), [integrations, isLoading, isLoadingParagon, integrationFileLink, customIntegrationsMapping]);

  if (isLoadingInstallation) {
    return (
      <div className={styles.page}>
        <LoadingBar data-testid='loadingTestId' />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <PageTitles subtitle='pages.integrations.subtitle' title='pages.integrations.title' />

      <div className={styles.searchWrapper}>
        <IntegrationsSearch
          onSearchChange={handleSearchChange}
          searchTerm={searchTerm}
        />
      </div>

      <div className={styles.pageContent}>
        <IntegrationsSidebar
          filteredCounts={filteredCounts}
          onCategorySelect={handleCategorySelect}
          selectedCategory={selectedCategory}
        />

        <div className={styles.page}>
          {isLoadingInstallation || (isLoading && !integrations) ? (
            <LoadingBar data-testid='loadingTestId' />
          ) : showEmptyState ? (
            // Show empty state when no results match
            <NoIntegrationsFound
              onClearSearch={() => setSearchTerm('')}
              searchTerm={debouncedSearchTerm}
            />
          ) : (
            <div className={styles.integrationGroup}>
              {/* Combine and sort all non-coming-soon cards */}
              {(() => {
                // Get custom cards for all categories
                const customCards = selectedCategory === 'all'
                  ? Object.values(IntegrationVendorType)
                    .map((category) => getCustomCardForCategory(
                      category as IntegrationVendorType,
                      debouncedSearchTerm,
                      customFilteredCards,
                    ))
                    .filter(Boolean) as React.ReactElement[] // Remove null values
                  : [getCustomCardForCategory(
                    selectedCategory as IntegrationVendorType,
                    debouncedSearchTerm,
                    customFilteredCards,
                  )]
                    .filter(Boolean) as React.ReactElement[];

                // Use the utility function to combine and sort all cards
                return combineAndSortCards(
                  customCards,
                  dynamicFilteredCards,
                  regularFilteredCards,
                  renderIntegrationCard,
                );
              })()}

              {/* Coming Soon Templates - displayed after all real cards */}
              {comingSoonFilteredCards.map((card) => card.template && renderIntegrationCard(card.template))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
