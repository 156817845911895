import { FC } from 'react';

import { InactiveState } from './components/InactiveState/InactiveState';

import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { PageTitles } from 'components/PageTitles/PageTitles';
import { PremiumFeatureBanner } from 'components/PremiumFeatureBanner/PremiumFeatureBanner';
import { useSbomContext } from 'context/SbomContext/SbomContext';
import { ExportSbomReport } from 'pages/SbomPage/components/ExportSbomReport/ExportSbomReport';
import { SbomTable } from 'pages/SbomPage/components/SbomTable/SbomTable';
import styles from 'pages/SbomPage/SbomPage.module.scss';
import { SbomReportStatus } from 'types/interfaces/Sbom/SbomReportStatus';

export const SbomPage: FC = () => {
  const {
    reportStatus, isPremiumFeatureEnabled,
  } = useSbomContext();
  const isSbomInactive = reportStatus === SbomReportStatus.INACTIVE;

  if (!reportStatus) {
    return <LoadingBar />;
  }

  if (isSbomInactive) {
    return <InactiveState />;
  }
  return (
    <>
      <div className={styles.bannerWrapper}>
        <PremiumFeatureBanner featureName='SBOM' isEntitled={isPremiumFeatureEnabled} />
      </div>

      <div className={styles.wrapper}>

        <div className={styles.titleWrapper}>
          <PageTitles subtitle='pages.sbom.subtitle' title='pages.sbom.title' />

          <ExportSbomReport />
        </div>

        <SbomTable />
      </div>
    </>
  );
};
