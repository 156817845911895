import { isEmpty } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQueryParam } from 'use-query-params';

import styles from './PageContent.module.scss';

import { JitEmpty } from 'components/JitEmpty/JitEmpty';
import {
  MissingScmIntegration,
} from 'components/JitTable/components/MissingScmIntegration/MissingScmIntegration';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext/hooks/useSendAnalyticsEvent';
import { useTenantContext } from 'context/TenantContext/TenantContext';
import { i18n } from 'locale/i18n';
import { ActionCard } from 'pages/ActionsPage/components/ActionCard';
import { IAction, IConcealedAction } from 'types/interfaces';
import { buildStringArrayQueryParam } from 'utils/functions/buildStringArrayQueryParam';

interface PageContentProps {
  actions: (IAction | IConcealedAction)[];
  handleScroll: (event: React.UIEvent<HTMLDivElement, UIEvent>) => void;
}

export const PageContent: FC<PageContentProps> = ({ actions, handleScroll }) => {
  const stringArrayParam = buildStringArrayQueryParam(
    (expendedActionId: string | null | undefined) => {
      const actionsIds = actions?.map((action) => action.id);
      return (actionsIds || []).includes(expendedActionId || '');
    },
  );

  const [expendedActionIds, setExpandedActionsCard] = useQueryParam('actionId', stringArrayParam);
  const [selectedActionIds] = useQueryParam('actionId', buildStringArrayQueryParam());
  const [hasChangedExpendedActionIds, setHasChangedExpendedActionIds] = useState(false);
  const actionCardsRef = useRef<{ [actionId: string]: HTMLDivElement | null }>({});
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const { isAnyScmVendorIntegrated } = useTenantContext();

  const isActionCardExpanded = useCallback((actionId: string) => !!expendedActionIds?.includes(actionId), [expendedActionIds]);

  const handleActionExpanded = useCallback((actionId: string) => {
    setHasChangedExpendedActionIds(true);
    if (isActionCardExpanded(actionId)) {
      setExpandedActionsCard((prev = []) => prev?.filter((id) => id !== actionId));
    } else {
      setExpandedActionsCard((prev = []) => [...prev, actionId]);
    }
  }, [isActionCardExpanded, setExpandedActionsCard]);

  const scrollToActionCard = useCallback((actionId: string | null) => {
    const cardElement = actionCardsRef.current[actionId || ''];
    if (cardElement) {
      // @ts-ignore
      cardElement?.parentNode?.scroll({
        top: cardElement.offsetTop,
        behavior: 'smooth',
      });
    }
  }, [actionCardsRef]);

  const hasOpenedPageWithSelectedActionIds = !hasChangedExpendedActionIds && !isEmpty(expendedActionIds);
  const concealedActions = useMemo(() => actions.filter((action) => action.is_concealed), [actions]);
  const notConcealedActions = useMemo(() => actions.filter((action) => !action.is_concealed), [actions]);
  const selectedActionObjectsConcealed = useMemo(() => concealedActions.filter((action) => selectedActionIds?.includes(action.id)) as IConcealedAction[], [concealedActions,
    selectedActionIds]);
  const selectedActionObjectsToDisplay = useMemo(() => notConcealedActions.filter((action) => selectedActionIds?.includes(action.id)) as IAction[], [notConcealedActions,
    selectedActionIds]);

  useEffect(() => {
    // We identify this case by checking if user didn't make any change to the expended action ids and we still have expandedActionIds
    if (hasOpenedPageWithSelectedActionIds) {
      scrollToActionCard((expendedActionIds || [])[0]);
    }
  }, [expendedActionIds, hasChangedExpendedActionIds, scrollToActionCard]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if ((!isEmpty(selectedActionIds))) {
      // send analytics event for each selected action
      selectedActionObjectsConcealed.forEach((concealedAction) => {
        sendAnalyticsEvent({
          action: 'action-page-redirection',
          params: {
            actionId: concealedAction.id,
            actionTitle: concealedAction.title,
            concealedAction: 'true',
          },
        });
      });
      selectedActionObjectsToDisplay.forEach((actionToDisplay) => {
        sendAnalyticsEvent({
          action: 'action-page-redirection',
          params: {
            actionId: actionToDisplay.id,
            actionTitle: actionToDisplay.title,
            concealedAction: 'false',
          },
        });
      });
    }
    // we want to send the analytics event only once, so we don't want to trigger this effect again
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isAnyScmVendorIntegrated) {
    return (
      <MissingScmIntegration
        subtitleAction='pages.actions.missingScmIntegrationSubtitleAction'
      />
    );
  }

  if (actions.length === 0) {
    return (
      <div className={styles.jitEmptyWrapper}>
        <JitEmpty data-testid='jitEmpty' description='pages.actions.noActionsMessage' title={i18n.t('table.noData', { entityName: 'Actions' })} />
      </div>
    );
  }

  return (
    <div className={styles.actionCardsList} onScroll={handleScroll}>

      {actions.map((action, actionIndex) => (
        <ActionCard
          key={action.id}
          action={action}
          actionIndex={actionIndex}
          handleActionExpanded={!action.is_concealed ? handleActionExpanded : undefined}
          innerRef={(card: HTMLDivElement | null) => {
            actionCardsRef.current[action.id] = card;
          }}
          isConcealed={!!action.is_concealed}
          isExpanded={isActionCardExpanded(action.id)}
          occurrences={action.occurrences}
        />
      ))}
    </div>
  );
};
