import { SecurityToolType } from '../components/SecurityToolsSidebar/SecurityToolsSidebar';

import { Tag } from 'components/JitCard/JitCard';
import colors from 'themes/colors.module.scss';

// Map security tool categories to tag values and colors
const TOOL_CATEGORY_TAG_MAP: Record<SecurityToolType, { text: string; color: string }> = {
  [SecurityToolType.all]: { text: 'all',
    color: colors.primary },
  [SecurityToolType.sca]: { text: 'Software Component Analysis',
    color: colors.red },
  [SecurityToolType.compliance]: { text: 'Compliance',
    color: colors.green },
  [SecurityToolType.secretScanning]: { text: 'Secret Scanning',
    color: colors.purple },
  [SecurityToolType.sast]: { text: 'SAST',
    color: colors.blue },
  [SecurityToolType.dast]: { text: 'DAST',
    color: colors.orange },
  [SecurityToolType.container]: { text: 'Container',
    color: colors.teal },
  [SecurityToolType.cloud]: { text: 'Cloud',
    color: colors.lightBlue },
  [SecurityToolType.monitoring]: { text: 'Monitoring',
    color: colors.yellow },
};

/**
 * Get tag array for a security tool category
 * @param category - The security tool category
 * @returns Array of Tag objects for the category
 */
export const getToolCategoryTagArray = (category: SecurityToolType): Tag[] => {
  const tagInfo = TOOL_CATEGORY_TAG_MAP[category];
  return [{ text: tagInfo.text,
    color: tagInfo.color }];
};
