import { camelCase } from 'lodash';
import { useCallback } from 'react';

import { IgnoreState } from './useUpdateFindingsStatus';

import { IFinding, IIgnoreRule, IWebsocketNotification } from 'types/interfaces';
import { isWebsocketNotificationEntity } from 'utils/functions/assertions/websocketNotificationEntity';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';

interface Props {
  setIgnoreState: React.Dispatch<React.SetStateAction<IgnoreState>>;
  onIgnoreRuleSuccess: () => void;
  findingsIgnoredCount: number;
  onIgnoreRuleFailure: (reason: string) => void;
}

export interface IgnoreRulesWebsocketMessage {
  status: 'in_progress' | 'completed' | 'failed';
  ignoreRule: IIgnoreRule;
  totalAffectedCount: number;
  tenantId: string;
}

export const useHandleIgnoreRulesWebSocketNotification = ({
  setIgnoreState,
  onIgnoreRuleSuccess,
  findingsIgnoredCount,
  onIgnoreRuleFailure,
}: Props) => {
  const doesIgnoreRuleMatchFinding = (ignoreRule: IIgnoreRule, finding: IFinding) => ignoreRule.fields.every(
    (field) => finding[camelCase(field.name) as keyof IFinding] === field.value,
  );

  const onIgnoreSuccess = useCallback(
    (ignoreRule: IIgnoreRule) => {
      setIgnoreState((prevState) => {
        const newFindings = prevState.findingsWaitingForIgnoreRules.filter((finding: IFinding) => !doesIgnoreRuleMatchFinding(ignoreRule, finding));

        if (newFindings.length === 0) {
          // if we got here, it means that all findings were ignored successfully
          onIgnoreRuleSuccess();
        }

        return {
          ...prevState,
          findingsWaitingForIgnoreRules: newFindings,
        };
      });
    },
    [setIgnoreState, onIgnoreRuleSuccess],
  );

  const handleIgnoreRulesWebSocketNotification = useCallback(
    (notification: IWebsocketNotification<IgnoreRulesWebsocketMessage>) => {
      // if no findings are ignored, we don't need to handle the notification because it means that its different user triggered ignore findings
      if (findingsIgnoredCount === 0 || !isWebsocketNotificationEntity(notification)) return;

      const updates = camelizeSnakeCaseKeys(notification.message.updated) as IgnoreRulesWebsocketMessage[];

      updates.forEach((message) => {
        if (message.status === 'completed') {
          onIgnoreSuccess(message.ignoreRule);
        } else if (message.status === 'failed' && findingsIgnoredCount) {
          // in case of failure, the failureReason is always present but we add a default empty string
          setIgnoreState((prevState) => ({
            ...prevState,
            findingsWaitingForIgnoreRules: [],
          }));
          onIgnoreRuleFailure(message.ignoreRule.failureReason || '');
        }
      });
    },
    [findingsIgnoredCount, onIgnoreSuccess, onIgnoreRuleFailure, setIgnoreState],
  );

  return { handleIgnoreRulesWebSocketNotification };
};
