import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { constants } from 'globalConstants';

const { FINDINGS, risks: { BASE_ROUTE, BACKLOG } } = constants.routes;

const OLD_ROUTE = `/${FINDINGS}`;
const NEW_ROUTE = `/${BASE_ROUTE}/${BACKLOG}`;

export const useNewBacklogPageRoute = () => {
  // The old path of `/findings` is deprecated, so if someone reaches it, we redirect them to the new route
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.startsWith(OLD_ROUTE)) {
      navigate({
        pathname: NEW_ROUTE,
        search: location.search,
      });
    }
  }, [location, navigate]);
};
