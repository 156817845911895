import { FC } from 'react';
import { styled, Box, Typography } from '@mui/material';
import { 
  Area, 
  AreaChart, 
  ResponsiveContainer, 
  XAxis, 
  YAxis
} from 'recharts';
import { SeraIcon } from 'assets';

// Define the props type for the funnel widget
export interface FunnelProps {
  stages: {
    label: string;
    value: number;
    color?: string;
  }[];
  title?: string;
}

// Define chart data point type
interface ChartDataPoint {
  x: number;
  y: number;
  label?: string;
  originalValue?: number;
}

// Container for the entire widget
const FunnelContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  minHeight: '100%',
  borderRadius: '12px',
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  padding: 16,
  backgroundColor: '#212839',
}));

// Header with icon and title
const FunnelHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(3),
}));

// Title container styling
const TitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'rgba(30, 34, 52, 0.9)',
  borderRadius: '12px',
  padding: '8px 12px',
  maxWidth: 'fit-content',
}));

// Icon styling
const IconWrapper = styled(Box)(({ theme }) => ({
  width: 24,
  height: 24,
  borderRadius: '50%',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: theme.spacing(1.5),
}));

// Vertical dividers with labels for stages
interface VerticalDividerProps {
  label?: string;
  value?: number;
}

const VerticalDivider: FC<VerticalDividerProps & { sx?: any, isLast?: boolean, isFirst?: boolean }> = ({ label, value, sx, isLast, isFirst, ...rest }) => {
  return (
    <Box 
      sx={{ 
        position: 'absolute',
        width: '200px',
        height: '100%',
        top: 0,
        zIndex: 1,
        display: 'flex',
        justifyContent: 'center',
        ...sx 
      }}
      {...rest}
    >
      {isLast && (
        <div style={{
          width: '100%',
          height: '120%',
          borderRadius: '12px',
          border: '1px solid white',
          marginTop: '-10%',
        }} />
      )}
      {/* The divider line */}
      {!isFirst && (
        <Box 
          sx={{
              position: 'absolute',
              left: '0px',
              bottom: '0',
              width: '1px',
              height: '110%',
              backgroundColor: 'white',
              opacity: isLast ? 0.4 : 0.2,
          }}
        />
      )}
      {/* Label and value container */}
      {(label || value !== undefined) && (
        <Box
          sx={{
            position: 'absolute',
            left: '20px', // 16px to the left of the divider (plus accounting for divider width)
            top: '20px',
            transform: 'translateY(-40%)',
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
          }}
        >
          {value !== undefined && (
            <Typography variant="h4" fontWeight={700} color={isLast ? 'white' : 'rgba(255, 255, 255, 0.4)'}>
              {value.toLocaleString()}
            </Typography>
          )}
          {label && (
            <Typography variant="body2" fontSize={12} fontWeight={400} color={isLast ? 'white' : 'rgba(255, 255, 255, 0.4)'}>
              {label}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export const Funnel: FC<FunnelProps> = ({ 
  stages, 
  title = 'Sera analysis',
}) => {
  // Default colors if not provided
  const defaultColors = ['#4169e1', '#6495ed', '#9370db', '#b19cd9'];
  
  // Assign colors to stages if not provided
  const stagesWithColors = stages.map((stage, index) => ({
    ...stage,
    color: stage.color || defaultColors[index % defaultColors.length]
  }));
  
  // Prepare data for the area chart
  const chartData: ChartDataPoint[] = [];
  
  // Store transition points for the dividers
  const transitionPoints: number[] = [];
  
  // Add a transition point at the leftmost beginning of the chart (0%)
  transitionPoints.push(0);
  
  // Generate more points for a smoother wave pattern
  const stepsPerSegment = 30;
  
  // Calculate logarithmic heights
  const maxValue = Math.max(...stages.map(stage => stage.value));
  const minValue = Math.min(...stages.map(stage => stage.value));
  
  // Find the log base that gives us a good range for visualization
  // We want the y values to range from about 15 to 75 for good visibility
  const logBase = Math.pow(maxValue / minValue, 1 / 3);
  
  // Function to calculate the height based on value using logarithmic scale
  const getLogHeight = (value: number) => {
    // Convert to log scale and map to our desired range (15-75)
    const logValue = Math.log(value) / Math.log(logBase);
    const normalizedValue = logValue / Math.log(maxValue / Math.log(logBase));
    return 15 + normalizedValue * 60; // Map to range 15-75
  };
  
  // Create a wavy pattern with logarithmic heights
  stagesWithColors.forEach((stage, stageIndex) => {
    // Calculate starting and ending positions for this stage
    const startPosition = stageIndex * stepsPerSegment;
    const endPosition = (stageIndex + 1) * stepsPerSegment;
    
    // Calculate heights using logarithmic scale
    const currentTop = getLogHeight(stage.value);
    const nextTop = stageIndex < stagesWithColors.length - 1 
      ? getLogHeight(stagesWithColors[stageIndex + 1].value) 
      : currentTop;
    
    // Add data points for this stage
    if (stageIndex === 0) {
      // First point of the chart
      chartData.push({
        x: 0,
        y: currentTop,
        label: stage.label,
        originalValue: stage.value
      });
    }
    
    // Points for the plateau of the current stage (about 70% of the segment)
    const plateauEndX = startPosition + (stepsPerSegment * 0.7);
    
    // Add a point at the beginning of this stage (if not the first stage)
    if (stageIndex > 0) {
      chartData.push({
        x: startPosition,
        y: currentTop,
        label: stage.label,
        originalValue: stage.value
      });
    }
    
    // Add a point at the plateau end
    chartData.push({
      x: plateauEndX,
      y: currentTop,
      label: stage.label,
      originalValue: stage.value
    });
    
    // If not the last stage, add points for the curve down
    if (stageIndex < stagesWithColors.length - 1) {
      // Calculate curve control points
      const curveStart = plateauEndX;
      const curveEnd = endPosition;
      const curveMid = curveStart + ((curveEnd - curveStart) / 2);
      
      // Add transition points for all stages except the last one
      // Store the transition point as a percentage for the divider
      const transitionPercentage = (plateauEndX / (stepsPerSegment * stages.length)) * 100;
      transitionPoints.push(transitionPercentage);
      
      // Add points for smooth curve
      chartData.push({
        x: curveStart + ((curveEnd - curveStart) * 0.25),
        y: currentTop - ((currentTop - nextTop) * 0.2),
        label: stage.label,
        originalValue: stage.value
      });
      
      chartData.push({
        x: curveMid,
        y: currentTop - ((currentTop - nextTop) * 0.5),
        label: stage.label,
        originalValue: stage.value
      });
      
      chartData.push({
        x: curveEnd - ((curveEnd - curveStart) * 0.25),
        y: nextTop + ((currentTop - nextTop) * 0.2),
        label: stagesWithColors[stageIndex + 1].label,
        originalValue: stagesWithColors[stageIndex + 1].value
      });
    } 
    else {
      // For the last stage, just extend to the end
      chartData.push({
        x: endPosition,
        y: currentTop,
        label: stage.label,
        originalValue: stage.value
      });
    }
  });

  // Define the background offset value
  const backgroundOffset = 10;
  return (
    <FunnelContainer>
      <FunnelHeader>
        <TitleContainer>
          <IconWrapper>
            <SeraIcon  />
          </IconWrapper>
          <Typography variant="body2" fontWeight="500" color="white">
            {title}
          </Typography>
        </TitleContainer>
      </FunnelHeader>
      
      <Box sx={{  height: '100%', width: '100%', position: 'relative' }}>
          {/* Vertical dividers at transition points */}
          {transitionPoints.map((position, index) => (
              <VerticalDivider 
                key={`divider-${index}`} 
                sx={{ left: `${(index * (100 / (stages.length)))}%` }}
                label={stages[index]?.label}
                value={stages[index]?.value}
                isLast={index === stagesWithColors.length - 1}
                isFirst={index === 0}
              />
          ))}
          
          <Box sx={{ 
            height: '100%', 
            width: '100%', 
            borderRadius: 1,
            overflow: 'hidden',
            position: 'relative',
            zIndex: 0, // Ensure chart is below dividers
            display: 'flex',
            alignItems: 'flex-end'
          }}>
            <ResponsiveContainer width="100%" height="60%">
              <AreaChart 
                data={chartData} 
                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              >
                <defs>
                  <linearGradient id="funnelGradient" x1="0" y1="0" x2="1" y2="0">
                    {stagesWithColors.map((stage, index) => (
                      <stop 
                        key={`color-stop-${index}`} 
                        offset={`${(index / (stagesWithColors.length - 1)) * 100}%`} 
                        stopColor={stage.color} 
                      />
                    ))}
                  </linearGradient>
                  <linearGradient id="funnelBackgroundGradient" x1="0" y1="0" x2="1" y2="0">
                    {stagesWithColors.map((stage, index) => (
                      <stop 
                        key={`bg-color-stop-${index}`} 
                        offset={`${(index / (stagesWithColors.length - 1)) * 100}%`} 
                        stopColor={stage.color}
                        stopOpacity="0.2"
                      />
                    ))}
                  </linearGradient>
                </defs>
                <XAxis dataKey="x" type="number" hide domain={['dataMin', 'dataMax']} />
                <YAxis dataKey="y" type="number" hide domain={[0, 90]} /> {/* Increased domain to accommodate higher background */}
                {/* Background semi-transparent area */}
                <Area 
                  type="basis"
                  dataKey={(dataPoint) => dataPoint.y + backgroundOffset}
                  stroke="none"
                  fill="url(#funnelBackgroundGradient)"
                  isAnimationActive={false}
                  fillOpacity={1}
                />
                {/* Foreground area with main gradient */}
                <Area 
                  type="basis"
                  dataKey="y" 
                  stroke="none"
                  fill="url(#funnelGradient)" 
                  isAnimationActive={false}
                />
              </AreaChart>
            </ResponsiveContainer>
        </Box>
      </Box>
    </FunnelContainer>
  );
};

export default Funnel; 