import { useMutation } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import { 
  Message as JittoMessage, 
  Conversation as JittoConversation,
  Thought,
  ReportStatus,
  Report,
} from 'context/JittoContext';
import { ReportData } from 'context/JittoContext/ReportData';
import { useJittoContext } from 'context/JittoContext';
import { MOCK_COMPLETED_REPORT } from 'context/JittoContext/mockData/mockJittoConversations';
import { config } from 'config';
import { useAegisService } from 'services/AegisService/useAegisService';
import { 
  ChatDataType, 
  ChatData,
  isDashboard, 
  Dashboard 
} from 'types/interfaces/Aegis/types';
import { useMockModeState } from 'utils/hooks/useMockModeState';

// Define the Step type locally to match the one in JittoPage
type StepType = 'thinking' | 'task' | 'outcome';
type Step = {
  type: StepType;
  text: string;
};

// Define RISK_ASSESSMENT_STEPS locally to avoid circular dependencies
const RISK_ASSESSMENT_STEPS: Step[] = [
  { type: 'thinking', text: 'I understand that I need to assess the risks for your whole environment.' },
  { type: 'thinking', text: 'OK, this is what I need to do to find the top risks:\n 1. Collecting all the resources in your enviroment\n 2. Collect all the vulnerabilies for all the resources\n 3. Add business context\n 4. Compile risk assessment and calculate scoring\n 5. Review and adjust the results\n 6. Generate a mitigation plan\n 7. Display the results' },
  { type: 'task', text: 'Collecting resources' },
  { type: 'outcome', text: 'I found 45 resources in your environment: 13 repositories, 4 databases and 28 other cloud resources.' },
  { type: 'task', text: 'Collecting vulnerabilities' },
  { type: 'outcome', text: 'I found 120 vulnerabilities in your environment: 30 critical, 40 high, 30 medium and 20 low.' },
  { type: 'task', text: 'Adding business context' },
  { type: 'outcome', text: 'I found 3 applications that are business critical and 1 that is business important.' },
  { type: 'task', text: 'Compiling risk assessment' },
  { type: 'outcome', text: 'I found 3 critical risks, 4 high risks and 2 medium risks.' },
  { type: 'task', text: 'Reviewing and adjusting results' },
  { type: 'outcome', text: 'I reviewed and removed 1 false positive. Now I have 3 critical risks, 4 high risks and 1 medium risks.' },
  { type: 'task', text: 'Generating mitigation plan' },
  { type: 'outcome', text: 'I generated a mitigation plan for the critical risks. The plan includes 3 actions.' },
  { type: 'task', text: 'Opening 3 remediation PRs in "jit-ops" repository and associated Jira tickets' },
  { type: 'outcome', text: 'I opened 3 PRs to fix the misconfigurations. Those will need a review before merging.' },
  { type: 'task', text: 'Displaying results.' },
  { type: 'outcome', text: 'Risk assessment complete: Found 3 critical, 4 high, and 1 medium risks. Mitigation plan generated with recommended actions.' },
];

/**
 * Helper function to simulate asynchronous operations
 * @param ms - Time to wait in milliseconds
 */
const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

/**
 * Custom hook for sending messages in the Jitto conversation
 * @returns Object containing sendMessage function and isLoading state
 */
export const useSendMessage = () => {
  const { 
    addConversation, 
    addMessage,
    currentConversation,
    updateConversation,
    updateLastMessage
  } = useJittoContext();
  
  // Get Aegis service for real API mode
  const { chat } = useAegisService();
  
  // Check if we should use mock mode
  const { getMockModeState } = useMockModeState();
  const useMockMode = getMockModeState();
  
  console.log(`Jitto is running in ${useMockMode ? 'MOCK' : 'REAL API'} mode`);

  // Use mutation for handling the async process of sending a message and getting responses
  const mutation = useMutation<void, Error, string>({
    mutationFn: async (text: string) => {
      if (!text.trim()) return;
      
      // Create the user message
      const userMessage: JittoMessage = {
        id: uuidv4(),
        source: 'user',
        content: [text],
        timestamp: new Date()
      };
      
      if (useMockMode) {
        // MOCK MODE - Use existing mock implementation
        await handleMockMessage(text, userMessage);
      } else {
        // REAL API MODE - Use Aegis API
        await handleRealApiMessage(text, userMessage);
      }
    }
  });
  
  /**
   * Handle messages using mock data
   */
  const handleMockMessage = async (text: string, userMessage: JittoMessage) => {
    // Determine the conversation stage based on messages and report status
    const conversationStage = !currentConversation || currentConversation.messages.length === 0
      ? 0 // Stage 0: New conversation (first message)
      : currentConversation.report?.status === ReportStatus.Complete 
        ? 2 // Stage 2: Conversation with completed report
        : 1; // Stage 1: Ongoing conversation
    
    // Handle different conversation stages
    if (conversationStage === 0) {
      // Create a new conversation with the user message
      const newConversation: JittoConversation = {
        id: uuidv4(),
        title: text,
        messages: [userMessage],
        createdAt: new Date()
      };

      addConversation(newConversation);

      // Simulate AI response delay
      await sleep(7000);
      
      // Add Jitto's initial response
      const jittoResponse: JittoMessage = {
        id: uuidv4(),
        source: 'sera',
        content: ["Sure, I'll help you with that. But first, what is the scope of your request?"],
        timestamp: new Date()
      };
      addMessage(newConversation.id, jittoResponse);
    } else if (conversationStage === 1) {
      // Add user message to existing conversation
      addMessage(currentConversation!.id, userMessage);

      // Simulate AI initial response
      await sleep(3000);
      
      const jittoResponse: JittoMessage = {
        id: uuidv4(),
        source: 'sera',
        content: [
          "Let me review your environment to tell you what are your top risks",
        ],
        timestamp: new Date()
      };
      addMessage(currentConversation!.id, jittoResponse);

      // Convert steps to thoughts for the UI
      const thoughtProcess: Thought[] = RISK_ASSESSMENT_STEPS.map((step: Step) => ({
        type: step.type,
        text: step.text,
        status: 'completed'
      }));
      
      // Display each thought with a delay between them
      for (const thought of thoughtProcess) {
        await sleep(4000);
        updateLastMessage(currentConversation!.id, [thought]);
      }

      // Add summary message
      await sleep(3000);
      updateLastMessage(currentConversation!.id, ["Based on my analysis, here's the risk assessment for your environment"]);
      
      // Update conversation with final report
      await sleep(2000);
      if (currentConversation) {
        const updatedConversation: Partial<JittoConversation> & { id: string } = {
          id: currentConversation.id,
          report: MOCK_COMPLETED_REPORT
        };
        updateConversation(updatedConversation);
      }
    } else if (conversationStage === 2) {
      // Stage 2 - conversation with completed report
      addMessage(currentConversation!.id, userMessage);
      
      // Simulate AI response for completed reports
      await sleep(2000);
      const jittoResponse: JittoMessage = {
        id: uuidv4(),
        source: 'sera',
        content: ["I've already completed the risk assessment. Is there anything specific you'd like to know about the assessment or would you like to start a new conversation?"],
        timestamp: new Date()
      };
      addMessage(currentConversation!.id, jittoResponse);
    }
  };
  
  /**
   * Handle messages using the real Aegis API
   */
  const handleRealApiMessage = async (text: string, userMessage: JittoMessage) => {
    console.log("REAL API MODE: Sending message to Aegis API", text);
    
    // Determine if we're in a new conversation or existing one
    let conversationId: string;
    
    if (!currentConversation) {
      // Create a new conversation
      const newConversation: JittoConversation = {
        id: uuidv4(),
        title: text,
        messages: [userMessage],
        createdAt: new Date()
      };
      
      addConversation(newConversation);
      conversationId = newConversation.id;
    } else {
      // Add to existing conversation
      addMessage(currentConversation.id, userMessage);
      conversationId = currentConversation.id;
    }

    try {
      // Call the Aegis API
      const response = await chat({
        message: text,
        metadata: { 
          conversationId
        }
      });
      
      if (!response || !response.data) {
        throw new Error('Empty response from Aegis API');
      }
      
      // Update with the actual response from the API
      const jittoResponse = response.data.message;
      const jittoMessage: JittoMessage = {
        id: uuidv4(),
        source: 'sera',
        content: [jittoResponse],
        timestamp: new Date()
      };
      addMessage(conversationId, jittoMessage);
      
      // Process dashboard data if present
      if (response.data.data && response.data.data.length > 0) {
        handleDashboardData(conversationId, response.data.data);
      }
      
    } catch (error) {
      console.error('Error calling Aegis API:', error);
      const errorMessage: JittoMessage = {
        id: uuidv4(),
        source: 'sera',
        content: [`Sorry, I encountered an error while processing your request: ${error instanceof Error ? error.message : 'Unknown error'}`],
        timestamp: new Date()
      };
      addMessage(conversationId, errorMessage);
    }
  };
  
  /**
   * Process dashboard data from API response and update conversation
   */
  const handleDashboardData = (conversationId: string, data: ChatData[]) => {
    // Find dashboard data if present
    const dashboardData = data.find(item => item.type === ChatDataType.DASHBOARD);
    
    if (dashboardData && isDashboard(dashboardData.content) && dashboardData.content.sections.length > 0) {
      console.log("Processing dashboard data for conversation:", conversationId);
      
      // Simply use dashboard as report data, assuming compatible structure
      const report: Report = {
        status: ReportStatus.Complete,
        data: dashboardData.content.sections as unknown as ReportData
      };
      
      // Update conversation with report
      const updatedConversation: Partial<JittoConversation> & { id: string } = {
        id: conversationId,
        report
      };
      
      updateConversation(updatedConversation);
    } else if (dashboardData) {
      console.error("Invalid dashboard data received:", dashboardData.content);
    }
  };
  
  return {
    /**
     * Send a message to Jitto
     * @param text - The message text to send
     */
    sendMessage: (text: string) => {
      if (text.trim()) {
        mutation.mutate(text);
      }
    },
    isLoading: mutation.isLoading
  };
};
