import { Dispatch, SetStateAction, useCallback } from 'react';

import {
  IPlanDetails,
  IPlanDetailsMap,
  ITenantPlanItem,
  IWebsocketNotification,
  PlanItemAggregatedStatus,
} from 'types/interfaces';
import { assertWebsocketNotificationEntity } from 'utils/functions/assertions/websocketNotificationEntity';

interface Props {
  setPlans: Dispatch<SetStateAction<IPlanDetailsMap>>,
}

export const useHandlePlanItemWebsocketNotification = ({ setPlans }: Props) => {
  const updateItemDetailsInPlan = (plan: IPlanDetails, item: ITenantPlanItem, isDeleted: boolean = false) => {
    // eslint-disable-next-line no-param-reassign
    plan.items = plan.items?.[item.slug] ? {
      ...plan.items,
      [item.slug]: {
        ...plan.items[item.slug],
        ...item,
        status: isDeleted ? undefined : item.status_properties?.status,
        last_evaluated: isDeleted ? undefined : item.status_properties?.modified_at,
        findings_count: isDeleted ? undefined : item.status_properties?.findings_count,
        is_active: isDeleted ? false : item.is_active,
      },
    } : plan.items;
  };

  const deleteItemFromPlan = useCallback((plan: IPlanDetails, item: ITenantPlanItem) => {
    plan.activeItemSlugs.delete(item.slug);
    plan.passedItemSlugs.delete(item.slug);
    plan.failedItemSlugs.delete(item.slug);
    updateItemDetailsInPlan(plan, item, true);
  }, []);

  const upsertItemInPlan = useCallback((plan: IPlanDetails, item: ITenantPlanItem) => {
    if (item.is_active) {
      plan.activeItemSlugs.add(item.slug);
      if (item.status_properties?.status === PlanItemAggregatedStatus.SUCCESS) {
        plan.passedItemSlugs.add(item.slug);
        plan.failedItemSlugs.delete(item.slug);
      } else if (item.status_properties?.status === PlanItemAggregatedStatus.FAILURE) {
        plan.passedItemSlugs.delete(item.slug);
        plan.failedItemSlugs.add(item.slug);
      }
      updateItemDetailsInPlan(plan, item);
    } else {
      deleteItemFromPlan(plan, item);
    }
  }, [deleteItemFromPlan]);

  const handlePlanItemWebSocketNotification = useCallback((notification: IWebsocketNotification<ITenantPlanItem>) => {
    assertWebsocketNotificationEntity(notification);
    const { created, updated, deleted } = notification.message;

    setPlans((plans) => {
      const modifiedPlans = { ...plans };

      (created || []).concat(updated || []).forEach((item) => {
        Object.values(modifiedPlans).forEach((plan) => {
          if (plan.allItemSlugs.has(item.slug)) {
            upsertItemInPlan(plan, item);
          }
        });
      });

      (deleted || []).forEach((item) => {
        Object.values(modifiedPlans).forEach((plan) => {
          if (plan.allItemSlugs.has(item.slug)) {
            deleteItemFromPlan(plan, item);
          }
        });
      });

      return modifiedPlans;
    });
  }, [setPlans, upsertItemInPlan, deleteItemFromPlan]);
  return { handlePlanItemWebSocketNotification };
};
