import { FC, KeyboardEvent, ReactNode } from 'react';

import styles from './IconButtonsGrid.module.scss';

import { DisabledElement } from 'components/DisabledElement/DisabledElement';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { LoadingSkeleton } from 'components/LoadingSkeleton/LoadingSkeleton';
import { ISvg } from 'types/interfaces';

const SKELETON_COUNT = 2;

export interface IconButtonItem {
  key: string;
  icon?: FC<ISvg> | string;
  onClick: () => void;
  isDisabled: boolean;
  isSelected: boolean;
  text: string;
  subText?: ReactNode
}

interface IconButtonsGridProps {
  items: IconButtonItem[];
  isLoading?: boolean;
}

export const IconButtonsGrid: FC<IconButtonsGridProps> = ({ items, isLoading = false }) => {
  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>, onClick: () => void) => {
    if (e.key === 'Enter' || e.key === ' ') {
      onClick();
    }
  };

  return (
    <div className={styles.root}>
      {!isLoading ? (
        items.map(({ key, icon, onClick, isDisabled, isSelected, text, subText }) => (
          <DisabledElement key={key} isDisabled={isDisabled}>
            <div
              className={isSelected ? styles.selectedButton : styles.button}
              data-testid={`icon-button-${key}`}
              onClick={() => !isSelected && onClick()}
              onKeyDown={(e) => handleKeyDown(e, onClick)}
              role='button'
              tabIndex={0}
            >
              {typeof icon === 'string'
                ? <img alt={text} className={styles.icon} src={icon} />
                : <JitIcon className={styles.icon} icon={icon} size={50} />}

              <JitText text={text} />

              {subText}
            </div>
          </DisabledElement>
        ))
      ) : (
        <>
          {Array(SKELETON_COUNT).fill(null).map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className={styles.skeletonWrapper}>
              <LoadingSkeleton className={styles.skeleton} />
            </div>
          ))}
        </>
      )}
    </div>
  );
};
