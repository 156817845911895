import React from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Label, LabelProps } from './Label';
import { Button, ButtonProps } from './Button';

// Define the types of cells we can render
export type CellContent = 
  | string 
  | { type: 'label'; props: LabelProps } 
  | { type: 'button'; props: ButtonProps };


export interface SimpleTableProps {
  columns: string[];
  rows: CellContent[][];
}

const CellRenderer: React.FC<{ content: CellContent }> = ({ content }) => {
  if (typeof content === 'string') {
    return <Typography variant="body2">{content}</Typography>;
  }
  
  // If content is a label configuration
  if (content.type === 'label') {
    return <Label {...content.props} />;
  }
  
  // If content is a button configuration
  if (content.type === 'button') {
    return <Button {...content.props} />;
  }
  
  // Fallback for unexpected content
  return null;
};

/**
 * SimpleTable component for displaying tabular data with support for text, labels, and buttons
 */
export const SimpleTable: React.FC<SimpleTableProps> = ({ columns, rows }) => {
  return (
    <Box
      sx={{
        backgroundColor: '#212839',
        borderRadius: '8px',
        padding: 3,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
      >
      
      <TableContainer sx={{ flex: 1 }}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell 
                  key={index}
                  sx={{ 
                    color: 'white', 
                    fontWeight: 'bold',
                    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                    padding: '12px 16px'
                  }}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, rowIndex) => (
              <TableRow 
                key={rowIndex}
                sx={{ 
                  '&:hover': { 
                    backgroundColor: 'rgba(255, 255, 255, 0.05)'
                  },
                  height: '60px'
                }}
              >
                {columns.map((column, colIndex) => (
                  <TableCell 
                    key={colIndex}
                    sx={{ 
                      color: 'white',
                      borderBottom: rowIndex === rows.length - 1 ? 'none' : '1px solid rgba(255, 255, 255, 0.1)',
                      padding: '12px 16px'
                    }}
                  >
                    <CellRenderer content={row[colIndex]} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SimpleTable; 