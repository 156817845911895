import { useCallback, useEffect } from 'react';

import { useSendAnalyticsEvent } from 'context/AnalyticsContext/hooks/useSendAnalyticsEvent';
import { useWebsocketSubscribe } from 'context/WebSocketContext/hooks';
import { logError, logInfo } from 'services/logger/logger';
import { WebSocketNotificationTopics } from 'types/enums';
import { IWebsocketNotification } from 'types/interfaces';
import { Snapshot, SnapshotStatus } from 'types/interfaces/Metrics/Snapshots';
import { assertWebsocketNotificationEntity } from 'utils/functions/assertions/websocketNotificationEntity';

export const useSubscribeToSnapshotWebsocket = (createdSnapshot: Snapshot | undefined, setCreatedSnapshot: (newSnapshot: Snapshot) => void) => {
  const { websocketSubscribe } = useWebsocketSubscribe();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const handleSnapshotWebSocketNotification = useCallback((notification: IWebsocketNotification<Snapshot>) => {
    logInfo('Received Snapshot websocket notification', { notification });
    assertWebsocketNotificationEntity(notification);
    const { message: { updated: updatedSnapshots } } = notification;
    const updatedCreatedSnapshot = updatedSnapshots.find((snapshot: Snapshot) => snapshot.id === createdSnapshot?.id);
    if (!updatedCreatedSnapshot) {
      logInfo('Snapshot not found in updated snapshots', {
        updatedSnapshots,
        createdSnapshot,
      });
      return;
    }
    if (updatedCreatedSnapshot?.status === SnapshotStatus.completed) {
      logInfo('Updated created snapshot', { updatedCreatedSnapshot });
      sendAnalyticsEvent({ action: 'snapshot-created' });
      setCreatedSnapshot(updatedCreatedSnapshot);
    } else if (updatedCreatedSnapshot?.status === SnapshotStatus.failed) {
      logError('Snapshot creation failed', { updatedCreatedSnapshot });
      sendAnalyticsEvent({ action: 'snapshot-creation-failed' });
      // TODO: We need a UI Indication for this
    }
  }, [createdSnapshot, sendAnalyticsEvent, setCreatedSnapshot]);

  useEffect(() => {
    websocketSubscribe(WebSocketNotificationTopics.Snapshot, handleSnapshotWebSocketNotification);
  }, [handleSnapshotWebSocketNotification, websocketSubscribe]);
};
