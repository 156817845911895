import { useMemo } from 'react';

import { BaseProps, PieChartData } from '../../types';

import { getColor } from 'components/Chart/utils';
import { JitPieChart } from 'components/graphs/JitPieChart/JitPieChart';

export const PieChart = <XField extends string = string, YFields extends string = string>({ data, xField, yField, height }: PieChartData<XField, YFields> & BaseProps) => {
  const pieData = useMemo(() => data.map((item, idx) => ({
    name: item[xField],
    value: item[yField],
    color: getColor(idx),
  })), [data, xField, yField]);

  return (
    <JitPieChart
      data={pieData}
      height={height}
    />
  );
};
