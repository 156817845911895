import { FC } from 'react';

import styles from './ResourceNameCell.module.scss';

import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import { ITableCell, IAsset } from 'types/interfaces';
import { getAssetDisplayName } from 'utils/constants/assetTypeConfigurations/assetTypeConfigurations';
import { useDetailsByVendor } from 'utils/hooks/useDetailsByVendor';

export const ResourceNameCell: FC<ITableCell<IAsset>> = ({ cell: { row: { original: asset } } }) => {
  const { getIconByVendor } = useDetailsByVendor();
  const icon = getIconByVendor(asset.vendor, asset.asset_type, true);
  const text = getAssetDisplayName(asset);

  return (
    <div key={`RecourceNameCell-${text}`} className={styles.wrapper}>
      {icon && <JitIcon icon={icon} />}

      <TooltipOnlyOnOverflow tooltipText={text}>
        <JitText bold className={styles.text} muted noWrap overflowHiddenEllipsis text={text} />
      </TooltipOnlyOnOverflow>
    </div>
  );
};
