import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './NoSecurityToolsFound.module.scss';

import { JitButton } from 'components/JitButton/JitButton';

interface NoSecurityToolsFoundProps {
  searchTerm: string;
  onClearSearch: () => void;
}

export const NoSecurityToolsFound: FC<NoSecurityToolsFoundProps> = ({
  searchTerm,
  onClearSearch,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2 className={styles.title}>{t('securityToolsSection.noResults.title')}</h2>

        <p className={styles.description}>
          {t('securityToolsSection.noResults.description', { searchTerm })}
        </p>

        <JitButton
          onClick={onClearSearch}
          variant='outlined'
        >
          {t('securityToolsSection.noResults.clearSearch')}
        </JitButton>
      </div>
    </div>
  );
};
