import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { getResourcesCoverageText } from '../Coverage/utils';

import { ResourceTypeCard } from './components/ResourceTypeCard/ResourceTypeCard';
import styles from './ResourcesCoverage.module.scss';

import { PercentageLineProgress } from 'components/PercentageLineProgress/PercentageLineProgress';
import { GraphLoading, GraphEmpty } from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/components';
import { MetricWithLoadingIndication } from 'types/interfaces/Metrics/Metric';
import { IResourcesCoverage } from 'types/interfaces/Metrics/SecurityImpactMetrics/ResourcesCoverage';
import { useAssetTypeToVendor } from 'utils/constants/assetTypeConfigurations/assetTypeConfigurations';

export interface ResourcesCoverageProps {
  resourcesCoverageMetric: MetricWithLoadingIndication<IResourcesCoverage>;
}

export const ResourcesCoverage: FC<ResourcesCoverageProps> = ({ resourcesCoverageMetric }) => {
  const { t } = useTranslation();
  const { getIconByAssetType } = useAssetTypeToVendor();
  const getContent = () => {
    if (resourcesCoverageMetric.isLoading) {
      return <GraphLoading />;
    }

    const resourcesDescriptionText = t('pages.securityImpact.coverage.planCoverage.description');
    const coveredResourcesDescriptionText = resourcesCoverageMetric.metric && getResourcesCoverageText(resourcesCoverageMetric.metric);

    const resourcesCoverage = resourcesCoverageMetric.metric;
    if (resourcesCoverage) {
      return (
        <div className={styles.content}>
          <PercentageLineProgress
            percentage={resourcesCoverageMetric.metric?.coveragePercentage || 0}
            subtitle={coveredResourcesDescriptionText ? `${resourcesDescriptionText} (${coveredResourcesDescriptionText}).` : 'pages.securityImpact.resources.percentageDescription'}
          />

          {resourcesCoverage.resourcesCoverage.length > 0 && (
            <div>
              {resourcesCoverage.resourcesCoverage.map((item) => (
                <ResourceTypeCard
                  key={item.assetType}
                  coveredAssets={item.covered}
                  icon={getIconByAssetType(item.assetType)}
                  title={t(`assetTypes.${item.assetType}`)}
                  uncoveredAssets={item.notCovered}
                />
              ))}
            </div>
          )}
        </div>
      );
    }

    return (
      <GraphEmpty />
    );
  };

  const content = getContent();
  return (
    <div className={styles.wrapper} data-testid='resources-coverage'>
      {content}
    </div>
  );
};
