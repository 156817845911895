/* eslint-disable react/jsx-max-depth */
import { t } from 'i18next';
import React, { useCallback, useMemo, useState } from 'react';

import styles from './ChooseSCMResources.module.scss';
import { RadioAll } from './components/common/RadioAll/RadioAll';
import { RadioRepositories, RadioRepositoriesProps } from './components/Github/RadioRepositories/RadioRepositories';
import { RadioProjects, RadioProjectsProps } from './components/Gitlab/RadioProjects/RadioProjects';
import { useSCMResourcesData } from './hooks/useSCMResourcesData';

import { GitlabIcon, GithubIcon } from 'assets';
import { IGithubIntegrationWizardStepStatus } from 'components/GithubIntegrationWizard/GithubIntegrationWizard';
import { IGitlabIntegrationWizardStepStatus } from 'components/GitlabIntegrationWizard/GitlabIntegrationWizard';
import { DialogContentWrapper } from 'components/JitDialogs/DialogContentWrapper/DialogContentWrapper';
import { JitText } from 'components/JitText/JitText';
import { CoverageSelectionDialog } from 'components/SCM/ChooseSCMResources/components/common/CoverageSelectionDialog/CoverageSelectionDialog';
import { useSCMService } from 'services/SCM/useSCMService';
import { SCMVendors, Vendor } from 'types/enums/Vendor';
import { GitlabAccessLevel } from 'types/interfaces';
import { selectedSCMResourcesCoverageType, allSCMResourcesCoverageType, Repository, Organization, SubOrganization } from 'types/interfaces/SCM/SCMMember';

interface Props<T =SCMVendors> {
  vendor: T;
  selectedOrganization?: Organization;
  status: T extends typeof Vendor.Github
    ? IGithubIntegrationWizardStepStatus
    : IGitlabIntegrationWizardStepStatus;
  markStepAsComplete?: (mode: typeof allSCMResourcesCoverageType | typeof selectedSCMResourcesCoverageType, repositories: Repository[]) => void;
  chosenSCMResources?: Repository[];
  chooseMode?: typeof allSCMResourcesCoverageType | typeof selectedSCMResourcesCoverageType | '';
  incrementStepIndex: () => void;
  selectedRole?: GitlabAccessLevel;
}

const icon = {
  [Vendor.Github]: GithubIcon,
  [Vendor.GITLAB]: GitlabIcon,
};

const allRadioComponent = {
  [Vendor.Github]: RadioAll,
  [Vendor.GITLAB]: RadioAll,
};

type RadioComponentProps = RadioRepositoriesProps | RadioProjectsProps;

const selectedRadioComponent: Record<SCMVendors, React.ComponentType<RadioComponentProps>> = {
  [Vendor.Github]: RadioRepositories as React.ComponentType<RadioComponentProps>,
  [Vendor.GITLAB]: RadioProjects as React.ComponentType<RadioComponentProps>,
};

const leftActionComponent = {
  [Vendor.Github]: undefined, // for GitHub it is temporary hidden, link will be added later
  [Vendor.GITLAB]: undefined,
};

const isAllSelectedDefault = {
  [Vendor.Github]: (chooseMode?: typeof allSCMResourcesCoverageType | typeof selectedSCMResourcesCoverageType | '') => chooseMode === allSCMResourcesCoverageType,
  [Vendor.GITLAB]: () => true,
};

export const ChooseSCMResources = <T extends SCMVendors>({
  vendor,
  selectedOrganization,
  status,
  incrementStepIndex,
  selectedRole,
  markStepAsComplete,
  chosenSCMResources = [],
  chooseMode,
}: Props<T>) => {
  const completeStep = useMemo(() => ({
    [Vendor.Github]: IGithubIntegrationWizardStepStatus.COMPLETE,
    [Vendor.GITLAB]: IGitlabIntegrationWizardStepStatus.COMPLETE,
  }), []);

  const [isAllSelected, setIsAllSelected] = useState(isAllSelectedDefault[vendor](chooseMode));
  const [isLoading, setIsLoading] = useState(false);
  const [showAssetsDialog, setShowAssetsDialog] = useState(false);

  const { updateInstallationSCMResources } = useSCMService(vendor);

  const {
    selectedSCMResources,
    setSelectedSCMResources,
    selectedSubOrganizations,
    setSelectedSubOrganizations,
    isSelectedSCMResourcesLoading,
    isRepositoriesLoading,
    isSubOrganizationsLoading,
    scmResourceOptions,
    subOrganizationOptions,
    handleInputChange,
  } = useSCMResourcesData({
    vendor,
    selectedOrganization,
    selectedRole,
    chosenSCMResources,
    chooseMode,
  });

  const handleAssetsDialogConfirm = useCallback((scmResources: Repository[], subOrganizations: SubOrganization[]) => {
    setSelectedSCMResources(scmResources);
    setSelectedSubOrganizations(subOrganizations);
    setShowAssetsDialog(false);
    setIsAllSelected(false);
  }, [setSelectedSCMResources, setSelectedSubOrganizations]);

  const onContinueButtonClick = useCallback(async () => {
    if (status === completeStep[vendor]) {
      incrementStepIndex();
      return;
    }

    setIsLoading(true);
    const reqBody = isAllSelected
      ? { scmResource_coverage_type: allSCMResourcesCoverageType }
      : {
        scmResource_coverage_type: selectedSCMResourcesCoverageType,
        scmResources: selectedSCMResources.map(({ id, name }) => ({
          scmResource_id: id,
          scmResource_name: name,
        })),
        subOrganizations: selectedOrganization ? selectedSubOrganizations.map((subOrganization) => `${selectedOrganization.path}/${subOrganization.fullPath}`) : undefined,
      };

    await updateInstallationSCMResources(selectedOrganization?.id ?? '', reqBody);
    setIsLoading(false);
    incrementStepIndex();
    if (markStepAsComplete) {
      markStepAsComplete(isAllSelected ? allSCMResourcesCoverageType : selectedSCMResourcesCoverageType, selectedSCMResources);
    }
  }, [
    vendor,
    status,
    isAllSelected,
    selectedSCMResources,
    updateInstallationSCMResources,
    selectedOrganization,
    selectedSubOrganizations,
    incrementStepIndex,
    completeStep,
    markStepAsComplete,
  ]);

  const areSCMResourcesSelected = useMemo(() => selectedSCMResources.length > 0 || selectedSubOrganizations.length > 0, [selectedSCMResources, selectedSubOrganizations]);

  const isContinueButtonDisabled = useMemo(
    () => !isAllSelected
      && selectedSCMResources.length === 0
      && selectedSubOrganizations.length === 0
      && status !== completeStep[vendor],
    [vendor, isAllSelected, selectedSCMResources.length, selectedSubOrganizations.length, status, completeStep],
  );

  return (
    <DialogContentWrapper
      actionButtons={[
        {
          label: 'ChooseSCMResources.buttonText',
          disabled: isContinueButtonDisabled,
          handleClick: onContinueButtonClick,
          isLoading,
        },
      ]}
      icon={icon[vendor]}
      leftButtonsElement={leftActionComponent[vendor]}
    >
      <div className={`${styles.dotJitTextBox} ${styles.content}`}>
        <JitText
          bold
          params={{
            name: String(t(`ChooseSCMResources.${vendor}.nomenclature.repository.plural.lowercase`)),
          }}
          size='l'
          text='ChooseSCMResources.title'
        />

        <div className={styles.radioButtonsContainer}>
          {React.createElement(selectedRadioComponent[vendor], {
            isAllSelected,
            label: t(`ChooseSCMResources.${vendor}.radioButtons.selected.label`),
            description: t(`ChooseSCMResources.${vendor}.radioButtons.selected.description`),
            note: t(`ChooseSCMResources.${vendor}.radioButtons.selected.note`),
            buttonText: t(`ChooseSCMResources.${vendor}.radioButtons.selected.buttonText`),
            index: 0,
            setIsAllSelected,
            setShowAssetsDialog,
            isCompleted: status === completeStep[vendor],
            areSCMResourcesSelected,
            selectedSCMResources,
            selectedSubOrganizations,
            isSelectedSCMResourcesLoading,
          })}

          {React.createElement(allRadioComponent[vendor], {
            isAllSelected,
            label: t(`ChooseSCMResources.${vendor}.radioButtons.all.label`),
            note: t(`ChooseSCMResources.${vendor}.radioButtons.all.note`, ''),
            description: t(`ChooseSCMResources.${vendor}.radioButtons.all.description`),
            index: 1,
            setIsAllSelected,
            isCompleted: status === completeStep[vendor],
          })}
        </div>
      </div>

      {showAssetsDialog && (
        <CoverageSelectionDialog
          alreadySelectedSCMResources={selectedSCMResources}
          alreadySelectedSubOrganizations={selectedSubOrganizations}
          handleSearch={handleInputChange}
          isLoading={isRepositoriesLoading || isSubOrganizationsLoading}
          onClose={() => setShowAssetsDialog(false)}
          onConfirm={handleAssetsDialogConfirm}
          scmResourcesResults={scmResourceOptions}
          subOrganizationsResults={subOrganizationOptions}
          vendor={vendor}
        />
      )}
    </DialogContentWrapper>
  );
};
