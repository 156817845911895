import styles from './AssetSection.module.scss';
import { ListSection } from './ListSection/ListSection';

import { CircularLoadingSpinner } from 'components/CircularLoadingSpinner/CircularLoadingSpinner';
import { JitText } from 'components/JitText/JitText';
import { SelectableAsset } from 'components/SCM/ChooseSCMResources/components/common/CoverageSelectionDialog/hooks/useAssetSelection';
import { CollapseSection } from 'pages/OverviewPage/components/CollapseSection/CollapseSection';

const getItemName = (item: SelectableAsset): string => ('fullPath' in item ? item.fullPath : item.pathWithNamespace);

interface Props {
  title: string;
  items: SelectableAsset[];
  onItemCheck: (id: string, checked: boolean) => void;
  isLoading?: boolean;
  dataTestId?: string;
}

export const AssetSection = ({ title, items, onItemCheck, isLoading, dataTestId }: Props) => (
  <CollapseSection
    header={(
      <div className={styles.headerContainer}>
        <JitText text={title} />

        {isLoading ? (
          <CircularLoadingSpinner size='small' variant='simple' />
        ) : (
          <JitText text={`(${items.length})`} />
        )}
      </div>
      )}
    testId={dataTestId}
  >
    <div className={styles.contentContainer}>
      <JitText bold className={styles.sectionHeader} muted size='xs' text='ChooseSCMResources.coverageSelectionDialog.nameHeader' />

      <ListSection getItemName={getItemName} items={items} onItemCheck={onItemCheck} />
    </div>
  </CollapseSection>
);
