import { useCallback } from 'react';

import {
  useGithubService,
  IRepository as GithubRepository,
} from 'services/GithubService/useGithubService';
import {
  useGitlabService,
  IProject as GitlabProject,
  IGroup as GitlabGroup,
  IUpdateInstallationProjects,
} from 'services/GitlabService/useGitlabService';
import { SCMVendors, Vendor } from 'types/enums/Vendor';
import { IInstallation } from 'types/interfaces';
import {
  FetchSubOrganizationsParams,
  Repository,
  SubOrganization,
  UpdateInstallationSCMResources,
  FetchSCMResourcesParams,
  FetchMembersParams,
  allSCMResourcesCoverageType,
} from 'types/interfaces/SCM/SCMMember';

export interface SCMResource {
  id: string;
  name: string;
  pathWithNamespace: string;
}

const DEFAULT_PER_PAGE_LIMIT = 20;

const githubMapper = (repositories: GithubRepository[]): SCMResource[] => repositories.map((repository) => ({
  id: repository.id,
  name: repository.name,
  pathWithNamespace: repository.fullName,
}));

const gitlabMapper = (projects: GitlabProject[]): SCMResource[] => projects.map((project) => ({
  id: project.id,
  name: project.name,
  pathWithNamespace: project.pathWithNamespace,
}));

const gitlabSubOrganizationMapper = (subOrganizations: GitlabGroup[]): SubOrganization[] => subOrganizations.map((subOrganization) => ({
  id: subOrganization.id,
  name: subOrganization.name,
  path: subOrganization.path,
  fullPath: subOrganization.fullPath,
}));

type SCMVendorService = typeof useGithubService | typeof useGitlabService;

const scmVendorServices: Record<SCMVendors, SCMVendorService> = {
  [Vendor.Github]: useGithubService,
  [Vendor.GITLAB]: useGitlabService,
};

const scmResourceMapper = {
  [Vendor.Github]: githubMapper,
  [Vendor.GITLAB]: gitlabMapper,
};

const subOrganizationMapper = {
  [Vendor.GITLAB]: gitlabSubOrganizationMapper,
};

export const useSCMService = (vendor: SCMVendors) => {
  const service = scmVendorServices[vendor]?.();

  if (!service) {
    throw new Error(`SCM vendor service not found for vendor: ${vendor}`);
  }

  const fetchSCMResources = useCallback(async (id: string, params: FetchSCMResourcesParams): Promise<Repository[] | undefined> => {
    switch (vendor) {
      case Vendor.Github: {
        const githubService = service as ReturnType<typeof useGithubService>;
        const ghParams = {
          suggestedRepos: params.suggested_repos ?? false,
          limit: params.per_page ?? DEFAULT_PER_PAGE_LIMIT,
          search: params.search,
          page: params.page,
        };
        const scmResources = await githubService.fetchSCMResources(ghParams);
        return scmResourceMapper[vendor](scmResources as GithubRepository[]);
      }
      case Vendor.GITLAB: {
        const gitlabService = service as ReturnType<typeof useGitlabService>;
        const scmResources = await gitlabService.fetchSCMResources(id, params);
        return scmResourceMapper[vendor](scmResources as GitlabProject[]);
      }
      default:
        return undefined;
    }
  }, [service, vendor]);

  const fetchSubOrganizations = useCallback(async (id: string, params: FetchSubOrganizationsParams): Promise<SubOrganization[] | undefined> => {
    switch (vendor) {
      case Vendor.Github: {
        return []; // github does not have sub organizations
      }
      case Vendor.GITLAB: {
        const gitlabService = service as ReturnType<typeof useGitlabService>;
        const glParams = {
          page: params.page,
          per_page: params.per_page,
          search: params.search,
          min_access_level: params.min_access_level,
        };
        const response = await gitlabService.fetchSubOrganizations(id, glParams);
        return subOrganizationMapper[vendor](response?.data?.groups || []);
      }
      default:
        return [];
    }
  }, [service, vendor]);

  const updateInstallationSCMResources = useCallback(async (id: string, params: UpdateInstallationSCMResources): Promise<IInstallation | undefined> => {
    switch (vendor) {
      case Vendor.Github: {
        const githubService = service as ReturnType<typeof useGithubService>;
        const ghParams = {
          allow_all_repositories: params.scmResource_coverage_type === allSCMResourcesCoverageType || undefined,
          repositories: params.scmResources?.map((repo) => ({
            id: repo.scmResource_id,
            name: repo.scmResource_name,
          })),
        };
        await githubService.updateInstallationSCMResources(ghParams);
        return undefined;
      }
      case Vendor.GITLAB: {
        const gitlabService = service as ReturnType<typeof useGitlabService>;
        const glParams: IUpdateInstallationProjects = {
          project_coverage_type: params.scmResource_coverage_type,
          projects: params.scmResources?.map((scmResource) => ({
            project_id: scmResource.scmResource_id,
            project_name: scmResource.scmResource_name,
          })),
          subgroups: params.subOrganizations,
        };
        const installation = await gitlabService.updateInstallationSCMResources(id, glParams);
        return installation?.data;
      }
      default:
        return undefined;
    }
  }, [service, vendor]);

  const fetchMembers = useCallback(async (params: FetchMembersParams) => {
    switch (vendor) {
      case Vendor.Github: {
        const githubService = service as ReturnType<typeof useGithubService>;
        const ghParams = {
          user_name: params.user_name ?? '',
          limit: params.limit,
          after: params.after,
        };
        return githubService.fetchMembers(ghParams);
      }
      case Vendor.GITLAB: {
        const gitlabService = service as ReturnType<typeof useGitlabService>;
        const glParams = {
          user_name: params.user_name ?? '',
          limit: params.limit,
          after: params.after,
        };
        return gitlabService.fetchMembers(glParams);
      }
      default:
        return undefined;
    }
  }, [service, vendor]);

  return {
    fetchSubOrganizations,
    fetchSCMResources,
    updateInstallationSCMResources,
    fetchMembers,
  };
};
