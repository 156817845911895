import React from 'react';
import { Grid, Button, Stack, Box } from '@mui/material';
import { PerformanceMetricsProps } from '../types';
import MetricCard from './MetricCard';
import { JitText } from 'components/JitText/JitText';
const PerformanceMetrics: React.FC<PerformanceMetricsProps> = ({ 
  review, 
  onHistoryClick 
}) => {
  return (
      <div
        style={{
          backgroundColor: '#121724',
          padding: '24px',
          width: '100%',
          borderRadius: '8px',
        }}
      >
        <Stack 
          direction="row" 
          justifyContent="space-between" 
          alignItems="center" 
          sx={{ mb: 2 }}
        >
          <Box sx={{ml: 1}}>
            <JitText text="Performance Review" />
          </Box>
          <Button
            variant="text"
            size="small" 
            onClick={onHistoryClick}
            sx={{ textTransform: 'none', color: '#9AA3F4' }}
          >
            Task History
          </Button>
        </Stack>

        <Grid container spacing={2}>
          {review.metrics.map((metric) => (
            <Grid item xs={12} sm={6} md={3} key={metric.id}>
              <MetricCard metric={metric} />
            </Grid>
          ))}
        </Grid>
      </div>
  );
};

export default PerformanceMetrics; 