import React, { ReactNode } from 'react';
import { Box } from '@mui/material';

export interface FlexListProps {
  /**
   * Direction of the flex container
   */
  direction: 'row' | 'column';
  
  /**
   * Content to display in the flex container
   */
  children: ReactNode;
}

/**
 * A simple container component that arranges its children in a row or column
 * using flexbox without adding any additional styling
 */
export const FlexList: React.FC<FlexListProps> = ({ 
  direction, 
  children 
}) => {
  return (
    <Box 
      sx={{ 
        display: 'flex',
        flexDirection: direction,
        gap: 2,
        flex: 1,
      }}
    >
      {children}
    </Box>
  );
};

export default FlexList; 