import { isEmpty } from 'lodash';
import { FC, PropsWithChildren, useCallback, useEffect, useMemo } from 'react';

import { FindingTableContext } from 'components/FindingsTable/FindingsTableContext/FindingsTableContext';
import { useColumnSorting } from 'components/FindingsTable/hooks/useColumnSorting';
import { useGetTableData } from 'components/FindingsTable/hooks/useGetTableData';
import { useManagePagination } from 'components/JitTable/hooks/useManagePagination';
import { useManageTable } from 'components/JitTable/hooks/useManageTable';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext/hooks/useSendAnalyticsEvent';
import { useAuthContext } from 'context/AuthContext/AuthContext';
import { useSubscribeToFindingsWebsocket } from 'context/FindingsContext/useSubscribeToFindingsWebsocket';
import { useGetTableColumns } from 'pages/FindingsPage/hooks/useGetTableColumns';
import { useSelectedFindings } from 'pages/FindingsPage/hooks/useSelectedFindings';
import { useUpdateFindingsStatus } from 'pages/FindingsPage/hooks/useUpdateFindingsStatus';
import { IFinding, TicketFinding, IServerFindingFilterOptions } from 'types/interfaces';
import { TableColumn } from 'types/types/TableColumn';
import { useQueryParams } from 'utils';
import { PERMISSIONS } from 'wrappers/RBAC/constants';

interface Props {
  filters: Partial<IServerFindingFilterOptions>;
  limit?: number;
  hasFetchedFilters?: boolean;
}

export const FindingTableProvider: FC<PropsWithChildren<Props>> = ({
  children,
  filters,
  limit,
  hasFetchedFilters,
}) => {
  const { hasPermission } = useAuthContext();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const { queryParams, setQueryParams } = useQueryParams();
  const {
    getColumn,
    toggleColumnSort,
    activeColumn,
  } = useColumnSorting();

  const {
    findings,
    fetchData,
    totalFindingsAmount,
    setFindings,
    getTotalFindingsAmount,
    hasFetchedFindings,
  } = useGetTableData({
    filters,
    activeColumn: activeColumn!,
    limit,
    hasFetchedFilters,
  });

  const selectedRowId = queryParams.id;
  const selectedRow = useMemo(() => findings.find((finding) => finding.id === selectedRowId) || null, [findings, selectedRowId]);
  const setSelectedRow = useCallback((rowId: string | null) => {
    setQueryParams((current) => {
      const nextInit = Object.fromEntries(current);
      if (rowId) {
        nextInit.id = rowId;
      } else {
        delete nextInit.id;
      }
      return nextInit;
    });
  }, [setQueryParams]);

  const updateSelectedRow = useCallback((row: IFinding | null) => {
    setSelectedRow(row?.id ?? null);
  }, [setSelectedRow]);

  const paginationManager = useManagePagination({
    data: findings as never[],
    fetchData,
  });
  const {
    findingsToIgnore,
    setFindingsToIgnore,
    updateFindingStatus,
    completeIgnoreFindings,
    isIgnoreLoading,
    updateMultipleFindingsStatus,
  } = useUpdateFindingsStatus(paginationManager.reFetchCurrentPage, findings, getTotalFindingsAmount);
  const {
    selectedFindingIds,
    isPageSelected,
    setPageSelected,
    findingsWithSelectedProperty,
    resetSelectedFindings,
  } = useSelectedFindings(findings);

  useSubscribeToFindingsWebsocket({ setFindings });

  useEffect(() => {
    paginationManager.resetPagination();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, hasFetchedFilters, activeColumn]);

  const isIgnoreRulesReadOnly = !hasPermission(PERMISSIONS.WRITE_IGNORE_RULES);

  const onSetIgnoredToSingleRow = useCallback(async (findingToUpdate: IFinding, selectedIsIgnored: boolean) => {
    await updateFindingStatus(findingToUpdate, selectedIsIgnored);
  }, [updateFindingStatus]);

  const setTicketUrlOnFindings = useCallback((findingsToUpdate: IFinding[], ticket: TicketFinding) => {
    const findingsToUpdateSet = new Set(findingsToUpdate.map(({ id }) => id));
    setFindings(findings.map((finding) => {
      if (findingsToUpdateSet.has(finding.id)) {
        return {
          ...finding,
          tickets: [...(finding.tickets || []), ticket],
        };
      }
      return finding;
    }));
  }, [findings, setFindings]);

  const onSetIsIgnoredToSelectedRows = useCallback(async (isIgnored: boolean) => {
    await updateMultipleFindingsStatus(selectedFindingIds, isIgnored);
  }, [updateMultipleFindingsStatus, selectedFindingIds]);

  const onSelectRow = useCallback((row: IFinding) => {
    const isCurrentSelectedRow = selectedRow && selectedRow.id === row?.id;
    updateSelectedRow(isCurrentSelectedRow ? null : row);

    if (!isCurrentSelectedRow) {
      sendAnalyticsEvent({
        action: 'finding-clicked',
        params: {
          finding_name: row.name,
          finding_severity: row.severity,
          security_tool: row.securityControl,
          asset_type: row.assetType,
        },
      });
    }
  }, [selectedRow, updateSelectedRow, sendAnalyticsEvent]);

  const { columns } = useGetTableColumns({
    isPageSelected,
    setPageSelected,
    updateFindingIgnoredState: onSetIgnoredToSingleRow,
    isIgnoreRulesReadOnly,
    getColumn,
    toggleColumnSort,
  });

  const tableInstance = useManageTable({
    ...paginationManager,
    columns: columns as TableColumn<object>[],
    data: findingsWithSelectedProperty,
  });

  const isIgnoreFindingsDialogOpen = useMemo(() => !isEmpty(findingsToIgnore), [findingsToIgnore]);
  const displayIgnoreByType = useMemo(() => findingsToIgnore && findingsToIgnore.length === 1, [findingsToIgnore]);
  const onDialogClose = useCallback(() => setFindingsToIgnore([]), [setFindingsToIgnore]);
  const value = useMemo(() => ({
    findings,
    setFindings,
    totalFindingsAmount,
    completeIgnoreFindings,
    isIgnoreLoading,
    resetSelectedFindings,
    onSetIsIgnoredToSelectedRows,
    onSelectRow,
    tableInstance,
    paginationManager,
    selectedRow,
    limit,
    onSetIgnoredToSingleRow,
    setTicketUrlOnFindings,
    updateSelectedRow,
    selectedFindingIds,
    findingsToIgnore,
    setFindingsToIgnore,
    displayIgnoreByType,
    onDialogClose,
    isIgnoreFindingsDialogOpen,
    hasFetchedFindings,
    selectedRowId,
  }), [
    findings,
    setFindings,
    totalFindingsAmount,
    completeIgnoreFindings,
    isIgnoreLoading,
    resetSelectedFindings,
    onSetIsIgnoredToSelectedRows,
    onSelectRow,
    tableInstance,
    paginationManager,
    selectedRow,
    limit,
    onSetIgnoredToSingleRow,
    setTicketUrlOnFindings,
    updateSelectedRow,
    selectedFindingIds,
    findingsToIgnore,
    setFindingsToIgnore,
    displayIgnoreByType,
    onDialogClose,
    isIgnoreFindingsDialogOpen,
    hasFetchedFindings,
    selectedRowId,
  ]);
  return (
    <FindingTableContext.Provider value={value}>
      {children}
    </FindingTableContext.Provider>
  );
};

