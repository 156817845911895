import { FC, useMemo } from 'react';

import { MSTeamsIconIris } from 'assets';
import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { useMSTeamsTeamChannels } from 'services/MicrosoftTeamsService/hooks';
import { ActionButtonVariant } from 'types/interfaces';
import { ICommunicationPlatformValue } from 'types/interfaces/Teams/ITeam';

interface Props {
  disabled: boolean;
  checkValue: ICommunicationPlatformValue;
  onClick: () => void;
}
export const MicrosoftTeamsActionButton: FC<Props> = ({ disabled, checkValue, onClick }) => {
  const [teamId, channelId] = useMemo(() => checkValue.channelId?.split('::') ?? [], [checkValue.channelId]);
  const { data: channels, isLoading } = useMSTeamsTeamChannels(teamId);
  const selectedChannel = useMemo(() => channels?.value?.find((channel) => channel.id === channelId), [channels, channelId]);

  return (
    <JitActionButton actionButton={{
      disabled,
      isLoading,
      label: selectedChannel?.displayName || '',
      handleClick: onClick,
      icon: MSTeamsIconIris,
      variant: ActionButtonVariant.SECONDARY,
    }}
    />
  );
};
