import React from 'react';
import { Box, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import JittoHeader from './components/JittoHeader';
import PerformanceMetrics from './components/PerformanceMetrics';
import ExpertiseSection from './components/ExpertiseSection';
import { AGENT_INFO, ACTION_BUTTONS, PERFORMANCE_REVIEW, EXPERTISE_SECTION } from './constants';
import { PageLayout } from 'components/Pages/PageLayout/PageLayout';

const JittoPage: React.FC = () => {
  const navigate = useNavigate();
  
  // Mock handlers

  const handleSlackChat = () => {
  };

  const handleDirectChat = () => {
    navigate('/chat/new');
  };

  const handleHistoryClick = () => {
    navigate('/agents/tasks?agent=sera');
  };

  const handleCustomizeExpertise = (cardId: string) => {
    navigate(`/agents/sera/skills/${cardId}`);
  };

  const handleAddExpertise = () => {
  };

  return (
    <PageLayout>
      <div style={{ height: '100vh', overflow: 'auto' }}>
        <div style={{margin: '0 40px', display: 'flex', flexDirection: 'column', gap: '40px'}}>
            <JittoHeader
              agentInfo={AGENT_INFO}
              actions={ACTION_BUTTONS}
              onSlackChat={handleSlackChat}
              onDirectChat={handleDirectChat}
            />
          
            <PerformanceMetrics
              review={PERFORMANCE_REVIEW}
              onHistoryClick={handleHistoryClick}
            />
          
            <ExpertiseSection
              expertise={EXPERTISE_SECTION}
              onCustomize={handleCustomizeExpertise}
              onAdd={handleAddExpertise}
        />
        </div>
      </div>
    </PageLayout>
  );
};

export default JittoPage;
