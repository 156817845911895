import React from 'react';

import styles from './DiscoveringState.module.scss';

import { JittyMagnifyingGlass } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';

type DiscoveringStateProps = {
  text?: string;
};

export const DiscoveringState: React.FC<DiscoveringStateProps> = ({ text = 'Discovering' }) => (
  <div className={styles.content} data-testid='discovering-state'>
    <JitIcon className={styles.waitingIcon} icon={JittyMagnifyingGlass} size={120} />

    <JitText
      className='loadingDots'
      data-testid='discovering-state-text'
      text={text}
    />
  </div>
);
