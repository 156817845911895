import { Queries } from 'types/enums/Queries';
import { ITableQueryParams } from 'types/interfaces';

export const base = [Queries.Findings];

export interface FindingsCountProps {
  params: ITableQueryParams
  backlog?: boolean | null
}
export const findingsCount = (props: FindingsCountProps) => [...base, 'count', props];

export const findingById = (id: string | undefined) => [...base, 'byId', id];
