import { logError } from 'services/logger/logger';
import { WebSocketNotificationType } from 'types/enums';
import { IWebsocketNotification, IWebsocketNotificationEntity } from 'types/interfaces/WebSocket';

export const isWebsocketNotificationEntity = <T extends object, U extends object = T>(
  notification: IWebsocketNotification<T, U>): notification is IWebsocketNotificationEntity<T> => notification.type === WebSocketNotificationType.entityUpdate;

export function assertWebsocketNotificationEntity <T extends object, U extends object = T>(
  notification: IWebsocketNotification<T, U>,
): asserts notification is IWebsocketNotificationEntity<T> {
  if (!isWebsocketNotificationEntity(notification)) {
    logError('Received system message while expecting entity update', { notification });
    // @ToDo - monitor the error messages. If they do not happen, throw the error.
    // it should throw, but we don't want to break the app
    // throw new Error('Notification is not an entity update');
  }
}
