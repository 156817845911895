import { MutableRefObject, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AssetType, Vendor, WebSocketNotificationType } from 'types/enums';
import { IntegrationStatus, IWebsocketNotification } from 'types/interfaces';
import { useSnackBar } from 'utils/hooks/useSnackBar';

interface Installation {
  vendor: AssetType,
  status: IntegrationStatus
}

const mapAssetToVendor = (asset: AssetType): Vendor | null => {
  switch (asset) {
    case AssetType.AWS_ACCOUNT:
    case AssetType.AWS_ORG:
      return Vendor.AWS;
    case AssetType.GCP_ACCOUNT:
      return Vendor.GCP;
    case AssetType.AZURE_ACCOUNT:
      return Vendor.AZURE;
    default:
      return null;
  }
};

interface Props {
  setInstallingVendor: (vendor: Vendor | null) => void,
  currentVendorRef: MutableRefObject<Vendor | null>,
}
export const useHandleInstallationWebsocketEvent = ({ setInstallingVendor, currentVendorRef }: Props) => {
  const { showSnackBar } = useSnackBar();

  const { t } = useTranslation();

  return useCallback((data: IWebsocketNotification<unknown, Installation>) => {
    if (data.type === WebSocketNotificationType.entityUpdate) {
      if (data.message.created.length) {
        setInstallingVendor(null);
      }
      return;
    }

    if (data.message.status === IntegrationStatus.CONNECTING) {
      setInstallingVendor(mapAssetToVendor(data.message.vendor));
    }
    if (data.message.status === IntegrationStatus.ERROR || data.message.status === IntegrationStatus.DISCONNECTED) {
      setInstallingVendor(null);
      if (currentVendorRef.current) {
        showSnackBar({
          type: 'error',
          title: t('toasts.cloudIntegrationError.title', { vendor: t(`vendor.${currentVendorRef.current}`) }),
          description: 'toasts.cloudIntegrationError.text',
        });
      }
    }
  }, [currentVendorRef, setInstallingVendor, showSnackBar, t]);
};
