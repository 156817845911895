import { createContext, useContext } from 'react';
import { ReportData } from './ReportData';

export interface Thought {
  type: 'thinking' | 'task' | 'outcome';
  text: string;
  status: 'inProgress' | 'completed';
}

export interface Message {
  id: string;
  source: 'sera' | 'user';
  content: (string | Thought)[];
  timestamp: Date;
}

export interface Conversation {
  id: string;
  title: string;
  messages: Message[];
  createdAt: Date;
  report?: Report;
}

// Report status enum to replace boolean flags
export enum ReportStatus {
  NotConfigured = 'NOT_CONFIGURED',
  Running = 'RUNNING',
  Complete = 'COMPLETE'
}
export interface Report {
  status: ReportStatus;
  data: ReportData;
}

export interface JittoContextType {
  // State
  conversations: Conversation[];
  currentConversation: Conversation | undefined;
  isChatOpen: boolean;
  currentDrilldownItemId: string | number | null;
  
  // Methods
  addConversation: (conversation: Conversation) => void;
  updateConversation: (conversation: Partial<Conversation> & { id: string }) => void;
  addMessage: (conversationId: string, message: Message) => void;
  setCurrentDrilldownItemId: (id: string | number | null) => void;
  updateLastMessage: (conversationId: string, additionalContent: Message['content']) => void;
}

// Create the context with a default undefined value
export const JittoContext = createContext<JittoContextType | undefined>(undefined);

// Custom hook for consuming the context
export const useJittoContext = (): JittoContextType => {
  const context = useContext(JittoContext);
  
  if (context === undefined) {
    throw new Error('useJittoContext must be used within a JittoProvider');
  }
  
  return context;
}; 