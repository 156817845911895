import Markdown from 'markdown-to-jsx';
import { FC } from 'react';
import { FindingInfoSection as FindingInfoSectionType } from 'types/interfaces/Drilldown/types';
import { JitSidePanelItem } from 'components/JitSidePanel/JitSidePanelItem/JitSidePanelItem';
import { JitText } from 'components/JitText/JitText';
import { markdownOptionsProps } from 'utils/constants/markdownOptionsProps';
import { parseMarkdown } from 'utils/functions/parseMarkdown';
import { Severity, Vendor } from 'types/enums';
import { CreateTicketButton } from 'components/CreateTicketButton';
import { JitCopyToClipboardBox } from 'components/JitCopyToClipboardBox/JitCopyToClipboardBox';
import { VulnerabilityClassBadge } from 'pages/FindingsPage/components/FindingDetails/components/VulnerabilityClassBadge/VulnerabilityClassBadge';
import { StatusBox } from 'pages/FindingsPage/components/FindingStatusCell/StatusBox';
import { SeverityBox } from 'components/SeverityBox/SeverityBox';
import { ToggleIgnoreFinding } from 'pages/FindingsPage/components/ToggleIgnoreFinding/ToggleIgnoreFinding';
import { HandleFixSuggestionButton } from 'pages/FindingsPage/components/HandleFixSuggestionButton/HandleFixSuggestionButton';
import { FixSuggestionSource, IFinding, ManualFactors } from 'types/interfaces';

import styles from './FindingInfoSection.module.scss';

interface FindingInfoSectionProps {
  section: FindingInfoSectionType;
}

// Create empty ManualFactors object
const emptyManualFactors: ManualFactors = {
  added: [],
  removed: []
};

// Mock finding for demonstration purposes that implements the IFinding interface
const mockFinding: IFinding = {
  id: 'mock-id',
  name: '',  // Will be replaced with section title
  issueText: '', // Will be replaced with section description
  lastDetectedDateTime: new Date().toISOString(),
  lastDetectedDate: new Date().toLocaleDateString(),
  date: new Date().toISOString(),
  referencesUrls: [],
  severity: Severity.Critical,
  securityControl: 'Security Control',
  controlName: 'Control Name',
  organization: 'Organization',
  repository: 'Repository',
  locationText: 'Location Text',
  vendor: Vendor.JIT,
  assetType: 'Asset Type',
  createdAt: new Date().toISOString(),
  assetId: 'asset-id',
  fingerprint: 'fingerprint',
  resolution: 'Open',
  planLayer: 'Plan Layer',
  vulnerabilityType: 'Security',
  assetName: 'Asset Name',
  ignoreRulesIds: [],
  ignored: false,
  manualFactors: emptyManualFactors,
  testId: 'test-id',
  fixSuggestion: {
    "guidelines": "Update each outdated library in your code.\n\nEnsure to thoroughly test your application after updating each library, to make sure that the update hasn't broken anything.\nIf an update does cause issues, consider whether you can modify your code to work with the updated library, or if necessary, look for an alternative library that is maintained and up to date.",
    "title": "Upgrade django to 1.2.5 (Directory traversal in Django)",
    "reason": "Outdated libraries expose known vulnerabilities to exploitation. Regular updates ensure stability and security.",
    "source": "control" as FixSuggestionSource,
    "filename": "packages/requirements.txt",
}
};

const FindingInfoSection: FC<FindingInfoSectionProps> = ({ section }) => {
  // Create a copy of the mock finding and set the properties from the section
  const finding = {
    ...mockFinding,
    name: section.title,
    issueText: section.description,
  };

  // Mock handlers that would normally come from context
  const handleCreateTicket = () => {};
  const handleSetIgnored = () => {};

  return (
    <JitSidePanelItem
      title='Finding Information'
      variant='light'
      wrapperStyleOverrides={{ borderRadius: '8px 8px 0 0' }}
    >
      <div className={styles.textsAndLabels}>
        <div className={styles.textsWrapper}>
          <div className={styles.identifiersWrapper}>
            <VulnerabilityClassBadge finding={finding} />

            <JitText bold size='l' text={section.title} />
          </div>

          <Markdown className={styles.issueText} {...markdownOptionsProps}>
            {parseMarkdown(section.description)}
          </Markdown>

        </div>

        <div className={styles.labelsWrapper}>
          <StatusBox finding={finding} resolution={finding.resolution} />
          <SeverityBox severity={finding.severity} />
        </div>
      </div>

      <div className={styles.buttonsWrapper}>
        <CreateTicketButton findings={[finding]} onClose={handleCreateTicket} />
        
        <ToggleIgnoreFinding
          isIgnored={finding.ignored}
          onSetIsIgnored={handleSetIgnored}
        />

        <HandleFixSuggestionButton finding={finding} />

        <JitCopyToClipboardBox displayText='' iconSize={14} text={section.description} variant='outlined' />
      </div>
    </JitSidePanelItem>
  );
};

export default FindingInfoSection; 