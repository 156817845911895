import { FC } from 'react';

import { ActionCardContentText } from './Components/ActionCardComponentText/ActionCardComponentText';

import { ArrowRight } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import styles from 'pages/ActionsPage/components/ActionCard/components/ActionCardContent/ActionCardContent.module.scss';
import colors from 'themes/colors.module.scss';
import { IAction, IActionFinding, IConcealedAction } from 'types/interfaces';

interface ActionCardContentProps {
  actionIndex: number
  action: IAction | IConcealedAction
  isExpanded: boolean
  notIgnoredFindings: IActionFinding[]
  isConcealed: boolean
}

export const ActionCardContent: FC<ActionCardContentProps> = ({ action, actionIndex, isExpanded, notIgnoredFindings, isConcealed }) => {
  const { status } = action;

  const concealedStyleIfConcealed = isConcealed ? styles.concealedSection : '';

  const expandIconColor = isConcealed ? colors.white : colors.iris;
  // Since all findings in an action originate from the same recipe, their source will always be the same.
  return (
    <div className={styles.outerWrapper}>
      <div className={styles.wrapper}>
        <div className={`${styles.actionNumberWrapper} ${concealedStyleIfConcealed}`}>
          <JitIcon color={expandIconColor} icon={ArrowRight} rotateDegrees={!isExpanded ? 0 : 90} />

          <JitText bold color={expandIconColor} data-testid='actionCardNumber' size='m' text={actionIndex + 1} />
        </div>

        <ActionCardContentText
          action={action}
          actionStatus={status}
          isConcealed={isConcealed}
          notIgnoredFindings={notIgnoredFindings}
        />
      </div>

    </div>
  );
};

