import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { NavButton } from '..';

import styles from './NavButtonGroup.module.scss';

import { ExpandArrow, MinimizeArrow } from 'assets';
import { ITabGroup, ITabItem, ITabSeperator, TabItemTypes } from 'components/Navbar/hooks';
import { JitText } from 'components/JitText/JitText';

interface Props {
  tabGroup: ITabGroup,
  isCollapsed: boolean,
  selectedTabName?: string,
  onTabItemClick: (tabItem: ITabItem) => void,
}

export const NavButtonGroup: FC<Props> = ({ tabGroup, isCollapsed, selectedTabName, onTabItemClick }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isSelected = useMemo(() => tabGroup.items.some((tabItem) => tabItem.name === selectedTabName), [tabGroup, selectedTabName]);

  useEffect(() => {
    if (isCollapsed && !isSelected) setIsExpanded(false);
  }, [isCollapsed, isSelected]);

  
  const renderSeperator = useCallback((tab: ITabSeperator) => (
    <div style={{display: 'flex', gap: '10px', padding: '0 20px'}}>

    <div style={{ 
      height: '1px', 
      flex: 1,
      backgroundColor: '#4A4A57', 
      margin: '10px 0'
    }} />
      {tab.name && <JitText text={tab.name} noWrap size={12} color={'gray'} />}
    <div style={{ 
      height: '1px', 
      flex: 1,
      backgroundColor: '#4A4A57', 
      margin: '10px 0'
    }} />
    </div>
  ), []);

  const renderItem = useCallback((tabItem: ITabItem) => (
    <div key={tabItem.name} className={styles.groupButton}>
      <NavButton
        icon={tabItem.icon}
        isCollapsed={isCollapsed}
        isDisabled={tabItem.isDisabled}
        isNew={tabItem.isNew}
        isSelected={tabItem.name === selectedTabName}
        onClick={() => onTabItemClick(tabItem)}
        text={tabItem.name}
      />
    </div>
  ), [isCollapsed, selectedTabName, onTabItemClick]);

  return (
    <div>
      <NavButton
        icon={tabGroup.icon}
        isCollapsed={isCollapsed}
        isDisabled={tabGroup.isDisabled}
        isNew={tabGroup.isNew}
        isSelected={isSelected && isCollapsed}
        onClick={() => setIsExpanded(!isExpanded)}
        rightSideIcon={isExpanded ? ExpandArrow : MinimizeArrow}
        text={tabGroup.name}
      />

      {!isCollapsed && isExpanded && tabGroup.items.map((tabItem) => (
        tabItem.type === TabItemTypes.item ? renderItem(tabItem) : 
        tabItem.type === TabItemTypes.seperator ? renderSeperator(tabItem as unknown as ITabSeperator) : null
      ))}
    </div>
  );
};
