import { t } from 'i18next';
import { useMemo } from 'react';

import {
  IGitlabIntegrationWizardStep, IGitlabIntegrationWizardStepStatus,
} from 'components/GitlabIntegrationWizard/GitlabIntegrationWizard';
import { ChooseSCMResources } from 'components/SCM/ChooseSCMResources/ChooseSCMResources';
import { useTenantContext } from 'context/TenantContext';
import { IGroup } from 'services/GitlabService/useGitlabService';
import { Vendor } from 'types/enums/Vendor';
import { GitlabAccessLevel, IGitlabInstallationVendorAttributes } from 'types/interfaces';

interface Props {
  selectedGroup?: IGroup;
  selectedRole: GitlabAccessLevel;
  incrementStepIndex: () => void;
}

export const useChooseGitlabProjectsStep = ({ selectedGroup, selectedRole, incrementStepIndex }: Props): IGitlabIntegrationWizardStep => {
  const { gitlabInstallation } = useTenantContext();

  const status = useMemo(() => {
    const vendorAttributes = gitlabInstallation?.vendor_attributes as IGitlabInstallationVendorAttributes;
    if (vendorAttributes?.repo_selection) return IGitlabIntegrationWizardStepStatus.COMPLETE;
    return IGitlabIntegrationWizardStepStatus.ACTIVE;
  }, [gitlabInstallation]);

  return useMemo(() => ({
    title: t('ChooseSCMResources.tabText', { name: t('ChooseSCMResources.gitlab.nomenclature.repository.plural.lowercase').toString() }).toString(),
    component: () => (
      selectedGroup ? (
        <ChooseSCMResources
          incrementStepIndex={incrementStepIndex}
          selectedOrganization={selectedGroup}
          selectedRole={selectedRole}
          status={status}
          vendor={Vendor.GITLAB}
        />
      ) : null
    ),
    status,
  }), [selectedGroup, selectedRole, status, incrementStepIndex]);
};
