import { FC, useMemo } from 'react';

import { JitText } from 'components/JitText/JitText';
import { useIntegrationsContext } from 'context/IntegrationsContext';
import { IntegrationVendorType } from 'context/IntegrationsContext/templates/interfaces';
import { useTenantContext } from 'context/TenantContext';
import { isVendorInstalled } from 'pages/IntegrationsPage/utils';
import colors from 'themes/colors.module.scss';
import { Vendor } from 'types/enums';
import { IIntegration, IntegrationStatus } from 'types/interfaces/Integrations/IIntegration';

export const SlackStatus: FC = () => {
  const { installations } = useTenantContext();
  const { integrations } = useIntegrationsContext();

  // Check for Slack using the old way (tenant context)
  const isSlackConnected = useMemo(
    () => isVendorInstalled(Vendor.SLACK, installations),
    [installations],
  );

  // Check for any collaboration platform integration - paragon
  const collaborationPlatformIntegration = useMemo(
    () => integrations?.find(
      (integration: IIntegration) => integration.display?.categoryKey === IntegrationVendorType.collaborationPlatform
        && integration.status === IntegrationStatus.SUCCESS,
    ),
    [integrations],
  );

  // Either platform is connected
  const isCollaborationPlatformConnected = useMemo(
    () => isSlackConnected || !!collaborationPlatformIntegration,
    [isSlackConnected, collaborationPlatformIntegration],
  );

  const text = useMemo(
    () => (isCollaborationPlatformConnected
      ? 'pages.quickStart.checks.statuses.integrated'
      : 'pages.quickStart.checks.statuses.notIntegrated'),
    [isCollaborationPlatformConnected],
  );

  const textColor = useMemo(
    () => (isCollaborationPlatformConnected ? colors.successGreen02 : colors.lightGray),
    [isCollaborationPlatformConnected],
  );

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <JitText
        bold
        color={textColor}
        size='m'
        text={text}
      />
    </div>
  );
};
