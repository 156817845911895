import {
  Dashboard as DashboardIcon,
  Code as CodeIcon,
  Loop as LoopIcon,
  Notifications as NotificationsIcon,
  BubbleChart as BubbleChartIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Security as SecurityIcon,
  ConfirmationNumber as ConfirmationNumberIcon,
  Cloud as CloudIcon,
  Inventory as InventoryIcon,
  GitHub as GithubIcon,
  Gavel as GavelIcon,
  BugReport as BugReportIcon,
  Storage as StorageIcon,
  Key as KeyIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './IntegrationsSidebar.module.scss';

import { IntegrationVendorType } from 'context/IntegrationsContext/templates/interfaces';

// Direct mapping between category types and icon components
const CATEGORY_ICON_MAP: Record<string, FC<{ className?: string }>> = {
  all: DashboardIcon,
  [IntegrationVendorType.sourceCodeManagement]: GithubIcon,
  [IntegrationVendorType.cloud]: CloudIcon,
  [IntegrationVendorType.cloudSecurity]: SecurityIcon,
  [IntegrationVendorType.tms]: ConfirmationNumberIcon,
  [IntegrationVendorType.collaborationPlatform]: NotificationsIcon,
  [IntegrationVendorType.cicdSecurity]: LoopIcon,
  [IntegrationVendorType.registry]: InventoryIcon,
  [IntegrationVendorType.siem]: BubbleChartIcon,
  [IntegrationVendorType.sast]: CodeIcon,
  [IntegrationVendorType.compliance]: GavelIcon,
  [IntegrationVendorType.dast]: BugReportIcon,
  [IntegrationVendorType.dataSecurity]: StorageIcon,
  [IntegrationVendorType.secretManagement]: KeyIcon,
  [IntegrationVendorType.observability]: VisibilityIcon,
};

interface IntegrationsSidebarProps {
  selectedCategory: string;
  onCategorySelect: (category: string) => void;
  filteredCounts: Record<string, number>;
}

export const IntegrationsSidebar: FC<IntegrationsSidebarProps> = ({
  selectedCategory,
  onCategorySelect,
  filteredCounts,
}) => {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(false);

  // Dynamically build categories from IntegrationVendorType enum
  const categories = [
    {
      id: 'all',
      label: t('cards.tags.all'),
    },
    ...Object.values(IntegrationVendorType).map((vendorType) => ({
      id: vendorType,
      label: t(`cards.tags.${vendorType}`),
    })),
  ];

  const sidebarTitle = 'Categories';

  // Function to render the appropriate icon component
  const renderIcon = (categoryId: string) => {
    const IconComponent = CATEGORY_ICON_MAP[categoryId];
    return IconComponent ? <IconComponent className={styles.categoryIconSvg} /> : categoryId;
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={`${styles.sidebar} ${isCollapsed ? styles.collapsed : ''}`}>
      <div className={styles.sidebarHeader}>
        {!isCollapsed && <div className={styles.sidebarTitle}>{sidebarTitle}</div>}

        <button
          aria-label={isCollapsed ? 'components.integrationsSidebar.expand' : 'components.integrationsSidebar.collapse'}
          className={styles.collapseButton}
          onClick={toggleCollapse}
          type='button'
        >
          {isCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </button>
      </div>

      <div className={styles.categoryListContainer}>
        <div className={styles.categoryList}>
          {categories.map((category) => {
            const count = filteredCounts[category.id] || 0;
            const buttonClassName = selectedCategory === category.id
              ? `${styles.categoryItem} ${styles.selected}`
              : styles.categoryItem;

            return (
              <button
                key={category.id}
                className={buttonClassName}
                data-testid={`integration-category-${category.id}`}
                onClick={() => onCategorySelect(category.id)}
                title={isCollapsed ? category.label : undefined}
                type='button'
              >
                <span className={styles.categoryIcon}>
                  {renderIcon(category.id)}
                </span>

                {!isCollapsed && (
                  <>
                    <span className={styles.categoryLabel}>{category.label}</span>

                    <span className={styles.count}>{count}</span>
                  </>
                )}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};
