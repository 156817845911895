import { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';

import styles from '../../SecurityToolsPage.module.scss';
import { SecurityTool } from '../../types/SecurityTool';
import { NoSecurityToolsFound } from '../NoSecurityToolsFound/NoSecurityToolsFound';

import { SecurityToolCard } from './SecurityToolCard/SecurityToolCard';

interface SecurityToolCardsProps {
  tools: SecurityTool[];
  searchTerm?: string;
  onClearSearch?: () => void;
}

export const SecurityToolCards: FC<SecurityToolCardsProps> = ({
  tools,
  searchTerm = '',
  onClearSearch = () => {},
}) => {
  if (tools.length === 0) {
    return (
      <NoSecurityToolsFound
        onClearSearch={onClearSearch}
        searchTerm={searchTerm}
      />
    );
  }

  return (
    <div className={styles.toolsGroup}>
      {tools.map((tool) => (
        <SecurityToolCard
          key={tool.id + uuidv4()}
          category={tool.category}
          description={tool.description}
          learnMoreUrl={tool.learnMoreUrl}
          name={tool.name}
          testId={`security-tool-${tool.id}`}
          vendor={tool.vendor}
        />
      ))}
    </div>
  );
};
