import { createContext, useContext } from 'react';

import { TicketFinding } from 'types/interfaces';

interface ITicketCreationWebsocketContext {
  registerTicketCreationSuccess: (findingIds: string[], onSuccess?: (ticketFinding: TicketFinding) => void, onError?: (reason: string) => void) => void;
  registerTicketCreationError: (findingIds: string[], reason: string) => void;
}

export const TicketCreationWebsocketContext = createContext<ITicketCreationWebsocketContext>({
  registerTicketCreationSuccess: () => {},
  registerTicketCreationError: () => {},
});

export const useTicketCreationWebsocketContext = () => useContext(TicketCreationWebsocketContext);
