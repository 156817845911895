import { useCallback, useEffect } from 'react';

import { useWebsocketSubscribe } from 'context/WebSocketContext/hooks';
import { logError, logInfo } from 'services/logger/logger';
import { WebSocketNotificationTopics } from 'types/enums';
import { GithubStatus, IGithubStatusAlert } from 'types/enums/GithubStatus';
import { IWebsocketMessageEntityUpdateMessage, IWebsocketNotification } from 'types/interfaces';

export const useSubscribeToGithubStatusWebsocket = (setGithubStatus: (status: GithubStatus) => void) => {
  const { websocketSubscribe } = useWebsocketSubscribe();

  const handleGithubStatusNotification = useCallback((notification: IWebsocketNotification<IGithubStatusAlert, IWebsocketMessageEntityUpdateMessage<IGithubStatusAlert>>) => {
    logInfo('Received Github status websocket notification', { notification });

    const { message: { created: createdStatusAlerts } } = notification;
    // We assume we got only one status alert
    const createdStatusAlert = createdStatusAlerts?.[0];
    if (createdStatusAlert) {
      const { status } = createdStatusAlert;
      setGithubStatus(status);
    } else {
      logError('No created status alert found in websocket notification', { notification });
    }
  }, [setGithubStatus]);

  useEffect(() => {
    websocketSubscribe(WebSocketNotificationTopics.GithubStatus, handleGithubStatusNotification);
  }, [handleGithubStatusNotification, websocketSubscribe]);
};
