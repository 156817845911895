import { paragon } from '@useparagon/connect';
import { FC, useCallback, useMemo } from 'react';

import { CircleFailure, WhiteDotCheck } from 'assets';
import { HeaderIcon, HeaderIcons, JitCard } from 'components/JitCard/JitCard';
import { InnerOptions, IntegrationTemplate, IntegrationType, IntegrationVendorType } from 'context/IntegrationsContext/templates/interfaces';
import { useTenantContext } from 'context/TenantContext/TenantContext';
import { useAddSecretDialog } from 'pages/IntegrationsPage/hooks/useAddSecretDialog/useAddSecretDialog';
import { getIntegrationTemplateVendor } from 'pages/IntegrationsPage/utils/getIntegrationTemplateVendor';
import { getVariantTagArray } from 'pages/IntegrationsPage/utils/variantTags';
import colors from 'themes/colors.module.scss';
import { ISvg } from 'types/interfaces';
import { ActionButton, ActionButtonVariant } from 'types/interfaces/ActionButton/ActionButton';
import { IIntegration, IntegrationProvider, IntegrationStatus } from 'types/interfaces/Integrations/IIntegration';

interface Props {
  icon: FC<ISvg> | string
  vendor?: string
  title?: string
  description?: string
  variant: IntegrationVendorType
  integrations?: IIntegration[]
  testId?: string
  integrationFileLink?: string
  isLoading?: boolean
  integrationTemplate?: IntegrationTemplate
  isActionHidden?: boolean
  isNew?: boolean
}

interface IntegrationActionHooks {
  component?: JSX.Element;
  isOpened: boolean;
  action: () => void;
  validation: (x: string | undefined) => IntegrationStatus | undefined;
}

type CustomHook = (integration?: IntegrationTemplate) => IntegrationActionHooks;

interface IntegrationActions {
  onConnect: CustomHook;
  onConfigure: CustomHook;
}

interface IntegrationTypeToActionMap {
  secret: IntegrationActions;
}

const integrationTypeToActionMap: IntegrationTypeToActionMap = {
  secret: {
    onConnect: useAddSecretDialog,
    onConfigure: useAddSecretDialog,
  },
};

const getParagonHandleClick = (relatedIntegration: IIntegration | undefined, integrationTemplate: IntegrationTemplate, integrationStatus: IntegrationStatus | undefined) => {
  let handleClick = () => paragon.connect(integrationTemplate?.key, {});
  if (relatedIntegration && relatedIntegration.display?.extras?.headlessConfig && !integrationStatus) {
    // If "headless config" is enabled, we send the user straight to the "Connect" action.
    // This happens only if the integration is not already connected, hence the !integrationStatus check.
    handleClick = async () => paragon.installIntegration(integrationTemplate?.key, {});
  }
  return handleClick;
};

export const IntegrationCard: FC<Props> = ({
  integrations,
  icon,
  vendor,
  variant,
  testId,
  integrationFileLink,
  isLoading,
  title,
  description,
  integrationTemplate,
  isActionHidden = false,
}) => {
  const {
    isScmVendorIntegrated,
    isAnyScmVendorIntegrated,
  } = useTenantContext();

  const {
    validation,
    isOpened,
    component,
    action,
  } = integrationTypeToActionMap[
    integrationTemplate?.integrationType as keyof typeof integrationTypeToActionMap]?.onConnect(integrationTemplate) || {};

  const integrationStatus = useMemo(() => {
    if (validation) {
      return validation((integrationTemplate?.options as InnerOptions).fields?.name?.value);
    }
    const integrationByStatus = integrations?.reduce((acc, integration) => {
      acc[integration.status] = acc[integration.status] ? acc[integration.status] + 1 : 1;
      return acc;
    }, {} as Record<IntegrationStatus, number>);

    if (integrationByStatus?.[IntegrationStatus.SUCCESS]) {
      return IntegrationStatus.SUCCESS;
    }
    if (integrationByStatus?.[IntegrationStatus.VALIDATING]) {
      return IntegrationStatus.VALIDATING;
    }

    if (integrationByStatus?.[IntegrationStatus.FAILURE]) {
      return IntegrationStatus.FAILURE;
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrations, validation]);

  const handleButtonClick = useCallback(() => {
    if (action) {
      action();
    } else if (integrationFileLink) {
      window.open(integrationFileLink, '_blank');
    }
  }, [action, integrationFileLink]);

  const integrationFailureReason = useMemo(() => integrations?.find(({
    status,
    reason,
  }) => status
    === IntegrationStatus.FAILURE
    && reason), [integrations]);

  const isComingSoon = integrationTemplate?.integrationType === 'comingSoon';

  const actionButtons: ActionButton[] = useMemo(() => {
    if (isActionHidden) {
      return [];
    }

    if (integrationTemplate?.integrationType === IntegrationType.securityTool) {
      return [];
    }

    // For coming soon integrations, return empty array to remove the button
    if (isComingSoon) {
      return [];
    }

    const defaultConfig = {
      tooltip: !isAnyScmVendorIntegrated ? 'cards.tooltip.requiresSCMIntegration' : undefined,
      variant: integrationStatus ? ActionButtonVariant.OUTLINED : ActionButtonVariant.PRIMARY,
      isLoading,
    };
    if (integrationTemplate?.integrationType === IntegrationType.thirdParty) {
      const relatedIntegration = integrations?.find((integration) => integration.vendor === integrationTemplate?.key);
      const handleClick = getParagonHandleClick(relatedIntegration, integrationTemplate, integrationStatus);
      return [{
        ...defaultConfig,
        tooltip: undefined,
        label: integrationStatus ? 'cards.buttons.configure' : 'cards.buttons.connect',
        handleClick,
      }];
    }
    return [{
      ...defaultConfig,
      disabled: (!integrationFileLink && !action) || isLoading || !isAnyScmVendorIntegrated,
      label: integrationStatus
        ? 'cards.buttons.configure'
        : (integrationTemplate?.options as InnerOptions)?.connect.label || 'cards.buttons.integrateAsCode',
      handleClick: handleButtonClick,
    }];
  }, [isActionHidden, integrationTemplate, isAnyScmVendorIntegrated, integrationStatus, isLoading, integrationFileLink, action, handleButtonClick, integrations, isComingSoon]);

  const statusIcon = useMemo(() => {
    const isSCMIntegration = integrationTemplate?.vendorType === IntegrationVendorType.sourceCodeManagement;

    if (isSCMIntegration) {
      const isSuccessIntegration = integrationStatus === IntegrationStatus.SUCCESS;
      const isSCMVendorIntegrated = isScmVendorIntegrated(getIntegrationTemplateVendor(integrationTemplate.vendor));
      return isSCMVendorIntegrated && isSuccessIntegration ? WhiteDotCheck : undefined;
    }
    return integrationStatus === IntegrationStatus.SUCCESS ? WhiteDotCheck : CircleFailure;
  }, [isScmVendorIntegrated, integrationTemplate, integrationStatus]);
  const borderColor = integrationStatus === IntegrationStatus.FAILURE ? colors.failRed : undefined;

  const headerIcons: HeaderIcons = useMemo(
    () => {
      const iconUrl = integrationTemplate?.logoUrl || (integrationTemplate?.provider && integrationTemplate?.provider !== IntegrationProvider.JIT ? icon as string : undefined);
      const integrationIcon: HeaderIcon = {
        icon: iconUrl ? undefined : icon as FC<ISvg>,
        iconUrl,
      };
      const statusHeaderIcon: HeaderIcon = {
        icon: statusIcon,
        tooltip: integrationFailureReason?.reason,
      };

      return {
        icons: integrationStatus ? [integrationIcon, statusHeaderIcon] : [integrationIcon],
      };
    },
    [icon, integrationFailureReason?.reason, integrationStatus, integrationTemplate?.provider, integrationTemplate?.logoUrl, statusIcon],
  );

  const learnMoreLink = useMemo(
    () => (integrationTemplate?.link ? {
      text: integrationTemplate?.link?.text || 'cards.buttons.learnMore',
      href: integrationTemplate?.link?.href,
    } : undefined),
    [integrationTemplate?.link],
  );

  return (
    <>
      {isOpened && component}

      <JitCard
        actionButtons={actionButtons}
        borderColor={borderColor}
        description={description || `cards.${vendor}.description`}
        headerIcons={headerIcons}
        isComingSoon={isComingSoon}
        isNew={integrationTemplate?.isNew}
        link={learnMoreLink}
        tags={getVariantTagArray(variant)}
        testid={testId || `cards.${vendor}.title`}
        title={title || `cards.${vendor}.title`}
      />
    </>
  );
};
