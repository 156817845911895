import { FC } from 'react';

import { OverviewBody } from './components';
import styles from './OverviewPage.module.scss';

import { PageLayout } from 'components/Pages/PageLayout/PageLayout';
import { PageTitles } from 'components/PageTitles/PageTitles';
  import { useAuthContext } from 'context/AuthContext/AuthContext';
import { i18n } from 'locale/i18n';

export const OverviewPage: FC = () => {
  const { frontEggUser } = useAuthContext();
  
  return (
    <PageLayout>
      <div className={styles.wrapper} data-testid='overviewPageWrapper'>
        <div className={styles.header}>
          <PageTitles
            subtitle={i18n.t('pages.overview.subtitle', { name: frontEggUser.name })}
            title='pages.overview.title'
          />
        </div>

        <div className={styles.body}>
        <OverviewBody />;
        </div>
      </div>
    </PageLayout>
  );
};
