import { FC, useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { JitText } from 'components/JitText/JitText';
import { useJittoContext } from 'context/JittoContext/JittoContext';
import { ItemDrilldownPanel } from '../ItemDrilldownPanel/ItemDrilldownPanel';
import RiskAssessmentLoading from '../RiskAssessmentLoading/RiskAssessmentLoading';
import { formatDateTime } from 'utils/functions/dateUtils';
import { JitButton } from 'components/JitButton/JitButton';
import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';

import { itemsMap } from 'context/JittoContext/ReportData';

// Main JittoReportRenderer component
const JittoReportRenderer: FC = () => {
  const navigate = useNavigate();
  // Add loading state
  const [isLoading, setIsLoading] = useState(true);
  
  // Get report data and drill down state from context
  const { 
    currentConversation
  } = useJittoContext();
  
  // Effect to handle the loading state for 10 seconds
  useEffect(() => {
    const loadingTimer = setTimeout(() => {
      setIsLoading(false);
    }, 10000);
    
    // Clean up timer
    return () => clearTimeout(loadingTimer);
  }, []);
  
  // We've already checked that data exists, but TypeScript needs this check again
  const sections = currentConversation?.report?.data || [];

  // Create components from data
  const renderComponent = (itemType: any, id: string, props: any) => {
    const Component = itemsMap[itemType as keyof typeof itemsMap]; 
    if (!Component) {
      console.error(`Component ${itemType} not found`);
      return null;
    }
      return (
        <div style={{ minHeight: 250, flex: 1, display: 'flex', }}>
            <Component id={id} {...props}>
                {props.items?.map((child: any, index: number) => renderComponent(child.itemType, child.id, child.props))}
            </Component>
        </div>
      )
  }
  return (
    <>
    <div style= {{
      justifyContent: 'space-between',
      overflow: 'scroll',
      backgroundColor: '#121926',
      padding: '24px 20px 0',
      }}
  >
    <div style={{
      textAlign: 'left', 
      marginTop: 30,
      marginLeft: 20,
      marginBottom: 20,
      fontSize: 28,
      animation: 'fadeIn 0.7s ease-in-out',
      display: 'flex',
      justifyContent: 'space-between',
    }}>
      <div style={{display: 'flex', flexDirection: 'column', gap: 6}}>
        <div style={{display: 'flex', gap: 6}}>
          <JitText muted size="l" text={formatDateTime(new Date().toISOString()).dateOnly} />
        </div>

        <JitText bold size="xxl" text={currentConversation?.title} />
      </div>
      <div style={{display: 'flex', gap: 20}}>
      <JitButton
            disableRipple
            onClick={() => navigate('/agents/tasks')}
            variant='text'
          >
            <JitText size='l' color='inherit' text='View Tasks' />
          </JitButton>
      <JitButton
            disableRipple
            onClick={() => {}}
            variant='text'
          >
            <JitText size='l' color='inherit' text='Save View' />
          </JitButton>
    </div>
    </div>
    {isLoading ? (
      <RiskAssessmentLoading />
    ) : (
      <div>
        {sections.map((item) => renderComponent(item.itemType, item.id, item.props))}
      </div>
    )}
  </div>
          
    {/* Render the drilldown panel inside the report renderer */}
    <ItemDrilldownPanel />
    </>
  )
    
};

export default JittoReportRenderer; 