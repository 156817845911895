import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserAvatar, NavbarItems, NavbarHeader } from './components';
import { ITabItem, useGetBottomTabItems, useGetPageTabItems, useGetSelectedTabName } from './hooks';
import styles from './Navbar.module.scss';

import { useTenantContext } from 'context/TenantContext';
import { JittoChat } from 'components/JittoChat';
import { PageLayout } from 'components/Pages/PageLayout/PageLayout';
export const Navbar: FC = () => {
  const [isHoverNavbar, setIsHoverNavbar] = useState(false);
  const [isHoverSwitchTenantPopper, setIsHoverSwitchTenantPopper] = useState(false);
  const { uiShowSecurityChampionQuickGuide } = useFlags();
  const { initialOnboardingCompleted } = useTenantContext();

  const shouldDisablePlatform = useMemo(() => !initialOnboardingCompleted && uiShowSecurityChampionQuickGuide, [initialOnboardingCompleted, uiShowSecurityChampionQuickGuide]);

  const { pagesTabs } = useGetPageTabItems(shouldDisablePlatform);
  const { bottomTabs } = useGetBottomTabItems(shouldDisablePlatform);
  const isCollapsed = useMemo(() => !isHoverNavbar && !isHoverSwitchTenantPopper, [isHoverNavbar, isHoverSwitchTenantPopper]);
  const tabs = useMemo(() => [...pagesTabs, ...bottomTabs], [pagesTabs, bottomTabs]);
  const { selectedTabName } = useGetSelectedTabName(tabs);

  const navigate = useNavigate();

  const onTabItemClick = useCallback((tabItem: ITabItem) => {
    if (tabItem.route) {
      navigate(tabItem.route);
    } else if (tabItem.onClick) {
      tabItem.onClick();
    } else {
      console.error('Tab item does not have a route or onClick function');
    }
  }, [navigate]);

  return (
    <>
    
      <div className={styles.fixedWidthWrapper}>
        <div
          className={`${styles.wrapper} ${isCollapsed ? styles.collapsed : styles.expanded}`}
          data-testid='Navbar'
          onMouseEnter={() => setIsHoverNavbar(true)}
          onMouseLeave={() => setIsHoverNavbar(false)}
        >

          <NavbarHeader isCollapsed={isCollapsed} />

          <div
            className={styles.scrollableContent}
            style={{ overflowY: isCollapsed ? 'hidden' : 'auto' }}
          >
            <NavbarItems isCollapsed={isCollapsed} items={pagesTabs} onClick={onTabItemClick} selectedTabName={selectedTabName} />

            <NavbarItems isCollapsed={isCollapsed} items={bottomTabs} onClick={onTabItemClick} selectedTabName={selectedTabName} />

          </div>

          <UserAvatar isCollapsed={isCollapsed} setIsHoverSwitchTenantPopper={setIsHoverSwitchTenantPopper} />
        </div>
      </div>
      {/* Floating chat button positioned outside the navbar */}
      <div className={`${styles.floatingJittoChat} ${isCollapsed ? styles.collapsed : styles.expanded}`}>
        <PageLayout>
          <JittoChat />
        </PageLayout>
      </div>
    </>
  );
};
