import React, { useMemo } from 'react';
import { useDetailsByVendor } from 'utils/hooks/useDetailsByVendor';
import { Vendor } from 'types/enums';
import { hexColorToRGBA } from 'utils';

// Define supported colors
const SUPPORTED_COLORS = [
  '#E57373', // Red
  '#F06292', // Pink
  '#BA68C8', // Purple
  '#9575CD', // Deep Purple
  '#7986CB', // Indigo
  '#64B5F6', // Blue
  '#4FC3F7', // Light Blue
  '#4DD0E1', // Cyan
  '#4DB6AC', // Teal
  '#81C784', // Green
  '#AED581', // Light Green
  '#FFD54F', // Amber
  '#FFB74D', // Orange
  '#FF8A65', // Deep Orange
];

// Type definitions for pre-defined color schemes
const TYPE_COLOR_MAPS = {
  vendor: {
    'aws': '#FF9900',
    'azure': '#0078D4',
    'gcp': '#4285F4',
    'wiz': '#2388FF33',
    'checkmarx': '#9D81FA',
    'default': '#757575' // Default color for unknown vendors
  },
  severity: {
    'critical': '#EC5B5B',
    'high': '#EE8355',
    'medium': '#E59485',
    'low': '#1D63ED',
    'info': '#1976D2',
    'default': '#757575' // Default color for unknown severity
  }
};

// Simple hash function to get consistent color for the same text
const hashText = (text: string): number => {
  let hash = 0;
  for (let i = 0; i < text.length; i++) {
    hash = ((hash << 5) - hash) + text.charCodeAt(i);
    hash = hash & hash; // Convert to 32bit integer
  }
  return Math.abs(hash);
};

/**
 * Helper function to check if a vendor is known using the Vendor enum directly
 * @param vendorText The vendor text to check
 * @returns Boolean indicating if the vendor is defined in the Vendor enum
 */
const isKnownVendor = (vendorText: string): boolean => {
  // Convert to lowercase for case-insensitive comparison
  const normalizedText = vendorText.toLowerCase();
  
  // Use direct comparison with Vendor enum values
  return Object.values(Vendor).some(vendorValue => vendorValue === normalizedText);
};

export interface LabelProps {
  /**
   * Text content of the label
   */
  text: string;
  
  /**
   * Optional custom color for the label
   * If not provided, a color will be generated based on the text
   */
  color?: string;
  
  /**
   * Optional type for pre-defined color schemes
   * Currently supports 'vendor' and 'severity'
   */
  type?: keyof typeof TYPE_COLOR_MAPS;
}

/**
 * Label component for displaying text with customizable colors
 */
export const Label: React.FC<LabelProps> = ({ 
  text, 
  color, 
  type,
}) => {
  const { getVendorDisplayName } = useDetailsByVendor();
  
  // Check if this is a known vendor
  const isVendorKnown = useMemo(() => {
    if (type === 'vendor') {
      return isKnownVendor(text);
    }
    return false;
  }, [text, type]);
  
  // Get display name when type is vendor and it's a known vendor
  const displayText = useMemo(() => {
    if (type === 'vendor' && isVendorKnown) {
      return getVendorDisplayName(text) || text;
    }
    return text;
  }, [text, type, getVendorDisplayName, isVendorKnown]);

  // Determine the color to use
  const labelColor = useMemo(() => {
    // If color is explicitly provided, use it
    if (color) {
      return color;
    }
    
    // If type is provided, use the type-specific color map
    if (type && type in TYPE_COLOR_MAPS) {
      const colorMap = TYPE_COLOR_MAPS[type as keyof typeof TYPE_COLOR_MAPS];
      return text.toLowerCase() in colorMap ? colorMap[text.toLowerCase() as keyof typeof colorMap] : colorMap.default;
    }
    
    // Otherwise, generate a consistent color based on the text
    const index = hashText(text) % SUPPORTED_COLORS.length;
    return SUPPORTED_COLORS[index];
  }, [text, color, type]);

  return (
    <span 
      style={{
        backgroundColor: hexColorToRGBA(labelColor, 0.5),
        border: `1px solid ${hexColorToRGBA(labelColor, 0.8)}`,
        color: '#FFFFFF', // White text for contrast
        padding: '4px 8px',
        borderRadius: '4px',
        fontSize: '12px',
        fontWeight: 500,
        display: 'inline-flex',
        alignItems: 'center',
        gap: '4px', // Add spacing between icon and text
      }}
    >
      {displayText}
    </span>
  );
};

export default Label; 