import { FC, useMemo } from 'react';

import { BookTimeWidget } from './components/BookTimeWidget/BookTimeWidget';
import { QuickGuideSection } from './components/QuickGuideSection/QuickGuideSection';
import { useQuickstartGuide } from './hooks';
import { useQuickGuideExpansion } from './hooks/useQuickGuideExpansion';
import styles from './QuickStartGuide.module.scss';
import { CheckStatus } from './types';

import { CompletionStatusRow } from 'components/CompletionStatusRow/CompletionStatusRow';
import { JitDivider } from 'components/JitDivider/JitDivider';
import { Background } from 'components/Pages/Background/Background';
import { PageLayout } from 'components/Pages/PageLayout/PageLayout';
import colors from 'themes/colors.module.scss';

export const QuickStartGuide: FC = () => {
  const { checksMap, data } = useQuickstartGuide();
  const { handleSectionToggle, isSectionExpanded, showAllSections, handleShowAllToggle } = useQuickGuideExpansion({
    sections: data.sections,
    checksMap,
  });

  const nonHiddenChecks = useMemo(() => data.checks.filter(({ isHidden }) => !isHidden), [data.checks]);
  const totalChecks = useMemo(() => nonHiddenChecks.length, [nonHiddenChecks]);
  const completedChecks = useMemo(() => nonHiddenChecks.filter(({ status }) => status === CheckStatus.COMPLETED).length, [nonHiddenChecks]);

  const renderedSections = useMemo(() => data.sections.map((section, index) => {
    const nextSection = data.sections[index + 1];

    // Calculate if all previous sections are completed/skipped
    const previousSectionsCompleted = data.sections.slice(0, index).every((prevSection) => prevSection.checks.every((checkSlug) => {
      const check = checksMap[checkSlug];
      return !check.isVisible
          || check.status === CheckStatus.COMPLETED
          || check.status === CheckStatus.SKIPPED;
    }));

    const isSectionCompleted = section.checks.every((checkSlug) => {
      const check = checksMap[checkSlug];
      return !check.isVisible || check.status === CheckStatus.COMPLETED;
    });

    const hasSectionSkippedChecks = section.checks.some((checkSlug) => checksMap[checkSlug].status === CheckStatus.SKIPPED);
    const shouldShowSection = showAllSections || !isSectionCompleted || hasSectionSkippedChecks;

    if (!shouldShowSection) return null;

    return (
      <QuickGuideSection
        key={section.sectionSlug}
        bottomText={section.bottomText}
        checks={section.checks}
        checksMap={checksMap}
        isExpanded={isSectionExpanded(section.sectionSlug)}
        nextSectionDisplayName={section.skipButtonText || nextSection?.display}
        onToggleExpand={() => handleSectionToggle(section.sectionSlug)}
        previousSectionsCompleted={previousSectionsCompleted}
        title={section.display}
      />
    );
  }), [checksMap, data.sections, showAllSections, handleSectionToggle, isSectionExpanded]);

  return (
    <PageLayout>
      <div className={styles.root}>
        <Background />

        <div className={styles.container}>
          <BookTimeWidget />

          <CompletionStatusRow
            completed={completedChecks}
            description='pages.quickStart.description'
            onToggleVisibility={handleShowAllToggle}
            showAllSections={showAllSections}
            title='pages.quickStart.title'
            total={totalChecks}
          />

          <JitDivider color={colors.darkGray03} />

          <div className={styles.sections}>
            {renderedSections}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
