import { useCallback, useEffect } from 'react';

import { useWebsocketSubscribe } from 'context/WebSocketContext/hooks';
import { Vendor, WebSocketNotificationTopics } from 'types/enums';
import { TicketFinding, IWebsocketNotification } from 'types/interfaces';
import { ITicket } from 'types/interfaces/TicketManager/ITicket';
import { assertWebsocketNotificationEntity } from 'utils/functions/assertions/websocketNotificationEntity';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';

interface Props {
  expectedNotifications: string[]
  onSuccess: (findingIds: string[], ticketFinding: TicketFinding) => void;
  onError: (findingIds: string[], reason: string) => void
}

export const useSubscribeToTicketWebsocket = ({
  expectedNotifications,
  onError,
  onSuccess,
}: Props) => {
  const { websocketSubscribe } = useWebsocketSubscribe();

  const handleTicketNotification = useCallback((notification: IWebsocketNotification<ITicket>) => {
    assertWebsocketNotificationEntity(notification);
    const { created } = notification.message;
    const ticket = camelizeSnakeCaseKeys(created[0]) as ITicket;
    if (!ticket.entityIds.every((id) => expectedNotifications.includes(id))) {
      return;
    }
    if (ticket.status === 'success') {
      const ticketFinding: TicketFinding = {
        ticketUrl: ticket.ticketUrl,
        vendor: ticket.vendor as Vendor,
        createdAt: ticket.createdAt,
        userId: ticket.userId,
      };
      onSuccess(ticket.entityIds, ticketFinding);
    } else {
      onError(ticket.entityIds, ticket.message);
    }
  }, [onSuccess, onError, expectedNotifications]);

  useEffect(() => {
    websocketSubscribe(WebSocketNotificationTopics.Tickets, handleTicketNotification);
  }, [handleTicketNotification, websocketSubscribe]);
};
