import React, { ReactNode } from 'react';
import { Paper, Typography } from '@mui/material';

export interface SimpleCardProps {
  /**
   * Label text displayed at the top of the card
   */
  label: string;
  
  /**
   * Value displayed as the main content of the card
   * Can be a string or a React element
   */
  value: string;
}

/**
 * SimpleCard component that displays a label and a value
 * Used for displaying metrics or summary information
 */
export const SimpleCard: React.FC<SimpleCardProps> = ({ 
  label, 
  value 
}) => {
  return (
    <Paper
      elevation={3}
      sx={{
        bgcolor: '#1E2234',
        color: 'white',
        borderRadius: '8px',
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        minWidth: '200px',
        height: '100%',
      }}
    >
      <Typography 
        variant="subtitle1" 
        sx={{ 
          mb: 2,
          opacity: 0.9,
          fontWeight: 400,
        }}
      >
        {label}
      </Typography>
      
        <Typography 
          variant="h2" 
          sx={{ 
            fontWeight: 700,
            flex: 1,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {value}
        </Typography>
    </Paper>
  );
};

export default SimpleCard; 