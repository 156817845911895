import type { EdgeTypes, NodeChange, NodeTypes } from '@reactflow/core/dist/esm/types';
import { useEffect, useCallback, useState, FC, useRef } from 'react';
import ReactFlow, { Background, useNodesState, useEdgesState, BackgroundVariant, ReactFlowInstance, Node, Edge } from 'reactflow';

import styles from './JitGraph.module.scss';

import { CircularLoadingSpinner } from 'components/CircularLoadingSpinner/CircularLoadingSpinner';
import colors from 'themes/colors.module.scss';

interface Props {
  nodes: Node[];
  edges: Edge[];
  isLoading?: boolean;
  getLayoutedElements?: (nodes: Node[], edges: Edge[]) => Promise<{ nodes: Node[]; edges: Edge[] }>;
  edgeTypes?: EdgeTypes;
  nodeTypes?: NodeTypes;
  backgroundColor?: string;
  minZoom?: number;
  maxZoom?: number;
}

const GRAPH_LOADING_TIMEOUT_MS = 2000;

const shouldFitView = (changes: NodeChange[]) => {
  const changeTypes = changes.map((change) => change.type);

  // if user selects a node, we don't want to fit the view, otherwise for actions like dimensions, position, add, remove and reset, we want to fit the view
  return !changeTypes.every((changeType) => changeType === 'select');
};

export const JitGraph: FC<Props> = ({
  nodes: initialNodes,
  edges: initialEdges,
  isLoading: isParentLoading,
  getLayoutedElements,
  edgeTypes,
  nodeTypes,
  backgroundColor = colors.cards,
  minZoom = 0.01,
  maxZoom,
}) => {
  const [nodes, setNodes, onNodesChange] = useNodesState<Node[]>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [isAdjustingLayout, setIsAdjustingLayout] = useState(true);
  const reactFlowInstance = useRef<ReactFlowInstance | null>(null);

  const layoutNodesAndEdges = useCallback(async () => {
    if (!getLayoutedElements) {
      setIsAdjustingLayout(false);
      setNodes(initialNodes);
      setEdges(initialEdges);
      return;
    }

    const {
      nodes: layoutedNodes,
      edges: layoutedEdges,
    } = await getLayoutedElements(initialNodes, initialEdges);
    setNodes(layoutedNodes);
    setEdges(layoutedEdges);

    // In 'Happy flow', we'll set this state to false as part of `onNodesChange`.
    // but this handles the case when the graph is empty and the event is not triggered
    setTimeout(() => setIsAdjustingLayout(false), GRAPH_LOADING_TIMEOUT_MS);
  }, [getLayoutedElements, initialNodes, initialEdges, setNodes, setEdges]);

  useEffect(() => {
    layoutNodesAndEdges();
  }, [isParentLoading, layoutNodesAndEdges]);

  const isLoading = isAdjustingLayout || isParentLoading;
  const padding = nodes.length > 5 ? 0.5 : 1; // for small number of nodes, keep the graph bigger
  return (
    <div className={styles.wrapper} data-testid='JitGraph' style={{ backgroundColor }}>
      {isLoading && (
        <div className={styles.spinnerWrapper}>
          <CircularLoadingSpinner size='large' />
        </div>
      )}

      <ReactFlow
        edges={isParentLoading ? [] : edges}
        edgeTypes={edgeTypes}
        fitView
        fitViewOptions={{ padding }}
        maxZoom={maxZoom}
        minZoom={minZoom}
        nodes={isParentLoading ? [] : nodes}
        nodeTypes={nodeTypes}
        onEdgesChange={onEdgesChange}
        onInit={(instance) => {
          reactFlowInstance.current = instance;
        }}
        onNodesChange={(changes) => {
          onNodesChange(changes);
          setIsAdjustingLayout(false);
          if (reactFlowInstance && shouldFitView(changes)) {
            reactFlowInstance.current?.fitView({ padding });
          }
        }}
        proOptions={{ hideAttribution: true }}
      >
        <Background color={colors.cardsDivider} gap={16} variant={BackgroundVariant.Dots} />
      </ReactFlow>
    </div>
  );
};
