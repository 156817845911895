/**
 * Defines the possible types of data that can be returned in a chat response
 * Currently supports dashboard data, with potential for future expansion
 * @enum {string}
 */
export enum ChatDataType {
  DASHBOARD = "dashboard"
}

/**
 * Represents a single piece of data returned from the chat API
 * @interface
 * @property {ChatDataType} type - Identifies the type of data being returned
 * @property {any} content - The actual data content, structure varies based on type
 * @property {Record<string, any>} [metadata] - Optional additional context or configuration
 */
export interface ChatData {
  type: ChatDataType;
  content: any;  // Type varies based on ChatDataType
  metadata?: Record<string, any>;
}

/**
 * Represents the complete response from the chat API
 * @interface
 * @property {string} message - The textual response from the chat
 * @property {ChatData[]} data - Array of data items returned by the chat
 */
export interface ChatResponse {
  message: string;
  data: ChatData[];
}

/**
 * Represents a request to the chat API
 * @interface
 * @property {string} message - The user's input message to the chat
 * @property {Record<string, any>} [metadata] - Optional context or configuration for the request
 */
export interface ChatRequest {
  message: string;
  metadata?: Record<string, any>;
}

/**
 * Represents a section within a dashboard visualization
 * @interface
 * @property {string} id - Unique identifier for the section
 * @property {string} itemType - Type identifier for the section, always "Section"
 * @property {object} props - Properties of the section
 * @property {string} props.title - Display title for the section
 * @property {unknown[]} props.items - Array of widget data, structure varies by widget type
 */
export interface DashboardSection {
  id: string;
  itemType: 'Section';
  props: {
    title: string;
    items: any[]; // Array of widget items with varying structure
  };
}

/**
 * Represents a complete dashboard structure
 * @interface
 * @property {DashboardSection[]} sections - Array of dashboard sections
 */
export interface Dashboard {
  sections: DashboardSection[];
}

/**
 * Type guard to verify if a value is a valid Dashboard object
 * @param {any} content - The value to check
 * @returns {boolean} True if the content matches the Dashboard interface structure
 */
export function isDashboard(content: any): content is Dashboard {
  return (
    typeof content === "object" &&
    content !== null &&
    "sections" in content &&
    Array.isArray(content.sections) &&
    content.sections.every(
      (section: any) =>
        typeof section === "object" &&
        section !== null &&
        "id" in section &&
        typeof section.id === "string" &&
        "itemType" in section &&
        section.itemType === "Section" &&
        "props" in section &&
        typeof section.props === "object" &&
        section.props !== null &&
        (!("title" in section.props) || typeof section.props.title === "string") &&
        "items" in section.props &&
        Array.isArray(section.props.items)
    )
  );
}
