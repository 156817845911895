/* eslint-disable react/jsx-no-literals */
import React, { useEffect, useState } from 'react';

import styles from './DocsQA.module.scss';
import { SearchModal } from './Modal/SearchModal';

import { Magic } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { config } from 'config';
import { constants } from 'globalConstants';
import { useIsCurrentPage } from 'wrappers/RBAC/hooks/useIsCurrentPage';

const HIDE_IN_PAGES = [constants.routes.PRICING_PLANS, constants.routes.BILLING, constants.routes.WORKFLOWS, constants.routes.OVERVIEW, '/'];

interface DocsQAProps {
  className?: string;
}

export const DocsQA: React.FC<DocsQAProps> = ({ className }) => {
  const [open, setOpen] = useState(false);
  const shouldHideDocsQA = useIsCurrentPage(HIDE_IN_PAGES);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        setOpen(true);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      // Clean up the event listener when the component is unmounted
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  if (shouldHideDocsQA) {
    return null;
  }

  return (
    <div
      className={`${className ? `${className} ` : ''}${styles.searchContainer}`}
      onClick={handleOpen}
      role='button'
      tabIndex={0}
    >
      <div className={styles.aiSearch}>

        <span className={styles.magicIcon}><JitIcon icon={Magic} size={20} /></span>

        <JitButton
          className={styles.askMe}
          data-testid='ask-me-anything-button'
          variant='text'
        >

          <JitText color='inherit' size='s' text='chat.placeholder' />

          <span><JitText color='inherit' size='s' text='chat.cmdk' /></span>

        </JitButton>

      </div>

      {config.docsQaEnabled && <SearchModal handleClose={handleClose} open={open} />}
    </div>
  );
};
