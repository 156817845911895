import { SvgIconComponent, Web as WebIcon, Api as ApiIcon } from '@mui/icons-material';
import { isEmpty } from 'lodash';
import { useCallback } from 'react';

import { SourceControl } from 'assets';
import { useIntegrationsContext } from 'context/IntegrationsContext/IntegrationsContext';
import { useTenantContext } from 'context/TenantContext/TenantContext';
import { getIntegrationTemplateVendor } from 'pages/IntegrationsPage/utils/getIntegrationTemplateVendor';
import { AssetType } from 'types/enums/AssetType';
import { Vendor } from 'types/enums/Vendor';
import { ISvg } from 'types/interfaces';
import { IAsset } from 'types/interfaces/IAsset';
import { IIntegration, IntegrationProvider } from 'types/interfaces/Integrations/IIntegration';
import { iconsMap } from 'utils/constants/iconsMap';

const getSCMIcon = (scmVendor?: string): React.FC<ISvg> => {
  if (scmVendor === Vendor.Github) {
    return iconsMap.github.icon;
  }
  return SourceControl;
};

const getIconComponent = (iconUrl: string): React.FC<ISvg> => {
  const IconComponent: React.FC<ISvg> = (props) => (
    <svg {...props}>
      <image height='100%' href={iconUrl} width='100%' />
    </svg>
  );
  return IconComponent;
};

type GetVendorAndIconByAssetType = {
  vendor: Vendor | undefined;
  icon?: React.FC<ISvg> | SvgIconComponent;
};

type VendorGetter = ((scmVendor?: string) => Vendor | undefined) | (() => Vendor);
type IconGetter = ((scmVendor?: string) => React.FC<ISvg> | SvgIconComponent | undefined) | (() => React.FC<ISvg> | SvgIconComponent | undefined);

interface IAssetTypeConfigurations {
  assetType: AssetType;
  assetTypeDisplayName: string; // used all arround the app
  assetTypeResourceName: string; // used for the resources table
  getAssetDisplayName: (asset: IAsset) => string;
  getVendor: VendorGetter;
  getIcon: IconGetter;
}

const configurationsMap: Record<AssetType, IAssetTypeConfigurations> = {
  [AssetType.REPO]: {
    assetType: AssetType.REPO,
    assetTypeDisplayName: '{{scmRepo}}',
    assetTypeResourceName: 'Repository',
    getAssetDisplayName: (asset: IAsset) => `${asset.owner}/${asset.asset_name}`,
    getVendor: (scmVendor?: string) => scmVendor as Vendor,
    getIcon: (scmVendor?: string) => getSCMIcon(scmVendor),
  },
  [AssetType.AWS_ACCOUNT]: {
    assetType: AssetType.AWS_ACCOUNT,
    assetTypeDisplayName: 'Account',
    assetTypeResourceName: 'AWS Account',
    getAssetDisplayName: (asset: IAsset) => asset.asset_name,
    getVendor: () => Vendor.AWS,
    getIcon: () => iconsMap.aws.icon,
  },
  [AssetType.AWS_ORG]: {
    assetType: AssetType.AWS_ORG,
    assetTypeDisplayName: 'AWS Organization',
    assetTypeResourceName: 'AWS Organization',
    getAssetDisplayName: (asset: IAsset) => asset.asset_name,
    getVendor: () => Vendor.AWS,
    getIcon: () => iconsMap.aws.icon,
  },
  [AssetType.GCP_ACCOUNT]: {
    assetType: AssetType.GCP_ACCOUNT,
    assetTypeDisplayName: 'Account',
    assetTypeResourceName: 'GCP Account',
    getAssetDisplayName: (asset: IAsset) => asset.asset_name,
    getVendor: () => Vendor.GCP,
    getIcon: () => iconsMap.gcp.icon,
  },
  [AssetType.AZURE_ACCOUNT]: {
    assetType: AssetType.AZURE_ACCOUNT,
    assetTypeDisplayName: 'Account',
    assetTypeResourceName: 'Azure Account',
    getAssetDisplayName: (asset: IAsset) => asset.asset_name,
    getVendor: () => Vendor.AZURE,
    getIcon: () => iconsMap.azure.icon,
  },
  [AssetType.API]: {
    assetType: AssetType.API,
    assetTypeDisplayName: 'API',
    assetTypeResourceName: 'API',
    getAssetDisplayName: (asset: IAsset) => asset.asset_name,
    getVendor: () => Vendor.ZAP_API,
    getIcon: () => ApiIcon,
  },
  [AssetType.WEB]: {
    assetType: AssetType.WEB,
    assetTypeDisplayName: 'Webapp',
    assetTypeResourceName: 'Web Application',
    getAssetDisplayName: (asset: IAsset) => asset.asset_name,
    getVendor: () => Vendor.ZAP_WEB,
    getIcon: () => WebIcon,
  },
  [AssetType.ORG]: {
    assetType: AssetType.ORG,
    assetTypeDisplayName: '{{scmOrg}}',
    assetTypeResourceName: 'Organization',
    getAssetDisplayName: (asset: IAsset) => asset.asset_name,
    getVendor: (scmVendor?: string) => scmVendor as Vendor,
    getIcon: (scmVendor?: string) => getSCMIcon(scmVendor),
  },
  [AssetType.APPLICATION]: {
    assetType: AssetType.APPLICATION,
    assetTypeDisplayName: 'Application',
    assetTypeResourceName: 'Application',
    getAssetDisplayName: (asset: IAsset) => asset.asset_name,
    getVendor: () => Vendor.Domain,
    getIcon: () => undefined,
  },
  [AssetType.IMAGE]: {
    assetType: AssetType.IMAGE,
    assetTypeDisplayName: 'Container Image',
    assetTypeResourceName: 'Container Image',
    getAssetDisplayName: (asset: IAsset) => `${asset.owner}/${asset.asset_name}`,
    getVendor: () => Vendor.DOCKER,
    getIcon: () => iconsMap.container.icon,
  },
};

const getAssetTypeConfigurations = (assetType: AssetType): IAssetTypeConfigurations | undefined => configurationsMap[assetType];

export const getDisplayNamesMap = (): Record<AssetType, string> => Object.values(AssetType).reduce<Record<AssetType, string>>((acc, assetType) => {
  acc[assetType] = configurationsMap[assetType].assetTypeDisplayName;
  return acc;
}, {} as Record<AssetType, string>);

export const getResourceNamesMap = (): Record<AssetType, string> => Object.values(AssetType).reduce<Record<AssetType, string>>((acc, assetType) => {
  acc[assetType] = configurationsMap[assetType].assetTypeResourceName;
  return acc;
}, {} as Record<AssetType, string>);

export const getAssetDisplayName = (asset: IAsset) => getAssetTypeConfigurations(asset.asset_type)?.getAssetDisplayName(asset) || asset.asset_name;

const getVendorAndIconForAssetType = (assetType: AssetType, scmVendor?: string): GetVendorAndIconByAssetType => {
  const assetTypeConfigurations = getAssetTypeConfigurations(assetType);
  if (!assetTypeConfigurations) {
    return {
      vendor: undefined,
    };
  }
  return {
    vendor: assetTypeConfigurations.getVendor(scmVendor),
    icon: assetTypeConfigurations.getIcon(scmVendor),
  };
};

const getIconFromThirdPartyIntegrationForAssetType = (integrations: IIntegration[], assetType: AssetType, scmVendor?: string): React.FC<ISvg> | undefined => {
  const { vendor } = getVendorAndIconForAssetType(assetType, scmVendor);
  if (!vendor) {
    return undefined;
  }

  const integration = integrations.find((currIntegration) => getIntegrationTemplateVendor(currIntegration.vendor) === vendor);
  const isIntegrationProvidedByJit = !integration || integration.provider === IntegrationProvider.JIT;
  if (isIntegrationProvidedByJit) {
    return undefined;
  }

  return getIconComponent(integration.display.icon);
};

export const useAssetTypeToVendor = () => {
  const { currentScmVendor } = useTenantContext();
  const { integrations, isLoading } = useIntegrationsContext();

  const getIconByAssetType = useCallback((assetType: AssetType) => {
    if (isLoading) {
      return undefined;
    }
    if (integrations && !isEmpty(integrations)) {
      const iconFromIntegration = getIconFromThirdPartyIntegrationForAssetType(integrations, assetType, currentScmVendor);

      if (iconFromIntegration) {
        return iconFromIntegration;
      }
    }

    const { icon } = getVendorAndIconForAssetType(assetType, currentScmVendor);
    return icon;
  }, [currentScmVendor, integrations, isLoading]);

  const getVendorByAssetType = useCallback((assetType: AssetType) => {
    if (isLoading) {
      return undefined;
    }

    const { vendor } = getVendorAndIconForAssetType(assetType, currentScmVendor);
    return vendor;
  }, [currentScmVendor, isLoading]);

  return {
    getIconByAssetType,
    getVendorByAssetType,
  };
};
