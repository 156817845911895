import { useCallback } from 'react';

import { useContextEngine } from 'context/GraphContext/hooks/useContextEngine';
import { FindingStatus, Severity, TimeAgo } from 'types/enums';
import { IFindingFilter, IFindingFilterType, IServerFindingFilterOptions, ServerFindingKeys, TIME_AGO_FILTER_KEY } from 'types/interfaces';

export const useGetDefaultFindingFilters = () => {
  const isContextEngineEnabled = useContextEngine();
  const getDefaultFilters = useCallback((fetchedServerFilterOptions: IServerFindingFilterOptions = {}): IFindingFilter[] => [
    {
      key: TIME_AGO_FILTER_KEY,
      type: IFindingFilterType.SINGLE_SELECT,
      valueOptions: [TimeAgo.OneWeek, TimeAgo.TwoWeeks, TimeAgo.OneMonth, TimeAgo.ThreeMonths],
      selectedValue: TimeAgo.OneMonth,
      defaultValue: TimeAgo.OneMonth,
      isVisible: true,
      defaultVisibility: true,
    },
    {
      key: ServerFindingKeys.status,
      type: IFindingFilterType.MULTI_SELECT,
      valueOptions: fetchedServerFilterOptions[ServerFindingKeys.status] ?? [],
      selectedValue: [FindingStatus.OPEN],
      defaultValue: [FindingStatus.OPEN],
      isVisible: true,
      defaultVisibility: true,
    },
    {
      key: ServerFindingKeys.issue_severity,
      type: IFindingFilterType.MULTI_SELECT,
      valueOptions: fetchedServerFilterOptions[ServerFindingKeys.issue_severity] ?? [],
      selectedValue: [Severity.High, Severity.Critical],
      defaultValue: [Severity.High, Severity.Critical],
      isVisible: true,
      defaultVisibility: true,
    },
    {
      key: ServerFindingKeys.plan_layer,
      type: IFindingFilterType.MULTI_SELECT,
      valueOptions: fetchedServerFilterOptions[ServerFindingKeys.plan_layer] ?? [],
      selectedValue: [],
      defaultValue: [],
      isVisible: false,
      defaultVisibility: false,
    },
    {
      key: ServerFindingKeys.vulnerability_type,
      type: IFindingFilterType.MULTI_SELECT,
      valueOptions: fetchedServerFilterOptions[ServerFindingKeys.vulnerability_type] ?? [],
      selectedValue: [],
      defaultValue: [],
      isVisible: false,
      defaultVisibility: false,
    },
    {
      key: ServerFindingKeys.asset_type,
      type: IFindingFilterType.MULTI_SELECT,
      valueOptions: fetchedServerFilterOptions[ServerFindingKeys.asset_type] ?? [],
      selectedValue: [],
      defaultValue: [],
      isVisible: false,
      defaultVisibility: false,
    },
    {
      key: ServerFindingKeys.asset_name,
      type: IFindingFilterType.MULTI_SELECT,
      valueOptions: fetchedServerFilterOptions[ServerFindingKeys.asset_name] ?? [],
      selectedValue: [],
      defaultValue: [],
      isVisible: false,
      defaultVisibility: false,
    },
    {
      key: ServerFindingKeys.location_text,
      type: IFindingFilterType.MULTI_SELECT,
      valueOptions: fetchedServerFilterOptions[ServerFindingKeys.location_text] ?? [],
      selectedValue: [],
      defaultValue: [],
      isVisible: false,
      defaultVisibility: false,
    },
    {
      key: ServerFindingKeys.control_name,
      type: IFindingFilterType.MULTI_SELECT,
      valueOptions: fetchedServerFilterOptions[ServerFindingKeys.control_name] ?? [],
      selectedValue: [],
      defaultValue: [],
      isVisible: false,
      defaultVisibility: false,
    },
    {
      key: ServerFindingKeys.test_name,
      type: IFindingFilterType.MULTI_SELECT,
      valueOptions: fetchedServerFilterOptions[ServerFindingKeys.test_name] ?? [],
      selectedValue: '',
      defaultValue: '',
      isVisible: false,
      defaultVisibility: false,
    },
    {
      key: ServerFindingKeys.plan_item,
      type: IFindingFilterType.MULTI_SELECT,
      valueOptions: fetchedServerFilterOptions[ServerFindingKeys.plan_item] ?? [],
      selectedValue: '',
      defaultValue: '',
      isVisible: false,
      defaultVisibility: false,
    },
    {
      key: ServerFindingKeys.ignored,
      type: IFindingFilterType.MULTI_SELECT,
      valueOptions: [true, false],
      selectedValue: [false],
      defaultValue: [false],
      isVisible: true,
      defaultVisibility: true,
    },
    {
      key: ServerFindingKeys.team,
      type: IFindingFilterType.MULTI_SELECT,
      valueOptions: fetchedServerFilterOptions[ServerFindingKeys.team] ?? [],
      selectedValue: [],
      defaultValue: [],
      isVisible: false,
      defaultVisibility: false,
    },
    {
      key: ServerFindingKeys.branch,
      type: IFindingFilterType.MULTI_SELECT,
      valueOptions: fetchedServerFilterOptions[ServerFindingKeys.branch] ?? [],
      selectedValue: [],
      defaultValue: [],
      isVisible: false,
      defaultVisibility: false,
    },
    ...(isContextEngineEnabled ? [{
      key: ServerFindingKeys.priority_factors,
      type: IFindingFilterType.MULTI_SELECT,
      valueOptions: fetchedServerFilterOptions[ServerFindingKeys.priority_factors] ?? [],
      selectedValue: [],
      defaultValue: [],
      isVisible: false,
      defaultVisibility: false,
    }] : []),
  ], [isContextEngineEnabled]);

  return { getDefaultFilters };
};
