import { t } from 'i18next';
import { debounce } from 'lodash';
import { useState, useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';

import { getSubGroupPath as getSubOrganizationPath } from 'components/GitlabIntegrationWizard/StepComponents/ChooseGitlabGroup/utils';
import { useSCMService } from 'services/SCM/useSCMService';
import { Queries } from 'types/enums/Queries';
import { SCMVendors, Vendor } from 'types/enums/Vendor';
import { GitlabAccessLevel } from 'types/interfaces';
import { Repository, SubOrganization, Organization, selectedSCMResourcesCoverageType, allSCMResourcesCoverageType } from 'types/interfaces/SCM/SCMMember';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';
import { useSnackBar } from 'utils/hooks/useSnackBar';

const PER_PAGE_LIMIT = 20;
const SUGGESTED_REPOSITORIES_QUERY_KEY = 'suggested_repos';

const getStringOrEmpty = (value?: string): string => value ?? '';

const mapSCMResources = (scmResources: Repository[]) => (scmResources || []).map((scmresource): Repository => ({
  ...scmresource,
  pathWithNamespace: scmresource?.pathWithNamespace?.split('/').slice(1).join('/'),
})).sort((a, b) => getStringOrEmpty(a?.pathWithNamespace).localeCompare(getStringOrEmpty(b?.pathWithNamespace))) ?? [];

interface UseSCMResourcesDataProps {
  vendor: SCMVendors;
  selectedOrganization?: Organization;
  selectedRole?: GitlabAccessLevel;
  chosenSCMResources?: Repository[];
  chooseMode?: typeof allSCMResourcesCoverageType | typeof selectedSCMResourcesCoverageType | '';
}

export const useSCMResourcesData = ({
  vendor,
  selectedOrganization,
  selectedRole,
  chosenSCMResources = [],
  chooseMode,
}: UseSCMResourcesDataProps) => {
  const organizationId = selectedOrganization?.id ?? '';
  const [selectedSCMResources, setSelectedSCMResources] = useState<Repository[]>(chosenSCMResources);
  const [selectedSubOrganizations, setSelectedSubOrganizations] = useState<SubOrganization[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  const { showSnackBar } = useSnackBar();
  const { fetchSCMResources, fetchSubOrganizations } = useSCMService(vendor);

  const handleApiError = useCallback(() => {
    showSnackBar({
      title: t(`ChooseSCMResources.${vendor}.fetchingError`),
      description: t('ChooseSCMResources.fetchingErrorDescription'),
      type: 'error',
    });
  }, [vendor, showSnackBar]);

  const {
    isLoading: isSelectedSCMResourcesLoading,
    isRefetching: isSelectedSCMResourcesRefetching,
  } = useQuery(
    [Queries.SCMResources, vendor, organizationId, SUGGESTED_REPOSITORIES_QUERY_KEY],
    () => fetchSCMResources(organizationId, {
      page: 1,
      suggested_repos: true,
    }),
    {
      onError: handleApiError,
      onSuccess: (data) => {
        setSelectedSCMResources(mapSCMResources(data ?? []));
      },
      enabled: vendor === Vendor.Github,
      refetchOnMount: chooseMode !== allSCMResourcesCoverageType,
    },
  );

  const {
    data: scmResourcesData,
    isLoading: isRepositoriesLoading,
  } = useQuery(
    [Queries.SCMResources, vendor, organizationId, searchTerm],
    () => fetchSCMResources(organizationId, {
      page: 1,
      per_page: PER_PAGE_LIMIT,
      search: searchTerm,
    }),
    {
      onError: handleApiError,
    },
  );

  const {
    data: subOrganizationsData,
    isLoading: isSubOrganizationsLoading,
  } = useQuery(
    [Queries.SCMSubOrganizations, vendor, organizationId, searchTerm],
    async () => {
      const response = await fetchSubOrganizations(organizationId, {
        per_page: PER_PAGE_LIMIT,
        page: 1,
        search: searchTerm,
        min_access_level: selectedRole,
      });
      if (response) {
        return camelizeSnakeCaseKeys(response) as SubOrganization[];
      }
      return [];
    },
    {
      onError: handleApiError,
      enabled: vendor === Vendor.GITLAB,
    },
  );

  const scmResourceOptions = useMemo(
    () => mapSCMResources(scmResourcesData ?? []),
    [scmResourcesData],
  );

  const subOrganizationOptions = useMemo(
    () => (subOrganizationsData || []).map((subOrganization): SubOrganization => ({
      ...subOrganization,
      fullPath: getSubOrganizationPath(subOrganization?.fullPath),
    })).sort((a, b) => getStringOrEmpty(a?.fullPath).localeCompare(getStringOrEmpty(b?.fullPath))) ?? [],
    [subOrganizationsData],
  );

  const debouncedInputChange = debounce((value: string) => {
    setSearchTerm(value);
  }, 300);

  const handleInputChange = useCallback((value: string) => {
    if (value.length < 3 && value.length > 0) {
      return;
    }

    debouncedInputChange(value);
  }, [debouncedInputChange]);

  return {
    selectedSCMResources,
    setSelectedSCMResources,
    selectedSubOrganizations,
    setSelectedSubOrganizations,
    isSelectedSCMResourcesLoading: isSelectedSCMResourcesLoading || isSelectedSCMResourcesRefetching,
    isRepositoriesLoading,
    isSubOrganizationsLoading,
    scmResourceOptions,
    subOrganizationOptions,
    handleInputChange,
  };
};
