import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from '../ButtonMainComponent.module.scss';
import { useFindingsCount } from '../ViewFindingsStatus/useFindingsCount';

import { CircularLoadingSpinner } from 'components/CircularLoadingSpinner/CircularLoadingSpinner';
import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitText } from 'components/JitText/JitText';
import { useTenantContext } from 'context/TenantContext';
import { VIEW_FINDINGS_CHECK_SLUG } from 'context/TenantContext/constant';
import { constants } from 'globalConstants';

export const ViewFindingsButton: React.FC = () => {
  const navigate = useNavigate();

  const { contextEngineFinished, isLoading } = useFindingsCount();

  const { setCheckAsCompleted } = useTenantContext();
  const handleClick = useCallback(() => {
    setCheckAsCompleted(VIEW_FINDINGS_CHECK_SLUG);

    navigate(`/${constants.routes.FINDINGS}?ignored=false`);
  }, [navigate, setCheckAsCompleted]);

  if (isLoading) {
    return <CircularLoadingSpinner />;
  }

  const text = contextEngineFinished ? 'pages.quickStart.checks.viewFindings.prioritized.ctaText' : 'pages.quickStart.checks.viewFindings.raw.ctaText';

  return (
    <JitGradientButton isLoading={isLoading} onClick={handleClick}>
      <div className={styles.buttonContent}>
        <JitText size='s' text={text} />
      </div>
    </JitGradientButton>
  );
};
