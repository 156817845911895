import Box from '@mui/material/Box';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import { FC } from 'react';

import { PROGRESS_SIZE, THICKNESS, PROGRESS_MAX_VALUE, GRADIENT_COLORS } from './constants';
import styles from './GradientCircularProgressBar.module.scss';

type Props = Pick<CircularProgressProps, 'size' | 'thickness'>;

export const GradientCircularProgressBar: FC<Props> = ({ size = PROGRESS_SIZE, thickness = THICKNESS }) => (

  <>
    <svg className={styles.gradientDefs}>
      <defs>
        <linearGradient id='combined_gradient' x1='0%' x2='0%' y1='0%' y2='100%'>
          <stop offset='40%' stopColor={GRADIENT_COLORS.START} />

          <stop offset='100%' stopColor={GRADIENT_COLORS.END} />
        </linearGradient>
      </defs>
    </svg>

    <Box className={styles.progressContainer} data-testid='gradient-circular-progress-bar'>
      <CircularProgress
        className={styles.backgroundProgress}
        size={size}
        thickness={thickness}
        value={PROGRESS_MAX_VALUE}
        variant='determinate'
      />

      <CircularProgress
        className={styles.spinningProgress}
        size={size}
        thickness={thickness}
        value={25}
        variant='determinate'
      />
    </Box>
  </>
);
