import { FC } from 'react';

import styles from './LoadingOverlay.module.scss';

import { GradientCircularProgressBar } from 'components/GradientCircularProgressBar/GradientCircularProgressBar';
import { JitText } from 'components/JitText/JitText';

export const LoadingOverlay: FC = () => (
  <div className={styles.loadingOverlay} data-testid='loading-overlay'>
    <GradientCircularProgressBar size={108} thickness={6} />

    <JitText bold muted text='pages.quickStart.checks.exploreResults.loadingOverlayText' />
  </div>
);
