import { 
    Label, LabelProps,
    Button, ButtonProps,
    Funnel, FunnelProps,
    SimpleTable, SimpleTableProps,
    ComplexCard, ComplexCardProps,
    SimpleCard, SimpleCardProps,
    BreakdownList, BreakdownListProps,
    Chart, ChartProps,
  } from 'pages/Jitto/ChatPage/components/JittoReportRenderer/JittoDashboardWidgets/core';
  import { 
    Section, SectionProps,
    FlexList, FlexListProps,
  } from 'pages/Jitto/ChatPage/components/JittoReportRenderer/JittoDashboardWidgets/layout';
  
  import {
    CardsList, CardsListProps,
  } from 'pages/Jitto/ChatPage/components/JittoReportRenderer/JittoDashboardWidgets/custom';


export const itemsMap = {
    // Core
    Label,
    Button,
    Funnel,
    SimpleTable,
    SimpleCard,
    BreakdownList,
    Chart,
    ComplexCard,
  
    // Layout
    FlexList,
    Section,
    
    // Custom
    CardsList,
  } as const;

  type ItemsPropsMap = {
    Label: LabelProps;
    Button: ButtonProps;
    Funnel: FunnelProps;
    SimpleTable: SimpleTableProps;
    ComplexCard: ComplexCardProps;
    SimpleCard: SimpleCardProps;
    BreakdownList: BreakdownListProps;
    Chart: ChartProps;
    FlexList: FlexListProps;
    Section: SectionProps;
    CardsList: CardsListProps;
  };

type ItemType = keyof typeof itemsMap;

type ItemData<T extends ItemType = ItemType> = {
    id: string;
    itemType: T;
    props: Omit<ItemsPropsMap[T], 'children'> & {items?: ItemData[]};
  };

  export type ReportData = ItemData[];