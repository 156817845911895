/* eslint-disable max-statements */
import { FC, useCallback, useEffect, useMemo } from 'react';

import { DiscoveringState } from '../DiscoveringState/DiscoveringState';

import { useAssetInitialStatsWebsocket } from './hooks/useAssetInitialStatsWebsocket';
import { usePlanItems } from './hooks/usePlanItems';
import { ProgressBarPanel } from './ProgressBarPanel';
import styles from './SecurityPlansProgress.module.scss';

import { AnimatedList } from 'components/AnimatedList/AnimatedList';
import { CircularLoadingSpinner } from 'components/CircularLoadingSpinner/CircularLoadingSpinner';
import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitText } from 'components/JitText/JitText';
import { useConfigurationsContext } from 'context/ConfigurationsContext/ConfigurationsContext';
import { useFetchConfigurations } from 'context/ConfigurationsContext/hooks';
import { useTenantContext } from 'context/TenantContext';
import { EXPLORE_APPSEC_PLAN_CHECK_SLUG } from 'context/TenantContext/constant';
import { useUpdateCheckStatus } from 'context/TenantContext/tenantHooks/useUpdateCheckStatus';
import { useWebsocketSubscribe } from 'context/WebSocketContext/hooks';
import { CheckStatus } from 'pages/QuickStartGuide/types';
import colors from 'themes/colors.module.scss';
import { WebSocketNotificationTopics } from 'types/enums';

export const SecurityPlansProgress: FC = () => {
  const { websocketSubscribe } = useWebsocketSubscribe();

  const { updateCheckStatus, isLoading: isUpdatingCheckStatus } = useUpdateCheckStatus();
  const { checks } = useTenantContext();

  const { handleAssetInitialStatsWebsocketNotification } = useAssetInitialStatsWebsocket();

  const { planItems, isLoading: isPlansLoading, assetStats } = usePlanItems();

  const { isLoadingConfigurations, fetchedConfigurations } = useConfigurationsContext();
  // important for additional items auto-configurations.
  useFetchConfigurations();

  useEffect(() => {
    websocketSubscribe(
      WebSocketNotificationTopics.AssetScanStats,
      handleAssetInitialStatsWebsocketNotification,
    );
  }, [handleAssetInitialStatsWebsocketNotification, websocketSubscribe]);

  const isAllCompleted = useMemo(
    () => assetStats?.every((item) => item.inProgressAssetCount === 0),
    [assetStats],
  );

  const titleKey = useMemo(
    () => (isAllCompleted
      ? 'pages.quickStart.checks.securityPlansProcess.completed.title'
      : 'pages.quickStart.checks.securityPlansProcess.scanning.title'),
    [isAllCompleted],
  );

  const handleResultsButtonClick = useCallback(() => {
    updateCheckStatus({
      checkSlug: EXPLORE_APPSEC_PLAN_CHECK_SLUG,
      status: CheckStatus.COMPLETED,
    });
  }, [updateCheckStatus]);

  const renderOptionalPlans = () => (
    <div className={styles.optionalPlans}>
      <JitText
        color={colors.gray}
        size='xs'
        text='pages.quickStart.checks.securityPlansProcess.optionalPlans.title'
      />

      <AnimatedList
        className={styles.processBarsWrapper}
        data-testid='security-plans-progress-optional-plans'
      >
        {planItems.map((item) => (
          <ProgressBarPanel
            key={item.slug}
            action={item.action}
            completed={item.completed || 0}
            title={item.title}
            total={item.total || 0}
          />
        ))}
      </AnimatedList>
    </div>
  );

  const isCheckCompleted = useMemo(
    () => checks?.some((check) => check.checkSlug === EXPLORE_APPSEC_PLAN_CHECK_SLUG && check.status === CheckStatus.COMPLETED),
    [checks],
  );

  const renderContent = () => {
    if (isPlansLoading || (isLoadingConfigurations && !fetchedConfigurations)) {
      return (
        <div className={styles.content} data-testid='security-plans-progress-loading'>
          <CircularLoadingSpinner />
        </div>
      );
    }
    if (!assetStats?.length) {
      return <DiscoveringState text='pages.quickStart.checks.securityPlansProcess.waitingForExecutionsText' />;
    }

    return (
      <>
        <JitText
          color={colors.gray}
          data-testid='security-plans-progress-title'
          size='xs'
          text={titleKey}
        />

        <AnimatedList
          className={styles.processBarsWrapper}
          data-testid='security-plans-progress-bars'
        >
          {assetStats.map((item) => (
            <ProgressBarPanel
              key={item.planItemSlug}
              completed={item.completedAssetCount}
              title={item.planItemDisplayName}
              total={item.completedAssetCount + item.inProgressAssetCount}
            />
          ))}
        </AnimatedList>

        {renderOptionalPlans()}
      </>
    );
  };

  return (
    <div className={styles.wrapper} data-testid='security-plans-progress'>
      {renderContent()}

      <div className={styles.buttons} data-testid='security-plans-progress-buttons'>
        {!isCheckCompleted && (
          <JitGradientButton
            className={styles.exploreButton}
            data-testid='security-plans-progress-results-button'
            disabled={isUpdatingCheckStatus}
            isLoading={isUpdatingCheckStatus}
            onClick={handleResultsButtonClick}
            showChildrenWhileLoading
          >
            <JitText
              bold
              data-testid='security-plans-progress-results-text'
              fontFamily='Inter, serif'
              size='s'
              text='pages.quickStart.checks.securityPlansProcess.resultsButton'
            />
          </JitGradientButton>
        )}

      </div>
    </div>
  );
};
