/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { getToolCategoryTagArray } from '../../../utils/toolCategoryTags';
import { SecurityToolType } from '../../SecurityToolsSidebar/SecurityToolsSidebar';

import { JitCard } from 'components/JitCard/JitCard';
import { useDetailsByVendor } from 'utils/hooks/useDetailsByVendor';

interface SecurityToolCardProps {
  vendor: string;
  name: string;
  description: string;
  category: SecurityToolType;
  testId?: string;
  isNew?: boolean;
  learnMoreUrl?: string;
  disableNavigation?: boolean;
}

export const SecurityToolCard: FC<SecurityToolCardProps> = ({
  vendor,
  name,
  description,
  category,
  testId,
  isNew = false,
  learnMoreUrl,
  disableNavigation = false,
}) => {
  const navigate = useNavigate();
  const { getIconByVendor } = useDetailsByVendor();

  // Get the icon component using the vendor
  const icon = useMemo(() => getIconByVendor(vendor), [vendor, getIconByVendor]);

  // Define learn more link if available
  const learnMoreLink = useMemo(
    () => (learnMoreUrl ? {
      text: 'securityToolsSection.buttons.learnMore',
      href: learnMoreUrl,
    } : undefined),
    [learnMoreUrl],
  );

  // Handle card click to navigate to the security tool's dedicated page
  const handleCardClick = () => {
    if (!disableNavigation) {
      // Convert the tool name to a URL-friendly format (lowercase, replace spaces with hyphens)
      const toolNameSlug = name.toLowerCase().replace(/\s+/g, '-');
      navigate(`/security-tools/${toolNameSlug}`);
    }
  };

  return (
    <div
      onClick={handleCardClick}
      style={{ cursor: disableNavigation ? 'default' : 'pointer' }}
    >
      <JitCard
        description={description}
        headerIcons={{ icons: [{ icon }] }}
        isNew={isNew}
        link={learnMoreLink}
        tags={getToolCategoryTagArray(category)}
        testid={testId || `security-tool-${name}`}
        title={name}
      />
    </div>
  );
};
