import { FC } from 'react';

import { CustomIntegrationType, getCustomIntegrationsData } from './customIntegrations';

import { IntegrationTemplate, IntegrationType, IntegrationVendorType } from 'context/IntegrationsContext/templates/interfaces';
import { i18n } from 'locale/i18n';
import { ISvg } from 'types/interfaces';

interface DynamicIntegration {
  vendor: string;
  variant: IntegrationVendorType;
  icon: FC<ISvg>;
}

interface CategoryCounts {
  [key: string]: number;
}

interface SpecialCard {
  vendor: CustomIntegrationType;
  type: IntegrationVendorType;
  label: string;
  description: string;
}

/**
 * Helper function to check if an integration matches the search term
 */
const matchesSearchTerm = (
  label: string | undefined,
  description: string | undefined,
  vendorType: IntegrationVendorType,
  searchTerm = '',
): boolean => {
  if (!searchTerm) return true;

  const term = searchTerm.toLowerCase().trim();

  // Search in label/title
  if (label?.toLowerCase().includes(term)) return true;

  // Search in description
  if (description?.toLowerCase().includes(term)) return true;

  // Search in vendor type (category)
  const vendorTypeDisplay = i18n.t(`cards.vendorTypes.${vendorType}`).toLowerCase();
  if (vendorTypeDisplay.includes(term)) return true;

  return false;
};

/**
 * Get special cards data with translations
 */
const getSpecialCardsData = (): SpecialCard[] => getCustomIntegrationsData().map(({ vendor, vendorType }) => ({
  vendor,
  type: vendorType,
  label: i18n.t(`vendor.${vendor}`),
  description: i18n.t(`cards.${vendor}.descriptions.primary`),
}));

/**
 * Process special cards and increment counts for matching cards
 */
const processSpecialCards = (
  specialCards: SpecialCard[],
  integrationTemplates: IntegrationTemplate[],
  searchTerm: string,
  incrementCount: (category: string) => void,
): void => {
  specialCards.forEach(({ vendor, type, label, description }) => {
    // Check if this special card should be counted
    if (vendor === 'aws') {
      // Check if AWS integration exists in the data
      const awsExists = integrationTemplates.some((template) => template.vendor === 'aws'
        && (template.integrationType === IntegrationType.custom
         || template.integrationType === IntegrationType.secret));

      // Only count if it exists and matches the search term
      if (awsExists && matchesSearchTerm(label, description, type, searchTerm)) {
        incrementCount(type);
      }
    } else if (matchesSearchTerm(label, description, type, searchTerm)) {
      // For other special cards, check if they match the search term
      incrementCount(type);
    }
  });
};

export const getCategoryCounts = (
  integrationTemplates: IntegrationTemplate[],
  dynamicIntegrations: DynamicIntegration[],
  searchTerm = '',
): CategoryCounts => {
  const counts: CategoryCounts = { all: 0 };

  // Helper to increment counts
  const incrementCount = (category: string) => {
    counts[category] = (counts[category] || 0) + 1;
    counts.all += 1;
  };

  // Get special cards data
  const specialCards = getSpecialCardsData();

  // Process special cards
  processSpecialCards(specialCards, integrationTemplates, searchTerm, incrementCount);

  // Count dynamic integrations that match the search term
  dynamicIntegrations.forEach((integration) => {
    // Skip if this is a special card that we've already counted
    const isSpecial = specialCards.some((special) => special.vendor === integration.vendor);
    if (isSpecial) return;

    const template = integrationTemplates.find((t) => t.vendor === integration.vendor);
    if (template && matchesSearchTerm(template.label || '', template.shortDescription || '', integration.variant, searchTerm)) {
      incrementCount(integration.variant);
    }
  });

  // Count remaining templates that match the search term
  integrationTemplates
    .filter((template) => {
      // Skip templates that are already counted (special or dynamic)
      const isSpecial = specialCards.some((special) => special.vendor === template.vendor);
      const isDynamic = dynamicIntegrations.some((dynamic) => dynamic.vendor === template.vendor);
      const isValidType = template.integrationType === IntegrationType.secret
        || template.integrationType === 'comingSoon'
        || (template.integrationType === IntegrationType.thirdParty
          && !dynamicIntegrations.some((integration) => integration.vendor === template.key));

      // Only include if it's valid and matches the search term
      return !isSpecial && !isDynamic && isValidType
        && matchesSearchTerm(template.label || '', template.shortDescription || '', template.vendorType, searchTerm);
    })
    .forEach((template) => {
      incrementCount(template.vendorType);
    });

  return counts;
};
