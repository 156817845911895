import { t } from 'i18next';

import { getSelectedProjectsText } from '../utils';

import styles from './SelectedProjects.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { Vendor } from 'types/enums';
import { Repository, SubOrganization } from 'types/interfaces/SCM/SCMMember';

interface SelectedProjectsProps {
  selectedSCMResources: Repository[];
  selectedSubOrganizations: SubOrganization[];
  setShowAssetsDialog: (show: boolean) => void;
  areSCMResourcesSelected: boolean;
  isCompleted: boolean;
}

export const SelectedProjects = ({ isCompleted, areSCMResourcesSelected, selectedSCMResources, selectedSubOrganizations, setShowAssetsDialog }: SelectedProjectsProps) => {
  if (isCompleted || !areSCMResourcesSelected) {
    return null;
  }

  const handleEditClick = () => {
    setShowAssetsDialog(true);
  };

  return (
    <div className={styles.selectedProjectsTextContainer}>
      <JitText size='l' text={getSelectedProjectsText(Vendor.GITLAB, selectedSCMResources, selectedSubOrganizations)} />

      <JitText bold color={colors.blue02} onClick={handleEditClick} size='l' text={t(`ChooseSCMResources.${Vendor.GITLAB}.radioButtons.selected.edit`).toString()} />
    </div>
  );
};
