import { FC, memo } from 'react';
import { DrilldownSection } from 'types/interfaces/Drilldown/types';
import FindingInfoSection from './sections/FindingInfoSection';
import TextSection from './sections/TextSection';
import TaskStepsSection from './sections/TaskStepsSection';
import GraphSection from './sections/GraphSection';
import { Typography } from '@mui/material';
import { JitSidePanelItem } from 'components/JitSidePanel/JitSidePanelItem/JitSidePanelItem';

interface SectionRendererProps {
  section: DrilldownSection;
}

/**
 * SectionRenderer component that renders the appropriate section component based on the section type
 * This component determines which section component to use and forwards the section data
 */
const SectionRenderer: FC<SectionRendererProps> = ({ section }) => {
  // Render the appropriate component based on the section type
  switch (section.type) {
    case 'FindingInfo':
      return <FindingInfoSection section={section} />;
    case 'TextSection':
      return <TextSection section={section} />;
    case 'TaskSteps':
      return <TaskStepsSection section={section} />;
    case 'Graph':
      return <GraphSection section={section} />;
    default:
      // Fallback for unknown section types
      return (
        <JitSidePanelItem
          title="Unknown Section Type"
          variant='light'
          wrapperStyleOverrides={{ 
            borderRadius: '8px',
            backgroundColor: 'error.light',
            border: '1px solid',
            borderColor: 'error.main'
          }}
        >
          <Typography 
            variant="subtitle1" 
            color="error"
            sx={{ fontWeight: 500 }}
          >
            Unknown section type: {(section as any).type}
          </Typography>
        </JitSidePanelItem>
      );
  }
};

// Use memo to prevent unnecessary re-renders when section content hasn't changed
export default memo(SectionRenderer); 