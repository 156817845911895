import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { PolicyContent } from './components/PolicyContent/PolicyContent';
import { PolicyHeader } from './components/PolicyHeader/PolicyHeader';
import styles from './PoliciesPage.module.scss';
import { IPolicyTemplate } from './types';

import { JitText } from 'components/JitText/JitText';
import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { PageLayout } from 'components/Pages/PageLayout/PageLayout';
import { PageTitles } from 'components/PageTitles/PageTitles';
import { PremiumFeatureBanner } from 'components/PremiumFeatureBanner/PremiumFeatureBanner';
import { PRODUCT_CATALOG_FEATURES } from 'context/StiggContext/constants';
import { useGetBooleanEntitlement } from 'context/StiggContext/useGetBooleanEntitlement';
import { i18n } from 'locale/i18n';
import { CollapseSection } from 'pages/OverviewPage/components/CollapseSection/CollapseSection';
import { usePoliciesService } from 'services/PoliciesService/usePoliciesService';
import { Queries } from 'types/enums/Queries';

export const PoliciesPage = () => {
  const { getPoliciesTemplate } = usePoliciesService();
  const { data, isLoading } = useQuery(Queries.policiesTemplate, getPoliciesTemplate);
  const getBooleanEntitlement = useGetBooleanEntitlement();

  const isEntitled = useMemo(
    () => getBooleanEntitlement(PRODUCT_CATALOG_FEATURES.POLICY_MANAGEMENT_AND_WORKFLOWS, true),
    [getBooleanEntitlement],
  );

  if (isLoading) {
    return <LoadingBar />;
  }

  const premiumBannerMessageText = i18n.t('pages.workflows.premiumBannerMessage');

  return (
    <PageLayout>
      <div className={styles.root}>
        <div className={styles.header}>
          <PageTitles title='pages.policies.title' />

          <div className={styles.bannerWrapper}>
            <PremiumFeatureBanner featureName='Policies' isEntitled={isEntitled} message={premiumBannerMessageText} />
          </div>
        </div>

        <div className={styles.content}>
          <JitText muted size='s' text='pages.policies.subtitle' />

          <div className={styles.policies}>
            {data?.map((template: IPolicyTemplate) => (
              <CollapseSection
                key={template.policySlug}
                header={(
                  <PolicyHeader
                    description={template.display.description}
                    docsLink={template.display.docsLink}
                    enabled={template.dynamicData.enabledRulesCount}
                    icon={template.display.icon}
                    title={template.display.displayName}
                  />
                )}
                withBackground
              >
                <PolicyContent template={template} />
              </CollapseSection>
            ))}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

