import Markdown from 'markdown-to-jsx';
import { FC } from 'react';

import { UseGetRelevantActivationButton } from '../../hooks/UseGetRelevantActivationButton/UseGetRelevantActivationButton';
import { PlanItemDetailsIntegrations } from '../PlanItemDetailsIntegrations/PlanItemDetailsIntegrations';

import styles from './PlanItemDetailsInfo.module.scss';

import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { JitSidePanelItem } from 'components/JitSidePanel/JitSidePanelItem/JitSidePanelItem';
import { JitText } from 'components/JitText/JitText';
import { ItemTypeBadge } from 'pages/PlanPage/components/ItemTypeBadge/ItemTypeBadge';
import { Vendor } from 'types/enums';
import { IMouseEvent, IPlanItemDetails } from 'types/interfaces';
import { useAssetTypeToVendor } from 'utils/constants/assetTypeConfigurations/assetTypeConfigurations';
import { markdownOptionsProps } from 'utils/constants/markdownOptionsProps';
import { vendorsWithIntegration } from 'utils/constants/vendorIntegrations';
import { parseMarkdown } from 'utils/functions/parseMarkdown';

interface Props {
  planItem: IPlanItemDetails;
  openDeactivateConfirmDialog: () => void;
  onActivate: (e: IMouseEvent) => void;
  isPlanItemActive: boolean;
  onConfigure: () => void;
  isItemConfigurable: boolean;
  onRedirect: () => void;
  isItemRedirecting: boolean,
}

export const PlanItemDetailsInfo: FC<Props> = ({
  planItem, openDeactivateConfirmDialog, onActivate, isPlanItemActive, onConfigure, isItemConfigurable, onRedirect, isItemRedirecting,
}) => {
  const { actionButtons } = UseGetRelevantActivationButton(planItem, openDeactivateConfirmDialog, onActivate, isPlanItemActive, onConfigure, isItemConfigurable, onRedirect, isItemRedirecting);
  const hasIntegrations = planItem.asset_types !== undefined && planItem.asset_types.length > 0;
  const { getVendorByAssetType } = useAssetTypeToVendor();

  const isVendorRequireIntegration = (vendor: Vendor | undefined) => vendor && vendorsWithIntegration.includes(vendor);
  const assetTypesWithIntegration = planItem?.asset_types?.filter((assetType) => isVendorRequireIntegration(getVendorByAssetType(assetType)));
  const integrationAssetTypesVendors = assetTypesWithIntegration.map((assetType) => getVendorByAssetType(assetType));
  const anyVendorRequireIntegrationExists = integrationAssetTypesVendors.some((vendor) => !!vendor);

  const shouldDisplayIntegrations = hasIntegrations && !!assetTypesWithIntegration?.length && anyVendorRequireIntegrationExists;

  const buttons = (
    <>
      {actionButtons.map((button) => (
        <JitActionButton key={button.label} actionButton={button} />
      ))}
    </>
  );
  return (
    <>
      <JitSidePanelItem actionComponent={buttons} title='pages.plan.itemDetails.info.title'>
        <div className={styles.infoHeaderWrapper}>
          <ItemTypeBadge itemType={planItem.is_manual ? 'manual' : 'auto'} />

          <JitText bold text={planItem.name} />
        </div>

        <div className={styles.text}>
          <Markdown {...markdownOptionsProps}>
            {parseMarkdown(planItem.description)}
          </Markdown>
        </div>
      </JitSidePanelItem>

      <JitSidePanelItem title='pages.plan.itemDetails.info.whatWillJitDo'>
        <div className={styles.text}>
          <Markdown {...markdownOptionsProps}>
            {parseMarkdown(planItem.summary)}
          </Markdown>
        </div>
      </JitSidePanelItem>

      {shouldDisplayIntegrations && (
        <JitSidePanelItem title='pages.plan.itemDetails.integrations'>
          <div className={styles.bodySectionWrapper}>
            <PlanItemDetailsIntegrations assetTypes={planItem.asset_types || []} />
          </div>
        </JitSidePanelItem>
      )}

    </>
  );
};
