import { FC } from 'react';
import { Box, Paper, Grid, Divider } from '@mui/material';
import { TextSection as TextSectionType } from 'types/interfaces/Drilldown/types';
import { JitSidePanelItem } from 'components/JitSidePanel/JitSidePanelItem/JitSidePanelItem';
import { JitText } from 'components/JitText/JitText';

interface TextSectionProps {
  section: TextSectionType;
}

const TextSection: FC<TextSectionProps> = ({ section }) => {
  return (
    <JitSidePanelItem
      title={section.title}
      variant='light'
      wrapperStyleOverrides={{ borderRadius: '8px' }}
    >
      {section.intro_text && (
        <JitText 
          text={section.intro_text}
        />
      )}
      
      {section.variant === 'blocks' ? (
        <Grid container spacing={2}>
          {section.sections.map((item, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Paper 
                elevation={1} 
                sx={{ 
                  p: 2, 
                  height: '100%',
                  backgroundColor: '#121926',
                }}
              >
                <JitText 
                  text={item.title}
                  muted
                  size="s"
                />
                <JitText 
                  text={item.description}
                />
              </Paper>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box sx={{ mt: 1 }}>
          {section.sections.map((item, index) => (
            <Box 
              key={index} 
              sx={{ 
                mb: 2,
              }}
            >
              <JitText 
                text={item.title}
                bold
              />
              <JitText 
                text={item.description}
                muted
              />
              {index < section.sections.length - 1 && (
                <Divider sx={{ mt: 3 }} />
              )}
            </Box>
          ))}
        </Box>
      )}
    </JitSidePanelItem>
  );
};

export default TextSection; 