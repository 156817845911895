import { createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useCallback, useContext, useMemo, useState } from 'react';

import { validatorsByItemSlug, validatorsByVendor } from './validators';

import { AssetType } from 'types/enums';
import { IConfigurations } from 'types/interfaces';
import { useAssetTypeToVendor } from 'utils/constants/assetTypeConfigurations/assetTypeConfigurations';
import { vendorsWithIntegration, vendorsThatDontRequireIntegration } from 'utils/constants/vendorIntegrations';

interface IConfigurationsContext {
  configurations: IConfigurations;
  fetchedConfigurations?: IConfigurations;
  configurationsCommit: string;
  setConfigurationsCommit: (commit: string) => void;
  setConfigurations: Dispatch<SetStateAction<IConfigurations>>;
  setFetchedConfigurations: (configurations: IConfigurations | undefined) => void;
  isProperlyIntegrated: (assetTypes: AssetType[]) => boolean;
  isProperlyConfigured: (itemSlug: string, assetType?: AssetType) => boolean;
  isLoadingConfigurations: boolean;
  setIsLoadingConfigurations: (isLoading: boolean) => void;
  isCommittingConfigurations: boolean;
  setIsCommittingConfigurations: (isCommitting: boolean) => void;
  isAssetTypeProperlyIntegratedInConfig: (assetType: AssetType) => boolean;
}

export const ConfigurationsContext = createContext<IConfigurationsContext>({
  fetchedConfigurations: {},
  setFetchedConfigurations: () => undefined,
  configurations: {},
  configurationsCommit: '',
  setConfigurationsCommit: () => undefined,
  setConfigurations: () => undefined,
  isProperlyIntegrated: () => true,
  isProperlyConfigured: () => true,
  isLoadingConfigurations: false,
  setIsLoadingConfigurations: () => {
  },
  isCommittingConfigurations: false,
  setIsCommittingConfigurations: () => {
  },
  isAssetTypeProperlyIntegratedInConfig: () => true,
});

export const useConfigurationsContext = () => useContext(ConfigurationsContext);

export const ConfigurationsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [configurations, setConfigurations] = useState<IConfigurations>({});
  const [configurationsCommit, setConfigurationsCommit] = useState<string>('');
  const [fetchedConfigurations, setFetchedConfigurations] = useState<IConfigurations | undefined>(undefined);
  const [isLoadingConfigurations, setIsLoadingConfigurations] = useState<boolean>(false);
  const [isCommittingConfigurations, setIsCommittingConfigurations] = useState<boolean>(false);
  const { getVendorByAssetType } = useAssetTypeToVendor();

  const isAssetTypeProperlyIntegratedInConfig = useCallback((assetType: AssetType) => {
    if (!fetchedConfigurations) {
      return true;
    }
    const vendor = getVendorByAssetType(assetType);
    if (!!vendor && vendorsWithIntegration.includes(vendor) && !vendorsThatDontRequireIntegration.includes(vendor)) {
      const existingConfiguration = configurations[vendor as string];
      return !!existingConfiguration;
    }
    return true;
  }, [configurations, fetchedConfigurations, getVendorByAssetType]);

  const isProperlyIntegrated = useCallback((assetTypes: AssetType[]) => !assetTypes || assetTypes.every(isAssetTypeProperlyIntegratedInConfig), [isAssetTypeProperlyIntegratedInConfig]);

  const isProperlyConfigured = useCallback(
    (itemSlug: string, assetType?: AssetType) => {
      if (!fetchedConfigurations) {
        return true;
      }

      const vendor = assetType && getVendorByAssetType(assetType);
      const isValidConfigBySlug = !(itemSlug in validatorsByItemSlug) || validatorsByItemSlug[itemSlug](configurations);
      const isValidConfigByVendor = !vendor || (!(vendor in validatorsByVendor) || validatorsByVendor[vendor](configurations));
      return isValidConfigBySlug && isValidConfigByVendor;
    },
    [configurations, fetchedConfigurations, getVendorByAssetType],
  );

  const value = useMemo(
    () => ({
      configurations,
      setConfigurations,
      fetchedConfigurations,
      setFetchedConfigurations,
      configurationsCommit,
      setConfigurationsCommit,
      isProperlyIntegrated,
      isProperlyConfigured,
      isLoadingConfigurations,
      setIsLoadingConfigurations,
      isCommittingConfigurations,
      setIsCommittingConfigurations,
      isAssetTypeProperlyIntegratedInConfig,
    }),
    [
      configurations,
      setConfigurations,
      configurationsCommit,
      setConfigurationsCommit,
      fetchedConfigurations,
      setFetchedConfigurations,
      isProperlyIntegrated,
      isProperlyConfigured,
      isLoadingConfigurations,
      setIsLoadingConfigurations,
      isCommittingConfigurations,
      setIsCommittingConfigurations,
      isAssetTypeProperlyIntegratedInConfig,
    ],
  );
  return <ConfigurationsContext.Provider value={value}>{children}</ConfigurationsContext.Provider>;
};
