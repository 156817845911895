import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { RisksBreadcrumbs } from '../components/RisksBreadcrumbs/RisksBreadcrumbs';
import { EmptyResourcePage } from '../EmptyResourcePage/EmptyResourcePage';

import { FindingsTableWrapper } from './components/FindingsTableWrapper/FindingsTableWrapper';
import styles from './ResourcePage.module.scss';

import { JitText } from 'components/JitText/JitText';
import { PageLayout } from 'components/Pages/PageLayout/PageLayout';
import { PageTitles } from 'components/PageTitles/PageTitles';
import { PremiumFeatureBanner } from 'components/PremiumFeatureBanner/PremiumFeatureBanner';
import { PriorityFactorsGraph } from 'components/PriorityFactorsGraph/PriorityFactorsGraph';
import { useAssetsContext } from 'context/AssetsContext';
import { GraphProvider } from 'context/GraphContext/GraphProvider';
import { useContextEngine } from 'context/GraphContext/hooks/useContextEngine';
import { constants } from 'globalConstants';
import { ResourceDetails } from 'pages/Resources/ResourcePage/components/ResourceDetails/ResourceDetails';
import { getAssetDisplayName } from 'utils/constants/assetTypeConfigurations/assetTypeConfigurations';

export const ResourcePage: FC = () => {
  const { assets } = useAssetsContext();
  const { isContextEngineEnabled, isEntitled } = useContextEngine();
  const assetId = useParams()[constants.routes.pathParameters.RESOURCE_ID];

  const asset = useMemo(
    () => assets.find((a) => a.asset_id === assetId),
    [assets, assetId],
  );

  const pageTitle = asset ? getAssetDisplayName(asset) : '';

  const graph = useMemo(() => (
    isContextEngineEnabled ? (
      <div className={styles.graphWrapper}>
        <JitText size='m' text='pages.risks.resources.resourcePage.graphTitle' />

        <PriorityFactorsGraph entity={asset} />
      </div>
    ) : null
  ), [asset, isContextEngineEnabled]);

  if (!isEntitled) {
    return <EmptyResourcePage />;
  }

  return (
    <PageLayout>
      <GraphProvider>
        <div className={styles.wrapper}>
          <RisksBreadcrumbs asset={asset} />

          <div className={styles.bannerWrapper}>
            <PremiumFeatureBanner featureName='Resources' isEntitled={isEntitled} />
          </div>

          <PageTitles title={pageTitle} />

          <ResourceDetails asset={asset} />

          <div className={styles.bodyWrapper}>
            {graph}

            <FindingsTableWrapper asset={asset} />
          </div>
        </div>
      </GraphProvider>
    </PageLayout>
  );
};
