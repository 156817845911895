import { useCallback, useState } from 'react';

import { useWebSocketContext } from 'context/WebSocketContext/WebSocketContext';
import { WebSocketNotificationTopics } from 'types/enums';
import { WebsocketNotificationCallback } from 'types/types';

export const useWebsocketSubscribe = () => {
  const { subscribeTopic, setTopicHandler } = useWebSocketContext();
  const [handlerId, setHandlerId] = useState<string>('');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const websocketSubscribe = useCallback(<T extends object = any, U extends object = T>(
    topic: WebSocketNotificationTopics, handler: WebsocketNotificationCallback<T, U>,
  ) => {
    if (!handlerId) {
      const { handlerId: newHandlerId } = subscribeTopic(topic, handler);
      setHandlerId(newHandlerId);
      setTopicHandler(topic, handler, newHandlerId);
    } else {
      setTopicHandler(topic, handler, handlerId);
    }
  }, [handlerId, setTopicHandler, subscribeTopic]);

  return { websocketSubscribe };
};
