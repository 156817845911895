import React from 'react';
import { Typography, Paper } from '@mui/material';
import { MetricCardProps } from '../types';

const MetricCard: React.FC<MetricCardProps> = ({ metric }) => {
  return (
    <Paper
      elevation={0}
      sx={{
        p: 2.5,
        height: '100%',
        borderRadius: '12px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        bgcolor: '#1C2235',
      }}
    >
      <Typography 
        sx={{ 
          fontFamily: 'Inter',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '143%',
          letterSpacing: '0.15px',
          textAlign: 'center',
          color: 'rgba(255, 255, 255, 1)',
          mb: 0.5,
        }}
      >
        {metric.title}
      </Typography>
      <Typography 
        variant="h4" 
        component="div" 
        sx={{ 
          fontWeight: 800,
          fontSize: '2rem',
          lineHeight: 1.2,
          color: '#fff',
        }}
      >
        {metric.value}
      </Typography>
      <Typography 
        sx={{ 
          fontSize: '0.875rem',
          color: 'rgba(255, 255, 255, 0.6)',
          mt: 0.5,
        }}
      >
        {metric.subtext}
      </Typography>
    </Paper>
  );
};

export default MetricCard; 