import { generateTitle } from './title';

import { OpenTicket } from 'components/CreateTicketButton/VendorConfig';
import { constants } from 'globalConstants';
import { logError, logInfo } from 'services/logger/logger';
import { IFinding } from 'types/interfaces';
import { IntegrationProvider } from 'types/interfaces/Integrations/IIntegration';
import { TicketError, TicketResponse } from 'types/interfaces/TicketManager/Ticket';
import { useSnackBar } from 'utils/hooks/useSnackBar';

export const useCreateTicketSync = () => {
  const { showSnackBar } = useSnackBar();
  const { time: { SECOND } } = constants;
  // TODO: once the backend will be ready we should handle errors and success in some other way
  // maybe we should check the status code in the response for errors.

  const displayErrorToast = (error: string, findings: IFinding[]) => {
    logError(`Failed to create ticket. Error: ${error}. For findings ${findings.map((finding) => finding.id).join(', ')}`);
    showSnackBar({
      title: 'tickets.failedToast.title',
      description: error,
      type: 'error',
      options: {
        autoClose: SECOND * 30,
      },
    });
  };

  const createTicket = async (
    findings: IFinding[],
    integrationId: string,
    openTicketFunction: OpenTicket,
    provider: IntegrationProvider,
    title: string | null = null,
  ): Promise<string | undefined> => {
    const ticketTitle = title || generateTitle(findings);
    const response = await openTicketFunction(integrationId, ticketTitle, findings, provider).catch((error) => {
      const errorMessage = error?.response?.data?.error;
      return {
        status: 400,
        data: { message: errorMessage } as TicketError,
      };
    });

    if (response?.status === 400) {
      displayErrorToast((response?.data as TicketError).message, findings);
      return undefined;
    }
    const typedData = response?.data as TicketResponse;
    logInfo(`Ticket created successfully with id ${typedData.id}`);
    if (typedData.link) {
      showSnackBar({
        title: 'tickets.successToast.title',
        description: 'tickets.successToast.subtitle',
        type: 'success',
        action: {
          label: 'tickets.successToast.link',
          handleClick: () => window.open(typedData.link, '_blank'),
        },
      });
    }
    return typedData.link;
  };
  return { createStory: createTicket };
};
