import { AxiosResponse } from 'axios';
import { useCallback } from 'react';

import { getApiUrls } from 'services/apiUrls';
import { useClient } from 'services/useClient';
import { GraphEntityType } from 'types/enums/ContextGraphEntityType';
import { ManualFactors } from 'types/interfaces';
import { IEntityGraph } from 'types/interfaces/Graph/IEntityGraph';
import { IFeedbackResponse, IFeedback } from 'types/interfaces/Graph/IFeedback';
import { IGraphRunsStatusResponse } from 'types/interfaces/Graph/IGraphRunsStatus';
import { IGetPriorityFactorsGroupsResponse } from 'types/interfaces/Graph/IPriorityFactor';
import { camelizeSnakeCaseKeys, turnCamelCaseToSnakeCase } from 'utils/functions/camelCaseConverter';

export const useGraphService = () => {
  const { client } = useClient();

  const fetchPriorityFactorsGroups = useCallback(async (): Promise<AxiosResponse<IGetPriorityFactorsGroupsResponse> | undefined> => {
    const url = getApiUrls.graphService.getPriorityFactors();

    const response = await client.get<IGetPriorityFactorsGroupsResponse>({
      url,
      allowedStatuses: [200],
    });

    if (response?.status === 200) {
      response.data = camelizeSnakeCaseKeys(response.data) as IGetPriorityFactorsGroupsResponse;
    }

    return response;
  }, [client]);

  const createFeedback = useCallback(async (payload: IFeedback): Promise<AxiosResponse | undefined> => {
    const url = getApiUrls.graphService.createFeedback();

    const response = await client.post<IFeedbackResponse>({
      url,
      allowedStatuses: [201],
      requestConfig: {
        data: {
          priority_factor: payload.priorityFactor,
          feedback: payload.feedback,
          asset_id: payload.assetId,
          finding_id: payload.findingId,
          finding_details: payload.findingDetails,
        },
      },
    });

    if (response?.status === 201) {
      response.data = camelizeSnakeCaseKeys(response.data) as IFeedbackResponse;
    }

    return response;
  }, [client]);

  const updateFindingPriorityContext = useCallback(async (findingId: string, newManualFactors: ManualFactors): Promise<AxiosResponse | undefined> => {
    const url = getApiUrls.graphService.updateFindingPriorityContext(findingId);

    return client.post({
      url,
      allowedStatuses: [200],
      requestConfig: {
        data: {
          manual_factors: turnCamelCaseToSnakeCase(newManualFactors),
        },
      },
    });
  }, [client]);

  const updateAssetPriorityContext = useCallback(async (assetId: string, newManualFactors: ManualFactors): Promise<AxiosResponse | undefined> => {
    const url = getApiUrls.graphService.updateAssetPriorityContext(assetId);

    return client.post({
      url,
      allowedStatuses: [200],
      requestConfig: {
        data: {
          manual_factors: turnCamelCaseToSnakeCase(newManualFactors),
        },
      },
    });
  }, [client]);

  const fetchGraph = useCallback(async (entityId: string, entityType: GraphEntityType): Promise<AxiosResponse | undefined> => {
    const url = getApiUrls.graphService.getEntityGraph(entityType, entityId);

    const response = await client.get<IEntityGraph | null>({
      url,
      allowedStatuses: [200, 404],
    });

    if (response?.status === 200) {
      response.data = camelizeSnakeCaseKeys(response.data!) as IEntityGraph;
    }

    return response;
  }, [client]);

  const fetchGraphRunsStatus = useCallback(async (): Promise<AxiosResponse<IGraphRunsStatusResponse> | undefined> => {
    const url = getApiUrls.graphService.getGraphRunsStatus();

    const response = await client.get<IGraphRunsStatusResponse>({
      url,
      allowedStatuses: [200],
    });

    if (response?.status === 200) {
      response.data = camelizeSnakeCaseKeys(response.data) as IGraphRunsStatusResponse;
    }

    return response;
  }, [client]);

  return {
    fetchPriorityFactorsGroups,
    createFeedback,
    updateFindingPriorityContext,
    updateAssetPriorityContext,
    fetchGraph,
    fetchGraphRunsStatus,
  };
};
