import { ChangeEvent, FC, KeyboardEvent, ReactNode, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';

import styles from './JittoChat.module.scss';

import { JitText } from 'components/JitText/JitText';
import { useJittoContext, Thought } from 'context/JittoContext';
import { SeraIcon } from 'assets';
import { useAuthContext } from 'context/AuthContext/AuthContext';
import { Message, ReportStatus } from 'context/JittoContext/JittoContext';
import styled from '@mui/material/styles/styled';
import { useSendMessage } from 'context/JittoContext/useSendMessage';

// Message type definition - remove local interface in favor of imported types
type MessageSource = 'user' | 'sera';

// Styled components for thought process display
const StepContainer = styled(Box)({
  marginTop: '16px',
  marginBottom: '16px',
  padding: '12px',
  borderRadius: '8px',
  backgroundColor: 'rgba(30, 34, 52, 0.5)',
  border: '1px solid rgba(255, 255, 255, 0.08)',
  maxWidth: '100%',
  overflowX: 'hidden',
  transition: 'all 0.3s ease',
});

const StepItem = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  marginBottom: '10px',
  '&:last-child': {
    marginBottom: 0,
  },
});

const StepIcon = styled(Box)({
  width: '18px',
  height: '18px',
  marginRight: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  marginTop: '2px',
});

const StepContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  minWidth: 0,
});

const StepLabel = styled(Box)({
  fontWeight: 'normal',
  marginBottom: '2px',
  whiteSpace: 'nowrap',
  fontSize: '0.85rem',
  opacity: 0.85,
});

const StepText = styled(Box)({
  wordBreak: 'break-word',
  hyphens: 'auto',
  width: '100%',
});

const ProgressDots = styled(Box)({
  display: 'flex',
  marginTop: '4px',
});

const CollapsibleHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '10px',
  cursor: 'pointer',
  userSelect: 'none',
});

const CollapseIcon = styled(Box)({
  width: '20px',
  height: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '4px',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  marginRight: '10px',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
});

// Suggested prompts for empty conversations
const SUGGESTED_PROMPTS = [
  "What vulnerabilities were found in my last scan?",
  "Explain my current security posture",
  "What compliance issues should I address first?"
];

export const JittoChat: FC = () => {
  const { 
    currentConversation,
  } = useJittoContext();
  
  // Use the new sendMessage hook
  const { sendMessage, isLoading } = useSendMessage();
  
  const { frontEggUser } = useAuthContext();
  const [inputValue, setInputValue] = useState<string>('');
  const messagesEndRef = useRef<HTMLDivElement>(null);
  // Check if the conversation is "empty" (only has initial welcome message)
  const isEmptyConversation = currentConversation?.messages && currentConversation?.messages.length === 1 && 
    currentConversation?.messages[0]?.source === 'sera';

  // Auto-scroll to the bottom when messages change
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentConversation]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleSendMessage = (content: string = inputValue) => {
    if (content.trim() === '') return;
    
    // Use the sendMessage function from the hook
    sendMessage(content);
    setInputValue('');
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  // Handle click on a suggested prompt
  const handlePromptClick = (prompt: string) => {
    handleSendMessage(prompt);
  };

  // Render avatar based on message source
  const renderAvatar = (source: MessageSource): ReactNode => {
    if (source === 'sera') {
      return (
        <div className={styles.jittoIconWrapper}>
          <SeraIcon />
        </div>
      );
    } else {
      return (
        <div className={styles.userAvatarWrapper}>
          <Avatar
            src={frontEggUser?.profilePictureUrl || ''}
            sx={{
              width: 20,
              height: 20,
            }}
          />
        </div>
      );
    }
  };

  // Helper function to render thought process
  const ThoughtProcess = ({thoughts}: {thoughts: Thought[]}) => {
    // Add state to track if the process view is collapsed
    const [isCollapsed, setIsCollapsed] = useState(false);
    if (thoughts.length === 0) return null;
    
    // Toggle collapsed state
    const toggleCollapsed = () => {
      setIsCollapsed(!isCollapsed);
    };
    
    return (
      <StepContainer sx={{
        width: isCollapsed ? 'auto' : '100%',
        display: 'inline-block',
        height: isCollapsed ? 'fit-content' : 'auto',
        padding: isCollapsed ? '8px 12px' : '12px',
        marginTop: isCollapsed ? '8px' : '16px',
        marginBottom: isCollapsed ? '8px' : '16px',
        transition: 'all 0.3s ease',
      }}>
        <CollapsibleHeader onClick={toggleCollapsed} sx={{marginBottom: isCollapsed ? 0 : '10px'}}>
          <CollapseIcon>
            {isCollapsed ? (
              // Chevron right icon for collapsed state
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 18l6-6-6-6" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            ) : (
              // Chevron down icon for expanded state
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 9l6 6 6-6" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            )}
          </CollapseIcon>
          <Box sx={{ 
            fontWeight: 'bold',
            fontSize: '0.9rem',
            color: '#FFFFFF',
          }}>
            Process Steps {isCollapsed ? `(${thoughts.length})` : ''}
          </Box>
        </CollapsibleHeader>
        
        <Box sx={{ 
          height: isCollapsed ? 0 : 'auto',
          maxHeight: isCollapsed ? 0 : '500px',
          opacity: isCollapsed ? 0 : 1,
          overflow: 'hidden',
          transition: 'all 0.3s ease',
          marginTop: isCollapsed ? 0 : '10px',
          padding: isCollapsed ? 0 : 'inherit',
          overflowY: 'scroll',
        }}>
          {thoughts.map((thought) => {
            const isCompleted = thought.status === 'completed';
            const isInProgress = thought.status === 'inProgress';
            return (
              <StepItem key={thought.text} sx={{ 
                opacity: !isCompleted && !isInProgress ? 0.5 : 1 
              }}>
                <StepIcon>
                  {thought.type === 'outcome' ? (
                    // No icon for outcome steps
                    <Box width="18px" height="18px" />
                  ) : isCompleted ? (
                    // Completed step icon
                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5 12l5 5L19 7" stroke="#4CAF50" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  ) : isInProgress ? (
                    // In-progress step - show spinner
                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 2v4M12 18v4M4.93 4.93l2.83 2.83M16.24 16.24l2.83 2.83M2 12h4M18 12h4M4.93 19.07l2.83-2.83M16.24 7.76l2.83-2.83" 
                        stroke="#0679FF" 
                        strokeWidth="2" 
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                        strokeDasharray="60"
                        strokeDashoffset="0">
                        <animateTransform
                          attributeName="transform"
                          attributeType="XML"
                          type="rotate"
                          from="0 12 12"
                          to="360 12 12"
                          dur="1s"
                          repeatCount="indefinite"
                        />
                      </path>
                    </svg>
                  ) : (
                    // Pending step
                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="12" cy="12" r="9" stroke="rgba(255, 255, 255, 0.4)" strokeWidth="2" />
                    </svg>
                  )}
                </StepIcon>
                
                <StepContent 
                  sx={{ color: isInProgress ? '#0679FF' : isCompleted ? '#fff' : 'rgba(255, 255, 255, 0.6)' }}
                >
                  {/* Render the appropriate label based on step type: thinking, task, or outcome */}
                  <StepLabel sx={{ fontWeight: isInProgress ? 'bold' : 'normal' }}>
                    {thought.type === 'thinking' 
                      ? 'Thinking:' 
                      : thought.type === 'task' 
                        ? 'Task:' 
                        : 'Outcome:'}
                  </StepLabel>
                  
                  <StepText>
                    {thought.text}
                  </StepText>
                  
                  {isInProgress && (
                    <ProgressDots>
                      <Box sx={{ 
                        width: 6, 
                        height: 6, 
                        borderRadius: '50%',
                        backgroundColor: '#0679FF',
                        animation: 'pulse 1s infinite',
                        animationDelay: '0s',
                        mx: 0.5
                      }} />
                      <Box sx={{ 
                        width: 6, 
                        height: 6, 
                        borderRadius: '50%',
                        backgroundColor: '#0679FF',
                        animation: 'pulse 1s infinite',
                        animationDelay: '0.2s',
                        mx: 0.5
                      }} />
                      <Box sx={{ 
                        width: 6, 
                        height: 6, 
                        borderRadius: '50%',
                        backgroundColor: '#0679FF',
                        animation: 'pulse 1s infinite',
                        animationDelay: '0.4s',
                        mx: 0.5
                      }} />
                    </ProgressDots>
                  )}
                </StepContent>
              </StepItem>
            );
          })}
        </Box>
      </StepContainer>
    );
  };

  // Render message with consistent structure but dynamic parts - updated for new content type
  const renderMessage = (message: Message) => {
    const { id, source, content, timestamp } = message;
    const senderName = source === 'sera' ? 'Sera' : 'You';
    const groupedContent: (string | Thought[])[] = [];
    let currentThoughtGroup: Thought[] = [];
    // Process each content item while preserving order
    content.forEach((item, index) => {
      if (typeof item === 'string') {
        // If we had a group of thoughts, add it to the results before this string
        if (currentThoughtGroup.length > 0) {
          groupedContent.push([...currentThoughtGroup]);
          currentThoughtGroup = [];
        }
        // Add the string to the results
        groupedContent.push(item);
      } else if (item && typeof item === 'object') {
        // Add the thought to the current group
        currentThoughtGroup.push(item);
        
        // If this is the last item, add the group to the results
        if (index === content.length - 1 && currentThoughtGroup.length > 0) {
          groupedContent.push([...currentThoughtGroup]);
        }
      }
    });
    return (
      <div key={id} className={`${styles.message} ${styles[source]}`}>
        <div className={styles.messageHeader}>
          <div className={styles.messageHeaderLeft}>
            {renderAvatar(source)}
            <span className={styles.messageName}>{senderName}</span>
          </div>
          <span className={styles.messageTime}>
            {timestamp instanceof Date ? timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : ''}
          </span>
        </div>
        <div className={styles.messageContent}>
          {
            // Handle array of string or ThoughtProcess
            groupedContent.map((item: string | Thought[], index: number) => {
              if (typeof item === 'string') {
                return <div key={`content-${index}`}>{item}</div>;
              } else {
                return <ThoughtProcess key={item.map((thought) => thought.text).join('-')} thoughts={item} />
              }
            })}
        </div>
      </div>
    );
  };

  // Render suggested prompts section
  const renderSuggestedPrompts = () => {
    if (!isEmptyConversation) return null;
    
    return (
      <div className={styles.suggestedPromptsContainer}>
        <div className={styles.suggestedPromptsTitle}>
          Try asking:
        </div>
        <div className={styles.suggestedPromptsList}>
          {SUGGESTED_PROMPTS.map((prompt, index) => (
            <button
              key={index}
              className={styles.suggestedPromptButton}
              onClick={() => handlePromptClick(prompt)}
            >
              {prompt}
            </button>
          ))}
        </div>
      </div>
    );
  };

  
  // Return null if the current report's status is not Complete
  if (currentConversation?.report?.status !== ReportStatus.Complete) {
    return null;
  }


    return (
      <div className={styles.chatContainer}>
        <div className={styles.header}>
          <div className={styles.headerTitle}>
            <div className={styles.jittoIconWrapper}>
              <SeraIcon />
            </div>
            <JitText size="m" bold text='Sera' />
          </div>
        </div>
        
        <div className={styles.messagesContainer}>
          {/* Messages are displayed in chronological order (oldest first) */}
          {currentConversation?.messages.map(renderMessage)}
          {renderSuggestedPrompts()}
          <div ref={messagesEndRef} />
        </div>
        
        <div className={styles.inputContainer}>
          <input
            type="text"
            className={styles.input}
            placeholder="How can I help you?"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            aria-label="Chat input"
            disabled={isLoading}
          />
          <button
            type="button"
            className={styles.sendButton}
            onClick={() => handleSendMessage()}
            aria-label="Send message"
            disabled={inputValue.trim() === '' || isLoading}
          >
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22 2L15 22L11 13L2 9L22 2Z" fill="white"/>
            </svg>
          </button>
        </div>
      </div>
    );
}; 