import { FC } from 'react';
import { Box, Typography, Card, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { AgentOption } from '../../../context/JittoContext';
import { ISvg } from 'types/interfaces';
import { useNavigate } from 'react-router-dom';

interface AgentCardProps {
  agent: AgentOption;
}

const IconWrapper: FC<{ Icon: React.FC<ISvg> }> = ({ Icon }) => <Icon />;

export const AgentCard: FC<AgentCardProps> = ({ agent }) => {
  const { title, description, icon, isEnabled, expertise } = agent;
  const navigate = useNavigate();
  const handleInviteClick = () => {
    if (isEnabled) {
      navigate(`/agents/${agent.id}`);
    }
  };

  return (
    <Card
      sx={{
        bgcolor: '#121926',
        borderRadius: '8px',
        flex: 1,
        maxWidth: '500px',
        height: { xs: 'auto', sm: '100%', md: 462 },
        minHeight: 462,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        p: 3,
        boxShadow: 3,
        opacity: isEnabled ? 1 : 0.8,
      }}
    >
      {!isEnabled && (
        <Box sx={{ position: 'absolute', top: 16, right: 16, zIndex: 1 }}>
          <JitGradientButton size="small" sx={{ fontSize: '0.75rem', color: '#FFFFFF', cursor: 'default', px: 1 }}>
            COMING SOON
          </JitGradientButton>
        </Box>
      )}

      <Box sx={{ mb: 3, height: 80, display: 'flex', alignItems: 'center',  }}>
        <IconWrapper Icon={icon} />
      </Box>

      <Typography variant="h5" component="h2" sx={{ color: 'white', mb: 1, fontWeight: 'bold' }}>
        {title}
      </Typography>

      <Typography variant="body2" sx={{ color: 'text.secondary', mb: 3 }}>
        {description}
      </Typography>

      {isEnabled && (
        <Box sx={{ mb: 3, mt: 'auto' }}>
          <JitGradientButton width="100%" onClick={handleInviteClick}>
            <Typography color="white" sx={{
              fontFamily: 'Inter',
              fontWeight: 500,
              fontSize: '12px',
              lineHeight: '18px',
              letterSpacing: '0.02em',
              verticalAlign: 'middle',
            }}>
              SEE AGENT DETAILS
            </Typography>
          </JitGradientButton>
        </Box>
      )}

      <Box sx={{ mt: isEnabled ? 0 : 'auto' }}>
        <Typography variant="subtitle2" sx={{ color: 'text.secondary', mb: 1 }}>
          Expertise:
        </Typography>

        <List sx={{ p: 0 }}>
          {expertise.map((expertise, index) => (
            <ListItem key={index} sx={{ px: 0, py: 0.5 }}>
              <ListItemIcon sx={{ minWidth: 30 }}>
                <CheckCircleIcon fontSize="small" color="success" />
              </ListItemIcon>
              <ListItemText 
                primary={expertise.name} 
                primaryTypographyProps={{ 
                  variant: 'body2', 
                  color: 'text.secondary' 
                }} 
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Card>
  );
}; 