import { FC } from 'react';
import { Box, Typography } from '@mui/material';

export const TitleSection: FC = () => {
  return (
    <Box sx={{ textAlign: 'center', mb: 6 }}>
      <Typography variant="h3" component="h1" sx={{ 
        color: 'white', 
        mb: 1,
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: '22px',
        lineHeight: '130%',
        letterSpacing: '0%',
        textAlign: 'center'
      }}>
        AI Agents
      </Typography>
      <Typography variant="subtitle1" sx={{ 
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '150%',
        letterSpacing: '0%',
        textAlign: 'center',
        color: '#ACB4C0',
      }}>
        Select an agent to configure and manage its capabilities
      </Typography>
    </Box>
  );
}; 