import { isEmpty } from 'lodash';
import { Dispatch, SetStateAction, useCallback } from 'react';

import { IWebsocketNotification } from 'types/interfaces';
import { IFileMetadata } from 'types/interfaces/Files/IFileMetadata';
import { assertWebsocketNotificationEntity } from 'utils/functions/assertions/websocketNotificationEntity';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';

interface Props {
  setFilesMetadata: Dispatch<SetStateAction<IFileMetadata[] | undefined>>,
}

export const useHandleFilesMetadataWebSocketNotification = ({ setFilesMetadata }: Props) => {
  const modifyFiles = (
    currentFiles: IFileMetadata[] | undefined,
    created: IFileMetadata[],
    updated: IFileMetadata[],
    deleted: IFileMetadata[],
  ) => {
    if (!currentFiles) {
      return isEmpty(created) ? undefined : created;
    }

    const modifiedFiles = [...created, ...updated];

    const filesByName = currentFiles.reduce((acc, file) => {
      acc[file.filePath] = file;
      return acc;
    }, {} as Record<string, IFileMetadata>);

    if (modifiedFiles) {
      modifiedFiles.forEach((file) => {
        filesByName[file.filePath] = file;
      });
    }

    if (deleted) {
      deleted.forEach((file) => {
        delete filesByName[file.filePath];
      });
    }
    return Object.values(filesByName);
  };

  const handleFilesMetadataWebSocketNotification = useCallback((notification: IWebsocketNotification<IFileMetadata>) => {
    assertWebsocketNotificationEntity(notification);
    const { message: { created, updated, deleted } } = notification;
    setFilesMetadata((currentFiles) => modifyFiles(
      currentFiles,
      camelizeSnakeCaseKeys(created || []) as IFileMetadata[],
      camelizeSnakeCaseKeys(updated || []) as IFileMetadata[],
      camelizeSnakeCaseKeys(deleted || []) as IFileMetadata[],
    ));
  }, [setFilesMetadata]);
  return { handleFilesMetadataWebSocketNotification };
};
