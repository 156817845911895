import { FC, useCallback, useMemo, useRef, useState, useEffect, PropsWithChildren } from 'react';

import { defaultSortColumns } from 'context/FindingsContext/constants';
import { FindingsContext } from 'context/FindingsContext/FindingsContext';
import { useSavedFilters } from 'context/FindingsContext/hooks/useSavedFilters';
import { useSubscribeToFindingsWebsocket } from 'context/FindingsContext/useSubscribeToFindingsWebsocket';
import { buildGetFindingsRequestParams } from 'context/FindingsContext/utils/buildGetFindingsRequestParams';
import { useContextEngine } from 'context/GraphContext/hooks/useContextEngine';
import { fetchFindings, fetchFindingsCount, fetchFindingsCsvLink, fetchFindingsStatistics, fetchFullFindingsByIds } from 'services/FindingsService';
import { SortDirections } from 'types/enums';
import { IFinding, IFindingFilter, IFindingServer, IFindingsStatistics, ISortColumns, ServerFindingKeys, ISortColumn } from 'types/interfaces';
import { ICount } from 'types/interfaces/Counts/ICounts';

export const FindingsProvider: FC<PropsWithChildren> = ({ children }) => {
  const lastGetFindingsRequestId = useRef(0);
  const lastTotalFindingsAmountRequestId = useRef(0);

  const [findings, setFindings] = useState<IFinding[]>([]);
  const [totalFindingsAmount, setTotalFindingsAmount] = useState<number>();
  const [findingsStatistics, setFindingsStatistics] = useState<IFindingsStatistics>();
  const [isLoadingStatistics, setIsLoadingStatistics] = useState(false);
  const [isFetchingCSV, setIsFetchingCSV] = useState(false);

  const [filters, setFilters] = useState<IFindingFilter[]>([]);
  const [hasFetchedFilters, setHasFetchedFilters] = useState(false);
  const [filtersVisibleInFirstLine, setFiltersVisibleInFirstLine] = useState<IFindingFilter[]>([]);
  const [sortColumns, setSortColumns] = useState<ISortColumns>(defaultSortColumns);
  const { isContextEngineEnabled } = useContextEngine();

  useSubscribeToFindingsWebsocket({ setFindings });

  const getColumn = useCallback((columnKey: string) => sortColumns.columns.find((c) => c.sortKey === columnKey), [sortColumns]);

  const currentActiveColumn = useMemo(() => getColumn(sortColumns.activeColumnKey)!, [getColumn, sortColumns]);

  const toggleColumn = useCallback((columnKey: string) => {
    const column = sortColumns.columns.find((c) => c.sortKey === columnKey);
    if (!column) {
      return;
    }
    const updatedColumn: ISortColumn = {
      ...column,
      direction: column.direction === SortDirections.ASC ? SortDirections.DESC : SortDirections.ASC,
    };
    const updatedColumns = sortColumns.columns.map((col) => (col.sortKey === columnKey ? { ...updatedColumn } : { ...col }));
    setSortColumns({
      columns: updatedColumns,
      activeColumnKey: columnKey,
    });
  }, [setSortColumns, sortColumns.columns]);

  useEffect(() => {
    const defaultSortColumnKey = isContextEngineEnabled ? ServerFindingKeys.priority_score : ServerFindingKeys.created_at;
    setSortColumns((prevState) => ({
      ...prevState,
      activeColumnKey: defaultSortColumnKey,
    }));
  }, [isContextEngineEnabled]);

  const {
    editSavedFilter,
    removeSavedFilter,
    setSavedFilterAsDefault,
    createSavedFilter,
    getSavedFilters,
    savedFilters,
    selectSavedFilter,
    selectedSavedFilter,
  } = useSavedFilters(filters, setFilters);

  const getFindingsStatistics = useCallback(async (isSilentFetch: boolean = false, requestFilters: IFindingFilter[] = []) => {
    if (!isSilentFetch) {
      setIsLoadingStatistics(true);
    }
    const formattedFilterParams = buildGetFindingsRequestParams({
      filters: requestFilters,
      currentActiveColumn,
    });
    const statisticsResponse = await fetchFindingsStatistics(formattedFilterParams);

    if (!statisticsResponse) {
      console.error('Error while getting findings statistics');
      setIsLoadingStatistics(false);
      return;
    }
    setFindingsStatistics(statisticsResponse);
    setIsLoadingStatistics(false);
  }, [currentActiveColumn]);

  const getFindings = useCallback(async (paginationKey: string | undefined, activeColumn: ISortColumn): Promise<string | undefined> => {
    const requestId = Date.now();
    lastGetFindingsRequestId.current = requestId;
    const formattedFilterParams = buildGetFindingsRequestParams({
      filters,
      currentActiveColumn: activeColumn,
      nextPageKey: paginationKey,
    });
    const { data, metadata: { nextKey } } = await fetchFindings(formattedFilterParams, paginationKey);
    if (lastGetFindingsRequestId.current !== requestId) return undefined; // Ignore outdated request

    setFindings(data);

    return nextKey;
  }, [filters]);

  const getTotalFindingsAmount = useCallback(async () => {
    const requestId = Date.now();
    lastTotalFindingsAmountRequestId.current = requestId;
    const formattedFilterParams = buildGetFindingsRequestParams({
      filters,
      currentActiveColumn,
    });
    const response = await fetchFindingsCount(formattedFilterParams) || {};
    const { count } = response as ICount;
    if (lastTotalFindingsAmountRequestId.current !== requestId) return; // Ignore outdated request
    setTotalFindingsAmount(count);
  }, [currentActiveColumn, filters]);

  const getCsvFile = useCallback(async (requestedFilters: IFindingFilter[]): Promise<string | undefined> => {
    setIsFetchingCSV(true);
    try {
      const formattedFilterParams = buildGetFindingsRequestParams({
        filters: requestedFilters,
        currentActiveColumn,
      });
      const csvDownloadUrl = await fetchFindingsCsvLink(formattedFilterParams);
      setIsFetchingCSV(false);
      return csvDownloadUrl;
    } catch (error) {
      setIsFetchingCSV(false);
      console.error(`Failed to get download URL for CSV file. The following error was received: ${error}`);
    }
    return undefined;
  }, [currentActiveColumn]);

  const fetchedFindings = useCallback((findingsIds: string[]): Promise<IFindingServer[] | undefined> => fetchFullFindingsByIds(findingsIds), []);

  const value = useMemo(() => ({
    findings,
    getFindings,
    isFetchingCSV,
    setFindings,
    getSavedFilters,
    findingsStatistics,
    isLoadingStatistics,
    getFindingsStatistics,
    getCsvFile,
    fetchedFindings,
    savedFilters,
    editSavedFilter,
    createSavedFilter,
    removeSavedFilter,
    setSavedFilterAsDefault,
    selectSavedFilter,
    selectedFilter: selectedSavedFilter,
    filters,
    setFilters,
    filtersVisibleInFirstLine,
    setFiltersVisibleInFirstLine,
    sortColumns,
    setSortColumns,
    totalFindingsAmount,
    setTotalFindingsAmount,
    getTotalFindingsAmount,
    getColumn,
    currentActiveColumn,
    toggleColumn,
    hasFetchedFilters,
    setHasFetchedFilters,
  }), [
    getSavedFilters,
    getFindings,
    findings,
    isFetchingCSV,
    findingsStatistics,
    isLoadingStatistics,
    getFindingsStatistics,
    getCsvFile,
    fetchedFindings,
    filters,
    setFilters,
    savedFilters,
    editSavedFilter,
    createSavedFilter,
    removeSavedFilter,
    setSavedFilterAsDefault,
    selectSavedFilter,
    selectedSavedFilter,
    filtersVisibleInFirstLine,
    setFiltersVisibleInFirstLine,
    sortColumns,
    setSortColumns,
    totalFindingsAmount,
    setTotalFindingsAmount,
    getTotalFindingsAmount,
    getColumn,
    currentActiveColumn,
    toggleColumn,
    hasFetchedFilters,
    setHasFetchedFilters,
  ]);

  return (
    <FindingsContext.Provider value={value}>
      {children}
    </FindingsContext.Provider>
  );
};
