import { TIME_AGO_FILTER_KEY } from 'types/interfaces';
import { getDisplayNamesMap } from 'utils/constants/assetTypeConfigurations/assetTypeConfigurations';

export const app = {
  assetTypes: getDisplayNamesMap(),
  chat: {
    welcomeMsg: "Hi, I'm Jit Documentation AI Bot :)",
    resetBtn: 'Reset chat',
    placeholder: 'Ask me something...',
    cmdk: '⌘K',
    sendBtn: 'Send',
  },
  actions: {
    title: 'Actions',
    card: {
      title: 'Take Action: <b>{{actionTitle}}</b>',
      remediateButton: 'Remediate',
      viewAllActionsButton: 'View All <b>{{teamName}}</b> team Actions ({{actionsNumber}})',
      viewAllActionsButtonNoTeam: 'View All Actions ({{actionsNumber}})',
      emptyTitle: 'Jit automatically creates Actions for you to quickly process backlog items.',
      emptyDescription: 'Once Actions will be available you will see them here.',
    },
  },
  jitCollapsibleText: {
    showMore: 'Show more',
    showLess: 'Show less',
  },
  fileInput: {
    invalid: 'Invalid file content, please validate your schema and try again',
    complete: 'File uploaded successfully',
    loading: 'Uploading file...',
  },
  widgets: {
    bookTime: {
      title: '<b>Need a hand?</b> No worries!',
      linkText: 'Book time with an expert',
    },
  },
  PercentageChip: {
    more: 'more',
    less: 'less',
    noData: 'No data to compare yet',
    tooltip: 'It’s relatively {{percentage}}% {{direction}} than the previous period',
  },
  mobileNotSupported: {
    title: 'Mobile isn\'t supported yet',
    description: 'Discover the JIT Security Plan on\n your desktop.',
  },
  components: {
    graphs: {
      statusBarChart: {
        completed: 'Resolved',
        total: 'Detected',
      },
    },
    newIndicator: {
      text: 'NEW',
    },
    copyToClipboard: {
      copied: 'Copied',
      copy: 'Click to copy',
    },
    copyToClipboardButton: {
      copied: 'Copied',
      copy: 'Copy',
    },
    scoreTrendingWidget: {
      team: {
        tooltip: "The performance of your team has been {{timesCount}} times higher than the company's threshold in the last six weeks.",
        title: 'Score history',
      },
      score: '<b>{{grade}}</b> {{score}}%',
      threshold: '<b>{{grade}}</b> {{score}}%<br> Org threshold',
    },
    scoreTrendingGraph: {
      tooltip: 'Avg. weekly score',
      noDataTooltip: 'No data yet',
    },
    feedback: {
      submit: 'Submit',
      placeholder: 'How can we improve?',
      ratings: {
        great: 'Excellent!',
        good: 'Good',
        neutral: 'Okay',
        bad: 'Bad',
        terrible: 'Terrible',
      },
    },
  },
  banners: {
    trialEnd: {
      attentionText: 'Attention!',
      text: 'Your free trial is about to end. Contact Jit today, to continue enjoying our product! <contactLink>Contact Us<contactLink/>',
    },
  },
  handleFixSuggestion: {
    viewFixSuggestion: {
      buttonText: 'View Fix Suggestion',
    },
    openFixPR: {
      buttonText: 'Open Fix {{scmRequestTypeShort}}',
      viewFixPR: 'View Fix {{scmRequestTypeShort}}',
      loading: 'Opening...',
      openingToast: {
        opening: {
          title: 'On it!',
          subtitle: 'Opening the fix {{scmRequestTypeShort}}... this might take a few minutes - You\'ll be notified once it\'s ready.',
        },
        openingCompleted: {
          title: 'Success!',
          subtitle: 'Fix {{scmRequestTypeShort}} has been created successfully.',
          link: 'View fix {{scmRequestTypeShort}}',
        },
        openingFailure: {
          title: 'Oops!',
          subtitle: 'Could not open the fix {{scmRequestTypeShort}}. Please try again later.',
        },
      },
    },

  },
  tickets: {
    baseText: 'Create {{ticketName}}',
    viewText: 'View {{ticketName}}',
    baseUnifiedText: 'Create unified {{ticketName}}',
    noIntegration: 'Create Ticket',
    noIntegrationTooltipWithLink: 'Ticket management system integration must first be connected through the <linkText>Integration file</linkText>',
    noIntegrationTooltip: 'Please contact your Jit Admin to set up an integration',
    noIntegrationActionTooltip: 'Ticket management system integration must first be connected through the Integrations page.',
    link: {
      createdDate: 'Created at <b>{{date}}</b>',
    },
    successToast: {
      title: 'Success!',
      subtitle: 'Ticket has been created successfully.',
      link: 'View ticket',
    },
    failedToast: {
      title: 'Failed to create ticket',
      subtitle: 'Ticket could not be created.',
      defaultContent: 'Please try again later.',
    },
    shortcut: {
      ticketName: 'Shortcut Story',
    },
    jira: {
      ticketName: 'Jira Issue',
    },
    linear: {
      ticketName: 'Linear Issue',
    },
    monday: {
      ticketName: 'Monday Item',
    },
  },
  plans: {
    'plan-github-marketplace': 'GitHub Marketplace',
    'plan-mvs-for-cloud-app': 'MVS for AppSec',
    'plan-owasp-serverless-top-10': 'OWASP Serverless Top 10',
    'plan-soc2': 'SOC2',
    'plan-iso-27001': 'ISO-27001',
  },
  navbar: {
    routes: {
      myPlan: 'My Plan',
      securityPlans: 'Security Plans',
      planMatrix: 'Plan Matrix',
      findings: 'Backlog',
      testLog: 'Test Log',
      integrations: 'Integrations',
      pipelines: 'Pipelines',
      pullRequests: '{{scmRequestType}} Requests',
      performance: 'Performance',
      deployments: 'Deployments',
      actions: 'Actions',
      teams: 'Teams',
      insights: 'Insights',
      overview: 'Overview',
      securityImpact: 'Security Impact',
      sbom: 'SBOM',
      securityMapping: 'Security Mapping',
      securityTools: 'Security Tools',
      more: 'More',
      quickstart: 'Quickstart',
      risks: 'Risks',
      resources: 'Resources',
      backlog: 'Backlog',
      policies: 'Policies',
      workflows: 'Workflows',
    },
    buttons: {
      whatsNew: 'What\'s New',
      docs: 'Docs',
      settings: 'Settings',
      manageResources: 'Manage Resources',
      secrets: 'Secrets',
      usersAndPermissions: 'Users & Permissions',
      subscription: 'Subscription',
      billing: 'Billing',
    },
    gettingStartedButton: 'Getting Started',
    menu: {
      currentOrganization: 'Current Organization',
      createOrganization: {
        button: 'Create Organization',
        createBtn: 'CREATE ORGANIZATION',
        title: 'Create a new Organization',
        description: 'Once created, follow the step-by-step Quick Start Guide. Integrate your SCM and complete the "Activate Jit" steps to continue.',
      },
      userManagement: 'User Management',
      logout: 'Logout',
    },
  },
  toasts: {
    networkError: {
      title: 'Network Error',
      subtitle: 'Please check your internet connection and try again.',
    },
    failedApiRequest: {
      title: 'Oops!',
      subtitle: 'Something went wrong. Please try again later.',
    },
    noPermissions: {
      title: 'No Permissions',
      subtitle: 'You don\'t have permissions to perform this action - please contact your system admin.',
    },
    actions: {
      learnMore: 'Learn More',
    },
    commitPlanSuccess: {
      title: 'Congratulations!',
      subtitle: 'You’ve successfully activated your plan.',
    },
    commonTitles: {
      success: 'Success!',
    },
    planChanged: {
      title: '',
      subtitle: {
        intro: 'Your plan was updated on {{timestamp}}',
        changeText: {
          addedItems: 'Added items: <b>{{addedItems}}</b>',
          removedItems: 'Removed items: <b>{{deletedItems}}</b>',
        },
      },
    },
    planActivated: {
      subtitle: 'Security Requirement Activated Successfully.',
    },
    configurationConflict: {
      title: 'Configuration out of sync',
      subtitle: 'You seem to be out of sync with the committed configurations. Please refresh the page.',
    },
    awsConfigurationError: {
      title: 'AWS Integration Failed',
      subtitle: 'The integration is broken, your environments are not scanned.',
    },
    githubOutage: {
      title: 'GitHub Outage',
      subtitle: 'GitHub is currently experiencing an outage. You might experience degraded performance.',
    },
    failedIntegration: {
      title: 'Integration Error!',
      subtitle: 'The <linkText /> is invalid.',
    },
    integrationFileError: {
      title: '<IntegrationName/> Error!',
      subtitle: 'The <FileLink/>  file is malformed <Reason/>. Please check the syntax and try again.',
      validCommit: 'You can view the last valid configuration file <CommitLink/>.',
      clickValidCommit: 'here',
    },
    nonExistingConfigFile: {
      title: 'Config file not found',
      subtitle: 'Your Jit config file (.jit/jit-config.yml) cannot be found. Please try re-installing the Jit GitHub app on your GitHub organization.',
    },
    ignoreFindingsFailure: {
      title: 'Error Ignore Findings',
      subtitle: 'An unknown error occurred. Try again later',
    },
    awsIntegrationVersionError: {
      text: '<b>Important Notice for AWS Users</b>: We have upgraded our security tool Prowler to version 3. Update your CloudFormation stack to take advantage of Prowler’s improved performance (up to X30 faster) and expanded capabilities (upgraded checks). <linkComponent>Learn here how to upgrade</linkComponent>.',
    },
    teamPreferencesNotFound: {
      title: 'Failed to update team',
      subtitle: 'Team {{teamName}} were not found.',
    },
    missingJitBranch: {
      title: 'Missing branch',
      subtitle: 'The following repos are missing the configured jit branch: <ReposList/>. Please refer to <ManageResourcesLink/> to see the faulty repos.',
      subtitleLink: 'Manage Resources',
    },
    demoPRCreated: {
      error: {
        title: 'Pipeline demo failed',
        description: 'Please try again later',
      },
    },
    cloudIntegrationError: {
      title: 'Could not connect to {{vendor}}',
      text: 'Please try again later',
    },
  },
  securityToolsSection: {
    title: 'Security Tools',
    subtitle: 'Explore and manage your security tools',
    search: {
      placeholder: 'Search security tools...',
    },
    sidebar: {
      title: 'Categories',
    },
    categories: {
      all: 'All Tools',
      sca: 'Software Component Analysis',
      compliance: 'Compliance',
      secretScanning: 'Secret Scanning',
      sast: 'SAST',
      dast: 'DAST',
      container: 'Container',
      cloud: 'Cloud',
      monitoring: 'Monitoring',
    },
    buttons: {
      connect: 'Connect',
      configure: 'Configure',
      learnMore: 'Learn More',
      backToTools: 'Back to Security Tools',
    },
    noResults: {
      title: 'No security tools found',
      description: 'No security tools match your search for "{{searchTerm}}"',
      clearSearch: 'Clear Search',
    },
    notFound: 'Security Tool Not Found',
    messages: {
      configurationUploaded: 'Configuration Uploaded',
      configurationSaved: 'Configuration Saved',
      configurationError: 'Configuration Error',
      configurationErrorDescription: 'Failed to save configuration',
      configurationFileNotFound: 'Configuration file not found, please upload',
      uploadError: 'Upload Error',
      uploadErrorDescription: 'Failed to upload configuration file',
      fetchError: 'Fetch Error',
      fetchErrorDescription: 'Failed to fetch configuration file',
    },
  },
  cards: {
    buttons: {
      connect: 'Connect',
      comingSoon: 'Coming Soon',
      configure: 'Configure',
      disconnect: 'Disconnect',
      disconnecting: 'Disconnecting',
      connecting: 'Connecting',
      learnMore: 'Learn More',
      integrateAsCode: 'Integrate as-code',
    },
    status: {
      connected: 'Connected',
      connecting: 'Connecting',
      disconnect: 'Disconnect',
    },
    github: {
      descriptions: {
        primary: 'Monitor and scan code repositories, container images and more.',
      },
    },
    aws: {
      learnMoreLink: 'https://docs.jit.io/docs/troubleshooting-aws-integration',
      descriptions: {
        primary: 'Scan your infrastructure for security issues and misconfigurations.',
        error: 'The integration is broken, your environments are not scanned.',
        warning: 'One of your AWS accounts has a problem.',
        pending: 'Waiting for input on AWS console.',
      },
      subtitle: {
        error: 'Integration Failed',
        warning: 'Warning',
        pending: 'Pending',
      },
      statusTitles: {
        error: 'Integration failed',
        warning: 'Warning',
        pending: 'Pending',
      },
    },
    slack: {
      descriptions: {
        primary: 'Receive notifications about new findings, changes in your security posture and other updates.',
      },
    },
    jira: {
      title: 'Jira',
      description: 'Create tickets directly from findings, track finding remediation progress and more.',
      learnMoreLink: 'https://docs.jit.io/docs/integrating-with-jira',
    },
    shortcut: {
      title: 'Shortcut',
      description: 'Create stories directly from findings, track finding remediation progress and more.',
      learnMoreLink: 'https://docs.jit.io/docs/integrating-with-shortcut',
    },
    drata: {
      title: 'Drata',
      description: 'Integrate with Drata to send security scan reports.',
      learnMoreLink: 'https://docs.jit.io/docs/drata-integrartion',
    },
    linear: {
      title: 'Linear',
      description: 'Create tickets directly from findings, track finding remediation progress and more.',
      learnMoreLink: 'https://docs.jit.io/docs/integrating-with-linear',
    },
    gcp: {
      title: 'GCP',
      description: 'Scan your infrastructure for security issues and misconfigurations.',
    },
    azure: {
      title: 'Azure',
      description: 'Scan your infrastructure for security issues and misconfigurations.',
    },
    tags: {
      all: 'All Integrations',
      sourceCodeManagement: 'Source Code Management',
      cloud: 'Cloud Services',
      tms: 'Ticket Management',
      collaborationPlatform: 'Collaboration Platform',
      cicdSecurity: 'CI/CD Security',
      registry: 'Registry',
      dataSecurity: 'Data Security',
      siem: 'SIEM',
      cloudSecurity: 'Cloud Security',
      dast: 'DAST',
      sast: 'SAST',
      compliance: 'Compliance',
      secretManagement: 'Secret Management',
      observability: 'Observability',
    },
    tooltip: {
      requiresSCMIntegration: 'SCM integration is mandatory before you can integrate with any other products or services.',
      allowedSingleScmIntegration: 'Only one source code management integration is allowed.',
    },
  },
  dialogs: {
    buttons: {
      next: 'Next',
      activate: 'Activate',
    },
    iconWithDialog: {
      cancel: 'Cancel',
      remove: 'Remove',
    },
    secretsManagement: {
      title: 'Secrets',
      createSecretButton: 'Create new secret',
      doneButton: 'Done',
      initialScreen: {
        title: 'Manage your secrets',
        subtitle: 'Manage and store API keys, passwords, certificates, and other sensitive data. Secrets are useful for integrating systems and configuring them.',
        createSecretButton: 'Create new secret',
      },
      tooltips: {
        edit: 'Edit',
        delete: 'Delete',
      },
      createSecret: {
        name: 'Name',
        namePlaceHolder: ' Your secret name',
        value: 'Secret',
        valuePlaceHolder: ' Enter secret',
        createSecretButton: 'Create secret',
        updateSecretButton: 'Update secret',
        backButton: 'Back',
        infoMessage: '<b>Note!</b> This token will be added as a secret called {{secretName}} in <b>Secrets</b> in the menu bar under <b>Settings</b>.',
        errors: {
          nameAlreadyExists: 'This secret name is already taken.',
          invalidSecretName: 'Special characters and spaces are not allowed.',
        },
      },
      deleteSecret: {
        header: 'Delete Secret',
        subtitle: 'Are you sure you want to delete',
        title: 'Note that this secret might be used in integrations configuration.',
        deleteButton: 'Delete',
        cancelButton: 'Cancel',
      },
    },
    importFile: {
      title: 'Import file',
      downloadTemplate: 'For best results, please use',
      downloadTemplateButton: 'this template',
      alertMessage: '<b>Note!</b> {{message}}',
      doneButton: 'Done',
      docsLink: 'Our JSON template guide',
    },
    stiggPaywall: {
      title: 'Subscription',
    },
    integration: {
      aws: {
        account: 'Account',
        org: 'Organization',
        link: 'AWS',
        title: 'Connect your AWS accounts',
        details: {
          addAccount: 'Add a new AWS Account',
          subTitle: {
            prefix: 'Monitored',
            suffix: 'accounts',
          },
        },
        fields: {
          type: {
            label: 'Integration Type',
            name: 'integration_type',
            placeholder: 'Select integration type',
            options: [
              'Account',
              'Organization',
            ],
          },
          account_id: {
            label: 'Account ID',
            name: 'account_id',
            placeholder: 'Please enter the AWS account ID (12 digits)',
          },
          account_name: {
            label: 'Account Name',
            name: 'account_name',
            placeholder: 'Insert your account name',
          },
          regions: {
            label: 'Regions to monitor',
            name: 'regions',
            placeholder: 'Please select one or more regions',
            tooltip: 'Select one or more AWS regions you would like to monitor with Jit.',
            options: [
              'us-east-1',
              'us-east-2',
              'us-west-1',
              'us-west-2',
              'af-south-1',
              'ap-east-1',
              'ap-southeast-3',
              'ap-south-1',
              'ap-northeast-3',
              'ap-northeast-2',
              'ap-southeast-1',
              'ap-southeast-2',
              'ap-northeast-1',
              'ca-central-1',
              'eu-central-1',
              'eu-west-1',
              'eu-west-2',
              'eu-south-1',
              'eu-west-3',
              'eu-north-1',
              'me-south-1',
              'sa-east-1',
              'us-gov-east-1',
              'us-gov-west-1',
            ],
          },
          deploy_region: {
            label: 'Jit Stack Region',
            name: 'deploy_region',
            placeholder: 'Select one region',
          },
          deploy_regions: {
            label: 'Jit Stack Region',
            name: 'deploy_regions',
            placeholder: 'Select one region',
            tooltip: 'Jit requires an IAM role to access your AWS account. The role is created via a CloudFormation Stack. Select in which region the stack will be created.',
            options: [
              'us-east-1',
              'us-east-2',
              'us-west-1',
              'us-west-2',
              'ap-south-1',
              'ap-northeast-3',
              'ap-northeast-2',
              'ap-southeast-1',
              'ap-southeast-2',
              'ap-northeast-1',
              'ca-central-1',
              'eu-central-1',
              'eu-west-1',
              'eu-west-2',
              'eu-west-3',
              'eu-north-1',
              'sa-east-1',
            ],
          },
        },
        add: 'Add',
        next: 'Next',
        done: 'Done',
        linkMessage: {
          title: 'Jit is connecting to your AWS account.',
          orgTitle: 'Jit is connecting to your AWS organization.',
          generatingNewFlowIntegrationLink: 'Generating AWS integration link...',
          orgSelectAssets: 'Next step would take you to the Resource management section, where you will choose which assets to be covered.',
          enableStackSets: 'Follow the link below and Click "Enabled trusted access". Return to this screen afterwards.',
          createStackSet: 'Click the link below to create a read-only IAM role and establish a trust relationship between Jit and your AWS account.',
          description: 'A role with reading permission only is necessary to establish a trusted relationship between Jit and your AWS account.',
          stackSetMessage: 'Click here to enable stacksets',
          integrationMessage: 'Click here to integrate',
          readAccessAccountExplain: 'Jit needs read access to this AWS account',
          linkAccountExplain: 'Click the link below to create a read-only IAM role and establish a trust relationship between Jit and your AWS account.',
          pleaseReturnMessage: '* Please return manually to Jit',
        },
      },
      slack: {
        connected: 'Disconnect',
        title: 'Configure your Slack integration',
        channels: {
          tenantNotifications: {
            title: 'Slack Notification Channels',
            description: 'For each notification type, select a Slack channel.',
          },
          teamNotifications: {
            description: 'Select Slack channel for team notification',
          },
          defaultDropDown: 'Slack Channel',
          preferencesDescriptions: {
            high_severity_findings: 'New High-Severity Findings',
            ignore_findings: 'Ignored Findings',
            deployment_with_vulnerabilities: 'Deployment on staging with findings',
            findings_on_saved_views: 'Findings on saved views',
            new_action_created: 'New action created',
            period_report: 'Overview report',
            security_plan_failures: 'Security plan failures',
            score_decrease: 'Team Score decrease',
          },
          preferencesTooltips: {
            findings_on_saved_views: 'You can receive notifications on new security \n findings that fit specific filter(s) (called a “view”) \n in the backlog page. To create a view, click “Save” \n next to a filter in the backlog page.',
          },
        },
        users: {
          title: 'Configure Users for Sharing Actions',
          description: 'Using email addresses, Jit will attempt to detect Slack users.',
          defaultDropDown: 'Slack User',
          loadingText: 'Loading users...',
        },
        inviteLink: 'Invite more users to Jit',
        done: 'Done',
      },
      secrets: {
        learn_more: 'Learn More',
        guidelines: 'Provide a secret to complete the integration.',
      },
    },
    disconnectIntegration: {
      slack: {
        title: 'Disconnect Integration',
        confirmationTitle: 'Are you sure you want to disconnect this integration?',
        confirmationMessage: 'It is always possible to connect <b>Slack</b> integration from the Integrations page.',
        cancel: 'Cancel',
        disconnect: 'Disconnect',
      },
    },
    planItemsCatalog: {
      title: 'Plan Items Catalog',
      subtitle: 'Select items to add',
      selectItems: 'Select items',
      itemExistsInPlan: 'This item is already exists in your plan',
      buttons: {
        addItems: 'Add items {{selectedItemsCount}}',
        cancel: 'Cancel',
      },
      toast: {
        title: 'Items were added.',
        subtitle: 'Don\'t forget to commit your plan!',
        subtitleWithConfig: 'Don’t forget to configure the relevant items and then commit your plan!',
      },
      empty: 'No Items were found',
      details: {
        description: 'Description',
        supportedLanguages: 'Supported languages',
        whatWillJitDo: 'What will Jit do?',
        plans: 'Plans',
        layer: 'Layer',
        language: 'Language',
        securityTool: 'Security Tool',
        link: 'Link',
      },
      filter: {
        filterBy: 'Filter By',
        plan: 'Plan',
        category: 'Category',
        searchForItems: 'Search for items',
        savedFilters: {
          saveNewFilter: 'Choose view',
          search: 'Search...',
          edit: 'Edit',
          saveButton: 'Save',
          editCustomFilter: 'Edit custom view',
          name: 'Name',
          description: 'Description',
          setAsDefault: 'Set as default',
          removeFromYourLibrary: 'Remove from your library',
          delete: 'Delete',
          remove: 'Remove',
          areYouSureDelete: 'Are you sure you want to remove this custom view?',
          onceYouRemove: 'Once you remove this custom view, you won\'t be able to undo this action.',
          createNew: 'Create your new custom view',
          create: 'Create',
          cancel: 'Cancel',
          notificationCheckBox: 'Get real-time notification',
          notificationCheckBoxDisabledTooltip: 'Slack integration is required for real-time notifications',
        },
      },
    },
    ignore: {
      accept_risk: 'Accept Risk',
      false_positive: 'False Positive',
    },
    welcome: {
      continuous: 'Continuous',
      productSecurity: 'Product Security',
      platform: 'Platform',
      madeBy: 'Made by',
      for: 'for',
      developers: 'Developers',
      subtitle: 'Welcome to Jit!',
      button: 'Get Started',
      description: {
        opening: 'Jit improves your product security with simple-to-use security plans.\n\n',
        itemDescription: {
          singleItem: {
            noControls: 'This is your starter security plan. It currently contains one item: <strong>{{itemName}}</strong>. Click on the item for more information. You can add more security controls by clicking on "Security Catalog".',
            singleControl: 'This is your starter security plan. It currently contains one item: <strong>{{itemName}}</strong>, utilizing the <strong>{{control}}</strong> tool. Click on the item for more information. You can add more security controls by clicking on "Security Catalog".',
            multipleControls: 'This is your starter security plan. It currently contains one item: <strong>{{itemName}}</strong>, utilizing the following tools: <strong>{{controls}}</strong>. Click on the item for more information. You can add more security controls by clicking on "Security Catalog".',
          },
        },
        ending: '\n\n Now you\'re almost ready to start using Jit! Please follow the following steps (less than 2 minutes).',
      },
    },
    commitPlan: {
      title: 'You are about to commit your Security Plan!',
      button: 'Commit Plan',
      body: {
        subtitle: 'What does it mean exactly?',
        description: ' \nJit will write your plan to the {{org}}/.jit repository. \n\n Jit will automatically run security controls'
          + ' that address the plan you created. We will update you through the Findings page and the relevant developers through PR reviews. \n\nWant to add more layers of security? Click on “Security Catalog” and add security controls to secure your code, infrastructure, runtime environments and more.',
      },
    },
    whyPermissions: {
      title: 'Why does the Jit app need permissions?',
      button: 'Got It',
      body: {
        subtitle1: 'Write access to dedicated Jit files',
        description1: 'Allows to manage the security controls executed on your repositories',
        subtitle2: 'Read access to repos administration, code, issues and metadata',
        description2: 'Allows to understand the context of the workflows and actions\nAllows to read GitHub issues (future usage)\nAllows to run security controls on the monitored repositories',
        // TODO: remove it not being used
        subtitle3: 'Read and write access to actions, checks, {{scmRequestTypeLower}} requests, and workflows',
        description3: 'Allows to trigger workflows in .jit repository\nAllows to create and update {{scmRequestTypeShort}} checks\nAllows to create and update {{scmRequestTypeShort}}\nAllows to modify workflow files',
        subtitle4: 'Read access to Organization Administration',
        description4: 'Allows to detect security issues at the organization level',
      },
    },
    assetsManagement: {
      title: 'Resource management',
      cantFindRepos: "Can't find your repositories?",
      checkPermissions: 'Check permissions',
      search: { placeholder: 'Search' },
      archived: 'Archived',
      done: 'Done',
      update: 'Update',
      cancel: 'Cancel',
      today: 'Today',
      daysAgo: '{{diffDays}} days ago',
      organizationTopRow: 'Organization',
      awsOrganizationTopRow: 'Management Account: {{orgTitle}}',
      awsStandaloneAccountsTopRow: 'Individual Accounts',
      selectionList: {
        tableHeaderAssetName: 'Application Name',
        tableHeaderActions: 'Actions',
        missingGithubIntegration: 'GitHub integration required. In the Plan page, under Activation, click Integrate.',
      },
      emptyState: {
        title: 'No resources found',
        description: 'There are no resources available for this tab.',
      },
      actions: {
        scan: {
          label: 'Scan',
          summary: 'Out of {{total}} plan item(s) associated with this resource: {{success}} were triggered successfully, {{skipped}} were skipped (already running), and {{failed}} failed.',
          error: 'An unexpected error occurred while processing scans, please try again later.',
        },
      },
      backlogBranchTooltip: 'To scan a different branch, please\ncontact our support team.',
      topRow: {
        github: 'Organization',
        gitlab: 'Group',
      },
      accordion: {
        github: 'Repository',
        gitlab: 'Project',
        aws: 'Account',
        lastUpdate: 'Last Update',
        account_id: 'Account ID',
        regions: 'Regions',
        status: 'Status',
      },
      wholeSelectionText: {
        github: 'All Repos',
        gitlab: 'All Projects',
        aws: 'All Accounts',
        gcp: 'All Accounts',
        azure: 'All Accounts',
        API: 'All Applications',
        WEB: 'All Websites',
      },
      tabTitles: {
        github: 'GitHub',
        gitlab: 'GitLab',
        aws: 'AWS',
        gcp: 'GCP',
        azure: 'Azure',
        API: 'API',
        WEB: 'Web',
      },
    },
    planItemsMisconfiguration: {
      title: 'Commit Plan Failed',
      subtitle: {
        nonConfiguredItemsHeader: 'There are misconfigured items ({{numOfMisconfiguredItems}}):',
        configureOrRemoveCaption: '* Please configure / remove the items before committing the plan',
      },
    },
    deletePlanItem: {
      title: 'Confirm removal',
      message: 'Are you sure you want to remove this security requirement from your plan?',
      actions: {
        yes: 'Yes',
        no: 'No',
      },
    },
    shareDialog: {
      title: 'Share Action to Slack',
      subtTitle: 'Share Action to Slack Private Message',
      placeHolder: 'Slack User',
      subtext: 'You can share an action with another JIT user so he can handle it and ensure it is resolved.',
      footer: 'Can\'t find the Slack User you\'re looking for? Integrate other users through Slack Configuration in the ',
      integrationPage: 'Integrations page',
      actions: {
        send: 'Send',
        cancel: 'Cancel',
      },
    },
    deactivatePlanItem: {
      title: 'Deactivate Security Control',
      message: 'Are you sure you want to deactivate this item?',
      subMessage: 'You can always activate the <b>{{planItemName}}</b> security control again.',
      actions: {
        yes: 'Deactivate',
        no: 'Cancel',
      },
    },
    activateAllPlanItems: {
      title: 'Activate Security Controls',
      message: 'Are you sure you want to activate all the available controls for this plan?',
      subMessage: 'You have the option to deactivate any control as needed from this page.',
      actions: {
        yes: 'Activate',
        no: 'Cancel',
      },
    },
    uncommittedPlan: {
      title: 'Uncommitted changes',
      message: 'You have uncommitted changes in your security plan.\nWould you like to commit them now, or discard the changes?',
      actions: {
        commit: 'Commit',
        discard: 'Discard',
      },
    },
    planItemIntro: {
      title: 'Security Requirement Activated',
      buttons: {
        testMeButton: 'Test Me',
      },
      itemsDescriptions: {
        SoftwareComponentAnalysis: 'Scanning code dependencies for vulnerabilities is now active and monitors your selected repositories.',
        SecretsDetection: 'Secret detection is now active and monitors your selected repositories.',
        IacMisconfigDetection: 'Scanning IaC for static misconfigurations is now active and monitors your selected repositories.',
        StaticCodeAnalysisPython: 'Scanning code for vulnerabilities is now active and monitors your selected repositories.',
        ContainerScan: 'Container scanning is now active and monitors your selected repositories.',
      },
    },
    executionLog: {
      title: 'Execution Log',
      downloadLog: 'Download Log',
      buttonClose: 'Close',
      errorTitle: 'Server Error',
      truncatedLogText: "You haven't reached the end of the log. To view the entire log, click",
      errorText: 'There was a problem fetching the log.<br>Please try again later.',
      truncatedLogFakeLineNumber: '...',
    },
  },
  gettingStartedWizard: {
    title: 'Getting Started',
    next: 'Next',
    subtitle: 'Let’s set you up really quick',
    welcome: {
      title: 'Welcome to Jit',
      contentTitle: 'Welcome to Jit!',
      contentSubtitle: 'You Only Need Three Minutes<br>to Get Started!',
      buttonText: 'Let’s Start',
    },
    createCentralizedRepo: {
      title: 'Activate Jit',
      information: '<b>Note!</b> it is best practice to create a dedicated repository for us, for reduced permissions and clearer view. You can call this repo Jit. Create dedicated repo in GitHub',
      buttonText: 'Done',
      goToPlan: 'Go to Plan',
      card: {
        title: 'GitHub',
        subtitle: 'Jit app requires a repository to store security configurations and run checks as GitHub actions. Please select one:',
        errorBanner: '<b>The selected repository is not suitable for Jit app due to branch protection.</b> Please select another repository or',
        errorBannerCreateRepo: 'Create dedicated repo in GitHub',
        errorBannerEditPermissions: 'Edit access permissions',
        errorBannerNoRepos: 'There are no repositories available for Jit.',
      },
      dropdown: {
        placeholder: 'Select a repository',
        emptyPlaceholder: 'No repositories',
        reload: 'Reload',
      },

    },
    installJitApp: {
      title: 'Install Jit App',
      buttonText: 'Next',
      card: {
        title: 'GitHub',
        description: 'Install our app in your GitHub organization.<br>Choose which repositories must be secured.<br>'
          + 'It is recommended choosing "All Repositories"<br>in order to be fully covered.',
        buttonText: 'Install',
        loading: 'Installing...',
        success: 'Installed!',
        learnMore: 'Learn more',
      },
    },
    activatePlan: {
      title: 'Activate Security Plan',
      buttonText: 'Start Monitoring',
      contentTitle: 'Activate Security Plan',
      contentSubtitle: 'Jit will create a Minimal Viable Security (MVS) plan for you, with<br><b>Secret Detection</b> enabled.'
        + ' In My Plan page you will be able to<br>add more security controls and disable Secret Detection.',
      infoBanner: '<b>Note!</b> Click \'Start Monitoring\' to begin testing your secret detection control. A dummy secret will be added to the .jit repository as a demo.',
    },

  },
  GithubIntegrationWizard: {
    title: 'GitHub integration',
    createCentralizedRepo: {
      tabText: 'Set centralized repo',
      title: 'Why does Jit require a centralized repository?',
      learnMore: 'Learn more',
      repoUrlParams: {
        name: 'Jit',
        description: 'Centralized Jit repository for configuration and GitHub Actions-based scanning',
        visibility: 'private',
      },
      description: {
        bullets: [
          'Config storage: Jit creates a dedicated .jit folder for Config as Code',
          'Privacy-focused scanning: Jit scans run as GitHub Actions in this repo',
        ],
      },
      next: 'Finish',
      card: {
        title: 'GitHub',
        subtitle: 'Choose an existing repo or create a dedicated one for Jit',
      },
      infoBanner: {
        title: 'Don’t want to use an existing repository?',
        createRepo: 'Create a new Jit repository',
        editPermissions: 'Edit access permissions',
        and: 'and',
      },
      dropdown: {
        placeholder: 'Select a repository',
        emptyPlaceholder: 'No repositories',
        reload: 'Reload',
        errorTooltip: 'Please select a repository\nwithout branch protection.',
      },

    },
    chooseRepositories: {
      tabText: 'Choose repositories',
    },
    installJitApp: {
      tabText: 'Install our GitHub App',
      title: 'Why does Jit require the GitHub App?',
      description: {
        bullets: [
          'Configuration as Code: Jit settings are stored in your GitHub',
          'Code scanning: Jit continuously scans your GitHub repos and organization',
          'Dev-friendly GH interactions: Jit comments with actions on {{scmRequestTypeShort}} changes',
        ],
      },
      learnMore: 'Learn more about permissions',
      buttonText: 'Next',
      card: {
        title: 'Install the GitHub app',
        description: 'Recommended installation: Choose All repositories for full security coverage.',
        buttonText: 'Install',
        loading: 'Installing...',
        success: 'Installed!',
      },
    },
  },
  ChooseSCMResources: {
    tabText: 'Choose {{name}}',
    title: 'Choose which {{name}} to scan',
    buttonText: 'Next',
    learnMore: 'Learn more',
    fetchingErrorDescription: 'Please try again later and verify that the provided token has the correct permissions',
    coverageSelectionDialog: {
      refineSearch: 'Each option displays up to 20 results. Try refining your search terms to find more specific matches.',
      selectedSection: 'Selected',
      cancelButton: 'Cancel',
      selectButton: 'Select',
      nameHeader: 'Name',
    },
    github: {
      coverageSelectionDialog: {
        title: 'Select Repositories',
        searchBoxPlaceholder: 'Search for repositories',
      },
      nomenclature: {
        repository: {
          singular: {
            uppercase: 'Repository',
            lowercase: 'repository',
          },
          plural: {
            uppercase: 'Repositories',
            lowercase: 'repositories',
          },
        },
      },
      fetchingError: 'Error fetching repositories',
      radioButtons: {
        all: {
          label: 'All repositories',
          note: '(automatically includes all current and future repositories)',
          description: 'This includes all current and future repositories, expect empty ones.',
        },
        selected: {
          label: 'Selected repositories',
          note: '(start with these to achieve quick value from Jit)',
          description: 'Begin with recently updated repositories.',
          buttonText: 'Change repositories',
        },
      },
    },
    gitlab: {
      coverageSelectionDialog: {
        title: 'Select Projects & Subgroups',
        searchBoxPlaceholder: 'Search for projects and subgroups',
      },
      fetchingError: 'Error fetching projects or subgroups',
      nomenclature: {
        repository: {
          singular: {
            uppercase: 'Project',
            lowercase: 'project',
          },
          plural: {
            uppercase: 'Projects',
            lowercase: 'projects',
          },
        },
        organization: {
          singular: {
            uppercase: 'Group',
            lowercase: 'group',
          },
          plural: {
            uppercase: 'Groups',
            lowercase: 'groups',
          },
        },
        subOrganization: {
          singular: {
            uppercase: 'Subgroup',
            lowercase: 'subgroup',
          },
          plural: {
            uppercase: 'Subgroups',
            lowercase: 'subgroups',
          },
        },
      },
      radioButtons: {
        all: {
          label: 'All projects',
          description: 'This includes all current and future projects, expect empty ones.',
        },
        selected: {
          label: 'Only selected projects',
          description: 'Select at least one project.',
          buttonText: 'Choose projects',
          edit: 'Edit',
          selectedProjectsOrSubgroupsText: '{{count}} {{plural}} have been selected',
          selectedProjectsAndSubgroupsText: '{{projectsCount}} {{projectsPlural}} and {{subgroupsCount}} {{subgroupsPlural}} have been selected',
        },
      },
    },
  },
  GitlabIntegrationWizard: {
    title: 'GitLab integration',
    verifyPermissionsStep: {
      tabText: 'Welcome to Gitlab',
      title: 'Welcome to the Gitlab onboarding!',
      description: "Please ensure you have <paidAccount>a paid Gitlab account</paidAccount> and <groupOwner>Group Owner permissions</groupOwner>. Although our application itself doesn't require Group Owner permissions, you'll need them to complete certain configuration tasks.",
      insufficientPermissions: "I don't have the correct permissions / plan, go back",
      confirmButton: 'Confirm and continue',
    },
    chooseIntegrationMethod: {
      tabText: 'Integration method',
      title: 'Choose your integration method',
      fastest: {
        title: 'Fastest',
        description: 'Selecting the Fastest method grants Jit group owner-level access, enabling the quickest and most seamless integration with minimal manual steps.',
      },
      fast: {
        title: 'Fast',
        description: 'Gives Jit maintainer-level access to your GitLab projects. This requires some manual setup but allows you to limit the permissions granted.',
      },
      nextButton: 'Next',
      docsReference: {
        text: 'Learn more about integration methods in our docs',
        link: 'https://docs.jit.io/docs/integrating-with-gitlab',
      },
    },
    runnerTypeSelection: {
      tabText: 'Self hosted runners',
      title: 'Do you use self-managed runners?',
      subtitle: 'Which type of runners would you like Jit to use for running security scans?',
      doneButton: 'Done, let’s continue',
      placeholder: 'Choose an option',
      saasTitle: 'GitLab-hosted runners',
      selfHostedTitle: 'Self-managed runners',
      selfHostedRunnerInfo: {
        title: 'Follow our instructions, Check Jit\'s <Guidelines/>',
        subtitle: 'Setting up a self-hosted runner can be complex. To simplify the process, please follow our detailed guide for easy configuration.',
        guidelines: {
          text: 'guidelines',
          link: 'https://docs.jit.io/docs/self-hosted-runners-requirements',
        },
      },
      noSaasRunnerInfo: {
        title: 'No available SaaS runners found or enabled',
        subtitle: 'Jit requires GitLab-hosted runners to run security scans. Please enable SaaS runners in your GitLab.',
      },
      invalidSaasRunner: {
        info: {
          title: 'No available SaaS runners found or enabled',
          subtitle: 'Jit couldn\'t detect GitLab-hosted runners in your GitLab environment. Please enable SaaS runners to allow security scans to run.',
        },
        continueButton: 'I understand, continue',
      },
    },
    maintainerToken: {
      tabText: 'Create token',
      title: 'Create Maintainer Token on a group level',
      description: 'This token will enable Jit to run scans without pulling your code to the cloud.',
      guidelines: 'This can be done by following these steps:',
      tokenName: 'gitlab_jit_secret_token',
      curl: {
        prefix: 'or ',
        copyButtonText: 'Generate a token with cURL',
      },
      steps: {
        pressAddNewToken: {
          title: 'In GitLab click on <addNewToken>Add new token</addNewToken>',
          titleBold: 'Add new token',
          body1: "Navigate to 'Group → Settings → Access tokens'",
          body2: "& click 'Add new token'.",
        },
        fillTheForm: {
          title: 'Fill out the form: <formName>Add a group access token</formName>',
          titleBold: 'Add a group access token',
          guidelines: 'Apply the following values to the form:',
          fieldColumnHeader: 'Field',
          valueColumnHeader: 'Value',
          tokenName: 'Token name',
          tokenNameValue: 'Any name of your choice',
          expirationDate: 'Expiration date',
          expirationDateValue: '1 year',
          role: 'Role',
          roleValue: 'Maintainer',
          scope: 'Scope',
          scopeValue: 'api',
        },
        pressCreateToken: {
          title: 'Click on <createGroupAccessToken>Add new token</createGroupAccessToken>',
          titleBold: 'Create group access token',
          body: 'Create the group access token and copy it to your clipboard.',
        },
      },
      tokenInput: {
        title: "Paste the token you've created",
        placeholder: 'Enter your GitLab token',
        mockSecret: '************',
        alreadyExistsBanner: '<b>Note!</b> This token already exists as {{secretName}} in <b>Secrets</b> in the menu bar under <b>Settings</b>.',
      },
      nextButton: 'Next',
      creatingSecret: 'Creating secret...',
      missingTokenTooltip: 'Missing token input',
    },
    connectWebhooks: {
      tabText: 'Connect webhooks',
      title: 'Connect Webhooks',
      description: 'Jit uses webhooks to trigger scans for new Merge Requests and merges. To enable automatic scans, manually register webhooks at the <boldDescription>group level</boldDescription>.',
      boldDescription: 'group level',
      guidelines: 'This can be done by following these steps:',
      tokenName: 'webhook_secret_token',
      curl: {
        prefix: 'or ',
        copyButtonText: 'Connect webhooks with cURL',
      },
      steps: {
        pressAddNewToken: {
          title: 'In GitLab click on <addNewWebhook>Add new webhook</addNewWebhook>',
          titleBold: 'Add new webhook',
          body1: "Navigate to 'Group → Settings → Webhooks'",
          body2: "& click 'Add new webhook'.",
        },
        fillTheForm: {
          title: 'Fill out the form: <formName>Group hooks</formName>',
          titleBold: 'Group hooks',
          guidelines: 'Apply the following values to the form:',
          fieldColumnHeader: 'Field',
          valueColumnHeader: 'Value',
          urlField: 'URL',
          urlValue: 'https://api.jit.io/gitlab/webhook',
          headerName: 'Header name',
          headerValue: 'Header value',
          tenantIdHeaderNameValue: 'Tenant-Id',
          tenantIdHeaderValueValue: 'TENANT ID, PROVIDED BY JIT',
          installationIdHeaderNameValue: 'Installation-Id',
          installationIdHeaderValueValue: 'GROUP ID, PROVIDED BY JIT',
          secretTokenField: 'Secret Token',
          secretTokenFieldValue: 'String of your choice',
          triggerField: 'Trigger',
          triggerValue: {
            choose: 'Choose:',
            pushEvents: '- Push Events (wildcard pattern)',
            comments: '- Comments',
            subgroupEvents: '- Subgroup events',
            projectEvents: '- Project events',
            mergeRequestEvents: '- Merge request events',
            deploymentEvents: '- Deployment events',
          },
        },
      },
      tokenInput: {
        title: 'Paste the secret token you used to configure webhooks',
        placeholder: 'Enter the webhook secret token',
        mockSecret: '************',
        alreadyExistsBanner: '<b>Note!</b> This token already exists as {{secretName}} in <b>Secrets</b> in the menu bar under <b>Settings</b>.',
        errorSavingSecret: 'Failed to create secret',
        errorSavingSecretDescription: 'Please try again later',
      },
      nextButton: 'Next',
      creatingSecret: 'Creating secret...',
      missingTokenTooltip: 'Missing token input',
    },
    integrateGitlab: {
      tabText: 'Integrate with Gitlab',
      title: 'Why Jit needs Gitlab?',
      description:
        "Integrating Gitlab enable one-click activation for Jit's security scanners, which analyze your resources without pulling your code to the cloud.",
      needHelpQuestion: 'Need help?',
      bookTimeWithUs: 'Book time with us',
      buttonText: 'Next',
      startScanningButton: 'START SCANNING',
      startScanningInfo:
        'By clicking "Start Scanning", Jit will scan your codebase for existing security '
        + 'issues, while implementing continuous scanning for every code change to surface '
        + 'newly introduced issues.',
      card: {
        title: 'Integrate with Gitlab',
        buttonText: 'Integrate',
        loading: 'Integrating...',
        success: 'Integrated!',
      },
    },
    chooseGitlabGroup: {
      tabText: 'Choose group',
      title: 'Choose GitLab group to integrate Jit',
      subGroups: {
        title: 'Select location for centralized project',
        dropdown: {
          placeholder: 'Choose a sub group',
          tooltip: 'Start by selecting a group',
        },
      },
      buttonText: 'Next',
      dropdown: {
        placeholder: 'Choose a group',
        searchBoxPlaceholder: 'Search for a group',
      },
      info: '<b>Note!</b> After choosing a group, a dedicated project will be added to it. <br/>This project will contain Jit configuration files, which can be managed as-code if desired.',
      docsReference: {
        text: 'Learn more',
        link: {
          50: 'https://docs.jit.io/v4.7.1/docs/fastest#choose-a-group',
          40: 'https://docs.jit.io/v4.7.1/docs/fast#choose-a-group',
        },
      },
      error: {
        text: '<b>Failed to {{action}}</b>. {{reason}}',
        401: 'The Gitlab token is invalid. Please validate the token and try again.',
        403: 'The Gitlab token does not have sufficient permissions. Please validate the token and try again.',
        425: 'The Gitlab token has not been created successfully. Please validate the token and try again.',
        buttonText: 'Start over',
      },
    },
  },
  configurations: {
    zap: {
      invalidToolTipTitle: {
        target_url: 'Please enter a valid HTTP/S URL',
        api_domain: 'Please enter a valid domain',
        application_name: 'The name already exists on another application,\nplease enter a new one.',
      },
      headerAuthConfigurations: {
        headerPreviewTitle: 'Preview header',
        headerPreviewPlaceholder: 'The header preview will be displayed here',
      },
      enableAuthentication: {
        label: 'Enable Authentication',
        info_enabled: 'Check this option if your API requires authentication. If this option is left unchecked, Jit will scan only publicly-accessible endpoints.',
        info_disabled: 'To enable authentication please contact Jit Support',
      },
      enableSelectorsConfiguration: {
        label: 'Enable Selectors Configuration',
        info: 'Check this option if you want to configure the selectors of the username and password fields of your login page. If this option is left unchecked, Jit will search for common selectors.',
      },
      deleteApplication: {
        header: 'Delete Application',
        subMessage: 'Application will only be deleted after completing all steps',
        message: 'Are you sure you want to delete',
        deleteButton: 'Delete',
        cancelButton: 'Cancel',
      },
      api: {
        title: 'Ensure your API is secure',
        subtitle: 'Configuring a non-production environment is highly recommended. ',
        linkText: 'Learn more',
        linkHref: 'https://docs.jit.io/docs/ensure-your-api-is-secure',
        fields: {
          application_name: {
            label: 'Application Name (pick a label of your choice)',
            info: 'Enter the display name (or alias) for this API. The name will be displayed across Jit to help you identify this specific API in Pipelines, Backlog, and more.',
            placeholder: 'Name of application',
          },
          target_url: {
            fileMethods: {
              upload: {
                label: 'Open API (Swagger) file URL:',
                header: 'Upload File',
                info: 'Upload your Open API (Swagger) file. API scanning requires a valid Open API file that includes the endpoints to be scanned. Note that this file will be uploaded to your centralized repository.',
                invalidToolTipTitle: 'Please enter a valid schema file',
                accept: 'application/json, application/x-yaml, .json, .yml, .yaml',
              },
              url: {
                label: 'Open API (Swagger) file URL:',
                header: 'Paste File URL',
                info: 'Enter a URL (starting with “http://” or “https://”) for your Open API (Swagger) file. API scanning requires a valid Open API file that includes the endpoints to be scanned. Note that this file must be publicly accessible for the API scanning to function correctly.',
                invalidToolTipTitle: 'Please enter a URL of a valid OpenAPI (Swagger) file (json/yaml/yml extension)',
                placeholder: 'Paste file url: https://storage.example.com/openapi.json',
              },
            },
          },
          exclude_paths: {
            label: 'Exclude Urls',
            subLabel: '(Optional)',
            info: 'If you’d like to exclude specific URLs from API scanning, specify them here. URLs should start with “http://” or “https://” and be separated with commas.',
            placeholder: 'https://api.example.com/delete, https://api.example.com/create',
          },
          login_page_url: {
            label: 'Login URL',
            info: 'In order to run an authenticated scan, enter your application login form URL. Jit will use it to authenticate to the application, as a user would do. ',
            placeholder: 'https://platform.example.com/login',
          },
          localStorageKey: {
            label: 'Local Storage Item Key',
            info: 'Enter your local storage item key here.',
            placeholder: 'Key',
          },
          localStorageValue: {
            label: 'Local Storage Item Value',
            info: 'Enter your local storage item value here.',
            placeholder: 'Value',
          },
          customCookieKey: {
            label: 'Cookie Name',
            info: 'The name of the cookie can be found in the browser developer tools.',
            placeholder: 'Enter your cookie name.',
          },
          customCookieValue: {
            label: 'Cookie Value',
            info: 'The value of the cookie can be found in the browser developer tools.',
            placeholder: 'Enter your cookie value.',
          },
          auth_header_name: {
            label: 'Key',
            info: 'Enter your custom header key name here.',
            placeholder: 'Key',
          },
          auth_header_value: {
            label: 'Value',
            info: 'Enter your custom header value here.',
            placeholder: 'Value',
          },
          auth_cookie_header_value: {
            label: 'Value',
            info: 'Enter your Cookie header value here.',
            placeholder: 'Value',
          },
          auth_bearer_token_header_value: {
            label: 'Value',
            info: 'Enter your Bearer token here.',
            placeholder: 'Value',
          },
          api_domain: {
            label: 'API Domain',
            info: 'Enter your API domain. This is required to target your specific api.',
            placeholder: 'api.example.com (without https://)',
          },
          username: {
            label: 'Username',
            info: 'The username used to authenticate',
            placeholder: 'Username',
          },
          username_css_selector: {
            label: 'Username Field Selector',
            info: 'Enter the CSS form selector of the user name field, so Jit can inject the user name into the login form.',
            placeholder: 'input[type=text]',
          },
          password: {
            label: 'Password',
            info: 'Enter the user’s password. Jit will use it to log in to your application.',
            placeholder: 'Password',
          },
          password_css_selector: {
            label: 'Password Field Selector',
            info: 'Enter the CSS form selector of the password field, so Jit can inject the password into the login form. ',
            placeholder: 'input[type=password]',
          },
        },
      },
      web: {
        title: 'Run a Web Application Scanner',
        subtitle: 'Configuring a non-production environment is highly recommended. ',
        linkText: 'Learn more',
        linkHref: 'https://docs.jit.io/docs/run-a-web-application-scanner',
        fields: {
          application_name: {
            label: 'Application Name (pick a label of your choice)',
            info: 'Enter the display name (or alias) for this application. The name will be displayed across Jit to help you identify this specific application on Pipelines, Backlog, and more.',
            placeholder: 'MyApp',
          },
          target_url: {
            label: 'Target URL',
            info: 'Enter your application URL (starting with “http://” or “https://”). Note that this URL must be publicly accessible for the application scanning to function correctly.',
            placeholder: 'https://platform.example.com',
          },
          exclude_paths: {
            label: 'Exclude Urls',
            subLabel: '(Optional)',
            info: 'If you’d like to exclude specific URLs from WEB scanning, specify them here. URLs should start with “http://” or “https://” and be separated with commas.',
            placeholder: 'https://platform.example.com/delete, https://platform.example.com/logout',
          },
          login_page_url: {
            label: 'Login URL',
            info: 'In order to run an authenticated scan, enter your application login form URL. Jit will use it to authenticate to the application, as a user would do. ',
            placeholder: 'https://platform.example.com/login',
          },
          auth_header_name: {
            label: 'Key',
            info: 'Enter your custom header key name here.',
            placeholder: 'Key',
          },
          auth_header_value: {
            label: 'Value',
            info: 'Enter your custom header value here.',
            placeholder: 'Value',
          },
          localStorageKey: {
            label: 'Local Storage Item Key',
            info: 'Enter your local storage item key here.',
            placeholder: 'Key',
          },
          localStorageValue: {
            label: 'Local Storage Item Value',
            info: 'Enter your local storage item value here.',
            placeholder: 'Value',
          },
          customCookieKey: {
            label: 'Cookie Name',
            info: 'The name of the cookie can be found in the browser developer tools.',
            placeholder: 'Enter your cookie name.',
          },
          customCookieValue: {
            label: 'Cookie Value',
            info: 'The value of the cookie can be found in the browser developer tools.',
            placeholder: 'Enter your cookie value.',
          },
          auth_cookie_header_value: {
            label: 'Value',
            info: 'Enter your Cookie header value here.',
            placeholder: 'Value',
          },
          auth_bearer_token_header_value: {
            label: 'Value',
            info: 'Enter your Bearer token here.',
            placeholder: 'Value',
          },
          api_domain: {
            label: 'API Domain',
            info: 'Enter your API domain. This is required to test the api behind your web application.',
            placeholder: 'api.example.com (without https://)',
          },
          username: {
            label: 'Username',
            info: 'The username used to authenticate',
            placeholder: 'Username',
          },
          username_css_selector: {
            label: 'Username Field Selector',
            info: 'Enter the CSS form selector of the user name field, so Jit can inject the user name into the login form.',
            placeholder: 'input[type=text]',
          },
          password: {
            label: 'Password',
            info: 'Enter the user’s password. Jit will use it to log in to your application.',
            placeholder: 'Password',
          },
          password_css_selector: {
            label: 'Password Field Selector',
            info: 'Enter the CSS form selector of the password field, so Jit can inject the password into the login form. ',
            placeholder: 'input[type=password]',
          },
        },
        authModes: {
          selenium: 'Form-based authentication',
          'header-bearer': 'Bearer token header',
          'local-storage': 'Local Storage',
          'custom-cookie': 'Custom Cookie',
          'header-cookie': 'Cookie header',
          'header-custom': 'Custom header',
        },
      },
      validation: {
        loginValidationFailed: 'Login validation failed',
        apiFileValidationFailed: 'Invalid API schema',
        noValidationRequired: 'No validation required',
        websiteDoesNotExist: 'Website does not exist',
        missingFields: 'Missing fields',
        validatingUrls: 'Validating URLs...',
      },
      triggerSubtitle: 'Your application will be scanned daily by Zap. Additionally, you can enable scanning your runtime environments when new code is deployed there.',
      saveOnCompleteNote: '<b>Note:</b> Application changes will only be saved after completing all the steps.',
      minimumApplicationsTooltip: 'There has to be at least one application.',
      addNewApplication: 'Add new application',
      saveApplication: 'Save Application',
      backButton: 'Back',
    },
    githubBranchProtection: {
      stepName: 'Branch Protection',
      title: 'Protect your repository branches',
      fields: {
        requireApproval: {
          label: 'Required number of approvals',
          description: 'When enabled, Jit will check whether pull requests targeting the default branch require this number of approvals before they can be merged.',
          dropDownText: 'Required number of approvals before merging: ',
        },
        requireStatusChecks: {
          label: 'Require status checks',
          description: 'When enabled, Jit will check whether these status checks must pass before pull requests can be merged to the default branch.',
          textboxHint: 'Add a status check',
          add: 'Add',
          listOfChecksThatMustPass: 'List of status checks that must pass:',
        },
      },
    },
    containerScanOnBuild: {
      stepName: 'Container Scan on Build',
      title: 'Scan your container images on build',
      fields: {
        createCredentials: {
          title: 'Create Jit Credentials',
          subtitle: 'Generate a new credential and select the `CLI Agent` role to ensure the credential has the necessary permissions for container scanning.',
          description: '-> API Tokens -> Generate Token -> Choose Role: CLI Agent',
          button: 'Click to open Admin Portal',
        },
        addSecrets: {
          title: 'Add Secrets to Your CI/CD System',
          subtitle: 'Add `JIT_CLIENT_ID` and `JIT_SECRET_KEY` as secrets in your CI/CD system. Use the <hyperlink>secrets guide</hyperlink> for platform-specific steps.',
        },
        integrateCli: {
          title: 'Integrate Jit CLI in Your Build Workflow',
          subtitle: 'Add the Jit CLI to your build workflow to trigger scans on each build. Follow the <hyperlink>integration guide</hyperlink> for detailed instructions.',
        },
      },
    },
    optionalIntegrations: {
      defaultDescriptions: {
        title: 'Complete optional integrations',
        subtitle: 'Completing optional integrations allows you to benefit from additional capabilities:',
      },
      privateRegistries: {
        title: 'Integrate with a private registry',
        subtitle: 'When enabled, Jit will scan packages that are stored in private registries.',
      },
    },
    notifications: {
      title: 'Notifications',
      subtitle: 'Receive notifications when findings are detected in your runtime environment.',
      cardStatuses: {
        connected: 'Connected!',
        integrationFailed: 'Integration failed',
        installing: 'Installing...',
      },
    },
    integrations: {
      configure: 'Configure Integration',
      slack: {
        description: 'Receive notifications about new findings, changes in your security posture and other updates.',
      },
      aws: {
        description: 'Scan your infrastructure for security issues and misconfigurations.',
      },
    },
  },
  filters: {
    clearFilters: 'Clear',
    allFilterValuesSelected: 'All',
  },
  priorityFactors: {
    tooltipTitle: 'Priority',
    listTitle: 'Priority Factors',
  },
  pages: {
    policies: {
      title: 'Policy Management',
      subtitle: 'All policies',
      premiumBannerMessage: 'To manage policies',
      policyItem: {
        header: {
          docsLink: 'Learn more',
          enabled: '{{count}} Enabled',
        },
      },
      rule: {
        deleteDialog: {
          title: 'Are you sure you want to delete this rule?',
          message: 'You can create a new rule by clicking the "Add {{policyDisplayName}} rule" button within this Policy.',
          confirm: 'Delete rule',
          cancel: 'Cancel',
        },
        divider: {
          or: 'OR',
          and: 'AND',
        },
        buttons: {
          delete: {
            label: 'delete',
            tooltip: 'Delete rule',
          },
          edit: {
            label: 'edit',
            tooltip: 'Edit rule',
            error: 'Failed to update rule',
          },
          add: {
            label: 'Add {{policyName}} rule',
            error: 'Failed to add rule',
          },
          save: {
            label: 'Save',
          },
          cancel: {
            label: 'Cancel',
          },
          condition: {
            remove: {
              label: 'Remove',
            },
            add: {
              label: '+ Add',
            },
            addNew: {
              label: '+ Add another',
            },
          },
        },
        modify: {
          createdBy: 'Created by',
          updatedBy: 'Updated by',
          updatedAt: ' at {{at}}',
        },
      },
    },
    workflows: {
      premiumBannerMessage: 'To manage workflows',
      workflowsListPage: {
        title: 'Workflows',
        subtitle: 'Set up automated workflows',
        buttons: {
          create: 'CREATE NEW WORKFLOW',
        },
        table: {
          columns: {
            name: 'Name',
            createdAt: 'Created At',
            createdBy: 'Created By',
            lastRun: 'Last Run',
            status: 'Status',
            enabled: 'Enabled',
          },
        },
      },
      emptyPage: {
        entitled: {
          title: 'Set up automated workflows',
          subtitle1: 'Design automated workflows tailored to your',
          subtitle2: 'organization’s needs.',
        },
        notEntitled: {
          title: 'Your free trial has ended',
          subtitle1: 'Upgrade to unlock premium features, like contextual',
          subtitle2: 'prioritization, policy management, and SBOM.',
        },
        buttons: {
          create: 'CREATE YOUR FIRST WORKFLOW',
        },
      },
      deleteWorkflowDialog: {
        title: 'Delete Workflow',
        message: 'Are you sure you want to delete {{workflowName}}?',
        subMessage: 'Deleting this workflow will permanently remove it. You can still create a new workflow by clicking "Add new workflow."',
        confirm: 'Delete workflow',
        cancel: 'Cancel',
        tooltips: {
          deleteWorkflow: 'Delete workflow',
        },
      },
      editWorkflowPage: {
        views: {
          diagram: 'Diagram',
          history: 'History',
        },
        header: {
          backButton: 'All Workflows',
          tooltips: {
            details: 'Workflow details',
            activateWorkflow: 'Activate workflow',
          },
          workflowDetails: {
            header: 'Workflow details',
            createdAt: 'Created at {{createdAt}}',
            createdBy: 'by {{createdBy}}',
          },
          unsavedChangesDialog: {
            title: 'Save changes',
            message: 'There are unsaved changes in this workflow.',
            backToEditing: 'Back to editing',
            cancel: 'Discard changes',
          },
          cantSaveReasons: {
            workflowInvalid: {
              allConditionsConfigured: 'All conditions must be configured.',
              allConditionsHaveNextStep: 'All conditions must have a next step.',
              hasActions: 'Workflow must have at least one action.',
              isAllActionsConfigured: 'All actions must be configured.',
            },
            notChanged: 'No changes were made',
          },
          save: 'SAVE',
        },
        stepDetails: {
          title: 'Workflow details',
          subtitle: 'Step Name',
          editNameTooltip: {
            edit: 'Edit step title',
            save: 'Save changes',
          },
          removeStepTooltip: {
            remove: 'Remove step',
            nonLeafStep: 'This step has children. Remove all children first.',
          },
          selectType: {
            ctaQuestion: 'Next step: condition or action?',
          },
          trigger: {
            ctaQuestion: 'How should this Workflow be triggered?',
            addTrigger: 'Add Trigger',
          },
          action: {
            ctaQuestion: 'What should happen after this workflow is triggered?',
            addAction: 'Add Action',
          },
          condition: {
            ctaQuestion: 'Every time <b><i>{{triggerName}}</i></b> and',
            addCondition: 'Add Condition',
          },
        },
        historyView: {
          disabled: 'No past versions or activity logs',
          table: {
            emptyText: 'No workflow history',
            emptySubtext: 'No history available for this workflow.',
            columns: {
              startedAt: 'Started',
              status: 'Status',
              duration: 'Duration',
            },
          },
        },
        noWorkflowFound: {
          title: 'Workflow not found',
          subtitle: 'Oops! We\'re having trouble finding the workflow you\'re looking for.',
          goBackButtonText: 'SEE ALL WORKFLOWS',
        },
      },
    },
    quickStart: {
      title: 'Quick Start Guide',
      description: 'Follow these steps to onboard Jit across your codebase',
      sectionsVisibility: {
        show: 'View all steps',
        hide: 'Hide completed steps',
      },
      section: {
        completed: 'Completed',
        skipped: 'Skipped',
      },
      checks: {
        statuses: {
          integrated: 'Integrated',
          notIntegrated: 'Not integrated',
        },
        configureIntegrations: {
          goToIntegrationsButton: 'GO TO INTEGRATIONS',
          viewAllIntegrationsButton: 'View all integrations',
        },
        configureSlack: {
          integrateButton: 'INTEGRATE SLACK',
          configureButton: 'Configure Slack',
          genericConfigure: 'Configure',
        },
        configureTeams: {
          configureButton: 'MANAGE TEAMS',
          inviteEngineeringManagersButton: 'Invite engineering managers',
          statusLabel: 'Teams',
        },
        viewFindings: {
          prioritized: {
            statusLabel: 'Findings by\n\n priority score',
            ctaText: 'EXPLORE PRIORITIZED FINDINGS',
          },
          raw: {
            statusLabel: 'Findings detected',
            ctaText: 'EXPLORE FINDINGS',
          },
        },
        testPRExperience: {
          statusLabel: 'Repos',
          ctaText: 'TEST PR EXPERIENCE',
          dialog: {
            title: 'Test pull requests experience on {{scm}}',
            description: 'This flow will introduce a PR with a secret, show how Jit scans the PR and offers remediation. Start by select a repo to test.',
            buttonLabel: 'TEST ME',
            selectPlaceholder: 'Select a repository',
          },
        },
        manageTeams: {
          statusLabel: 'Teams',
        },
        securityPlansProcess: {
          scanning: {
            title: 'Security scans in progress:',
            status: 'Scanning...',
          },
          completed: {
            title: 'Monitor the progress of your activated security scanners:',
            status: 'Completed',
          },
          resourcesCount: 'Resources: {{completed}}/{{total}}',
          notActivated: 'Not activated',
          resultsButton: 'SEE RESULTS',
          exploreButton: 'Activate additional scanners',
          statusLabel: 'Plan progress',
          waitingForExecutionsText: 'Just a moment, Jit is discovering your resources',
          optionalPlans: {
            title: 'Activate additional scanners to find more types of product security issues (optional)',
            activate: 'Activate',
            missingCloudIntegration: 'Integrate cloud to activate',
          },
        },
        exploreResults: {
          button: 'Explore all findings',
          loadingOverlayText: 'Analyzing your environment... <br /> This may take a few minutes.',
          statusText: 'Findings detected',
          empty: {
            title: 'All Clear! No Security Risks Found!',
            description: 'Your latest security scan found no issues. Keep monitoring <br /> your environment to stay protected.',
          },
        },
      },
    },
    risks: {
      breadcrumbs: {
        riskPrefix: 'Risk:',
        resourcesDashboard: 'Resources dashboard',
        resources: 'Resources',
        backlog: 'Backlog',
      },
      resources: {
        emptyPage: {
          notEntitled: {
            title: 'Your free trial has ended',
            subtitle1: 'Upgrade to unlock premium features, like contextual',
            subtitle2: 'prioritization, policy management, and SBOM.',
            contactus: 'contact us',
          },
        },
        resourcesDashboard: {
          title: 'Resources dashboard',
          resourcesCountTitle: '{{resourcesCount}} Resources',
          resourcesCount: 'Number of resources',
          riskScore: 'Risk score',
          findingsByCriticality: 'Resources by criticality',
          seeDetails: 'SEE DETAILS',
          sections: {
            priorityFactors: 'Breakdown by top priority factors',
            tags: 'Breakdown by tags',
          },
        },
        resourcesPage: {
          branchScan: {
            button: 'Scan Branch',
            modal: {
              title: 'On-Demand Branch Scan',
              branchNameLabel: 'Branch Name - Only repositories containing this branch will be scanned',
              branchNamePlaceholder: 'Enter branch name',
              warningText: 'As a result, CI scans will be triggered and costs may be incurred',
              confirmationText: 'I agree and confirm',
              cancelButton: 'Cancel',
              scanButton: 'Scan',
            },
            toasts: {
              success: {
                title: 'Success!',
                subtitle: 'Branch scan triggered successfully',
              },
              error: {
                title: 'Error!',
                subtitle: 'Failed to trigger branch scan',
              },
            },
          },
          title: 'Resources',
          cards: {
            score: {
              description: 'Average risk score',
            },
            findingsCount: {
              description: 'Findings by criticality',
            },
          },
          engineDisabled: {
            prelude: 'Automatically prioritize top risks with the',
            contextEngine: 'Context Engine',
            contactSales: 'CONTACT US',
            learnMore: 'Learn more',
          },
          resourcesCount: '{{count}} Resources',
          table: {
            columnHeaders: {
              name: 'Name',
              type: 'Type',
              score: 'Risk Score',
              team: 'Team',
              priorityFactors: 'Priority Factors',
              findingsByPriorityScore: 'Findings by Priority Score',
            },
            filters: {
              priorityFactors: 'Priority Factors',
              type: 'Type',
              teams: 'Teams',
              tags: 'Tags',
            },
          },
        },
        resourcePage: {
          graphTitle: 'Path',
          findingsCount: '{{count}} Findings',
          noFindings: 'No findings',
          resourceDetails: {
            team: 'Team',
            riskScore: 'Risk Score',
            priorityFactors: 'Priority Factors',
            findingsByCriticality: 'Findings by Criticality',
            goToTeamPage: 'Go to Team Page',
            tags: 'Tags',
            editTag: 'Edit',
            addTag: 'Add Tag',
            addTagPopover: {
              formattedNewTag: 'Create \'{{tag}}\'',
              key: 'KEY',
              value: 'VALUE',
              placeHolder: 'Search or select an option...',
              save: 'SAVE',
            },

          },
          emptyTable: {
            title: 'There are no findings related to this resource',
            subtitle: 'Our scanning tools did not detect any vulnerabilities or issues in this resource.<br>Everything looks clear and secure.',
          },
          engineDisabled: {
            learnMore: 'Learn about Context Engine',
          },
        },
      },
    },
    teamsPortal: {
      header: {
        leaderboard: 'Leaderboard',
        guide: 'Quick Guide',
        teams: 'TEAMS',
        teamPage: 'Team Page',
        notActiveTeamTooltip: 'Inactive team, Contact the engineering manager to activate.',
      },
      welcomePage: {
        title: 'Hey {{userName}}, Welcome to <icon />',
        subtitle: 'The adaptable and engaging security portal for developers <br/> Join your team, and secure your environment.',
        emptySubtitle: 'We were unable to locate you as a member of any team.<br>For more information, please contact your admin',
        startWithTeamButton: 'START WITH {{teamName}} TEAM',
        emptyState: {
          linkText: 'Learn more in our docs',
        },
      },
      guidePage: {
        quickStartGuide: {
          title: 'Quick Start Guide',
          description: 'Maximize your team experience and improve your score',
        },
        activated: 'Activated',
        inactive: 'Inactive',
        configured: 'Configured',
        notConfigured: 'Not Configured',
      },
      teamPage: {
        resources: 'Resources',
        highlights: 'Highlights',
        gradeTooltip: 'The score is calculated based on the average of the passed plan items for each repository.',
        findingsMetric: {
          title: 'Security findings resolved pre production',
          tooltip: '{{total}} security findings have been resolved by your team. A total of {{resolved}} security findings have been resolved before reaching production.',
          footer: 'Since Jit first scanned your resources',
        },
        topFindingsCallToAction: {
          title: 'Team top findings',
          description: 'A list of the top 10 findings of the resources with the lowest score.',
          button: 'Top Findings',
          disabledTooltip: 'Add team repositories to view top findings.',
        },
        table: {
          columns: {
            name: 'Name',
            score: 'Score',
            planItems: 'Factors',
            planItemsTooltip: 'Score factors reflect specific checks (e.g., secret detection, vulnerability scanning) tailored to enhance your service’s security.',
          },
          emptyState: {
            title: 'No resources have been added to the team yet.',
            description: 'Add your first GitHub repository to begin managing code security <br/>within your team.',
            cta: 'ADD GITHUB REPOSITORY',
          },
          delete: {
            tooltip: 'Remove resource',
            cancel: 'Cancel',
            confirm: 'Remove',
            subtitle: 'Are you sure you want to remove {{assetName}} <br/>from {{teamName}}?',
            title: 'It is still possible to add this repository again using the \'Add repository\' button.',
            header: 'Remove resource',
            successTitle: '{{scmRepo}} successfully removed',
            successDescription: '{{scmRepo}} has been successfully removed from the team!',
          },
          add: {
            github: {
              cta: 'Add repository',
              header: 'Add repository',
              title: 'Available repositories',
              placeholder: 'Search or select a repository',
              emptyState: 'No available repositories',
              tooltip: 'Limited to 10 repositories',
              success: {
                single: '1 {{scmRepo}} has been added successfully to the team!',
                multiple: '{{affected}} Repositories has been added successfully to the team!',
                partial: '{{affected}} out of {{expected}} Repositories has been added successfully to the team!',
              },
            },
            confirm: 'Add to team',
          },
        },
      },
      topFindingsPage: {
        breadcrumbs: {
          text: 'Top Findings',
        },
        columns: {
          name: 'Name',
          location: 'Location',
          firstDetected: 'First Detected',
          severity: 'Severity',
        },
      },
      resourcePage: {
        noPlanItemStatuses: {
          title: 'No factor statuses',
          description: 'No factor statuses were found for this resource.',
        },
        viewOnGithub: 'View on GitHub',
        columns: {
          planItems: 'Factors',
          results: 'Results',
          securityFindings: 'Security Findings',
          passed: 'Passed',
          failed: 'Failed',
        },
      },
      findingsPage: {
        securityFindings: 'Security Findings',
        noFindings: {
          title: 'No findings',
          description: 'No findings were found for this factor.',
        },
      },
      planItemPage: {
        securityFindings: 'Security Findings',
        noFindings: {
          title: 'No findings',
          description: 'No findings were found for this factor.',
        },
        columns: {
          name: 'Name',
          firstDetected: 'First Detected',
          severity: 'Severity',
          status: 'Status',
        },
      },
      leaderboardPage: {
        title: 'Leaderboard',
        table: {
          columns: {
            rank: 'Rank',
            team: 'Team',
            members: 'Members',
            score: 'Score',
            weeklyTrend: 'Weekly trend',
            activated: 'Activated',
            actionButton: 'ActionButton',
          },
          cells: {
            inviteEngineeringManager: 'Invite Eng Manager',
            activateTeam: 'Follow guide for activation',
            navigateToTeam: 'Your team <icon />',
          },
        },
      },
    },
    sbom: {
      title: 'SBOM',
      subtitle: 'SBOM provides a detailed inventory of all software elements within your applications,'
        + ' enhancing transparency, security, and compliance.',
      exportReport: 'Export report',
      table: {
        searchPlaceHolder: 'Search by Library / Version / Resource / License',
        paywallGradiant: {
          title: 'Unlock Premium Feature',
          description: 'Trial ended. Get the full report by upgrading today.',
          contactUs: 'Contact us',
          upgrade: 'UPGRADE NOW',
        },
        columns: {
          libraryName: 'Library name',
          license: 'License',
          resources: 'Resource',
        },
        noData: {
          title: 'No data found',
          description: 'No data was returned from the scan (No repositories and/or packages found)',
        },
        noSearchData: {
          title: 'No results found',
          description: 'We couldn’t find any matches for your search',
        },
        scanError: {
          title: 'Scan error',
          description: 'An error has been detected during the SBOM scan. To run the scan again, click Re-run.',
        },
        scanning: {
          title: 'Collecting data...',
          description: 'A SBOM report is being prepared for you. Once it is ready, it will appear here. It might'
            + ' take a few minutes.',
        },
        activateSbom: {
          title: 'Scan to reveal your software components',
          description: 'SBOM provides a detailed inventory of all software elements within your applications.',
          button: 'Activate',
        },
        resourcesAmount: '{{amount}} resources',
      },
      inactiveState: {
        activate: {
          title: 'Create an inventory of open source components and dependencies',
          text: 'SBOM scans your codebase every day to maintain a continuously updated inventory.',
          cta: 'Activate',
        },
        upgrade: {
          title: 'Your free trial has ended',
          text: 'Upgrade to unlock premium features, like contextual prioritization, policy management, and SBOM.',
          cta: 'Upgrade Now',
        },
        contactUs: 'Contact us to extend free trial',
      },

    },
    onboarding: {
      stepsProgress: 'Step {{currentStep}} of {{totalSteps}}',
      nextButtonText: 'Next',
      backButtonText: 'Back',
      formStep: {
        title: 'Let\'s get started!',
        subtitle: 'First, tell us about yourself',
        nameInput: {
          label: 'Your Name *',
          placeholder: 'Enter your name',
        },
        emailInput: {
          label: 'Your Email *',
          placeholder: 'Enter your company email',
          errorMessage: 'Please enter a valid email address',
        },
        numberOfDevelopers: {
          label: 'How many developers are in your company?',
          options: {
            1: 'Up to 10',
            2: '11-100',
            3: '101-500',
            4: '501+',
          },
        },
        hostingPlatformInput: {
          label: 'Main Git hosting platform',
        },
      },
      goalsStep: {
        title: 'How can Jit help you?',
        subtitle: 'We\'ll find the plan that matches your goals',
        goals: {
          1: 'Implement a full product security plan (Code, <br>CI/CD, Cloud, runtime)',
          2: 'Empower my developers to own the security of the code they write while keeping velocity',
          3: 'Co-sell with AWS (FTR<br>automation)',
          4: 'Automated AppSec for<br>security compliance<br>(e.g. SOC 2, etc.)',
          5: 'Improve security posture<br>by implementing an<br>overall MVS plan',
        },
      },
    },

    generalError: {
      title: 'Sorry, something went wrong!',
      description: 'Refresh the page or try again later.',
      refresh: 'Refresh',
    },
    pageLevelError: {
      title: 'Uh oh!',
      description: 'We\'re experiencing a temporary issue. We\'re <br>'
        + 'working hard to fix it as quickly as possible.',
    },
    accessDenied: {
      loading: 'Hi there! we\'ll need a few moments to get you set up',
      title: 'Access Denied',
      description: 'You currently do not have access to this page.',
    },
    integrations: {
      search: {
        placeholder: 'Search integrations...',
        noResults: {
          title: 'No integrations found',
          description: 'Please try a different search term or clear the search.',
          clearSearch: 'Clear search',
        },
      },
      title: 'Integrations',
      subtitle: 'Manage integrations with third-party products and services.',
    },
    findings: {
      title: 'Backlog',
      subtitle: 'View and manage the security findings detected in your product.',
      emptyFindingsTable: {
        scanning: {
          ifFindings: "In case you'll have findings,",
          findingsShowUp: 'they will show up here.',
        },
        noFindings: {
          wellDone: 'Well Done!',
          noFindingsSoFar: "You don't have findings so far.",
        },
        commitPlanRequired: {
          commitPlanRequired: 'Activate a requirement',
          firstCommit: 'Go to the Plan page and activate a requirement first.',
          findingsShowUp: "In case you'll have findings, They will show up here.",
        },
        missingGithubIntegration: 'GitHub integration required in order to see activate security controls and see findings. In the Plan page, under Activation, click Integrate.',
        missingScmIntegrationSubtitleAction: 'activate security controls and see findings',
      },
      lastScan: 'Last Scan',
      lastUpdate: 'Last update',
      scanRunning: 'Scanning...',
      status: {
        open: 'Open',
        ignored: 'Ignored',
        fixed: 'Fixed',
      },
      ignored: {
        true: 'True',
        false: 'False',
      },
      copy: {
        copiedToClipboard: 'Copied to Clipboard!',
        securityFinding: 'Security Finding',
        findingName: 'Finding name',
        firstDetected: 'First Detected',
        lastDetected: 'Last Detected',
        layer: 'Layer',
        vulnerabilityType: 'Vulnerability Type',
        assetType: 'Asset Type',
        severity: 'Severity',
        securityControl: 'Security Control',
        issueText: 'Description',
        learnMore: 'Learn more about this issue',
        findingLocation: 'Locations',
        fileName: 'File Name',
        linkRange: 'Link Range',
        info: 'Detected first by **{{control}}** security tool on **{{date}}**',
        adf: {
          detectedFirst: 'Detected first by ',
          securityTool: ' security tool on ',
        },
      },
      statisticsSection: {
        titles: {
          findingsByStatus: 'Findings by status',
          findingsByLayer: 'Top findings by plan layer',
          totalLayers: 'Total Layers',
          findingsBySeverity: 'Findings by severity',
          totalAssets: 'Total Assets',
          topAssets: 'Top vulnerable assets by findings',
        },
        overTime: {
          noDataPlaceholder: 'Not enough data to view graph',
          menu: {
            categories: {
              statusView: 'Status View',
              timeFrame: 'TimeFrame',
            },
            timeRanges: {
              lastWeek: 'Last Week',
              lastTwoWeeks: 'Last Two Weeks',
              lastMonth: 'Last Month',
            },
          },
        },
      },
      exportCsv: 'Export CSV',
      selectedFindings: {
        findingSelected: 'Finding Selected',
        StatusPlaceholder: 'Status',
        IgnoredPlaceholder: 'Ignored',
        DefaultPlaceholder: 'Ignored: False/True',
      },
      toggleIgnoreFindings: {
        ignore: 'Mark as Ignored',
        undoIgnore: 'Mark as Unignored',
      },
      filtersSection: {
        appliedFilters: 'Applied Filters',
        resetFilters: 'Reset',
        noFilters: 'No Filter',
        clearFilters: 'Clear',
        saveFilters: 'Save',
        noFilterValueSelected: 'None',
        allFilterValuesSelected: 'All',
        addFilters: 'Add Filter',
        filterKeys: {
          asset_type: 'Asset Type',
          issue_severity: 'Severity',
          resolution: 'Status',
          control_name: 'Security Tool',
          location_text: 'Location',
          plan_layer: 'Plan Layer',
          test_name: 'Name',
          plan_item: 'Plan Item',
          [TIME_AGO_FILTER_KEY]: 'Date',
          asset_name: 'Asset Name',
          vulnerability_type: 'Vulnerability Type',
          team: 'Team',
          exposure: 'Exposure',
          environment: 'Environment',
          ignored: 'Ignored',
          priority_factors: 'Priority Factors',
          code_attributes_branch: 'Branch',
        },
        filterValues: {
          date: {
            ONE_WEEK: 'Last week',
            TWO_WEEKS: 'Last Two Weeks',
            ONE_MONTH: 'Last Month',
            THREE_MONTHS: 'Last Three Months',
          },
        },
      },
      findingsTable: {
        columnHeaders: {
          firstDetected: 'First Detected',
          name: 'Name',
          assetPriorityScore: 'Priority',
          priorityScore: 'Priority',
          severity: 'Severity',
          securityControl: 'Security Control',
          securityTool: 'Security Tool',
          status: 'Status',
          location: 'Location',
          assetType: 'Asset Type',
          planLayer: 'Layer',
          vulnerabilityType: 'Type',
          ignored: 'Ignored',
          priorityScoreTooltip: 'Finding priority is used to calculate the risk associated with a finding based on the combination of priority'
            + ' factors.',
          branch: 'Branch',
        },
      },
      findingDetails: {
        header: {
          title: 'Findings Details',
        },
        findingInfo: {
          title: 'Finding info',
        },
        aboutThisIssue: {
          title: 'About this issue',
          learnMore: 'Learn more',
          location: 'Location',
        },
        priority: {
          title: 'Priority Score',
          tooltip: 'Finding priority is used to calculate the risk associated with a finding based on the combination of priority factors.',
          scoreDescription: 'Finding priority is used to calculate the risk associated with a finding based on \n'
            + 'the combination of priority factors.',
          feedbackButtonText: 'Tell us your feedback',
          feedbackSubmitted: 'Thanks for your feedback!',
          feedbackWindowTitle: "We're seeking your feedback",
          feedbackWindowSubtitle: 'Do you agree with how we’ve prioritized this finding, or would you suggest a different approach?',
          graphTitle: 'Finding Graph',
          addRemoveFactors: {
            emptyState: {
              title: 'No factors applied',
              buttonText: 'Add security factors',
            },
            assetConfirmation: {
              title: 'Apply changes',
              onlyFindingBtn: 'Finding Only',
              subMessage: 'Note: Priority factors can be adjusted anytime.',
              resourceAndFindingsBtn: 'Resource & Findings',
              message: 'You can apply changes to the priority factors for this finding or for both the related resource and all its findings.',
            },
            buttonText: 'Score breakdown',
            title: 'Priority Score',
            subtitle: 'Sum of factors weights',
            apply: 'Apply',
            removeIconTooltip: 'Remove factor',
            factorsListTitle: 'Security factor',
            editIconTooltip: {
              edit: 'Edit Factors',
              goBack: 'Go back',
            },
          },
        },
        planContext: {
          title: 'Plan context',
          vulnerabilityType: 'Type',
          controlName: 'Security Tool',
          assetType: 'Asset Type',
        },
        details: {
          title: 'Details',
        },
        tms: {
          title: 'Ticket management system',
        },
        fixSuggestion: {
          title: 'Fix suggestion',
        },
        findingContext: {
          title: 'Finding context',
          code: {
            organization: '{{scmOrg}}',
            repository: '{{scmRepo}}',
            branch: 'Branch',
          },
          cloud: {
            accountId: 'Account ID',
            accountName: 'Account Name',
            region: 'Region',
            serviceName: 'Service',
          },
          app: {
            appName: 'Application Name',
            targetUrl: 'URL',
            requestHeader: 'Request Header',
            responseHeader: 'Response Header',
            param: 'Param used for the attack',
            path: 'Path',
            method: 'Method',
            solution: 'Solution',
            cweid: 'CWE ID',
            tags: 'Tags',
            description: 'Description',
          },
        },
      },
      notifications: {
        ignoreFindingSuccess: {
          title: 'Findings Ignored!',
          description: 'Your request to ignore {{count}} findings has been processed successfully.',
        },
        ignoreFindingFailed: {
          title: 'Failed to Ignore',
          description: 'Reason: {{reason}}',
        },
        ignoreFindingsInProgress: {
          title: 'Processing request to ignore',
          description: 'Your request to ignore {{count}} findings is being processed. This may take a moment.',
        },
        creatingTicketInProgress: {
          title: {
            singular: 'Creating ticket',
            plural: 'Creating tickets',
          },
          description: {
            singular: 'Your ticket for finding is being created. This may take a moment.',
            plural: 'Your tickets for {{count}} findings are being created. This may take a moment.',
          },
        },
        creatingTicketSuccess: {
          title: {
            singular: 'Ticket created',
            plural: 'Tickets created',
          },
          description: {
            singular: 'Your ticket has been created successfully. ',
            plural: 'Your tickets have been created successfully. ',
          },
          link: 'View ticket',
        },
        creatingTicketFailed: {
          title: {
            singular: 'Failed to create ticket',
            plural: 'Failed to create tickets',
          },
          description: 'Reason: {{reason}}',
        },
      },
    },
    executions: {
      filters: {
        status: 'Status',
        planItem: 'Plan Item',
        clearFilters: 'Clear Filters',
      },
      executionsTable: {
        columnHeaders: {
          timestamp: 'Timestamp',
          item: 'Item',
          securityTool: 'Security Tool',
          asset: 'Asset',
          duration: 'Duration',
          testResult: 'Test Result',
        },
      },
    },
    login: {
      disclosure: 'By continuing you agree to our<br><termsOfService/> and <privacyPolicy/>.',
      termsOfService: 'terms of service',
      privacyPolicy: 'privacy policy',
      getStartedForFree: 'Start your free trial',
      trial: '14 day trial, no credit card required',
      or: 'OR',
      emailTitle: 'Login with SSO',
      emailInputPlaceholder: 'Enter your company email',
      continue: 'Login',
      continuousProduct: 'Continuous Product',
      theOnly: 'The Only',
      open: 'Open',
      devSecOps: 'DevSecOps',
      orchestrationPlatform: 'Orchestration Platform',
      description: 'Jit provides a single-pane-of-glass of all AppSec & DevSecOps tooling, enabling fast & simple rollout - get to 100% coverage of all resources, across dozens of security tools - in minutes.',
      socialLogins: {
        invalidTitle: 'Please use your work email',
        failedBackToLoginBtn: 'Sign in with company email',
      },
    },
    planMatrix: {
      title: 'Control Matrix',
      description: 'The Control Matrix is a visual representation of the security controls that are currently in use in your organization.\n\nThe matrix is organized by the following dimensions:',
      controls: 'Controls',
      table: {
        title: 'Control Matrix',
        columnHeaders: {
          itemsNames: {
            title: 'Security Items Catalog',
            layerSubtitle: 'Layer',
            itemNameSubTitle: 'Item name',
          },
          myPlan: {
            title: 'My Plan',
          },
          comingSoon: 'Coming soon',
        },
      },
      descriptionBox: {
        addToMyPlan: 'Add to my plan',
        goToMyPlan: 'Go to my plan',
      },
    },
    securityPlans: {
      title: 'Security Plans',
      subtitle: 'Tailored frameworks to guide users in achieving specific business goals, ensuring continuous security and certification readiness.',
      planSelector: {
        placeholder: 'Search plan',
        mainPage: 'All Security Plans',
      },
      planCard: {
        viewPlan: {
          label: 'View Plan',
        },
        controlsSummary: {
          controls: 'Controls',
          activatedControls: 'Activated <b>{{ active }}</b> / {{ total }}',
          passedControls: 'Passed <b>{{ passed }}</b> / {{ active }}',
        },
        setAsGoal: {
          setTooltip: 'Set plan as a goal',
          unsetTooltip: 'Remove goal',
          dialog: {
            header: 'Set Plan as a Goal',
            title: '{{ planName }} Plan Set as Goal',
            subtitle: 'Jit will facilitate progress towards your objective by<br/>enabling seamless and automated control activation<br/>and suggesting actionable recommendations.',
            buttons: {
              close: 'Close',
              plan: 'Go to Plan',
              gotIt: 'Got it!',
              dontShowAgain: 'Don\'t show again',
            },
          },
        },
      },
      planDetails: {
        securityControls: 'Security Controls',
        activated: 'Activated',
        passed: 'Passed',
        failed: 'Failed',
        score: {
          title: 'Score',
          tooltip: {
            description: 'The score is calculated by the average of the passed\nplan items / number of activated plan items.',
            lastUpdated: '\n\nLast update: {{ lastUpdated }}',
          },
        },
      },
      removeGoalDialog: {
        title: 'Remove Goal',
        description: 'Are you sure you want to remove this plan from My Goals?',
        the: 'The',
        setBackAsGoal: 'Plan</b> can be set back to goal at any time.',
        cancel: 'Cancel',
        remove: 'Remove',
      },
      planInfoCard: {
        learnMore: 'Learn More',
        setAsGoal: 'Set as Goal',
        addedAsGoal: 'Added as Goal!',
        removeGoalText: 'Remove',
        version: 'Version:',
      },
    },
    overview: {
      title: 'Overview',
      subtitle: '<b>Hi {{name}}!</b> Here\'s your organization\'s security status and recommended tasks.',
      teamsLeaderboard: {
        title: 'Team Leaderboard',
        subtitle: 'The teams listed here are the ones with the highest Jit Score:',
        footerButton: 'View and manage Jit teams',
        emptyStateTitle: 'Manage teams in Jit',
        emptyStateDescription: 'Your organization\'s teams will appear here once you create them in GitHub.',
      },
      insights: {
        title: 'Insights: <b>Last two weeks</b>',
        viewSecurityImpact: 'View Security Impact',
        previousPeriod: '<days /> Previous period',
        viewPullRequests: 'View {{scmRequestType}} Requests',
        viewPerformance: 'View Performance',
        developersTimeSaved: 'Developers time saved. <button>Edit Calculation</button>',
        pullRequestsWithFindings: {
          title: '{{scmRequestType}} Requests with Findings',
          tooltip: '{{scmRequestType}} requests with findings out of\nall {{scmRequestTypeLower}} requests reviewed by Jit.',
        },
        mttr: {
          title: 'Mean Time to Resolve (MTTR)',
          cardContent: '<days />d',
        },
        developersEfficiency: {
          title: 'Developers\' Efficiency',
          tooltip: 'Time and money saved by resolving\nfindings pre production.',
        },
      },
      securityPlans: {
        title: 'Security Plans',
        card: {
          viewPlan: 'View Plan',
          myGoals: 'My Goals',
          emptyTitle: 'Take Action: <b>Add Security Plans as Your Organization’s Business Goals</b>',
          emptyDescription: 'Jit will assist you in successfully completing your security plans. <learnMoreLink />',
          learnMoreText: 'Learn More',
          viewAllSecurityPlansButton: 'View All Security Plans',
        },
      },
      coverage: {
        title: 'Coverage',
        card: {
          LayersCoverage: {
            title: 'Security Controls',
            buttonText: 'Activate Security Controls',
          },
          ResourcesCoverage: {
            title: 'Resources',
            buttonText: 'Add Resources',
          },
        },
      },
      securityPosture: {
        title: 'Security Posture',
        card: {
          planItemFindings: {
            buttonText: 'View Performance',
            emptyState: {
              title: 'No Open Findings',
              description: 'Distribution of open findings based on each security control will be displayed here.',
            },
          },
        },
      },
    },
    plan: {
      title: 'My Plan',
      subtitle: 'Activate security controls to secure the product you are building.',
      exportPlanResults: 'Export Plan Results',
      configurationDialog: {
        trigger: {
          disabledTooltip: 'You must enter an environment name or unselect deployment scanning to continue.',
          title: 'Set up Trigger',
          subtitle: 'Your application can be scanned daily, or when a deployment is detected.',
          types: {
            scanDaily: 'Scan Daily',
            scanOnDeployment: {
              title: 'Scan on deployment',
              subtitle: 'When this option is enabled, Jit will scan your application when a deployment event is detected on your GitHub account. ',
              linkText: 'Learn more',
              linkHref: 'https://docs.jit.io/docs/deployment-based-scanning',
            },
            tooltipText: 'When this option is enabled, Jit will scan your application when a deployment event is detected on your GitHub account. For more information, see our ',
            documentation: 'Documentation',
          },
          fields: {
            environmentName: 'Environment name',
            applyToAll: 'Apply to All',
            awsAccountsInputText: 'AWS Accounts',
            accountsToScan: 'Accounts to scan',
            accountsToScanPlaceHolder: 'Choose AWS account',
          },
          optionCannotBeTurnedOff: 'This option cannot be turned off.',
          environmentEditDescription: 'The environment configured will be applied to all deployment scans.',
        },
        awsIntegration: {
          title: 'Scan Infrastructure for Runtime Misconfigurations',
          subtitle: 'This requirement requires an integration with AWS. ',
          learnMoreText: 'Learn more',
          learnMoreHref: 'https://docs.jit.io/docs/run-a-web-application-scanner',
          disabledTooltip: 'You must connect your AWS environment to continue.',
          link: 'View Jit AWS required permissions',
        },
        gcpIntegration: {
          title: 'Scan Infrastructure for Runtime Misconfigurations',
          subtitle: 'This requirement requires an integration with GCP.',
          disabledTooltip: 'You must connect your GCP environment to continue.',
          link: 'View Jit GCP required permissions',
        },
        azureIntegration: {
          title: 'Scan Infrastructure for Runtime Misconfigurations',
          subtitle: 'This requirement requires an integration with Azure.',
          disabledTooltip: 'You must connect your Azure environment to continue.',
          link: 'View Jit Azure required permissions',
        },
        secretIntegration: {
          title: 'Integration Required',
          subtitle: 'Complete the below integration to activate this plan item:',
          disabledTooltip: 'You must complete the integration using a token to continue.',
        },
        optionalSecretIntegration: {
          subtitle: 'Enhance your security coverage by completing optional integrations:',
          disabledTooltip: 'By choosing to integrate, you have to complete an integration with at least one of the options.',
        },
        githubIntegration: {
          link: 'View Jit GitHub required permissions',
        },
      },
      planItemsCategory: {
        steps: {
          targetApplication: 'Target Application',
          trigger: 'Trigger',
          notifications: 'Notifications',
          awsIntegration: 'AWS Integration',
        },
      },
      topRow: {
        itemsActivity: {
          totalItems: 'Security Controls',
          activeItems: 'Active',
          inactiveItems: 'Inactive',
        },
        totalItems: 'Total Items',
        currentPlan: 'Current Plan',
        planCompletion: 'Plan Completion',
        monitoredResources: 'Monitored Resources',
        manageAssets: 'Manage Assets',
        browseCatalog: 'Security Catalog',
        commitPlan: 'Commit Plan',
        planAsCode: 'Plan as-code',
        commitPlanTooltipMessages: {
          notReady: 'Please complete the onborading process to commit plan',
          notChanged: 'No changes since last commit',
          dotJitMissing: '.jit repo is missing, please create it first',
        },
      },
      statuses: {
        lastTested: 'last tested',
        scheduled: 'Scheduled',
        failed: 'Failed',
        passed: 'Passed',
        pending: 'Pending',
        completed: 'Completed',
        running: 'Running',
        tooltip: {
          header_one: 'Control test status of {{count}} asset:',
          header_other: 'Control test status of {{count}} assets:',
        },
      },
      itemDetails: {
        deactivate: 'Deactivate Security Control',
        title: 'Item Details',
        description: 'Description',
        whatWillJitDo: 'What Will Jit Do?',
        supportedLanguages: 'Supported Languages',
        securityTools: 'Security Tools',
        securityTool: 'Security Tool',
        website: 'Website',
        language: 'Language',
        links: 'Links',
        findingsCount: {
          title: 'Findings',
          button: 'View Full Details',
          singular: '{{count}} Security finding',
          plural: '{{count}} Security findings',
        },
        configuration: {
          title: 'Configuration',
          apply: 'Apply',
          ok: 'OK',
          pleaseFillMissingConfigurationBanner: 'Please fill missing configuration',
        },
        plans: 'Plans',
        integrations: 'Integrations',
        connected: 'Connected',
        add: 'Add',
        results: {
          title: 'Results',
          timestamp: 'Last evaluated: ',
          rerun: 'Re-run',
          successResources: {
            singular: '{{count}} resource passed',
            plural: '{{count}}{{plusSign}} resources passed',
          },
          failedResources: {
            singular: '{{count}} resource failed',
            plural: '{{count}}{{plusSign}} resources failed',
          },
          errorResources: {
            singular: '{{count}} resource has errors',
            plural: '{{count}}{{plusSign}} resources have errors',
          },
          latestPipeline: 'View latest pipeline',
        },
        info: {
          title: 'Control Info',
          activateButton: 'Activate',
          deactivateButton: 'Deactivate',
          whatWillJitDo: 'What will Jit do?',
          activateToConnect: 'Activate security control to connect integration.',
          manual: 'Manual',
          auto: 'Auto',
        },
      },
      planFilters: {
        allStatuses: 'All Statuses',
        allStates: 'All States',
      },
      planItem: {
        remove: 'Remove',
        activated: 'Activated',
        inactive: 'Inactive',
        activate: 'Activate',
        activateLong: 'Activate Security Control',
        findingsCount: {
          singular: '{{count}} finding',
          plural: '{{count}} findings',
        },
        rerun: {
          success: 'Rerun triggered successfully',
        },
        passed: 'Passed',
        failed: 'Failed',
        error: 'Error',
        pending: 'Pending',
        hasErrors: 'This run has Errors',
        isPremium: 'Premium feature',
        detailsSummary: {
          securityTools: 'Security Tools:',
          securityTool: 'Security Tool:',
          manual: 'Manual',
          configure: 'Configure',
          commitPlanToActivate: 'Commit plan to activate',
          manualControls: 'Manual Controls',
          scanningInProgress: 'Scanning in progress...',
          lastScanUpdate: 'Last Scan Update',
          lastEvaluated: 'Last Evaluated',
          noInformationYet: 'There is yet not information on previous scans',
          findingsCount: '{{findingsCount}} findings',
          awsMisConfiguration: {
            brokenIntegration: 'The integration is broken, your environments are not scanned',
            warning: 'Warning',
            partialMisconfig: 'One of your AWS accounts has a problem. ',
            configure: 'Configure',
          },
        },
        config: {
          configureItemButton: 'Configure',
          activateItemButton: 'Activate Security Control',
          finishOnboardingFirst: 'you must complete your onboarding first',
          configurationRequired: 'Configuration required',
          githubIntegrationRequired: 'GitHub integration required',
          scmIntegrationRequired: 'Source code management integration required',
          integrationRequired: '{{missingIntegrations}} integration required',
          comingSoon: 'Coming Soon',
          applyChangesFirst: 'Apply Configuration',
          clickToConfig: 'Click here',
        },
        viewReport: {
          viewReportButton: 'View Report',
        },
        scheduledTasks: {
          tooltip: {
            hasTasksTitle: '{{jobsDescription}}',
            noTasksTitle: 'No scheduled tasks for item',
          },
          jobs: {
            jobDescription: 'Tool {{willSentence}} {{timeString}}',
            cron: {
              jobIsScheduledToRun: 'is scheduled to run',
            },
            datetime: {
              jobWillRunAt: 'will run at',
            },
          },
        },
      },
      listTitle: {
        missingSettings: 'Configuration is required for {{numOfItemsWithImproperSettings}} item{{suffix}}',
        AvailableSecurityControls: 'Available Security Controls',
        activateAll: 'Activate All',
      },
      configurationsDialog: {
        title: 'Configure Security Control',
        apply: 'Apply',
        applyAndActivate: 'Apply and Activate',
        cancel: 'Cancel',
        missingField: 'One or more required field(s) are missing.',
        validating: 'Validating inputs, this may take a few seconds...',
        recheck: 'Please recheck your input and try again.',
        validationErrorTooltip: 'Validation error',
        validationInProgressTooltip: 'Validation in progress',
        commitInProgressTooltip: 'Committing changes...',
        missingAssetTooltip: 'Storing configurations...',
        manualItemsConfiguration: {
          addFreeText: 'Add free text',
          applyDisabledTooltip: 'Add note to apply changes.',
          controlStatus: 'Control Status',
          note: 'Note (required)',
          noteWithRequirements: 'General note',
          menuItemsNames: {
            passed: 'Passed',
            failed: 'Failed',
            not_applicable: 'Not Applicable',
          },
          requirementsMenuItemsNames: {
            passed: 'Yes',
            failed: 'No',
            not_applicable: 'Not Applicable',
          },
        },
      },
      integrationWidget: {
        title: 'Choose & Integrate SCM to Get Started',
        info: "Integrating the Jit app will enable activation for Jit's security scanners, which analyze your resources without pulling your code to the cloud.",
      },
      githubIntegration: {
        title: 'Integrate GitHub to Activate Jit',
        card: {
          title: 'GitHub',
          subtitle: 'Install our app in your GitHub organization. Choose which repositories must be secured.',
          button: 'Integrate',
        },
      },
    },
    pipelines: {
      title: 'All Pipelines',
      subTitle: 'Pipelines are created when your continuous security system is triggered by an event',
      statuses: {
        displayNames: {
          success: 'Success',
          failed: 'Failed',
          error: 'Error',
          running: 'Running',
          queued: 'Queued',
        },
        findings: {
          singular: '{{count}} Security finding',
          plural: '{{count}} Security findings',
          noNewFindings: 'No new findings',
        },
        errorMessage: 'Pipeline failed to run.\nSee pipeline details for logs.',
      },
      trigger: {
        displayNames: {
          fullScan: '{{scmRequestType}} request',
          pullRequestCreated: '{{scmRequestType}} request created',
          pullRequestUpdated: '{{scmRequestType}} request updated',
          mergeDefaultBranch: '{{scmRequestType}} request merged',
          triggerScheduledTask: 'Scheduled',
        },
        displayNamesNew: {
          fullScan: '{{scmRequestType}} request',
          pullRequestCreated: '{{scmRequestType}} request created',
          pullRequestUpdated: '{{scmRequestType}} request updated',
          mergeDefaultBranch: '{{scmRequestType}} request merged',
          triggerScheduledTask: 'Daily Scan',
          deployment: 'Deployment Started',
          productionDeployment: 'Production Deployment Started',
          nonProductionDeployment: 'Non-Production Deployment Started',
          openFixPullRequest: 'Remediation Action Requested',
          itemActivated: 'Plan item activated',
          jitBranchChanged: 'Jit branch changed',
          sync: 'Synchronous Execution',
          manualBranchScan: 'Manual Branch Scan',
        },
        displayNamesV3: {
          fullScan: 'Triggered by <b>{{scmRequestType}} Request</b>',
          pullRequestCreated: 'Triggered by <b>{{scmRequestType}} Request Created</b>',
          pullRequestUpdated: 'Triggered by <b>{{scmRequestType}} Request Updated</b>',
          mergeDefaultBranch: 'Triggered by <b>{{scmRequestType}} Request Merged</b>',
          triggerScheduledTask: 'Triggered by <b>Daily Scan</b>',
          deployment: 'Triggered by <b>Deployment on {{ environment }} Started</b>',
          productionDeployment: 'Triggered by <b>Production Deployment on {{ environment }} Started</b>',
          nonProductionDeployment: 'Triggered by <b>Non-Production Deployment on {{ environment }} Started</b>',
          openFixPullRequest: 'Triggered by <b>Remediation Action Requested</b>',
          itemActivated: 'Triggered by <b>Item Activated</b>',
          manualExecution: 'Triggered by <b>Manual Execution</b>',
          resourceAdded: 'Triggered by <b>Resource Added</b>',
          jitBranchChanged: 'Triggered by <b>Jit branch changed</b>',
          sync: 'Triggered by <b>Synchronous Execution</b>',
          manualBranchScan: 'Triggered by <b>Manual Branch Scan</b>',
        },
      },
      pipelinesTable: {
        createPipelinesLoading: 'Waiting for pipeline creation',
        noPipelinesData: 'No pipelines have been created yet.<br> Try activating a security requirement from the plan page.',
        collectWorkflowsLoading: 'Collecting workflows',
        loading: 'Loading',
        noWorkflows: 'No workflows',
        noRunner: 'No runner',
        openDetails: 'Open Details',
        resources: {
          aws: 'AWS',
          github: 'Repository',
          org: '{{scmOrg}}',
          repo: '{{scmRepo}}',
          web: 'Web application',
          api: 'API',
        },
        TriggerEventDisplayNames: {
          monitoredResources: 'Monitored Resources',
        },
        resourceNameWithId: '<b>{{assetName}}</b> ID {{id}}',
        resourceDetailsTooltip: {
          start: 'Start time',
          duration: 'Running duration',
          jitJobRunner: 'This pipeline runs on Jit cloud',
          github_actionsJobRunner: 'This pipeline runs on GitHub actions',
          ciJobRunner: 'This pipeline runs on your CI system',
        },
        actionRunners: {
          github_actions: 'GitHub Actions',
          ci: 'CI',
          jit: 'Jit',
        },
        scopes: {
          change: 'Change',
          all: 'All',
        },
        scopesTooltip: {
          change: 'Jit scans and surfaces new vulnerabilities\nrelated to these specific code changes',
          all: 'Jit scans and surfaces all the vulnerabilities\nrelated to this resource',
        },
        columnHeaders: {
          resourceDetails: 'Resource Details',
          triggerEvent: 'Trigger Event',
          pipelineStatus: 'Pipeline status',
          workflow: 'Workflow',
          trigger: 'Trigger',
          start: 'Start',
          scope: 'Scope',
          details: 'Details',
        },
        columnHeadersV3: {
          resource: 'Resource',
          runner: 'Runner',
          workflows: 'Workflows',
          pipelineStatus: 'Pipeline Status',
        },
        executionLogs: {
          viewLog: 'View log',
        },
        missingGithubIntegration: 'GitHub integration required in order to activate security controls and see pipelines. In the Plan page, under Activation, click Integrate.',
        missingScmIntegrationSubtitleAction: 'activate security controls and see pipelines',
      },
      pipelinesActivation: {
        dismiss: 'Dismiss',
        'item-secret-detection': {
          clean: {
            title: 'Testing Secret Detection',
            body: 'We\'ve just opened a test {{scmRequestTypeLower}} request on <b>{{assetName}}</b>,'
              + ' which will create a successful pipeline in the pipelines page.<br/>'
              + ' Now, let’s introduce a {{scmRequestTypeLower}} request with a secret.',
            loadingBody: 'Please wait while we open a new {{scmRequestTypeLower}} request on your selected repository.<br/> This will take a few seconds.',
            button: 'Introduce a secret',
          },
          vulnerability: {
            title: 'Testing Secret Detection',
            body: 'We’ve just opened a {{scmRequestTypeLower}} request containing a secret on <b>{{assetName}}</b><br/>'
              + 'The secret will cause the pipeline to fail, showing alerts on GitHub '
              + 'and here, in the pipelines page.',
            loadingBody: 'Please wait while we open a new {{scmRequestTypeLower}} request on your selected repository.<br/> This will take a few seconds.',
            button: 'View on GitHub',
          },
        },
        'item-code-vulnerability': {
          clean: {
            title: 'Scanning code for vulnerabilities',
            body: 'We\'ve just opened a test {{scmRequestTypeLower}} request on <b>{{assetName}}</b>,'
              + ' which will create a successful pipeline.'
              + ' Now, let’s introduce a {{scmRequestTypeLower}} request with a vulnerability.',
            loadingBody: 'Please wait while we open a new {{scmRequestTypeLower}} request on your selected repository.<br/> This will take a few seconds.',
            button: 'Introduce a vulnerability',
          },
          vulnerability: {
            title: 'Scanning code for vulnerabilities',
            body: 'We’ve just opened a {{scmRequestTypeLower}} request containing a vulnerability on <b>{{assetName}}</b><br/>'
              + 'The vulnerability will cause the pipeline to fail, showing alerts on GitHub '
              + 'and here, in the pipelines page.',
            loadingBody: 'Please wait while we open a new {{scmRequestTypeLower}} request with a vulnerability on your selected repository.<br/> This will take a few seconds.',
            button: 'View on GitHub',
          },
        },
        'item-dependency-check': {
          clean: {
            title: 'Testing Scanning code dependencies for vulnerabilities',
            body: 'We\'ve just opened a test {{scmRequestTypeLower}} request on <b>{{assetName}}</b>,'
              + ' which will create a successful pipeline.'
              + ' Now, let’s introduce a {{scmRequestTypeLower}} request with a dependency vulnerability.',
            loadingBody: 'Please wait while we open a new {{scmRequestTypeLower}} request on your selected repository.<br/> This will take a few seconds.',
            button: 'Introduce a vulnerability',
          },
          vulnerability: {
            title: 'Testing Scanning code dependencies for vulnerabilities',
            body: 'We’ve just opened a {{scmRequestTypeLower}} request containing a dependency vulnerability on <b>{{assetName}}</b><br/>'
              + 'The vulnerability will cause the pipeline to fail, showing alerts on GitHub '
              + 'and here, in the pipelines page.',
            loadingBody: 'Please wait while we open a new {{scmRequestTypeLower}} request with a vulnerability on your selected repository.<br/> This will take a few seconds.',
            button: 'View on GitHub',
          },
        },
        'item-iac-misconfiguration-detection': {
          clean: {
            title: 'Testing Scanning IaC for static misconfigurations',
            body: 'We\'ve just opened a test {{scmRequestTypeLower}} request on <b>{{assetName}}</b>,'
              + ' which will create a successful pipeline.<br/>'
              + ' Now, let’s introduce a {{scmRequestTypeLower}} request with a IaC misconfiguration.',
            loadingBody: 'Please wait while we open a new {{scmRequestTypeLower}} request on your selected repository.<br/> This will take a few seconds.',
            button: 'Introduce a misconfiguration',
          },
          vulnerability: {
            title: 'Testing Scanning IaC for static misconfigurations',
            body: 'We’ve just opened a {{scmRequestTypeLower}} request containing a vulnerability on <b>{{assetName}}</b><br/>'
              + 'The misconfiguration will cause the pipeline to fail, showing alerts on GitHub '
              + 'and here, in the pipelines page.',
            loadingBody: 'Please wait while we open a new {{scmRequestTypeLower}} request with a vulnerability on your selected repository.<br/> This will take a few seconds.',
            button: 'View on GitHub',
          },
        },
        'item-container-scan': {
          clean: {
            title: 'Testing Container Scanning',
            body: 'We\'ve just opened a test {{scmRequestTypeLower}} request on <b>{{assetName}}</b>,'
              + ' which will create a successful pipeline.<br/>'
              + 'Now, let’s introduce a {{scmRequestTypeLower}} request with a vulnerability.',
            loadingBody: 'Please wait while we open a new {{scmRequestTypeLower}} request on your selected repository.<br/> This will take a few seconds.',
            button: 'Introduce a vulnerability',
          },
          vulnerability: {
            title: 'Testing Container Scanning',
            body: 'We’ve just opened a {{scmRequestTypeLower}} request containing a container vulnerability on <b>{{assetName}}</b><br/>'
              + 'The vulnerability will cause the pipeline to fail, showing alerts on GitHub and here, in the pipelines page.',
            loadingBody: 'Please wait while we open a new {{scmRequestTypeLower}} request with a vulnerability on your selected repository.<br/> This will take a few seconds.',
            button: 'View on GitHub',
          },
        },
        defaultRepository: 'In your selected repository',
      },
      filters: {
        events: {
          deployment: 'Deployment Started',
          open_fix_pull_request: 'Remediation Action Requested',
          item_activated: 'Item Activated',
          trigger_scheduled_task: 'Daily Scan',
          jit_branch_changed: 'Jit branch changed',
          sync: 'Synchronous Execution',
          manual_branch_scan: 'Manual Branch Scan',
        },
        resource: {
          category: {
            aws_account: 'AWS Accounts',
            aws_org: 'AWS Organizations',
            repo: 'Repositories',
            org: 'GitHub Organizations',
            web: 'Web applications',
            api: 'APIs',
          },
        },
      },
      navigate: {
        toPipelines: 'Go to the pipeline page for scan details',
      },
    },
    pullRequests: {
      title: '{{scmRequestType}} Requests',
      subtitle: 'This page displays {{scmRequestType}} Request security statistics and the most relevant {{scmRequestTypeShort}} events to review.',
      contentTitle: 'Failed {{scmRequestTypeShort}}s',
      contentSubtitle: 'With Security Findings',
      dataPeriod: 'Period: 14 days ({{periodStartDate}}  -  {{periodEndDate}}) compared to the previous two weeks.',
      statisticsWidget: {
        totalReviewedPrsTooltip: 'Jit reviewed {{allPrsAmount}} {{scmRequestTypeShort}}s in the last two weeks.',
        passedPrsTooltip: '{{passedPrsAmount}} {{scmRequestTypeShort}}s passed with no security findings.',
        failedPrsTooltip: '{{failedPrsAmount}} {{scmRequestTypeShort}}s failed (with security findings).<br> Found on {{failedPrsReposAmount}} out of'
          + ' {{allPrsReposAmount}} repos scanned.',
        failedPrs: 'Failed {{scmRequestTypeShort}}s',
        passedPrs: 'Passed {{scmRequestTypeShort}}s',
      },
      statistics: {
        titles: {
          prsMonitoredTitle: '{{scmRequestTypeShort}}s were reviewed by Jit',
          prsOpenTitle: '{{scmRequestTypeShort}}s with Findings',
          fixedMergedPrsTitle: '{{scmRequestTypeShort}}s were fixed!',
        },
        subTitles: {
          prsMonitoredSubTitle: 'In the past 2 weeks',
          prsOpenSubTitle: 'Found on {{reposWithFindings}} out of {{scannedRepos}} repos scanned',
          fixedMergedPrsSubTitle: '{{numberOfHours}} hours of work saved using Jit',
        },
      },
      prsLists: {
        columns: {
          repository: '{{scmRepo}}',
          prLink: '{{scmRequestTypeShort}} Link',
          opened: 'Opened',
          merged: 'Merged',
        },
        types: {
          mergedPrsWithFailed: {
            title: 'Merged with Open Findings',
            subTitle: 'Review {{scmRequestTypeShort}}s that were merged to your main branch with security findings.',
          },
          mergedPrsWithFailedIgnored: {
            title: 'Merged with Ignored Findings',
            subTitle: 'Review {{scmRequestTypeShort}}s that were merged to your main branch with ignored security findings.',
          },
          openPrsWithFailedChecks: {
            title: 'Open with Findings',
            subTitle: 'Review {{scmRequestTypeShort}}s currently opened with security findings and assist in releasing the stalled ones.',
          },
          mergedPrsWithFixedChecks: {
            title: 'Merged with Fixed Findings',
            subTitle: 'Review {{scmRequestTypeShort}}s that were merged to your main branch with fixed security findings.',
          },
        },
        stalePRsTitle: 'Stale {{scmRequestTypeShort}}s with Open Security Findings',
        stalePRsSubtitle: 'Open for more than 3 days',
        mergedPRsWithFindingsTitle: 'Merged {{scmRequestTypeShort}}s with Open Security Findings',
        mergedPRsWithIgnoredFindingsTitle: 'Merged {{scmRequestTypeShort}}s with Ignored Security Findings',
        reasonForIgnoring: 'Reason for ignoring',
        description: 'Description',
        ignoreButton: 'Ignore',
        cancelButton: 'Cancel',
        selectSecurityFindings: 'Select security findings in order to apply actions',
        selectedFindings: '{{numberOfSelectedFindings}} Findings Selected',
        loadingFindings: 'Loading Findings',
        emptyList: {
          title: 'Nice!',
          description: "You don't have {{scmRequestTypeShort}}s to review",
        },
      },
    },
    insights: {
      insightsMenuTitle: 'Insights:',
      insightsMenuPlaceholder: 'Choose page',
      showDetails: 'Show Details',
      listCardItem: {
        findings: {
          total: '{{totalFindings}} Findings detected:',
          new: '{{newFindings}} New',
          ignored: '{{ignoredFindings}} Ignored',
          fixed: '{{fixedFindings}} Fixed',
        },
      },
    },
    securityImpact: {
      title: 'Security Impact',
      subTitle: 'Security report since your organization started using Jit until today: <dates />',
      sections: {
        coverage: {
          title: 'Coverage',
          description: 'Understand which resources you are actively protecting and which security controls are active.',
        },
      },
      layers: {
        title: 'Security Controls',
        percentageDescription: 'Covered out of all Jit security layers.',
        actionText: 'Activate Security Controls',
        actionTitle: 'No activated security controls',
      },
      resources: {
        title: 'Resources',
        percentageDescription: 'Covered out of all known resources.',
        buttonText: 'Activate Security Controls',
        actionText: 'Manage Resources',
        actionTitle: 'No covered resources',
      },
      resolvedFindings: {
        title: 'Resolved Findings',
        description: 'Findings detected & resolved in your application.',
        actionText: 'Show Details',
        resolvedButton: 'Resolved',
        detectedButton: 'Detected',
      },
      coverage: {
        title: 'Coverage',
        description: "You activated <activeItemsPercentage /> of Jit's security controls and you are covering <coveragePercentage /> of your resources.",
        securityControls: {
          title: 'Security Controls',
          description: 'Activate Jit security controls',
        },
        planCoverage: {
          title: 'Resources',
          description: 'Covered out of all known resources',
        },
      },
      devProductivity: {
        title: 'Dev Productivity',
        description: 'Your organization saved <time /> due to <developersCount /> who resolved {{preProdResolvedFindings}} security findings before reaching production.',
        developersCount: '{{developersCount}} Active Developers',
        threatPrevention: {
          title: 'Resolved Pre Production',
          description: 'Issues resolved before reaching production.',
        },
        developersEfficiency: {
          title: 'Developers\' Efficiency',
          description: 'Interacted with Jit and resolved issues.',
          // eslint-disable-next-line no-template-curly-in-string
          moneySaved: '${{moneySaved}}',
          equalSign: '=',
        },
      },
      securityPosture: {
        title: 'Security Posture',
        detectedFindingsTitle: 'Detected Findings',
        newOpenFindingsTitle: 'New Open Findings',
        resolvedFindingsTitle: 'Resolved Findings',
        mttrTitle: 'Mean Time to Resolve (MTTR)',
        resolvedFindingsCount: '{{ resolvedFindingsCount }} findings',
        openFindingsCount: '{{ openFindingsCount }} open findings',
        openFindingsFromCurrentPeriodCount: '{{ openFindingsFromCurrentPeriodCount }}',
        resolutionBold: 'Resolution',
        description: '<resolvedFindingsCount /> successfully resolved using Jit. <br> There are currently <openFindingsCount />. <br> <openFindingsFromCurrentPeriodCount /> new open findings have been added over the last 3 months. <br> Mean time to resolution over the last 3 months is <averageMttrString />.',
        openFindingsTitle: 'Production Open Findings',
        openFindingsDescription: 'How many findings remain open in your organization.',
        findingsOverTimeTitle: 'Production Findings Over Time',
        findingsOverTimeDescription: 'Findings detected & resolved in your organization.',
        detectedFindings: {
          last90Days: 'Last 90 Days:',
          previous90Days: 'Previous 90 Days',
          totalOpenFindings: 'Current total open findings',
          detectedFindings: 'Detected Findings',
          resolvedFindings: 'Resolved Findings',
          openFindings: 'Open Findings',
        },
        mttrOverview: {
          last90Days: 'Last 90 Days',
          previous90Days: 'Previous 90 Days',
          totalResolvedFindings: 'Total Resolved Findings',
          initialMttr: 'Initial MTTR',
          industryStandard: 'Industry standard',
          initialMttrPopoverTitle: 'Initial MTTR',
          initialMttrPopoverContent: 'The average time it took to resolve the first 10 findings in your organization.',
          industryStandardPopoverTitle: 'Industry Standard',
          industryStandardPopoverContent: 'The average time it takes to resolve security findings in the industry.',
        },
      },
      calculator: {
        findingsResolvedPreProd: 'Findings resolved pre-production',
        averageTimeSaved: 'Average time saved',
        productionAdditionalMttrIncludes: 'Production additional MTTR includes: Research, <br> Coding, Reviewing, Deploying',
        avgHourlySalary: 'Average hourly salary',
        apply: 'Apply',
        title: 'Developer Efficiency Calculation',
        editCalculation: 'Edit Calculation',
      },

    },
    securityImpactV2: {
      title: 'Insights Overview',
      sections: {
        securityProgress: {
          title: 'Security progress',
          rightTitleElement: 'General metrics - All times',
          widgets: {
            orgScore: {
              title: 'My Org’s Security Posture',
              tooltip: "View your organization's score trend over time.\n The organization score represents the average of all your team's individual scores.",
              label: 'Organization score | Total',
            },
            resolvedIssues: {
              title: 'How Many Security Findings Were Resolved?',
              tooltip: 'Number of resolved findings both fixed and ignored since the initial scan, relative to the total findings detected.',
              label: 'resolved findings of all detected | All times',
              type: 'findings',
            },
            coveredResources: {
              title: 'How Many Of Our Resources Are Covered?',
              headers: {
                resourceType: 'Resource type',
              },
            },
          },
        },
        performance: {
          title: 'Performance',
          rightTitleElement: {
            periodsOptions: {
              last90Days: 'Last 90 days',
            },
            tooltip: 'Additional time frames will be available soon',
          },
          widgets: {
            mttr: {
              title: 'How Quickly Are We Resolving Issues (Mean Time to Resolution)?',
              tooltip: 'MTTR measures the median time from detection to remediation of security finding.',
              previousPeriodMttr: 'Previous {{days}} days: {{previousMttr}}',
            },
            fixedIssues: {
              title: 'Are We Fixing More Findings in Pre-Production or Production?',
              tooltip: 'Comparison of security findings resolved in \n pre-production versus those addressed from \n the backlog in post-production.',
              bars: {
                preProduction: 'Fix pre production',
                production: 'Fix production',
              },
            },
            ignoredIssues: {
              title: 'How Many Issues Were Ignored?',
              categories: {
                resolved: 'Fixed',
                ignoredAcceptRisk: 'Ignored - Accept risk',
                ignoredFalsePositive: 'Ignored - False positive',
              },
            },
            mostDetectedFindings: {
              title: 'What Are the Top Repeated Findings?',
              tooltip: 'Top 3 Frequently Detected Vulnerabilities.',
              headers: {
                key: 'Security findings type',
                count: 'Detected',
              },
              navigate: 'View open findings',
            },
            securityAwareDevelopers: {
              title: 'Are developers resolving issues before production?',
              subTitle: 'Weekly Avg % of PRs with fixes over time',
            },
            secureDevsWorking: {
              title: 'How Security Mature Are Our Developers?',
              subTitle: 'Weekly Avg % Of flagged PRs (PRs with findings)',
              referenceLineLabel: 'Industry benchmark',
            },
          },
        },
      },
    },
    deployments: {
      title: 'Deployments',
      subtitle: 'This page displays your deployment`s security insights associated with specific merged {{scmRequestTypeShort}}s.',
      contentTitle: 'Failed Deployments',
      contentSubtitle: 'Deployments With Security Findings',
      dataPeriod: 'Period: 14 days ({{periodStartDate}}  -  {{periodEndDate}}) compared to the previous two weeks.',
      statisticsWidget: {
        totalReviewedPrsTooltip: 'Jit reviewed {{allPrsAmount}} {{scmRequestTypeShort}}s in the last two weeks.',
        passedPrsTooltip: '{{passedPrsAmount}} {{scmRequestTypeShort}}s passed with no security findings.',
        failedPrsTooltip: '{{failedPrsAmount}} {{scmRequestTypeShort}}s failed (with security findings).<br> Found on {{failedPrsReposAmount}} out of'
          + ' {{allPrsReposAmount}} repos scanned.',
        failedPrs: 'Failed',
        passedPrs: 'Passed',
      },
      statistics: {
        titles: {
          prsMonitoredTitle: '{{scmRequestTypeShort}}s were reviewed by Jit',
          prsOpenTitle: '{{scmRequestTypeShort}}s with Findings',
          fixedMergedPrsTitle: '{{scmRequestTypeShort}}s were fixed!',
        },
        subTitles: {
          prsMonitoredSubTitle: 'In the past 2 weeks',
          prsOpenSubTitle: 'Found on {{reposWithFindings}} out of {{scannedRepos}} repos scanned',
          fixedMergedPrsSubTitle: '{{numberOfHours}} hours of work saved using Jit',
        },
        header: {
          title: 'Deployments Summary',
          environmentTitle: 'Environment:',
        },
        tooltips: {
          totalReviewedDeploymentsTooltip: 'Jit reviewed {{totalAmount}} Deployments in the last two weeks.',
          passedDeploymentsTooltip: '{{passedDeploymentsAmount}} Deployments passed with no security findings.',
          failedDeploymentsTooltip: '{{failedDeploymentsAmount}} Deployments failed (with security findings).',
        },
      },
      deploymentsLists: {
        types: {
          deploymentsWithFailedFindings: {
            title: 'Deployments with security findings',
            subTitle: 'Review deployments with at least one new finding.',
          },
          deploymentsWithIgnoredFindings: {
            title: 'Deployments with ignored findings',
            subTitle: 'Review deployments with at least one ignored finding.',
          },
          deploymentsWithFixedFindings: {
            title: 'Deployments with fixed findings',
            subTitle: 'Review deployments with at least one fixed finding.',
          },
        },
      },
      deploymentsListItem: {
        title: 'Repo {{repoName}} deployment',
      },
      emptyList: {
        title: 'Nice!',
        description: "You don't have any deployments to review",
      },
      noDeploymentConfiguration: {
        title: 'Welcome to the Deployments Insights page',
        description: 'Insights from deployments will appear here once you set up your environment name and the deployment scan trigger on the relevant security controls.',
        planPageRedirectButton: 'Go to the Security Plans Page',
      },
    },
    performance: {
      pageName: 'Performance',
      title: 'DevSecOps Performance',
      shortTitle: '(DSOP) - {{snapshotName}} (snapshot)',
      shortTitleNoSnapshot: '(DSOP)',
      subtitle: 'Keep track of metrics to improve security handling within your organization.',
      dataPeriod: 'Period: {{daysAmount}} days ({{periodStartDate}}  -  {{periodEndDate}}) compared to the previous'
        + ' period.',
      periodsOptions: {
        ONE_WEEK: 'Last Week',
        TWO_WEEKS: 'Last Two Weeks',
        ONE_MONTH: 'Last Month',
        THREE_MONTHS: 'Last Three Months',
      },
      shareSnapshot: {
        dateDisplayText: '{{startDate}} to {{endDate}}',
        openDialogButtonText: 'Share Snapshot',
        dialogTitle: 'Share Snapshot',
        snapshotNameInputLabel: 'Snapshot Name',
        snapshotNameInputPlaceholder: 'My Performance Snapshot',
        expireInputLabel: 'Expire',
        dialogMessage: 'Share your organization\'s performance reports publicly, as a link, based on current selected dates, using snapshots.',
        expirationOptions: {
          ONE_WEEK: 'In One week',
          TWO_WEEKS: 'In Two weeks',
          ONE_MONTH: 'In One month',
        },
        createSnapshotButtonText: 'Create Snapshot',
        cancelButtonText: 'Cancel',
        snapshotUrl: 'Snapshot URL',
        copyButtonText: 'Copy Link',
        copiedButtonText: 'Link copied to clipboard',
        tooltipText: 'Snapshot with the same preferences already exist. Change settings to share.',
        notFound: {
          title: 'Snapshot Unavailable',
          description: 'The page you’re looking for could not be found.',
        },
        history: {
          title: 'Snapshot History',
          description: 'Browse and edit your snapshot list history. Expired snapshots are automatically removed from the list.',
          orgOnly: 'Org Only',
          public: 'Public',
          CopyLink: 'Copy Link',
          loading: 'Loading Snapshots History',
          delete: {
            title: 'Delete Snapshot',
            confirmationMessage: 'Are you sure you want to delete {{snapshotName}} snapshot?',
            explanationMessage: 'You won\'t be able to access the snapshot after deleting it.',
            cancelButtonText: 'Cancel',
            deleteButtonText: 'Delete',
          },
        },

      },
      graphs: {
        performanceRateTitle: 'Performance Rate',
        emptyGraph: {
          title: "Data will be added as soon as it's available",
          description: 'This chart is empty for now',
        },
        findingCountList: {
          seeMore: 'See more',
        },
        detectionRate: {
          widget: {
            title: 'Detection Rate',
            description: 'How often a {{scmRequestTypeLower}} request or deployment contain security findings.',
          },
          title: 'Security Detection Rate',
          description: 'How often a scan contains security findings.',
          tooltip: {
            detectedFindings: 'Findings detected',
            failedExecutions: 'Scans with findings',
            totalExecutions: 'Total Scans',
            noData: 'There were no scans on this day',
          },
        },
        findingsOverTime: {
          widget: {
            title: 'Findings Over Time',
            description: 'Displays the total number of security findings detected and resolved in your application over the selected time period.\n\nEach data point represents the accumulated findings for that day. This can be used to track the progress of your application’s security and identify trends in findings.',
          },
          checkboxes: {
            detected: 'Detected',
            resolved: 'Resolved',
          },
          title: 'Findings Over Time',
          description: 'Findings detected & resolved in your application.',
          tooltip: {
            detectedFindings: 'Findings detected',
            resolvedFindings: 'Findings resolved',
          },
        },
        dailyFindingsActivities: {
          widget: {
            title: 'Daily Findings Activities',
            description: 'Displays newly opened and resolved vulnerabilities each day. Resolved includes fixed or deleted vulnerabilities (due to asset deletion or plan item deactivation).',
          },
          checkboxes: {
            detected: 'Detected',
            resolved: 'Resolved',
          },
          title: 'Daily Findings Activities',
          description: 'Opened and resolved vulnerabilities each day.',
          tooltip: {
            detectedFindings: 'Findings detected',
            resolvedFindings: 'Findings resolved',
          },
        },
        mttr: {
          widget: {
            title: 'MTTR Metric',
            description: 'MTTR measures the mean time from detection to remediation of security findings. Here you can view the industry standard to measure your team\'s performance.',
          },
          title: 'Mean Time to Resolve (MTTR)',
          description: 'Mean time to resolve',
          environments: {
            production: 'Production',
            preProduction: 'Pre production',
          },
          tooltip: {
            production: 'Production',
            preProduction: 'Pre production',
            fixedFindings: 'Fixed Findings',
            noData: 'No finding was remediated on this day',
          },
        },
        exposureWindow: {
          widget: {
            title: 'Exposure Window',
            description: 'Highlights the length of time that security findings remain open in your production environment.\n\nThis can be used to understand the duration of vulnerability for your application and prioritize resolution efforts.',
          },
          title: 'Exposure Window',
          description: 'How many findings remain open in your organization and for how long',
          tooltip: {
            total: 'Total',
          },
        },
        resourceTypeFindings: {
          title: 'Open Findings per Resource Type',
          description: 'Distribution of open findings based on resource type.',
        },
        planItemFindings: {
          title: 'Open Findings per Security Control',
          description: 'Distribution of open findings based on each security control.',
          displayNames: {
            planItemSecretDetection: 'Static Secrets Detection',
            planItemIACMisconfigurationDetection: 'Cloud IaC Scanning',
            planItemCodeVulnerability: 'Code SAST Scanning',
            planItemDependencyCheck: 'Open Source Security Scanning (SCA)',
            planItemContainerScan: 'Docker IaC Scanning',
            planItemMFASCM: 'GitHub MFA Enable Checker',
            planItemRuntimeMisconfigurationDetection: 'Cloud Runtime Scanning',
            planItemMFACLoudProviders: 'AWS MFA Enable Checker',
            planItemAPISecurity: 'DAST Scanning for APIs',
            planItemWebappScanner: 'DAST Scanning for Web Apps',
            planItemBranchProtectionSCM: 'Branch Protection Checker',
            planItemIACKubernetes: 'Kubernetes IaC Scanning',
            planItemGithubMisconfiguration: 'GitHub Misconfiguration Checker',
          },
        },
        pieChartCountList: {
          pieChart: {
            label: 'Open findings',
          },
        },
      },
    },
    teams: {
      title: 'Teams',
      subtitle: 'Get an overview of how your teams are working with Jit and follow their progress.',
      invite: {
        button: 'Invite members',
        dialog: {
          title: 'Invite members',
          integrateSlack: {
            title: 'Invite members',
            subtitle: 'Empower developers to own the security of the code they write.',
            card: {
              description: 'To get the best experience, it is recommended to invite via Slack.',
              configure: 'Configure integration',
            },
            button: 'Invite via Slack',
          },
          sendInvite: 'Send invite',
          slackUser: 'Slack user',
          inviteViaSlack: 'Invite via Slack',
          copyInviteLink: 'Copy link',
          copyToClipboardTooltip: 'Copy to clipboard',
          copiedToClipboardTooltip: 'Copied!',
          invitationSent: 'Invitation sent successfully',
          engineeringManager: 'Eng Manager',
          role: 'Role',
        },
      },
      teamsTable: {
        noData: {
          title: 'Manage teams in Jit',
          description: 'All your organization GitHub teams will appear here once <br> you’ll create them in GitHub.',
        },
        noSearchResults: {
          title: 'No Results Found',
          description: 'We can’t found any team matching your search',
        },
        columns: {
          name: 'Name',
          members: 'Members',
          resources: 'Resources',
          score: 'Score',
          isActivated: {
            header: 'Status',
            true: 'Activated',
            false: 'Inactive',
          },
        },
        missingGithubIntegration: 'GitHub integration required in order to see teams. In the Plan page, under Activation, click Integrate.',
        missingScmIntegrationSubtitleAction: 'see teams',
        noTeams: {
          title: 'Manage Your Teams in Jit',
          description: 'Boost developer code security ownership! Connect all your teams, invite team leads to Jit for collaborative, secure development across all teams.',
        },
        importTeams: {
          button: 'Import Teams From JSON File',
          dialog: {
            title: "Import your organization's team structure from a JSON file",
            fileInputTitle: 'Teams structure',
            alertMessages: {
              auto: 'Uploading a new teams structure file will disable auto sync and remove already synced teams.',
              manual: 'Uploading a new team file will update existing teams structure or add new ones.',
            },
          },
          loadingText: 'Uploading teams, it may take a few moments',
          resource: {
            error: {
              title: 'Resource Attachment Failed',
              description: 'Failed to attach resources to team {{teamName}}. Missing integrations for: {{vendors}}',
            },
          },
        },
        removeTeam: {
          title: 'Remove team',
          message: 'Are you sure you want to remove {{teamName}} from your organization?',
          subMessage: 'It is still possible to add this team again using a JSON file.',
          tooltip: 'Remove team',
          successToastTitle: 'Team removed successfully',
          successToastDescription: 'Your team has been successfully removed from your organization!',
        },
        syncGradient: {
          title: 'Welcome to',
          description: 'The portal that empowers developers to take responsibility for security! <br>We mapped teams using GitHub in your team organization structure.<br>Start with this structure or import teams from a JSON file.',
          externalSyncButton: 'Continue with GitHub teams',
        },
      },
      resourcePlanItemsStatuses: {
        resourceLink: 'View in {{vendor}}',
        notFound: {
          title: 'Resource Not Found',
          description: 'The resource you are looking for does not exist.',
        },
        planItemsTable: {
          columns: {
            planItems: 'Plan Items {{planItemsAmount}}',
            results: 'Results',
          },
          noData: 'No Security Items have been created yet.', // TODO: Review with Lital
          cells: {
            results: {
              passed: 'Passed',
              failed: 'Failed',
              securityFindings: '{{findingsCount}} Security Findings',
            },
          },
        },
        stats: {
          score: 'Score',
          securityFinding: 'Security Findings',
        },
        rowHover: {
          toolTips: {
            actions: 'View related actions',
            findings: 'View findings in Backlog page',
          },
        },
      },
      teamDetails: {
        securityFindings: 'Security Findings',
        score: 'Score',
        scoreDescription: 'The team score is calculated by the average of the<br> passed plan items / number of activated plan items.',
        seeDetails: 'See Details',
        setupSlack: {
          buttonText: 'Set Slack Notification',
          disabledTooltipText: 'Slack integration must first be connected\n through the Integrations page.',
        },
        notFound: {
          breadcrumbs: 'Not Found',
          title: 'Team Not Found',
          description: 'The team you are looking for does not exist.',
        },
        membersList: {
          title: '<b>Members</b> ({{membersCount}})',
          noMembers: 'No Members',
        },
        childTeamsList: {
          title: '<b>Child Teams</b> ({{childTeamsCount}})',
          noChildTeams: 'No Child Teams',
        },
        resourceTable: {
          title: 'Resources ({{resourcesCount}})',
          columns: {
            name: 'Name',
            tags: 'Risk Factors',
            score: 'Score',
          },
          noResources: {
            title: 'No Resources',
            description: 'This team doesn’t have associated resources',
          },
        },
      },
    },
    actions: {
      title: 'Actions',
      manySubtitle: 'Listed below are {{numberOfActions}} actions that help remediate vulnerabilities from your backlog.',
      noActionsMessage: 'Actions will appear here if there are vulnerabilities in your backlog that need to be remediated.',
      singleSubtitle: 'Listed below is 1 action that help remediate vulnerabilities from your backlog.',
      actionCard: {
        reasonTitle: 'Why?',
        occurrences: 'Occurrences:',
        severity: 'Severity:',
        lastDetected: 'Last Detected:',
        vulnerabilityType: 'Type:',
        selectedFindings: '{{ selectedFindings }} Selected',
        viewFixPR: 'View Fix {{scmRequestTypeShort}}',
        viewRepository: 'View {{scmRepo}}',
        generatedByAIText: '✨AI',
        generatedByAITooltipText: 'This action was created using Jit generative AI.',
        premium: {
          upgradeToPremium: 'Upgrade to unlock',
        },
        actions: {
          ignoreFindingButton: 'Ignore finding',
          ignoreFindingsButton: 'Ignore findings',
          openPrButton: 'Create a Fix {{scmRequestTypeShort}}',
          openPrsButton: 'Create Fix {{scmRequestTypeShort}}s',
          downloadScriptButton: 'Download script',
          guidelines: 'Guidelines',
        },
        nonCodeDialog: {
          title: 'Suggested Fix',
          guidelines: 'Guidelines',
          reason: 'Reason',
          done: 'Done',
        },
        confirmationDialog: {
          title: 'Ignore',
          message: 'Are you sure you want to ignore this findings?',
          cancel: 'Cancel',
          confirm: 'Confirm',
        },
        findingsList: {
          openingPr: 'Creating the Fix {{scmRequestTypeShort}}',
        },
        ignoreDialog: {
          title: 'Mark as ignored',
          reasonDropDownTitle: 'Reason for ignoring',
          reasonPlaceholder: 'Select reason',
          descriptionPlaceholder: 'Add free text',
          descriptionTitle: 'Description',
          optionalNote: '(optional)',
          IgnoreByType: {
            title: 'Ignore all findings of this type',
            description: 'When enabled, Jit will automatically ignore all current and past findings of the type: {{findingType}}',
          },
          infoBanner: {
            title: '<b>Note!</b> The organization has enabled a policy to block ignored requests. Your request may be affected by this policy.',
          },
        },
        shareTooltip: 'Share action to Slack private message.',
      },
      missingGithubIntegration: 'GitHub integration required in order to activate security controls and see actions. In the Plan page, under Activation, click Integrate.',
      missingScmIntegrationSubtitleAction: 'activate security controls and see actions',
    },
    pipelineDetails: {
      header: {
        downloadArtifacts: 'Download artifacts',
      },
      errorPage: {
        title: 'This page is gone.',
        description: 'The page you’re looking for could not be found.',
        assetText: 'Not Found',
      },
      findingsTable: {
        columnHeaders: {
          name: 'Name',
          firstDetected: 'First Detected',
          severity: 'Severity',
          resolution: 'Resolution',
        },
        noData: {
          mainText: 'No findings found',
          subText: "This pipeline doesn't have findings",
        },
      },
      findingsDetails: {
        header: 'Finding Details',
        firstDetected: 'First Detected',
        planContext: 'Plan context',
        vulnerabilityType: 'Type',
        securityTool: 'Security Tool',
        assetType: 'Asset Type',
        findingContext: 'Finding Context',
        details: 'Details',
        learnMoreHeader: 'Learn more about this issue',
        fileName: 'File Name',
        ticketSectionHeader: 'Ticket management system',
      },
      zapSpecificDetails: {
        applicationName: 'Application Name',
        url: 'URL',
        paramUsedForAttack: 'Param used for the attack',
        path: 'Path',
        method: 'Method',
        cwe: 'CWE ID',
        tags: 'Tags',
        requestHeader: 'Request Header',
        responseHeader: 'Response Header',
        solution: 'Solution',
      },
      breadcrumbs: {
        allPipelines: 'All Pipelines',
      },
      metadata: {
        environment: 'Environment',
        pr: '{{scmRequestTypeShort}}',
        branch: 'Branch',
        initiator: 'Initiator',
        start: 'Start',
        duration: 'Duration',
        scope: 'Scope',
        trigger: 'Trigger',
        runner: 'Runner',
        deployment: 'Deployment',
        deploymentAction: 'Deployment action',
        runners: {
          github_actions: 'GitHub actions',
          ci: 'CI',
          jit: 'Jit cloud',
          sync: 'Sync',
        },
      },
      workflows: {
        header: 'Workflows',
        jobs: '{{jobsCount}} jobs',
        resources: '{{assetsCount}} resources',
      },
    },
    securityMapping: {
      title: 'Security Mapping',
      theLoop: {
        labels: {
          template: '{{segmentName}} Security Controls',
          leftSide: 'pre-production',
          rightSide: 'production',
        },
      },
      coverageScale: {
        labels: {
          noCoverage: 'No Coverage',
          greatCoverage: 'Great Coverage',
        },
      },
      coverageOffering: {
        labels: {
          coverage: 'Coverage',
          turnOn: 'Turn On Security Plans',
        },
        linkToPlan: {
          tooltip: 'Go to plan page',
        },
      },
    },
  },
  table: {
    noData: 'No {{entityName}} Available',
    filtersAppliedDoesNotHaveData: 'No {{entityName}} found',
    tryOtherFilters: 'Try other filters combination or',
    clearFiltersEmptyTableMessage: 'Clear Filters',
    missingGithubIntegration: {
      title: 'Integrate GitHub to start using Jit',
      subtitle: 'GitHub integration required in order to {{action}}. In the Plan page, under Activation, click Integrate.',
      buttonText: 'Go to Plan',
    },
    missingScmIntegration: {
      title: 'Integrate source code management tool to start using Jit',
      subtitle: 'Source code management integration required in order to {{action}}. In the Plan page, under Activation, click Integrate.',
      buttonText: 'Go to Plan',
    },
  },
  securityTools: {
    kics: 'KICS',
    airiam: 'AirIAM',
    'npm-audit': 'npm-audit',
    'npm audit': 'npm-audit',
    'control-mfa-aws': 'MFA AWS',
    'control-mfa-github': 'MFA GitHub',
    'aws-mfa': 'AWS MFA Checker',
    'github-mfa': 'GitHub MFA Checker',
    'osv-scanner': 'OSV-Scanner',
    zap: 'ZAP',
    'github mfa checker': 'GitHub MFA Checker',
  },
  supportedLanguages: {
    js: 'Javascript',
    ts: 'Typescript',
    php_deps: 'PHP',
    js_deps: 'Javascript',
    javascript: 'Javascript',
    typescript: 'Typescript',
    go_deps: 'Go',
    docker: 'Dockerfile',
    cloudformation: 'CloudFormation',
    aws_cdk_output: 'AWS CDK Output',
    serverless: 'Serverless Framework',
    aws: 'AWS',
    text: 'Textual file types',
    csharp: 'C#',
    cpp: 'C++',
  },
  readOnly: {
    tooltip: 'Requires Jit extended permissions',
  },
  common: {
    checkout: {
      subscriptionInfo: 'Your new subscription will be billed and begin immediately. You will be charged $600.00/yr per Developer until you cancel. You can add new Developers at any time, and you will be billed for additional Developers monthly, pro-rated from when the additional Developer was added to the end of your subscription period. Any added Developer will be included in your renewal subscription.',
      otherPaymentOptions: 'For other payment options,',
      contactUs: 'Contact us',
      termsAgreement: 'By clicking "{{buttonText}}" you agree to Jit\'s',
      terms: 'Terms',
    },
    pricingPlan: {
      extendFreeTrial: 'Contact us to extend free trial',
      upgradeNow: 'Upgrade Now',
      upgradeNowWithFeatureName: 'Upgrade now to keep access to {{featureName}}',
      upgradeToActivate: 'Upgrade to activate',
      premiumFeature: 'This is a premium feature.',
      trialDaysLeft_one: 'Free trial ends in {{count}} day.',
      trialDaysLeft_other: 'Free trial ends in {{count}} days.',
      navbar: {
        upgrade: 'UPGRADE',
        daysLeft: '{{daysLeft}}d left',
      },
      paywall: {
        cta: {
          upgrade: 'CONTINUE WITH GROWTH',
          contact: 'CONTACT US',
          downgrade: 'DOWNGRADE PLAN',
          current: 'ACTIVE',
        },
      },
      page: {
        title: 'Choose your plan',
        subtitle: 'Jit provides full coverage for app + cloud security,\n at a flat rate per developer.',
      },
    },
    statuses: {
      failure: 'Failure',
      running: 'Running',
      success: 'Success',
    },
  },
  notifications: {
    platformSelect: {
      placeholder: 'Platform',
    },
    msTeams: {
      teamSelect: {
        placeholder: 'Team',
      },
      channelSelect: {
        placeholder: 'Channel',
      },
    },
    slack: {
      channelSelect: {
        placeholder: 'Channel',
      },
    },
  },
};
