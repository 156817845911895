import { FC, useCallback, useMemo, useEffect, useState } from 'react';
import { JitSlidingPanel } from 'components/JitSlidingPanel/JitSlidingPanel';
import { useJittoContext } from 'context/JittoContext/JittoContext';
import ItemDrilldownContent from './ItemDrilldownContent';

/**
 * ItemDrilldownPanel component that renders a sliding panel with detailed information
 * about the selected item in the report. It manages the panel's state and dimensions.
 */
export const ItemDrilldownPanel: FC = () => {
  const { 
    currentDrilldownItemId, 
    setCurrentDrilldownItemId,
  } = useJittoContext();
  
  // Track window dimensions for responsive panel sizing
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  // Update window width on resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Handler to close the panel
  const handleClosePanel = useCallback(() => {
    setCurrentDrilldownItemId(null);
  }, [setCurrentDrilldownItemId]);

  // Calculate panel width - memoized to prevent recalculation on each render
  const panelWidth = useMemo(() => {
    const JITTO_CHAT_WIDTH = 320;
    const NAVIGATION_BAR_WIDTH = 85;
    const GAP = windowWidth * 0.1;
    
    // Ensure minimum width for small screens
    const calculatedWidth = windowWidth - JITTO_CHAT_WIDTH - NAVIGATION_BAR_WIDTH - GAP;
    const MIN_WIDTH = 400;
    const MAX_WIDTH = windowWidth * 0.6;
    
    return Math.min(Math.max(calculatedWidth, MIN_WIDTH), MAX_WIDTH);
  }, [windowWidth]);

  return (
    <JitSlidingPanel 
      isOpen={!!currentDrilldownItemId} 
      closePanel={handleClosePanel}
      width={`${panelWidth}px`}
    >
      <ItemDrilldownContent onClose={handleClosePanel} />
    </JitSlidingPanel>
  );
};

export default ItemDrilldownPanel; 