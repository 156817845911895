import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { IGitlabInstallationVendorAttributes } from '../../../../types/interfaces';

import { GitlabStartScanningSection } from './GitlabStartScanningSection/GitlabStartScanningSection';
import styles from './SCMIntegrationTask.module.scss';

import { GithubIcon, GitlabIcon } from 'assets';
import { IconButtonsGrid } from 'components/IconButtonsGrid/IconButtonsGrid';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext';
import { useTenantContext } from 'context/TenantContext';
import { constants } from 'globalConstants';
import { SCMVendors, Vendor } from 'types/enums';
import { useScmIntegration } from 'utils/hooks/useScmIntegration';

export const SCMIntegrationTask: FC = () => {
  const { currentScmVendor, isLoadingInstallation, githubInstallation, gitlabInstallation, initialOnboardingCompleted } = useTenantContext();
  const {
    ActiveSCMWizardComponent,
    setActiveSCMWizard,
    handleClose,
  } = useScmIntegration();
  const navigate = useNavigate();

  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const handleSendAnalyticsEvent = useCallback((vendor: SCMVendors) => {
    sendAnalyticsEvent({
      action: 'click-scm-integration',
      params: {
        vendor,
      },
    });
  }, [sendAnalyticsEvent]);

  const handleClick = useCallback((vendor: SCMVendors) => {
    if (currentScmVendor && initialOnboardingCompleted) return;

    setActiveSCMWizard(vendor);

    handleSendAnalyticsEvent(vendor);

    // Only navigate if we're not already on the QuickStart page
    // This prevents unnecessary navigation that could cause infinite loops
    if (!window.location.pathname.includes(constants.routes.QUICKSTART)) {
      navigate(`/${constants.routes.QUICKSTART}`);
    }

    // The reason for this navigate is that the default page will change as soon as SCM configuration is done, and we want to keep the user in this page.
    // The current routing solution doesn't allow a good solution to this, as the defaultRoute is determined by the first render and we currently don't
    // actually redirect the user to it, only render the component.
  }, [currentScmVendor, handleSendAnalyticsEvent, initialOnboardingCompleted, navigate, setActiveSCMWizard]);

  const scmIntegrations = useMemo(() => ([{
    key: Vendor.Github,
    icon: GithubIcon,
    onClick: () => handleClick(Vendor.Github),
    isSelected: currentScmVendor === Vendor.Github,
    isDisabled: !!((currentScmVendor && currentScmVendor !== Vendor.Github && initialOnboardingCompleted) || gitlabInstallation),
    text: 'vendor.github',
  }, {
    key: Vendor.GITLAB,
    icon: GitlabIcon,
    onClick: () => handleClick(Vendor.GITLAB),
    isSelected: !!(currentScmVendor === Vendor.GITLAB && (gitlabInstallation?.vendor_attributes as IGitlabInstallationVendorAttributes)?.repo_selection),
    isDisabled: !!((currentScmVendor && currentScmVendor !== Vendor.GITLAB && initialOnboardingCompleted) || githubInstallation),
    text: 'vendor.gitlab',
  }]), [currentScmVendor, githubInstallation, gitlabInstallation, handleClick, initialOnboardingCompleted]);

  const shouldShowStartScanning = useMemo(() => {
    if (!gitlabInstallation || !gitlabInstallation.vendor_attributes) return false;

    const vendorAttributes = gitlabInstallation.vendor_attributes as IGitlabInstallationVendorAttributes;
    return !!vendorAttributes.repo_selection;
  }, [gitlabInstallation]);

  return (
    <>
      <div className={styles.root}>
        <IconButtonsGrid
          isLoading={isLoadingInstallation}
          items={scmIntegrations}
        />

        {ActiveSCMWizardComponent && <ActiveSCMWizardComponent handleClose={handleClose} />}

      </div>

      {shouldShowStartScanning && (
        <GitlabStartScanningSection
          dataTestId='gitlab-start-scanning-section'
          initialOnboardingCompleted={initialOnboardingCompleted}
        />
      )}
    </>
  );
};
