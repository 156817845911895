export enum Vendor {
  Github = 'github',
  AWS = 'aws',
  AZURE = 'azure',
  GCP = 'gcp',
  PARAGON_GCP = 'custom.gcp',
  Domain = 'domain',
  SLACK = 'slack',
  Jira = 'jira',
  Shortcut = 'shortcut',
  Drata = 'drata',
  LINEAR = 'linear',
  MONDAY = 'monday.com',
  JIT = 'jit',
  SEMGREP = 'semgrep',
  GHSECURITY = 'github_security',
  ZAP_API = 'zapApi',
  ZAP_WEB = 'zapWeb',
  GITLAB = 'gitlab',
  BITBUCKET = 'bitbucket',
  AZURE_REPOS = 'azurerepos',
  GOSEC = 'gosec',
  BANDIT = 'bandit',
  CHECKMARX = 'checkmarx',
  GITLEAKS = 'gitleaks',
  TRUFFLEHOG = 'trufflehog',
  NPM_AUDIT = 'npmaudit',
  OSV_SCANNER = 'osvscanner',
  NANCY = 'nancy',
  DEPENDABOT = 'dependabot',
  KICS = 'kics',
  KUBESCAPE = 'kubescape',
  TRIVY = 'trivy',
  PRISMA = 'prisma',
  PROWLER = 'prowler',
  CHAIN_BENCH = 'chain_bench',
  ZAP = 'zap',
  NPM = 'npm',
  DOCKER = 'docker',
  MS_TEAMS = 'microsoftTeams',
  WIZ = 'wiz',
}

export const SCM_VENDORS = [Vendor.Github, Vendor.GITLAB];
export type SCMVendors = Vendor.Github | Vendor.GITLAB;
