import { AWSIntegrationCard } from '../components/integrationCards/AWSIntegrationCard/AWSIntegrationCard';
import { GithubIntegrationCard } from '../components/integrationCards/GithubIntegrationCard/GithubIntegrationCard';
import { SlackIntegrationCard } from '../components/integrationCards/SlackIntegrationCard/SlackIntegrationCard';

import { getVariantTagArray } from './variantTags';

import { AwsIcon, GithubIcon, SlackIcon } from 'assets';
import { IntegrationVendorType } from 'context/IntegrationsContext/templates/interfaces';

/**
 * Returns the appropriate custom card component for a given category
 * @param category The integration vendor type category
 * @param searchTerm Optional search term to filter cards
 * @param customFilteredCards Optional filtered custom cards to check against
 * @returns The custom card component or null if none matches
 */
export const getCustomCardForCategory = (
  category: IntegrationVendorType,
  searchTerm?: string,
  customFilteredCards?: Array<{ vendorType: string }>,
): JSX.Element | null => {
  // If we're searching and no custom cards match for this category, return null
  if (searchTerm && customFilteredCards
      && !customFilteredCards.some((card) => card.vendorType === category)) {
    return null;
  }

  // Otherwise, return the appropriate custom card component
  switch (category) {
    case IntegrationVendorType.sourceCodeManagement:
      return (
        <GithubIntegrationCard
          key='vendor.github'
          icon={GithubIcon}
          tags={getVariantTagArray(IntegrationVendorType.sourceCodeManagement)}
          title='vendor.github'
        />
      );
    case IntegrationVendorType.cloud:
      return (
        <AWSIntegrationCard
          key='vendor.aws'
          icon={AwsIcon}
          tags={getVariantTagArray(IntegrationVendorType.cloud)}
          title='vendor.aws'
        />
      );
    case IntegrationVendorType.collaborationPlatform:
      return (
        <SlackIntegrationCard
          key='vendor.slack'
          icon={SlackIcon}
          tags={getVariantTagArray(IntegrationVendorType.collaborationPlatform)}
          title='vendor.slack'
        />
      );
    default:
      return null;
  }
};
