import React from 'react';
import { JitButton } from 'components/JitButton/JitButton';
import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';

export interface ButtonProps {
  /**
   * Text content of the button
   */
  text: string;
  
  /**
   * Button style variant
   * Supports basic JitButton variants plus 'gradient'
   */
  variant: 'text' | 'contained' | 'outlined' | 'gradient';
  
  /**
   * Optional click handler
   */
  onClick?: () => void;
}

/**
 * Button component for displaying buttons with different styles
 * Abstracts JitButton and JitGradientButton with minimal props
 */
export const Button: React.FC<ButtonProps> = ({ 
  text, 
  variant,
  onClick
}) => {
  // For gradient variant, use JitGradientButton
  if (variant === 'gradient') {
    return (
      <JitGradientButton
        style={{
          padding: '6px 16px', // Add more horizontal padding
          color: '#FFFFFF'     // Explicitly set text color to white
        }}
        onClick={onClick}
      >
        {text}
      </JitGradientButton>
    );
  }
  
  // For all other variants, use JitButton
  return (
    <JitButton 
      variant={variant}
      onClick={onClick}
    >
      {text}
    </JitButton>
  );
};

export default Button; 