import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback } from 'react';

import {
  configurationComponentByItemSlug,
  configurationComponentByItemSlugWithProwler,
} from 'components/Configurations/configurationComponentByItemSlug';
import { configurationComponentBySecret } from 'components/Configurations/configurationComponentBySecret';
import { configurationComponentByVendor } from 'components/Configurations/configurationComponentByVendor';
import { ConfigurationsComponentDetails } from 'components/Configurations/interfaces/configurations';
import { IntegrationType } from 'context/IntegrationsContext/templates/interfaces';
import { useIntegrationsTemplate } from 'context/IntegrationsContext/templates/useIntegrationTemplates';
import { IControlDetails, IntegrationDetail, IPlanItemDetails } from 'types/interfaces';
import { useAssetTypeToVendor } from 'utils/constants/assetTypeConfigurations/assetTypeConfigurations';

type PlanItem = Pick<IPlanItemDetails, 'slug' | 'asset_types' | 'name' | 'is_manual'> & {
  controls?: IPlanItemDetails['controls'];
};

export const useGetConfigurationsComponentDetails = () => {
  const { uiDeploymentBasedScanProwler } = useFlags();
  const { getVendorByAssetType } = useAssetTypeToVendor();
  const { getTemplateByVendorKey } = useIntegrationsTemplate();

  const findIntegration = useCallback((controls: IControlDetails[] | undefined, isRequired: boolean, integrationType: string): IntegrationDetail | null => {
    if (!controls) return null;

    const allIntegrations = controls.flatMap((control) => control.integrations || []);

    return allIntegrations.find((integration) => {
      const doesRequireIntegration = integration.required === isRequired;
      const template = getTemplateByVendorKey(integration.name);
      const doesMatchIntegrationType = template?.integrationType === integrationType;
      return doesRequireIntegration && doesMatchIntegrationType;
    }) || null;
  }, [getTemplateByVendorKey]);

  return useCallback((planItem: PlanItem): ConfigurationsComponentDetails | null => {
    const configurationComponents = uiDeploymentBasedScanProwler ? configurationComponentByItemSlugWithProwler : configurationComponentByItemSlug;

    if (planItem.slug in configurationComponents) {
      return configurationComponents[planItem.slug];
    }

    if (planItem.is_manual) return null;

    const assetType = planItem.asset_types?.[0];
    const vendor = assetType ? getVendorByAssetType(assetType) : null;
    if (vendor && vendor in configurationComponentByVendor) {
      return configurationComponentByVendor[vendor];
    }

    // check if there is a required secret integration
    // if yes, ask the user to configure it
    const requiredIntegration = findIntegration(planItem.controls, true, IntegrationType.secret);
    if (requiredIntegration) {
      return configurationComponentBySecret(requiredIntegration.name, planItem.name);
    }

    // check if there is an optional secret integration
    // if yes, advise the user to configure it
    const optionalIntegration = findIntegration(planItem.controls, false, IntegrationType.secret);
    if (optionalIntegration) {
      return configurationComponentBySecret(optionalIntegration.name, planItem.name, true);
    }

    return null;
  }, [getVendorByAssetType, uiDeploymentBasedScanProwler, findIntegration]);
};
