import { useMemo, CSSProperties } from 'react';

import styles from '../components/QuickGuideSection/QuickGuideSection.module.scss';
import { SkipButton } from '../components/SkipButton/SkipButton';
import { UICheck, BottomText, Check } from '../types';

import { ArrowRight } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { MUIIcon } from 'components/MUIIcon/MUIIcon';
import colors from 'themes/colors.module.scss';

export const rightStyle = {
  justifyContent: 'start',
  marginLeft: 30,
};

const leftStyle = {
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'flex-start',
  display: 'flex',
};

const bottomTextStyle = {
  display: 'flex',
  flexDirection: 'column' as CSSProperties['flexDirection'],
  gap: '10px',
};

const color = colors.iris;

export const useQuickGuideSectionComponents = (
  isExpanded: boolean,
  title: string,
  isAllCompleted: boolean,
  allRequiredCompleted: boolean,
  hasNonRequiredPending: boolean,
  nextSectionDisplayName?: string,
  nonRequiredPendingChecks?: (UICheck & Check)[],
  bottomText?: BottomText,
  isAtLeastOneSkipped?: boolean,
) => {
  const leftComponent = useMemo(() => ({
    component: () => (
      <JitIcon
        color={color}
        data-testid='quick-guide-section-arrow'
        icon={ArrowRight}
        rotateDegrees={isExpanded ? 90 : 0}
      />
    ),
    style: leftStyle,
  }), [isExpanded]);

  const centerComponent = useMemo(() => ({
    component: () => (
      <JitText
        bold
        color={color}
        data-testid='quick-guide-section-title'
        text={title}
      />
    ),
  }), [title]);

  const completedComponent = useMemo(() => (
    <JitText bold color={colors.successGreen02} data-testid='quick-guide-section-completed' text='pages.quickStart.section.completed' />
  ), []);

  const skippedComponent = useMemo(() => (
    <JitText bold color={colors.gray} data-testid='quick-guide-section-skipped' text='pages.quickStart.section.skipped' />
  ), []);

  const rightComponent = useMemo(() => ({
    component: () => (!isExpanded && (isAllCompleted || isAtLeastOneSkipped) ? (
      <div className={styles.completedText}>
        {isAllCompleted ? completedComponent : skippedComponent}
      </div>
    ) : null),
    style: rightStyle,
  }), [isAllCompleted, isExpanded, isAtLeastOneSkipped, completedComponent, skippedComponent]);

  const rowComponents = useMemo(() => [{
    left: leftComponent,
    center: centerComponent,
    right: rightComponent,
  }], [leftComponent, centerComponent, rightComponent]);

  const bottomTextRowComponent = useMemo(() => {
    if (!bottomText) return [];
    const { icon, title: bottomTitle, description: bottomDescription } = bottomText;

    return [{
      left: {
        component: () => <MUIIcon color={colors.lightGray} iconName={icon} />,
        style: leftStyle,
      },
      center: {
        component: () => (
          <>
            <JitText bold muted text={bottomTitle} />

            <JitText muted text={bottomDescription} />
          </>
        ),
        style: bottomTextStyle,
      },
      right: {
        component: () => null,
      },
    }];
  }, [bottomText]);

  const skipButtonRowComponents = useMemo(() => {
    if (!isExpanded || !allRequiredCompleted || !hasNonRequiredPending || !nextSectionDisplayName) {
      return null;
    }

    return [{
      center: {
        component: () => (
          <SkipButton
            nextSectionDisplayName={nextSectionDisplayName}
            nonRequiredPendingChecks={nonRequiredPendingChecks}
          />
        ),
        style: {
          paddingTop: 20,
        },
      },
      left: {
        component: () => null,
      },
      right: {
        component: () => null,
      },
    }];
  }, [isExpanded, allRequiredCompleted, hasNonRequiredPending, nextSectionDisplayName, nonRequiredPendingChecks]);

  return {
    rowComponents,
    bottomTextRowComponent,
    skipButtonRowComponents,
    rightStyle,
  };
};
