import { Severity } from 'types/enums';
import { IFinding } from 'types/interfaces';

export const FINDINGS_LIMIT = 3;
export const SEMGREP_CONTROL_NAME = 'semgrep';

export const MOCK_CRITICAL_FINDING: IFinding = {
  id: 'mock-finding',
  name: 'Javascript.lang.security.detect-child-process',
  severity: Severity.Critical,
  vendor: 'github',
  date: new Date().toISOString(),
  assetType: 'repository',
  lastDetectedDateTime: new Date().toISOString(),
  lastDetectedDate: new Date().toISOString().split('T')[0],
  issueText: 'Security vulnerability detected',
  assetName: 'litalj/Serverless-Goat',
  assetId: 'pkg-123',
  priorityScore: 90,
  referencesUrls: [],
  securityControl: '',
  controlName: '',
  organization: '',
  repository: '',
  locationText: '',
  createdAt: '',
  fingerprint: '',
  resolution: '',
  planLayer: '',
  vulnerabilityType: '',
  ignoreRulesIds: [],
  ignored: false,
  testId: '',
  manualFactors: {
    added: [],
    removed: [],
  },
};
