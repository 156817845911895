import Box from '@mui/material/Box';
import React from 'react';

import { LoadingBar } from 'components/LoadingBar/LoadingBar';

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
  className?: string;
  style?: React.CSSProperties;
  onScroll?: () => void;
  isFetchingNextPage?: boolean;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, index, value, className, style, onScroll, isFetchingNextPage, ...other } = props;

  return (
    <div
      aria-labelledby={`simple-tab-${index}`}
      className={className}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      onScroll={onScroll}
      role='tabpanel'
      style={{
        ...style,
        display: value !== index ? 'none' : 'block',
        position: 'relative',
      }}
      {...other}
    >
      {value === index && (
        <Box sx={{ height: '100%',
          position: 'relative',
          paddingTop: 3,
          paddingBottom: 3 }}
        >
          {children}

          {isFetchingNextPage && (
            <LoadingBar
              sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: 16,
                width: '100%',
              }}
            />
          )}
        </Box>
      )}
    </div>
  );
};
