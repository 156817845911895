export enum WebSocketNotificationTopics {
  Asset = 'asset',
  Plan = 'plan',
  PlanItem = 'plan_item',
  Findings = 'findings',
  Installation = 'installation',
  Pipeline = 'pipeline',
  PrUpdates = 'pr_updates',
  ActionUpdates = 'action_updates',
  ActionFinding = 'action_finding_updates',
  Preferences = 'preferences',
  Deployment = 'deployment',
  Snapshot = 'snapshot',
  Secret = 'secret',
  Integration = 'integration',
  FileMetadata = 'file_metadata',
  GithubStatus = 'github_status',
  SbomStatusChange = 'sbom-status-change',
  SbomFirstReportGenerated = 'sbom-first-org-report-change',
  Team = 'team',
  TeamMembers = 'team-members',
  PlanItemAssetStatus = 'plan_item_asset_status',
  Check = 'check',
  AssetScanStats = 'asset-scan-stats',
  WorkflowStatusChange = 'workflow',
  WorkflowRunStatusChange = 'workflow-run',
  IgnoreRules = 'ignore-rules',
  Tickets = 'tickets',
}
