import { useState, useCallback, useMemo } from 'react';

import { Repository, SubOrganization } from 'types/interfaces/SCM/SCMMember';

export interface SelectableItem {
  isSelected: boolean;
}

export type SelectableAsset = (Repository | SubOrganization) & SelectableItem;

interface UseAssetSelectionProps {
  alreadySelectedSCMResources?: Repository[];
  alreadySelectedSubOrganizations?: SubOrganization[];
  scmResourcesResults?: Repository[];
  subOrganizationsResults?: SubOrganization[];
}

export const useAssetSelection = ({
  alreadySelectedSCMResources = [],
  alreadySelectedSubOrganizations = [],
  scmResourcesResults = [],
  subOrganizationsResults = [],
}: UseAssetSelectionProps) => {
  const initialItems = useMemo(() => [
    ...alreadySelectedSubOrganizations.map((org) => ({ ...org,
      isSelected: true })),
    ...alreadySelectedSCMResources.map((scmResource) => ({ ...scmResource,
      isSelected: true })),
  ], [alreadySelectedSCMResources, alreadySelectedSubOrganizations]);

  const [selectedItems, setSelectedItems] = useState<SelectableAsset[]>(initialItems);

  const subOrganizations = useMemo(
    () => subOrganizationsResults.map((org) => ({
      ...org,
      isSelected: selectedItems.some((selected) => selected.id === org.id),
    })),
    [subOrganizationsResults, selectedItems],
  );

  const scmResources = useMemo(
    () => scmResourcesResults.map((scmResource) => ({
      ...scmResource,
      isSelected: selectedItems.some((selected) => selected.id === scmResource.id),
    })),
    [scmResourcesResults, selectedItems],
  );

  const handleItemSelect = useCallback((itemId: string, checked: boolean, isSubOrg: boolean) => {
    const items: SelectableAsset[] = isSubOrg ? subOrganizations : scmResources;
    const itemToUpdate = items.find((item) => item.id === itemId);

    if (!itemToUpdate) return;

    if (checked) {
      setSelectedItems((prev) => [...prev, { ...itemToUpdate,
        isSelected: true }]);
    } else {
      setSelectedItems((prev) => prev.filter((item) => item.id !== itemId));
    }
  }, [subOrganizations, scmResources]);

  const handleSelectedItemUncheck = useCallback((itemId: string) => {
    setSelectedItems((prev) => prev.filter((item) => item.id !== itemId));
  }, []);

  const getSelectedAssets = useCallback(() => ({
    selectedSCMResources: selectedItems.filter((item) => !('fullPath' in item)) as Repository[],
    selectedSubOrganizations: selectedItems.filter((item) => 'fullPath' in item) as SubOrganization[],
  }), [selectedItems]);

  const handleSubOrganizationSelect = useCallback((id: string, checked: boolean) => handleItemSelect(id, checked, true), [handleItemSelect]);

  const handleRepositorySelect = useCallback((id: string, checked: boolean) => handleItemSelect(id, checked, false), [handleItemSelect]);

  return {
    subOrganizations,
    scmResources,
    selectedItems,
    handleSubOrganizationSelect,
    handleRepositorySelect,
    handleSelectedItemUncheck,
    getSelectedAssets,
  };
};
