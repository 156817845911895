import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { JitAutoCompleteForm } from 'components/JitAutoCompleteForm/components/JitAutocompleteForm';
import { useCommunicationPlatforms } from 'pages/TeamsPortal/GuidePage/hooks/useCommunicationPlatforms';

interface Props {
  selectedVendor?: string;
  onSelectVendor: (vendor?: string) => void;
}

export const PlatformSelect: FC<Props> = ({ selectedVendor, onSelectVendor }) => {
  // Combine all active collaboration platforms
  const [platformOptions, isLoadingOptions] = useCommunicationPlatforms();

  const { t } = useTranslation();

  return (
    <JitAutoCompleteForm
      disabled={platformOptions.length === 0}
      getOptionLabel={(option) => {
        const platform = platformOptions.find((p) => p.value === option);
        return platform?.label || '';
      }}
      isLoading={isLoadingOptions}
      isSingleValue
      options={platformOptions.map((p) => p.value)}
      placeHolder={t('notifications.platformSelect.placeholder')}
      selectedValue={selectedVendor}
      setSelectedValue={onSelectVendor}
    />
  );
};
