import { FC, useState, useEffect } from 'react';
import styles from './RiskAssessmentLoading.module.scss';
import { JitText } from 'components/JitText/JitText';

const RiskAssessmentLoading: FC = () => {
  const [progressPercentage, setProgressPercentage] = useState(0);
  const staticLoadingText = 'Generating your dashboard...';
  
  // Progress bar animation - completes in approximately 10 seconds
  useEffect(() => {
    // Create a more random, jumpy progress animation
    // Still completes in about 10 seconds
    
    // Keep track of the highest progress value to prevent decreases
    let highestProgress = 0;
    
    // Use random intervals between updates (faster and slower periods)
    const updateProgress = () => {
      setProgressPercentage(prev => {
        // Random increment between 1-5%
        const baseIncrement = Math.floor(Math.random() * 5) + 1;
        
        // Occasionally add a larger jump (10-15%)
        const jumpProbability = Math.random();
        const extraJump = jumpProbability < 0.2 ? Math.floor(Math.random() * 6) + 10 : 0;
        
        // Combine base increment with potential jump
        const totalIncrement = baseIncrement + extraJump;
        
        // Calculate new value and ensure it's never less than the previous highest
        const newValue = Math.min(prev + totalIncrement, 100);
        highestProgress = Math.max(highestProgress, newValue);
        
        // Return the highest progress achieved so far
        return highestProgress;
      });
    };
    
    // Start with an initial delay
    const initialDelay = setTimeout(() => {
      // Set initial progress between 5-15%
      const initialProgress = Math.floor(Math.random() * 11) + 5;
      highestProgress = initialProgress;
      setProgressPercentage(initialProgress);
      
      // Create a series of timeouts with varying intervals
      let totalElapsed = 0;
      const maxTime = 9000; // Leave 1 second for the final update
      const intervals: NodeJS.Timeout[] = [];
      
      while (totalElapsed < maxTime) {
        // Random interval between 200-800ms
        const interval = Math.floor(Math.random() * 601) + 200;
        totalElapsed += interval;
        
        if (totalElapsed <= maxTime) {
          const timeout = setTimeout(updateProgress, totalElapsed);
          intervals.push(timeout);
        }
      }
      
      // Final update to ensure we reach 100%
      const finalTimeout = setTimeout(() => {
        setProgressPercentage(100);
      }, 10000);
      
      intervals.push(finalTimeout);
      
      // Return cleanup function
      return () => {
        clearTimeout(initialDelay);
        intervals.forEach(timeout => clearTimeout(timeout));
      };
    }, 200);
    
    return () => clearTimeout(initialDelay);
  }, []);
  
  return (
    <div className={styles.loadingContainer} data-testid="riskAssessmentLoading">
      <div className={styles.loadingSpinner}>
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="40" cy="40" r="36" stroke="#e9d1f2" strokeWidth="6" />
          <circle cx="40" cy="40" r="36" stroke="#d9a8ee" strokeWidth="6" strokeLinecap="round" strokeDasharray="226" strokeDashoffset="50">
            <animateTransform 
              attributeName="transform" 
              type="rotate"
              from="0 40 40"
              to="360 40 40"
              dur="1.5s"
              repeatCount="indefinite"
            />
          </circle>
        </svg>
      </div>
      <div className={styles.loadingText}>
        <JitText bold size="l" text={staticLoadingText} />
      </div>
      <div className={styles.progressBarContainer}>
        <div className={styles.progressBar}>
          <div 
            className={styles.progressFill} 
            style={{ width: `${progressPercentage}%` }}
          />
        </div>
        <div className={styles.progressPercentage}>
          <JitText size="s" text={`${Math.round(progressPercentage)}%`} />
        </div>
      </div>
      {/* <div className={styles.loadingDescription}> */}
        {/* <JitText size="s" text="This comprehensive security assessment is analyzing your environment to identify vulnerabilities, compliance gaps, and potential risk factors." /> */}
      {/* </div> */}
    </div>
  );
};

export default RiskAssessmentLoading; 