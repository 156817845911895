import { isEmpty } from 'lodash';
import { Dispatch, SetStateAction, useCallback } from 'react';

import { IWebsocketNotification } from 'types/interfaces';
import { IIntegration } from 'types/interfaces/Integrations/IIntegration';
import { assertWebsocketNotificationEntity } from 'utils/functions/assertions/websocketNotificationEntity';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';

interface Props {
  setIntegrations: Dispatch<SetStateAction<IIntegration[] | undefined>>,
}

export const useHandleIntegrationsWebSocketNotification = ({ setIntegrations }: Props) => {
  const getIntegrationUniqueKey = useCallback((integration: IIntegration) => `${integration.tenantId}-${integration.vendor}-${integration.integrationId}`, []);
  const modifyIntegrations = useCallback((currentIntegrations: IIntegration[] | undefined, updated: IIntegration[], deleted: IIntegration[]) => {
    if (!currentIntegrations) {
      return isEmpty(updated) ? undefined : updated;
    }
    const integrationsByUniqueKey = currentIntegrations.reduce((acc, integration) => {
      acc[getIntegrationUniqueKey(integration)] = integration;
      return acc;
    }, {} as Record<string, IIntegration>);

    if (deleted) {
      deleted.forEach((integration) => {
        delete integrationsByUniqueKey[getIntegrationUniqueKey(integration)];
      });
    }

    if (updated) {
      updated.forEach((integration) => {
        const uniqueKey = getIntegrationUniqueKey(integration);
        const currentIntegration = integrationsByUniqueKey[uniqueKey];
        const newIntegrationFields = Object.fromEntries(Object.entries(integration).filter(([, value]) => value));
        integrationsByUniqueKey[uniqueKey] = { ...currentIntegration,
          ...newIntegrationFields };
      });
    }
    return Object.values(integrationsByUniqueKey);
  }, [getIntegrationUniqueKey]);

  const handleIntegrationsWebSocketNotification = useCallback((notification: IWebsocketNotification<IIntegration>) => {
    assertWebsocketNotificationEntity(notification);
    const { message: { updated, deleted } } = notification;
    setIntegrations((currentIntegrations) => modifyIntegrations(
      currentIntegrations,
      camelizeSnakeCaseKeys(updated) as IIntegration[],
      camelizeSnakeCaseKeys(deleted) as IIntegration[],
    ));
  }, [modifyIntegrations, setIntegrations]);
  return { handleIntegrationsWebSocketNotification };
};
