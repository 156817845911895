import React from 'react';

import { CUSTOM_INTEGRATION_LABELS } from './customIntegrations';
import { FilterableCard } from './filterIntegrations';

import { IntegrationTemplate } from 'context/IntegrationsContext/templates/interfaces';

interface CardWithComponent {
  label: string;
  component: React.ReactNode;
  id: string;
}

/**
 * Extracts vendor from a custom card component key
 */
const extractVendorFromKey = (component: React.ReactElement): string => {
  const key = component?.key || '';
  return String(key).replace('vendor.', '');
};

/**
 * Combines and sorts all non-coming-soon cards alphabetically
 */
export const combineAndSortCards = (
  customCards: React.ReactElement[],
  dynamicCards: FilterableCard[],
  regularCards: FilterableCard[],
  renderIntegrationCard: (template: IntegrationTemplate) => React.ReactNode,
): React.ReactElement[] => {
  // Convert custom cards to sortable format
  const customCardObjects: CardWithComponent[] = customCards.map((component) => {
    const vendor = extractVendorFromKey(component);
    return {
      label: CUSTOM_INTEGRATION_LABELS[vendor as keyof typeof CUSTOM_INTEGRATION_LABELS] || vendor,
      component,
      id: `custom-${vendor}`,
    };
  });

  // Convert dynamic cards to sortable format
  const dynamicCardObjects: CardWithComponent[] = dynamicCards
    .filter((card) => card.template)
    .map((card) => ({
      label: card.label,
      component: renderIntegrationCard(card.template!),
      id: `dynamic-${card.vendor}`,
    }));

  // Convert regular cards to sortable format
  const regularCardObjects: CardWithComponent[] = regularCards
    .filter((card) => card.template)
    .map((card) => ({
      label: card.label,
      component: renderIntegrationCard(card.template!),
      id: `regular-${card.vendor}`,
    }));

  // Combine all cards and sort by label
  const allCards = [...customCardObjects, ...dynamicCardObjects, ...regularCardObjects]
    .sort((a, b) => a.label.localeCompare(b.label));

  // Return the sorted components
  return allCards.map((card) => (
    <React.Fragment key={card.id || `card-${card.label}`}>
      {card.component}
    </React.Fragment>
  ));
};
