import React from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { JitBreadcrumbs, BreadcrumbItem } from 'components/JitBreadcrumbs/JitBreadcrumbs';
import { JitText } from 'components/JitText/JitText';
import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitButton } from 'components/JitButton/JitButton';
import { SlackIconIris } from 'assets';

interface SkillHeaderProps {
  title: string;
  description: string;
}

const SkillHeader: React.FC<SkillHeaderProps> = ({ 
  title = 'Risk Assessor',
  description = 'Continuously monitors systems and infrastructure to identify security vulnerabilities and compliance gaps.'
}) => {
  const navigate = useNavigate();

  const handleJittoClick = () => {
    navigate('/agents/jitto');
  };
  const handleSlackChat = () => {
  };

  const handleDirectChat = () => {
    navigate('/chat/new');
  };

  const breadcrumbItems: BreadcrumbItem[] = [
    {
      content: (
        <div onClick={handleJittoClick} style={{ cursor: 'pointer' }}>
          <JitText text='Sera' color="#9AA3F4" />
        </div>
      ),
      itemKey: 'sera'
    },
    {
      content: <JitText text={title} />,
      itemKey: 'skill'
    }
  ];

  return (
    <div style={{ marginBottom: '24px'}}>
      <Box sx={{ marginBottom: '12px' }}>
        <JitBreadcrumbs items={breadcrumbItems} />
      </Box>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Stack spacing={1}>
          <Typography 
          sx={{ 
            fontSize: '1.2rem',
            fontWeight: 600,
            color: 'text.primary',
          }}
        >
          {title}
        </Typography>
        
        <Typography 
          variant="body1" 
          sx={{ 
            fontSize: '0.9375rem', // 15px
            color: 'text.secondary',
            maxWidth: '700px',
          }}
        >
          {description}
        </Typography>
      </Stack>

      <Stack 
        direction="row" 
        spacing={4} 
        sx={{ mt: 2 }}
      >
        <JitButton
          variant="text"
          size="small"
          onClick={handleSlackChat}
          startIcon={<SlackIconIris />}
        >
          <JitText size='s' text={'Chat via Slack'} />
        </JitButton>
        <JitGradientButton
          onClick={handleDirectChat}
          width='140px'
        >
          <JitText size='s' text={'CHAT WITH SERA'} />
        </JitGradientButton>
      </Stack>
      </div>
    </div>
  );
};

export default SkillHeader; 