import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useState, useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';

import { EmptyResourcePage } from '../EmptyResourcePage/EmptyResourcePage';

import styles from './ResourcesPage.module.scss';

import { PageLayout } from 'components/Pages/PageLayout/PageLayout';
import { PageTitles } from 'components/PageTitles/PageTitles';
import { PremiumFeatureBanner } from 'components/PremiumFeatureBanner/PremiumFeatureBanner';
import { useAssetsContext } from 'context/AssetsContext';
import { useContextEngine } from 'context/GraphContext/hooks/useContextEngine';
import { RisksBreadcrumbs } from 'pages/Resources/components/RisksBreadcrumbs/RisksBreadcrumbs';
import { BranchScanButton } from 'pages/Resources/ResourcesPage/component/BranchScanButton/BranchScanButton';
import { FindingsCountCard } from 'pages/Resources/ResourcesPage/component/FindingsCountCard/FindingsCountCard';
import { ResourcesTable } from 'pages/Resources/ResourcesPage/component/ResourcesTable/ResourcesTable';
import { ResourcesTableFilters } from 'pages/Resources/ResourcesPage/component/ResourcesTable/ResourcesTableFilters/ResourcesTableFilters';
import { ScoreCard } from 'pages/Resources/ResourcesPage/component/ScoreCard/ScoreCard';
import { useAssetService } from 'services/AssetsService/useAssetService';
import { AssetType } from 'types/enums';
import { Queries } from 'types/enums/Queries';
import { IFilter } from 'types/interfaces';

export const ResourcesPage: FC = () => {
  const { isContextEngineEnabled, isEntitled } = useContextEngine();
  const { getAssetsStatistics } = useAssetService();
  const { assets } = useAssetsContext();
  const { uiShowManualBranchScan } = useFlags();

  const [filters, setFilters] = useState<IFilter[]>([]);

  const hasRepoAssets = useMemo(() => assets.some((asset) => asset.asset_type === AssetType.REPO), [assets]);

  const { data: statistics = [] } = useQuery(
    [Queries.AssetsStatistics, { filterParams: filters }],
    getAssetsStatistics,
    { keepPreviousData: true },
  );
  // Since we didn't pass any aggregation key, we expect that we would always have one item represents 'all'
  const resourcesStatistics = statistics?.[0];

  const clearFilters = useCallback(() => {
    setFilters((prevFilters) => prevFilters.map((filter) => ({
      ...filter,
      selectedValue: [],
    })));
  }, []);

  if (!isEntitled) {
    return <EmptyResourcePage />;
  }

  return (
    <PageLayout>
      <div className={styles.wrapper}>
        <RisksBreadcrumbs />

        <div className={styles.bannerWrapper}>
          <PremiumFeatureBanner featureName='Resources' isEntitled={isEntitled} />
        </div>

        <div className={styles.titleWrapper}>
          <PageTitles title='pages.risks.resources.resourcesPage.title' />
        </div>

        <div className={styles.header}>
          <ResourcesTableFilters filters={filters} setFilters={setFilters} />

          <div className={styles.cardsWrapper}>
            {hasRepoAssets && uiShowManualBranchScan && <BranchScanButton />}

            {isContextEngineEnabled && (
              <div className={styles.cardsWrapper} data-testid='statistic-cards'>
                <ScoreCard resourcesStatistics={resourcesStatistics} />

                <FindingsCountCard filters={filters} />
              </div>
            )}
          </div>
        </div>

        <div className={styles.body}>
          <ResourcesTable clearFilters={clearFilters} filters={filters} resourcesStatistics={resourcesStatistics} />
        </div>
      </div>
    </PageLayout>
  );
};
