import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './NoIntegrationsFound.module.scss';

import { JittyEmpty } from 'assets';
import { JitEmpty } from 'components/JitEmpty/JitEmpty';
import { ActionButton } from 'types/interfaces';
import { ActionButtonVariant } from 'types/interfaces/ActionButton/ActionButton';

interface NoIntegrationsFoundProps {
  searchTerm: string;
  onClearSearch: () => void;
  testId?: string;
}

export const NoIntegrationsFound: FC<NoIntegrationsFoundProps> = ({
  searchTerm,
  onClearSearch,
  testId = 'no-integrations-found',
}) => {
  const { t } = useTranslation();

  const actionButton: ActionButton = {
    label: t('pages.integrations.search.noResults.clearSearch'),
    handleClick: onClearSearch,
    variant: ActionButtonVariant.PRIMARY,
  };

  return (
    <div className={styles.root} data-testid={testId}>
      <JitEmpty
        actionButtons={[actionButton]}
        data-testid={`${testId}-empty`}
        description={t('pages.integrations.search.noResults.description', { searchTerm })}
        icon={JittyEmpty}
        title={t('pages.integrations.search.noResults.title')}
      />
    </div>
  );
};
