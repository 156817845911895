import styles from './ListSection.module.scss';

import { JitCheckbox } from 'components/JitCheckbox/JitCheckbox';
import { JitText } from 'components/JitText/JitText';
import { SelectableAsset } from 'components/SCM/ChooseSCMResources/components/common/CoverageSelectionDialog/hooks/useAssetSelection';
import colors from 'themes/colors.module.scss';
import { stopPropagation } from 'utils';

interface Props {
  items: SelectableAsset[];
  readOnly?: boolean;
  truncate?: boolean;
  onItemCheck?: (id: string, checked: boolean) => void;
  getItemName: (item: SelectableAsset) => string;
}

export const ListSection = ({ readOnly = false, truncate = false, items, onItemCheck, getItemName }: Props) => {
  const itemsToRender = truncate ? items.slice(0, 3) : items;
  return (
    <div className={styles.contentContainer}>
      {itemsToRender.map((item) => (
        <div key={item.id} className={styles.itemContainer} data-testid={`AssetSection - ${item.name}`}>
          <div className={styles.cell}>
            <JitCheckbox
              checked={item.isSelected}
              data-testid='asset-checkbox'
              disabled={false}
              id={item.id}
              onChange={(_, checked) => onItemCheck && onItemCheck(item.id, checked)}
              onClick={stopPropagation}
              style={{
                pointerEvents: readOnly ? 'none' : 'auto',
                cursor: readOnly ? 'default' : 'pointer',
              }}
            />

            <div className={styles.archiveLabelWrapper}>
              <JitText bold color={colors.lightGray} horizontalAlign='center' size='s' text={getItemName(item)} />
            </div>
          </div>
        </div>
      ))}

      {truncate && items.length > itemsToRender.length && <JitText style={{ marginLeft: '12px' }} text={`+${items.length - itemsToRender.length} more`} />}
    </div>
  );
};
