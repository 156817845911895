import { PlanItemSlugDemoCommits } from 'services/CiService/constants';

export enum PipelineDemoCommitType {
  VULNERABILITY = 'vulnerability',
  CLEAN = 'clean',
}

export interface IRunPipelineDemoRequest {
  plan_item_slug: PlanItemSlugDemoCommits;
  commit_type: PipelineDemoCommitType;
  vendor?: string;
  repo_name?: string;
}

export interface IOwner {
  login: string;
}

export interface IRepo {
  name: string;
  full_name: string;
  owner: IOwner;
}

export interface IBase {
  repo: IRepo;
}

export interface IRunPipelineDemoResponse {
  url: string;
  id: number;
  base: IBase;
  node_id: string;
  html_url: string;
  diff_url: string;
  patch_url: string;
  issue_url: string;
  commits_url: string;
  review_comments_url: string;
  review_comment_url: string;
  comments_url: string;
  statuses_url: string;
  number: number;
  state: string;
  locked: boolean;
  title: string;
  comments: number;
  review_comments: number;
  maintainer_can_modify: boolean;
  commits: number;
  additions: number;
  deletions: number;
  changed_files: number;
}

