import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { FC } from 'react';

import styles from './ExploreResultsEmpty.module.scss';

import { ExploreResultsEmptyJitta } from 'assets';
import { JitText } from 'components/JitText/JitText';

export const ExploreResultsEmpty: FC = () => (
  <div className={styles.container} data-testid='explore-results-empty'>
    <div>
      <ExploreResultsEmptyJitta data-testid='explore-results-empty-jitta' />

      <div className={styles.messageContainer}>
        <div className={styles.titleRow}>
          <CheckCircleIcon className={styles.checkIcon} data-testid='explore-results-empty-check-circle' />

          <JitText data-testid='explore-results-empty-title' size='xxl' text='pages.quickStart.checks.exploreResults.empty.title' />
        </div>

        <JitText data-testid='explore-results-empty-description' muted text='pages.quickStart.checks.exploreResults.empty.description' />
      </div>
    </div>
  </div>
);
