import { FC } from 'react';

interface Props {
  src: string;
}
export const TaskDialogImage: FC<Props> = ({ src }) => (
  <img
    alt='illustration'
    data-testid='dialog-illustration'
    src={src}
  />
);
