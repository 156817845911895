import { SecurityToolType } from '../components/SecurityToolsSidebar/SecurityToolsSidebar';
import { SecurityTool } from '../types/SecurityTool';

/**
 * Filter security tools based on search term and category
 * @param tool - The security tool to filter
 * @param searchTerm - The search term to filter by
 * @returns Whether the tool matches the search term
 */
export const filterTool = (tool: SecurityTool, searchTerm: string): boolean => {
  if (!searchTerm) {
    return true;
  }

  const searchTermLower = searchTerm.toLowerCase();

  // Check if the tool name, description, or category matches the search term
  return (
    tool.name.toLowerCase().includes(searchTermLower)
    || tool.description.toLowerCase().includes(searchTermLower)
    || tool.category.toLowerCase().includes(searchTermLower)
  );
};

/**
 * Get category counts for security tools
 * @param tools - The security tools to count
 * @param searchTerm - Optional search term to filter tools before counting
 * @returns Record of category counts
 */
export const getCategoryCounts = (
  tools: SecurityTool[],
  searchTerm?: string,
): Record<string, number> => {
  const counts: Record<string, number> = {
    [SecurityToolType.all]: 0,
    [SecurityToolType.compliance]: 0,
    [SecurityToolType.secretScanning]: 0,
    [SecurityToolType.sast]: 0,
    [SecurityToolType.dast]: 0,
    [SecurityToolType.container]: 0,
    [SecurityToolType.cloud]: 0,
    [SecurityToolType.monitoring]: 0,
  };

  // Filter tools by search term if provided
  const filteredTools = searchTerm
    ? tools.filter((tool) => filterTool(tool, searchTerm))
    : tools;

  // Count tools by category
  filteredTools.forEach((tool) => {
    counts[tool.category] = (counts[tool.category] || 0) + 1;
    counts[SecurityToolType.all] += 1;
  });

  return counts;
};
