import { paragon } from '@useparagon/connect';
import React, { useCallback, useMemo, useState } from 'react';

import styles from './IntegrateCollaborationPlatform.module.scss';

import { IconButtonItem, IconButtonsGrid } from 'components/IconButtonsGrid/IconButtonsGrid';
import { ITenantStageProps, SlackIntegrationDialog, SlackIntegrationDialogStage } from 'components/JitDialogs/SlackIntegrationDialog/SlackIntegrationDialog';
import { JitText } from 'components/JitText/JitText';
import { useIntegrationsContext } from 'context/IntegrationsContext';
import { useParagonAuth } from 'context/IntegrationsContext/hooks/useParagonAuth';
import { IntegrationVendorType } from 'context/IntegrationsContext/templates/interfaces';
import { useTenantContext } from 'context/TenantContext';
import { INTEGRATE_SLACK_CHECK_SLUG } from 'context/TenantContext/constant';
import { useUpdateCheckStatus } from 'context/TenantContext/tenantHooks/useUpdateCheckStatus';
import { constants } from 'globalConstants';
import { useFetchIntegrations } from 'pages/IntegrationsPage/hooks/useFetchIntegrations';
import { getSlackRedirectUri, isVendorInstalled, SlackRedirectParams } from 'pages/IntegrationsPage/utils';
import colors from 'themes/colors.module.scss';
import { Vendor } from 'types/enums';
import { IntegrationStatus } from 'types/interfaces/Integrations';
import { useDetailsByVendor } from 'utils/hooks/useDetailsByVendor';

const baseUrl = window.location.origin;
const quickstartPath = `${baseUrl}/${constants.routes.QUICKSTART}`;
const slackIntegDialogType: ITenantStageProps = { type: SlackIntegrationDialogStage.TENANT };

export const IntegrateCollaborationPlatform: React.FC = () => {
  const [showSlackConfigureDialog, setShowSlackConfigureDialog] = useState(false);

  const { integrations: fetchedIntegrations } = useFetchIntegrations();
  const { integrations } = useIntegrationsContext();
  const { installations } = useTenantContext();
  const { isLoading: isParagonAuthLoading } = useParagonAuth();
  const { getIconByVendor, getVendorDisplayName } = useDetailsByVendor();
  const { updateCheckStatusLocally } = useUpdateCheckStatus();

  // Check for Slack using the old way (tenant context)
  const isSlackConnected = useMemo(
    () => isVendorInstalled(Vendor.SLACK, installations),
    [installations],
  );

  // Check for any collaboration platform integration - paragon
  const collaborationPlatformIntegrations = useMemo(
    () => integrations?.filter(
      (integration) => integration.display?.categoryKey === IntegrationVendorType.collaborationPlatform
        && integration.status === IntegrationStatus.SUCCESS,
    ),
    [integrations],
  );

  const slackInstallRedirectUri = useMemo(() => {
    const slackIntegration = fetchedIntegrations.find(({ vendor }) => vendor === Vendor.SLACK);
    const slackRedirectParams: SlackRedirectParams = {
      httpRedirect: `${quickstartPath}`,
    };
    return getSlackRedirectUri(slackIntegration?.installation_path, slackRedirectParams);
  }, [fetchedIntegrations]);

  const handleClose = useCallback(() => setShowSlackConfigureDialog(false), []);

  const handleIntegrateSlackClick = useCallback(() => {
    window.open(slackInstallRedirectUri, '_self');
  }, [slackInstallRedirectUri]);

  const handleConfigureSlackClick = useCallback(() => {
    setShowSlackConfigureDialog(true);
  }, []);

  // Get active collaboration platform
  const activeCollaborationPlatforms = useMemo(() => {
    const activeIntegrations = collaborationPlatformIntegrations?.map((integration) => integration.vendor) ?? [];

    if (isSlackConnected) {
      activeIntegrations.push(Vendor.SLACK);
    }
    return new Set(activeIntegrations);
  }, [collaborationPlatformIntegrations, isSlackConnected]);

  // Slack integration button configuration
  const slackIntegration = useMemo((): IconButtonItem => ({
    key: Vendor.SLACK,
    icon: getIconByVendor(Vendor.SLACK, undefined, true),
    onClick: handleIntegrateSlackClick,
    isSelected: activeCollaborationPlatforms.has(Vendor.SLACK),
    isDisabled: !!(activeCollaborationPlatforms.size && !activeCollaborationPlatforms.has(Vendor.SLACK)),
    text: getVendorDisplayName(Vendor.SLACK),
    subText: activeCollaborationPlatforms.has(Vendor.SLACK) ? (
      <JitText
        color={colors.iris}
        onClick={handleConfigureSlackClick}
        text='pages.quickStart.checks.configureSlack.configureButton'
      />
    )
      : null,
  }), [getIconByVendor, handleIntegrateSlackClick, activeCollaborationPlatforms, getVendorDisplayName, handleConfigureSlackClick]);

  // Other collaboration platform integrations
  const otherCollaborationIntegrations = useMemo(
    () => integrations?.filter(
      (integration) => (integration.display?.categoryKey === IntegrationVendorType.collaborationPlatform)
      && integration.vendor !== Vendor.SLACK,
    ).map((integration): IconButtonItem => ({
      key: integration.vendor,
      icon: getIconByVendor(integration.vendor, undefined, true) ?? integration.display?.icon,
      onClick: () => paragon.connect(integration.vendor, {
        onInstall: () => updateCheckStatusLocally(INTEGRATE_SLACK_CHECK_SLUG),
      }),
      isSelected: activeCollaborationPlatforms.has(integration.vendor),
      isDisabled: !!(activeCollaborationPlatforms.size && !activeCollaborationPlatforms.has(integration.vendor)),
      text: integration.display?.name ?? getVendorDisplayName(integration.vendor),
      subText: activeCollaborationPlatforms.has(integration.vendor) ? (
        <JitText
          color={colors.iris}
          onClick={() => paragon.connect(integration.vendor, {})}
          text='pages.quickStart.checks.configureSlack.genericConfigure'
        />
      )
        : null,
    })) || [],
    [integrations, getIconByVendor, activeCollaborationPlatforms, getVendorDisplayName, updateCheckStatusLocally],
  );

  // Combine Slack and other collaboration platforms
  const platformItems = useMemo(
    () => [slackIntegration, ...otherCollaborationIntegrations],
    [slackIntegration, otherCollaborationIntegrations],
  );

  return (
    <>
      <SlackIntegrationDialog
        isOpen={showSlackConfigureDialog}
        onClose={handleClose}
        stage={slackIntegDialogType}
      />

      <div className={styles.root}>
        <IconButtonsGrid isLoading={isParagonAuthLoading} items={platformItems} />
      </div>

    </>
  );
};

// For backward compatibility
export const IntegrateSlackButton = IntegrateCollaborationPlatform;
