import React, { useState, useRef, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Avatar,
  Button,
  InputBase,
  useTheme,
  Chip
} from '@mui/material';
import { SeraIcon } from 'assets/agent-svg';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'context/AuthContext/AuthContext';
import { v4 as uuidv4 } from 'uuid';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';

const MOCK_JITTO_RESPONSE = `I've reviewed the risk assessment process file you shared. Based on my analysis, I understand your current workflow follows these key steps:

1. Initial data collection from multiple sources
2. Risk identification and categorization 
3. Impact assessment using your 5-tier scoring system
4. Probability assessment based on historical data
5. Risk prioritization matrix calculation
6. Mitigation strategy development
7. Quarterly review cycle

I can implement this process for you while providing additional insights and optimization recommendations. Would you like me to proceed with this framework or would you like to modify any aspects of the process?
`;
// Define Message interface
interface Message {
  id: string;
  content: string;
  source: 'sera' | 'user';
  timestamp?: string;
  file?: { name: string };
}

const PersonalizedTraining: React.FC = () => {
  const [inputValue, setInputValue] = useState('');
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isAttachmentActive, setIsAttachmentActive] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<{name: string} | null>(null);
  const [isJittoThinking, setIsJittoThinking] = useState(false);
  const [thinkingDots, setThinkingDots] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();
  const navigate = useNavigate();
  const { frontEggUser } = useAuthContext();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  
  // Animate thinking dots
  useEffect(() => {
    if (!isJittoThinking) return;
    
    const interval = setInterval(() => {
      setThinkingDots(prev => {
        if (prev === '') return '.';
        if (prev === '.') return '..';
        if (prev === '..') return '...';
        return '';
      });
    }, 400);
    
    return () => clearInterval(interval);
  }, [isJittoThinking]);
  
  // Format user name: if it's an email, extract the username part and capitalize first letter
  const formatUserName = (name?: string): string => {
    if (!name) return '';
    
    // Check if the name is an email
    if (name.includes('@')) {
      // Extract the part before @
      const username = name.split('@')[0];
      // Capitalize first letter
      return username.charAt(0).toUpperCase() + username.slice(1);
    }
    
    return name;
  };
  
  const formattedUserName = formatUserName(frontEggUser?.name);
  
  // Initial messages as state
  const [messages, setMessages] = useState<Message[]>([
    {
      id: '1',
      content: "Hi, I'm Sera. I'm here to help you create and customize your security reporting workflow. You can define how you want the data to be collected, processed, and presented in your reports.",
      source: 'sera',
      timestamp: '4:32 PM'
    }
  ]);

  // Auto-scroll to the bottom when messages change
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, isJittoThinking]);

  // Get current timestamp in format "h:mm A"
  const getCurrentTimestamp = (): string => {
    const now = new Date();
    return now.toLocaleTimeString(undefined, { 
      hour: 'numeric', 
      minute: '2-digit',
      hour12: true 
    }).replace(' ', ' '); // Ensure proper spacing
  };

  // Function to render a message bubble
  const renderMessage = (message: Message) => {
    const isAgent = message.source === 'sera';
    
    return (
      <Box 
        key={message.id}
        sx={{ 
          display: 'flex',
          alignItems: 'flex-start',
          mb: 2,
        }}
      >
        {/* Message content */}
        <Box 
          sx={{ 
            bgcolor: isAgent ? 'rgba(40, 44, 62, 0.8)' : 'rgba(30, 34, 52, 0.8)', 
            borderRadius: '12px',
            p: 2,
            maxWidth: '80%',
            minWidth: '200px',
            boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
            ml: 0,
            mr: 'auto',
          }}
        >
          {/* Header with avatar, name and timestamp */}
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            {isAgent ? (
              <Box 
                sx={{ 
                  width: 24,
                  height: 24,
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mr: 1.5,
                  flexShrink: 0,
                  overflow: 'hidden',
                }}
              >
                <SeraIcon />
              </Box>
            ) : (
              <Avatar 
                src={frontEggUser?.profilePictureUrl || ''}
                sx={{ 
                  width: 24, 
                  height: 24,
                  mr: 1.5
                }}
              >
                {formattedUserName.charAt(0)}
              </Avatar>
            )}
            
            <Typography
              variant="subtitle2"
              sx={{ 
                color: '#FFFFFF',
                fontSize: '0.875rem',
                fontWeight: 600,
                mr: 'auto'
              }}
            >
              {isAgent ? 'Sera' : formattedUserName}
            </Typography>
            
            <Typography
              variant="caption"
              sx={{ 
                color: 'rgba(255, 255, 255, 0.6)',
                fontSize: '0.75rem',
              }}
            >
              {message.timestamp}
            </Typography>
          </Box>
          
          {/* Message text */}
          <Typography
            variant="body2"
            sx={{ 
              color: '#FFFFFF',
              fontSize: '0.875rem',
              lineHeight: 1.5,
              fontWeight: 400,
            }}
          >
            {message.content}
          </Typography>
          
          {/* File tag at the bottom of the message */}
          {message.file && (
            <Box sx={{ mt: 1.5 }}>
              <Chip
                label={truncateFileName(message.file.name)}
                size="small"
                sx={{
                  backgroundColor: 'rgba(59, 130, 246, 0.15)',
                  color: '#FFFFFF',
                  height: '24px',
                  borderRadius: '4px',
                  fontSize: '0.75rem',
                  maxWidth: '100%',
                  '& .MuiChip-label': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }
                }}
                icon={<AttachFileIcon fontSize="small" sx={{ color: 'rgba(255, 255, 255, 0.7)', ml: 0.5 }} />}
              />
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  // Render the "Jitto is thinking" message with animated dots
  const renderThinkingMessage = () => {
    if (!isJittoThinking) return null;
    
    return (
      <Box 
        sx={{ 
          display: 'flex',
          alignItems: 'flex-start',
          mb: 2,
        }}
      >
        {/* Message content */}
        <Box 
          sx={{ 
            bgcolor: 'rgba(40, 44, 62, 0.8)', 
            borderRadius: '12px',
            p: 2,
            maxWidth: '80%',
            minWidth: '150px',
            boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
            ml: 0,
            mr: 'auto',
          }}
        >
          {/* Header with avatar and name */}
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Box 
              sx={{ 
                width: 24,
                height: 24,
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mr: 1.5,
                flexShrink: 0,
                overflow: 'hidden',
              }}
            >
              <SeraIcon />
            </Box>
            
            <Typography
              variant="subtitle2"
              sx={{ 
                color: '#FFFFFF',
                fontSize: '0.875rem',
                fontWeight: 600,
              }}
            >
              Sera
            </Typography>
          </Box>
          
          {/* Thinking text with animated dots */}
          <Typography
            variant="body2"
            sx={{ 
              color: '#FFFFFF',
              fontSize: '0.875rem',
              lineHeight: 1.5,
              fontWeight: 400,
              opacity: 0.8,
            }}
          >
            Thinking{thinkingDots}
          </Typography>
        </Box>
      </Box>
    );
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };

  const handleAttachmentMouseEnter = () => {
    setIsAttachmentActive(true);
  };

  const handleAttachmentMouseLeave = () => {
    setIsAttachmentActive(false);
  };

  const handleAttachmentClick = () => {
    // Trigger the hidden file input
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      // Store the file name in state
      setUploadedFile({ name: files[0].name });
      
      // Reset the input so the same file can be selected again
      e.target.value = '';
    }
  };

  const handleRemoveFile = () => {
    setUploadedFile(null);
  };

  // Truncate long filenames and add ellipsis
  const truncateFileName = (fileName: string, maxLength: number = 20): string => {
    if (fileName.length <= maxLength) return fileName;
    
    const extension = fileName.split('.').pop() || '';
    const nameWithoutExtension = fileName.substring(0, fileName.lastIndexOf('.'));
    
    const truncatedName = nameWithoutExtension.substring(0, maxLength - extension.length - 3);
    return `${truncatedName}...${extension}`;
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    // Only submit on Enter without Shift key
    if (e.key === 'Enter' && !e.shiftKey && inputValue.trim() !== '') {
      e.preventDefault(); // Prevent default to avoid form submission
      handleSendMessage();
    }
  };

  // Focus the input when clicking on the form container
  const handleFormContainerClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleSendMessage = () => {
    if (inputValue.trim() === '') return;
    
    // Create a new user message
    const userMessage: Message = {
      id: uuidv4(),
      content: inputValue.trim(),
      source: 'user',
      timestamp: getCurrentTimestamp(),
      file: uploadedFile || undefined // Fix type error by using undefined instead of null
    };
    
    // Add user message to the state
    setMessages(prevMessages => [...prevMessages, userMessage]);
    
    // Clear input and file state
    setInputValue('');
    setUploadedFile(null);
    
    // Set Jitto to thinking
    setTimeout(() => {
      setIsJittoThinking(true);
    }, 500);
    
    // Add Jitto response after 2 seconds
    setTimeout(() => {
      const jittoMessage: Message = {
        id: uuidv4(),
        content: MOCK_JITTO_RESPONSE,
        source: 'sera',
        timestamp: getCurrentTimestamp()
      };
      
      setMessages(prevMessages => [...prevMessages, jittoMessage]);
      
      // Stop thinking animation
      setIsJittoThinking(false);
    }, 7000);
  };
  
  // Prevent form submission which causes page reload
  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleSendMessage();
  };

  return (
    <Box sx={{ mt: 0 }}> {/* Removed top margin */}
      <Paper
        elevation={0}
        sx={{
          borderRadius: 0,
          bgcolor: '#121926', // Updated background color
          p: 0, // No padding
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '80vh',
          overflow: 'auto',
        }}
      >
        {/* Header */}
        <Box
          sx={{
            p: 2,
            borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
            textAlign: 'center'
          }}
        >
          <Typography 
            variant="h6" 
            sx={{ 
              fontSize: '1rem',
              fontWeight: 500,
              color: '#FFFFFF',
            }}
          >
            Expertise Context
          </Typography>
        </Box>
        
        {/* Timestamp header */}
        <Box
          sx={{
            py: 1.5,
            textAlign: 'center'
          }}
        >
          <Typography 
            variant="caption" 
            sx={{ 
              fontSize: '0.75rem',
              color: 'rgba(255, 255, 255, 0.6)',
            }}
          >
            Today 4:32 PM
          </Typography>
        </Box>
        
        {/* Message list */}
        <Box sx={{ flexGrow: 1, overflow: 'auto', px: 2 }}>
          {messages.map(renderMessage)}
          {renderThinkingMessage()}
          <div ref={messagesEndRef} />
        </Box>
        
        {/* Input field with send button */}
        <Box 
          component="form"
          onSubmit={handleFormSubmit}
          onClick={handleFormContainerClick}
          sx={{ 
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            position: 'relative',
            borderTop: '1px solid rgba(255, 255, 255, 0.08)'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Box sx={{ position: 'relative', width: '100%' }}>
              <InputBase
                placeholder="How can I help you?"
                fullWidth
                multiline
                minRows={1}
                maxRows={4}
                value={inputValue}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                onKeyPress={handleKeyPress}
                inputRef={inputRef}
                sx={{
                  p: '12px 16px',
                  paddingRight: uploadedFile ? '80px' : '50px', // Extra padding when file chip is visible
                  fontSize: '0.875rem',
                  color: '#FFFFFF',
                  bgcolor: '#1F2236',
                  borderRadius: '6px',
                  '& .MuiInputBase-input': {
                    '&::placeholder': {
                      color: 'rgba(255, 255, 255, 0.5)',
                      opacity: 0.7,
                    },
                    overflow: 'auto',
                    maxHeight: '100px',
                  },
                }}
              />
              
              {/* File tag on the right side */}
              {uploadedFile && (
                <Box
                  sx={{
                    position: 'absolute',
                    right: '50px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 1,
                  }}
                >
                  <Chip
                    label={truncateFileName(uploadedFile.name)}
                    size="small"
                    onDelete={handleRemoveFile}
                    deleteIcon={<CloseIcon fontSize="small" />}
                    sx={{
                      backgroundColor: 'rgba(59, 130, 246, 0.15)',
                      color: '#FFFFFF',
                      '& .MuiChip-deleteIcon': {
                        color: 'rgba(255, 255, 255, 0.7)',
                        '&:hover': {
                          color: '#FFFFFF',
                        },
                      },
                      height: '24px',
                      borderRadius: '4px',
                      fontSize: '0.75rem',
                      maxWidth: '120px',
                    }}
                  />
                </Box>
              )}
              
              <Box
                onClick={handleAttachmentClick}
                sx={{
                  position: 'absolute',
                  right: '12px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'rgba(255, 255, 255, 0.6)',
                  p: '6px',
                  width: '28px',
                  height: '28px',
                  borderRadius: '4px',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    color: 'rgba(255, 255, 255, 0.9)',
                  },
                }}
              >
                <AttachFileIcon fontSize="small" />
              </Box>
              
              {/* Hidden file input */}
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
              />
            </Box>
            
            <button
              type="button"
              onClick={handleSendMessage}
              aria-label="Send message"
              disabled={inputValue.trim() === '' || isJittoThinking}
              style={{
                backgroundColor: '#3B82F6',
                border: 'none',
                cursor: 'pointer',
                color: 'white',
                width: '40px',
                height: '40px',
                minWidth: '40px',
                borderRadius: '6px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 0,
                transition: 'background-color 0.2s ease',
                marginLeft: '8px',
                opacity: (inputValue.trim() === '' || isJittoThinking) ? 0.5 : 1,
              }}
            >
              <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22 2L15 22L11 13L2 9L22 2Z" fill="white"/>
              </svg>
            </button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default PersonalizedTraining; 