
import { FC, ReactNode } from 'react';

import styles from './TaskDialogTask.module.scss';

import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  title: string;
  description: string;
  buttonLabel?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  handleClick: () => void;
  children?: ReactNode
  testId?: string;
}

export const TaskDialogTask: FC<Props> = ({
  children, title, description, buttonLabel, isDisabled, isLoading, handleClick, testId,
}) => (
  <div className={styles.task}>
    <div>
      <JitText bold size='l' text={title} />

      <JitText color={colors.lightGray} size='m' text={description} />
    </div>

    {children}

    <JitGradientButton
      data-testid={testId}
      disabled={isDisabled || isLoading}
      isLoading={isLoading}
      onClick={handleClick}
      showChildrenWhileLoading
    >
      <div className={styles.button}>
        <JitText text={buttonLabel} />
      </div>
    </JitGradientButton>
  </div>
);
