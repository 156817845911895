import { useMemo } from 'react';

import { calcPriorityScoreColor } from './utils';

import colors from 'themes/colors.module.scss';

interface Props {
  priorityScore: number;
  disabled?: boolean;
  dynamicColor?: boolean;
}
export const useScoreColor = ({ priorityScore, disabled, dynamicColor }: Props) => useMemo(() => {
  let color = dynamicColor ? calcPriorityScoreColor(priorityScore) : colors.criticalPriority;
  const isNegative = priorityScore < 0;
  if (isNegative) {
    color = colors.lightGray;
  }
  if (disabled) {
    color = colors.gray;
  }

  return color;
}, [priorityScore, disabled, dynamicColor]);
