import React from 'react';
import { Typography, Box, Divider } from '@mui/material';
import { Button } from './Button';

export interface BreakdownListItem {
  /**
   * The title of the item
   */
  title: string;
  
  /**
   * The description or subtitle of the item
   */
  description: string;
  
  /**
   * The count or number value
   */
  number: number;
}

export interface BreakdownListProps {
  /**
   * The title for the breakdown list
   */
  title: string;
  
  /**
   * Array of items to display in the list
   */
  rows: BreakdownListItem[];
}

/**
 * BreakdownList component displays a list of items with titles, descriptions, counts, and optional action buttons
 */
export const BreakdownList: React.FC<BreakdownListProps> = ({ title, rows }) => {
  return (
    <Box
      sx={{
        backgroundColor: '#212839',
        borderRadius: '8px',
        padding: '35px 25px',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h5" component="h2" sx={{ 
        mb: '20px', 
        color: 'white',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '18px',
        letterSpacing: 0
      }}>
        {title}
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, flexGrow: 1 }}>
        {rows.map((row, index) => (
          <React.Fragment key={index}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                py: 1,
              }}
            >
              <Box sx={{ flex: 1 }}>
                <Typography variant="subtitle1" sx={{ 
                  color: 'white', 
                  fontWeight: 500,
                  fontSize: '14px',
                  mb: 0.5 
                }}>
                  {row.title}
                </Typography>
                <Typography variant="body2" sx={{                 
                  fontWeight: 500,
                  fontSize: '14px',
                  color: '#AEB9E1'
                   }}>
                  {row.description}
                </Typography>
              </Box>
              
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                <Typography variant="h4" sx={{ color: 'white', fontWeight: 500, fontSize: '18px' }}>
                  {row.number}
                </Typography>
                
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Button 
                          text='→'
                          variant='text'
                      />
                    </Box>
                  </Box>
              </Box>
            </Box>
            
            {index < rows.length - 1 && (
              <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.1)' }} />
            )}
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default BreakdownList; 