export const allSCMResourcesCoverageType = 'all' as const;
export const selectedSCMResourcesCoverageType = 'selected' as const;

export interface FetchSCMResourcesParams {
  search?: string;
  page?: number;
  per_page?: number;
  suggested_repos?: boolean;
}

export interface UpdateInstallationSCMResources {
  scmResource_coverage_type: typeof allSCMResourcesCoverageType | typeof selectedSCMResourcesCoverageType;
  scmResources?: {
    scmResource_id: string;
    scmResource_name: string;
  }[];
  subOrganizations?: string[];
}

export interface FetchSubOrganizationsParams {
  page: number;
  per_page: number;
  search?: string;
  min_access_level?: number;
}

export interface Repository {
  id: string;
  name: string;
  pathWithNamespace: string;
}

export interface Organization {
  id: string;
  name: string;
  path: string;
  fullPath: string;
}

export interface SubOrganization {
  id: string;
  name: string;
  path: string;
  fullPath: string;
}

export interface FetchMembersParams {
  user_name?: string;
  limit: number;
  after?: string;
}
