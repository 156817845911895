import { Dispatch, SetStateAction, useCallback } from 'react';

import { IAsset, IWebsocketNotification } from 'types/interfaces';
import { assertWebsocketNotificationEntity } from 'utils/functions/assertions/websocketNotificationEntity';

interface Props {
  setAssets: Dispatch<SetStateAction<IAsset[]>>,
}

export const useHandleAssetsWebSocketNotification = ({ setAssets }: Props) => {
  const getAssetsAfterCreated = (curAssets: IAsset[], created: IAsset[]) => {
    const curAssetsIds = new Set(curAssets.map((asset) => asset.asset_id));
    return [...curAssets, ...created.filter((asset) => !curAssetsIds.has(asset.asset_id))];
  };
  const getAssetsAfterUpdated = (newAssets: IAsset[], updated: IAsset[]) => newAssets.map((asset) => {
    const foundAsset = updated.find((updatedAsset) => updatedAsset.asset_id === asset.asset_id);
    if (foundAsset) {
      return foundAsset;
    }
    return asset;
  });

  const getAssetsAfterDeleted = (newAssets: IAsset[], deleted: IAsset[]) => newAssets.filter((asset) => !deleted.find((deletedAsset) => deletedAsset.asset_id === asset.asset_id));

  const handleAssetsWebSocketNotification = useCallback((notification: IWebsocketNotification<IAsset>) => {
    assertWebsocketNotificationEntity(notification);
    const { message: { created, updated, deleted } } = notification;

    setAssets((curAssets) => {
      let newAssets = [...curAssets];
      newAssets = created ? getAssetsAfterCreated(newAssets, created) : newAssets;
      newAssets = updated ? getAssetsAfterUpdated(newAssets, updated) : newAssets;
      newAssets = deleted ? getAssetsAfterDeleted(newAssets, deleted) : newAssets;
      return newAssets;
    });
  }, [setAssets]);

  return { handleAssetsWebSocketNotification };
};
