import { useQuery, useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';

import { useAssetService } from 'services/AssetsService/useAssetService';
import { Queries } from 'types/enums/Queries';
import { IAsset, AssetSortBy } from 'types/interfaces';
import { IPaginatedResponse } from 'types/interfaces/IPaginatedResponse/IPaginatedResponse';

export interface UseInfiniteAssetsParams {
  vendor?: string;
  asset_type?: string;
  search?: string;
  limit?: number;
  team?: string;
  is_covered?: boolean;
  sort_by?: AssetSortBy;
  sort_order?: string;
}

export const useAssetsQueries = () => {
  const { getInitialScanStats, getAssetsInfinite } = useAssetService();

  const useInitialScanStats = (opts?: { enabled?: boolean }) => useQuery({
    queryFn: getInitialScanStats,
    queryKey: [Queries.InitialScanStats],
    ...opts,
  });

  const useInfiniteAssets = (
    params: UseInfiniteAssetsParams,
    options?: Partial<UseInfiniteQueryOptions<IPaginatedResponse<IAsset>, Error>>,
  ) => useInfiniteQuery<IPaginatedResponse<IAsset>, Error>(
    options?.queryKey || [Queries.Assets, params],
    ({ pageParam }: { pageParam?: string }) => getAssetsInfinite({
      ...params,
      after: pageParam,
    }),
    {
      getNextPageParam: (lastPage: IPaginatedResponse<IAsset>) => lastPage.metadata.after,
      ...options,
    },
  );

  return {
    useInitialScanStats,
    useInfiniteAssets,
  };
};
