import { useFlags } from 'launchdarkly-react-client-sdk';
import { SyntheticEvent, useState, useMemo, useEffect } from 'react';
import { useQueryClient, useMutation } from 'react-query';

import { useAssetsManagementTabDetailsByTab } from '../useAssetsManagementTabDetailsByTab/useAssetsManagementTabDetailsByTab';

import { TABS } from 'components/JitDialogs/AssetsManagementDialog/constants';
import { useAssetsContext } from 'context/AssetsContext/AssetsContext';
import { useDialogsContext } from 'context/DialogsContext/DialogsContext';
import { useAssetService } from 'services/AssetsService/useAssetService';
import { useAssetsQueries } from 'services/AssetsService/useAssetsQueries';
import { logError } from 'services/logger/logger';
import { Dialogs, SortDirections } from 'types/enums';
import { Queries } from 'types/enums/Queries';
import { IAsset, IChangeInputEvent, AssetSortBy } from 'types/interfaces';
import { useDebounce } from 'utils/hooks/useDebounce';

const ASSETS_PER_PAGE = 50;

interface PageMetadata {
  count: number;
  limit: number;
  after?: string;
}

interface QueryData {
  pages: {
    data: IAsset[];
    metadata: PageMetadata;
  }[];
}

export const useAssetsDialogManagement = (
  defaultTab: TABS,
  onClose?: () => void,
  tagAllAsCheckedForVendors?: string[], // Vendors for which all assets should be checked (checkbox ticked) at opening by default
) => {
  const { centralizedRepo } = useAssetsContext();
  const { hideDialog } = useDialogsContext();
  const { updateMultipleAssets } = useAssetService();
  const { useInfiniteAssets } = useAssetsQueries();
  const queryClient = useQueryClient();
  const { useGcpIntegrationAsCode } = useFlags();

  const [searchInput, setSearchInput] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<TABS>(defaultTab);
  const [modifiedAssets, setModifiedAssets] = useState<Record<string, IAsset>>({});

  const tabDetails = useAssetsManagementTabDetailsByTab(selectedTab);
  const { shouldAppearInCurrentSelectedTab, customQueryParams } = tabDetails;

  const { debounceValue: debouncedSearch } = useDebounce(searchInput, 500);

  const queryKey = useMemo(
    () => [Queries.Assets, selectedTab, debouncedSearch, customQueryParams],
    [selectedTab, debouncedSearch, customQueryParams],
  );

  const {
    data: infiniteAssetsData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    refetch,
  } = useInfiniteAssets({
    vendor: selectedTab === TABS.GCP ? (useGcpIntegrationAsCode ? selectedTab : 'custom.gcp') : selectedTab,
    search: debouncedSearch,
    limit: ASSETS_PER_PAGE,
    is_covered: undefined,
    sort_by: AssetSortBy.AssetName,
    sort_order: SortDirections.ASC,
    ...customQueryParams,
  }, {
    queryKey,
    staleTime: 5 * 60 * 1000,
  });

  useEffect(() => {
    setSearchInput('');
  }, [selectedTab]);

  const fetchedAssets = useMemo(() => {
    if (!infiniteAssetsData) return [];
    return infiniteAssetsData.pages.flatMap((page) => page.data);
  }, [infiniteAssetsData]);

  const filteredAssets = useMemo(
    () => fetchedAssets.filter(shouldAppearInCurrentSelectedTab),
    [fetchedAssets, shouldAppearInCurrentSelectedTab],
  );

  useEffect(() => {
    if (tagAllAsCheckedForVendors?.includes(selectedTab) && filteredAssets.length > 0) {
      const initialModifiedAssets = { ...modifiedAssets };
      filteredAssets.forEach((asset) => {
        const isCentralizedRepo = asset.asset_id === centralizedRepo?.asset_id;
        if (!isCentralizedRepo) {
          initialModifiedAssets[asset.asset_id] = {
            ...asset,
            is_covered: true,
          };
        }
      });
      setModifiedAssets(initialModifiedAssets);
    }
  }, [selectedTab, filteredAssets, tagAllAsCheckedForVendors, centralizedRepo, modifiedAssets]);

  const displayedAssets = useMemo(() => filteredAssets.map((asset) => (
    modifiedAssets[asset.asset_id] || asset
  )), [filteredAssets, modifiedAssets]);

  const handleTabChange = (event: SyntheticEvent, newValue: TABS) => {
    setSelectedTab(newValue);
  };

  const checkAsset = (assetId: string, checked: boolean) => {
    const asset = filteredAssets.find((a) => a.asset_id === assetId);
    if (!asset) return;

    setModifiedAssets((prev) => ({
      ...prev,
      [assetId]: {
        ...asset,
        is_covered: checked,
      },
    }));
  };

  const checkMultipleAssetsByOwner = (owner: string, checked: boolean) => {
    const updatedAssets = { ...modifiedAssets };

    filteredAssets.forEach((asset) => {
      const isCentralizedRepo = asset.asset_id === centralizedRepo?.asset_id;
      if (owner === asset.owner && !isCentralizedRepo) {
        updatedAssets[asset.asset_id] = {
          ...asset,
          is_covered: checked,
        };
      }
    });

    setModifiedAssets(updatedAssets);
  };

  const { mutateAsync: syncUpdatedAssets, isLoading: isSaving } = useMutation(
    async () => {
      const assetsToSync = Object.values(modifiedAssets);
      if (!assetsToSync.length) return null;

      const res = await updateMultipleAssets(assetsToSync);
      return res?.data;
    },
    {
      onSuccess: (data) => {
        if (!data) return;

        queryClient.setQueryData<QueryData | undefined>(queryKey, (oldData) => {
          if (!oldData) {
            return {
              pages: [{
                data,
                metadata: {
                  count: data.length,
                  limit: ASSETS_PER_PAGE,
                },
              }],
            };
          }

          const updatedAssetsMap = data.reduce((acc: Record<string, IAsset>, asset: IAsset) => {
            acc[asset.asset_id] = asset;
            return acc;
          }, {});

          return {
            ...oldData,
            pages: oldData.pages.map((page) => ({
              ...page,
              data: page.data.map((asset) => updatedAssetsMap[asset.asset_id] || asset),
            })),
          };
        });
        setModifiedAssets({});
      },
    },
  );

  const handleToggleSelectAllAssets = (e: IChangeInputEvent, checked: boolean) => {
    const updatedAssets = { ...modifiedAssets };

    filteredAssets.forEach((asset) => {
      const isCentralizedRepo = asset.asset_id === centralizedRepo?.asset_id;
      if (!isCentralizedRepo) {
        updatedAssets[asset.asset_id] = {
          ...asset,
          is_covered: checked,
        };
      }
    });

    setModifiedAssets(updatedAssets);
  };

  const handleSearchAsset = (e: IChangeInputEvent) => {
    setSearchInput(e.target.value);
  };

  const isAllAssetsSelectedTabCovered = useMemo(
    () => displayedAssets.length > 0 && displayedAssets.every((asset) => asset.is_covered),
    [displayedAssets],
  );

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      hideDialog(Dialogs.AssetsManagement);
    }
  };

  const handleSave = async () => {
    try {
      await syncUpdatedAssets();
      handleClose();
    } catch (error) {
      logError('Failed to sync assets');
    }
  };

  const ownerDisplayNames = useMemo(() => displayedAssets.reduce((acc, asset) => {
    // With AWS assets, each asset have a specific sub-account (which mean a specific environment).
    // All of these accounts, which are actually relate to the same account, will have the same 'owner' value which
    // is the aws account value of the main account.
    // This is why for each one of these assets we look back to the full list of assets and search for the main
    // account asset, and then bring the asset_name from there. Then the mapping is created correctly.
    //
    // If selectedTab is not AWS, the ownerDisplayNames keys will be the same as the values for each item.
    const displayName = selectedTab === TABS.AWS
      ? filteredAssets.find((currAsset) => currAsset.aws_account_id === asset.owner)?.asset_name
      : asset.owner;

    return {
      ...acc,
      [asset.owner]: displayName,
    };
  }, {}), [displayedAssets, selectedTab, filteredAssets]);

  return {
    isAllAssetsSelectedTabCovered,
    handleSave,
    handleClose,
    handleToggleSelectAllAssets,
    handleSearchAsset,
    checkMultipleAssetsByOwner,
    checkAsset,
    handleTabChange,
    selectedTab,
    displayedAssets,
    ownerDisplayNames,
    tabDetails,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    searchInput,
    refetch,
    isSaving,
  };
};
