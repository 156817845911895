import { useQuery } from 'react-query';

import { PRODUCT_CATALOG_FEATURES } from 'context/StiggContext/constants';
import { useGetBooleanEntitlement } from 'context/StiggContext/useGetBooleanEntitlement';
import { useGraphService } from 'services/GraphService/useGraphService';
import { Queries } from 'types/enums/Queries';

interface UseContextEngineResult {
  isContextEngineEnabled: boolean;
  isEntitled: boolean;
  isLoading: boolean;
}

export const useContextEngine = (): UseContextEngineResult => {
  const getBooleanEntitlement = useGetBooleanEntitlement();
  const { fetchGraphRunsStatus } = useGraphService();

  const isEntitled = getBooleanEntitlement(
    PRODUCT_CATALOG_FEATURES.SMART_PRIORITIZATION_WITH_CONTEXT_ENGINE,
  );

  const { data: hasSuccessfulRun, isLoading } = useQuery({
    queryKey: [Queries.GraphRunsStatus],
    queryFn: fetchGraphRunsStatus,
    enabled: isEntitled,
    select: (response) => response?.data.hasSuccessfulRun ?? false,
  });

  return {
    isContextEngineEnabled: isEntitled && (hasSuccessfulRun ?? false),
    isEntitled,
    isLoading,
  };
};
