import { FC, useCallback } from 'react';

import { AboutThisIssue } from './components/AboutThisIssue/AboutThisIssue';
import { FindingContext } from './components/FindingContext/FindingContext';
import { FindingInfo } from './components/FindingInfo/FindingInfo';
import { FixSuggestion } from './components/FixSuggestion/FixSuggestion';
import { Priority } from './components/Priority/Priority';
import { TicketManagement } from './components/TicketManagement/TicketManagement';

import { JitSidePanel } from 'components/JitSidePanel/JitSidePanel';
import { useJitSlidingPanelContext } from 'components/JitSlidingPanel/JitSlidingPanel';
import { GraphProvider } from 'context/GraphContext/GraphProvider';
import { useContextEngine } from 'context/GraphContext/hooks/useContextEngine';
import { IFinding } from 'types/interfaces';

type Props = {
  onClosePanel: () => void;
  finding: IFinding | null;
};

export const FindingDetails: FC<Props> = ({ onClosePanel, finding }) => {
  const { closePanel } = useJitSlidingPanelContext();
  const { isContextEngineEnabled } = useContextEngine();

  const handleClosePanel = useCallback(() => {
    closePanel();
    onClosePanel();
  }, [closePanel, onClosePanel]);

  if (!finding) return null;
  return (
    <JitSidePanel closePanel={handleClosePanel} title='pages.findings.findingDetails.header.title'>

      <FindingInfo finding={finding} />

      <FindingContext finding={finding} />

      {isContextEngineEnabled && (
        <GraphProvider>
          <Priority finding={finding} />
        </GraphProvider>
      )}

      <AboutThisIssue finding={finding} />

      <TicketManagement finding={finding} />

      {finding.fixPRUrl && <FixSuggestion url={finding.fixPRUrl} />}

    </JitSidePanel>

  );
};
