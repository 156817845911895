import { FC } from 'react';

import { CountStatus } from '../CountStatus/CountStatus';

import { useFindingsCount } from './useFindingsCount';
import styles from './ViewFindingsStatus.module.scss';

import { CircularLoadingSpinner } from 'components/CircularLoadingSpinner/CircularLoadingSpinner';
import { JitText } from 'components/JitText/JitText';
import { PriorityBoxIcon } from 'pages/FindingsPage/components/PriorityBox/PriorityBoxIcon';

export const ViewFindingsStatus: FC = () => {
  const { counts, contextEngineFinished, isLoading } = useFindingsCount();

  if (isLoading) {
    return <CircularLoadingSpinner />;
  }

  if (!contextEngineFinished) {
    return <CountStatus count={counts} mutedText='pages.quickStart.checks.viewFindings.raw.statusLabel' />;
  }
  return (
    <CountStatus
      count={(
        <div className={styles.counts}>
          {counts.map((group) => (
            <div key={group.key} className={styles.count}>
              <PriorityBoxIcon priorityScore={+group.key} />

              <JitText bold muted size='l' text={group.count} />
            </div>
          ))}
        </div>
)}
      mutedText='pages.quickStart.checks.viewFindings.prioritized.statusLabel'
    />
  );
};
