import { FC } from 'react';
import { Box } from '@mui/material';
import { TaskStepSection as TaskStepSectionType } from 'types/interfaces/Drilldown/types';
import { JitSidePanelItem } from 'components/JitSidePanel/JitSidePanelItem/JitSidePanelItem';
import { JittoTaskSteps } from 'pages/Jitto/JittoPage/subPages/JittoTasks/components/JittoTaskSteps/JittoTaskSteps';

interface TaskStepsSectionProps {
  section: TaskStepSectionType;
}

const TaskStepsSection: FC<TaskStepsSectionProps> = ({ section }) => {
  return (
    <JitSidePanelItem
      title={section.title}
      variant='light'
      wrapperStyleOverrides={{ borderRadius: '8px' }}
    >
      <Box sx={{ p: 1 }}>
        <JittoTaskSteps steps={section.steps} />
      </Box>
    </JitSidePanelItem>
  );
};

export default TaskStepsSection; 