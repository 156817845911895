import { FC } from 'react';
import { Node, Edge } from 'reactflow';

import { ISvg, IFinding, IAsset } from 'types/interfaces';
import { IPriorityFields } from 'types/interfaces/Graph/IPriorityFactor';

export type GraphEntity = IFinding | IAsset;

export type FactorType = string | {
  factor: string;
  description?: string;
};

export const getFactor = (factor: FactorType) => (typeof factor === 'string' ? factor : factor.factor);

export type CustomNodeData = {
  icon: {
    icon: FC<ISvg>;
    bgColor: string;
  }
  type: string;
  name?: string;
  factors?: FactorType[];
  entity: GraphEntity;
  priorityFields: IPriorityFields;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metadata?: any;
};

export type CustomNodeType = Node<CustomNodeData>;

export type CustomEdgeData = {
  description?: string;
};
export type CustomEdgeType = Edge<CustomEdgeData>;

