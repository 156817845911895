import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { Queries } from 'types/enums/Queries';
import { IInitialScanStats, IWebsocketNotification } from 'types/interfaces';
import { isWebsocketNotificationEntity } from 'utils/functions/assertions/websocketNotificationEntity';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';

export const useAssetInitialStatsWebsocket = () => {
  const queryClient = useQueryClient();

  const getUpdatedAssetInitialStats = useCallback((newData: IInitialScanStats[], prevData: IInitialScanStats[] | undefined) => {
    const updatedDataMap = new Map(newData.map((newItem) => [newItem.planItemSlug, newItem]));

    const mergedData = prevData?.map((prevItem) => {
      const newItem = updatedDataMap.get(prevItem.planItemSlug);

      if (!newItem) return prevItem;

      const isMoreAdvanced = (
        newItem.completedAssetCount > prevItem.completedAssetCount
        || newItem.inProgressAssetCount > prevItem.inProgressAssetCount
      );

      return isMoreAdvanced ? newItem : prevItem;
    }) || [];

    newData.forEach((newItem) => {
      if (!prevData?.find((prevItem) => prevItem.planItemSlug === newItem.planItemSlug)) {
        mergedData.push(newItem);
      }
    });

    return mergedData;
  }, []);

  const handleAssetInitialStatsWebsocketNotification = useCallback((notification: IWebsocketNotification<IInitialScanStats>) => {
    if (isWebsocketNotificationEntity(notification) && notification.message.updated) {
      const parsedItems = camelizeSnakeCaseKeys(notification.message.updated) as IInitialScanStats[];
      queryClient.setQueryData(Queries.InitialScanStats, (prevData: IInitialScanStats[] | undefined) => (
        getUpdatedAssetInitialStats(parsedItems, prevData)
      ));
    }
  }, [getUpdatedAssetInitialStats, queryClient]);

  return { handleAssetInitialStatsWebsocketNotification };
};
