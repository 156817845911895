import { constants } from 'globalConstants';

const getMockModeState = (): boolean => {
  const mockModeString = localStorage.getItem(constants.MOCK_MODE_STORAGE_KEY);
  // Return true by default, only return false if explicitly set to 'false'
  return mockModeString !== 'false';
};

const setMockModeState = (enabled: boolean): void => {
  localStorage.setItem(constants.MOCK_MODE_STORAGE_KEY, enabled ? 'true' : 'false');
};

const deleteMockModeState = (): void => {
  localStorage.removeItem(constants.MOCK_MODE_STORAGE_KEY);
};

export const useMockModeState = () => ({
  getMockModeState,
  setMockModeState,
  deleteMockModeState,
}); 