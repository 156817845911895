import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';
import { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PREMIUM_FEATURE_BANNER_FEATURE_NAMES } from './consts';
import styles from './PremiumFeatureBanner.module.scss';

import { Cross } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitLink } from 'components/JitLink/JitLink';
import { JitText } from 'components/JitText/JitText';
import { useGetCustomerTrialDetails } from 'context/StiggContext/useGetCustomerTrialDetails';
import { constants } from 'globalConstants';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';

interface PremiumFeatureBannerProps {
  isEntitled: boolean;
  featureName: keyof typeof PREMIUM_FEATURE_BANNER_FEATURE_NAMES;
  message?: string;
}

export const PremiumFeatureBanner: FC<PremiumFeatureBannerProps> = ({ isEntitled, message, featureName }) => {
  const navigate = useNavigate();
  const { isOnTrial, daysLeft, isLoading } = useGetCustomerTrialDetails(true);
  const [closed, setClosed] = useState(false);
  const onClose = useCallback(() => setClosed(true), []);

  if (closed || isLoading || (isEntitled && !isOnTrial)) {
    return null;
  }

  const hasFeature = isEntitled && isOnTrial;

  let bannerMessage = '';
  if (hasFeature) {
    bannerMessage = ` ${i18n.t('common.pricingPlan.trialDaysLeft', { count: daysLeft ?? 0 })}`;
  } else {
    bannerMessage = i18n.t('common.pricingPlan.premiumFeature');
  }

  if (!isEntitled && !isOnTrial) {
    if (message) {
      bannerMessage += ` ${message}`;
    }
  }

  const upgradeNowKey = hasFeature
    ? 'common.pricingPlan.upgradeNowWithFeatureName'
    : 'common.pricingPlan.upgradeNow';

  return (
    <div className={styles.wrapper}>
      <DiamondOutlinedIcon
        fontSize='small'
        sx={{
          color: colors.upgradeColor,
          marginRight: '6px',
        }}
      />

      <JitText color={colors.upgradeColor} text={bannerMessage} />

      <JitLink
        className={styles.docsLink}
        data-testid='upgradeToPremium'
        onClick={() => navigate(`/${constants.routes.PRICING_PLANS}`)}
        style={{
          cursor: 'pointer',
          textDecoration: 'none',
        }}
      >
        <JitText bold color={colors.upgradeColor} params={{ featureName: PREMIUM_FEATURE_BANNER_FEATURE_NAMES[featureName] }} text={upgradeNowKey} />
      </JitLink>

      <JitIcon className={styles.closeIcon} icon={Cross} onClick={onClose} size={16} />
    </div>
  );
};
