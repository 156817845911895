import React from 'react';
import FlexList from '../layout/FlexList';
import ComplexCard, { ComplexCardProps } from '../core/cards/ComplexCard';

export interface CardsListProps {
  cards: Array<ComplexCardProps>;
}

/**
 * A component that displays a list of ComplexCard components in a FlexList layout
 */
export const CardsList: React.FC<CardsListProps> = ({
  cards
}) => {
  return (
    <FlexList direction="row">
      {cards.map((card) => <ComplexCard key={card.id} {...card}/>)}
    </FlexList>
  );
};

export default CardsList;
