import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './FindingRow.module.scss';

import { JitButton } from 'components/JitButton/JitButton';
import { JitText } from 'components/JitText/JitText';
import { SeverityBox } from 'components/SeverityBox/SeverityBox';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import { constants } from 'globalConstants';
import { IFinding } from 'types/interfaces';
import { useDetailsByVendor } from 'utils/hooks/useDetailsByVendor';

interface Props {
  finding: IFinding;
}

export const FindingRow: FC<Props> = ({ finding }) => {
  const { getIconByVendor } = useDetailsByVendor();
  const navigate = useNavigate();
  const VendorIcon = getIconByVendor(finding.vendor, finding.assetType);
  const { routes: { risks: { BASE_ROUTE, BACKLOG } } } = constants;

  return (
    <div className={styles.container} data-testid='finding-row-container'>
      <div className={styles.findingRow}>
        <div className={styles.leftSection}>
          <SeverityBox data-testid='severity-box' severity={finding.severity} />

          <div className={styles.vendorInfo} data-testid='vendor-info'>
            {VendorIcon && (
              <div data-testid='vendor-icon'>
                <VendorIcon className={styles.vendorIcon} />
              </div>
            )}

            <TooltipOnlyOnOverflow
              data-testid='asset-name'
              muted
              size='s'
              text={finding.assetName}
            />
          </div>

          <TooltipOnlyOnOverflow
            data-testid='finding-name'
            muted
            size='s'
            text={finding.name}
          />
        </div>

        <div className={styles.rightSection}>
          <JitText
            data-testid='finding-date'
            muted
            size='s'
            text={finding.lastDetectedDate}
          />

          <JitButton
            className={styles.arrowButton}
            data-testid='arrow-button'
            height={24}
            onClick={() => navigate(`/${BASE_ROUTE}/${BACKLOG}?id=${finding.id}`)}
            variant='outlined'
            width={24}
          >
            <ArrowForwardIcon className={styles.arrowIcon} />
          </JitButton>
        </div>
      </div>
    </div>
  );
};
