import { AxiosResponse, AxiosError } from 'axios';
import { useCallback, useMemo, useEffect } from 'react';

import { config } from 'config';
import { useClient, createClient } from 'services/useClient';
import { ChatRequest, ChatResponse } from 'types/interfaces/Aegis/types';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';
import { useAuthContext } from 'context/AuthContext/AuthContext';

export interface UseAegisService {
  chat: (request: ChatRequest) => Promise<AxiosResponse<ChatResponse> | undefined>;
}

export const useAegisService = (): UseAegisService => {
  // Create the client inside the hook to ensure it has the latest auth headers
  const aegisClient = useMemo(() => createClient(config.aegisApiUrl), []);
  
  // Get the auth context to access the user's token
  const { frontEggUser } = useAuthContext();
  
  // Set authorization header for this client instance
  useEffect(() => {
    if (frontEggUser?.accessToken) {
      aegisClient.defaults.headers.common.authorization = `Bearer ${frontEggUser.accessToken}`;
    }
  }, [frontEggUser, aegisClient]);
  
  const { client } = useClient(aegisClient);

  // Main chat method
  const chat = useCallback(async (request: ChatRequest): Promise<AxiosResponse<ChatResponse> | undefined> => {
    try {
      // Make API request
      const response = await client.post<ChatResponse>({
        url: '/agent/chat',
        allowedStatuses: [200],
        requestConfig: {
          data: request
        }
      });
      
      // Check if response is defined before processing
      if (!response) {
        return undefined;
      }
      
      return response;
    } catch (error) {
      // errors are handled in useClient
      console.error('Error in Aegis chat:', error);
      return undefined;
    }
  }, [client]);

  return {
    chat,
  };
};
