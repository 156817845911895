import { Tag } from 'components/JitCard/JitCard';
import { IntegrationVendorType } from 'context/IntegrationsContext/templates/interfaces';
import { LABELS_COLORS } from 'pages/SecurityPlansPage/components/SecurityPlansBody/constants';

const variantTags: Partial<Record<IntegrationVendorType, Tag>> = {
  tms: {
    color: LABELS_COLORS[0],
    text: 'cards.tags.tms',
  },
  cloud: {
    color: LABELS_COLORS[1],
    text: 'cards.tags.cloud',
  },
  sourceCodeManagement: {
    color: LABELS_COLORS[2],
    text: 'cards.tags.sourceCodeManagement',
  },
  collaborationPlatform: {
    color: LABELS_COLORS[3],
    text: 'cards.tags.collaborationPlatform',
  },
  cicdSecurity: {
    color: LABELS_COLORS[12],
    text: 'cards.tags.cicdSecurity',
  },
  registry: {
    color: LABELS_COLORS[8],
    text: 'cards.tags.registry',
  },
  dataSecurity: {
    color: LABELS_COLORS[7],
    text: 'cards.tags.dataSecurity',
  },
  siem: {
    color: LABELS_COLORS[13],
    text: 'cards.tags.siem',
  },
  cloudSecurity: {
    color: LABELS_COLORS[14],
    text: 'cards.tags.cloudSecurity',
  },
  dast: {
    color: LABELS_COLORS[15],
    text: 'cards.tags.dast',
  },
  compliance: {
    color: LABELS_COLORS[16],
    text: 'cards.tags.compliance',
  },
  secretManagement: {
    color: LABELS_COLORS[17],
    text: 'cards.tags.secretManagement',
  },
  observability: {
    color: LABELS_COLORS[18],
    text: 'cards.tags.observability',
  },
  sast: {
    color: LABELS_COLORS[19],
    text: 'cards.tags.sast',
  },
};

export const getVariantTagArray = (vendorType: IntegrationVendorType): Tag[] => {
  const tag = variantTags[vendorType];
  return tag ? [tag] : [];
};
