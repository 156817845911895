import React from 'react';
import { Box, Container, Divider, Stack } from '@mui/material';
import { PageLayout } from 'components/Pages/PageLayout/PageLayout';
import { useParams } from 'react-router-dom';
import { SkillHeader, PersonalizedTraining } from './components';

const JittoSkillPage: React.FC = () => {
  // Handle the wildcard parameter from the URL
  const params = useParams();
  
  return (
    <PageLayout>
      <div style={{ height: '100vh', overflow: 'auto' }}>
        <Container maxWidth="lg">
          <Box sx={{ py: 3 }}>
            <SkillHeader 
              title="Risk Assessor"
              description="Continuously monitors systems and infrastructure to identify security vulnerabilities and compliance gaps"
            />
            <PersonalizedTraining />
          </Box>
        </Container>
      </div>
    </PageLayout>
  );
};

export default JittoSkillPage; 