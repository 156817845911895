export const ROLES = {
  ADMIN: 'admin',
  MEMBER: 'member',
  DEVELOPER: 'developer',
  ENGINEERING_MANAGER: 'engineering_manager',
  CLI_AGENT: 'cli_agent',
};

export const PERMISSIONS = {
  // Old format - DO NOT WRITE NEW PERMISSIONS IN THIS FORMAT
  READ_PIPELINE: 'jit.read.pipeline',
  READ_RESOURCE: 'jit.read.resource',
  READ_PIPELINES: 'jit.read.pipelines',
  READ_ACTIONS: 'jit.read.actions',
  READ_TEAMS: 'jit.read.teams',
  READ_INSIGHTS: 'jit.read.insights',
  READ_SNAPSHOT: 'jit.read.performanceSnapshot',
  READ_FINDINGS: 'jit.read.findings',
  READ_INTEGRATIONS: 'jit.read.integrations',
  READ_PLAN: 'jit.read.plan',
  READ_OVERVIEW: 'jit.read.overview',

  // New format
  READ_SBOM: 'jit.sbom.read',
  READ_SECURITY_MAPPING: 'jit.securityMapping.read',
  WRITE_FINDINGS: 'jit.findings.write',
  WRITE_ORGANIZATIONS: 'jit.organizations.write',
  WRITE_IGNORE_RULES: 'jit.ignoreRules.write',
  WRITE_TEAMS: 'jit.teams.write',
  WRITE_TICKETS: 'jit.ticketing.write',
  TEAMS_PORTAL: 'jit.teamsPortal.read',
  MANAGEMENT_PLATFORM: 'jit.managementPlatform.read',
  INVITE: 'jit.invitationTokens.write',
  READ_BACKLOG: 'jit.findings.read',
  WRITE_BACKLOG: 'jit.findings.write',
  READ_RESOURCES: 'jit.resources.read',
  QUICKSTART: 'jit.quickstart.read',
  TEAM_PORTAL_QUICKSTART: 'jit.teams.quickstart.read',
  READ_POLICIES: 'jit.policies.read',
  READ_WORKFLOWS: 'jit.workflows.read',
  WRITE_WORKFLOWS: 'jit.workflows.write',
  READ_PRICING: 'jit.pricing.read',
  WRITE_PRICING: 'jit.pricing.write',
  READ_BILLING: 'jit.billing.read',
  WRITE_BILLING: 'jit.billing.write',
  WRITE_POLICIES: 'jit.policies.write',
};
