import { groupBy } from 'lodash';
import { useMemo } from 'react';

import { useContextEngine } from 'context/GraphContext/hooks/useContextEngine';
import { calcPriorityScoreColor } from 'pages/FindingsPage/components/PriorityBox/utils';
import { useFindingsCount as useFindingsCountHook } from 'services/FindingsService/hooks';
import { ICount } from 'types/interfaces/Counts/ICounts';

const FINDINGS_COUNT_PARAMS = {
  group_by: 'priority_score',
  filters: { ignored: false },
};

const RAW_FINDINGS_COUNT_PARAMS = {
  filters: { ignored: false },
};

export const useFindingsCount = () => {
  const { isLoading: isContextEngineLoading, isContextEngineEnabled } = useContextEngine();
  const { data: priorityScores, isFetching: isPriorityScoresLoading } = useFindingsCountHook({
    params: FINDINGS_COUNT_PARAMS,
  });

  const priorityScoresCount = useMemo(() => {
    if (!Array.isArray(priorityScores)) {
      return [];
    }

    const removedZero = priorityScores.filter((score) => score.key !== '0');
    const groupedByColor = groupBy(removedZero, (item) => calcPriorityScoreColor(Number(item.key)));

    return Object.values(groupedByColor).map((group) => ({
      key: group[0].key,
      count: group.reduce((acc, item) => acc + item.count, 0),
    }));
  }, [priorityScores]);

  const { data: rawCount, isFetching: isRawCountLoading } = useFindingsCountHook({
    params: RAW_FINDINGS_COUNT_PARAMS,
  }, { enabled: Boolean(priorityScores) && priorityScoresCount.length === 0 });

  const isLoading = isPriorityScoresLoading || isRawCountLoading || isContextEngineLoading;

  return useMemo(() => {
    if ((!rawCount || Array.isArray(rawCount) || priorityScoresCount.length) && isContextEngineEnabled) {
      return {
        counts: priorityScoresCount,
        contextEngineFinished: true,
        isLoading,
      } as const;
    }

    return {
      counts: (rawCount as ICount)?.count,
      contextEngineFinished: false,
      isLoading,
    } as const;
  }, [rawCount, priorityScoresCount, isContextEngineEnabled, isLoading]);
};
