import { useCallback } from 'react';

import { useStiggExtendedContext } from './StiggExtendedContext';
import { useGetBooleanEntitlement } from './useGetBooleanEntitlement';

export const useGetIsUserEntitledToPlanItem = () => {
  const { stiggFeatures } = useStiggExtendedContext();
  const getBooleanEntitlement = useGetBooleanEntitlement();
  return useCallback((planItemSlug: string) => {
    const stiggPlanItemFeature = `feature-${planItemSlug}`;

    const isPlanItemPremiumFeature = stiggFeatures.some((feature) => feature.featureId === stiggPlanItemFeature);

    const isUserEntitled = isPlanItemPremiumFeature
      ? getBooleanEntitlement(stiggPlanItemFeature, false)
      : true;

    return isUserEntitled;
  }, [getBooleanEntitlement, stiggFeatures]);
};
