/* eslint-disable max-statements */
import { useEffect, useCallback, useMemo } from 'react';

import { useAssetInitialStatsWebsocket } from '../../SecurityPlansProgress/hooks/useAssetInitialStatsWebsocket';
import { FINDINGS_LIMIT, MOCK_CRITICAL_FINDING, SEMGREP_CONTROL_NAME } from '../constants';

import { useWebsocketSubscribe } from 'context/WebSocketContext/hooks';
import { useAssetsQueries } from 'services/AssetsService/useAssetsQueries';
import { useFindings } from 'services/FindingsService/hooks';
import { Severity, WebSocketNotificationTopics } from 'types/enums';
import { IFinding } from 'types/interfaces';
import { ITableQueryParams } from 'types/interfaces/Table/ITableQueryParams';

const mockFindings = Array(FINDINGS_LIMIT).fill(null).map((_, index) => ({
  ...MOCK_CRITICAL_FINDING,
  id: `mock-finding-${index}`,
  name: '',
}));

const criticalParams: ITableQueryParams = {
  page_limit: FINDINGS_LIMIT,
  filters: {
    control_name: [SEMGREP_CONTROL_NAME],
    issue_severity: [Severity.Critical, Severity.High],
    backlog: true,
  },
};

const fallbackParams: ITableQueryParams = {
  page_limit: FINDINGS_LIMIT,
  filters: {
    backlog: true,
  },
};

export const useExploreFindings = () => {
  const { websocketSubscribe } = useWebsocketSubscribe();
  const { handleAssetInitialStatsWebsocketNotification } = useAssetInitialStatsWebsocket();
  const { useInitialScanStats } = useAssetsQueries();
  const { data: assetStats, isLoading: isAssetStatsLoading } = useInitialScanStats();

  const isInitialScanComplete = useMemo(() => assetStats?.every((item) => item.inProgressAssetCount === 0), [assetStats]);

  const {
    data: criticalFindings,
    isFetching: isCriticalFindingsFetching,
    refetch: refetchCriticalFindings,
    isLoading: isCriticalFindingsLoading,
  } = useFindings({
    params: criticalParams,
    enabled: isInitialScanComplete,
  });

  const {
    data: fallbackFindings,
    isFetching: isFallbackFindingsFetching,
    refetch: refetchFallbackFindings,
    isLoading: isFallbackFindingsLoading,
  } = useFindings({
    params: fallbackParams,
    enabled: isInitialScanComplete && (criticalFindings?.data?.length || 0) < FINDINGS_LIMIT,
  });

  const handleAssetStatsUpdate = useCallback(() => {
    if (isInitialScanComplete) {
      const currentFindingsCount = criticalFindings?.data?.length || 0;
      if (currentFindingsCount < FINDINGS_LIMIT) {
        refetchCriticalFindings();
        if (currentFindingsCount + (fallbackFindings?.data?.length || 0) < FINDINGS_LIMIT) {
          refetchFallbackFindings();
        }
      }
    }
  }, [isInitialScanComplete, criticalFindings?.data?.length, fallbackFindings?.data?.length, refetchCriticalFindings, refetchFallbackFindings]);

  useEffect(() => {
    websocketSubscribe(
      WebSocketNotificationTopics.AssetScanStats,
      (notification) => {
        handleAssetInitialStatsWebsocketNotification(notification);
        handleAssetStatsUpdate();
      },
    );
  }, [websocketSubscribe, handleAssetInitialStatsWebsocketNotification, handleAssetStatsUpdate]);

  const hasRealFindings = Boolean((criticalFindings?.data?.length ?? 0) > 0 || (fallbackFindings?.data?.length ?? 0) > 0);

  const findings: IFinding[] = useMemo(() => {
    if (!isInitialScanComplete && !hasRealFindings) {
      return mockFindings;
    }
    const result = [...(criticalFindings?.data || [])].slice(0, FINDINGS_LIMIT);

    if (result.length < FINDINGS_LIMIT && fallbackFindings?.data) {
      const remainingSlots = FINDINGS_LIMIT - result.length;
      const criticalFindingIds = new Set(result.map((finding) => finding.id));

      const uniqueFallbackFindings = fallbackFindings.data.filter(
        (finding) => !criticalFindingIds.has(finding.id),
      );

      return [...result, ...uniqueFallbackFindings.slice(0, remainingSlots)];
    }

    return result;
  }, [isInitialScanComplete, hasRealFindings, criticalFindings?.data, fallbackFindings?.data]);

  const isFetching = (isCriticalFindingsFetching || isFallbackFindingsFetching) && findings.length === 0;

  return {
    findings,
    isInitialScanComplete,
    isMockData: !hasRealFindings,
    isLoading: isAssetStatsLoading || isCriticalFindingsLoading || isFallbackFindingsLoading || isFetching,
  };
};
