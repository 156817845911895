import { FC } from 'react';

import { ActionConfigInputProps } from '../../ActionConfigInput';

import styles from './MicrosoftteamsChannelPicker.module.scss';

import { MSTeamsChannelSelect } from 'components/MSTeamsChannelSelect/MSTeasmChannelSelect';

export const MicrosoftteamsChannelPicker: FC<ActionConfigInputProps> = ({
  configSchema,
  actionConfig,
  updateActionConfig,
}) => (
  <div className={styles.wrapper} data-testid='msChannelPicker'>
    <MSTeamsChannelSelect onChange={(value) => updateActionConfig(configSchema.key, value)} value={actionConfig[configSchema.key]} />

  </div>
);
