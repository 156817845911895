import React from 'react';
import { Box, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { ExpertiseSectionProps } from '../types';
import ExpertiseCard from './ExpertiseCard';
import { JitText } from 'components/JitText/JitText';
import { Typography } from '@mui/material';

const ExpertiseSection: React.FC<ExpertiseSectionProps> = ({
  expertise,
  onCustomize,
  onAdd,
}) => {
  return (
    <div
      style={{
        padding: '16px',
        backgroundColor: '#121926',
        borderRadius: '8px',
      }}
    >
      <Box sx={{ mb: 2, ml: 1 }}>
        <JitText text={expertise.title} />
      </Box>

      <Grid container spacing={3}>
        {expertise.cards.map((card) => (
          <Grid item xs={12} sm={6} key={card.id}>
            <ExpertiseCard 
              card={card} 
              onCustomize={() => onCustomize && onCustomize(card.id)} 
            />
          </Grid>
        ))}
        
        {expertise.cards.length < 4 && (
          <Grid item xs={12} sm={6}>
            <div
              style={{
                padding: '24px',
                height: '100%',
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px dashed rgba(255, 255, 255, 0.12)',
                backgroundColor: '#1E2235',
                cursor: 'pointer',
              }}
              onClick={onAdd}
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.04)'}
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#1E2235'}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  py: 2,
                }}
              >
                <Box
                  sx={{
                    width: 40,
                    height: 40,
                    borderRadius: '50%',
                    bgcolor: '#252A3D',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 1.5,
                  }}
                >
                  <AddIcon sx={{ color: '#6B7AFF' }} />
                </Box>
                <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                  {expertise.actions.add}
                </Typography>
              </Box>
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default ExpertiseSection; 