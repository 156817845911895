import { FC, useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { JitText } from 'components/JitText/JitText';
import { PageLayout } from 'components/Pages/PageLayout/PageLayout';
import { SeraIcon } from 'assets/agent-svg';
import { JitIcon } from 'components/JitIcon/JitIcon';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import styled from '@mui/material/styles/styled';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { useAuthContext } from 'context/AuthContext/AuthContext';
import { useJittoContext, Message as JittoMessage, Conversation as JittoConversation, ReportStatus, Thought } from 'context/JittoContext';
import { MOCK_COMPLETED_REPORT } from 'context/JittoContext/mockData/mockJittoConversations';
import { v4 as uuidv4 } from 'uuid';
import JittoReportRenderer from 'pages/Jitto/ChatPage/components/JittoReportRenderer/JittoReportRenderer';
import { Typography } from '@mui/material';
import { useSendMessage } from 'context/JittoContext/useSendMessage';

// Styled components
const PageContainer = styled(Box)({
  position: 'relative',
  minHeight: '100vh',
  background: '#1A1E2E',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: 'radial-gradient(circle at 10% 30%, rgba(72, 91, 255, 0.05) 0%, transparent 50%)',
    pointerEvents: 'none',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: '0',
    right: '0',
    width: '100%',
    height: '100%',
    background: 'radial-gradient(circle at 90% 80%, rgba(97, 75, 195, 0.05) 0%, transparent 50%)',
    pointerEvents: 'none',
  }
});


const CenteredContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  textAlign: 'center',
  maxWidth: '950px',
  margin: '0 auto',
  padding: '40px 20px',
  position: 'relative',
  zIndex: 1,
});

// Add the central glow effect
const CentralGlow = styled(Box)({
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  background: 'radial-gradient(circle at center 30%, rgba(6, 121, 255, 0.2) 0%, rgba(42, 140, 207, 0.15) 25%, transparent 60%)',
  pointerEvents: 'none',
  zIndex: 0,
});

const IconWrapper = styled(Box)({
  marginBottom: '30px',
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: '-15px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '40px',
    height: '2px',
    background: 'linear-gradient(90deg, transparent, rgba(184, 190, 239, 0.5), transparent)',
  }
});

const InputContainer = styled(Paper)({
  width: '100%',
  borderRadius: '22px',
  padding: '12px 16px',
  marginBottom: '30px',
  boxShadow: '0 3px 8px rgba(0, 0, 0, 0.3)',
  display: 'flex',
  alignItems: 'center',
});

const StyledInput = styled(Input)({
  width: '100%',
  padding: '4px 0',
  fontSize: '16px',
  color: '#fff',
  backgroundColor: 'transparent',
  '&::placeholder': {
    color: 'rgba(255, 255, 255, 0.6)',
  },
  '&:before, &:after': {
    display: 'none',
  },
});

const InputActions = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginLeft: '12px',
});

const SendButton = styled(Button)({
  minWidth: '50px',
  width: '50px',
  height: '50px',
  borderRadius: '16px',
  background: '#0679FF',
  color: '#fff',
  boxShadow: 'none',
  padding: 0,
  '&:hover': {
    background: '#2A8CFF',
    boxShadow: 'none',
  },
  '&.Mui-disabled': {
    background: 'rgba(6, 121, 255, 0.4)',
    color: 'rgba(255, 255, 255, 0.5)',
  }
});

const OptionButton = styled(Button)({
  background: 'rgba(30, 34, 52, 0.8)',
  color: '#fff',
  borderRadius: '12px',
  padding: '16px 24px',
  textTransform: 'none',
  fontWeight: 'normal',
  fontSize: '16px',
  width: 'auto',
  textAlign: 'center',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
  border: '1px solid rgba(255, 255, 255, 0.08)',
  '&:hover': {
    background: 'rgba(42, 48, 70, 0.9)',
    borderColor: 'rgba(255, 255, 255, 0.15)',
  }
});

const ActionButton = styled(Button)({
  backgroundColor: 'rgba(30, 34, 52, 0.6)',
  color: '#fff',
  borderRadius: '8px',
  padding: '6px 12px',
  fontSize: '13px',
  textTransform: 'none',
  border: '1px solid rgba(255, 255, 255, 0.08)',
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
  minWidth: 'unset',
  '&:hover': {
    backgroundColor: 'rgba(30, 34, 52, 0.8)',
    borderColor: 'rgba(6, 121, 255, 0.3)',
  },
});

// Add these new styled components for the chat view
const ChatView = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  width: '100%',
  position: 'relative',
  background: '#1A1E2E',
  overflow: 'hidden', // To ensure contents don't overflow
});

// Chat content with wider padding for better spacing
const ChatContent = styled(Box)({
  flexGrow: 1,
  overflowY: 'scroll',
  padding: '20px 40px',
  paddingBottom: '120px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  width: '70%',
  margin: '0 auto',
});

const BottomInputContainer = styled(Box)({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  padding: '16px 8px', // Reduced horizontal padding
  paddingTop: '8px',
  zIndex: 10,
});

const ChatInputContainer = styled(Paper)({
  width: 'calc(50% - 120px)',
  margin: '0 auto',
  borderRadius: '8px',
  padding: '8px 12px',
  backgroundColor: '#1E2234',
  border: '1px solid rgba(255, 255, 255, 0.05)',
  boxShadow: '0 3px 8px rgba(0, 0, 0, 0.3)',
  display: 'flex',
  alignItems: 'center',
});

// Modify the MessageContainer for individual messages
const MessageContainer = styled(Box)({
  backgroundColor: '#1A1E2E',
  borderRadius: '12px',
  border: '1px solid rgba(255, 255, 255, 0.08)',
  padding: '14px 16px',
  marginBottom: '24px',
  width: 'fit-content',
});

// Define container for all messages with consistent layout
const SenderMessageContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start', // Align all messages to the left
});

const MessageHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '12px',
});

// Message avatar styling - merged component for both Jitto and User avatars
const MessageAvatar = styled(Box)({
  width: '36px',
  height: '36px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '12px',
});


// Styled component for the thinking/action steps
const StepContainer = styled(Box)({
  marginTop: '16px',
  padding: '12px',
  borderRadius: '8px',
  backgroundColor: 'rgba(30, 34, 52, 0.5)',
  border: '1px solid rgba(255, 255, 255, 0.08)',
  // maxHeight: '300px',
  // overflowY: 'auto'
});

const StepItem = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  marginBottom: '8px',
  '&:last-child': {
    marginBottom: 0,
  },
});

const StepIcon = styled(Box)({
  width: '18px',
  height: '18px',
  marginRight: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '2px',
});

// Utility function to group message content
const groupMessageContent = (messageContent: (string | Thought)[]): (string | Thought[])[] => {
  const groupedContent: (string | Thought[])[] = [];
  let currentThoughtGroup: Thought[] = [];
  
  // Process each content item while preserving order
  messageContent.forEach((item, index) => {
    if (typeof item === 'string') {
      // If we had a group of thoughts, add it to the results before this string
      if (currentThoughtGroup.length > 0) {
        groupedContent.push([...currentThoughtGroup]);
        currentThoughtGroup = [];
      }
      // Add the string to the results
      groupedContent.push(item);
    } else if (item && typeof item === 'object') {
      // Add the thought to the current group
      currentThoughtGroup.push(item);
      
      // If this is the last item, add the group to the results
      if (index === messageContent.length - 1) {
        groupedContent.push([...currentThoughtGroup]);
      }
    }
  });
  
  return groupedContent;
};

export const ChatPage: FC = () => {
  const [inputValue, setInputValue] = useState<string>('');
  
  const chatContentRef = useRef<HTMLDivElement>(null);
  
  const { frontEggUser } = useAuthContext();
  const { 
    currentConversation,
  } = useJittoContext();
  const userProfilePictureUrl = frontEggUser?.profilePictureUrl || '';
  
  // Use the sendMessage hook
  const { sendMessage, isLoading } = useSendMessage();
  
  // Derive isJittoResponding from loading state
  const isJittoResponding = useMemo(() => {
    return isLoading;
  }, [isLoading]);
  
  // Scroll to bottom when messages change
  useEffect(() => {
    if (chatContentRef.current) {
      chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
    }
  }, [currentConversation?.messages]);
  
  const handleSubmit = (directText?: string) => {
    // Use directText if provided, otherwise use inputValue
    const text = directText || inputValue.trim();
    
    // Return if there's no text to submit
    if (!text) return;
    
    // Clear input field (only if we're using the input field submission)
    if (!directText) {
      setInputValue('');
    }
    
    // Use the sendMessage function from the hook
    sendMessage(text);
  };
  
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }
  };
  
  // Get messages from the current conversation
  const messages = currentConversation?.messages || [];
  
  return (
    <PageLayout>
      {currentConversation?.report?.status === ReportStatus.Complete && currentConversation?.report?.data !== undefined ? (
        // Render JittoReportRenderer when report is complete and has data
        <JittoReportRenderer />
      ) : !messages.length ? (
        <PageContainer>
          <CentralGlow />
          
          <CenteredContainer>
            <IconWrapper>
              <JitIcon 
                icon={SeraIcon}
                width={90} 
                height={90} 
                style={{ 
                  filter: 'drop-shadow(0 0 10px rgba(184, 190, 239, 0.3))'
                }} 
              />
            </IconWrapper>
            
            <JitText size="xl" bold text="Get Started with Sera" />
            <Box sx={{ marginTop: '16px', marginBottom: '40px', maxWidth: '600px' }}>
              <JitText color="rgba(255, 255, 255, 0.7)" text="Type in your question and let Sera help you." />
            </Box>
            
            <InputContainer>
              <StyledInput
                placeholder="How can I help you?"
                disableUnderline
                disabled={isJittoResponding}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyPress={handleKeyPress}
                fullWidth
              />
              
              <InputActions>
                <SendButton 
                  disabled={!inputValue.trim() || isJittoResponding} 
                  onClick={() => handleSubmit()}
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 12H19M19 12L13 6M19 12L13 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </SendButton>
              </InputActions>
            </InputContainer>
            
            {/* Horizontal layout for template buttons */}
            <Box sx={{ 
              display: 'flex', 
              flexDirection: { xs: 'column', sm: 'row' }, 
              justifyContent: 'center', 
              flexWrap: 'wrap', 
              gap: '16px', 
              width: '100%', 
              mb: 4
            }}>
              <OptionButton onClick={() => handleSubmit("What are my top risks?")}>
                What are my top risks?
              </OptionButton>
              
              <OptionButton onClick={() => handleSubmit("Am I vulnerable to this zero day attack?")}>
                Am I vulnerable to this zero day attack?
              </OptionButton>
              
              <OptionButton onClick={() => handleSubmit("Can you help me get compliant with this regulation?")}>
                Can you help me get compliant with this regulation?
              </OptionButton>
            </Box>
          </CenteredContainer>
        </PageContainer>
      ) : (
        // Chat view after first message
        <ChatView>
          {/* Message content area with higher z-index */}
          <ChatContent 
            ref={chatContentRef}
            sx={{ 
              position: 'relative', 
              zIndex: 2
            }}
          >
            {/* This will push content to the bottom */}
            <Box sx={{ flexGrow: 1 }} />
            
            {/* Message list */}
            {messages.map((message) => (
              <SenderMessageContainer>
                <MessageContainer>
                  <MessageHeader>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {message.source === 'user' ? (
                        <>
                          <MessageAvatar sx={{ backgroundColor: '#0679FF' }}>
                            <Avatar 
                                src={userProfilePictureUrl} 
                                sx={{
                                  width: 36,
                                  height: 36
                                }}
                              />
                          </MessageAvatar>
                          <Box sx={{ ml: 2, color: '#FFFFFFCC', fontSize: '14px' }}>
                            You | {message.timestamp instanceof Date ? message.timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : ''}
                          </Box>
                        </>
                      ) : (
                        <>
                          <MessageAvatar>
                            <SeraIcon />
                          </MessageAvatar>
                          <Box sx={{ ml: 2, color: '#FFFFFFCC', fontSize: '14px' }}>
                            Sera | {message.timestamp instanceof Date ? message.timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : ''}
                          </Box>
                        </>
                      )}
                    </Box>
                  </MessageHeader>
                  <Box sx={{ 
                    color: '#FFFFFF',
                    maxWidth: '100%',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word'
                  }}>
                    <div>
                      {/* Render content in original order, with thoughts grouped */}
                      {groupMessageContent(message.content).map((item, index) => {
                        // If it's a string, render it directly
                        if (typeof item === 'string') {
                          return (
                            <Typography key={`text-${index}`} sx={{ mb: 2 }}>
                              {item}
                            </Typography>
                          );
                        }
                        
                        // If it's an array of thoughts, render them in a container
                        const thoughts = item as Thought[];
                        return (
                          <StepContainer key={`thoughts-${index}`} sx={{ mb: 2 }}>
                            {thoughts.map((thought, stepIndex) => (
                              <StepItem key={stepIndex}>
                                <StepIcon>
                                  {thought.type === 'outcome' ? (
                                    // No icon for outcome steps
                                    <Box width="18px" height="18px" />
                                  ) : thought.status === 'completed' ? (
                                    // Completed step icon
                                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M5 12l5 5L19 7" stroke="#4CAF50" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                  ) : (
                                    // In progress step - show spinner
                                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M12 2v4M12 18v4M4.93 4.93l2.83 2.83M16.24 16.24l2.83 2.83M2 12h4M18 12h4M4.93 19.07l2.83-2.83M16.24 7.76l2.83-2.83" 
                                        stroke="#fff" 
                                        strokeWidth="2" 
                                        strokeLinecap="round" 
                                        strokeLinejoin="round"
                                        strokeDasharray="60"
                                        strokeDashoffset="0">
                                        <animateTransform
                                          attributeName="transform"
                                          attributeType="XML"
                                          type="rotate"
                                          from="0 12 12"
                                          to="360 12 12"
                                          dur="1s"
                                          repeatCount="indefinite"
                                        />
                                      </path>
                                    </svg>
                                  )}
                                </StepIcon>
                                
                                <Box sx={{ 
                                  flexDirection: 'column',
                                  alignItems: 'flex-start',
                                  color: thought.status === 'inProgress' ? '#fff' : '#fff',
                                  fontSize: '14px'
                                }}>
                                  <Box sx={{ 
                                    mb: 0.5, 
                                    fontWeight: thought.status === 'inProgress' ? 'bold' : 'normal',
                                    color: 'rgba(255, 255, 255, 0.6)',
                                    fontSize: '12px'
                                  }}>
                                    {thought.type === 'thinking' 
                                      ? 'Thinking:' 
                                      : thought.type === 'task' 
                                        ? 'Task:' 
                                        : 'Outcome:'}
                                  </Box>
                                  <Box>{thought.text}</Box>
                                </Box>
                              </StepItem>
                            ))}
                          </StepContainer>
                        );
                      })}
                    </div>
                  </Box>
                </MessageContainer>
              </SenderMessageContainer>
            ))}
            
            {/* Show a loading state while waiting for Jitto's response */}
            {isLoading && (
              <SenderMessageContainer>
                <MessageContainer>
                  <MessageHeader>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <MessageAvatar>
                        <SeraIcon  />
                      </MessageAvatar>
                      <Box sx={{ mt: 2, ml: 2 }}>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                          <Box sx={{ 
                            width: 8, 
                            height: 8, 
                            borderRadius: '50%',
                            backgroundColor: 'rgba(255, 255, 255, 0.5)',
                            animation: 'pulse 1s infinite',
                            animationDelay: '0s'
                          }} />
                          <Box sx={{ 
                            width: 8, 
                            height: 8, 
                            borderRadius: '50%',
                            backgroundColor: 'rgba(255, 255, 255, 0.5)',
                            animation: 'pulse 1s infinite',
                            animationDelay: '0.2s'
                          }} />
                          <Box sx={{ 
                            width: 8, 
                            height: 8, 
                            borderRadius: '50%',
                            backgroundColor: 'rgba(255, 255, 255, 0.5)',
                            animation: 'pulse 1s infinite',
                            animationDelay: '0.4s'
                          }} />
                        </Box>
                      </Box>
                    </Box>
                  </MessageHeader>
                </MessageContainer>
              </SenderMessageContainer>
            )}
          </ChatContent>
          
          {/* Bottom input bar with highest z-index */}
          <BottomInputContainer sx={{ zIndex: 5 }}>
            <ChatInputContainer>
              <StyledInput
                placeholder="Ask Sera..."
                disableUnderline
                disabled={isJittoResponding}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyPress={handleKeyPress}
                fullWidth
              />
              
              <InputActions>
                <SendButton 
                  disabled={!inputValue.trim() || isJittoResponding} 
                  onClick={() => handleSubmit()}
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 12H19M19 12L13 6M19 12L13 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </SendButton>
              </InputActions>
            </ChatInputContainer>
          </BottomInputContainer>
        </ChatView>
      )}
    </PageLayout>
  );
};
