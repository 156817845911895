/* eslint-disable react/jsx-max-depth */
import {
  Box,
  Paper,
} from '@mui/material';
import { AxiosResponse } from 'axios';
import { FC, useEffect, useState, useRef } from 'react';

import { MonacoConfigEditor } from './components/MonacoConfigEditor';
import styles from './ConfigurationSection.module.scss';

import { EditPen, Eye } from 'assets';
import { CircularLoadingSpinner } from 'components/CircularLoadingSpinner/CircularLoadingSpinner';
import { CustomDialog } from 'components/JitDialogs/CustomDialog/CustomDialog';
import { JitFileInput } from 'components/JitFileInput/JitFileInput';
import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitSidePanelItem } from 'components/JitSidePanel/JitSidePanelItem/JitSidePanelItem';
import { JitText } from 'components/JitText/JitText';
import { useSecurityToolRules } from 'pages/SecurityToolsPage/hooks/useSecurityToolRules';
import { SecurityTool } from 'pages/SecurityToolsPage/types/SecurityTool';
import { usePlanService } from 'services/PlanService/usePlanService';
import { ActionButtonVariant } from 'types/interfaces/ActionButton/ActionButton';
import { UploadFileErrorResponse } from 'types/interfaces/Files/IUploadFile';
import { useSnackBar } from 'utils/hooks/useSnackBar';

interface Props {
  tool: SecurityTool;
}

export const ConfigurationSection: FC<Props> = ({ tool }) => {
  const [fileName, setFileName] = useState<string>('');
  const [openViewDialog, setOpenViewDialog] = useState<boolean>(false);
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
  const [editedContent, setEditedContent] = useState<string>('');
  const [isLoadingContent, setIsLoadingContent] = useState<boolean>(false);
  const awaitingErrorRef = useRef(false);

  const { uploadFileToCentralizedRepo } = usePlanService();
  const { showSnackBar } = useSnackBar();
  const {
    isUploading: isUploadingContent,
    error: configError,
    fileContent,
    fetchRulesFile,
    uploadRulesContent,
  } = useSecurityToolRules();

  const configFilePath = `${tool.id}-config.yml`;

  useEffect(() => {
    if (openEditDialog && fileContent) {
      setEditedContent(fileContent);
      setIsLoadingContent(false);
    }
  }, [fileContent, openEditDialog]);

  useEffect(() => {
    if (configError && openEditDialog) {
      if (awaitingErrorRef.current) {
        showSnackBar({
          title: 'securityToolsSection.messages.configurationError',
          description: configError,
          type: 'error',
        });
        awaitingErrorRef.current = false;
      }
    }
  }, [configError, openEditDialog, showSnackBar]);

  const handleProcessUploadResponse = async (response: AxiosResponse | undefined) => {
    if (response && response.status >= 200 && response.status < 300) {
      await fetchRulesFile(configFilePath);
      showSnackBar({
        title: 'securityToolsSection.messages.configurationUploaded',
        description: `${tool.name} configuration uploaded successfully.`,
        type: 'success',
      });
    } else {
      showSnackBar({
        title: 'securityToolsSection.messages.uploadError',
        description: 'Failed to upload configuration file',
        type: 'error',
      });
    }
  };

  const handleViewConfig = async () => {
    try {
      setIsLoadingContent(true);
      setOpenViewDialog(true);
      await fetchRulesFile(configFilePath);
      setIsLoadingContent(false);
    } catch (error) {
      showSnackBar({
        title: 'securityToolsSection.messages.fetchError',
        description: 'securityToolsSection.messages.fetchErrorDescription',
        type: 'error',
      });
      setOpenViewDialog(false);
      setIsLoadingContent(false);
    }
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
  };

  const handleEditClick = async () => {
    try {
      setIsLoadingContent(true);
      setOpenEditDialog(true);
      await fetchRulesFile(configFilePath);
      setIsLoadingContent(false);
    } catch (err) {
      showSnackBar({
        title: 'securityToolsSection.messages.fetchError',
        description: 'securityToolsSection.messages.fetchErrorDescription',
        type: 'error',
      });
      setOpenEditDialog(false);
      setIsLoadingContent(false);
    }
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleSaveEditedContent = async () => {
    awaitingErrorRef.current = true;

    const success = await uploadRulesContent(configFilePath, editedContent);

    if (success) {
      awaitingErrorRef.current = false;
      setOpenEditDialog(false);
      showSnackBar({
        title: 'securityToolsSection.messages.configurationSaved',
        description: `${tool.name} configuration saved successfully.`,
        type: 'success',
      });
    } else {
      setTimeout(() => {
        if (awaitingErrorRef.current) {
          awaitingErrorRef.current = false;
          showSnackBar({
            title: 'securityToolsSection.messages.configurationError',
            description: 'securityToolsSection.messages.configurationErrorDescription',
            type: 'error',
          });
        }
      }, 300);
    }
  };

  const validateFileContent = async (content: string): Promise<boolean> => {
    try {
      const isValid = content.length > 0 && (content.includes(':') || content.includes('{'));

      return isValid;
    } catch (err) {
      return false;
    }
  };

  const handleFileUpload = async (file: File): Promise<AxiosResponse | AxiosResponse<UploadFileErrorResponse> | undefined> => {
    try {
      const newFile = new File([file], configFilePath, { type: file.type });

      const response = await uploadFileToCentralizedRepo(newFile);
      await handleProcessUploadResponse(response);
      return response;
    } catch (err) {
      showSnackBar({
        title: 'securityToolsSection.messages.uploadError',
        description: 'securityToolsSection.messages.uploadErrorDescription',
        type: 'error',
      });
      return undefined;
    }
  };

  const handleFileChange = (filename: string) => {
    setFileName(filename);
  };

  const viewButtonContent = (
    <div className={styles.buttonContent}>
      <JitIcon icon={Eye} size={14} style={{ marginRight: '8px' }} />

      <JitText bold size='s' text={`View ${tool.name} Config`} />
    </div>
  );

  const editButtonContent = (
    <div className={styles.buttonContent}>
      <JitIcon icon={EditPen} size={12} style={{ marginRight: '8px' }} />

      <JitText bold size='s' text={`Edit ${tool.name} Config`} />
    </div>
  );

  const editDialogContent = isLoadingContent ? (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      my: 4,
    }}
    >
      <CircularLoadingSpinner />
    </Box>
  ) : (
    <div>
      <Paper
        elevation={0}
        sx={{
          p: 0.5,
          margin: '6px',
        }}
      >
        <MonacoConfigEditor
          content={editedContent}
          isLoading={isLoadingContent}
          onChange={(value) => setEditedContent(value)}
        />
      </Paper>
    </div>
  );

  const editDialogActionButtons = [
    {
      label: 'Cancel',
      handleClick: handleCloseEditDialog,
      variant: ActionButtonVariant.SECONDARY,
    },
    {
      label: 'Save',
      handleClick: handleSaveEditedContent,
      variant: ActionButtonVariant.PRIMARY,
      disabled: isUploadingContent || isLoadingContent,
      isLoading: isUploadingContent,
    },
  ];

  const viewDialogActionButtons = [
    {
      label: 'Close',
      handleClick: handleCloseViewDialog,
      variant: ActionButtonVariant.SECONDARY,
    },
  ];

  const viewDialogContent = isLoadingContent ? (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      my: 4,
    }}
    >
      <CircularLoadingSpinner />
    </Box>
  ) : (
    <Box sx={{ width: '100%' }}>
      <Paper
        elevation={0}
        sx={{
          p: 0.5,
          margin: '6px',
        }}
      >
        <MonacoConfigEditor
          content={fileContent || ''}
          onChange={() => { }}
          readOnly
        />
      </Paper>
    </Box>
  );

  return (
    <JitSidePanelItem title='Custom Configuration'>
      <div className={styles.container}>
        <Box sx={{
          display: 'flex',
          gap: 2,
          mb: 3,
          flexWrap: 'wrap',
        }}
        >
          <JitGradientButton
            onClick={handleViewConfig}
            sx={{
              height: '40px',
              minWidth: '180px',
            }}
          >
            {viewButtonContent}
          </JitGradientButton>

          <JitGradientButton
            onClick={handleEditClick}
            sx={{
              height: '40px',
              minWidth: '180px',
            }}
            variant='outlined'
          >
            {editButtonContent}
          </JitGradientButton>
        </Box>

        <JitText text={`Upload a custom configuration file for ${tool.name}:`} />

        <div className={styles.fileInputContainer}>
          <JitFileInput
            analyticsTitle={`${tool.id}-config`}
            data-testid='config-file-input'
            handleChange={handleFileChange}
            handleUpload={handleFileUpload}
            inputValue={fileName}
            isFileContentValid={validateFileContent}
          />
        </div>

        <CustomDialog
          actionButtons={viewDialogActionButtons}
          content={viewDialogContent}
          isOpen={openViewDialog}
          onClose={handleCloseViewDialog}
          title={`View ${tool.name} Configuration`}
          width='l'
        />

        <CustomDialog
          actionButtons={editDialogActionButtons}
          content={editDialogContent}
          isOpen={openEditDialog}
          onClose={handleCloseEditDialog}
          title={`Edit ${tool.name} Configuration`}
          width='l'
        />
      </div>
    </JitSidePanelItem>
  );
};
