import { useCallback } from 'react';

import { getApiUrls } from 'services/apiUrls';
import { useClient } from 'services/useClient';

export const useTriggerService = () => {
  const { client } = useClient();

  const manualExecute = useCallback(async (itemSlug: string, assetId: string) => {
    const url = getApiUrls.triggerService.manualExecute();
    const data = {
      plan_item_slug: itemSlug,
      assets: [{ id: assetId }],
    };

    return client.post({
      url,
      allowedStatuses: [201, 400],
      requestConfig: {
        data,
      },
    });
  }, [client]);

  const manualBranchScan = useCallback(async (branch: string) => {
    const url = getApiUrls.triggerService.manualBranchScan();

    return client.post({
      url,
      allowedStatuses: [201],
      requestConfig: {
        data: { branch },
      },
    });
  }, [client]);

  return {
    manualExecute,
    manualBranchScan,
  };
};
