import React from 'react';
import { Box, Typography, Stack, Button } from '@mui/material';
import SecurityIcon from '@mui/icons-material/Security';
import CodeIcon from '@mui/icons-material/Code';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BuildIcon from '@mui/icons-material/Build';
import AddIcon from '@mui/icons-material/Add';
import { ExpertiseCardProps } from '../types';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { FlowerIcon } from 'assets';
import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitText } from 'components/JitText/JitText';
import { JitTag } from 'components/JitTag/JitTag';
const ExpertiseCard: React.FC<ExpertiseCardProps> = ({ card, onCustomize }) => {
  return (
    <div
      style={{
        padding: '24px',
        height: '100%',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#1E2235',
        position: 'relative',
      }}
    >
      {card.status === 'active' && (
        <div style={{position: 'absolute', top: 12, right: 12}}>
          <JitTag textSize='xs' text="Added" color="#4CAF50" withBorder/>
        </div>
      )}
      
      {card.status !== 'active' && (
        <Box 
          sx={{ 
            position: 'absolute', 
            top: 12, 
            right: 12,
            color: '#6B7AFF',
            cursor: 'pointer'
          }}
        >
          <AddIcon style={{ color: '#9AA3F4' }} />
        </Box>
      )}

      <Stack direction="row" spacing={2} alignItems="flex-start" sx={{ mb: 2 }}>
        <Box sx={{ 
          p: 1.5, 
          borderRadius: '50%', 
          bgcolor: '#252A3D',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '36px',
          height: '36px'
        }}>
          <JitIcon icon={FlowerIcon} />
        </Box>
        <Box>
          <Typography variant="subtitle2" component="div" fontWeight="medium" sx={{ color: 'white' }}>
            {card.title}
          </Typography>
          <Typography variant="body2" sx={{ mt: 0.5, color: 'rgba(255, 255, 255, 0.7)' }}>
            {card.description}
          </Typography>
        </Box>
      </Stack>

      {card.status === 'active' && (
        <Box sx={{ mt: 'auto', pt: 2 }}>
          <JitGradientButton
            width="100%"
            onClick={onCustomize}
          >
            <JitText text="CUSTOMIZE EXPERTISE" />
          </JitGradientButton>
        </Box>
      )}
    </div>
  );
};

export default ExpertiseCard; 