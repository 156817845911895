import { AdminPortal } from '@frontegg/react';
import { FC, useEffect, useState } from 'react';

import { IConfigurationProps } from '../interfaces/configurations';

import styles from './ContainerScanOnBuildConfiguration.module.scss';

import { JitCheckbox } from 'components/JitCheckbox/JitCheckbox';
import { JitLink } from 'components/JitLink/JitLink';
import { JitText } from 'components/JitText/JitText';
import { config } from 'config';
import { usePlansContext } from 'context/PlansContext/PlansContext';
import colors from 'themes/colors.module.scss';

export const ContainerScanOnBuildConfiguration: FC<IConfigurationProps> = ({ setIsDoneStep, planSlug, planItemSlug, onClose }) => {
  const { plans } = usePlansContext();
  const [checkedSteps, setCheckedSteps] = useState({
    createCredentials: false,
    addSecrets: false,
    integrateCli: false,
  });

  const handleCheckboxChange = (step: keyof typeof checkedSteps) => {
    const newCheckedSteps = {
      ...checkedSteps,
      [step]: !checkedSteps[step],
    };
    setCheckedSteps(newCheckedSteps);
    setIsDoneStep(Object.values(newCheckedSteps).every(Boolean));
  };

  const handleCreateCredentialsClick = () => {
    onClose();
    AdminPortal.show();
  };

  const secretsTextWithLink = (
    <JitText
      color={colors.lightGray}
      components={{
        hyperlink: <JitLink
          color={colors.iris}
          href={config.docsContainerScanSecretsSetup}
          rel='noreferrer'
          target='_blank'
          title='secrets guide'
          underline='none'
        />,
      }}
      size='m'
      text='configurations.containerScanOnBuild.fields.addSecrets.subtitle'
    />
  );

  const integrationTextWithLink = (
    <JitText
      color={colors.lightGray}
      components={{
        hyperlink: <JitLink
          color={colors.iris}
          href={config.docsContainerScanIntegration}
          rel='noreferrer'
          target='_blank'
          title='integration guide'
          underline='none'
        />,
      }}
      size='m'
      text='configurations.containerScanOnBuild.fields.integrateCli.subtitle'
    />
  );

  useEffect(() => {
    const isPlanItemActive = plans[planSlug]?.items?.[planItemSlug]?.is_active;

    if (isPlanItemActive) {
      setCheckedSteps({
        createCredentials: true,
        addSecrets: true,
        integrateCli: true,
      });
    }
  }, [plans, planSlug, planItemSlug]);

  useEffect(() => {
    setIsDoneStep(Object.values(checkedSteps).every(Boolean));
  }, [checkedSteps, setIsDoneStep]);

  return (
    <div className={styles.container}>
      <div className={styles.containerStep}>
        <JitCheckbox
          checked={checkedSteps.createCredentials}
          onChange={() => handleCheckboxChange('createCredentials')}
          style={{ marginRight: '0.5rem' }}
        />

        <div className={styles.containerStepText}>
          <JitText bold text='configurations.containerScanOnBuild.fields.createCredentials.title' />

          <JitText color={colors.lightGray} text='configurations.containerScanOnBuild.fields.createCredentials.subtitle' />

          <div className={styles.createCredentialsDescription}>
            <JitText addUnderlineOnHover color={colors.iris} onClick={handleCreateCredentialsClick} text='configurations.containerScanOnBuild.fields.createCredentials.button' />

            <JitText color={colors.lightGray} text='configurations.containerScanOnBuild.fields.createCredentials.description' />
          </div>
        </div>
      </div>

      <div className={styles.containerStep}>
        <JitCheckbox
          checked={checkedSteps.addSecrets}
          onChange={() => handleCheckboxChange('addSecrets')}
          style={{ marginRight: '0.5rem' }}
        />

        <div className={styles.containerStepText}>
          <JitText bold text='configurations.containerScanOnBuild.fields.addSecrets.title' />

          {secretsTextWithLink}
        </div>
      </div>

      <div className={styles.containerStep}>
        <JitCheckbox
          checked={checkedSteps.integrateCli}
          onChange={() => handleCheckboxChange('integrateCli')}
          style={{ marginRight: '0.5rem' }}
        />

        <div className={styles.containerStepText}>
          <JitText bold text='configurations.containerScanOnBuild.fields.integrateCli.title' />

          {integrationTextWithLink}
        </div>
      </div>
    </div>
  );
};
