import { SecurityToolType } from '../components/SecurityToolsSidebar/SecurityToolsSidebar';
import { SecurityTool } from '../types/SecurityTool';

import { Vendor } from 'types/enums';

export const securityTools: SecurityTool[] = [
  {
    id: 'semgrep',
    name: 'Semgrep',
    description: 'Static analysis tool for finding vulnerabilities in code. Supports Javascript, Typescript, Python, Go, Java, Scala, Kotlin, Swift, Rust, C#, PHP, C, C++, Ruby.',
    category: SecurityToolType.sast,
    vendor: Vendor.SEMGREP,
    isConfigurable: true,
    configUrl: 'https://semgrep.dev',
    learnMoreUrl: 'https://github.com/semgrep/semgrep',
  },
  {
    id: 'npm-audit',
    name: 'npm audit',
    description: 'Security audit for JavaScript and TypeScript projects to identify vulnerabilities in dependencies.',
    category: SecurityToolType.sca,
    vendor: Vendor.NPM_AUDIT,
    isConfigurable: false,
    configUrl: 'https://docs.npmjs.com/cli/v8/commands/npm-audit',
    learnMoreUrl: 'https://docs.npmjs.com/cli/v8/commands/npm-audit',
  },

  {
    id: 'trivy',
    name: 'Trivy',
    description: 'Comprehensive vulnerability scanner for containers and other artifacts, suitable for CI.',
    category: SecurityToolType.container,
    vendor: Vendor.TRIVY,
    isConfigurable: false,
    configUrl: 'https://aquasecurity.github.io/trivy',
    learnMoreUrl: 'https://github.com/aquasecurity/trivy',
  },
  {
    id: 'kics',
    name: 'KICS',
    description: 'Find security vulnerabilities, compliance issues, and infrastructure misconfigurations in IaC (Terraform, Serverless, CloudFormation, etc).',
    category: SecurityToolType.cloud,
    vendor: Vendor.KICS,
    isConfigurable: false,
    configUrl: 'https://kics.io',
    learnMoreUrl: 'https://github.com/Checkmarx/kics',
  },
  {
    id: 'prowler',
    name: 'Prowler',
    description: 'Security tool to perform AWS, GCP and Azure security best practices assessments, audits, incident response, and more.',
    category: SecurityToolType.cloud,
    vendor: Vendor.PROWLER,
    isConfigurable: false,
    configUrl: 'https://prowler.pro',
    learnMoreUrl: 'https://github.com/prowler-cloud/prowler',
  },
  {
    id: 'aws-security-hub',
    name: 'AWS Security Hub',
    description: 'Import AWS Security Hub Findings to centralize security alerts and compliance status.',
    category: SecurityToolType.cloud,
    vendor: Vendor.AWS,
    isConfigurable: false,
    configUrl: 'https://aws.amazon.com/security-hub',
    learnMoreUrl: 'https://docs.aws.amazon.com/securityhub/latest/userguide/what-is-securityhub.html',
  },
  {
    id: 'aws-mfa-checker',
    name: 'AWS MFA Checker',
    description: 'Verify that the users of your AWS accounts have enabled Multi-Factor Authentication (MFA).',
    category: SecurityToolType.cloud,
    vendor: Vendor.AWS,
    isConfigurable: false,
    configUrl: '#',
    learnMoreUrl: '#',
  },
  {
    id: 'zap',
    name: 'OWASP ZAP',
    description: 'The world\'s most widely used web app scanner for finding vulnerabilities in web applications and APIs.',
    category: SecurityToolType.dast,
    vendor: Vendor.ZAP,
    isConfigurable: false,
    configUrl: 'https://www.zaproxy.org',
    learnMoreUrl: 'https://github.com/zaproxy/zaproxy',
  },
  {
    id: 'legitify',
    name: 'Legitify',
    description: 'Detect and remediate misconfigurations and security risks across all your GitHub assets.',
    category: SecurityToolType.compliance,
    vendor: Vendor.GHSECURITY,
    isConfigurable: false,
    configUrl: 'https://legitify.dev',
    learnMoreUrl: 'https://github.com/Legit-Labs/legitify',
  },
  {
    id: 'gosec',
    name: 'Gosec',
    description: 'Golang security checker that inspects source code for security problems by scanning the Go AST.',
    category: SecurityToolType.sast,
    vendor: Vendor.GOSEC,
    isConfigurable: false,
    configUrl: 'https://securego.io',
    learnMoreUrl: 'https://github.com/securego/gosec',
  },
  {
    id: 'gitleaks',
    name: 'Gitleaks',
    description: 'Scan code for hard-coded secrets and keys in text files to prevent credential leaks.',
    category: SecurityToolType.secretScanning,
    vendor: Vendor.GITLEAKS,
    isConfigurable: false,
    configUrl: 'https://gitleaks.io',
    learnMoreUrl: 'https://github.com/zricethezav/gitleaks',
  },
  {
    id: 'trufflehog',
    name: 'TruffleHog',
    description: 'Searches through git repositories for secrets, digging deep into commit history and branches.',
    category: SecurityToolType.secretScanning,
    vendor: Vendor.TRUFFLEHOG,
    isConfigurable: false,
    configUrl: 'https://trufflesecurity.com',
    learnMoreUrl: 'https://github.com/trufflesecurity/trufflehog',
  },
  {
    id: 'nancy',
    name: 'Nancy',
    description: 'Scan your Go dependencies for vulnerabilities to identify and fix security issues.',
    category: SecurityToolType.sca,
    vendor: Vendor.NANCY,
    isConfigurable: false,
    configUrl: 'https://github.com/sonatype-nexus-community/nancy',
    learnMoreUrl: 'https://github.com/sonatype-nexus-community/nancy',
  },
  {
    id: 'osv-scanner',
    name: 'OSV-Scanner',
    description: 'Vulnerability scanner for Python, PHP, and Java dependencies using the Open Source Vulnerabilities database.',
    category: SecurityToolType.sca,
    vendor: Vendor.OSV_SCANNER,
    isConfigurable: false,
    configUrl: 'https://google.github.io/osv-scanner',
    learnMoreUrl: 'https://github.com/google/osv-scanner',
  },
  {
    id: 'kubescape',
    name: 'Kubescape',
    description: 'Kubernetes security platform for K8s manifest files and Helm charts to detect misconfigurations.',
    category: SecurityToolType.cloud,
    vendor: Vendor.KUBESCAPE,
    isConfigurable: false,
    configUrl: 'https://kubescape.io',
    learnMoreUrl: 'https://github.com/kubescape/kubescape',
  },
  {
    id: 'github-mfa-checker',
    name: 'GitHub MFA Checker',
    description: 'Verify that MFA for your GitHub organization is enabled to enhance security.',
    category: SecurityToolType.compliance,
    vendor: Vendor.Github,
    isConfigurable: false,
    configUrl: '#',
    learnMoreUrl: '#',
  },
  {
    id: 'github-bp-checker',
    name: 'GitHub Branch Protection Checker',
    description: 'Verify that GitHub Branch Protection is properly configured to prevent unauthorized changes.',
    category: SecurityToolType.compliance,
    vendor: Vendor.Github,
    isConfigurable: false,
    configUrl: '#',
    learnMoreUrl: '#',
  },
];

