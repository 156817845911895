import Markdown from 'markdown-to-jsx';
import { FC, useMemo } from 'react';

import styles from './SecurityToolHeader.module.scss';

import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitSidePanelItem } from 'components/JitSidePanel/JitSidePanelItem/JitSidePanelItem';
import { JitTag } from 'components/JitTag/JitTag';
import { JitText } from 'components/JitText/JitText';
import { SecurityTool } from 'pages/SecurityToolsPage/types/SecurityTool';
import { getToolCategoryTagArray } from 'pages/SecurityToolsPage/utils/toolCategoryTags';
import { useDetailsByVendor } from 'utils/hooks/useDetailsByVendor';

interface Props {
  tool: SecurityTool;
}

export const SecurityToolHeader: FC<Props> = ({ tool }) => {
  const categoryConfig = getToolCategoryTagArray(tool.category)[0];
  const { getIconByVendor } = useDetailsByVendor();

  const icon = useMemo(() => getIconByVendor(tool.vendor), [tool.vendor, getIconByVendor]);

  return (
    <JitSidePanelItem title='Tool Information'>
      <div className={styles.headerContainer}>
        <div className={styles.logoContainer}>
          {typeof icon === 'string' ? (
            <img alt={tool.name} className={styles.logoImage} src={icon} />
          ) : (
            <JitIcon icon={icon} size={64} />
          )}
        </div>

        <div className={styles.infoContainer}>
          <div className={styles.titleRow}>
            <JitText bold size='xl' text={tool.name} />

            <JitTag
              color={categoryConfig.color}
              text={categoryConfig.text}
            />
          </div>

          <div className={styles.description}>
            <Markdown>
              {tool.description}
            </Markdown>
          </div>
        </div>
      </div>
    </JitSidePanelItem>
  );
};
