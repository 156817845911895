import { useMutation } from 'react-query';

import { runPipelineDemoVulnerability } from './CiService';

import { tryRun } from 'services/client';
import { IRunPipelineDemoRequest } from 'types/interfaces/Ci';
import { useSnackBar } from 'utils/hooks/useSnackBar';

export const useRunPipelineDemoVulnerability = () => {
  const snackbar = useSnackBar();
  return useMutation({
    mutationFn: (request: IRunPipelineDemoRequest) => tryRun(() => runPipelineDemoVulnerability(request)),
    onError: () => {
      snackbar.showSnackBar({
        title: 'toasts.demoPRCreated.error.title',
        type: 'error',
        description: 'toasts.demoPRCreated.error.description',
      });
    },

  });
};
