import { FC } from 'react';
import { Typography, Stack } from '@mui/material';

export const JittoTasksHeader: FC = () => {
  return (
    <div style={{ margin: '24px 14px'}}>
        <Stack spacing={1}>
          <Typography 
            sx={{ 
              fontSize: '1.2rem',
              fontWeight: 600,
              color: 'text.primary',
            }}
          >
            Tasks
          </Typography>
          
          <Typography 
            variant="body1" 
            sx={{ 
              fontSize: '0.9375rem', // 15px
              color: 'text.secondary',
              maxWidth: '700px',
            }}
          >
            View and manage agent tasks. Monitor automated processes and check their status.
          </Typography>
        </Stack>
    </div>
  );
}; 