import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ExploreResultsEmpty } from './components/ExploreResultsEmpty/ExploreResultsEmpty';
import { LoadingOverlay } from './components/LoadingOverlay/LoadingOverlay';
import styles from './ExploreResults.module.scss';
import { useExploreFindings } from './hooks/useExploreFindings';

import { CircularLoadingSpinner } from 'components/CircularLoadingSpinner/CircularLoadingSpinner';
import { FindingRow } from 'components/FindingRow/FindingRow';
import { JitText } from 'components/JitText/JitText';
import { EXPLORE_FINDINGS_CHECK_SLUG } from 'context/TenantContext/constant';
import { useUpdateCheckStatus } from 'context/TenantContext/tenantHooks/useUpdateCheckStatus';
import { constants } from 'globalConstants';
import { CheckStatus } from 'pages/QuickStartGuide/types';
import colors from 'themes/colors.module.scss';

export const ExploreResults: FC = () => {
  const { updateCheckStatus } = useUpdateCheckStatus();
  const { findings, isInitialScanComplete, isLoading, isMockData } = useExploreFindings();
  const navigate = useNavigate();

  const handleExploreClick = useCallback(() => {
    updateCheckStatus({
      checkSlug: EXPLORE_FINDINGS_CHECK_SLUG,
      status: CheckStatus.COMPLETED,
    });
    navigate(`/${constants.routes.FINDINGS}`);
  }, [updateCheckStatus, navigate]);

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <CircularLoadingSpinner />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {findings.length === 0 ? (
        <ExploreResultsEmpty />
      ) : (
        <div className={styles.findings}>
          {findings.map((finding) => (
            <FindingRow key={finding.id} finding={finding} />
          ))}
        </div>
      )}

      {!isInitialScanComplete && isMockData && <LoadingOverlay />}

      {isInitialScanComplete && <JitText color={colors.iris} data-testid='explore-all-findings-button' onClick={handleExploreClick} text='pages.quickStart.checks.exploreResults.button' />}
    </div>
  );
};
