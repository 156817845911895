import { CSSProperties, FC, ReactElement } from 'react';

import styles from './InsightsCard.module.scss';

import { CircleInfo, CircleQuestion } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import colors from 'themes/colors.module.scss';
import { hexColorToRGBA } from 'utils';
import { TextSize } from 'utils/constants/textSizeMap';

export type InsightsCardSize = 's' | 'm' | 'l';
export type InsightsCardVariant = 'top' | 'bottom' | 'light';

interface CardHeader {
  title: string;
  infoTooltip?: string;
}

export interface InsightsCardProps {
  header: CardHeader;
  subTitle?: string;
  content: ReactElement;
  footer?: ReactElement;
  size?: InsightsCardSize;
  variant?: InsightsCardVariant;
  className?: string;
  onClick?: () => void;
}

const sizeToStyle = {
  s: { minWidth: '246px' },
  m: { minWidth: '260px' },
  l: { minWidth: '300px' },
};

const variantToStyle: {
  [key: string]: {
    wrapper: { borderRadius: string; background?: string };
    headerSize: TextSize;
    headerStyle: CSSProperties;
  };
} = {
  top: {
    wrapper: { borderRadius: '10px 10px 0 0' },
    headerSize: 'l',
    headerStyle: {},
  },
  bottom: {
    wrapper: { borderRadius: '0 0 10px 10px' },
    headerSize: 'l',
    headerStyle: {},
  },
  light: {
    wrapper: {
      borderRadius: '10px',
      background: hexColorToRGBA(colors.cards, 0.6),
    },
    headerSize: 'm',
    headerStyle: { justifyContent: 'space-between' },
  },
};

const variantToIcon = {
  top: CircleInfo,
  bottom: CircleInfo,
  light: CircleQuestion,
};

export const InsightsCard: FC<InsightsCardProps> = ({ header, subTitle, content, footer, size = 's', variant, className, onClick }) => (
  <div
    className={`${styles.wrapper} ${className}`}
    data-testid='InsightsCard'
    style={variant ? variantToStyle[variant].wrapper : {}}
    onClick={onClick}
  >
    <div
      className={styles.contentWrapper}
      data-testid='InsightsCard-contentWrapper'
      style={sizeToStyle[size]}
    >
      <div
        className={styles.header}
        style={variant ? variantToStyle[variant].headerStyle : {}}
      >
        <JitText bold data-testid='InsightsCard-header' size={variant ? variantToStyle[variant].headerSize : 'l'} text={header.title} />

        {header.infoTooltip && (
          <JitTooltip
            childrenWrapperClassName={styles.infoIcon}
            data-testid='InsightsCard-tooltip'
            followCursor={false}
            placement='top'
            title={header.infoTooltip}
            withHighlightShadow
          >
            <JitIcon color={colors.gray} icon={variant ? variantToIcon?.[variant] : CircleInfo} size={15} />
          </JitTooltip>
        )}
      </div>

      {subTitle && (<JitText muted size='s' text={subTitle} />)}

      <div className={styles.content} data-testid='InsightsCard-content'>
        {content}
      </div>

      {footer && (
        <div data-testid='InsightsCard-footer'>
          {footer}
        </div>
      )}
    </div>
  </div>
);
