import { isEmpty } from 'lodash';
import { FC, useMemo } from 'react';

import styles from './FindingsTable.module.scss';

import { RowActions } from 'components/FindingsTable/components/RowActions/RowActions';
import { useFindingTableContext } from 'components/FindingsTable/FindingsTableContext/FindingsTableContext';
import { IgnoreFindingsDialog } from 'components/IgnoreFindingsDialog/IgnoreFindingsDialog';
import { JitTable } from 'components/JitTable/JitTable';
import { FindingsSelectedFindings, FindingDetailsPanel } from 'pages/FindingsPage/components';
import { EmptyFindingsTable } from 'pages/FindingsPage/components/EmptyFindingTable/EmptyFindingsTable';
import { MAX_FINDINGS_IN_PAGE } from 'pages/FindingsPage/constants';

interface Props {
  isLoading?: boolean;
  EmptyTableView?: FC;
}

export const FindingsTableContent: FC<Props> = ({ isLoading, EmptyTableView }) => {
  const {
    totalFindingsAmount,
    onSelectRow,
    tableInstance,
    paginationManager,
    selectedRow,
    limit,
    selectedFindingIds,
    displayIgnoreByType,
    completeIgnoreFindings,
    isIgnoreLoading,
    onDialogClose,
    isIgnoreFindingsDialogOpen,
    findingsToIgnore,
    hasFetchedFindings,
  } = useFindingTableContext();
  const displaySelectedFindings = useMemo(() => !isEmpty(selectedFindingIds) && !paginationManager.isLoading, [selectedFindingIds,
    paginationManager.isLoading]);

  const displayLoading = isLoading || paginationManager.isLoading || !hasFetchedFindings;
  const emptyTableView = !displayLoading ? (EmptyTableView || EmptyFindingsTable) : undefined;

  return (
    <div className={styles.container}>
      <div className={styles.findingsTable}>
        <JitTable
          cellPadding='0px 12px'
          className={styles.tableWrapper}
          ElementToShowOnRowHover={RowActions}
          EmptyTableView={emptyTableView}
          headerCellPadding='0'
          isLoading={displayLoading}
          onSelectRow={onSelectRow}
          pageMaxLength={MAX_FINDINGS_IN_PAGE}
          selectedRow={selectedRow}
          showPagination={!limit}
          tableInstance={tableInstance}
          totalRecordsAmount={totalFindingsAmount || undefined}
        />
      </div>

      <FindingDetailsPanel />

      {displaySelectedFindings && (
        <FindingsSelectedFindings />
      )}

      <IgnoreFindingsDialog
        displayIgnoreByType={displayIgnoreByType}
        findingType={displayIgnoreByType ? findingsToIgnore[0].name : undefined}
        ignoreFunction={completeIgnoreFindings}
        isIgnoredLoading={isIgnoreLoading}
        onClose={onDialogClose}
        open={isIgnoreFindingsDialogOpen}
      />
    </div>
  );
};
