import { FC, useMemo } from 'react';

import styles from './EmptyResourcePage.module.scss';

import { Link, PremiumLock, ResourcesEmptyBG } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitLink } from 'components/JitLink/JitLink';
import { JitText } from 'components/JitText/JitText';
import { JitUpgradeButton } from 'components/JitUpgradeButton/JitUpgradeButton';
import { EmptyPage } from 'components/Pages/EmptyPage/EmptyPage';
import { config } from 'config';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';

interface ITitlesTexts {
  title: string;
  subtitle1: string;
  subtitle2: string;
  contactus: string;
}

const EMPTY_PAGE_TYPE = 'notEntitled';

export const EmptyResourcePage: FC = () => {
  const { t } = i18n;

  const labels: ITitlesTexts = useMemo(
    () => t(`pages.risks.resources.emptyPage.${EMPTY_PAGE_TYPE}`, { returnObjects: true }),
    [t],
  );

  const upgradeNowTexts = useMemo(
    () => t('common.pricingPlan.upgradeNow').toUpperCase(),
    [t],
  );

  const bgImage = useMemo(() => <ResourcesEmptyBG />, []);

  const Content = useMemo(
    () => (
      <div className={styles.content} data-testid='empty-resources-page-content'>
        <div className={styles.title}>
          <JitIcon icon={PremiumLock} size={20} />

          <JitText className={styles.contentTitle} size='xxl' text={labels.title} />
        </div>

        <div className={styles.subtitle}>
          <JitText muted text={labels.subtitle1} />

          <JitText muted text={labels.subtitle2} />
        </div>

        <JitUpgradeButton text={upgradeNowTexts} />
      </div>
    ),
    [labels, upgradeNowTexts],
  );

  const Footer = (
    <JitLink
      className={styles.footer}
      color={colors.blue02}
      href={config.contactUsUrl}
      rel='noreferrer'
      target='_blank'
      title={labels.contactus}
      underline='hover'
    >
      <JitText color={colors.iris} text='common.pricingPlan.extendFreeTrial' />

      <JitIcon color={colors.iris} icon={Link} size={12} />
    </JitLink>
  );

  return <EmptyPage bgClassName={styles.bgImage} bgImage={bgImage} content={Content} footer={Footer} />;
};
