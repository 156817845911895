import { useMemo } from 'react';

import { CustomNodeType, CustomEdgeType, getFactor } from '../types';

export const PRIORITY_FACTORS_NODES_TYPES = [
  'Severity',
  'Medium Severity',
  'High Severity',
  'Critical Severity',
  'Fix Available',
  'FixAvailable',
  'Non-Production',
  'Administrator',
  'Business Critical',
  'Authentication',
  'Credentials & Secrets',
  'Database Integration',
  'Externally Accessible',
  'Infrastructure',
  'Measurement Tools',
  'Payment',
  'Production',
  'Test File',
  'Testing Tools',
  'BusinessRole',
  'Associated External Source Finding',
];
export const usePatchFinalGraph = (nodes: CustomNodeType[], edges: CustomEdgeType[]) => {
  // TODO: This is a term solution to remove node of a factor, will be resolved in the BE soon.
  const nodesAfterPatch: CustomNodeType[] = useMemo(
    () => {
      const patchedNodes = nodes.map((node) => {
        if (PRIORITY_FACTORS_NODES_TYPES.includes(node.data.type)) return null;
        const relatedFactorNodes = nodes.filter((n) => {
          if (!PRIORITY_FACTORS_NODES_TYPES.includes(n.data.type)) return false;

          const relatedEdge = edges.find((edge) => edge.target === n.id);
          return node.id === relatedEdge?.source;
        });
        if (!relatedFactorNodes.length) return node;
        const factorsToAdd = relatedFactorNodes.map((n) => n.data.factors || [])
          .flat();
        // eslint-disable-next-line no-param-reassign
        node.data.factors = [...new Set([...(node.data.factors || []), ...factorsToAdd].map(getFactor))];
        return node;
      });
      const filteredNodes: CustomNodeType[] = patchedNodes.filter((node: CustomNodeType | null): node is CustomNodeType => node !== null);
      return filteredNodes;
    },
    [edges, nodes],
  );

  // TODO: This is a term solution to remove node of a factor, will be resolved in the BE soon.
  const edgesAfterPatch: CustomEdgeType[] = useMemo(() => {
    const nodesIds = nodesAfterPatch.map((node) => node.id);
    return edges.filter((edge) => nodesIds.includes(edge.target));
  }, [edges, nodesAfterPatch]);

  return {
    nodesAfterPatch,
    edgesAfterPatch,
  };
};
