import { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import styles from './RisksBreadcrumbs.module.scss';

import { JitBreadcrumbs, BreadcrumbItem } from 'components/JitBreadcrumbs/JitBreadcrumbs';
import { renderLinkBreadCrumbItem, renderTextBreadCrumbItem } from 'components/JitBreadcrumbs/utils/textToBreadcrumbContent';
import { JitText } from 'components/JitText/JitText';
import { useContextEngine } from 'context/GraphContext/hooks/useContextEngine';
import { constants } from 'globalConstants';
import { RiskPagesDropdown } from 'pages/Resources/components/RisksBreadcrumbs/RiskPagesDropdown/RiskPagesDropdown';
import { IAsset } from 'types/interfaces';
import { getAssetDisplayName } from 'utils/constants/assetTypeConfigurations/assetTypeConfigurations';

const { BASE_ROUTE, RESOURCES, BACKLOG, RESOURCES_LIST } = constants.routes.risks;

export const DASHBOARD_ROUTE = `/${BASE_ROUTE}/${RESOURCES}`;
export const RESOURCES_ROUTE_BASE = `/${BASE_ROUTE}/${RESOURCES}/${RESOURCES_LIST}`;
export const BACKLOG_ROUTE = `/${BASE_ROUTE}/${BACKLOG}`;

interface Props {
  asset?: IAsset;
}

export const RisksBreadcrumbs: FC<Props> = ({ asset }) => {
  const location = useLocation();
  const { isContextEngineEnabled } = useContextEngine();

  const currentPath = location.pathname;
  const isInSpecificResourcePage = !!asset;

  const items: BreadcrumbItem[] = useMemo(() => {
    const breadcrumbItems: BreadcrumbItem[] = [
      {
        content: <RiskPagesDropdown useDashboardPage={isContextEngineEnabled} />,
        itemKey: 'resourcesPage',
      },
    ];

    if (currentPath.includes(RESOURCES_ROUTE_BASE)) {
      if (isInSpecificResourcePage) {
        if (isContextEngineEnabled) {
          breadcrumbItems.push({
            content: renderLinkBreadCrumbItem(
              'pages.risks.breadcrumbs.resources',
              RESOURCES_ROUTE_BASE,
            ),
            itemKey: 'resourcesPage',
          });
        }

        const assetName = getAssetDisplayName(asset);
        breadcrumbItems.push({
          content: renderTextBreadCrumbItem(assetName),
          itemKey: 'assetName',
        });
      } else if (isContextEngineEnabled) {
        breadcrumbItems.push({
          content: renderTextBreadCrumbItem('pages.risks.breadcrumbs.resources'),
          itemKey: 'resourcesPage',
        });
      }
    }

    return breadcrumbItems;
  }, [isContextEngineEnabled, currentPath, isInSpecificResourcePage, asset]);

  return (
    <div className={styles.wrapper}>

      <JitText horizontalAlign='center' size='l' text='pages.risks.breadcrumbs.riskPrefix' />

      <JitBreadcrumbs
        items={items}
      />
    </div>
  );
};
