import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { 
  JittoContext, 
  JittoContextType, 
  Message, 
  Conversation,
} from './JittoContext';
import { MOCK_CONVERSATIONS } from './mockData/mockJittoConversations';
import { useLocation, useNavigate } from 'react-router-dom';

export const JittoProvider: FC<PropsWithChildren> = ({ children }) => {
  const [conversations, setConversations] = useState<Conversation[]>(MOCK_CONVERSATIONS);
  const [currentDrilldownItemId, setCurrentDrilldownItemId] = useState<string | number | null>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const currentConversationId = useMemo(() => {
    const pathname = window.location.pathname;
    return pathname.includes('chat/') ? pathname.split('/').pop() : undefined;
  }, [location.pathname]);

  const setCurrentConversationId = useCallback((conversationId: string) => {
    navigate(`/chat/${conversationId}`);
  }, []);

  const currentConversation = useMemo(() => 
    conversations.find(conv => conv.id === currentConversationId),
    [conversations, currentConversationId]
  );
  const isChatOpen = !!currentConversation?.report;
  
  const addConversation = useCallback((conversation: Conversation) => {
    setConversations(prevConversations => [...prevConversations, conversation]);
    setCurrentConversationId(conversation.id);
  }, []);

  const updateConversation = useCallback((conversation: Partial<Conversation> & { id: string }) => {
    setConversations(prevConversations => prevConversations.map(conv => conv.id === conversation.id ? { ...conv, ...conversation } : conv));
  }, []);

  const addMessage = useCallback((conversationId: string, message: Message) => {
    setConversations(prevConversations => 
      prevConversations.map(conv => 
        conv.id === conversationId 
          ? { ...conv, messages: [...conv.messages, message] }
          : conv
      )
    );
  }, [setConversations]);

  const updateLastMessage = useCallback((conversationId: string, additionalContent: Message['content']) => {
    setConversations(prevConversations => {
      const updatedConversation = {...prevConversations.find(conv => conv.id === conversationId)!};
      const lastMessage = updatedConversation.messages[updatedConversation.messages.length - 1];
      const updatedLastMessage = {...lastMessage, content: [...lastMessage.content, ...additionalContent]};
      updatedConversation.messages = [...updatedConversation.messages.slice(0, -1), updatedLastMessage];
      return prevConversations.map(conv => conv.id === conversationId ? updatedConversation : conv);
    });
  }, [setConversations]);


  // Create the context value with all state and handlers
  const contextValue = useMemo<JittoContextType>(() => ({
    // State
    conversations,
    currentConversation,
    isChatOpen,
    currentDrilldownItemId,
    
    // Methods
    addConversation,
    addMessage,
    setCurrentDrilldownItemId,
    updateConversation,
    updateLastMessage
  }), [
    conversations,
    currentConversation,
    isChatOpen,
    currentDrilldownItemId,
    addConversation,
    addMessage,
    setCurrentDrilldownItemId,
    updateConversation,
    updateLastMessage
  ]);

  return (
    <JittoContext.Provider value={contextValue}>
      {children}
    </JittoContext.Provider>
  );
}; 