import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { fetchFindingsCount, fetchFindings } from './FindingsService';
import * as keys from './keys';

import { Queries } from 'types/enums/Queries';
import { ITableQueryParams } from 'types/interfaces/Table/ITableQueryParams';

export const useFindingsCount = (props: keys.FindingsCountProps, opts?: { enabled?: boolean }) => useQuery({
  queryFn: () => fetchFindingsCount(props.params, props.backlog),
  queryKey: keys.findingsCount(props),
  ...opts,
});

export const useFindingById = (id: string | undefined, opts?: { enabled?: boolean }) => useQuery({
  queryFn: async () => {
    const response = await fetchFindings({
      filters: {
        id: id || '',
      },
    });
    return response.data[0];
  },
  queryKey: keys.findingById(id),
  ...opts,
  enabled: !!id && opts?.enabled,
});

interface UseFindingsProps {
  params: ITableQueryParams;
  enabled?: boolean;
}

export const useFindings = ({ params, enabled = true }: UseFindingsProps) => useQuery({
  queryKey: [Queries.Findings, params],
  queryFn: () => fetchFindings(params),
  enabled,
});

export const useInvalidateFindings = () => {
  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient.invalidateQueries(keys.base);
  }, [queryClient]);
};

