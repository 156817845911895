import { FC, useState, useMemo, useEffect } from 'react';
import { Typography, Box, Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from '@mui/material';
import styled from '@mui/material/styles/styled';
import { startCase } from 'lodash';
import { useSearchParams } from 'react-router-dom';

// Import JitFilters component
import { JitFilters } from 'components/JitFilters/JitFilters';
import { JitTag } from 'components/JitTag/JitTag';
import { JittoTaskSteps, TaskStep } from '../components/JittoTaskSteps';
import { IFilter, IFilterOption, IFilterType } from 'types/interfaces/IFilter';
import colors from 'themes/colors.module.scss';

// Styled components
const SectionTitle = styled(Typography)({
  fontSize: '16px',
  fontWeight: 500,
  color: '#fff',
  marginBottom: '16px',
});

const InsightCard = styled(Box)({
  backgroundColor: '#212839',
  borderRadius: '8px',
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
});

const CardTitle = styled(Typography)({
  fontSize: '14px',
  color: 'rgba(255, 255, 255, 0.7)',
  marginBottom: '8px',
  textAlign: 'center',
});

const CardValue = styled(Typography)({
  fontSize: '32px',
  fontWeight: 'bold',
  color: '#FFFFFF',
  textAlign: 'center',
});

const TaskTable = styled(TableContainer)({
  backgroundColor: '#1E2234',
  borderRadius: '8px',
  marginTop: '16px',
  overflow: 'auto',
  maxHeight: 'calc(100vh - 550px)',
});

const StyledTableHead = styled(TableHead)({
  backgroundColor: '#1A1E2D',
});

const HeaderCell = styled(TableCell)({
  color: 'rgba(255, 255, 255, 0.7)',
  fontSize: '14px',
  fontWeight: 500,
  padding: '16px',
  borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
});

const BodyCell = styled(TableCell)({
  color: '#fff',
  fontSize: '14px',
  padding: '16px',
  borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
});

const Section = styled(Box)({
  backgroundColor: '#121926',
  borderRadius: '8px',
  padding: '24px',
  marginBottom: '24px',
});

// Add sidebar styled components
const Sidebar = styled(Box)({
  position: 'fixed',
  top: 0,
  right: 0,
  width: '70%',
  height: '100vh',
  backgroundColor: '#0F1118',
  borderLeft: '1px solid rgba(255, 255, 255, 0.1)',
  zIndex: 1300,
  overflow: 'auto',
  boxShadow: '-5px 0 15px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease-in-out',
  transform: 'translateX(100%)',
  '&.open': {
    transform: 'translateX(0)',
  },
});

const SidebarHeader = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  padding: '24px',
  borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
});

const TaskTitle = styled(Typography)({
  fontSize: '18px',
  fontWeight: 500,
  color: '#fff',
  marginBottom: '12px',
});

const TaskDescription = styled(Typography)({
  fontSize: '14px',
  color: 'rgba(255, 255, 255, 0.7)',
  marginBottom: '16px',
});

const StepContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px 24px',
});

const CloseButton = styled(IconButton)({
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
});

// Add a component wrapper for the entire page with scrolling
const PageWrapper = styled(Box)({
  height: 'calc(100vh - 80px)',
  overflow: 'auto',
  padding: '16px',
});

// Keep header sticky
const StickyHeader = styled(TableHead)({
  position: 'sticky',
  top: 0,
  zIndex: 10,
});

// Add a background overlay component
const Overlay = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 1200,
  display: 'none',
  '&.visible': {
    display: 'block',
  },
});

export const JittoTasksBody: FC = () => {
  // Add useSearchParams hook
  const [searchParams, setSearchParams] = useSearchParams();
  // Add filters state
  const [filters, setFilters] = useState<IFilter[]>([]);
  // Add selected task state
  const [selectedTask, setSelectedTask] = useState<any | null>(null);
  
  // Mock data for tasks history
  const tasksData = [
    {
      id: 'task-1',
      title: 'Security vulnerability scan',
      date: 'Jan 15, 2024',
      duration: '14 min',
      timeSaved: '2h 30m',
      status: 'completed',
      agent: 'sera',
      steps: [
        { 
          id: 'step-1-1', 
          title: 'Environment Analysis', 
          status: 'completed', 
          icon: 'analyze',
          description: 'Scan of cloud infrastructure components and configuration settings to identify security vulnerabilities.',
          tools: ['CloudScan', 'ConfigAnalyzer', 'NetworkMapper'],
          sources: ['AWS Infrastructure']
        },
        { 
          id: 'step-1-2', 
          title: 'Vulnerability Detection', 
          status: 'completed', 
          icon: 'security',
          tools: ['CVE Database'],
          sources: []
        },
        { 
          id: 'step-1-3', 
          title: 'Risk Assessment', 
          status: 'completed', 
          icon: 'assess',
          description: 'Evaluation of detected vulnerabilities based on severity, exploitability, and potential impact.',
          tools: ['RiskMatrix', 'CVSS Calculator'],
          sources: ['Industry Benchmarks', 'Historical Data', 'Risk Framework']
        },
        { 
          id: 'step-1-4', 
          title: 'Report Generation', 
          status: 'completed', 
          icon: 'document',
          tools: [],
          sources: ['Analysis Results', 'Remediation Database']
        }
      ]
    },
    {
      id: 'task-2',
      title: 'Compliance audit - AWS',
      date: 'Jan 15, 2024',
      duration: '14 min',
      timeSaved: '2h 30m',
      status: 'failed',
      agent: 'rica',
      steps: [
        { 
          id: 'step-2-1', 
          title: 'Compliance Framework Mapping', 
          status: 'completed', 
          icon: 'map',
          tools: ['FrameworkMapper'],
          sources: ['NIST 800-53', 'ISO 27001']
        },
        { 
          id: 'step-2-2', 
          title: 'Configuration Analysis', 
          status: 'completed', 
          icon: 'analyze',
          description: 'Deep inspection of AWS configuration settings against compliance requirements and security best practices.',
          tools: [],
          sources: ['AWS API', 'CloudFormation Templates', 'Service Configs']
        },
        { 
          id: 'step-2-3', 
          title: 'Security Control Validation', 
          status: 'failed', 
          icon: 'validate',
          description: 'Verification of security controls implementation. Failed due to missing encryption for data at rest.',
          tools: ['ControlValidator', 'SecurityGuard', 'PolicyChecker'],
          sources: ['AWS IAM Policies']
        },
        { 
          id: 'step-2-4', 
          title: 'Compliance Report', 
          status: 'pending', 
          icon: 'document',
          tools: ['ComplianceReporter', 'GapAnalyzer'],
          sources: []
        }
      ]
    },
    {
      id: 'task-3',
      title: 'Security patch verification',
      date: 'Jan 15, 2024',
      duration: '8 min',
      timeSaved: '2h 30m',
      status: 'waiting_for_approval',
      agent: 'sera',
      steps: [
        { 
          id: 'step-3-1', 
          title: 'Patch Inventory', 
          status: 'completed', 
          icon: 'list',
          tools: ['PatchDB', 'VersionTracker'],
          sources: ['Vendor Updates']
        },
        { 
          id: 'step-3-2', 
          title: 'Environment Scan', 
          status: 'completed', 
          icon: 'scan',
          description: 'Comprehensive scan of all system components to determine current patch levels and identify missing updates.',
          tools: ['SystemScanner'],
          sources: ['OS Configurations', 'Application Inventories', 'Dependency Trees']
        },
        { 
          id: 'step-3-3', 
          title: 'Patch Validation', 
          status: 'completed', 
          icon: 'check',
          tools: [],
          sources: []
        },
        { 
          id: 'step-3-4', 
          title: 'Security Impact Analysis', 
          status: 'waiting_for_approval', 
          icon: 'security', 
          requiresConsent: true,
          description: 'Analysis of potential security impacts from applying or not applying critical patches. Requires approval to proceed with recommended actions.',
          tools: ['ImpactAnalyzer', 'RiskCalculator', 'ScenarioModeler'],
          sources: ['CVE Database']
        }
      ]
    },
    {
      id: 'task-4',
      title: 'Automated incident triage',
      date: 'Jan 14, 2024',
      duration: '22 min',
      timeSaved: '3h 45m',
      status: 'completed',
      agent: 'cota',
      steps: [
        { 
          id: 'step-4-1', 
          title: 'Alert Collection', 
          status: 'completed', 
          icon: 'alert',
          tools: ['AlertAggregator'],
          sources: ['SIEM Alerts', 'EDR Events']
        },
        { 
          id: 'step-4-2', 
          title: 'Severity Classification', 
          status: 'completed', 
          icon: 'classify',
          tools: ['ML Classifier', 'SeverityRater'],
          sources: []
        },
        { 
          id: 'step-4-3', 
          title: 'Impact Assessment', 
          status: 'completed', 
          icon: 'assess',
          tools: [],
          sources: ['CMDB']
        },
        { 
          id: 'step-4-4', 
          title: 'Response Orchestration', 
          status: 'completed', 
          icon: 'orchestrate',
          tools: ['PlaybookRunner', 'AutoResponse', 'RemediationEngine'],
          sources: ['Response Playbooks', 'Runbooks']
        },
        { 
          id: 'step-4-5', 
          title: 'Post-Incident Analysis', 
          status: 'completed', 
          icon: 'analyze',
          tools: ['RootCauseAnalyzer'],
          sources: ['Incident Timeline', 'Response Actions', 'System Changes']
        }
      ]
    },
    {
      id: 'task-5',
      title: 'IAM permissions review',
      date: 'Jan 14, 2024',
      duration: '17 min',
      timeSaved: '1h 50m',
      status: 'completed',
      agent: 'sera',
      steps: [
        { 
          id: 'step-5-1', 
          title: 'Permissions Collection', 
          status: 'completed', 
          icon: 'data',
          tools: [],
          sources: ['IAM Policies', 'Role Definitions']
        },
        { 
          id: 'step-5-2', 
          title: 'Least Privilege Analysis', 
          status: 'completed', 
          icon: 'analyze',
          tools: ['PrivilegeAnalyzer', 'AccessPatternDetector'],
          sources: ['Access Logs']
        },
        { 
          id: 'step-5-3', 
          title: 'Risk Identification', 
          status: 'completed', 
          icon: 'risk',
          tools: ['RiskScorer'],
          sources: ['Best Practices', 'Compliance Frameworks']
        },
        { 
          id: 'step-5-4', 
          title: 'Recommendations Report', 
          status: 'completed', 
          icon: 'document',
          tools: ['ReportBuilder', 'RecommendationEngine', 'PolicyGenerator'],
          sources: []
        }
      ]
    },
    {
      id: 'task-6',
      title: 'Code vulnerability assessment',
      date: 'Jan 13, 2024',
      duration: '25 min',
      timeSaved: '4h 15m',
      status: 'completed',
      agent: 'sera',
      steps: [
        { 
          id: 'step-6-1', 
          title: 'Repository Scan', 
          status: 'completed', 
          icon: 'scan',
          tools: [],
          sources: []
        },
        { 
          id: 'step-6-2', 
          title: 'Dependency Analysis', 
          status: 'completed', 
          icon: 'analyze',
          tools: ['DependencyCheck', 'CompositionAnalyzer'],
          sources: ['Package Manifests']
        },
        { 
          id: 'step-6-3', 
          title: 'Vulnerability Detection', 
          status: 'completed', 
          icon: 'security',
          tools: ['SAST Scanner'],
          sources: ['CWE Database', 'OWASP Top 10', 'Language-specific Rules']
        },
        { 
          id: 'step-6-4', 
          title: 'Prioritization', 
          status: 'completed', 
          icon: 'priority',
          tools: ['VulnPrioritizer', 'ExploitabilityCalculator', 'ImpactAssessor'],
          sources: ['CVSS Scores']
        },
        { 
          id: 'step-6-5', 
          title: 'Remediation Guidance', 
          status: 'completed', 
          icon: 'fix',
          tools: ['FixAdvisor'],
          sources: []
        }
      ]
    },
    {
      id: 'task-7',
      title: 'Compliance gap analysis',
      date: 'Jan 13, 2024',
      duration: '35 min',
      timeSaved: '5h 20m',
      status: 'waiting_for_approval',
      agent: 'rica',
      steps: [
        { 
          id: 'step-7-1', 
          title: 'Framework Selection', 
          status: 'completed', 
          icon: 'select',
          tools: ['FrameworkCatalog', 'ComplianceSelector'],
          sources: []
        },
        { 
          id: 'step-7-2', 
          title: 'Control Mapping', 
          status: 'completed', 
          icon: 'map',
          tools: [],
          sources: ['Control Catalogs', 'Framework Documentation']
        },
        { 
          id: 'step-7-3', 
          title: 'Evidence Collection', 
          status: 'in_progress', 
          icon: 'data',
          tools: ['EvidenceCollector'],
          sources: ['System Configurations']
        },
        { 
          id: 'step-7-4', 
          title: 'Gap Identification', 
          status: 'pending', 
          icon: 'analyze',
          tools: ['GapDetector', 'ComplianceAnalyzer'],
          sources: ['Control Implementation', 'Evidence Repository', 'Compliance Criteria']
        },
        { 
          id: 'step-7-5', 
          title: 'Remediation Plan', 
          status: 'waiting_for_approval', 
          icon: 'plan', 
          requiresConsent: true,
          tools: [],
          sources: ['Gap Analysis', 'Best Practices']
        }
      ]
    },
    {
      id: 'task-8',
      title: 'Alert correlation analysis',
      date: 'Jan 12, 2024',
      duration: '12 min',
      timeSaved: '1h 45m',
      status: 'failed',
      agent: 'cota',
      steps: [
        { 
          id: 'step-8-1', 
          title: 'Data Collection', 
          status: 'completed', 
          icon: 'data',
          tools: ['DataCollector', 'LogAggregator'],
          sources: ['SIEM Logs']
        },
        { 
          id: 'step-8-2', 
          title: 'Pattern Recognition', 
          status: 'completed', 
          icon: 'analyze',
          tools: ['PatternDetector'],
          sources: []
        },
        { 
          id: 'step-8-3', 
          title: 'Correlation Engine', 
          status: 'failed', 
          icon: 'process',
          tools: [],
          sources: ['Temporal Data', 'Causal Relationships']
        },
        { 
          id: 'step-8-4', 
          title: 'Insight Generation', 
          status: 'waiting_for_approval', 
          icon: 'insight', 
          requiresConsent: true,
          tools: ['InsightExtractor'],
          sources: ['Correlation Results']
        }
      ]
    }
  ];
  
  // Stats data
  const statsData = [
    {
      title: 'Tasks Completed',
      value: '148'
    },
    {
      title: 'Time Saved',
      value: '23h 45m'
    },
    {
      title: 'Cost Savings',
      value: '$3,470'
    },
  ];
  
  // Initialize filters based on task data
  useMemo(() => {
    if (filters.length === 0) {
      // Create status filter
      const statusOptions: IFilterOption[] = [
        { value: 'completed', displayText: 'Completed' },
        { value: 'failed', displayText: 'Failed' },
        { value: 'in_progress', displayText: 'In Progress' },
        { value: 'waiting_for_approval', displayText: 'Waiting for approval' },
      ];
      
      // Create agent filter
      const agents = [...new Set(tasksData.map(task => task.agent))];
      const agentOptions: IFilterOption[] = agents.map(agent => ({
        value: agent,
        displayText: startCase(agent)
      }));
      
      // Get status filters from URL if present
      const statusParam = searchParams.get('status');
      const statusValues = statusParam ? statusParam.split(',') : [];
      
      // Get agent filters from URL if present
      const agentParam = searchParams.get('agent');
      const agentValues = agentParam ? agentParam.split(',') : [];
      
      // Create filters with values from URL if present
      setFilters([
        {
          entityKey: 'status',
          displayText: 'Status',
          type: IFilterType.MULTI_SELECT,
          valueOptions: statusOptions,
          selectedValue: statusValues.length > 0 
            ? statusOptions.filter(option => statusValues.includes(option.value))
            : [],
          withSearchBox: false,
          width: '200px'
        },
        {
          entityKey: 'agent',
          displayText: 'Agent',
          type: IFilterType.MULTI_SELECT,
          valueOptions: agentOptions,
          selectedValue: agentValues.length > 0
            ? agentOptions.filter(option => agentValues.includes(option.value))
            : [],
          withSearchBox: false,
          width: '200px'
        }
      ]);
    }
  }, [filters.length, tasksData, searchParams]);

  // Sync filters with URL when they change
  useEffect(() => {
    if (filters.length === 0) return;
    
    // Build URL params from filters
    const params = new URLSearchParams(searchParams);
    
    // Update status param
    const statusFilter = filters.find(f => f.entityKey === 'status');
    const statusValues = statusFilter?.selectedValue as IFilterOption[] || [];
    if (statusValues.length > 0) {
      params.set('status', statusValues.map(option => option.value).join(','));
    } else {
      params.delete('status');
    }
    
    // Update agent param
    const agentFilter = filters.find(f => f.entityKey === 'agent');
    const agentValues = agentFilter?.selectedValue as IFilterOption[] || [];
    if (agentValues.length > 0) {
      params.set('agent', agentValues.map(option => option.value).join(','));
    } else {
      params.delete('agent');
    }
    
    // Update URL without causing navigation
    setSearchParams(params, { replace: true });
  }, [filters, setSearchParams, searchParams]);

  // Update filter function
  const updateFilter = (updatedFilter: IFilter) => {
    setFilters(prevFilters => 
      prevFilters.map(filter => 
        filter.entityKey === updatedFilter.entityKey ? updatedFilter : filter
      )
    );
  };

  // Reset filters function
  const resetFilters = () => {
    setFilters(prevFilters => 
      prevFilters.map(filter => ({
        ...filter,
        selectedValue: []
      }))
    );
  };
  
  // Filter tasks based on selected filters
  const filteredTasks = useMemo(() => {
    return tasksData.filter(task => {
      // Check if task passes all filters
      return filters.every(filter => {
        const selectedValues = filter.selectedValue as IFilterOption[];
        
        // If no options selected for this filter, don't filter by it
        if (!selectedValues || selectedValues.length === 0) {
          return true;
        }
        
        // Check if task has the selected filter value
        if (filter.entityKey === 'status') {
          return selectedValues.some(option => option.value === task.status);
        }
        
        if (filter.entityKey === 'agent') {
          return selectedValues.some(option => option.value === task.agent);
        }
        
        return true;
      });
    });
  }, [filters, tasksData]);
  
  // Update the renderStatusChip function to use JitTag
  const renderStatusChip = (status: string) => {
    const textsMap = {
      completed: 'Completed',
      failed: 'Failed',
      in_progress: 'In progress',
      waiting_for_approval: 'Waiting for user approval',
    }
    const statusText = textsMap[status as keyof typeof textsMap];
    
    const statusColors: Record<string, string> = {
      completed: '#6EBD9C',
      failed: '#EC5B5B', 
      in_progress: '#C4C4C4',
      waiting_for_approval: '#FFB74D',
    };
    
    const tagColor = statusColors[status];
    
    return <JitTag text={statusText} withBorder color={tagColor} />;
  };
  
  // Handle task click
  const handleTaskClick = (task: any) => {
    setSelectedTask(task);
  };
  
  // Close sidebar
  const closeSidebar = () => {
    setSelectedTask(null);
  };
  
  // Generate description for task (mock)
  const getTaskDescription = (task: any) => {
    if (task.title.includes('vulnerability')) {
      return `Identified 2 critical vulnerability in cloud infrastructure`;
    } else if (task.title.includes('audit')) {
      return `Comprehensive AWS compliance audit against industry standards`;
    } else if (task.title.includes('patch')) {
      return `Verification of security patches applied to cloud resources`;
    } else if (task.title.includes('incident')) {
      return `Automated triage of security incidents detected in the environment`;
    } else if (task.title.includes('IAM')) {
      return `Review of IAM permissions for least privilege assessment`;
    } else if (task.title.includes('code')) {
      return `Assessment of codebase for security vulnerabilities and best practices`;
    } else if (task.title.includes('gap')) {
      return `Analysis of compliance gaps against required frameworks`;
    } else if (task.title.includes('alert')) {
      return `Correlation analysis of security alerts across systems`;
    }
    return `Task executed by ${startCase(task.agent)}`;
  };
  
  return (
    <PageWrapper>
      {/* Background overlay */}
      <Overlay 
        className={selectedTask ? 'visible' : ''} 
        onClick={closeSidebar}
      />
      
      {/* Filters at the top */}
      <Box sx={{ mb: 2 }}>
        <JitFilters 
          filters={filters}
          updateFilter={updateFilter}
          resetFilters={resetFilters}
          shouldCloseOnItemClick={false}
        />
      </Box>
      
      {/* Task Insights Section */}
      <Section>
        <SectionTitle>Task Insights</SectionTitle>
        <Grid container spacing={2}>
          {statsData.map((stat, index) => (
            <Grid item xs={12} md={4} key={index}>
              <InsightCard>
                <CardTitle>{stat.title}</CardTitle>
                <CardValue>{stat.value}</CardValue>
              </InsightCard>
            </Grid>
          ))}
        </Grid>
      </Section>
      
      {/* Recent Task History Section */}
      <div style={{paddingBottom: 40}}>
      <Section>
        <SectionTitle>Recent task history</SectionTitle>
        
        {/* Task Table */}
        <TaskTable>
          <Table stickyHeader>
            <StickyHeader>
              <TableRow>
                <HeaderCell>Task details</HeaderCell>
                <HeaderCell>Agent</HeaderCell>
                <HeaderCell>Date</HeaderCell>
                <HeaderCell>Duration</HeaderCell>
                <HeaderCell>Time saved</HeaderCell>
                <HeaderCell>Status</HeaderCell>
              </TableRow>
            </StickyHeader>
            <TableBody>
              {filteredTasks.map((task) => (
                <TableRow 
                  key={task.id} 
                  onClick={() => handleTaskClick(task)}
                  sx={{ 
                    cursor: 'pointer',
                    '&:hover': { 
                      backgroundColor: 'rgba(255, 255, 255, 0.05)'
                    } 
                  }}
                >
                  <BodyCell>{task.title}</BodyCell>
                  <BodyCell>{startCase(task.agent)}</BodyCell>
                  <BodyCell>{task.date}</BodyCell>
                  <BodyCell>{task.duration}</BodyCell>
                  <BodyCell>{task.timeSaved}</BodyCell>
                  <BodyCell>{renderStatusChip(task.status)}</BodyCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TaskTable>
      </Section>
      </div>
      
      {/* Task Details Sidebar */}
      <Sidebar 
        className={selectedTask ? 'open' : ''}
        onClick={(e) => e.stopPropagation()} // Prevent clicks inside sidebar from closing it
      >
        {selectedTask && (
          <>
            <SidebarHeader>
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', mb: 1 }}>
                  <Typography variant="caption" color="rgba(255, 255, 255, 0.5)">
                    Task info
                  </Typography>
                </Box>
                <TaskTitle>{selectedTask.title}</TaskTitle>
                <TaskDescription>
                  {getTaskDescription(selectedTask)}
                </TaskDescription>
              </Box>
              <CloseButton onClick={closeSidebar}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18 6L6 18M6 6l12 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </CloseButton>
            </SidebarHeader>
            
            <StepContainer>
              <Typography variant="body2" color="rgba(255, 255, 255, 0.5)" sx={{ mb: 2 }}>
                Task steps
              </Typography>
              
              <JittoTaskSteps steps={selectedTask.steps as TaskStep[]} />
            </StepContainer>
          </>
        )}
      </Sidebar>
    </PageWrapper>
  );
}; 