import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useChooseGithubRepositoriesStep } from './useChooseGithubRepositoriesSetup';
import { useGetCreateCentralizedRepoStep } from './useGetCreateCentralizedRepoStep';

import { useGetInstallJitAppStep } from '.';

import { IGithubIntegrationWizardStep, IGithubIntegrationWizardStepStatus } from 'components/GithubIntegrationWizard/GithubIntegrationWizard';
import { constants } from 'globalConstants';
import { Repository, allSCMResourcesCoverageType, selectedSCMResourcesCoverageType } from 'types/interfaces/SCM/SCMMember';

export const useWizardSteps = (incrementStepIndex: () => void, closeWizard: () => void, handleInstallClick?: () => void) => {
  const [repositoriesStateStep, setRepositoriesStateStep] = useState<{
    repositories: Repository[],
    mode: typeof allSCMResourcesCoverageType | typeof selectedSCMResourcesCoverageType | '' }>({ repositories: [],
    mode: '' });
  const { pathname } = useLocation();
  const planSlug = sessionStorage.getItem(constants.GOAL_PLAN_KEY) || undefined;

  const isRedirectedBackFromGHInstallation = useMemo(() => pathname === constants.GITHUB_INSTALLATION_REDIRECT_PATH, [pathname]);
  const isStepCompleted = useCallback((step: IGithubIntegrationWizardStep) => step.status === IGithubIntegrationWizardStepStatus.COMPLETE, []);

  const installJitAppStep = useGetInstallJitAppStep({
    incrementStepIndex,
    isRedirectedBackFromGHInstallation,
    planSlug,
    handleInstallClick,
  });

  const chooseRepositoryStep = useChooseGithubRepositoriesStep({
    incrementStepIndex,
    repositoriesStateStep,
    setRepositoriesStateStep,
  });

  const createCentralizedRepoStep = useGetCreateCentralizedRepoStep({
    isPrevStepCompleted: isStepCompleted(installJitAppStep),
    closeWizard,
  });

  const { uiShowChooseRepoGithubOnboarding } = useFlags();

  return useMemo(
    () => (uiShowChooseRepoGithubOnboarding
      ? [installJitAppStep, chooseRepositoryStep, createCentralizedRepoStep]
      : [installJitAppStep, createCentralizedRepoStep]),
    [installJitAppStep, createCentralizedRepoStep, chooseRepositoryStep, uiShowChooseRepoGithubOnboarding],
  );
};
