import { t } from 'i18next';
import { SetStateAction, Dispatch, useMemo } from 'react';

import { IGithubIntegrationWizardStep, IGithubIntegrationWizardStepStatus } from 'components/GithubIntegrationWizard/GithubIntegrationWizard';
import { ChooseSCMResources } from 'components/SCM/ChooseSCMResources/ChooseSCMResources';
import { Vendor } from 'types/enums/Vendor';
import { Repository, allSCMResourcesCoverageType, selectedSCMResourcesCoverageType } from 'types/interfaces/SCM/SCMMember';

interface Props {
  incrementStepIndex: () => void;
  repositoriesStateStep: { repositories: Repository[], mode: typeof allSCMResourcesCoverageType | typeof selectedSCMResourcesCoverageType | '' };
  setRepositoriesStateStep: Dispatch<SetStateAction<{
    repositories: Repository[];
    mode: typeof allSCMResourcesCoverageType | typeof selectedSCMResourcesCoverageType | '';
  }>>
}

export const useChooseGithubRepositoriesStep = ({ incrementStepIndex, repositoriesStateStep, setRepositoriesStateStep }: Props): IGithubIntegrationWizardStep => {
  const status = useMemo(() => {
    if ((repositoriesStateStep.repositories.length > 0 && repositoriesStateStep.mode === selectedSCMResourcesCoverageType) || (repositoriesStateStep.mode === allSCMResourcesCoverageType)) {
      return IGithubIntegrationWizardStepStatus.COMPLETE;
    }
    return IGithubIntegrationWizardStepStatus.ACTIVE;
  }, [repositoriesStateStep]);

  return useMemo(() => ({
    title: t('ChooseSCMResources.tabText', { name: t('ChooseSCMResources.github.nomenclature.repository.plural.lowercase').toString() }).toString(),
    component: () => (
      <ChooseSCMResources
        chooseMode={repositoriesStateStep.mode}
        chosenSCMResources={repositoriesStateStep.repositories}
        incrementStepIndex={incrementStepIndex}
        markStepAsComplete={(mode: typeof allSCMResourcesCoverageType | typeof selectedSCMResourcesCoverageType, repositories: Repository[]) => setRepositoriesStateStep({ ...repositoriesStateStep,
          mode,
          repositories })}
        status={status}
        vendor={Vendor.Github}
      />
    ),
    status,
  }), [status, incrementStepIndex, repositoriesStateStep, setRepositoriesStateStep]);
};

