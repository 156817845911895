import { diff } from 'deep-diff';
import { isEmpty } from 'lodash';
import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

import { initialPlan } from './constants';
import { buildPlanItemRecord } from './planUtils';

import { useSendAnalyticsEvent } from 'context/AnalyticsContext/hooks/useSendAnalyticsEvent';
import { useHandlePlanItemWebsocketNotification } from 'context/PlanInstanceContext/planHooks';
import { PlanInstanceContext } from 'context/PlanInstanceContext/PlanInstanceContext';
import { usePlanTemplatesContext } from 'context/PlanTemplatesContext/PlanTemplatesContext';
import { useReferralContext } from 'context/ReferralContext/ReferralContext';
import { useWebsocketSubscribe } from 'context/WebSocketContext/hooks';
import { constants } from 'globalConstants';
import { usePlanService } from 'services/PlanService/usePlanService';
import { WebSocketNotificationTopics } from 'types/enums';
import { IPlanInstance, IPlanInstanceItem, IPlanItemTemplate, ITenantPlanItem } from 'types/interfaces';
import { useUpdateEffect } from 'utils';
import { getPlanItemSlug } from 'utils/functions/getPlanItemSlug';
import { getPlansDiff } from 'utils/functions/getPlansDiff/getPlansDiff';
import { yamlDump, yamlLoad } from 'utils/functions/yaml';

export const PlanInstanceProvider: FC<PropsWithChildren> = ({ children }) => {
  const { planItemsTemplates } = usePlanTemplatesContext();
  const { getPlanItems, getPlanContent } = usePlanService();
  const { referral } = useReferralContext();
  const { sendAnalyticsPlanDiffEvent } = useSendAnalyticsEvent();
  const [shouldAddReferralItems, setShouldAddReferralItems] = useState(false);
  const { commitPlan } = usePlanService();
  const [isCommittingPlan, setIsCommittingPlan] = useState(false);

  const [plan, setPlan] = useState<IPlanInstance>(initialPlan);
  const [fetchedPlan, setFetchedPlan] = useState<IPlanInstance>(initialPlan);
  const [loadedPlanFromStorage, setLoadedPlanFromStorage] = useState<boolean>(false);

  const [planItemSlugsInCommit, setPlanItemSlugsInCommit] = useState<string[]>([]);

  const [isLoadingPlan, setIsLoadingPlan] = useState<boolean>(false);

  const [hasPlanFetched, setHasPlanFetched] = useState<boolean>(false);

  const [planItems, setPlanItems] = useState<ITenantPlanItem[]>();

  const { websocketSubscribe } = useWebsocketSubscribe();
  const { handlePlanItemWebSocketNotification } = useHandlePlanItemWebsocketNotification({
    plan,
    setPlan,
    fetchedPlan,
    setFetchedPlan,
    setPlanItems,
  });
  const isPlanActivated = useMemo(() => !isEmpty(plan.items), [plan.items]);
  const isPlanExist = useMemo(() => !!fetchedPlan, [fetchedPlan]);
  const tempPlan = sessionStorage.getItem(constants.TEMP_PLAN_KEY);

  const updatePlanItem = useCallback((updatedPlanItem: ITenantPlanItem) => {
    const updatedPlanItems = planItems?.map((item) => {
      if (item.slug === updatedPlanItem.slug) {
        return updatedPlanItem;
      }
      return item;
    });
    setPlanItems(updatedPlanItems);
  }, [planItems]);

  const yamlLoadPlan = useCallback(async (planRes?: string) => {
    if (!planRes) {
      console.error('Could not get plan. No plan found.');
      return undefined;
    }
    sessionStorage.removeItem(constants.TEMP_PLAN_KEY);
    const loadedPlan = yamlLoad<IPlanInstance>(planRes);
    if (!loadedPlan) {
      console.error('Could not get plan. Invalid plan.');
      return undefined;
    }
    return {
      ...loadedPlan,
      items: loadedPlan.items || [],
      tags: loadedPlan.tags || [],
    };
  }, []);

  const getPlan = useCallback(async () => {
    const planRes = await getPlanContent();
    return yamlLoadPlan(planRes?.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yamlLoadPlan]);

  const getPlanItemBySlug = useCallback((slug: string) => planItems?.find((item) => item.slug === slug), [planItems]);

  const setInitialPlan = useCallback(async () => {
    if (!hasPlanFetched) {
      setIsLoadingPlan(true);
      const planRes = await getPlan();
      if (planRes) {
        setPlan(planRes);
        setFetchedPlan(planRes);
        setHasPlanFetched(true);
      }
      setIsLoadingPlan(false);
    }
  }, [getPlan, hasPlanFetched]);

  const isPlanItemActive = useCallback((planItemSlug: string) => !!plan.items?.some((item: IPlanInstanceItem) => getPlanItemSlug(item)
    === planItemSlug), [plan?.items]);

  const getActivePlanItemsFromItemNames = useCallback((planItemsNames: string[]) => planItemsNames.filter((planItemSlug) => isPlanItemActive(planItemSlug)), [isPlanItemActive]);

  const commitCurrentPlan = useCallback(async (updatedPlan: IPlanInstance, vendor?: string) => {
    setIsCommittingPlan(true);
    const stringPlan = yamlDump(updatedPlan);
    if (!stringPlan) {
      console.error('Missing function commitPlan args');
      return;
    }
    const { addedItems, removedItems } = getPlansDiff(fetchedPlan, updatedPlan);
    setPlanItemSlugsInCommit([...addedItems, ...removedItems].map((item) => getPlanItemSlug(item)));

    const res = await commitPlan(vendor || constants.GITHUB, stringPlan);
    sendAnalyticsPlanDiffEvent(plan, fetchedPlan);
    if (isPlanActivated) setPlanItemSlugsInCommit([]);
    if (!res?.data) {
      console.error('Commit plan failed');
      return;
    }
    setFetchedPlan(updatedPlan);
    setIsCommittingPlan(false);
  }, [fetchedPlan, commitPlan, sendAnalyticsPlanDiffEvent, plan, isPlanActivated]);

  const saveTempPlanToSessionStorage = useCallback(() => {
    sessionStorage.setItem(constants.TEMP_PLAN_KEY, yamlDump(plan));
  }, [plan]);

  const handleCodeChange = useCallback((newValue: string) => {
    const newPlan = yamlLoad<IPlanInstance>(newValue);
    if (!newPlan) {
      console.error('Could not get plan. Invalid plan.');
      return;
    }
    setPlan(newPlan);
  }, []);

  const initPlanItems = useCallback(async () => {
    if (!planItems) {
      await getPlanItems(constants.PLAN_SLUG.MY_PLAN).then((res) => {
        if (!res?.data) return;
        setPlanItems(res.data);
      });
    }
  }, [getPlanItems, planItems]);

  const getTempPlanFromSessionStorage = useCallback(() => {
    if (!tempPlan) return;
    const loadedPlan = yamlLoad<IPlanInstance>(tempPlan);
    if (!loadedPlan) {
      console.error('Could not get plan. Invalid plan.');
      return;
    }
    setLoadedPlanFromStorage(true);
    setPlan(loadedPlan);
  }, [tempPlan]);

  useEffect(() => {
    if (isPlanActivated || loadedPlanFromStorage || !tempPlan) return;
    getTempPlanFromSessionStorage();
  }, [getTempPlanFromSessionStorage, isPlanActivated, loadedPlanFromStorage, tempPlan]);

  useUpdateEffect(() => {
    if (plan.items.length > 0) {
      setShouldAddReferralItems(true);
    }
  }, [plan.items]);

  useUpdateEffect(() => {
    if (planItemsTemplates.length > 0 && plan.items.length === 0 && !shouldAddReferralItems && referral.items) {
      const itemsToAdd: IPlanItemTemplate[] = [];
      referral.items.forEach((item) => {
        const currentPlanItem = planItemsTemplates.find((planItem) => planItem.slug === item);
        if (currentPlanItem && !itemsToAdd.includes(currentPlanItem)) {
          itemsToAdd.push(currentPlanItem);
        }
        const planInstanceItemsToAdd = itemsToAdd.map((itemOption) => buildPlanItemRecord(itemOption.name, itemOption?.layer, itemOption.slug));
        const newPlanItems = [...planInstanceItemsToAdd, ...plan.items];
        const newPlan = {
          ...plan,
          items: newPlanItems,
        };
        setPlan(newPlan);
      });
      setShouldAddReferralItems(true);
    }
  }, [plan, planItemsTemplates, shouldAddReferralItems, referral]);

  useEffect(() => {
    websocketSubscribe(WebSocketNotificationTopics.PlanItem, handlePlanItemWebSocketNotification);
  }, [handlePlanItemWebSocketNotification, websocketSubscribe]);

  useUpdateEffect(() => {
    if (isPlanActivated) return;
    saveTempPlanToSessionStorage();
  }, [isPlanActivated]);

  const isPlanValid = !!plan;
  const planUncommitedChanges = useMemo(() => diff(fetchedPlan, plan), [fetchedPlan, plan]);
  const value = useMemo(
    () => ({
      plan,
      setPlan,
      fetchedPlan,
      setFetchedPlan,
      isPlanActivated,
      isPlanExist,
      planUncommitedChanges,
      commitCurrentPlan,
      isPlanItemActive,
      handleCodeChange,
      isPlanValid,
      getActivePlanItemsFromItemNames,
      planItemSlugsInCommit,
      isLoadingPlan,
      setIsLoadingPlan,
      setPlanItemSlugsInCommit,
      setInitialPlan,
      hasPlanFetched,
      planItems,
      setPlanItems,
      initPlanItems,
      getPlanItemBySlug,
      updatePlanItem,
      isCommittingPlan,
    }),
    [
      plan,
      setPlan,
      fetchedPlan,
      setFetchedPlan,
      isPlanActivated,
      isPlanExist,
      planUncommitedChanges,
      commitCurrentPlan,
      isPlanItemActive,
      handleCodeChange,
      isPlanValid,
      getActivePlanItemsFromItemNames,
      planItemSlugsInCommit,
      isLoadingPlan,
      setIsLoadingPlan,
      setPlanItemSlugsInCommit,
      setInitialPlan,
      hasPlanFetched,
      planItems,
      setPlanItems,
      initPlanItems,
      getPlanItemBySlug,
      updatePlanItem,
      isCommittingPlan,
    ],
  );
  return <PlanInstanceContext.Provider value={value}>{children}</PlanInstanceContext.Provider>;
};
