import { FC } from 'react';

import { IntegrationCard } from '../components/integrationCards/IntegrationCard/IntegrationCard';

import { JitIcon } from 'assets';
import { IntegrationTemplate, IntegrationType, CustomIntegrationCardDefaultProps } from 'context/IntegrationsContext/templates/interfaces';
import { IIntegration } from 'types/interfaces/Integrations/IIntegration';

interface RenderIntegrationCardProps {
  integrationTemplate: IntegrationTemplate;
  integrations?: IIntegration[];
  isLoading: boolean;
  isLoadingParagon: boolean;
  integrationFileLink?: string;
  customIntegrationsMapping: Record<string, FC<CustomIntegrationCardDefaultProps>>;
  constants: {
    INTEGRATIONS: {
      GITLAB: string;
    };
  };
}

/**
 * Renders the appropriate integration card based on the integration template
 */
export const createRenderIntegrationCardFunction = ({
  integrations,
  isLoading,
  isLoadingParagon,
  integrationFileLink,
  customIntegrationsMapping,
  constants,
}: Omit<RenderIntegrationCardProps, 'integrationTemplate'>) => (integrationTemplate: IntegrationTemplate): JSX.Element | null => {
  const {
    integrationType,
    vendor,
    key,
    icon,
    vendorType,
    label,
    shortDescription,
    logoUrl,
    isNew,
  } = integrationTemplate;

  if (integrationType === IntegrationType.custom) {
    const CustomIntegrationCard = customIntegrationsMapping[vendor];
    return (
      <CustomIntegrationCard
        key={key}
        icon={icon as FC || JitIcon}
        tags={vendorType ? [{ text: vendorType,
          color: 'blue' }] : []}
        title={label}
      />
    );
  }
  return (
    <IntegrationCard
      key={key}
      description={shortDescription}
      icon={logoUrl || (icon as FC || JitIcon)}
      integrationFileLink={integrationType === IntegrationType.asCode
        ? integrationFileLink
        : undefined}
      integrations={integrations?.filter((integration) => vendor === integration.vendor)}
      integrationTemplate={integrationTemplate}
      isActionHidden={vendor === constants.INTEGRATIONS.GITLAB} // a temporary solution to hide action button for Gitlab integration
      isLoading={isLoading || (integrationType === IntegrationType.thirdParty && isLoadingParagon)}
      isNew={isNew}
      testId={`integrationCard-${vendor}`}
      title={label}
      variant={vendorType}
      vendor={vendor}
    />
  );
};
