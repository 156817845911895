import { IconButton } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import { t } from 'i18next';

import styles from './CoverageSelectionHeader.module.scss';

import { Cross } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { SCMVendors } from 'types/enums/Vendor';

interface Props {
  onClose: () => void;
  vendor: SCMVendors;
}

export const CoverageSelectionHeader = ({ onClose, vendor }: Props) => (
  <DialogTitle className={styles.dialogTitle}>
    <JitText
      bold
      size='l'
      text={t(`ChooseSCMResources.${vendor}.coverageSelectionDialog.title`).toString()}
    />

    <div className={styles.closeButtonWrapper}>
      <IconButton onClick={onClose}>
        <JitIcon icon={Cross} size={25} />
      </IconButton>
    </div>
  </DialogTitle>
);
