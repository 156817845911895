import { useQuery } from 'react-query';

import { useAssetService } from './useAssetService';

import { Queries } from 'types/enums/Queries';

export const useInitialScans = () => {
  const { getInitialScanStats } = useAssetService();

  return useQuery(Queries.InitialScanStats, getInitialScanStats);
};
