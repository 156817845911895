import { FC, ReactElement } from 'react';

import styles from './ActivationPopper.module.scss';

import { JitButton } from 'components/JitButton/JitButton';
import { JitPopper } from 'components/JitPopper/JitPopper';
import { JitText } from 'components/JitText/JitText';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext/hooks/useSendAnalyticsEvent';
import { usePipelineContextNew } from 'context/PipelinesContext/PipelinesContextNew';
import { i18n } from 'locale/i18n';
import { createMockPipelineNew, createPlaceholderMockPipelineNew } from 'pages/PipelinesPage/utils/MockPipelineNew/mockPipelineNew';
import { runPipelineDemoVulnerability } from 'services/CiService';
import { PLAN_ITEM_SLUG_DEMO_COMMITS, PlanItemSlugDemoCommits } from 'services/CiService/constants';
import colors from 'themes/colors.module.scss';
import { Vendor } from 'types/enums';
import { IMouseEvent } from 'types/interfaces';
import { PipelineDemoCommitType } from 'types/interfaces/Ci';
import { IMockPipelineNew } from 'types/interfaces/Pipelines/IPipelineNew';
import { stopPropagation } from 'utils';

interface Props {
  children: ReactElement
  planItemSlug: PlanItemSlugDemoCommits;
  assetName: string;
  ownerName: string;
  commitType: PipelineDemoCommitType;
  prLink: string;
  isOpen: boolean;
  dismiss: () => void;
  isButtonDisabled?: boolean;
}

const DEMO_PLAN_ITEM_SLUG = PLAN_ITEM_SLUG_DEMO_COMMITS.SECRET_DETECTION;

export const ActivationPopper: FC<Props> = ({
  children, planItemSlug, ownerName, assetName, commitType, prLink, isOpen, dismiss, isButtonDisabled = false,
}) => {
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const { t } = i18n;

  if (planItemSlug === DEMO_PLAN_ITEM_SLUG) {
    if (commitType === PipelineDemoCommitType.CLEAN) {
      sendAnalyticsEvent({ action: 'plan-active-viewed' });
    } else {
      sendAnalyticsEvent({ action: 'testing-secret-viewed' });
    }
  }

  const pipelineContextNew = usePipelineContextNew();

  const sendDismissAnalyticsEvent = () => {
    if (commitType === PipelineDemoCommitType.CLEAN) {
      sendAnalyticsEvent({ action: 'plan-active-dismissed' });
    } else {
      sendAnalyticsEvent({ action: 'testing-secret-dismissed' });
    }
  };

  const continueActivationFlowNew = (event: IMouseEvent) => {
    stopPropagation(event);
    if (pipelineContextNew) {
      if (commitType === PipelineDemoCommitType.CLEAN) {
        if (planItemSlug === DEMO_PLAN_ITEM_SLUG) {
          sendAnalyticsEvent({ action: 'introduce-secret-clicked' });
        }
        const setActivationStateNew = (newMockPipeline: IMockPipelineNew, showActivationPopper: boolean) => {
          pipelineContextNew.setMockPipeline(newMockPipeline);
          pipelineContextNew.setShowActivationPopper(showActivationPopper);
        };
        pipelineContextNew.setSecondaryMockPipeline(pipelineContextNew.mockPipeline);
        createPlaceholderMockPipelineNew(planItemSlug, PipelineDemoCommitType.VULNERABILITY, setActivationStateNew);
        return runPipelineDemoVulnerability({
          plan_item_slug: planItemSlug,
          commit_type: PipelineDemoCommitType.VULNERABILITY,
          vendor: Vendor.Github,
        }).then((response) => {
          if (response) {
            createMockPipelineNew(response.base.repo.name, response.number, planItemSlug, PipelineDemoCommitType.VULNERABILITY, response.base.repo.owner?.login, setActivationStateNew);
          }
        });
      }

      if (planItemSlug === DEMO_PLAN_ITEM_SLUG) {
        sendAnalyticsEvent({ action: 'testing-secret-view-github_clicked' });
      }
      pipelineContextNew.setShowActivationPopper(false);
    }
    return window.open(prLink, '_blank');
  };
  const finalAssetName = assetName ? `${ownerName}/${assetName}` : `${t('pages.pipelines.pipelinesActivation.defaultRepository')}`;
  // eslint-disable-next-line max-len
  const popperBody = assetName
    ? `pages.pipelines.pipelinesActivation.${planItemSlug}.${commitType}.body`
    : `pages.pipelines.pipelinesActivation.${planItemSlug}.${commitType}.loadingBody`;
  const content = (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={styles.popperContainer}
      onClick={stopPropagation}
    >
      <JitText
        bold
        className={styles.header}
        color={colors.white}
        data-testid='popper-header'
        size='xl'
        text={`pages.pipelines.pipelinesActivation.${planItemSlug}.${commitType}.title`}
      />

      <JitText color={colors.white} data-testid='text-body' params={{ assetName: finalAssetName }} size='m' text={popperBody} />

      <div className={styles.buttonContainer}>
        <JitButton
          data-testid='dismiss-button'
          onClick={() => {
            sendDismissAnalyticsEvent();
            dismiss();
          }}
          variant='text'
          width={150}
        >
          <JitText bold color={colors.lightBlue} text='pages.pipelines.pipelinesActivation.dismiss' />
        </JitButton>

        <JitButton
          data-testid='activate-button'
          disabled={isButtonDisabled}
          onClick={(event) => continueActivationFlowNew(event)}
          variant='contained'
          width={320}
        >
          <JitText
            bold
            className={styles.activationButton}
            size='l'
            text={`pages.pipelines.pipelinesActivation.${planItemSlug}.${commitType}.button`}
          />
        </JitButton>
      </div>

    </div>
  );

  return (
    <JitPopper
      arrowPlacement='top'
      className={styles.popper}
      content={content}
      isWithArrow
      onClose={sendDismissAnalyticsEvent}
      open={isOpen}
      placement='bottom'
      transition={false}
    >
      {children}
    </JitPopper>
  );
};

