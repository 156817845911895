import { FC } from 'react';

export const CotaIcon: FC = () => (
  <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="30.6569" cy="30.106" r="29" stroke="#C5C3D6" strokeOpacity="0.1" strokeWidth="2"/>
    <path d="M46.6569 30.106C46.6569 39.5216 39.2432 47.106 30.1569 47.106C21.0705 47.106 13.6569 39.5216 13.6569 30.106C13.6569 20.6904 21.0705 13.106 30.1569 13.106C39.2432 13.106 46.6569 20.6904 46.6569 30.106Z" stroke="#C5C3D6" strokeOpacity="0.1" strokeWidth="2"/>
    <path d="M27.1072 13.3989C30.2136 12.7969 33.4232 13.1269 36.3538 14.3506C39.2848 15.5744 41.8158 17.6416 43.6417 20.3097C45.4679 22.978 46.5107 26.1334 46.6426 29.399C46.7745 32.6648 45.9898 35.8989 44.3846 38.715C42.7795 41.5308 40.423 43.8078 37.5998 45.2781C34.7769 46.7481 31.6046 47.3507 28.4608 47.0159C25.3169 46.6812 22.3299 45.4227 19.8571 43.3869C17.3839 41.3509 15.5291 38.6228 14.518 35.526" stroke="url(#paint0_linear_299_89254)" strokeWidth="2"/>
    <path d="M40.6569 30.106C40.6569 36.2217 35.9162 41.106 30.1569 41.106C24.3976 41.106 19.6569 36.2217 19.6569 30.106C19.6569 23.9903 24.3976 19.106 30.1569 19.106C35.9162 19.106 40.6569 23.9903 40.6569 30.106Z" stroke="#C5C3D6" strokeWidth="2"/>
    <path d="M52.6569 30.106C52.6569 42.8285 42.5635 53.106 30.1569 53.106C17.7502 53.106 7.65686 42.8285 7.65686 30.106C7.65686 17.3835 17.7502 7.10599 30.1569 7.10599C42.5635 7.10599 52.6569 17.3835 52.6569 30.106Z" stroke="#C5C3D6" strokeOpacity="0.1" strokeWidth="2"/>
    <path d="M31.1194 53.0849C27.2694 53.2534 23.4408 52.4084 20.0008 50.6296C16.5606 48.8507 13.6236 46.1971 11.4734 42.9215C9.32309 39.6458 8.03252 35.8588 7.72727 31.9242C7.42203 27.9895 8.11256 24.0413 9.73156 20.4593C11.3505 16.8775 13.8429 13.7833 16.9672 11.4724C20.0913 9.16158 23.7428 7.71115 27.5709 7.2584C31.3989 6.80566 35.2771 7.36548 38.8336 8.885C42.3902 10.4046 45.507 12.8336 47.8833 15.9409" stroke="url(#paint1_linear_299_89254)" strokeWidth="2"/>
    <path d="M1.84655 33.4175C1.0428 26.4249 2.80968 19.3785 6.81775 13.5925C10.8258 7.80637 16.8015 3.67549 23.6304 1.97008C30.4594 0.264667 37.6756 1.10114 43.9333 4.32352C50.1911 7.54591 55.0633 12.9342 57.6415 19.4838C60.2196 26.0333 60.3277 33.297 57.9457 39.9203C55.5636 46.5437 50.8539 52.0747 44.6948 55.4819C38.5357 58.8891 31.3477 59.94 24.471 58.4386C17.5943 56.9372 11.4983 52.986 7.31981 47.3217" stroke="url(#paint2_linear_299_89254)" strokeWidth="2"/>
    <defs>
      <linearGradient id="paint0_linear_299_89254" x1="44.0391" y1="13.3608" x2="9.10003" y2="42.9038" gradientUnits="userSpaceOnUse">
        <stop stopColor="#C5C3D6"/>
        <stop offset="1" stopColor="#C5C3D6" stopOpacity="0"/>
      </linearGradient>
      <linearGradient id="paint1_linear_299_89254" x1="30.1569" y1="6.10599" x2="31.6244" y2="62.2478" gradientUnits="userSpaceOnUse">
        <stop stopColor="#C5C3D6"/>
        <stop offset="1" stopColor="#C5C3D6" stopOpacity="0"/>
      </linearGradient>
      <linearGradient id="paint2_linear_299_89254" x1="30.6569" y1="0.105987" x2="5.30266" y2="57.6549" gradientUnits="userSpaceOnUse">
        <stop stopColor="#C5C3D6"/>
        <stop offset="1" stopColor="#C5C3D6" stopOpacity="0"/>
      </linearGradient>
    </defs>
  </svg>
); 