import { t } from 'i18next';
import { FC, useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';

import styles from './ResourcesCoverage.module.scss';

import { JitDivider } from 'components/JitDivider/JitDivider';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { GraphEmpty, GraphLoading } from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/components';
import { useAssetService } from 'services/AssetsService/useAssetService';
import { AssetType } from 'types/enums';
import { Queries } from 'types/enums/Queries';
import { IDictionary } from 'types/interfaces';
import { IGroupByCount } from 'types/interfaces/Counts/ICounts';
import { useAssetTypeToVendor } from 'utils/constants/assetTypeConfigurations/assetTypeConfigurations';

const GROUP_BY_FIELD = 'asset_type';

interface Props {
  filters: IDictionary<boolean | string>;
}

export const ResourcesCoverage: FC<Props> = ({ filters }) => {
  const { fetchAssetsCount } = useAssetService();
  const { getIconByAssetType } = useAssetTypeToVendor();

  const fetchAssetsCountParams = useMemo(() => ({
    groupBy: GROUP_BY_FIELD,
    filters,
  }), [filters]);

  const getAssetsCount = useCallback(async () => {
    const data = await fetchAssetsCount(fetchAssetsCountParams);

    return data as IGroupByCount[] | undefined;
  }, [fetchAssetsCount, fetchAssetsCountParams]);

  const { data, isLoading, isError } = useQuery([Queries.AssetsCount, fetchAssetsCountParams], getAssetsCount);

  const assets = useMemo(() => (
    data?.map((asset) => ({
      name: t(`assetTypes.${asset.key}`),
      value: asset.count,
      icon: getIconByAssetType(asset.key as AssetType),
    })).sort((a, b) => b.value - a.value)
  ), [data, getIconByAssetType]);

  if (isLoading) return <GraphLoading />;

  if (!assets?.length || isError) return <GraphEmpty />;

  const numCategories = assets.length - 1;

  return (
    <>
      <JitText bold className={styles.header} muted size='s' text='pages.securityImpactV2.sections.securityProgress.widgets.coveredResources.headers.resourceType' />

      <div className={styles.wrapper}>
        <JitDivider />

        {assets.map((asset, index) => (
          <div key={asset.name}>
            <div className={styles.row}>
              <JitIcon icon={asset.icon} />

              <JitText className={styles.name} data-testid={`resourceCoverage-${asset.name}`} text={asset.name} />

              <JitText text={asset.value} />
            </div>

            {index < numCategories && <JitDivider />}
          </div>
        ))}
      </div>
    </>
  );
};
