export const PLAN_ITEM_SLUG_DEMO_COMMITS = {
  WELCOME: 'welcome-message',
  SECRET_DETECTION: 'item-secret-detection',
  IAC_MISCONFIGURATION_DETECTION: 'item-iac-misconfiguration-detection',
  CODE_VULNERABILITY: 'item-code-vulnerability',
  DEPENDENCY_CHECK: 'item-dependency-check',
  CONTAINER_SCAN: 'item-container-scan',
} as const;

export type PlanItemSlugDemoCommits = (typeof PLAN_ITEM_SLUG_DEMO_COMMITS)[keyof typeof PLAN_ITEM_SLUG_DEMO_COMMITS];
