import { paragon } from '@useparagon/connect';
import { useQuery } from 'react-query';

import * as keys from './keys';
import { MSTeamsTeamsResponse, MSTeamsChannelsResponse } from './types';

export const useMSTeamsTeams = () => useQuery({
  queryKey: keys.teams,
  queryFn: () => paragon.request<MSTeamsTeamsResponse>('microsoftTeams', 'teams', { method: 'GET',
    body: undefined,
    headers: undefined }),
});

export const useMSTeamsTeamChannels = (selectedTeam: string) => useQuery({
  queryKey: keys.channels(selectedTeam),
  queryFn: () => paragon.request<MSTeamsChannelsResponse>('microsoftTeams', `teams/${selectedTeam}/channels`, { method: 'GET',
    body: undefined,
    headers: undefined }),
  enabled: Boolean(selectedTeam),
});
