import { FC, useMemo, useState } from 'react';

import { NoIntegrationButton } from './components/NoIntegrationButton';
import styles from './CreateTicketButton.module.scss';
import { useCreateTicketAsync } from './hooks/useCreateTicketAsync';
import { useCreateTicketSync } from './hooks/useCreateTicketSync';
import { useVendorTicketConfig } from './VendorConfig';

import { IconButton } from 'components/IconButton/IconButton';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { useAuthContext } from 'context/AuthContext/AuthContext';
import { useGetActiveIntegration } from 'context/IntegrationsContext/hooks/useGetActiveIntegration';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';
import { IFinding, TicketFinding } from 'types/interfaces';
import { IntegrationProvider } from 'types/interfaces/Integrations/IIntegration';
import { PERMISSIONS } from 'wrappers/RBAC/constants';

interface Props {
  onClose: (ticket?: TicketFinding) => void;
  findings: IFinding[];
  callToActionText?: string;
  fullSize?: boolean;
}

export const CreateTicketButton: FC<Props> = ({
  onClose,
  findings,
  callToActionText,
  fullSize = true,
}) => {
  const [isInProgress, setIsInProgress] = useState(false);
  const { frontEggUser } = useAuthContext();
  const { vendorTicketConfig } = useVendorTicketConfig();
  const { hasPermission } = useAuthContext();
  const {
    isLoading: isIntegrationsLoading,
    activeIntegration,
  } = useGetActiveIntegration((integration) => integration.vendor in vendorTicketConfig);
  const { createStory: createStorySync } = useCreateTicketSync();
  const { createStory: createStoryAsync } = useCreateTicketAsync({
    onSuccess: (ticket: TicketFinding) => {
      onClose({
        ticketUrl: ticket.ticketUrl,
        vendor: activeIntegration!.vendor,
        createdAt: new Date().toISOString(),
        userId: frontEggUser.id,
      });
    },
    onError: () => {
      onClose();
    },
  });

  const hasWriteTicketPermission = hasPermission(PERMISSIONS.WRITE_TICKETS);

  const isSingleFindingWithTickets = useMemo(() => findings.length === 1 && !!findings[0].tickets?.length, [findings]);

  if (!activeIntegration && fullSize) return <NoIntegrationButton hoverText={callToActionText} />;
  if (!activeIntegration) return null;

  const vendorConfig = vendorTicketConfig[activeIntegration.vendor as keyof typeof vendorTicketConfig];

  const handleCreateStory = async () => {
    if (!activeIntegration) return;
    setIsInProgress(true);
    if (activeIntegration.provider === IntegrationProvider.JIT) {
      const ticketUrl = await createStorySync(findings, activeIntegration.integrationId, vendorConfig.openTicket, activeIntegration.provider);
      if (ticketUrl) {
        onClose({
          ticketUrl: ticketUrl!,
          vendor: activeIntegration.vendor,
          createdAt: new Date().toISOString(),
          userId: frontEggUser.id,
        });
      } else {
        onClose();
      }
    } else {
      await createStoryAsync(findings, activeIntegration.integrationId, vendorConfig.openTicket, activeIntegration.provider);
    }
    setIsInProgress(false);
  };

  const handleIconButtonClick = () => {
    if (isSingleFindingWithTickets) {
      window.open(findings[0].tickets![0].ticketUrl, '_blank');
    } else {
      handleCreateStory();
    }
  };

  const isDisabled = isInProgress || !activeIntegration || isIntegrationsLoading;
  const ticketName = i18n.t(vendorConfig.ticketName)
    .toString();
  const textColor = !isInProgress && hasWriteTicketPermission ? colors.iris : colors.grey;

  if (fullSize) {
    return (
      <div className={styles.wrapper}>
        <JitButton
          data-testid='create-ticket-button'
          disabled={isDisabled}
          isLoading={isInProgress}
          isReadOnly={!hasWriteTicketPermission}
          noHover
          onClick={handleCreateStory}
          sx={{
            ':hover': {
              borderColor: colors.iris,
            },
          }}
          variant='outlined'
        >
          <div className={styles.button}>
            <JitIcon
              color={colors.iris}
              data-testid='integration-vendor-icon'
              icon={vendorConfig.icon}
              size='14px'
              strokeColor={hasWriteTicketPermission ? colors.iris : colors.lightGray}
            />

            <JitText
              color={textColor}
              data-testid='create-ticket-button-text'
              params={{ ticketName }}
              text='tickets.baseText'
            />
          </div>

        </JitButton>
      </div>
    );
  }

  const tooltipText = (
    isSingleFindingWithTickets
      ? i18n.t('tickets.viewText', {
        ticketName: i18n.t(vendorConfig.ticketName)
          .toString(),
      })
      : i18n.t('tickets.baseText', {
        ticketName: i18n.t(vendorConfig.ticketName)
          .toString(),
      })
  );

  return (
    <IconButton
      color={colors.iris}
      completed={isSingleFindingWithTickets}
      icon={vendorConfig.icon}
      isDisabled={isDisabled || !hasWriteTicketPermission}
      onClick={handleIconButtonClick}
      testId='create-ticket-icon-button'
      tooltipText={tooltipText}
    />
  );
};
