import { memo, ReactNode } from 'react';

import styles from './CountStatus.module.scss';

import { JitText } from 'components/JitText/JitText';

const shouldDisplayText = (text: ReactNode): text is string | number | undefined => typeof text !== 'object' && typeof text !== 'boolean';

interface Props {
  count: ReactNode;
  mutedText: ReactNode;
}

export const CountStatus = memo(({ count, mutedText }: Props) => (
  <div className={styles.wrapper}>
    {shouldDisplayText(count) ? <JitText bold data-testid='count' muted size='l' text={count} /> : count}

    {shouldDisplayText(mutedText) ? <JitText className={styles.description} data-testid='text' fontFamily='Inter, serif' muted text={mutedText} textAlign='center' /> : mutedText}
  </div>
));
