import { t } from 'i18next';
import { isEmpty } from 'lodash';
import { FC, useCallback } from 'react';

import {
  AwsGreyIcon,
  GcpIcon,
  AzureIcon,
  GithubGreyIcon,
  ZapAPIIcon,
  ZapWebIcon,
  WebsiteIcon,
  SemgrepIcon,
  LegitifyIcon,
  GitlabIcon,
  BitbucketIcon,
  AzureReposIcon,
  GosecIcon,
  BanditIcon,
  CheckmarxIcon,
  GitleaksIcon,
  NpmAuditIcon,
  OsvScannerIcon,
  NancyIcon,
  KicsIcon,
  KubescapeIcon,
  TrivyIcon,
  PrismaIcon,
  Prowler,
  DependabotIcon,
  ChainBenchIcon,
  ZapIcon,
  AwsIcon,
  JitFavicon,
  JiraIcon,
  ShortcutIcon,
  LinearIcon,
  SlackIcon,
  NpmIcon,
  DrataIcon,
  ContainerScanIcon,
  WizIcon,
} from 'assets';
import { useIntegrationsContext } from 'context/IntegrationsContext/IntegrationsContext';
import { AssetType, Vendor } from 'types/enums';
import { ISvg } from 'types/interfaces';
import { IntegrationProvider } from 'types/interfaces/Integrations/IIntegration';

const VENDORS_ICONS = new Map<string, FC<ISvg>>([
  [Vendor.Github, GithubGreyIcon],
  [Vendor.AWS, AwsGreyIcon],
  [Vendor.GCP, GcpIcon],
  [Vendor.PARAGON_GCP, GcpIcon],
  [Vendor.AZURE, AzureIcon],
  [Vendor.Domain, WebsiteIcon],
  [Vendor.SEMGREP, SemgrepIcon],
  [Vendor.GHSECURITY, LegitifyIcon],
  [Vendor.GITLAB, GitlabIcon],
  [Vendor.BITBUCKET, BitbucketIcon],
  [Vendor.AZURE_REPOS, AzureReposIcon],
  [Vendor.GOSEC, GosecIcon],
  [Vendor.BANDIT, BanditIcon],
  [Vendor.CHECKMARX, CheckmarxIcon],
  [Vendor.WIZ, WizIcon],
  [Vendor.GITLEAKS, GitleaksIcon],
  [Vendor.NPM_AUDIT, NpmAuditIcon],
  [Vendor.OSV_SCANNER, OsvScannerIcon],
  [Vendor.NANCY, NancyIcon],
  [Vendor.KICS, KicsIcon],
  [Vendor.KUBESCAPE, KubescapeIcon],
  [Vendor.TRIVY, TrivyIcon],
  [Vendor.PRISMA, PrismaIcon],
  [Vendor.PROWLER, Prowler],
  [Vendor.DEPENDABOT, DependabotIcon],
  [Vendor.CHAIN_BENCH, ChainBenchIcon],
  [Vendor.ZAP, ZapIcon],
  [Vendor.NPM, NpmIcon],
  [Vendor.DOCKER, ContainerScanIcon],
]);

const COLORED_ICONS = new Map<string, FC<ISvg>>([
  [Vendor.AWS, AwsIcon],
  [Vendor.JIT, JitFavicon],
  [Vendor.Jira, JiraIcon],
  [Vendor.Drata, DrataIcon],
  [Vendor.Shortcut, ShortcutIcon],
  [Vendor.LINEAR, LinearIcon],
  [Vendor.SLACK, SlackIcon],
]);

const DOMAIN_ICONS = new Map<string, FC<ISvg>>([
  [AssetType.API, ZapAPIIcon],
  [AssetType.WEB, ZapWebIcon],
]);

const getIconComponent = (iconUrl: string): React.FC<ISvg> => {
  const IconComponent: React.FC<ISvg> = (props) => (
    <svg {...props}>
      <image height='100%' href={iconUrl} width='100%' />
    </svg>
  );
  return IconComponent;
};

export const useDetailsByVendor = () => {
  const { integrations, isLoading } = useIntegrationsContext();

  const getIconByVendor = useCallback((vendorName: string | undefined, assetType?: string | undefined, colored: boolean = false) => {
    if (!vendorName) {
      return undefined;
    }

    const vendor = vendorName.toLowerCase();

    if (vendor === Vendor.Domain && assetType) {
      return DOMAIN_ICONS.get(assetType);
    }

    if (colored && COLORED_ICONS.has(vendor)) {
      return COLORED_ICONS.get(vendor);
    }

    if (VENDORS_ICONS.has(vendor)) {
      return VENDORS_ICONS.get(vendor);
    }

    if (!isLoading && integrations && !isEmpty(integrations)) {
      const integration = integrations.find(
        (currIntegration) => currIntegration.vendor === vendor || currIntegration.vendor === vendorName,
      );

      if (integration && integration.provider !== IntegrationProvider.JIT) {
        return getIconComponent(integration.display.icon);
      }
    }

    return undefined;
  }, [integrations, isLoading]);

  const getVendorDisplayName = useCallback((vendorName: string | undefined) => {
    if (isLoading || !vendorName) {
      return '';
    }
    const vendor = vendorName.toLowerCase();

    if (integrations && !isEmpty(integrations)) {
      const integration = integrations.find(
        (currIntegration) => currIntegration.vendor === vendor || currIntegration.vendor === vendorName,
      );

      if (integration && integration.provider !== IntegrationProvider.JIT) {
        return integration.display.name;
      }
    }

    return t(`vendor.${vendor}`);
  }, [integrations, isLoading]);

  return {
    getIconByVendor,
    getVendorDisplayName,
  };
};
