
import { FC } from 'react';

import { CountStatus } from '../CountStatus/CountStatus';

import styles from './TestPRExperienceStatus.module.scss';

import { GithubIcon } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { useAssetsContext } from 'context/AssetsContext/AssetsContext';

export const TestPRExperienceStatus: FC = () => {
  const { repoAssets } = useAssetsContext();

  const mutedText = (
    <div className={styles.wrapper}>
      <JitIcon icon={GithubIcon} size={15} />

      <JitText fontFamily='Inter, serif' fontWeight='inherit' muted text='pages.quickStart.checks.testPRExperience.statusLabel' />
    </div>
  );

  return <CountStatus count={repoAssets.length} mutedText={mutedText} />;
};

