import { Dispatch, SetStateAction, useCallback } from 'react';

import { IAction, IConcealedAction } from 'types/interfaces/Actions';
import { IPaginatedState } from 'types/interfaces/IPaginatedState';
import { IWebsocketNotification } from 'types/interfaces/WebSocket/IWebsocketNotification';
import { assertWebsocketNotificationEntity } from 'utils/functions/assertions/websocketNotificationEntity';

interface Props {
  setActions: Dispatch<SetStateAction<IPaginatedState<(IAction | IConcealedAction)>>>,
  actions: IPaginatedState<(IAction | IConcealedAction)>,
}

export const useHandleActionUpdatesWebSocketNotification = ({ actions, setActions }: Props) => {
  const removeDeletedActions = useCallback((deletedActions: (IAction | IConcealedAction)[]) => (
    actions?.data.filter((prevAction) => !deletedActions?.find((deletedAction) => deletedAction.id === prevAction.id))
  ), [actions?.data]);

  const updateActions = useCallback(
    (updatedActionsInput: (IAction | IConcealedAction)[], currentActions: (IAction | IConcealedAction)[]) => currentActions.map((prevAction) => {
      const updatedAction = updatedActionsInput?.find((action) => action.id === prevAction.id);
      return updatedAction || prevAction;
    }),
    [],
  );

  const handleActionUpdatesWebSocketNotification = useCallback((notification: IWebsocketNotification<IAction | IConcealedAction>) => {
    assertWebsocketNotificationEntity(notification);
    const { message: { updated, deleted } } = notification;
    let updatedActions = removeDeletedActions(deleted);
    updatedActions = updateActions(updated, updatedActions);

    setActions((prevState) => ({
      ...prevState,
      data: updatedActions,
    }));
  }, [removeDeletedActions, setActions, updateActions]);

  return { handleActionUpdatesWebSocketNotification };
};
