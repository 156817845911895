import { FC } from 'react';

import styles from './AllToasts.module.scss';
import { useToastsMapping } from './useToastsMapping';

import { Toast } from 'components/Toast/Toast';
import { useToastsContext } from 'context/ToastsContext/ToastsContext';

interface Props {
  displayTopScreen: boolean,
}

export const AllToasts: FC<Props> = ({ displayTopScreen }) => {
  const { activeToasts } = useToastsContext();
  const toastMapping = useToastsMapping();
  const relevantToasts = activeToasts.filter((activeToast) => toastMapping[activeToast.type].shouldBeRenderedTop === displayTopScreen);
  return null;
  return (
    <div className={styles.toastsWrapper} data-testid='all-toasts' style={{ position: displayTopScreen ? 'relative' : 'absolute' }}>
      {relevantToasts.map((activeToast, index) => (
        <Toast
          key={`${activeToast.type}-${index.toString()}`}
          {...toastMapping[activeToast.type].props}
          {...activeToast.overrideProps}
          index={index}
          type={activeToast.type}
        />
      ))}
    </div>
  );
};
