import { Dispatch, SetStateAction, useCallback } from 'react';

import {
  IPreferences,
  IWebsocketNotification,
} from 'types/interfaces';
import { assertWebsocketNotificationEntity } from 'utils/functions/assertions/websocketNotificationEntity';

interface Props {
  setPreferences: Dispatch<SetStateAction<IPreferences | undefined>>,
}

export const useHandlePreferencesUpdateWebSocketNotification = ({ setPreferences }: Props) => {
  const handlePreferencesUpdateWebSocketNotification = useCallback((notification: IWebsocketNotification<Partial<IPreferences>>) => {
    assertWebsocketNotificationEntity(notification);
    const { message: { updated } } = notification;
    // the updated const is a list but because we update all the preferences at once only the last event matters
    setPreferences((prev) => ({
      ...prev as IPreferences,
      ...updated[updated.length - 1],
    }));
  }, [setPreferences]);

  return { handlePreferencesUpdateWebSocketNotification };
};
