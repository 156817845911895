import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import {
  Box,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { ConfigurationSection } from './components/ConfigurationSection/ConfigurationSection';
import { SecurityToolHeader } from './components/SecurityToolHeader/SecurityToolHeader';

import { JitBreadcrumbs, BreadcrumbItem } from 'components/JitBreadcrumbs/JitBreadcrumbs';
import { JitButton } from 'components/JitButton/JitButton';
import { JitText } from 'components/JitText/JitText';
import { securityTools } from 'pages/SecurityToolsPage/data/securityToolsData';
import { SecurityTool } from 'pages/SecurityToolsPage/types/SecurityTool';

export const SecurityToolDetailPage: FC = () => {
  const { toolName } = useParams<{ toolName: string }>();
  const navigate = useNavigate();
  const [tool, setTool] = useState<SecurityTool | null>(null);

  useEffect(() => {
    if (toolName) {
      // match against the correct security tool from the URL even if it has whitespaces
      const toolDetails = securityTools.find((t) => t.name.toLowerCase().replace(/\s+/g, '-') === toolName);
      setTool(toolDetails || null);
    }
  }, [toolName]);

  const handleBackClick = () => {
    navigate('/security-tools');
  };

  if (!tool) {
    return (
      <Box sx={{ p: 3 }}>
        <JitButton
          onClick={handleBackClick}
          startIcon={<ArrowBackIcon />}
          sx={{ mb: 2 }}
        >
          <JitText text='securityToolsSection.buttons.backToTools' />
        </JitButton>

        <JitText
          color='error'
          size='xxl'
          text='securityToolsSection.notFound'
        />
      </Box>
    );
  }

  const breadcrumbItems: BreadcrumbItem[] = [
    {
      itemKey: 'security-tools',
      content: (
        <JitButton
          color='inherit'
          onClick={handleBackClick}
          sx={{
            minWidth: 'auto',
            p: 0,
          }}
          variant='text'
        >
          <JitText muted text='securityToolsSection.title' />
        </JitButton>
      ),
    },
    {
      itemKey: 'tool-name',
      content: <JitText text={tool.name} />,
    },
  ];

  return (
    <Box sx={{
      p: 3,
      height: '100%',
      overflow: 'auto',
    }}
    >
      <JitBreadcrumbs items={breadcrumbItems} />

      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '650px',
            margin: '0 auto',
          }}
        >
          <SecurityToolHeader tool={tool} />

          {tool.isConfigurable && (
            <ConfigurationSection tool={tool} />
          )}
        </div>
      </div>
    </Box>
  );
};
