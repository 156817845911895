import { Editor } from '@monaco-editor/react';
import { Box } from '@mui/material';
import { FC } from 'react';

import { CircularLoadingSpinner } from 'components/CircularLoadingSpinner/CircularLoadingSpinner';

interface MonacoConfigEditorProps {
  content: string;
  onChange: (value: string) => void;
  language?: string;
  isLoading?: boolean;
  readOnly?: boolean;
}

export const MonacoConfigEditor: FC<MonacoConfigEditorProps> = ({ content, onChange, language = 'text', isLoading = false, readOnly = false }) => {
  if (isLoading) {
    return (
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        my: 4,
      }}
      >
        <CircularLoadingSpinner data-testid='loadingSpinner' />
      </Box>
    );
  }

  return (
    <div data-readonly={readOnly.toString()} data-testid='editor' data-value={content}>
      <Editor
        height='500px'
        language={language}
        onChange={(value) => onChange(value || '')}
        options={{
          readOnly,
          minimap: { enabled: false },
          lineNumbersMinChars: 3,
          scrollBeyondLastLine: false,
          automaticLayout: true,
          fontSize: 14,
          wordWrap: 'on',
        }}
        theme='vs-dark'
        value={content}
      />
    </div>
  );
};
