import { FC, HTMLAttributes, ReactElement, TransitionEvent, useCallback, useEffect, useRef, useState } from 'react';

import styles from './AnimatedList.module.scss';

const shouldSkipEvent = (evt: TransitionEvent<HTMLDivElement>): boolean => {
  if (evt.currentTarget !== evt.target) {
    return true;
  }
  if (!evt.currentTarget.classList.contains(styles.removed)) {
    return true;
  }
  const { key } = evt.currentTarget.dataset;

  if (!key) {
    return true;
  }

  return false;
};

const EMPTY_ARRAY: Array<ReactElement> = [];

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: Array<ReactElement>
}

/**
 * AnimatedList is a component that animates adding/removing of its children.
 * **IMPORTANT**: The children **MUST** have unique keys. Otherwise, undefined behavior will occur.
 */
export const AnimatedList: FC<Props> = ({ children = EMPTY_ARRAY, className, ...props }) => {
  const [renderedChildren, setRenderedChildren] = useState(children);
  const previousChildren = useRef(children);
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  const allChildKeys = new Set(children.map((child) => child.key));
  const isRemoved = (child: ReactElement) => !allChildKeys.has(child.key);
  if (previousChildren.current !== children) {
    previousChildren.current = children;
    setRenderedChildren((current) => {
      const removedChildren = current.filter(isRemoved);

      const nextChildren = [...children];

      removedChildren.forEach((child) => {
        const idx = current.indexOf(child);

        nextChildren.splice(idx, 0, child);
      });

      return nextChildren;
    });
  }

  const handleTransitionEnd = useCallback((evt: TransitionEvent<HTMLDivElement>) => {
    if (shouldSkipEvent(evt)) {
      return;
    }
    const { key } = evt.currentTarget.dataset;

    setRenderedChildren((current) => current.filter((child) => child.key !== key));
  }, []);

  return (
    <div className={`${styles.list} ${className} ${mounted ? styles.mounted : ''}`} {...props}>
      {renderedChildren.map((child) => (
        <div
          key={child.key}
          className={`${styles.item} ${isRemoved(child) ? styles.removed : ''}`}
          data-key={child.key}
          onTransitionEnd={handleTransitionEnd}
        >
          {child}
        </div>
      ))}
    </div>
  );
};
