import { getParsedPlanItemTemplates } from './utils/getParsedPlanItemTemplates';

import { client } from 'services/client';
import { IPlanItemTemplate } from 'types/interfaces';
import { ICachedFile } from 'types/interfaces/Files/ICachedFile';
import { IGetPlanItemTemplateResponse } from 'types/interfaces/PlanTemplate/IGetPlanItemTemplateResponse';
import {
  IWorkFlowTemplateResponse,
  IWorkFlowTemplateServer,
} from 'types/interfaces/PlanTemplate/IWorkFlowTemplateServer';

const serviceName = 'plan';

export const convertWorkflowTemplateObjectToCamelCase = (workFlowTemplateServer: IWorkFlowTemplateServer): IWorkFlowTemplateResponse => ({
  ...workFlowTemplateServer,
  parsedContent: workFlowTemplateServer.parsed_content,
  isScheduled: workFlowTemplateServer.is_scheduled,
});

export const getAllTemplateWorkflows = async () => {
  const url = `${serviceName}/template/workflows`;
  const response: IWorkFlowTemplateServer[] | undefined = await client.get<[IWorkFlowTemplateServer]>(url);
  return response ? response.map(convertWorkflowTemplateObjectToCamelCase) : [];
};

export const getAllPlanItemTemplates = async (): Promise<IPlanItemTemplate[] | undefined> => {
  const url = `${serviceName}/template/items`;
  const response = await client.get<IGetPlanItemTemplateResponse>(url);
  if (!response?.items) return undefined;
  return getParsedPlanItemTemplates(response.items);
};

export const getCachedFile = async (filePath: string): Promise<ICachedFile | undefined> => {
  const url = `${serviceName}/files/${filePath}`;
  return client.get<ICachedFile>(url);
};

export const uploadFileContentToCentralizedRepo = async (filePath: string, content: string) => {
  const url = `${serviceName}/files`;
  const data = {
    path: filePath,
    content,
  };

  return client.post(url, data, {
    headers: { 'content-type': 'application/json' },
  });
};
