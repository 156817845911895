import React, { ReactNode } from 'react';
import { Box, Typography, Button as MuiButton } from '@mui/material';
import { Button } from '../core';

export interface SectionProps {
  /**
   * Title of the section
   */
  title: string;
  
  /**
   * Optional button configuration
   */
  button: {
    text: string;
    variant: 'text' | 'contained' | 'outlined' | 'gradient';
    onClick: () => void;
  };
  
  /**
   * Content to display in the section
   */
  children: ReactNode;
}

/**
 * Section component for displaying titled content blocks with optional action buttons
 */
export const Section: React.FC<SectionProps> = ({ 
  title, 
  button,
  children 
}) => {
  return (
    <Box sx={{ width: '100%', mb: '50px' }}>
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          mb: '20px'
        }}
      >
        <Typography 
          variant="h5" 
          component="h2" 
          sx={{ 
            color: 'white',
            fontFamily: 'Inter',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '18px',
            letterSpacing: '0%'
          }}
        >
          {title}
        </Typography>
        
        {button && (
          <Button
            variant={button.variant}
            onClick={button.onClick}
            text={button.text}
          />
        )}
      </Box>
      
      {children}
    </Box>
  );
};

export default Section; 