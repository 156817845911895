import { FC, useCallback, useEffect } from 'react';

import { useAssetInitialStatsWebsocket } from '../SecurityPlansProgress/hooks/useAssetInitialStatsWebsocket';

import styles from './ExploreResultsStatus.module.scss';

import { CircularLoadingSpinner } from 'components/CircularLoadingSpinner/CircularLoadingSpinner';
import { GradientCircularProgressBar } from 'components/GradientCircularProgressBar/GradientCircularProgressBar';
import { JitText } from 'components/JitText/JitText';
import { useWebsocketSubscribe } from 'context/WebSocketContext/hooks';
import { useAssetsQueries } from 'services/AssetsService/useAssetsQueries';
import { useFindingsCount } from 'services/FindingsService/hooks';
import { WebSocketNotificationTopics } from 'types/enums';
import { IInitialScanStats, IWebsocketNotification } from 'types/interfaces';
import { ICount } from 'types/interfaces/Counts/ICounts';
import { assertWebsocketNotificationEntity } from 'utils/functions/assertions/websocketNotificationEntity';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';

export const ExploreResultsStatus: FC = () => {
  const { websocketSubscribe } = useWebsocketSubscribe();
  const { handleAssetInitialStatsWebsocketNotification } = useAssetInitialStatsWebsocket();
  const { useInitialScanStats } = useAssetsQueries();
  const findingsCountFilters = {
    params: {
      filters: {
        is_backlog: true,
        ignored: false,
      },
    },
  };

  const { data: findingsCount, isLoading: isFindingsLoading, refetch: refetchFindingsCount } = useFindingsCount(findingsCountFilters);
  const { data: assetStats, isLoading: isAssetStatsLoading } = useInitialScanStats();

  const handleStatsUpdate = useCallback((notification: IWebsocketNotification<IInitialScanStats>) => {
    handleAssetInitialStatsWebsocketNotification(notification);
    assertWebsocketNotificationEntity(notification);
    const updatedStats = camelizeSnakeCaseKeys(notification.message.updated) as IInitialScanStats[];
    const allCompleted = updatedStats?.every((stat) => stat.inProgressAssetCount === 0);

    if (allCompleted) {
      refetchFindingsCount();
    }
  }, [handleAssetInitialStatsWebsocketNotification, refetchFindingsCount]);

  useEffect(() => {
    websocketSubscribe(WebSocketNotificationTopics.AssetScanStats, handleStatsUpdate);
  }, [websocketSubscribe, handleStatsUpdate]);

  if (isFindingsLoading) {
    return (
      <div className={styles.container}>
        <CircularLoadingSpinner />
      </div>
    );
  }

  const count = (findingsCount as ICount)?.count ?? 0;
  const isScanning = assetStats?.some((item) => item.inProgressAssetCount > 0);

  return (
    <div className={styles.container}>
      <div className={styles.countContainer}>
        {isScanning && !isAssetStatsLoading && <GradientCircularProgressBar size={20} thickness={6} />}

        <JitText bold muted params={{ count }} text={count} />
      </div>

      <JitText bold muted text='pages.quickStart.checks.exploreResults.statusText' />
    </div>
  );
};
