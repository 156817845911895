import styles from './SelectedRepositories.module.scss';

import { ListSection } from 'components/SCM/ChooseSCMResources/components/common/CoverageSelectionDialog/components/AssetSection/ListSection/ListSection';
import { SelectableAsset } from 'components/SCM/ChooseSCMResources/components/common/CoverageSelectionDialog/hooks/useAssetSelection';
import { Repository } from 'types/interfaces/SCM/SCMMember';

interface SelectedRepositoriesProps {
  isAllSelected: boolean;
  selectedSCMResources: Repository[];
}

export const SelectedRepositories = ({ selectedSCMResources, isAllSelected }: SelectedRepositoriesProps) => {
  if (!selectedSCMResources.length || isAllSelected) {
    return null;
  }

  const items = selectedSCMResources.map((scmResource) => ({ ...scmResource,
    isSelected: true }));
  return (
    <div className={styles.listContainer}>
      <div className={styles.contentContainer}>
        <ListSection getItemName={(item: SelectableAsset) => item.name} items={items} readOnly truncate />
      </div>
    </div>
  );
};
