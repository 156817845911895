import { t } from 'i18next';

import { IGroup, IProject } from 'services/GitlabService/useGitlabService';

export const getSelectedProjectsText = (vendor: string, selectedProjects: IProject[], selectedSubgroups: IGroup[]): string => {
  const subgroupsPlural = selectedSubgroups.length > 1
    ? t(`ChooseSCMResources.${vendor}.nomenclature.subOrganization.plural.uppercase`)
    : t(`ChooseSCMResources.${vendor}.nomenclature.subOrganization.singular.uppercase`);

  const projectsPlural = selectedProjects.length > 1
    ? t(`ChooseSCMResources.${vendor}.nomenclature.repository.plural.uppercase`)
    : t(`ChooseSCMResources.${vendor}.nomenclature.repository.singular.uppercase`);

  if (selectedProjects.length === 0 && selectedSubgroups.length === 0) return '';

  if (selectedProjects.length === 0) {
    return t(
      `ChooseSCMResources.${vendor}.radioButtons.selected.selectedProjectsOrSubgroupsText`,
      {
        count: selectedSubgroups.length,
        plural: subgroupsPlural,
      },
    );
  }

  if (selectedSubgroups.length === 0) {
    return t(
      `ChooseSCMResources.${vendor}.radioButtons.selected.selectedProjectsOrSubgroupsText`,
      {
        count: selectedProjects.length,
        plural: projectsPlural,
      },
    );
  }

  return t(
    `ChooseSCMResources.${vendor}.radioButtons.selected.selectedProjectsAndSubgroupsText`,
    {
      projectsCount: selectedProjects.length,
      projectsPlural,
      subgroupsCount: selectedSubgroups.length,
      subgroupsPlural,
    },
  );
};
