import { FC } from 'react';

import { mainComponentKeyToComponentMap, statusComponentKeyToComponentMap } from './hooks/constants';

type MainComponentsKeys = keyof typeof mainComponentKeyToComponentMap;
type StatusComponentKeys = keyof typeof statusComponentKeyToComponentMap;

type TextsBase = {
  title: string;
  description?: string;
};

type TextsWithStatus = TextsBase & {
  statusCompleted: string;
  statusNotCompleted: string;
};

export enum CheckStatus {
  PENDING = 'pending',
  COMPLETED = 'completed',
  SKIPPED = 'skipped',
}

export type BaseCheck = {
  checkSlug: string;
  order: number;
  isRequired: boolean;
  isHidden: boolean;
  status: CheckStatus;
  mainComponent: MainComponentsKeys;
  dependsOn: string;
  section: string;
  statusComponent?: StatusComponentKeys;
  texts: {
    title: string;
    description: string;
    statusCompleted?: string;
    statusNotCompleted?: string;
  };
};

export type CheckWithStatusComponent = BaseCheck & {
  statusComponent: StatusComponentKeys;
  texts: TextsBase;
};

export type CheckWithoutStatusComponent = BaseCheck & {
  texts: TextsWithStatus;
};

export type Check = CheckWithStatusComponent | CheckWithoutStatusComponent;

export type BottomText = {
  title: string;
  description: string;
  icon: string; // MUI icon name
};

export type Section = {
  sectionSlug: string;
  display: string;
  order: number;
  checks: Check['checkSlug'][];
  bottomText?: BottomText;
  skipButtonText?: string;
};

export type QuickstartGuideData = {
  sections: Section[],
  checks: Check[],
};

export type UICheck = {
  isVisible: boolean;
  mainUIComponent?: FC;
  statusUIComponent?: FC;
};
