import { SeraIcon, RicaIcon, CotaIcon } from '../../assets/agent-svg';
import { ISvg } from 'types/interfaces';

export interface AgentExpertise {
  name: string;
}

export interface AgentOption {
  id: string;
  title: string;
  description: string;
  icon: React.FC<ISvg>;
  isEnabled: boolean;
  expertise: AgentExpertise[];
}

const agentsData: AgentOption[] = [
  {
    id: 'sera',
    title: 'Sera',
    description: 'Security Evaluation and Remediation Agent',
    icon: SeraIcon,
    isEnabled: true,
    expertise: [
      { name: 'Risk Assessment' },
      { name: 'Code Security Review' },
      { name: 'Security Reporting' },
      { name: 'Threat Remediation' },
    ]
  },
  {
    id: 'rica',
    title: 'Rica',
    description: 'Regulation Intelligence and Compliance Agent',
    icon: RicaIcon,
    isEnabled: false,
    expertise: [
      { name: 'Compliance Gap Analysis' },
      { name: 'Framework Mapping' },
      { name: 'Audit Evidence Collection' },
      { name: 'Policy Violation Detection' },
    ]
  },
  {
    id: 'cota',
    title: 'Cota',
    description: 'Communication, Operations, and Ticketing Agent',
    icon: CotaIcon,
    isEnabled: false,
    expertise: [
      { name: 'Incident Response Coordination' },
      { name: 'Alert Management' },
      { name: 'Workflow Automation' },
      { name: 'Team Communication' },
    ]
  }
];

export const useGetAgents = () => {
  return agentsData;
}; 