import { AxiosResponse } from 'axios';
import { useCallback } from 'react';

import { getApiUrls } from 'services/apiUrls';
import { useClient } from 'services/useClient';
import { ITableQueryParams } from 'types/interfaces';
import { ICount, IGroupByCount } from 'types/interfaces/Counts/ICounts';
import { ICreateTicket } from 'types/interfaces/TicketManager/ICreateTicket';
import { Ticket } from 'types/interfaces/TicketManager/Ticket';
import { parseObjectToQueryParams } from 'utils';
import { turnCamelCaseToSnakeCase } from 'utils/functions/camelCaseConverter';

export const useFindingsService = () => {
  const { client } = useClient();

  const createTicket = useCallback(async (body: ICreateTicket): Promise<AxiosResponse<Ticket> | undefined> => {
    const url = getApiUrls.findingService.createTicket();
    return client.post<Ticket>({
      url,
      allowedStatuses: [202, 400],
      requestConfig: {
        data: turnCamelCaseToSnakeCase(body),
      },
    });
  }, [client]);

  const getFindingsCount = useCallback(async (params: ITableQueryParams, backlog: boolean = true): Promise<AxiosResponse<ICount | IGroupByCount[]> | undefined> => {
    const filters = params.filters ? {
      ...params.filters,
      backlog,
    } : null;

    const queryParams = parseObjectToQueryParams({
      ...params,
      ...(filters ? { filters } : {}),
    });
    const url = `${getApiUrls.findingService.getFindingsCount()}?${queryParams}`;
    return client.get<ICount | IGroupByCount[]>({
      url,
      allowedStatuses: [200],
    });
  }, [client]);

  return {
    createTicket,
    getFindingsCount,
  };
};
