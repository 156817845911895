import { FC } from 'react';

import { CustomIntegrationType, CUSTOM_INTEGRATION_VENDOR_TYPES } from './customIntegrations';

import { IntegrationTemplate, IntegrationVendorType } from 'context/IntegrationsContext/templates/interfaces';
import { i18n } from 'locale/i18n';
import { ISvg } from 'types/interfaces';

/**
 * Represents any card that can be filtered
 */
export interface FilterableCard {
  type: 'template' | 'dynamic' | 'custom';
  vendor: string;
  label: string;
  description?: string;
  vendorType: IntegrationVendorType;
  // Original data references for rendering
  template?: IntegrationTemplate;
  dynamicData?: DynamicIntegration;
  customType?: CustomIntegrationType;
  icon?: FC<ISvg> | string;
}

/**
 * Interface for dynamic integration data
 */
export interface DynamicIntegration {
  vendor: string;
  variant: IntegrationVendorType;
  icon: FC<ISvg> | string;
}

/**
 * Filters a card based on the search term
 * @param card The card to filter
 * @param searchTerm The search term to filter by
 * @returns True if the card matches the search term
 */
export const filterCard = (card: FilterableCard, searchTerm: string): boolean => {
  if (!searchTerm) return true;

  const term = searchTerm.toLowerCase().trim();

  // Search in label/title
  if (card.label.toLowerCase().includes(term)) return true;

  // Search in description
  if (card.description?.toLowerCase().includes(term)) return true;

  // Search in vendor type (category)
  const vendorTypeDisplay = i18n.t(`cards.vendorTypes.${card.vendorType}`).toLowerCase();
  if (vendorTypeDisplay.includes(term)) return true;

  // For custom cards, search in vendor-specific translations
  if (card.type === 'custom') {
    // Search in custom card title
    const customTitle = i18n.t(`vendor.${card.vendor}`).toLowerCase();
    if (customTitle.includes(term)) return true;

    // Search in custom card description
    const customDesc = i18n.t(`cards.${card.vendor}.descriptions.primary`).toLowerCase();
    if (customDesc.includes(term)) return true;
  }

  return false;
};

/**
 * Create filterable cards from integration templates
 */
export const createFilterableCardsFromTemplates = (
  templates: IntegrationTemplate[],
): FilterableCard[] => templates.map((template) => ({
  type: 'template',
  vendor: template.vendor,
  label: template.label,
  description: template.shortDescription,
  vendorType: template.vendorType,
  template,
  icon: template.icon,
}));

/**
 * Create filterable cards from dynamic integrations
 */
export const createFilterableCardsFromDynamicIntegrations = (
  dynamicIntegrations: DynamicIntegration[],
  templates: IntegrationTemplate[],
): FilterableCard[] => dynamicIntegrations.map((integration) => {
  const template = templates.find((t) => t.vendor === integration.vendor);
  return {
    type: 'dynamic',
    vendor: integration.vendor,
    label: template?.label || integration.vendor,
    description: template?.shortDescription,
    vendorType: integration.variant,
    dynamicData: integration,
    template,
    icon: integration.icon,
  };
});

/**
 * Create filterable cards for custom cards (AWS, GitHub, Slack)
 */
export const createFilterableCardsForCustomCards = (
  customTypes: CustomIntegrationType[],
  icons: Record<string, FC<ISvg> | string> = {},
): FilterableCard[] => customTypes.map((type) => ({
  type: 'custom',
  customType: type,
  vendor: type,
  label: i18n.t(`vendor.${type}`),
  description: i18n.t(`cards.${type}.descriptions.primary`),
  vendorType: CUSTOM_INTEGRATION_VENDOR_TYPES[type],
  icon: icons[type],
}));
