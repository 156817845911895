import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

type QueryParams = Record<string, string>;

/*
* A simple custom hook that returns current URL query params as an object.
* E.g:
*   For the URL: '.../matrix?plan_id="mvs"&plan_item_id="item-logging"'
*   we will get {
*     plan_id: "mvs",
*     plan_item_id: "item-logging"
* }
* */
export const useQueryParams = () => {
  const [search, setSearch] = useSearchParams();
  const queryParams = useMemo((): QueryParams => Object.fromEntries(search), [search]);
  const setQueryParams = useCallback((update: QueryParams | ((current: URLSearchParams) => QueryParams)) => {
    setSearch(update);
  }, [setSearch]);

  return {
    queryParams,
    setQueryParams,
  };
};
