import { FC, useCallback, useMemo, useState } from 'react';

import { JitDropdownNew } from 'components/JitDropdownNew/JitDropdownNew';
import { MenuItemType } from 'components/JitMenu/JitMenu';
import { TaskDialogTask } from 'components/TaskDialog/TaskDialogTask';
import { useAssetsContext } from 'context/AssetsContext';
import { PLAN_ITEM_SLUG_DEMO_COMMITS } from 'services/CiService/constants';
import { useRunPipelineDemoVulnerability } from 'services/CiService/hooks';
import colors from 'themes/colors.module.scss';
import { Vendor } from 'types/enums';
import { MenuItemKeyType } from 'types/interfaces';
import { PipelineDemoCommitType } from 'types/interfaces/Ci';

interface Props {
  onSuccess: () => void
}
export const TestPRExperienceDialogContent: FC<Props> = ({ onSuccess }) => {
  const { repoAssets, isLoadingAssets } = useAssetsContext();
  const availableRepos = useMemo(() => repoAssets.filter((asset) => asset.is_covered), [repoAssets]);
  const [repo, setRepo] = useState(() => availableRepos.at(0));

  const menuItems = useMemo((): Partial<MenuItemType>[] => availableRepos.map((asset) => ({
    itemKey: asset.asset_id,
    itemName: asset.asset_name,
    isSelected: asset.asset_id === repo?.asset_id,
  })), [availableRepos, repo?.asset_id]);

  const onMenuItemClick = useCallback(({ itemKey }: { itemKey: MenuItemKeyType }) => {
    setRepo(availableRepos.find((asset) => asset.asset_id === itemKey));
  }, [availableRepos]);

  const { mutateAsync: runPipelineDemoVulnerability, isLoading } = useRunPipelineDemoVulnerability();

  const handleSubmit = useCallback(async () => {
    const result = await runPipelineDemoVulnerability({
      plan_item_slug: PLAN_ITEM_SLUG_DEMO_COMMITS.SECRET_DETECTION,
      commit_type: PipelineDemoCommitType.VULNERABILITY,
      vendor: Vendor.Github,
      repo_name: repo?.asset_name,
    });
    if (result?.html_url) {
      window.open(result?.html_url, '_blank');
    }
    onSuccess();
  }, [runPipelineDemoVulnerability, repo?.asset_name, onSuccess]);
  return (
    <TaskDialogTask
      buttonLabel='pages.quickStart.checks.testPRExperience.dialog.buttonLabel'
      description='pages.quickStart.checks.testPRExperience.dialog.description'
      handleClick={handleSubmit}
      isDisabled={!repo}
      isLoading={isLoading}
      title='pages.quickStart.checks.testPRExperience.dialog.title'
    >

      <JitDropdownNew
        borderColor={colors.darkGray03}
        defaultDisplayText='pages.quickStart.checks.testPRExperience.dialog.selectPlaceholder'
        displayText={repo?.asset_name ?? ''}
        height={40}
        isLoading={isLoadingAssets}
        menuItems={menuItems}
        onMenuItemClick={onMenuItemClick}
        padding={20}
      />

    </TaskDialogTask>
  );
};
