import React from 'react';
import {
  Box,
  Stack,
} from '@mui/material';
import { JittoHeaderProps } from '../types';
import { SeraIcon, SlackIconIris } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { JitText } from 'components/JitText/JitText';
import { JitTag } from 'components/JitTag/JitTag';
import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';

const JittoHeader: React.FC<JittoHeaderProps> = ({
  agentInfo,
  actions,
  onSlackChat,
  onDirectChat,
}) => {
  return (
      <div style={{marginTop: '55px'}}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" spacing={2} alignItems="center">
            <Box sx={{ position: 'relative' }}>
              <div style={{transform: 'scale(1.5)', padding: '0 10px'}}>
                <SeraIcon />
              </div>
            </Box>
            <Box>
              <JitText size='l' text={agentInfo.name} />
              <JitText size='s' muted text={agentInfo.joinDate} />
              <div style={{paddingTop: '8px'}}>
                <JitTag textSize='xs' withBorder text={agentInfo.status} color={'#66BB6A'} />
              </div>
            </Box>
          </Stack>

          <Stack direction="row" spacing={4}>
            <JitButton
              variant="text"
              size="small"
              onClick={onSlackChat}
              startIcon={<SlackIconIris />}
            >
              {actions.slack}
            </JitButton>
            <JitGradientButton
              onClick={onDirectChat}
              width='140px'
            >
              <JitText size='s' text={actions.direct.toUpperCase()} />
            </JitGradientButton>
          </Stack>
        </Stack>
      </div>
  );
};

export default JittoHeader; 