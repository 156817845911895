import { FC } from 'react';

import { ActivationPopper } from './ActivationPopper';
import { WorkflowsNew } from './WorkflowsNew';

import { usePipelineContextNew } from 'context/PipelinesContext/PipelinesContextNew';
import { PlanItemSlugDemoCommits } from 'services/CiService/constants';
import { ITableCell } from 'types/interfaces';
import { PipelineDemoCommitType } from 'types/interfaces/Ci';
import { IWorkflowNew } from 'types/interfaces/Pipelines/IPipelineNew';

type WorkFlowMetaData = {
  workflows: IWorkflowNew;
  prLink: string;
  jobRunner: string;
  jitEventId?: string;
  expected?: number;
  activationProperties?: {
    sourceAssetOwner: string;
    sourceAssetName: string;
    planItemSlug: PlanItemSlugDemoCommits;
    commitType: PipelineDemoCommitType;
    prLink: string;
  };
};

export const WorkflowsCellNew: FC<ITableCell> = ({ cell: { value } }) => {
  const { showActivationPopper, setShowActivationPopper } = usePipelineContextNew();

  const { workflows, prLink, jobRunner, jitEventId, expected, activationProperties } = value as WorkFlowMetaData;
  const closePopper = () => {
    setShowActivationPopper(false);
  };

  const innerElement = <WorkflowsNew expected={expected} jitEventId={jitEventId} jobRunner={jobRunner} prLink={prLink} workflows={workflows} />;
  const isActivationPropertiesLoaded = !!activationProperties?.sourceAssetName;
  const shouldShowActivationPopper = !!(activationProperties?.planItemSlug || activationProperties?.commitType);

  const wrapped = (shouldShowActivationPopper ? (
    <ActivationPopper
      assetName={activationProperties.sourceAssetName}
      commitType={activationProperties.commitType}
      dismiss={closePopper}
      isButtonDisabled={!isActivationPropertiesLoaded}
      isOpen={showActivationPopper}
      ownerName={activationProperties.sourceAssetOwner}
      planItemSlug={activationProperties.planItemSlug}
      prLink={activationProperties.prLink}
    >
      {innerElement}
    </ActivationPopper>
  ) : innerElement);

  return (
    wrapped
  );
};
