import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SecurityToolCards } from './components/securityToolCards/SecurityToolCards';
import { SecurityToolsSearch } from './components/SecurityToolsSearch';
import { SecurityToolsSidebar, SecurityToolType } from './components/SecurityToolsSidebar/SecurityToolsSidebar';
import { securityTools } from './data/securityToolsData';
import styles from './SecurityToolsPage.module.scss';
import { filterTool, getCategoryCounts } from './utils/filterSecurityTools';

import { PageTitles } from 'components/PageTitles/PageTitles';
import { useDebounce } from 'utils/hooks/useDebounce';

export const SecurityToolsPage: FC = () => {
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState<SecurityToolType>(SecurityToolType.all);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { debounceValue: debouncedSearchTerm } = useDebounce(searchTerm, 300);

  const handleCategorySelect = (category: string) => {
    setSelectedCategory(category as SecurityToolType);
  };

  const handleSearchChange = (term: string) => {
    setSearchTerm(term);
  };

  const filteredTools = useMemo(
    () => securityTools.filter(
      (tool) => filterTool(tool, debouncedSearchTerm) && (
        selectedCategory === SecurityToolType.all || tool.category === selectedCategory
      ),
    ),
    [debouncedSearchTerm, selectedCategory],
  );

  const filteredCounts = useMemo(() => getCategoryCounts(securityTools, debouncedSearchTerm), [debouncedSearchTerm]);
  const showEmptyState = debouncedSearchTerm && filteredTools.length === 0;

  return (
    <div className={styles.wrapper}>
      <PageTitles subtitle={t('securityToolsSection.subtitle')} title={t('securityToolsSection.title')} />

      <div className={styles.searchWrapper}>
        <SecurityToolsSearch
          onSearchChange={handleSearchChange}
          searchTerm={searchTerm}
        />
      </div>

      <div className={styles.pageContent}>
        <SecurityToolsSidebar
          filteredCounts={filteredCounts}
          onCategorySelect={handleCategorySelect}
          selectedCategory={selectedCategory}
        />

        <div className={styles.page}>
          {showEmptyState ? (
            <SecurityToolCards
              onClearSearch={() => setSearchTerm('')}
              searchTerm={debouncedSearchTerm}
              tools={[]}
            />
          ) : (
            <SecurityToolCards tools={filteredTools} />
          )}
        </div>
      </div>
    </div>
  );
};
