import { FC, useState } from 'react';

import { ActionCardDetailsSection } from '../ActionCardDetailsSection/ActionCardDetailsSection';
import { ActionStatusLabel } from '../ActionStatusLabel/ActionStatusLabel';
import { AIGeneratedLabel } from '../AIGeneratedLabel/AIGeneratedLabel';

import styles from './ActionCardContentText.module.scss';

import { Share } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { ShareDialog } from 'components/JitDialogs/ShareDialog/ShareDialog';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { JitUpgradeButton } from 'components/JitUpgradeButton/JitUpgradeButton';
import { app } from 'locale/en';
import colors from 'themes/colors.module.scss';
import { ActionStatus, FixSuggestionSource, IAction, IActionFinding, IConcealedAction } from 'types/interfaces';

interface ActionCardContentTextProps {
  action: IAction | IConcealedAction;
  notIgnoredFindings: IActionFinding[];
  isConcealed: boolean;
  actionStatus: ActionStatus
}

export const ActionCardContentText: FC<ActionCardContentTextProps> = ({ action, notIgnoredFindings, isConcealed, actionStatus }) => {
  const { title, reason, updated_at: updatedAt, vulnerability_type: vulnerabilityType } = action;
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const actionSource = action.source;
  const concealedStyleIfConcealed = isConcealed ? styles.concealedSection : '';

  return (
    <div className={styles.cardContent}>
      <div className={`${styles.descriptionSection} ${concealedStyleIfConcealed}`}>
        <div className={styles.actionsTitle}>
          <JitText
            bold
            data-testid='actionCardTitle'
            size='l'
            text={title}
          />

          <div className={styles.aiGenLabel}>
            {actionSource === FixSuggestionSource.ai && <AIGeneratedLabel />}
          </div>
        </div>

        <JitText bold className={styles.reasonTitle} color={colors.gray} size='s' text='pages.actions.actionCard.reasonTitle' />

        <JitText color={colors.white} data-testid='actionCardReason' size='m' text={reason} />

      </div>

      <div className={concealedStyleIfConcealed}>
        <ActionCardDetailsSection finding={notIgnoredFindings[0]} updatedAt={updatedAt} vulnerabilityType={vulnerabilityType} />
      </div>

      <div>
        <JitTooltip className={styles.shareTooltip} followCursor={false} placement='top' title={app.pages.actions.actionCard.shareTooltip}>
          <JitButton
            data-testid='shareAction'
            disabled={isConcealed}
            onClick={(event) => {
              event.stopPropagation();
              setIsDialogOpen(true);
            }}
            variant='text'
          >
            <Share />
          </JitButton>
        </JitTooltip>

        {(isDialogOpen && !action.is_concealed) && (
        <ShareDialog
          action={action as IAction} // Since we checked for !action.isConcealed, we can safely cast it to IAction
          data-testid='shareActionDialog'
          isOpen={isDialogOpen}
          setIsOpen={setIsDialogOpen}
        />
        )}

      </div>

      { isConcealed ? <JitUpgradeButton size='small' text='pages.actions.actionCard.premium.upgradeToPremium' /> : <ActionStatusLabel status={actionStatus} />}
    </div>
  );
};
