import { useEffect, useRef, useState } from 'react';

import { useHandleInstallationWebsocketEvent } from './useHandleInstallationWebsocketEvent';

import { useWebsocketSubscribe } from 'context/WebSocketContext/hooks';
import { Vendor, WebSocketNotificationTopics } from 'types/enums';

export const useInstallingVendor = () => {
  const [installingVendor, setInstallingVendor] = useState<Vendor | null>(null);

  const { websocketSubscribe } = useWebsocketSubscribe();

  const currentVendorRef = useRef<Vendor | null>(installingVendor);
  currentVendorRef.current = installingVendor;
  const handle = useHandleInstallationWebsocketEvent({ setInstallingVendor,
    currentVendorRef });
  useEffect(() => {
    websocketSubscribe(WebSocketNotificationTopics.Installation, handle);
  }, [handle, websocketSubscribe]);

  return { installingVendor };
};
