import { Conversation, Message, ReportStatus } from "../JittoContext";
import { MOCK_REPORT_ITEMS } from "./jittoReportData";
import { Report } from "../JittoContext";

export const MOCK_COMPLETED_REPORT: Report = {
    status: ReportStatus.Complete,
    data: MOCK_REPORT_ITEMS,
  }
  
  const initialWelcomeMessage: Message = {
    id: 'welcome',
    source: 'sera',
    content: ['Welcome to your security dashboard! I\'m Sera, your AI security assistant. You can ask me about your security posture, vulnerabilities, compliance status, or recent findings.'],
    timestamp: new Date()
  };

const initialConversation: Conversation = {
    id: '1',
    title: 'Welcome Conversation',
    messages: [initialWelcomeMessage],
    createdAt: new Date(),
    report: MOCK_COMPLETED_REPORT,
  };

const mockConversation: Conversation = {
    id: '2',
    title: 'Top risks of the whole Jit organization',
    messages: [initialWelcomeMessage],
    createdAt: new Date(),
    report: MOCK_COMPLETED_REPORT,
  };

const mockConversation2: Conversation = {
    id: '3',
    title: 'Vulnerability Assessment',
    messages: [initialWelcomeMessage],
    createdAt: new Date(),
    report: MOCK_COMPLETED_REPORT,
  };


  export const MOCK_CONVERSATIONS: Conversation[] = [initialConversation , mockConversation, mockConversation2];