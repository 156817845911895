// Agent Information
export const AGENT_INFO = {
  name: 'Sera - AI Security Agent',
  joinDate: 'Joined our team in March 15, 2024',
  status: 'Invited' as 'Invited' | 'Inactive',
};

// Action Buttons
export const ACTION_BUTTONS = {
  slack: 'Chat via Slack',
  direct: 'Chat with Sera',
};

// Performance Review
export const PERFORMANCE_REVIEW = {
  title: 'Performance Review',
  metrics: [
    {
      id: 'time-saved',
      title: 'Total Time Saved',
      value: '6h 45m',
      subtext: 'this week',
    },
    {
      id: 'tasks-completed',
      title: 'Tasks Completed',
      value: '32',
      subtext: 'automated runs',
    },
    {
      id: 'risk-impact',
      title: 'Risk Impact',
      value: '3',
      subtext: 'critical risks flagged',
    },
    {
      id: 'communications',
      title: 'Communications Insight',
      value: '247',
      subtext: 'successful interactions',
    },
  ],
};

// Expertise Section
export const EXPERTISE_SECTION = {
  title: 'Expertise',
  cards: [
    {
      id: 'risk-assessor',
      title: 'Risk Assessor',
      description: 'Continuously monitors systems and infrastructure to identify security vulnerabilities and compliance gaps.',
      icon: 'risk',
      status: 'active' as 'active' | 'inactive',
    },
    {
      id: 'code-reviewer',
      title: 'Code Reviewer',
      description: 'Analyzes codebase for security vulnerabilities, bad practices, and potential exploits in your software.',
      icon: 'code',
      status: 'inactive' as 'active' | 'inactive',
    },
    {
      id: 'security-reporting',
      title: 'Security Reporting',
      description: 'Generates comprehensive security reports, compliance documentation, and executive summaries.',
      icon: 'report',
      status: 'active' as 'active' | 'inactive',
    },
    {
      id: 'threat-remediation',
      title: 'Threat Remediation',
      description: 'Provides actionable remediation steps and automated fixes for identified security issues.',
      icon: 'threat',
      status: 'inactive' as 'active' | 'inactive',
    },
  ],
  actions: {
    customize: 'Customize Expertise',
    add: 'Add',
  },
}; 