import axios from 'axios';

import { setClientsHeaders as setClientHeadersNew } from './useClient';

import { config } from 'config';
import { constants } from 'globalConstants';
import { logInfo } from 'services/logger/logger';
import { IClient } from 'types/interfaces';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const logRequestFailure = (error: any) => { // This 'any' will be address once we'll refactor our responses handling
  const extra = {
    message: error?.message,
    method: error?.config?.method,
    url: error?.config?.url,
    responseStatusCode: error?.response?.status || '',
    responseData: error?.response?.data || '',
  };
  const message = `API Request Failed - ${extra.responseStatusCode || extra.message}`;
  logInfo(message, extra);
};

export const defaultHeaders = {
  'Content-Type': 'application/json',
};

const createFrontEggClient = () => axios.create({
  baseURL: config.frontEggBaseUrl,
  headers: defaultHeaders,
});

const createClient = () => {
  const instance: IClient = axios.create({
    baseURL: config.apiBaseUrl,
    headers: defaultHeaders,
  });

  instance.interceptors.response.use(
    (response) => response?.data, // Any status code that lie within the range of 2xx cause this function to trigger
    (error) => { // Any status codes that falls outside the range of 2xx cause this function to trigger
      if (error.response?.status === 401 || (error.response?.status === 403 && error.response?.data?.Message === constants.UNAUTHORIZED_MESSAGE)) {
        window.location.href = constants.LOGOUT_PATH;
      }
      logRequestFailure(error);
      return undefined;
    },
  );

  return instance;
};

export const frontEggClient = createFrontEggClient();
export const client = createClient();

export const setClientsHeaders = (values: { [key: string]: string }) => {
  try {
    setClientHeadersNew(values);
    // TODO: Once we'll deprecated the old client, we can remove function and calling directly the one in 'useClient.ts'
    [client, frontEggClient].forEach((instance) => {
      // eslint-disable-next-line no-param-reassign
      instance.defaults.headers.common = {
        ...instance.defaults.headers.common,
        ...values,
      };
    });
    return true;
  } catch (e) {
    console.error('Could not set authorization header for client');
    return undefined;
  }
};

export const tryRun = async <T>(fn: () => Promise<T | undefined>) => {
  const result = await fn();
  if (!result) {
    throw new Error('Request failed');
  }
  return result;
};
