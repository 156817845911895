import { FC, ReactNode } from 'react';

import styles from './TaskDialog.module.scss';

import { CustomDialog } from 'components/JitDialogs/CustomDialog';
import colors from 'themes/colors.module.scss';

interface Props {
  isOpen: boolean;
  rightComponent: ReactNode;
  leftComponent: ReactNode;
  handleClose?: (event: object, reason?: string) => void;
}

export const TaskDialog: FC<Props> = ({ isOpen, rightComponent, leftComponent, handleClose }) => {
  const content = (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        {leftComponent}
      </div>

      <div className={styles.right}>
        {rightComponent}
      </div>
    </div>
  );

  return (
    <CustomDialog
      backgroundColor={colors.navbarBackground}
      borderColor={colors.darkGray03}
      content={content}
      contentPadding={7}
      height='s'
      isOpen={isOpen}
      onClose={handleClose}
      shouldShowEdgesBackground={false}
      width='xl'
    />
  );
};
