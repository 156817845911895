import { FC } from 'react';
import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PageLayout } from 'components/Pages/PageLayout/PageLayout';
import { TitleSection } from './TitleSection';
import { AgentCard } from './AgentCard';
import { useGetAgents } from '../../../context/JittoContext';

// Styled components
const PageContainer = styled(Box)({
  position: 'relative',
  minHeight: '100vh',
  backgroundColor: '#23283D',
});

// Add the central glow effect
const CentralGlow = styled(Box)({
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  background: 'radial-gradient(circle at center 30%, rgba(6, 121, 255, 0.2) 0%, rgba(42, 140, 207, 0.15) 15%, transparent 40%)',
  pointerEvents: 'none',
  zIndex: 0,
});

const ContentContainer = styled(Box)({
  position: 'relative',
  zIndex: 1,
});

export const AgentsGallery: FC = () => {
  const agentsData = useGetAgents();
  
  return (
    <PageLayout>
      <PageContainer>
        <CentralGlow />
        <ContentContainer sx={{ py: '120px', px: { xs: 3, md: '100px' } }}>
          <TitleSection />
          
          <div style={{ 
            display: 'flex',
              justifyContent: 'center',
            alignItems: 'center',
            gap: '40px',
            marginTop: '32px'
          }}>
            {agentsData.map((agent) => (
                <AgentCard agent={agent} key={agent.id}/>
            ))}
          </div>
        </ContentContainer>
      </PageContainer>
    </PageLayout>
  );
};