import { useCallback } from 'react';
import { QueryFunctionContext, useQueryClient } from 'react-query';

import { AssetAggregationKey } from 'pages/Resources/ResourcesDashboardPage/ResourcesDashboardPage';
import { getApiUrls } from 'services/apiUrls';
import { logError } from 'services/logger/logger';
import { useClient } from 'services/useClient';
import { IAggregatedAssetStatistics, IAggregatedAssetStatisticsSnakeCase, IAsset, IUpdateAssetRequest, IInitialScanStats, IFilter, IFilterOption, IDictionary, AssetSortBy } from 'types/interfaces';
import { ICount, IGroupByCount } from 'types/interfaces/Counts/ICounts';
import { IAssetFilterValues } from 'types/interfaces/IAssetFilterValues';
import { IPaginatedResponse } from 'types/interfaces/IPaginatedResponse/IPaginatedResponse';
import { Tag } from 'types/interfaces/Tag';
import { camelizeSnakeCaseKeys, turnCamelCaseToSnakeCase } from 'utils/functions/camelCaseConverter';

type UpdateAssetParams = {
  assetId: string;
  tags: Tag[];
};

type GetAssetCountParams = {
  groupBy?: string;
  filters?: IDictionary<boolean | string>;
};

type GetAssetsPaginatedParams = {
  is_covered?: undefined | boolean;
  team?: undefined | string | string[];
  asset_type?: undefined | string | string[];
  priority_factors?: undefined | string | string[];
  tags?: undefined | Tag[];
};

export const filtersToRequestParams = (filters: IFilter[], joinArrays: boolean): GetAssetsPaginatedParams => {
  const getFilterValues = (entityKey: keyof IAssetFilterValues) => filters.find((filterItem) => filterItem.entityKey === entityKey)?.selectedValue;
  const extractParam = (entityKey: keyof IAssetFilterValues) => {
    const filterValues = getFilterValues(entityKey);
    if (!filterValues) return undefined;
    const values = (filterValues as IFilterOption[]).map((option) => option.value);
    if (joinArrays) {
      return values.join(',');
    }
    return values;
  };
  const tagsFilterValues = getFilterValues('tags') as IFilterOption[] | undefined;
  const tags = tagsFilterValues?.map((tag) => tag.value.split(': '))
    .map(([name, value]) => ({
      name,
      value,
    })) as Tag[];

  return {
    is_covered: true,
    team: extractParam('teams'),
    asset_type: extractParam('asset_types'),
    priority_factors: extractParam('priority_factors'),
    tags,
  };
};

export const useAssetService = () => {
  const { client } = useClient();
  const queryClient = useQueryClient();

  const getAssetsFilterValues = useCallback(async (): Promise<IAssetFilterValues | undefined> => {
    const response = await client.get<IAssetFilterValues>(
      {
        url: getApiUrls.assetService.getAssetsFilterValues(),
        allowedStatuses: [200],
        requestConfig: {
          params: {
            is_active: true,
            is_covered: true,
          },
        },
      },
    );
    if (response?.status === 200) {
      return response.data as IAssetFilterValues;
    }
    throw new Error('Error fetching assets filter values');
  }, [client]);

  interface ISortParams {
    sort_by: AssetSortBy;
    sort_order: string;
  }

  const getAssetsWithPagination = useCallback(async ({
    queryKey,
    pageParam,
  }: QueryFunctionContext<[string, GetAssetsPaginatedParams, ISortParams]>): Promise<IPaginatedResponse<IAsset> | undefined> => {
    const [, filterParams, sortParams] = queryKey;
    const response = await client.get<IPaginatedResponse<IAsset>>(
      {
        url: getApiUrls.assetService.getAssets(),
        allowedStatuses: [200],
        requestConfig: {
          headers: {
            api_version: 'v2',
          },
          params: {
            ...filterParams,
            after: pageParam,
            limit: 80,
            ...sortParams,
          },
        },
      },
    );
    if (response?.status === 200) {
      return response.data as IPaginatedResponse<IAsset>;
    }
    throw new Error('Error fetching assets');
  }, [client]);

  const getAllAssets = useCallback(
    async (teamName?: string): Promise<IAsset[]> => {
      let allAssets: IAsset[] = [];
      let nextPageKey: string | undefined;

      do {
        // eslint-disable-next-line no-await-in-loop
        const response = await queryClient.fetchQuery(
          [
            'assets',
            {
              team: teamName,
            },
            {
              sort_by: AssetSortBy.PriorityScore,
              sort_order: 'desc',
            }],
          // eslint-disable-next-line @typescript-eslint/no-loop-func
          ({
            ...params
          }: QueryFunctionContext<[string, GetAssetsPaginatedParams, ISortParams]>) => getAssetsWithPagination({
            ...params,
            pageParam: nextPageKey,
          }),
        );
        if (response && response.data) {
          allAssets = [...allAssets, ...response.data];
          nextPageKey = response.metadata.after;
        } else {
          nextPageKey = undefined;
        }
      } while (nextPageKey);
      return allAssets;
    },
    [getAssetsWithPagination, queryClient],
  );

  const updateMultipleAssets = useCallback(async (assets: IAsset[]) => {
    const url = getApiUrls.assetService.updateMultipleAssets();
    const assetsPatch: IUpdateAssetRequest[] = assets.map((asset) => ({
      asset_id: asset.asset_id,
      new_name: asset.asset_name,
      is_covered: asset.is_covered,
      tags: asset.tags,
    }));
    return client.post<IAsset[]>({
      url,
      allowedStatuses: [200, 404],
      requestConfig: {
        data: assetsPatch,
      },
    });
  }, [client]);

  type GetAssetsStatisticsParams = {
    aggregationKey?: string;
    filterParams?: IFilter[];
  };
  const getAssetsStatistics = useCallback(
    async ({
      queryKey,
    }: QueryFunctionContext<[string, GetAssetsStatisticsParams]>): Promise<IAggregatedAssetStatistics[] | undefined> => {
      const [, params] = queryKey;
      const {
        filterParams,
        aggregationKey,
      } = params;

      const response = await client.get<IAggregatedAssetStatisticsSnakeCase[]>({
        url: getApiUrls.assetService.getAssetsStatistics(),
        allowedStatuses: [200],
        requestConfig: {
          params: {
            aggregation_key: aggregationKey,
            filters: filtersToRequestParams(filterParams || [], true),
          },
        },
      });

      if (response?.status === 200) {
        if (aggregationKey === AssetAggregationKey.Tags) {
          response.data = response.data.map((stat) => {
            const {
              name,
              value,
            } = stat.aggregation_key as Tag;
            return {
              ...stat,
              aggregation_key: `${name}: ${value}`,
            };
          });
        }
        return camelizeSnakeCaseKeys(response.data) as IAggregatedAssetStatistics[];
      }
      return undefined;
    },
    [client],
  );

  const getInitialScanStats = useCallback(async () => {
    const url = getApiUrls.assetService.getInitialScanStats();
    const response = await client.get<IInitialScanStats[]>({
      url,
      allowedStatuses: [200],
    });

    if (response?.status === 200) {
      return camelizeSnakeCaseKeys(response.data) as IInitialScanStats[];
    }

    return undefined;
  }, [client]);

  const fetchAssetsCount = useCallback(async (params: GetAssetCountParams) => {
    const url = getApiUrls.assetService.getAssetsCount();
    const response = await client.get<ICount | IGroupByCount[]>({
      url,
      allowedStatuses: [200],
      requestConfig: {
        params: turnCamelCaseToSnakeCase(params),
      },
    });

    if (response?.status === 200) {
      return response.data;
    }

    throw new Error('Error fetching assets count');
  }, [client]);

  const updateAsset = useCallback(async ({ assetId, ...restParams }: UpdateAssetParams) => {
    const url = getApiUrls.assetService.updateAsset(assetId);
    return client.patch<IAsset>({
      url,
      allowedStatuses: [200],
      requestConfig: {
        data: turnCamelCaseToSnakeCase(restParams) as object,
      },
    });
  }, [client]);

  const getAssetsInfinite = async (params: {
    vendor?: string;
    asset_type?: string;
    search?: string;
    limit?: number;
    after?: string;
    team?: string;
    is_covered?: boolean;
    sort_by?: AssetSortBy;
    sort_order?: string;
  }): Promise<IPaginatedResponse<IAsset>> => {
    try {
      const response = await client.get<IPaginatedResponse<IAsset>>({
        url: getApiUrls.assetService.getAssets(),
        allowedStatuses: [200],
        requestConfig: {
          headers: {
            api_version: 'v2',
          },
          params,
        },
      });

      if (!response?.data) {
        throw new Error('No data returned from assets API');
      }

      return response.data;
    } catch (error) {
      logError(`Error fetching assets: ${error}`);
      return {
        data: [],
        metadata: {
          count: 0,
          limit: 0,
          after: undefined,
        },
      };
    }
  };

  return {
    getAllAssets,
    getAssetsWithPagination,
    updateMultipleAssets,
    getAssetsStatistics,
    getAssetsFilterValues,
    getInitialScanStats,
    fetchAssetsCount,
    updateAsset,
    getAssetsInfinite,
  };
};
