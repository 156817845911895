import { FC, useCallback } from 'react';

import { SlackChannelSelect } from '../SlackChannelSelect/SlackChannelSelect';

import { PlatformSelect } from './PlatformSelect';

import { MSTeamsChannel, MSTeamsChannelSelect } from 'components/MSTeamsChannelSelect/MSTeasmChannelSelect';
import { ICommunicationPlatformValue } from 'types/interfaces/Teams/ITeam';

interface Props {
  value?: ICommunicationPlatformValue;
  onChange: (value: ICommunicationPlatformValue) => void;
}

export const NotificationsSelect: FC<Props> = ({ value, onChange }) => {
  const handleSelectPlatform = useCallback((selectedVendor?: string) => {
    if (selectedVendor) {
      onChange({ vendor: selectedVendor });
    } else {
      onChange({ vendor: '' });
    }
  }, [onChange]);

  const handleSelectChannel = useCallback((channelId?: string) => {
    onChange({
      vendor: value!.vendor,
      channelId,
    });
  }, [onChange, value]);

  return (
    <>
      <PlatformSelect
        onSelectVendor={handleSelectPlatform}
        selectedVendor={value?.vendor}
      />

      {value?.vendor === 'slack' && <SlackChannelSelect onChange={handleSelectChannel} value={value?.channelId} />}

      {value?.vendor === 'microsoftTeams' && <MSTeamsChannelSelect onChange={handleSelectChannel} value={value?.channelId as MSTeamsChannel} />}
    </>
  );
};
