import {
  Dashboard as DashboardIcon,
  Security as SecurityIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Shield as ShieldIcon,
  BugReport as BugReportIcon,
  Code as CodeIcon,
  Storage as StorageIcon,
  CloudQueue as CloudIcon,
  VpnKey as KeyIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './SecurityToolsSidebar.module.scss';

// Define security tool categories
export enum SecurityToolType {
  all = 'all',
  sca = 'sca',
  compliance = 'compliance',
  secretScanning = 'secretScanning',
  sast = 'sast',
  dast = 'dast',
  container = 'container',
  cloud = 'cloud',
  monitoring = 'monitoring',
}

// Direct mapping between category types and icon components
const CATEGORY_ICON_MAP: Record<string, FC<{ className?: string }>> = {
  [SecurityToolType.all]: DashboardIcon,
  [SecurityToolType.sca]: BugReportIcon,
  [SecurityToolType.compliance]: ShieldIcon,
  [SecurityToolType.secretScanning]: KeyIcon,
  [SecurityToolType.sast]: CodeIcon,
  [SecurityToolType.dast]: SecurityIcon,
  [SecurityToolType.container]: StorageIcon,
  [SecurityToolType.cloud]: CloudIcon,
  [SecurityToolType.monitoring]: VisibilityIcon,
};

interface SecurityToolsSidebarProps {
  selectedCategory: string;
  onCategorySelect: (category: string) => void;
  filteredCounts: Record<string, number>;
}

export const SecurityToolsSidebar: FC<SecurityToolsSidebarProps> = ({
  selectedCategory,
  onCategorySelect,
  filteredCounts,
}) => {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(false);

  // Dynamically build categories from SecurityToolType enum
  const categories = [
    {
      id: SecurityToolType.all,
      label: t('securityToolsSection.categories.all'),
    },
    ...Object.values(SecurityToolType)
      .filter((type) => type !== SecurityToolType.all)
      .map((toolType) => ({
        id: toolType,
        label: t(`securityToolsSection.categories.${toolType}`),
      })),
  ];

  const sidebarTitle = t('securityToolsSection.sidebar.title');

  // Function to render the appropriate icon component
  const renderIcon = (categoryId: string) => {
    const IconComponent = CATEGORY_ICON_MAP[categoryId];
    return IconComponent ? <IconComponent className={styles.categoryIconSvg} /> : null;
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={`${styles.sidebar} ${isCollapsed ? styles.collapsed : ''}`}>
      <div className={styles.sidebarHeader}>
        {!isCollapsed && <div className={styles.sidebarTitle}>{sidebarTitle}</div>}

        <button
          aria-label={isCollapsed ? 'components.securityToolsSidebar.expand' : 'components.securityToolsSidebar.collapse'}
          className={styles.collapseButton}
          onClick={toggleCollapse}
          type='button'
        >
          {isCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </button>
      </div>

      <div className={styles.categoryListContainer}>
        <div className={styles.categoryList}>
          {categories.map((category) => {
            const count = filteredCounts[category.id] || 0;
            const buttonClassName = selectedCategory === category.id
              ? `${styles.categoryItem} ${styles.selected}`
              : styles.categoryItem;

            return (
              <button
                key={category.id}
                className={buttonClassName}
                data-testid={`security-tool-category-${category.id}`}
                onClick={() => onCategorySelect(category.id)}
                title={isCollapsed ? category.label : undefined}
                type='button'
              >
                <span className={styles.categoryIcon}>
                  {renderIcon(category.id)}
                </span>

                {!isCollapsed && (
                  <>
                    <span className={styles.categoryLabel}>{category.label}</span>

                    <span className={styles.count}>{count}</span>
                  </>
                )}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};
