import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useContextEngine } from 'context/GraphContext/hooks/useContextEngine';
import { constants } from 'globalConstants';

const { risks: { BASE_ROUTE, RESOURCES, RESOURCES_LIST } } = constants.routes;

const RESOURCES_ROUTE = `/${BASE_ROUTE}/${RESOURCES}/${RESOURCES_LIST}`;

export const useRedirectToResourcePage = () => {
  // In case the engine is not enabled, the dashboard page should redirect to the resources page
  const navigate = useNavigate();
  const { isContextEngineEnabled, isLoading } = useContextEngine();

  useEffect(() => {
    if (!isContextEngineEnabled && !isLoading) {
      navigate({
        pathname: RESOURCES_ROUTE,
      });
    }
  }, [isContextEngineEnabled, isLoading, navigate]);
};
