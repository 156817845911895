import { useState, useEffect, useMemo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Check, CheckStatus, Section, UICheck } from '../types';

interface UseQuickGuideExpansionProps {
  sections: Section[];
  checksMap: Record<string, Check & UICheck>;
}

interface UseQuickGuideExpansionReturn {
  expandedSectionSlug: string | null;
  handleSectionToggle: (sectionSlug: string) => void;
  isSectionExpanded: (sectionSlug: string) => boolean;
  clearExpandedSection: () => void;
  showAllSections: boolean;
  handleShowAllToggle: () => void;
}

export const useQuickGuideExpansion = ({ sections, checksMap }: UseQuickGuideExpansionProps): UseQuickGuideExpansionReturn => {
  const [expandedSectionSlug, setExpandedSectionSlug] = useState<string | null>(null);
  const [hasUserInteracted, setHasUserInteracted] = useState(false);
  const [showAllSections, setShowAllSections] = useState(false);
  const lastSectionSlug = useMemo(() => sections[sections.length - 1].sectionSlug, [sections]);
  const location = useLocation();
  const navigate = useNavigate();

  const isSectionCompleted = useCallback((sectionChecks: string[]) => sectionChecks.every((checkSlug) => {
    const check = checksMap[checkSlug];
    return !check.isVisible || check.status === CheckStatus.COMPLETED;
  }), [checksMap]);

  useEffect(() => {
    const hash = location.hash.slice(1);
    if (hash) {
      const section = sections.find((s) => s.sectionSlug === hash);
      if (section) {
        setExpandedSectionSlug(hash);
        setHasUserInteracted(true);
        if (isSectionCompleted(section.checks)) {
          setShowAllSections(true);
        }
        return;
      }
    }

    if (hasUserInteracted) {
      return;
    }

    const sectionToExpand = sections.find((section) => {
      const isLastSection = section.sectionSlug === lastSectionSlug;
      const isAllCompleted = isSectionCompleted(section.checks);
      const hasSkippedChecks = section.checks.some((checkSlug) => checksMap[checkSlug].status === CheckStatus.SKIPPED);

      return isLastSection || (!isAllCompleted && !hasSkippedChecks);
    });

    if (sectionToExpand) {
      setExpandedSectionSlug(sectionToExpand.sectionSlug);
    }
  }, [checksMap, sections, lastSectionSlug, hasUserInteracted, location.hash, isSectionCompleted]);

  const handleSectionToggle = (sectionSlug: string) => {
    setHasUserInteracted(true);
    const newExpandedSlug = expandedSectionSlug === sectionSlug ? null : sectionSlug;
    setExpandedSectionSlug(newExpandedSlug);

    if (newExpandedSlug) {
      navigate(`#${newExpandedSlug}`);
    } else {
      navigate(location.pathname);
    }
  };

  const isSectionExpanded = (sectionSlug: string) => expandedSectionSlug === sectionSlug;

  const clearExpandedSection = useCallback(() => {
    setHasUserInteracted(true);
    setExpandedSectionSlug(null);
  }, []);

  const handleShowAllToggle = useCallback(() => {
    clearExpandedSection();
    setShowAllSections(!showAllSections);
  }, [clearExpandedSection, showAllSections]);

  return {
    expandedSectionSlug,
    handleSectionToggle,
    isSectionExpanded,
    clearExpandedSection,
    showAllSections,
    handleShowAllToggle,
  };
};
