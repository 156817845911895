import { FC, useCallback } from 'react';
import { Box, Typography, CircularProgress, IconButton, Button } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';
import { useItemDrilldown } from './useItemDrilldown';
import SectionRenderer from './SectionRenderer';
import { useJittoContext } from 'context/JittoContext/JittoContext';
import styles from './ItemDrilldownPanel.module.scss';
import { JitSidePanel } from 'components/JitSidePanel/JitSidePanel';

interface ItemDrilldownContentProps {
  onClose: () => void;
}

const ItemDrilldownContent: FC<ItemDrilldownContentProps> = ({ onClose }) => {
  const { drilldownData, isLoading, error, isError, refetch } = useItemDrilldown();
  const { currentDrilldownItemId } = useJittoContext();

  const handleRefresh = useCallback(() => {
    refetch();
  }, [refetch]);

  return (
    <JitSidePanel closePanel={onClose} title=''>
      {/* Content */}
      <Box sx={{ 
        flexGrow: 1, 
        overflow: 'auto',
        p: 2
      }} className={styles.content}>
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="200px">
            <CircularProgress />
          </Box>
        ) : isError ? (
          <Box 
            sx={{ 
              p: 3, 
              textAlign: 'center',
              backgroundColor: 'error.light',
              borderRadius: '8px',
              mt: 2
            }}
          >
            <Typography color="error" gutterBottom>
              Error loading detail view
            </Typography>
            <Typography variant="body2" color="text.secondary" paragraph>
              {error || 'An unexpected error occurred'}
            </Typography>
            <Button 
              variant="outlined" 
              onClick={handleRefresh}
              startIcon={<RefreshIcon />}
            >
              Try again
            </Button>
          </Box>
        ) : drilldownData.sections.length === 0 ? (
          <Box sx={{ p: 3, textAlign: 'center', mt: 2 }}>
            <Typography color="textSecondary">
              No detailed information available for this item
            </Typography>
            <Button 
              variant="outlined" 
              onClick={handleRefresh}
              startIcon={<RefreshIcon />}
              sx={{ mt: 2 }}
            >
              Refresh
            </Button>
          </Box>
        ) : (
          drilldownData.sections.map((section, index) => (
            <SectionRenderer key={index} section={section} />
          ))
        )}
      </Box>
    </JitSidePanel>
  );
};

export default ItemDrilldownContent; 