import { FC, useMemo } from 'react';

import { PriorityBoxIcon } from './PriorityBoxIcon';
import { useScoreColor } from './useScoreColor';

import { IconTextBox } from 'components/IconTextBox/IconTextBox';
import { TextSize } from 'utils/constants/textSizeMap';

interface Props {
  priorityScore: number;
  withBorder?: boolean;
  disabled?: boolean;
  dynamicColor?: boolean;
  size?: TextSize;
  iconSize?: TextSize;
  bold?: boolean;
}

export const PriorityBox: FC<Props> = ({ priorityScore, withBorder = true, disabled, dynamicColor = true, size = 's', iconSize = size, bold = true }) => {
  const color = useScoreColor({ priorityScore,
    dynamicColor,
    disabled });

  const icon = useMemo(() => (
    <PriorityBoxIcon disabled={disabled} dynamicColor={dynamicColor} priorityScore={priorityScore} size={iconSize} />
  ), [disabled, dynamicColor, priorityScore, iconSize]);

  return (
    <IconTextBox bold={bold} color={color} icon={icon} size={size} testId='priorityBox' text={Math.abs(priorityScore)} withBorder={withBorder} />
  );
};
