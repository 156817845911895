import { orderBy, uniqBy } from 'lodash';
import { useCallback, useEffect, useMemo, ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ConfigurePlanItemButton } from '../components/ConfigurePlanItemButton/ConfigurePlanItemButton';

import { JitButton } from 'components/JitButton/JitButton';
import { JitText } from 'components/JitText/JitText';
import { JitUpgradeButton } from 'components/JitUpgradeButton/JitUpgradeButton';
import { useGetPlanItemConfigurationsStatus } from 'context/ConfigurationsContext/hooks';
import { useIntegrationsContext } from 'context/IntegrationsContext';
import { IntegrationVendorType } from 'context/IntegrationsContext/templates/interfaces';
import { usePlanInstanceContext } from 'context/PlanInstanceContext';
import { usePlansContext } from 'context/PlansContext';
import { useActivatePlanItem } from 'context/PlansContext/hooks/useActivatePlanItem';
import { useGetIsUserEntitledToPlanItem } from 'context/StiggContext/useGetIsUserEntitledToPlanItem';
import { useTenantContext } from 'context/TenantContext';
import { CONFIGURE_CLOUD_SECTION_SLUG } from 'context/TenantContext/constant';
import { constants } from 'globalConstants';
import { useInitialScans } from 'services/AssetsService/hooks';
import colors from 'themes/colors.module.scss';
import { Vendor } from 'types/enums';
import { IInitialScanStats, IPlanItemDetails } from 'types/interfaces';
import { IntegrationStatus } from 'types/interfaces/Integrations';
import { isVendorCloudProvider } from 'utils/functions/matchVendorToAssetType';

const { PLAN_SLUG: { MVS, MY_PLAN },
  PLAN_ITEMS_SLUG: { RUNTIME_MISCONFIGURATION_DETECTION_GCP, IAC_MISCONFIGURATION_DETECTION, ZAP_WEB, PROWLER, LEGITIFY } } = constants;

interface OptionalPlanItem {
  title: string;
  action?: ReactNode;
  completed?: number;
  total?: number;
  isActivated?: boolean;
  slug: string;
}

const TRANSLATIONS = {
  activate: 'pages.quickStart.checks.securityPlansProcess.optionalPlans.activate',
  missingCloud: 'pages.quickStart.checks.securityPlansProcess.optionalPlans.missingCloudIntegration',
};

export const usePlanItems = () => {
  const navigate = useNavigate();
  const { installations } = useTenantContext();
  const { integrations } = useIntegrationsContext();
  const { activatePlanItem } = useActivatePlanItem();
  const { plans, fetchPlans, fetchPlanDetails, isFetchingPlans } = usePlansContext();
  const [loadingItemSlug, setLoadingItemSlug] = useState<string | null>(null);
  const { isCommittingPlan, setInitialPlan, isLoadingPlan } = usePlanInstanceContext();
  const { isConfigurable, isConfigurationMissing, isIntegrated } = useGetPlanItemConfigurationsStatus();
  const { data: assetStats, isLoading: isAssetStatsLoading } = useInitialScans();

  useEffect(() => {
    fetchPlans();
    fetchPlanDetails(MVS);
    setInitialPlan();
  }, [fetchPlans, fetchPlanDetails, setInitialPlan]);

  useEffect(() => {
    if (!isCommittingPlan) {
      setLoadingItemSlug(null);
    }
  }, [isCommittingPlan]);

  const planDetails = plans[MVS];
  const isLoading = (isFetchingPlans && !planDetails) || !planDetails?.hasFetchedItems || isLoadingPlan;

  const handleActivatePlanItem = useCallback(async (itemSlug: string) => {
    const itemDetails = planDetails?.items?.[itemSlug];
    if (itemDetails) {
      setLoadingItemSlug(itemSlug);
      try {
        await activatePlanItem(MY_PLAN, itemDetails);
      } catch (error) {
        setLoadingItemSlug(null);
      }
    }
  }, [activatePlanItem, planDetails]);

  const activeCloudVendor = useMemo(() => {
    const cloudIntegration = integrations?.find(
      (integration) => integration.display?.categoryKey === IntegrationVendorType.cloud
        && integration.status === IntegrationStatus.SUCCESS,
    );
    if (cloudIntegration) return cloudIntegration.vendor;

    const cloudProviderInstallation = installations?.find(
      (installation) => isVendorCloudProvider(installation.vendor as Vendor),
    );
    if (cloudProviderInstallation) return cloudProviderInstallation.vendor;

    return undefined;
  }, [installations, integrations]);

  const isUserEntitledToPlanItem = useGetIsUserEntitledToPlanItem();

  const createActionButton = useCallback((text: string, onClick: () => void, itemSlug: string) => {
    const isItemLoading = loadingItemSlug === itemSlug;
    const isDisabled = loadingItemSlug !== null || isCommittingPlan;

    return (
      <JitButton
        disabled={isDisabled}
        isLoading={isItemLoading}
        onClick={onClick}
        showChildrenWhileLoading
        size='small'
        variant='text'
      >
        <JitText color={colors.iris} text={text} />
      </JitButton>
    );
  }, [loadingItemSlug, isCommittingPlan]);

  const getStatsForItem = useCallback((slug: string) => {
    const stats = assetStats?.find((stat) => stat.planItemSlug === slug);
    const itemDetails = planDetails?.items?.[slug];
    const isBeingActivated = loadingItemSlug === slug;

    // Keep original stats and mark as not activated during activation process
    if (isBeingActivated) {
      return {
        completed: stats?.completedAssetCount || 0,
        total: (stats?.completedAssetCount || 0) + (stats?.inProgressAssetCount || 0),
        isActivated: false,
      };
    }

    // Only show scanning state (0/0) when the item is active and has no stats yet
    // This will happen after isCommittingPlan becomes false
    if (itemDetails?.is_active && !stats) {
      return {
        completed: 0,
        total: 0,
        isActivated: true,
      };
    }

    const isActivated = itemDetails?.is_active;
    return {
      completed: stats?.completedAssetCount || 0,
      total: (stats?.completedAssetCount || 0) + (stats?.inProgressAssetCount || 0),
      isActivated,
    };
  }, [assetStats, loadingItemSlug, planDetails?.items]);

  const createBasicPlanItem = useCallback((slug: string, itemDetails: IPlanItemDetails, stats: { completed: number; total: number; isActivated: boolean }): OptionalPlanItem => ({
    slug,
    title: itemDetails.name,
    ...stats,
  }), []);

  const createActivatablePlanItem = useCallback((basicItem: OptionalPlanItem, activateAction: () => void, isActivated: boolean): OptionalPlanItem => {
    const planItemDetails = planDetails?.items?.[basicItem.slug];
    if (isActivated || !planItemDetails) {
      return basicItem;
    }
    const shouldConfigure = isConfigurable(planItemDetails);

    if (!isUserEntitledToPlanItem(basicItem.slug)) {
      return {
        ...basicItem,
        action: <JitUpgradeButton size='small' text='common.pricingPlan.upgradeNow' textSize='s' />,
      };
    }

    if (isConfigurationMissing(planItemDetails) && !isIntegrated(planItemDetails)) {
      return {
        ...basicItem,
        action: createActionButton(
          TRANSLATIONS.missingCloud,
          () => navigate(`#${CONFIGURE_CLOUD_SECTION_SLUG}`),
          basicItem.slug,
        ),
      };
    }

    return ({
      ...basicItem,
      action: shouldConfigure ? (
        <ConfigurePlanItemButton
          isDisabled={loadingItemSlug !== null || isCommittingPlan}
          isLoading={loadingItemSlug === basicItem.slug}
          itemDetails={planItemDetails}
          planSlug={MVS}
        />
      ) : createActionButton(
        TRANSLATIONS.activate,
        activateAction,
        basicItem.slug,
      ),
    });
  }, [createActionButton, isCommittingPlan, isConfigurable, isConfigurationMissing, isIntegrated, isUserEntitledToPlanItem, loadingItemSlug, navigate, planDetails?.items]);

  const createPlanItem = useCallback((slug: string): OptionalPlanItem | null => {
    const itemDetails = planDetails?.items?.[slug];
    if (!itemDetails) return null;

    const stats = getStatsForItem(slug);
    const basicItem = createBasicPlanItem(slug, itemDetails, {
      completed: stats.completed,
      total: stats.total,
      isActivated: stats.isActivated ?? false,
    });

    return createActivatablePlanItem(
      basicItem,
      () => handleActivatePlanItem(slug),
      stats.isActivated ?? false,
    );
  }, [planDetails?.items, getStatsForItem, createBasicPlanItem, createActivatablePlanItem, handleActivatePlanItem]);

  const getCloudSpecificItems = useCallback((): OptionalPlanItem[] => {
    if (activeCloudVendor?.includes(Vendor.GCP)) {
      const gcpItem = createPlanItem(RUNTIME_MISCONFIGURATION_DETECTION_GCP);
      return gcpItem ? [gcpItem] : [];
    }

    const runtimeItem = createPlanItem(PROWLER);
    return runtimeItem ? [runtimeItem] : [];

    return [];
  }, [activeCloudVendor, createPlanItem]);

  const additionalPlanItems = useMemo<OptionalPlanItem[]>(() => {
    if (isLoading) return [];

    const baseItems = [
      createPlanItem(IAC_MISCONFIGURATION_DETECTION),
      createPlanItem(ZAP_WEB),
      createPlanItem(LEGITIFY),
    ].filter((item): item is OptionalPlanItem => item !== null);

    const cloudItems = getCloudSpecificItems();

    const allPlanItems = orderBy(Array.from(planDetails?.allItemSlugs ?? []), (item) => item)
      .map(createPlanItem)
      .filter((item): item is OptionalPlanItem => item !== null);

    return uniqBy([...baseItems, ...cloudItems, ...allPlanItems], 'slug')
      .filter((item) => !item?.isActivated)
      .slice(0, 3);
  }, [isLoading, createPlanItem, getCloudSpecificItems, planDetails?.allItemSlugs]);

  const allActiveItems = useMemo((): IInitialScanStats[] | undefined => {
    if (!assetStats) {
      return undefined;
    }
    if (!planDetails) {
      return assetStats;
    }

    const activeItemStats = Array.from(planDetails.activeItemSlugs).map((slug): IInitialScanStats => {
      const detail = planDetails.items?.[slug];
      const stat = assetStats.find((assetStat) => assetStat.planItemSlug === slug);
      if (stat) {
        return stat;
      }

      return ({
        planItemSlug: slug,
        completedAssetCount: 0,
        inProgressAssetCount: 0,
        planItemDisplayName: detail?.name ?? '',
      });
    });

    return orderBy(activeItemStats, 'planItemSlug')
      .filter((item) => !additionalPlanItems.find((optionalItem) => optionalItem.slug === item.planItemSlug));
  }, [assetStats, planDetails, additionalPlanItems]);

  return {
    planItems: additionalPlanItems,
    activeCloudVendor,
    isLoading: isLoading || isAssetStatsLoading,
    planDetails,
    assetStats: allActiveItems,
  };
};
