/* eslint-disable max-nested-callbacks */
import { useCallback, useEffect, useMemo } from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';

import { useWebsocketSubscribe } from 'context/WebSocketContext/hooks';
import { useWorkflowsService } from 'services/WorkflowsService/useWorkflowsService';
import { WebSocketNotificationTopics } from 'types/enums';
import { Queries } from 'types/enums/Queries';
import { IWebsocketNotification, IWebsocketNotificationEntity } from 'types/interfaces/WebSocket';
import { IWorkflow } from 'types/interfaces/Workflows/IWorkflow';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';

interface WorkflowsData {
  pages: Array<{
    data: IWorkflow[];
    metadata?: {
      after?: string;
    };
  }>;
}

export const useWorkflows = (shouldFetch: boolean) => {
  const { getWorkflows } = useWorkflowsService();
  const { websocketSubscribe } = useWebsocketSubscribe();
  const queryClient = useQueryClient();

  const {
    data,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    isIdle,
  } = useInfiniteQuery(
    [Queries.Workflows],
    ({ pageParam }) => getWorkflows({ after: pageParam }),
    {
      enabled: shouldFetch,
      getNextPageParam: (lastPage) => lastPage?.metadata?.after || undefined,
      refetchOnMount: true,
    },
  );

  const transformWorkflowsData = useCallback((workflows: IWorkflow[], notification: IWebsocketNotificationEntity<IWorkflow>) => workflows
    .map((workflow: IWorkflow) => {
      // eslint-disable-next-line max-nested-callbacks
      const updatedWorkflow = notification.message.updated.find(
        (updated) => updated.id === workflow.id,
      );

      if (updatedWorkflow) {
        return camelizeSnakeCaseKeys(updatedWorkflow);
      }

      if (notification.message.deleted.some(
        (deleted) => deleted.id === workflow.id,
      )) return null;

      return workflow;
    })
    .filter((workflow): workflow is IWorkflow => workflow !== null), []);

  const handleWorkflowStatusChange = useCallback((notification: IWebsocketNotification<IWorkflow>) => {
    queryClient.setQueryData(
      [Queries.Workflows],
      (oldData: WorkflowsData | undefined): WorkflowsData => {
        if (!oldData?.pages) {
          return {
            pages: [{
              data: [],
              metadata: { after: undefined },
            }],
          };
        }

        return {
          ...oldData,
          pages: oldData.pages.map((page) => ({
            ...page,
            data: transformWorkflowsData(page.data, notification as IWebsocketNotificationEntity<IWorkflow>),
          })),
        };
      },
    );
  }, [queryClient, transformWorkflowsData]);

  // Subscribe to websocket notifications
  useEffect(() => {
    websocketSubscribe(
      WebSocketNotificationTopics.WorkflowStatusChange,
      handleWorkflowStatusChange,
    );
  }, [handleWorkflowStatusChange, websocketSubscribe]);

  const workflows = useMemo(
    () => data?.pages.flatMap((page) => page?.data || []) || [],
    [data?.pages],
  );

  return {
    workflows,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    isIdle,
  };
};
