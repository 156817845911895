import { EVENTS_TYPES } from 'pages/PipelinesPage/constants';
import { runPipelineDemoVulnerability } from 'services/CiService';
import { PlanItemSlugDemoCommits } from 'services/CiService/constants';
import { AssetType, Vendor } from 'types/enums';
import { PipelineStatus } from 'types/enums/PipelineStatus';
import { PipelineDemoCommitType } from 'types/interfaces/Ci';
import { IMockPipelineNew, IPipelineNew } from 'types/interfaces/Pipelines/IPipelineNew';

export const runPipelineDemoNew = async (planItemSlug: PlanItemSlugDemoCommits, commitType: PipelineDemoCommitType) => runPipelineDemoVulnerability({
  plan_item_slug: planItemSlug,
  commit_type: commitType,
});
const prepareMockPipelineNew = (repoName: string, pullRequestNumber: number, planItemSlug: string, commitType: PipelineDemoCommitType, owner: string): IMockPipelineNew => ({
  additionalAttributes:
      {
        pullRequestNumber,
        originalRepository: repoName,
      },
  assetId: '',
  assetName: repoName,
  assetType: AssetType.REPO,
  createdAt: new Date().toISOString(),
  jitEventId: '',
  jitEventName: EVENTS_TYPES.PULL_REQUEST_CREATED,
  pipelineId: '',
  status: PipelineStatus.QUEUED,
  sourceAssetOwner: owner,
  sourceAssetName: repoName,
  sourceAssetType: AssetType.REPO,
  sourceAssetVendor: Vendor.Github,
  tenantId: '',
  version: 0,
  vendor: Vendor.Github,
  workflows: {},
  planItemSlug,
  commitType,
} as unknown as IMockPipelineNew);

export const createMockPipelineNew = (
  repoName: string,
  pullRequestNumber: number,
  planItemSlug: string,
  commitType: PipelineDemoCommitType,
  owner: string,
  setActivationState: (demoPipeline: IMockPipelineNew, showActivationPopper: boolean) => void,
) => {
  const demoPipeline = prepareMockPipelineNew(repoName, pullRequestNumber, planItemSlug, commitType, owner);
  setActivationState(demoPipeline, true);
  return demoPipeline;
};

export const createPlaceholderMockPipelineNew = (
  planItemSlug: string,
  commitType: PipelineDemoCommitType,
  setActivationState: (demoPipeline: IMockPipelineNew, showActivationPopper: boolean) => void,
) => {
  const demoPipeline = prepareMockPipelineNew('', 0, planItemSlug, commitType, '');
  setActivationState(demoPipeline, true);
  return demoPipeline;
};

export const downcastMockPipelineNew = (mockPipeline: IMockPipelineNew): IPipelineNew => {
  const { planItemSlug, commitType, ...rest } = mockPipeline;
  return { ...rest };
};
