
import { memo, useCallback, useMemo, useState } from 'react';

import { PlanItemManual } from 'components/Configurations/PlanItemManual/PlanItemManual';
import { JitButton } from 'components/JitButton/JitButton';
import { JitText } from 'components/JitText/JitText';
import { useGetConfigurationsComponentDetails } from 'context/ConfigurationsContext/hooks';
import { ConfigurePlanItemDialog } from 'pages/PlanPage/components';
import colors from 'themes/colors.module.scss';
import { IPlanItemDetails } from 'types/interfaces';

interface Props {
  itemDetails: IPlanItemDetails
  isDisabled: boolean
  isLoading: boolean
  planSlug: string
}
export const ConfigurePlanItemButton = memo(({ itemDetails, isDisabled, isLoading, planSlug }: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleClose = useCallback(() => setDialogOpen(false), []);
  const handleOpen = useCallback(() => setDialogOpen(true), []);
  const getConfigurationsComponentDetails = useGetConfigurationsComponentDetails();
  const autoConfig = useMemo(() => getConfigurationsComponentDetails(itemDetails), [getConfigurationsComponentDetails, itemDetails]);

  return (
    <>
      <JitButton
        disabled={isDisabled}
        isLoading={isLoading}
        onClick={handleOpen}
        showChildrenWhileLoading
        size='small'
        variant='text'
      >
        <JitText color={colors.iris} text='pages.quickStart.checks.securityPlansProcess.optionalPlans.activate' />
      </JitButton>

      <PlanItemManual
        isOpen={!!itemDetails.is_manual && dialogOpen}
        onClose={handleClose}
        planItem={itemDetails}
        planSlug={planSlug}
      />

      {autoConfig && !itemDetails.is_manual && dialogOpen && (
      <ConfigurePlanItemDialog
        handleClose={handleClose}
        isOpen={!itemDetails.is_manual && dialogOpen}
        planItemSlugToConfigure={itemDetails.slug}
        planSlug={planSlug}
        {...autoConfig}
      />
      )}
    </>
  );
});
