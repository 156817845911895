import { FC } from 'react';

export const RicaIcon: FC = () => (
  <svg width="62" height="57" viewBox="0 0 62 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="-1.12624e-07" y="1.88951" width="31.5481" height="31.5481" transform="matrix(0.866025 0.5 -0.866025 0.5 32.6691 22.1409)" stroke="url(#paint0_linear_299_89224)" strokeWidth="3.77903"/>
    <rect x="-1.12624e-07" y="1.88951" width="31.5481" height="31.5481" transform="matrix(0.866025 0.5 -0.866025 0.5 32.6691 15.0757)" stroke="url(#paint1_linear_299_89224)" strokeWidth="3.77903"/>
    <rect x="-1.12624e-07" y="1.88951" width="31.5481" height="31.5481" transform="matrix(0.866025 0.5 -0.866025 0.5 32.6691 8.01006)" stroke="url(#paint2_linear_299_89224)" strokeWidth="3.77903"/>
    <rect x="-1.12624e-07" y="1.88951" width="31.5481" height="31.5481" transform="matrix(0.866025 0.5 -0.866025 0.5 32.6691 0.944757)" stroke="url(#paint3_linear_299_89224)" strokeWidth="3.77903"/>
    <defs>
      <linearGradient id="paint0_linear_299_89224" x1="-20.9582" y1="-20.9581" x2="40.7948" y2="-20.9581" gradientUnits="userSpaceOnUse">
        <stop stopColor="white"/>
        <stop offset="1" stopColor="white" stopOpacity="0.2"/>
      </linearGradient>
      <linearGradient id="paint1_linear_299_89224" x1="-17.0329" y1="-17.0329" x2="39.1379" y2="-17.0329" gradientUnits="userSpaceOnUse">
        <stop offset="0.012919" stopColor="white"/>
        <stop offset="1" stopColor="white" stopOpacity="0.2"/>
      </linearGradient>
      <linearGradient id="paint2_linear_299_89224" x1="-7.17615" y1="-7.17615" x2="41.3191" y2="-7.17615" gradientUnits="userSpaceOnUse">
        <stop stopColor="white"/>
        <stop offset="1" stopColor="white" stopOpacity="0.2"/>
      </linearGradient>
      <linearGradient id="paint3_linear_299_89224" x1="-4.64643" y1="-4.64643" x2="40.3599" y2="-4.64642" gradientUnits="userSpaceOnUse">
        <stop stopColor="white"/>
        <stop offset="1" stopColor="white" stopOpacity="0.2"/>
      </linearGradient>
    </defs>
  </svg>
); 