import { isEmpty } from 'lodash';
import { FC } from 'react';

import { JitText } from 'components/JitText/JitText';
import { TicketsList } from 'components/TicketsList/TicketsList';
import colors from 'themes/colors.module.scss';
import { IFinding } from 'types/interfaces';

interface Props {
  finding: IFinding;
  shouldDisplayCreateTicketButton?: boolean;
}

export const FindingDetailsTickets: FC<Props> = ({ finding, shouldDisplayCreateTicketButton }) => (
  <>
    {(!isEmpty(finding.tickets) || shouldDisplayCreateTicketButton) && (
      <JitText
        color={colors.lightGray}
        data-testid='ticket-section-header'
        size='s'
        text='pages.pipelineDetails.findingsDetails.ticketSectionHeader'
      />
    )}

    {finding.tickets && <TicketsList tickets={finding.tickets} />}
  </>
);
