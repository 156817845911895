import { DrilldownResponse } from 'types/interfaces/Drilldown/types';

export const mockAwsS3BucketsDrilldown: DrilldownResponse = {
  sections: [
    {
      type: 'FindingInfo',
      title: 'Exposed AWS S3 Buckets with Sensitive Data',
      description: 'The detected S3 buckets allow public read permissions, making them accessible to anyone on the internet.'
    },
    {
      type: 'TextSection',
      variant: 'list',
      title: 'Justification summary',
      intro_text: 'This risk received a High Priority Score (87) based on the following factors:',
      sections: [
        {
          title: 'Impact Score',
          description: 'High impact due to critical application handling sensitive customer data. The application\'s tier-1 classification (×0.6) combined with PII data sensitivity (×0.4) resulted in an elevated impact score.'
        },
        {
          title: 'Exploitability Score',
          description: 'Moderate to high exploitability considering the public exposure of the API endpoint and known exploit patterns. The service\'s internet accessibility increases the likelihood of exploitation.'
        },
        {
          title: 'Control Influence',
          description: 'Significant control gaps identified, including missing WAF protection and inadequate access controls. The absence of runtime protection mechanisms further elevates the risk level.'
        },
        {
          title: 'Compliance Impact',
          description: 'Critical compliance implications due to violations of SOC2, GDPR, and PCI requirements. The handling of regulated data without proper controls triggers multiple compliance flags.'
        }
      ]
    },
    {
      type: 'TaskSteps',
      title: 'Task Steps',
      steps: [
        { 
          id: 'step-3-1', 
          title: 'Patch Inventory', 
          status: 'completed', 
          tools: ['PatchDB', 'VersionTracker'],
          sources: ['Vendor Updates']
        },
        { 
          id: 'step-3-2', 
          title: 'Environment Scan', 
          status: 'completed', 
          description: 'Comprehensive scan of all system components to determine current patch levels and identify missing updates.',
          tools: ['SystemScanner'],
          sources: ['OS Configurations', 'Application Inventories', 'Dependency Trees']
        },
        { 
          id: 'step-3-3', 
          title: 'Patch Validation', 
          status: 'completed', 
          tools: [],
          sources: []
        },
        { 
          id: 'step-3-4', 
          title: 'Security Impact Analysis', 
          status: 'waiting_for_approval', 
          description: 'Analysis of potential security impacts from applying or not applying critical patches. Requires approval to proceed with recommended actions.',
          tools: ['ImpactAnalyzer', 'RiskCalculator', 'ScenarioModeler'],
          sources: ['CVE Database']
        }
      ]
    },
    {
      type: 'Graph',
      title: 'Context Graph',
      graph: {
        "nodes": [
            {
                "type": "Finding",
                "name": "Directory traversal in Django",
                "id": "Finding#Directory traversal in Django",
                "factors": [],
                "metadata": {}
            },
            {
                "type": "GitHub Repository",
                "name": "jitsecurity-cto/terragoat",
                "id": "GitHub Repository#jitsecurity-cto/terragoat",
                "factors": [
                    "Critical Severity Findings",
                    "Associated External Source Finding"
                ],
                "metadata": {
                    "wiz": {
                        "risks": [
                            "INSECURE_CICD"
                        ]
                    }
                }
            },
            {
                "type": "Team",
                "name": "Eyal's team 2",
                "id": "Team#Eyal's team 2",
                "factors": [],
                "metadata": {}
            },
            {
                "type": "Team",
                "name": "Losers",
                "id": "Team#Losers",
                "factors": [],
                "metadata": {}
            },
            {
                "type": "GitHub Actions",
                "name": "3 GitHub Actions files",
                "id": "GitHub Actions#3 GitHub Actions files",
                "factors": [],
                "metadata": {}
            },
            {
                "type": "Wiz Issue",
                "name": "",
                "id": "Wiz Issue#",
                "factors": [],
                "metadata": {
                    "wiz": {
                        "issue_url": "https://app.wiz.io/issues#~(issue~'005a80c1-d2ef-4744-bc1b-3e08582ea124)"
                    }
                }
            },
            {
                "type": "FixAvailable",
                "name": "Fix Available",
                "id": "FixAvailable#Fix Available",
                "factors": [
                    "Fix Available"
                ],
                "metadata": {}
            },
            {
                "type": "Severity",
                "name": "CRITICAL",
                "id": "Severity#CRITICAL",
                "factors": [
                    "Critical Severity"
                ],
                "metadata": {}
            }
        ],
        "edges": [
            {
                "id": "GitHub Repository#jitsecurity-cto/terragoat#Finding#Directory traversal in Django#GitHub Repository#jitsecurity-cto/terragoat_Finding#Directory traversal in Django",
                "description": "Has",
                "source": "GitHub Repository#jitsecurity-cto/terragoat",
                "target": "Finding#Directory traversal in Django"
            },
            {
                "id": "Team#Eyal's team 2#GitHub Repository#jitsecurity-cto/terragoat#Team#Eyal's team 2_GitHub Repository#jitsecurity-cto/terragoat",
                "description": "Owns",
                "source": "Team#Eyal's team 2",
                "target": "GitHub Repository#jitsecurity-cto/terragoat"
            },
            {
                "id": "Team#Losers#GitHub Repository#jitsecurity-cto/terragoat#Team#Losers_GitHub Repository#jitsecurity-cto/terragoat",
                "description": "Owns",
                "source": "Team#Losers",
                "target": "GitHub Repository#jitsecurity-cto/terragoat"
            },
            {
                "id": "GitHub Repository#jitsecurity-cto/terragoat#GitHub Actions#3 GitHub Actions files#GitHub Repository#jitsecurity-cto/terragoat_GitHub Actions#3 GitHub Actions files",
                "description": "Has",
                "source": "GitHub Repository#jitsecurity-cto/terragoat",
                "target": "GitHub Actions#3 GitHub Actions files"
            },
            {
                "id": "GitHub Repository#jitsecurity-cto/terragoat#Wiz Issue##GitHub Repository#jitsecurity-cto/terragoat_Wiz Issue#",
                "description": "Has",
                "source": "GitHub Repository#jitsecurity-cto/terragoat",
                "target": "Wiz Issue#"
            },
            {
                "id": "Finding#Directory traversal in Django#FixAvailable#Fix Available#Finding#Directory traversal in Django_FixAvailable#Fix Available",
                "source": "Finding#Directory traversal in Django",
                "target": "FixAvailable#Fix Available"
            },
            {
                "id": "Finding#Directory traversal in Django#Severity#CRITICAL#Finding#Directory traversal in Django_Severity#CRITICAL",
                "source": "Finding#Directory traversal in Django",
                "target": "Severity#CRITICAL"
            }
        ]
    }
    },
    {
      type: 'TextSection',
      variant: 'blocks',
      title: 'Mitigation Recommendations',
      intro_text: 'The following recommendations are based on the risk assessment and are designed to mitigate the identified vulnerabilities.',
      sections: [
        {
          title: 'Update Vulnerable Dependencies',
          description: 'Critical: Update express-validator to 6.14.2 to patch CVE-2022-25896'
        },
        {
          title: 'Implement API Gateway Protection',
          description: 'High Priority: Enable AWS WAF with OWASP Top 10 rules and rate limiting (100 req/min)'
        },
        {
          title: 'Restrict Network Access',
          description: 'Critical: Limit inbound traffic on port 443 to load balancer security group only'
        },
        {
          title: 'Enable Data Encryption',
          description: 'High Priority: Enable AWS KMS encryption at rest for customer data in RDS'
        }
      ]
    }
  ]
};

export default mockAwsS3BucketsDrilldown; 