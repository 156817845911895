/* eslint-disable react/no-multi-comp */
/* eslint-disable react/no-unstable-nested-components */
import { CSSProperties, FC, useMemo } from 'react';

import { CompletionGauge } from '../CompletionGauge/CompletionGauge';

import styles from './CompletionStatusRow.module.scss';

import { JitText } from 'components/JitText/JitText';
import { PanelLayout } from 'components/PanelLayout';
import colors from 'themes/colors.module.scss';

interface Props {
  title: string;
  description: string;
  completed: number;
  total: number;
  showAllSections?: boolean;
  onToggleVisibility?: () => void;
}

const rightStyle = {
  display: 'flex',
  flexDirection: 'column' as CSSProperties['flexDirection'],
  justifyContent: 'end',
  padding: '8px',
};

export const CompletionStatusRow: FC<Props> = ({ title, description, completed, total, showAllSections, onToggleVisibility }) => {
  const center = useMemo(() => ({
    component: () => (
      <div className={styles.center}>
        <div className={styles.text}>
          <JitText bold size='xxl' text={title} />

          <JitText muted text={description} />
        </div>
      </div>
    ),
    style: {
      display: 'flex',
    },
  }), [title, description]);

  const left = useMemo(() => ({
    component: () => <CompletionGauge completed={completed} total={total} />,
    style: {
      display: 'flex',
      justifyContent: 'center',
    },
  }), [completed, total]);

  const right = useMemo(() => ({
    component: () => (
      !completed ? null : (
        <div className={styles.right}>
          <JitText
            color={colors.iris}
            onClick={onToggleVisibility}
            text={showAllSections ? 'pages.quickStart.sectionsVisibility.hide' : 'pages.quickStart.sectionsVisibility.show'}
          />
        </div>
      )
    ),
    style: rightStyle,
  }), [showAllSections, onToggleVisibility, completed]);

  const rowComponents = useMemo(() => [{
    left,
    center,
    right,
  }], [center, left, right]);

  return (
    <PanelLayout rowComponents={rowComponents} />
  );
};
