import { FC } from 'react';

import { PageLayout } from 'components/Pages/PageLayout/PageLayout';
import { ActionsProvider } from 'context/ActionsContext/ActionsProvider';
import { TicketCreationWebsocketProvider } from 'context/TicketCreationWebsocketContext/TicketCreationWebsocketProvider';
import { ActionsPage } from 'pages/ActionsPage/ActionsPage';

export const ActionsPageWrapper: FC = () => (
  <PageLayout>
    <ActionsProvider>
      <TicketCreationWebsocketProvider>
        <ActionsPage />
      </TicketCreationWebsocketProvider>
    </ActionsProvider>
  </PageLayout>
);
