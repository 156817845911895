import styles from './RadioAll.module.scss';

import { JitRadioButton } from 'components/JitRadioButton/JitRadioButton';
import { JitText } from 'components/JitText/JitText';

interface RadioAllProps {
  isAllSelected: boolean;
  label: string;
  description: string;
  setIsAllSelected: (isAllSelected: boolean) => void
  index: number
  note?: string
  isCompleted: boolean
}

export const RadioAll = ({ isAllSelected, label, description, setIsAllSelected, index, note, isCompleted }: RadioAllProps) => {
  const handleClick = () => {
    if (!isCompleted) {
      setIsAllSelected(true);
    }
  };

  return (
    <div
      className={styles.buttonContainer}
      onClick={handleClick}
      role='button'
      tabIndex={index}
    >
      <JitRadioButton checked={isAllSelected} disabled={isCompleted} />

      <div>
        <div className={styles.radioWapper}>
          <JitText bold size='l' text={label} />

          {note && <JitText size='l' text={note} />}
        </div>

        <JitText
          muted
          size='l'
          text={description}
        />
      </div>
    </div>
  );
};
