import { FC } from 'react';

import { FindingTableProvider } from 'components/FindingsTable/FindingsTableContext/FindingsTableProvider';
import { FindingsTableContent } from 'components/FindingsTable/FindingTableContent';
import { TicketCreationWebsocketProvider } from 'context/TicketCreationWebsocketContext/TicketCreationWebsocketProvider';
import { IServerFindingFilterOptions } from 'types/interfaces';

interface Props {
  filters: Partial<IServerFindingFilterOptions>;
  isLoading?: boolean;
  limit?: number;
  EmptyTableView?: FC;
  hasFetchedFilters?: boolean;
}

export const FindingsTable: FC<Props> = ({
  filters,
  isLoading,
  limit,
  EmptyTableView,
  hasFetchedFilters = true,
}) => (
  <TicketCreationWebsocketProvider>
    <FindingTableProvider filters={filters} hasFetchedFilters={hasFetchedFilters} limit={limit}>
      <FindingsTableContent EmptyTableView={EmptyTableView} isLoading={isLoading} />
    </FindingTableProvider>
  </TicketCreationWebsocketProvider>
);
