import { useCallback } from 'react';

import { useConfigurationsContext } from '../ConfigurationsContext';

import { useGetConfigurationsComponentDetails } from './useGetConfigurationsComponentDetails';
import { useHasConfigurationsChanged } from './useHasConfigurationsChanged/useHasConfigurationsChanged';
import { useValidateSecretByIntegration } from './useValidateSecretByIntegration';

import { useAssetsContext } from 'context/AssetsContext/AssetsContext';
import { useIntegrationsContext } from 'context/IntegrationsContext';
import {
  IntegrationTemplate,
  IntegrationType,
  IntegrationVendorType,
} from 'context/IntegrationsContext/templates/interfaces';
import { useIntegrationsTemplate } from 'context/IntegrationsContext/templates/useIntegrationTemplates';
import { useTenantContext } from 'context/TenantContext';
import { i18n } from 'locale/i18n';
import { getIntegrationTemplateVendor } from 'pages/IntegrationsPage/utils/getIntegrationTemplateVendor';
import { AssetType } from 'types/enums';
import { IntegrationDetail, IPlanItemDetails } from 'types/interfaces';
import { IntegrationProvider, IntegrationStatus } from 'types/interfaces/Integrations/IIntegration';
import { useAssetTypeToVendor } from 'utils/constants/assetTypeConfigurations/assetTypeConfigurations';
import { isVendorCloudProvider } from 'utils/functions/matchVendorToAssetType';

export const useGetPlanItemConfigurationsStatus = () => {
  const { isProperlyConfigured, isAssetTypeProperlyIntegratedInConfig } = useConfigurationsContext();
  const { isAssetTypeProperlyIntegrated } = useAssetsContext();
  const getConfigurationsComponentDetails = useGetConfigurationsComponentDetails();
  const isSecretValidForIntegration = useValidateSecretByIntegration();
  const { getVendorByAssetType } = useAssetTypeToVendor();
  const { integrations } = useIntegrationsContext();
  const { getTemplateByVendorKey } = useIntegrationsTemplate(integrations);
  const { isScmVendorIntegrated } = useTenantContext();
  const { t } = i18n;

  const isConfigured = useCallback((planItem: IPlanItemDetails, assetType?: AssetType) => isProperlyConfigured(planItem.slug, assetType), [isProperlyConfigured]);

  const isMissingThirdPartyIntegration = useCallback(
    (integrationTemplate: IntegrationTemplate): boolean => {
      if (integrationTemplate?.provider === IntegrationProvider.THIRD_PARTY) {
        return !integrations?.some((integration) => integration.vendor === integrationTemplate.key && integration.status === IntegrationStatus.SUCCESS);
      }
      return false;
    },
    [integrations],
  );

  const isMissingScmIntegration = useCallback(
    (integrationTemplate: IntegrationTemplate): boolean => {
      if (integrationTemplate?.vendorType === IntegrationVendorType.sourceCodeManagement) {
        return isMissingThirdPartyIntegration(integrationTemplate) || !isScmVendorIntegrated(getIntegrationTemplateVendor(integrationTemplate.key));
      }
      return false;
    },
    [isScmVendorIntegrated, isMissingThirdPartyIntegration],
  );

  const isMissingRequiredControlsScmIntegrations = useCallback(
    (planItem: IPlanItemDetails): boolean => planItem.controls.some((control) => control.integrations?.some((integration) => integration.required
      && isMissingScmIntegration(getTemplateByVendorKey(integration.name)!))),
    [isMissingScmIntegration, getTemplateByVendorKey],
  );

  const isMissingIntegrationDependencies = useCallback(
    (integration: IntegrationDetail): boolean => {
      const integrationTemplate = getTemplateByVendorKey(integration.name);
      if (integrationTemplate?.vendorType === IntegrationVendorType.sourceCodeManagement) {
        return isMissingScmIntegration(integrationTemplate);
      }
      if (integrationTemplate?.provider === IntegrationProvider.THIRD_PARTY) {
        return isMissingThirdPartyIntegration(integrationTemplate);
      }
      if (integrationTemplate?.integrationType === IntegrationType.secret) {
        return !isSecretValidForIntegration(integrationTemplate);
      }
      return false;
    },
    [getTemplateByVendorKey, isSecretValidForIntegration, isMissingThirdPartyIntegration, isMissingScmIntegration],
  );

  const isMissingControlsIntegrations = useCallback(
    (planItem: IPlanItemDetails): boolean => planItem.controls.some((control) => control.integrations?.some((integration) => isMissingIntegrationDependencies(integration))),
    [isMissingIntegrationDependencies],
  );

  const getMissingControlsIntegrations = useCallback(
    (planItem: IPlanItemDetails): IntegrationDetail[] => planItem.controls.flatMap((control) => control.integrations?.filter((integration) => isMissingIntegrationDependencies(integration)) || []),
    [isMissingIntegrationDependencies],
  );

  const isMissingAssetTypeIntegration = useCallback(
    (assetType: AssetType): boolean => {
      const vendor = getVendorByAssetType(assetType);
      return isVendorCloudProvider(vendor)
        ? !isAssetTypeProperlyIntegrated(assetType)
        : !isAssetTypeProperlyIntegratedInConfig(assetType);
    },
    [getVendorByAssetType, isAssetTypeProperlyIntegrated, isAssetTypeProperlyIntegratedInConfig],
  );

  const isMissingAssetTypesIntegrations = useCallback(
    (planItem: IPlanItemDetails): boolean => !!planItem.asset_types?.some((assetType) => isMissingAssetTypeIntegration(assetType)),
    [isMissingAssetTypeIntegration],
  );

  const getMissingAssetTypesIntegrations = useCallback(
    (planItem: IPlanItemDetails): AssetType[] => planItem.asset_types?.filter((assetType) => isMissingAssetTypeIntegration(assetType)),
    [isMissingAssetTypeIntegration],
  );

  const getMissingIntegrations = useCallback((planItem: IPlanItemDetails): string => {
    const missingControlsIntegrations = getMissingControlsIntegrations(planItem)
      .map((integration) => getTemplateByVendorKey(integration.name)?.label);

    const missingAssetTypesIntegrations = getMissingAssetTypesIntegrations(planItem)
      .map((assetType) => t(`vendor.${getVendorByAssetType(assetType) || ''}`, { defaultValue: '' }));

    const missingIntegrations = missingControlsIntegrations.concat(missingAssetTypesIntegrations);
    return Array.from(new Set(missingIntegrations)).join(', ') || '';
  }, [getMissingControlsIntegrations, getTemplateByVendorKey, getMissingAssetTypesIntegrations, t, getVendorByAssetType]);

  const isIntegrated = useCallback(
    (planItem: IPlanItemDetails): boolean => !isMissingControlsIntegrations(planItem) && !isMissingAssetTypesIntegrations(planItem),
    [isMissingControlsIntegrations, isMissingAssetTypesIntegrations],
  );

  const { hasConfigurationsChanged } = useHasConfigurationsChanged();

  const isConfigurationMissing = useCallback(
    (planItem: IPlanItemDetails) => !isIntegrated(planItem)
      || !isConfigured(planItem, planItem.asset_types?.[0])
      || hasConfigurationsChanged(planItem.slug)
      || !!planItem.is_manual
      || planItem.is_enabled
      === false,
    [isIntegrated, isConfigured, hasConfigurationsChanged],
  );

  const isConfigurable = useCallback(
    (planItem: IPlanItemDetails) => !!((getConfigurationsComponentDetails(planItem) && !isMissingRequiredControlsScmIntegrations(planItem)) || planItem.is_manual),
    [getConfigurationsComponentDetails, isMissingRequiredControlsScmIntegrations],
  );

  return {
    isConfigured,
    isIntegrated,
    isConfigurationMissing,
    isConfigurable,
    getMissingIntegrations,
  };
};
