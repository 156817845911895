import { useMemo } from 'react';

import { useIntegrationsContext } from 'context/IntegrationsContext';
import { IntegrationVendorType } from 'context/IntegrationsContext/templates/interfaces';
import { useTenantContext } from 'context/TenantContext';
import { isVendorInstalled } from 'pages/IntegrationsPage/utils';
import { Vendor } from 'types/enums';
import { IntegrationStatus } from 'types/interfaces/Integrations';
import { useDetailsByVendor } from 'utils/hooks/useDetailsByVendor';

export const useCommunicationPlatforms = () => {
  const { integrations, isLoading: isLoadingIntegrations } = useIntegrationsContext();
  const { installations, isLoadingInstallation } = useTenantContext();
  const { getVendorDisplayName } = useDetailsByVendor();

  // Check for Slack using the old way (tenant context)
  const isSlackConnected = useMemo(
    () => isVendorInstalled(Vendor.SLACK, installations),
    [installations],
  );

  // Get all configured collaboration platform integrations
  const collaborationPlatformIntegrations = useMemo(
    () => integrations?.filter(
      (integration) => integration.display?.categoryKey === IntegrationVendorType.collaborationPlatform
        && integration.status === IntegrationStatus.SUCCESS,
    ),
    [integrations],
  );

  // Combine all active collaboration platforms
  const integrationOptions = useMemo(() => {
    const activeIntegrations = collaborationPlatformIntegrations?.map((integration) => ({
      value: integration.vendor,
      label: getVendorDisplayName(integration.vendor),
    })) ?? [];

    if (isSlackConnected) {
      activeIntegrations.unshift({
        value: Vendor.SLACK,
        label: getVendorDisplayName(Vendor.SLACK),
      });
    }

    return activeIntegrations;
  }, [collaborationPlatformIntegrations, isSlackConnected, getVendorDisplayName]);

  return [integrationOptions, isLoadingIntegrations || isLoadingInstallation] as const;
};
