import { FC } from 'react';
import { Box } from '@mui/material';
import { PageLayout } from 'components/Pages/PageLayout/PageLayout';
import { JittoTasksHeader } from './components/JittoTasksHeader';
import { JittoTasksBody } from './components/JittoTasksBody';

export const JittoTasksPage: FC = () => {
  return (
    <PageLayout>
      <Box sx={{ padding: '20px 40px', }}>
        <JittoTasksHeader />
        <JittoTasksBody />
      </Box>
    </PageLayout>
  );
}; 