import { ReportData } from "../ReportData";

export const MOCK_REPORT_ITEMS: ReportData = [
  {
    id: 'section-1',
    itemType: 'Section',
    props: {
      title: '',
      items: [
        {
          id: 'funnel-1',
          itemType: 'Funnel',
          props: {
             // @ts-ignore
            title: 'Sera analysis process', 
            stages: [
              { id: 'stage-1', label: 'Detected high-priority vulnerabilities', value: 5234 },
              { id: 'stage-2', label: 'Reduced vulnerabilities with low business impact', value: 2768 },
              { id: 'stage-3', label: 'Reduced un-exploitable vulnerabilities', value: 320 },
              { id: 'stage-4', label: 'Remaining high-priority vulnerabilities', value: 27 }
            ],
          }
        }
      ]
    }
  },
  {
    id: 'section-2',
    itemType: 'Section',
      props: {
        title: "Start Here: Most Urgent Issues",
        button: {
          text: "Show all (27)",
          variant: 'text'
        },
        items: [
          {
            id: 'cardslist-1',
            itemType: 'CardsList',
            props: {
               // @ts-ignore
              cards: [
                {
                    id: 'cardslist-1-1',
                    topLabels: [
                      { id: 'toplabel-1', text: 'Critical', type: 'severity' },
                    ],
                    bottomLabels: [
                      { id: 'bottomlabel-1', text: 'Wiz', type: 'vendor' },
                    ],
                    title: 'Exposed AWS S3 Buckets with Sensitive Data',
                    description: 'Multiple S3 buckets with public read access contain customer PII and financial data.',
                    buttons: [
                      { id: 'button-1', text: 'FIX IT', variant: 'gradient' }
                    ]
                },
                {
                    id: 'cardslist-1-2',
                    topLabels: [
                      { id: 'toplabel-2', text: 'Critical', type: 'severity' },
                    ],
                    bottomLabels: [
                      { id: 'bottomlabel-2', text: 'Checkmarx', type: 'vendor' }
                    ],
                    title: 'SQL Injection in User Profile API',
                    description: 'Unvalidated user input directly concatenated with SQL queries.',
                    buttons: [
                      { id: 'button-2', text: 'FIX IT', variant: 'gradient' }
                    ]
                },
                {
                    id: 'cardslist-1-3',
                    topLabels: [
                      { id: 'toplabel-3', text: 'Critical', type: 'severity' }
                    ],
                    bottomLabels: [
                    ],
                    title: 'Outdated Dependencies with Known CVEs',
                    description: '47 critical vulnerabilities in production dependencies.',
                    buttons: [
                      { id: 'button-3', text: 'FIX IT', variant: 'gradient' }
                    ]
                }
              ]
            }
          }
        ]
      }
  },
  {
    id: 'section-3',
    itemType: 'Section',
      props: {
        title: "Risk Breakdown by Category",
        items: [
          {
            id: 'flexlist-2',
            itemType: 'FlexList',
            props: {
              direction: 'row',
              items: [
                {
                  id: 'breakdownlist-1',
                  itemType: 'BreakdownList',
                  props: {
                    // @ts-ignore
                    title: 'Code Security Risks',
                    rows: [
                      {
                        id: 'breakdown-item-1',
                        title: 'Dependency Vulnerabilities',
                        description: 'Log4j (CVE-2021-44228)',
                        number: 47,
                      },
                      {
                        id: 'breakdown-item-2',
                        title: 'Risky Coding Practices',
                        description: 'Top issue: Unsanitized user inputs',
                        number: 35,
                      },
                      {
                        id: 'breakdown-item-3',
                        title: 'Secrets Exposure',
                        description: 'Top issue: API keys in source code',
                        number: 12,
                      },
                    ]
                  }
                },
                {
                  id: 'breakdownlist-2',
                  itemType: 'BreakdownList',
                  props: {
                     // @ts-ignore
                    title: 'Cloud Security Risks',
                    rows: [
                      {
                        id: 'breakdown-item-4',
                        title: 'Misconfigurations',
                        description: 'Top issue: Public S3 buckets',
                        number: 23,
                      },
                      {
                        id: 'breakdown-item-5',
                        title: 'Exposed Services',
                        description: 'Top issue: Unprotected admin panels',
                        number: 17,
                      },
                      {
                        id: 'breakdown-item-6',
                        title: 'Over-permissioned Roles',
                        description: 'Top issue: Wildcard permissions',
                        number: 9,
                      },
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
  },
  {
    id: 'section-4',
    itemType: 'Section',
    props: {
      title: 'Security Controls Status',
      items: [
        {
          id: 'simpletable-1',
          itemType: 'SimpleTable',
          props: {
             // @ts-ignore
            columns: [
               'Control name',
               'Description',
               'Status',
               'Action',
            ],
            rows: [
              [
                'Multi-factor Authentication',
                'Enable MFA for all admin accounts',
                { 
                  id: 'status-1',
                  type: 'label',
                  props: { 
                    text: 'Missing',
                    color: '#D32F2F' // Red color for missing
                  }
                },
                {
                  id: 'action-1',
                  type: 'button', 
                  props: { 
                    text: 'Add', 
                    variant: 'contained'
                  } 
                }
              ],
              [
                'Vulnerability Scanning',
                'Increase scanning frequency to daily',
                { 
                  id: 'status-2',
                  type: 'label', 
                  props: { 
                    text: 'Missing', 
                    color: '#D32F2F' // Red color for missing
                  } 
                },
                {
                  id: 'action-2',
                  type: 'button', 
                  props: { 
                    text: 'Add', 
                    variant: 'contained'
                  } 
                }
              ]
            ]
          }
        }
      ]
    }
  }
]