import React from 'react';
import { Chart as BaseChart } from 'components/Chart/Chart';
import { ChartData } from 'components/Chart/types';

/**
 * Re-export the Chart Props to match the original component's interface
 */
export type ChartProps<XField extends string = string, YFields extends string = string> = {
  data: ChartData<XField, YFields>;
  loading?: boolean;
  height?: number;
};

/**
 * Chart component that wraps the base Chart component
 * Uses the same interface and passes all props directly
 */
export const Chart = <XField extends string = string, YFields extends string = string>(
  props: ChartProps<XField, YFields>
) => {
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <BaseChart {...props} />
    </div>
  );
};

export default Chart; 