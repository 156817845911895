/* eslint-disable react/no-multi-comp */
import { AwsConfigurationTrigger } from './ConfigurationTrigger/AwsConfigurationTrigger';
import { ContainerScanOnBuildConfiguration } from './ContainerScanOnBuild/ContainerScanOnBuildConfiguration';
import { BranchProtectionConfiguration } from './GithubBranchProtection/BranchProtectionConfiguration';
import { AwsIntegration } from './Integrations/AwsIntegration/AwsIntegration';
import { IntegrationProps, ConfigurationsComponentDetails, SlackNotificationsProps, IConfigurationProps } from './interfaces/configurations';
import { SlackNotifications } from './Notifications/SlackNotifications';
import { upsertApp } from './Zap/hooks/useConfigurationStateManager';

import { GithubIcon, OpenInNewTab, ProwlerIcon, ContainerScanIcon } from 'assets';
import { constants } from 'globalConstants';
import { i18n } from 'locale/i18n';
import {
  Application,
  ApplicationTypes,
  AWSApplication,
  IConfigurations,
  IPlanItemDeployment,
} from 'types/interfaces';

// TODO: We should remove all the configurations from this file and split it into small pices like in the onboarding steps dialog. this logic should move to the prowler configuration.
const getUpsertConfigApplication = (baseConfigurations: IConfigurations | undefined, upsertApplication: Application): IConfigurations => {
  const newApplications = upsertApp(baseConfigurations?.applications || [], upsertApplication);
  return {
    ...baseConfigurations,
    applications: newApplications,
  };
};

const getProwlerUpdatedAppConfiguration = (baseConfigurations: IConfigurations | undefined, applicationName: string): IConfigurations => {
  const currentConfiguration = baseConfigurations?.applications?.find((app) => app.application_name === applicationName) as AWSApplication;
  const updatedApp: AWSApplication = {
    application_name: applicationName,
    type: ApplicationTypes.AWS,
    account_ids: currentConfiguration?.account_ids || baseConfigurations?.aws?.map((account) => account.account_id) || [],
  };
  return getUpsertConfigApplication(baseConfigurations, updatedApp);
};

const getConfigurationAndPlanItemForProwler = (baseConfigurations: IConfigurations | undefined, defaultApplicationName: string, planItemTriggerConfiguration: IPlanItemDeployment):
[IConfigurations, IPlanItemDeployment] => {
  const planItem = { ...planItemTriggerConfiguration };
  if (planItem?.configuration) {
    planItem.configuration = [defaultApplicationName];
  }
  return [getProwlerUpdatedAppConfiguration(baseConfigurations, defaultApplicationName), planItem];
};

const branchProtectionComponentDetails: ConfigurationsComponentDetails = {
  steps: [{
    stepDisplayText: 'configurations.githubBranchProtection.stepName',
    ConfigurationComponent: ({ setIsDoneStep }: IConfigurationProps) => <BranchProtectionConfiguration setIsDoneStep={setIsDoneStep} />,
    title: 'configurations.githubBranchProtection.title',
    button: {
      text: 'dialogs.buttons.activate',
      disabledTooltip: 'pages.plan.configurationsDialog.missingField',
    },
  }],
  icon: GithubIcon,
};

const containerScanOnBuildComponentDetails: ConfigurationsComponentDetails = {
  steps: [{
    stepDisplayText: 'configurations.containerScanOnBuild.stepName',
    ConfigurationComponent: ({ setIsDoneStep, planSlug, planItemSlug, onClose }: IConfigurationProps) => (
      <ContainerScanOnBuildConfiguration
        onClose={onClose}
        planItemSlug={planItemSlug}
        planSlug={planSlug}
        setIsDoneStep={setIsDoneStep}
      />
    ),
    title: 'configurations.containerScanOnBuild.title',
    button: {
      text: 'dialogs.buttons.activate',
      disabledTooltip: 'pages.plan.configurationsDialog.missingField',
    },
  }],
  icon: ContainerScanIcon,
  analyticsEvent: { action: 'ContainerScanOnBuild-Configure-Complete' },
};

export const configurationComponentByItemSlug: { [index: string]: ConfigurationsComponentDetails } = {
  [constants.PLAN_ITEMS_SLUG.GITHUB_BRANCH_PROTECTION]: branchProtectionComponentDetails,
  [constants.PLAN_ITEMS_SLUG.GITHUB_BRANCH_SET_CONFIG]: branchProtectionComponentDetails,
  [constants.PLAN_ITEMS_SLUG.PROWLER]: {
    steps: [
      {
        stepDisplayText: 'pages.plan.planItemsCategory.steps.awsIntegration',
        ConfigurationComponent: ({ setIsDoneStep, setShouldHideStepsDialog }: IntegrationProps) => (
          <AwsIntegration
            dialogCardWidth='100%'
            setIsDoneStep={setIsDoneStep}
            setShouldHideStepsDialog={setShouldHideStepsDialog}
          />
        ),
        button: {
          text: 'dialogs.buttons.next',
          disabledTooltip: 'pages.plan.configurationDialog.trigger.disabledTooltip',
        },
        title: 'pages.plan.configurationDialog.awsIntegration.title',
        subtitle: 'pages.plan.configurationDialog.awsIntegration.subtitle',
      }],
    icon: ProwlerIcon,
    analyticsEvent: { action: 'Prowler-Configure-Complete' },
  },
  [constants.PLAN_ITEMS_SLUG.CONTAINER_SCAN_ON_BUILD]: containerScanOnBuildComponentDetails,
};

export const configurationComponentByItemSlugWithProwler: { [index: string]: ConfigurationsComponentDetails } = {
  ...configurationComponentByItemSlug,
  [constants.PLAN_ITEMS_SLUG.PROWLER]: {
    steps: [
      {
        stepDisplayText: 'pages.plan.planItemsCategory.steps.awsIntegration',
        ConfigurationComponent: ({ setIsDoneStep, setShouldHideStepsDialog }: IntegrationProps) => (
          <AwsIntegration
            setIsDoneStep={setIsDoneStep}
            setShouldHideStepsDialog={setShouldHideStepsDialog}
          />
        ),
        button: {
          text: 'dialogs.buttons.next',
          disabledTooltip: 'pages.plan.configurationDialog.trigger.disabledTooltip',
        },
        title: 'pages.plan.configurationDialog.awsIntegration.title',
        subtitle: 'pages.plan.configurationDialog.awsIntegration.subtitle',
        subtitleLink: {
          href: i18n.t('pages.plan.configurationDialog.awsIntegration.learnMoreHref'),
          text: 'pages.plan.configurationDialog.awsIntegration.learnMoreText',
          icon: OpenInNewTab,
        },
      },
      {
        stepDisplayText: 'pages.plan.planItemsCategory.steps.trigger',
        ConfigurationComponent: ({ setIsDoneStep }: IConfigurationProps) => (
          <AwsConfigurationTrigger
            applicationType={ApplicationTypes.AWS}
            getUpdatedConfigurationAndPlanItem={getConfigurationAndPlanItemForProwler}
            planItemSlug={constants.PLAN_ITEMS_SLUG.PROWLER}
            setIsDoneStep={setIsDoneStep}
          />
        ),
        button: {
          text: 'dialogs.buttons.next',
          disabledTooltip: 'pages.plan.configurationDialog.trigger.disabledTooltip',
        },
        title: 'pages.plan.configurationDialog.trigger.title',
        subtitle: 'pages.plan.configurationDialog.trigger.subtitle',
      },
      {
        stepDisplayText: 'pages.plan.planItemsCategory.steps.notifications',
        // eslint-disable-next-line react/no-multi-comp
        ConfigurationComponent: ({ setIsDoneStep, stepIndex }: SlackNotificationsProps) => (
          <SlackNotifications applicationType={ApplicationTypes.AWS} planItemSlug={constants.PLAN_ITEMS_SLUG.PROWLER} setIsDoneStep={setIsDoneStep} stepIndex={stepIndex} />
        ),
        button: {
          text: 'dialogs.buttons.activate',
          disabledTooltip: 'pages.plan.configurationsDialog.missingField',
        },
        subtitle: 'configurations.notifications.subtitle',
        title: 'configurations.notifications.title',
      }],
    icon: ProwlerIcon,
    analyticsEvent: { action: 'Prowler-Configure-Complete' },
  },
};
