import { isEmpty } from 'lodash';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { ISpecificTeamState, initialSpecificTeamState } from '../TeamsProvider';

import { Queries } from 'types/enums/Queries';
import { IWebsocketNotification, IWebsocketNotificationEntity } from 'types/interfaces';
import { IPaginatedState } from 'types/interfaces/IPaginatedState';
import { IGetTeamResponseItem, ITeam } from 'types/interfaces/Teams/ITeam';
import { TeamSortBy, TeamSortOrder } from 'types/interfaces/Teams/TeamSorting';
import { assertWebsocketNotificationEntity } from 'utils/functions/assertions/websocketNotificationEntity';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';
import { sortTeams } from 'utils/functions/sortTeams';

interface Props {
  setTeams: Dispatch<SetStateAction<IPaginatedState<IGetTeamResponseItem>>>,
  setSpecificTeam: Dispatch<SetStateAction<ISpecificTeamState>>,
  sortedBy: TeamSortBy,
  sortOrder: TeamSortOrder,
  setIsUploadingTeams: Dispatch<SetStateAction<boolean>>,
}

// the second return value is a boolean that indicates if we need the invalidate the query
type ModifyTeams = (currentTeams: IGetTeamResponseItem[], modifiedTeams: ITeam[]) => [IGetTeamResponseItem[], boolean];

const DEFAULT_SHOULD_INVALIDATE = false;

const DEFAULT_NEW_TEAM_FIELDS = {
  members: [],
  membersCount: 0,
};

export const useHandleTeamsWebSocketNotification = ({ setTeams, setSpecificTeam, sortedBy, sortOrder, setIsUploadingTeams }: Props) => {
  const queryClient = useQueryClient();

  const updateSpecificTeam = (
    currentSpecificTeam: ISpecificTeamState,
    modifiedTeams: ITeam[],
  ) => {
    if (!currentSpecificTeam.team) {
      return currentSpecificTeam;
    }

    const modifiedTeam = modifiedTeams.find((team) => team.id === currentSpecificTeam.team?.id);
    if (!modifiedTeam) {
      return currentSpecificTeam;
    }

    return {
      ...currentSpecificTeam,
      team: {
        ...currentSpecificTeam.team,
        ...modifiedTeam as ITeam,
      },
    };
  };

  const modifySpecificTeam = useCallback((
    currentSpecificTeam: ISpecificTeamState,
    deletedTeams: ITeam[],
    updatedTeams: ITeam[],
  ) => {
    if (deletedTeams.some((deletedTeam) => deletedTeam.id === currentSpecificTeam.team?.id)) {
      return initialSpecificTeamState;
    }
    return updateSpecificTeam(currentSpecificTeam, updatedTeams);
  }, []);

  const updateTeams = useCallback<ModifyTeams>((currentTeams, modifiedTeams) => {
    if (!currentTeams || !modifiedTeams) {
      return [currentTeams, DEFAULT_SHOULD_INVALIDATE];
    }

    const currentTeamsById = currentTeams.reduce((acc, team) => {
      acc[team.id] = team;
      return acc;
    }, {} as Record<string, IGetTeamResponseItem>);

    let wasTeamScoreUpdated = false;

    modifiedTeams.forEach((modifiedTeam) => {
      if (!currentTeamsById[modifiedTeam.id]) {
        return;
      }
      if (currentTeamsById[modifiedTeam.id].score !== modifiedTeam.score) {
        wasTeamScoreUpdated = true;
      }
      currentTeamsById[modifiedTeam.id] = {
        ...currentTeamsById[modifiedTeam.id],
        ...modifiedTeam,
      };
    });

    return [Object.values(currentTeamsById), wasTeamScoreUpdated];
  }, []);

  const removeDeletedTeams = useCallback((
    currentTeams: IGetTeamResponseItem[],
    deletedTeams: ITeam[],
  ) => currentTeams.filter((team) => !deletedTeams?.find((deletedTeam) => deletedTeam.id === team.id)), []);

  const addCreatedTeams = useCallback((
    currentTeams: IGetTeamResponseItem[],
    createdTeams: ITeam[],
  ) => {
    if (isEmpty(createdTeams)) {
      return currentTeams;
    }

    // Create an object to keep track of unique team IDs
    const teamIds = currentTeams.reduce((acc, team) => {
      acc[team.id] = true;
      return acc;
    }, {} as { [key: string]: boolean });

    const newTeams = createdTeams
      .filter((team) => !teamIds[team.id]) // Filter out teams that already exist
      .map((team) => ({
        ...team,
        ...DEFAULT_NEW_TEAM_FIELDS,
        imageDetails: team.image,
      }) as IGetTeamResponseItem);

    const sortedTeams = sortTeams([...currentTeams, ...newTeams], sortedBy, sortOrder);
    setIsUploadingTeams(false);

    return sortedTeams;
  }, [setIsUploadingTeams, sortOrder, sortedBy]);

  const modifyTeams = useCallback((
    currentState: IPaginatedState<IGetTeamResponseItem>,
    createdTeams: ITeam[],
    deletedTeams: ITeam[],
    updatedTeams: ITeam[],
  ) => {
    let [newTeams, shouldInvalidate] = [currentState.data, DEFAULT_SHOULD_INVALIDATE];
    newTeams = removeDeletedTeams(newTeams, deletedTeams);
    [newTeams, shouldInvalidate] = updateTeams(newTeams, updatedTeams);
    newTeams = addCreatedTeams(newTeams, createdTeams);

    if (!isEmpty(createdTeams) || !isEmpty(deletedTeams) || shouldInvalidate) {
      queryClient.invalidateQueries({ queryKey: [Queries.TeamsLeaderboard] });
    }

    return {
      ...currentState,
      data: newTeams,
    };
  }, [addCreatedTeams, queryClient, removeDeletedTeams, updateTeams]);

  const handleTeamsWebSocketNotification = useCallback((notification: IWebsocketNotification<ITeam>) => {
    const camelizedNotification = camelizeSnakeCaseKeys(notification) as IWebsocketNotificationEntity<ITeam>;
    assertWebsocketNotificationEntity(camelizedNotification);

    let { message: { created, updated, deleted } } = camelizedNotification;
    created ??= [];
    updated ??= [];
    deleted ??= [];

    setTeams((currentTeams) => modifyTeams(currentTeams, created, deleted, updated));
    setSpecificTeam((currentSpecificTeam) => modifySpecificTeam(currentSpecificTeam, deleted, updated));
  }, [setTeams, setSpecificTeam, modifyTeams, modifySpecificTeam]);
  return { handleTeamsWebSocketNotification };
};
