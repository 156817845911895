import { createContext, useContext } from 'react';

import { WebSocketNotificationTopics } from 'types/enums';
import { WebsocketNotificationCallback } from 'types/types';

export type SubscribeTopicArgs<EntityType extends object = object, SystemType extends object = EntityType> = [
  notificationTopic: WebSocketNotificationTopics,
  handler: WebsocketNotificationCallback<EntityType, SystemType>,
];

export type SetTopicHandlerArgs<EntityType extends object = object, SystemType extends object = EntityType> = [
  notificationTopic: WebSocketNotificationTopics,
  handler: WebsocketNotificationCallback<EntityType, SystemType>,
  handlerId: string,
];

interface IWebSocketContext {
  subscribeTopic: <EntityType extends object = object, SystemType extends object = EntityType>(
    ...args: SubscribeTopicArgs<EntityType, SystemType>
  ) => { handlerId: string },
  unsubscribeTopic: (handlerId: string) => void,
  setTopicHandler: <EntityType extends object = object, SystemType extends object = EntityType>(
    ...args: SetTopicHandlerArgs<EntityType, SystemType>
  ) => void,
}

export const WebSocketContext = createContext<IWebSocketContext>({
  subscribeTopic: () => ({ handlerId: '' }),
  unsubscribeTopic: () => {},
  setTopicHandler: () => {},
});

export const useWebSocketContext = () => useContext(WebSocketContext);

