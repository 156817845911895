import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField, IconButton } from '@mui/material';
import { FC, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './SecurityToolsSearch.module.scss';

interface SecurityToolsSearchProps {
  searchTerm: string;
  onSearchChange: (term: string) => void;
  testId?: string;
}

/**
 * Search component for the Security Tools page
 * Uses MUI TextField with search and clear icons
 */
export const SecurityToolsSearch: FC<SecurityToolsSearchProps> = ({
  searchTerm,
  onSearchChange,
  testId = 'security-tools-search',
}) => {
  const { t } = useTranslation();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onSearchChange(event.target.value);
  };

  const handleClear = () => {
    onSearchChange('');
  };

  return (
    <div className={styles.searchContainer} data-testid={testId}>
      <TextField
        aria-label={t('securityToolsSection.search.placeholder')}
        data-testid={`${testId}-input`}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon color='action' />
            </InputAdornment>
          ),
          endAdornment: searchTerm ? (
            <InputAdornment position='end'>
              <IconButton
                aria-label='clear search'
                data-testid={`${testId}-clear-button`}
                edge='end'
                onClick={handleClear}
                size='small'
              >
                <ClearIcon fontSize='small' />
              </IconButton>
            </InputAdornment>
          ) : null,
          className: styles.searchInput,
        }}
        onChange={handleChange}
        placeholder={t('securityToolsSection.search.placeholder')}
        size='small'
        value={searchTerm}
        variant='outlined'
      />
    </div>
  );
};
