import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { SxProps, Theme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { FC, CSSProperties, useCallback } from 'react';

import styles from './AssetsManagementDialog.module.scss';
import { AssetsManagementButtons, Title, TopBar, TabPanel, EmptyState } from './components';
import { TABS, TABS_ARRAY } from './constants';
import { useAssetsDialogManagement } from './hooks';

import { CircularProgressBar } from 'components/CircularProgressBar/CircularProgressBar';
import { useTenantContext } from 'context/TenantContext/TenantContext';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';

const dialogStyles = {
  paperStyle: {
    backgroundColor: colors.cards,
    border: `1px solid ${colors.darkGray}`,
    borderRadius: 20,
    minWidth: 844,
    minHeight: 600,
    maxHeight: 600,
    backgroundImage: 'none',
    paddingLeft: 10,
    paddingRight: 10,
    display: 'flex',
    flexDirection: 'column' as const,
  },
  content: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column' as const,
    overflow: 'hidden',
    flex: 1,
  },
  tabContent: {
    flex: 1,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column' as const,
    minHeight: 0,
  } as SxProps<Theme>,
  assetsList: {
    flex: 1,
    overflowY: 'auto',
    minHeight: 0,
    height: '100%',
    position: 'relative' as const,
  } as CSSProperties,
};

interface Props {
  defaultTab?: TABS;
  onClose?: () => void;
  // An object to help inner components act based on the extra attributes
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  extraDetails?: {
    [key: string]: any
  }
  tagAllAsCheckedForVendors?: string[]; // Vendors for which all assets should be checked at opening by default
}

export const AssetsManagementDialog: FC<Props> = ({
  defaultTab,
  onClose,
  extraDetails,
  tagAllAsCheckedForVendors,
}) => {
  const { scmInstallations, isGithubIntegrated, isGitlabIntegrated } = useTenantContext();
  let shouldDisableTab = (tab: TABS) => !isGithubIntegrated && tab === TABS.GITHUB;
  let startingTab = defaultTab || TABS.GITHUB;
  let tabsArray = [TABS.GITHUB].concat(TABS_ARRAY);
  // The tab param will not be needed once we remove the special handling for Github.
  if (isGitlabIntegrated) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    shouldDisableTab = (tab: TABS) => false;
    startingTab = defaultTab || scmInstallations[0]?.vendor as TABS;
    const scmTabsArray = scmInstallations.map((installation) => installation.vendor as TABS);
    tabsArray = scmTabsArray.concat(TABS_ARRAY);
  }

  const {
    displayedAssets,
    handleClose,
    handleSave,
    handleSearchAsset,
    handleTabChange,
    handleToggleSelectAllAssets,
    isAllAssetsSelectedTabCovered,
    ownerDisplayNames,
    selectedTab,
    checkAsset,
    checkMultipleAssetsByOwner,
    tabDetails,
    // Properties for infinite scrolling and search
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    searchInput,
    isSaving,
  } = useAssetsDialogManagement(startingTab, onClose, tagAllAsCheckedForVendors);

  const handleClearSearch = () => {
    const event = { target: { value: '' } } as unknown as React.ChangeEvent<HTMLInputElement>;
    handleSearchAsset(event);
  };

  const handleReachEnd = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  const handleTabPanelScroll = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  return (
    <Dialog
      data-testid='AssetsManagementDialog'
      maxWidth={false}
      onClose={handleClose}
      open
      PaperProps={{ style: dialogStyles.paperStyle }}
      scroll='paper'
    >
      <Title
        onClose={handleClose}
        titleLinkDetails={tabDetails.titleLinkDetails}
      />

      <DialogContent style={dialogStyles.content}>
        <TopBar
          handleSearchAsset={handleSearchAsset}
          handleToggleSelectAllAssets={handleToggleSelectAllAssets}
          isAllAssetsSelectedTabCovered={isAllAssetsSelectedTabCovered}
          isSearching={isLoading && searchInput !== ''}
          onClearSearch={handleClearSearch}
          searchValue={searchInput}
          wholeSelectionCheckboxText={tabDetails.wholeSelectionCheckboxText}
        />

        <Box>
          <Tabs
            onChange={handleTabChange}
            style={{ borderBottom: `1px solid ${colors.darkGray}` }}
            TabIndicatorProps={{
              style: {
                backgroundColor: colors.blue02,
              },
            }}
            value={selectedTab}
          >
            {tabsArray.map((tab) => (
              <Tab
                key={tab}
                disabled={shouldDisableTab(tab)}
                label={i18n.t(`dialogs.assetsManagement.tabTitles.${tab}`) as string}
                style={{
                  paddingLeft: 45,
                  paddingRight: 45,
                  paddingBottom: 5,
                  color: shouldDisableTab(tab) ? colors.gray : colors.blue02,
                  textTransform: 'none',
                }}
                value={tab}
              />
            ))}
          </Tabs>
        </Box>

        <Box sx={dialogStyles.tabContent}>
          {tabsArray.map((tab) => (
            <TabPanel
              key={tab}
              index={tab}
              isFetchingNextPage={isFetchingNextPage && selectedTab === tab}
              onScroll={handleTabPanelScroll}
              style={dialogStyles.assetsList}
              value={selectedTab}
            >
              {isLoading && selectedTab === tab ? (
                <Box className={styles.loadingBox}>
                  <CircularProgressBar className={styles.loadingBar} size={40} />
                </Box>
              ) : displayedAssets.length === 0 ? (
                <EmptyState />
              ) : (
                <tabDetails.SelectionListComponent
                  assets={displayedAssets}
                  checkAsset={checkAsset}
                  checkMultipleAssetsByOwner={checkMultipleAssetsByOwner}
                  handleClose={handleClose}
                  onReachEnd={handleReachEnd}
                  ownerDisplayNames={ownerDisplayNames}
                  vendor={tab}
                />
              )}
            </TabPanel>
          ))}
        </Box>
      </DialogContent>

      <AssetsManagementButtons
        CustomLowerBarLeftComponent={tabDetails.CustomLowerBarLeftComponent}
        extraDetails={extraDetails}
        handleClose={handleClose}
        handleSave={handleSave}
        isSaving={isSaving}
      />
    </Dialog>
  );
};
