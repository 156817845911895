
import { FC, useCallback, useState } from 'react';

import styles from '../ButtonMainComponent.module.scss';

import { TestPRExperienceDialogContent } from './TestPRExperienceDialogContent';

import { PrEnablementIllustration } from 'assets';
import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitText } from 'components/JitText/JitText';
import { TaskDialog } from 'components/TaskDialog/TaskDialog';
import { TaskDialogImage } from 'components/TaskDialog/TaskDialogImage';
import { useTenantContext } from 'context/TenantContext';
import { TEST_PR_EXPERIENCE_CHECK_SLUG } from 'context/TenantContext/constant';

export const TestPRExperienceButton: FC = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleOpen = useCallback(() => setDialogOpen(true), []);
  const handleClose = useCallback(() => setDialogOpen(false), []);
  const { setCheckAsCompleted } = useTenantContext();

  const onSuccess = useCallback(() => {
    setCheckAsCompleted(TEST_PR_EXPERIENCE_CHECK_SLUG);
    handleClose();
  }, [setCheckAsCompleted, handleClose]);

  return (
    <>
      <JitGradientButton onClick={handleOpen}>
        <div className={styles.buttonContent}>
          <JitText size='s' text='pages.quickStart.checks.testPRExperience.ctaText' />
        </div>
      </JitGradientButton>

      <TaskDialog
        handleClose={handleClose}
        isOpen={dialogOpen}
        leftComponent={<TestPRExperienceDialogContent onSuccess={onSuccess} />}
        rightComponent={<TaskDialogImage src={PrEnablementIllustration} />}
      />
    </>
  );
};
