import { FC } from 'react';

import { Tag } from 'components/JitCard/JitCard';
import { ISvg } from 'types/interfaces';
import { IntegrationProvider } from 'types/interfaces/Integrations/IIntegration';

export interface CustomIntegrationCardDefaultProps {
  icon: FC;
  title: string;
  tags: Tag[];
}

export enum IntegrationType {
  secret = 'secret',
  securityTool = 'securityTool',
  asCode = 'asCode',
  custom = 'custom',
  thirdParty = 'third_party',
}

export enum IntegrationVendorType {
  cloud = 'cloud',
  tms = 'tms',
  collaborationPlatform = 'collaborationPlatform',
  sourceCodeManagement = 'sourceCodeManagement',
  sast = 'sast',
  cicdSecurity = 'cicdSecurity',
  registry = 'registry',
  dataSecurity = 'dataSecurity',
  siem = 'siem',
  cloudSecurity = 'cloudSecurity',
  dast = 'dast',
  compliance = 'compliance',
  secretManagement = 'secretManagement',
  observability = 'observability',
}

interface Link {
  href: string;
  text: string;
}

interface Field {
  label: string;
  value?: string;
  placeholder?: string;
}

export interface ISecretFields {
  name: Field;
  secret: Field;
  secretLink?: Link;
}

export interface InnerOptions {
  connect: {
    label: string;
  };
  fields: ISecretFields;
}

export interface IntegrationTemplate {
  key: string;
  vendor: string;
  vendorType: IntegrationVendorType;
  integrationType: string;
  title?: string;
  label: string;
  description?: string;
  shortDescription?: string;
  link?: Link;
  options?: InnerOptions;
  icon?: FC<ISvg> | string;
  provider?: IntegrationProvider;
  isNew?: boolean;
  logoUrl?: string;
}
