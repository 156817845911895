import { FC } from 'react';

import { useScoreColor } from './useScoreColor';

import { PriorityScore } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { sizesMap, TextSize } from 'utils/constants/textSizeMap';

interface Props {
  priorityScore: number;
  disabled?: boolean;
  dynamicColor?: boolean;
  size?: TextSize;

}

export const PriorityBoxIcon: FC<Props> = ({ priorityScore, disabled, dynamicColor = true, size = 's' }) => {
  const color = useScoreColor({ priorityScore,
    dynamicColor,
    disabled });

  return <JitIcon color={color} icon={PriorityScore} rotateDegrees={priorityScore < 0 ? 180 : 0} size={sizesMap[size]} />;
};
