import { useMemo } from 'react';

import { PRODUCT_CATALOG_FEATURES } from './constants';
import { useGetBooleanEntitlement } from './useGetBooleanEntitlement';

type FeatureId = (typeof PRODUCT_CATALOG_FEATURES)[keyof typeof PRODUCT_CATALOG_FEATURES];

export const useIsEntitled = (featureId: FeatureId, defaultValue = true) => {
  const getBooleanEntitlement = useGetBooleanEntitlement();

  return useMemo(() => getBooleanEntitlement(featureId, defaultValue), [featureId, defaultValue, getBooleanEntitlement]);
};
