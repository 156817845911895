import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetChannelsMenuItems } from '../../../useGetMenuItems/useGetChannelsMenuItems';

import { JitAutoCompleteForm } from 'components/JitAutoCompleteForm/components/JitAutocompleteForm';

interface Props {
  value?: string;
  onChange: (value?: string) => void;
}

export const SlackChannelSelect: FC<Props> = ({ value, onChange }) => {
  const { menuItems, isLoading } = useGetChannelsMenuItems();
  const { t } = useTranslation();

  return (
    <JitAutoCompleteForm
      disabled={isLoading}
      getOptionLabel={(option) => option}
      isLoading={isLoading}
      isSingleValue
      options={menuItems.map((item) => item.itemKey)}
      placeHolder={t('notifications.slack.channelSelect.placeholder')}
      selectedValue={value}
      setSelectedValue={(itemKey) => onChange(itemKey)}
    />
  );
};
