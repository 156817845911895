import { FC, useState } from 'react';

import styles from './BranchScanButton.module.scss';

import { JitButton } from 'components/JitButton/JitButton';
import { JitCheckbox } from 'components/JitCheckbox/JitCheckbox';
import { CustomDialog } from 'components/JitDialogs/CustomDialog';
import { JitText } from 'components/JitText/JitText';
import { JitTextInput } from 'components/JitTextInput/JitTextInput';
import { useToastsContext } from 'context/ToastsContext/ToastsContext';
import { useTriggerService } from 'services/TriggerService/useTriggerService';
import { ToastType } from 'types/enums';
import { ActionButton, ActionButtonVariant } from 'types/interfaces/ActionButton/ActionButton';

export const BranchScanButton: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [branchName, setBranchName] = useState('');
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { manualBranchScan } = useTriggerService();
  const { showToast } = useToastsContext();

  const handleScan = async () => {
    setIsLoading(true);
    try {
      await manualBranchScan(branchName);

      showToast({
        type: ToastType.Success,
        overrideProps: {
          title: 'pages.risks.resources.resourcesPage.branchScan.toasts.success.title',
          subtitle: 'pages.risks.resources.resourcesPage.branchScan.toasts.success.subtitle',
        },
      });
      setIsModalOpen(false);
      setBranchName('');
      setIsConfirmed(false);
    } catch (error) {
      showToast({
        type: ToastType.Error,
        overrideProps: {
          title: 'pages.risks.resources.resourcesPage.branchScan.toasts.error.title',
          subtitle: 'pages.risks.resources.resourcesPage.branchScan.toasts.error.subtitle',
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setBranchName('');
    setIsConfirmed(false);
  };

  return (
    <>
      <JitButton
        className={styles.scanButton}
        data-testid='trigger-branch-scan-button'
        height='40px'
        onClick={() => setIsModalOpen(true)}
        variant='contained'
        width='120px'
      >
        <JitText noWrap text='pages.risks.resources.resourcesPage.branchScan.button' />
      </JitButton>

      <CustomDialog
        actionButtons={[
          {
            label: 'pages.risks.resources.resourcesPage.branchScan.modal.cancelButton',
            handleClick: handleClose,
            variant: ActionButtonVariant.SECONDARY,
            testId: 'cancel-branch-scan-button',
          } as ActionButton,
          {
            label: 'pages.risks.resources.resourcesPage.branchScan.modal.scanButton',
            handleClick: handleScan,
            disabled: !branchName.trim() || !isConfirmed,
            variant: ActionButtonVariant.PRIMARY,
            isLoading,
          } as ActionButton,
        ]}
        content={(
          <div className={styles.modalContent}>
            <JitTextInput
              data-testid='branch-name-input'
              fullWidth
              label='pages.risks.resources.resourcesPage.branchScan.modal.branchNameLabel'
              onChange={setBranchName}
              placeholder='pages.risks.resources.resourcesPage.branchScan.modal.branchNamePlaceholder'
              value={branchName}
            />

            <div className={styles.confirmationSection}>
              <JitText
                color='warning'
                text='pages.risks.resources.resourcesPage.branchScan.modal.warningText'
                variant='body2'
              />

              <div className={styles.checkboxWrapper}>
                <JitCheckbox
                  checked={isConfirmed}
                  data-testid='scan-confirmation-checkbox'
                  onChange={(e) => setIsConfirmed(e.target.checked)}
                />

                <JitText
                  color='default'
                  text='pages.risks.resources.resourcesPage.branchScan.modal.confirmationText'
                  variant='body2'
                />
              </div>
            </div>
          </div>
        )}
        dataTestId='branch-scan-modal'
        isOpen={isModalOpen}
        onClose={handleClose}
        title='pages.risks.resources.resourcesPage.branchScan.modal.title'
        width='m'
      />
    </>
  );
};
