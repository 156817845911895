import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { AssetSection } from './components/AssetSection/AssetSection';
import { CoverageSelectionHeader } from './components/CoverageSelectionHeader/CoverageSelectionHeader';
import { CoverageSelectionSearch } from './components/CoverageSelectionSearch/CoverageSelectionSearch';
import styles from './CoverageSelectionDialog.module.scss';
import { useAssetSelection } from './hooks/useAssetSelection';

import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { SCMVendors, Vendor } from 'types/enums';
import { ActionButtonVariant } from 'types/interfaces';
import { Repository, SubOrganization } from 'types/interfaces/SCM/SCMMember';

interface Props {
  vendor: SCMVendors;
  onClose: () => void;
  onConfirm: (selectedSCMResources: Repository[], selectedSubOrganizations: SubOrganization[]) => void;
  handleSearch: (value: string) => void;
  alreadySelectedSCMResources?: Repository[];
  alreadySelectedSubOrganizations?: SubOrganization[];
  scmResourcesResults?: Repository[];
  subOrganizationsResults?: SubOrganization[];
  isLoading?: boolean;
}

const dialogStyle = {
  backgroundColor: colors.cards,
  border: `1px solid ${colors.darkGray}`,
  borderRadius: 20,
  minWidth: 844,
  minHeight: 600,
  maxHeight: 600,
  backgroundImage: 'none',
};

export const CoverageSelectionDialog = ({
  vendor,
  onClose,
  onConfirm,
  handleSearch,
  alreadySelectedSCMResources = [],
  alreadySelectedSubOrganizations = [],
  scmResourcesResults = [],
  subOrganizationsResults = [],
  isLoading,
}: Props) => {
  const {
    subOrganizations,
    scmResources,
    selectedItems,
    handleSubOrganizationSelect,
    handleRepositorySelect,
    handleSelectedItemUncheck,
    getSelectedAssets,
  } = useAssetSelection({
    alreadySelectedSCMResources,
    alreadySelectedSubOrganizations,
    scmResourcesResults,
    subOrganizationsResults,
  });
  const handleConfirm = () => {
    const { selectedSCMResources, selectedSubOrganizations } = getSelectedAssets();
    onConfirm(selectedSCMResources, selectedSubOrganizations);
  };
  return (
    <Dialog
      data-testid='CoverageSelectionDialog'
      onClose={onClose}
      open
      PaperProps={{ style: dialogStyle }}
    >
      <CoverageSelectionHeader onClose={onClose} vendor={vendor} />

      <DialogContent className={styles.content}>
        <CoverageSelectionSearch onChange={handleSearch} vendor={vendor} />

        {(scmResourcesResults?.length === 20 || subOrganizationsResults?.length === 20) && (
          <div className={styles.searchHint}>
            <JitText
              muted
              size='s'
              text='ChooseSCMResources.coverageSelectionDialog.refineSearch'
            />
          </div>
        )}

        <div className={styles.stepWrapper}>
          <AssetSection
            dataTestId='selected-section'
            items={selectedItems}
            onItemCheck={handleSelectedItemUncheck}
            title='ChooseSCMResources.coverageSelectionDialog.selectedSection'
          />

          {vendor === Vendor.GITLAB && (
            <AssetSection
              dataTestId='suborganizations-section'
              isLoading={isLoading}
              items={subOrganizations}
              onItemCheck={handleSubOrganizationSelect}
              title={`ChooseSCMResources.${vendor}.nomenclature.subOrganization.plural.uppercase`}
            />
          )}

          <AssetSection
            dataTestId='repositories-section'
            isLoading={isLoading}
            items={scmResources}
            onItemCheck={handleRepositorySelect}
            title={`ChooseSCMResources.${vendor}.nomenclature.repository.plural.uppercase`}
          />
        </div>
      </DialogContent>

      <DialogActions className={styles.actionButtonWrapper}>
        <JitActionButton
          actionButton={{
            variant: ActionButtonVariant.OUTLINED,
            label: 'ChooseSCMResources.coverageSelectionDialog.cancelButton',
            handleClick: onClose,
          }}
        />

        <JitActionButton
          actionButton={{
            variant: ActionButtonVariant.PRIMARY,
            label: 'ChooseSCMResources.coverageSelectionDialog.selectButton',
            handleClick: handleConfirm,
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

