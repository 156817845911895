import { useState, useCallback } from 'react';

import { getApiUrls } from 'services/apiUrls';
import { usePlanService } from 'services/PlanService/usePlanService';
import { useClient } from 'services/useClient';

// Define the interface locally since it's not exported from usePlanService
interface UploadFileToCentralizedRepoResponse {
  file_path: string;
}

interface UseSecurityToolRulesReturn {
  isLoading: boolean;
  error: string | null;
  fileContent: string | null;
  fetchRulesFile: (filePath: string) => Promise<void>;
  uploadRulesContent: (filePath: string, content: string) => Promise<boolean>;
  isUploading: boolean;
}

/**
 * Custom hook to fetch security tool rules files from the cached files endpoint
 * @returns {UseSecurityToolRulesReturn} Object containing loading state, error state, file content, and fetch function
 */
export const useSecurityToolRules = (): UseSecurityToolRulesReturn => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [fileContent, setFileContent] = useState<string | null>(null);

  const { getCachedFile } = usePlanService();
  const { client } = useClient();

  /**
   * Fetches a security tool rules file from the cached files endpoint
   * @param {string} filePath - The path of the file to retrieve (e.g. "somefolder1/somefolder2/test-file.txt")
   * @returns {Promise<void>}
   */
  const fetchRulesFile = useCallback(async (filePath: string): Promise<void> => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await getCachedFile(filePath);

      if (response) {
        setFileContent(response.content);
      } else {
        setError('File not found or is empty');
        setFileContent(null);
      }
    } catch (err) {
      console.error('Error fetching security tool rules file:', err);
      setError('Failed to fetch security tool rules file');
      setFileContent(null);
    } finally {
      setIsLoading(false);
    }
  }, [getCachedFile]);

  /**
   * Handle response from file upload API
   * @param response - The API response
   * @param content - The content that was uploaded
   * @returns {boolean} - Whether the upload was successful
   */
  const handleUploadResponse = (
    response: {
      status: number;
      data?: UploadFileToCentralizedRepoResponse | Record<string, unknown>;
    } | undefined,
    content: string,
  ): boolean => {
    // Success case: status is in 2xx range
    if (response && response.status >= 200 && response.status < 300) {
      // Update the file content in the state
      setFileContent(content);
      return true;
    }

    // Error case: status 400 with data
    if (response && response.status === 400 && response.data) {
      const errorData = response.data;
      console.error('Error uploading rules file:', errorData);

      // Check if we have the INVALID_SEMGREP_RULES error
      if (typeof errorData === 'object' && 'error' in errorData && errorData.error === 'INVALID_SEMGREP_RULES') {
        setError('message' in errorData && typeof errorData.message === 'string'
          ? errorData.message
          : 'Invalid Semgrep rules file');
      } else if (typeof errorData === 'object' && 'message' in errorData && typeof errorData.message === 'string') {
        // Check if we have any message
        setError(errorData.message);
      } else {
        // Default error message
        setError('Failed to upload rules file content');
      }
    } else {
      // Other error cases
      setError('Failed to upload rules file content');
    }
    return false;
  };

  /**
   * Uploads content to a security tool rules file
   * @param {string} filePath - The path of the file to upload (e.g. "somefolder1/somefolder2/test-file.txt")
   * @param {string} content - The content to upload
   * @returns {Promise<boolean>} - Whether the upload was successful
   */
  const uploadRulesContent = useCallback(async (filePath: string, content: string): Promise<boolean> => {
    setIsUploading(true);
    setError(null);

    try {
      // Get the client used by the service
      const url = getApiUrls.planService.uploadFileToCentralizedRepo();
      const data = {
        path: filePath,
        content,
      };

      // Include 400 in the allowed statuses to get the error details
      const response = await client.post<UploadFileToCentralizedRepoResponse>({
        url,
        allowedStatuses: [201, 400],
        requestConfig: {
          data,
          headers: { 'content-type': 'application/json' },
        },
      });

      return handleUploadResponse(response, content);
    } catch (err) {
      console.error('Error uploading security tool rules file content:', err);
      setError('Failed to upload rules file content');
      return false;
    } finally {
      setIsUploading(false);
    }
  }, [client]);

  return {
    isLoading,
    isUploading,
    error,
    fileContent,
    fetchRulesFile,
    uploadRulesContent,
  };
};
